import { DateTime } from 'luxon'
import { COUNTRY_CODES, LanguageCode, PHONE_COUNTRY_CODES, PhoneCountryCode } from './constants'
import { CountryCode, PartialNaturalPerson } from './types'
import seedrandom from 'seedrandom'

export function stringToCountryCode(key: string): CountryCode {
  key = key.slice(0, 2).toUpperCase()
  if (!COUNTRY_CODES.includes(key as CountryCode)) return CountryCode.FR
  return key as CountryCode
}

export function stringToPhoneCountryCode(key: string): PhoneCountryCode {
  key = (key || '').slice(0, 2).toUpperCase()
  if (!Object.keys(PHONE_COUNTRY_CODES).includes(key)) return 'FR'
  return key as PhoneCountryCode
}

export const formatUTC = (isoDate: string) => `${isoDate.substring(0, 10)}`

export function focusNextElement() {
  const focussableElements =
    'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])'
  if (document.activeElement && (document.activeElement as any).form) {
    const focussable = Array.prototype.filter.call(
      (document.activeElement as any).form.querySelectorAll(focussableElements),
      function (element) {
        return (
          element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
        )
      }
    )
    const index = focussable.indexOf(document.activeElement)
    focussable[index + 1].focus()
  }
}

export function formatDate(
  languageCode: LanguageCode,
  date?: Date | string | null,
  format?: Intl.DateTimeFormatOptions
) {
  if (!date) return ''

  let usableDate = null

  if (typeof date === 'string') {
    usableDate = DateTime.fromISO(date)
  } else {
    usableDate = DateTime.fromJSDate(date)
  }

  if (format) {
    return usableDate.setLocale(languageCode.toLocaleLowerCase()).toLocaleString(format)
  }

  return usableDate.setLocale(languageCode.toLocaleLowerCase()).toLocaleString(DateTime.DATE_MED)
}

export const formatNumber = (value: number, fractionDigits?: number) => {
  const options = {
    minimumFractionDigits: fractionDigits === undefined ? 2 : fractionDigits,
    maximumFractionDigits: fractionDigits
  } as Intl.NumberFormatOptions

  return (1000).toLocaleString(undefined, options).match(/ /)
    ? value.toLocaleString('fr-FR', options).replace(/ /gm, '.')
    : value.toLocaleString(undefined, options)
}

export const generateSeed = (name: string) => Math.floor(seedrandom(name)() * 100000000) % 360

export const formatCurrencyInput = (value: string) => {
  const tmp = value.replace(/^0+/, '').replace(/[^0-9]/g, '')
  return tmp.slice(0, -2).padStart(1, '0') + '.' + tmp.slice(-2).padStart(2, '0')
}

export const validateEmailFormat = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  if (emailRegex.test(email)) return true

  return false
}

export const getFullName = (person: Pick<PartialNaturalPerson, 'FirstName' | 'LastName'>) =>
  [person.FirstName, person.LastName].filter((name) => name).join(' ') || ''
