import { CheckIcon } from '@heroicons/react/20/solid'
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Base } from '../text/Text.react'

export type CheckboxProps = {
  onChange?: (status: boolean, e?: React.MouseEvent<HTMLInputElement>) => void
  value?: boolean
  className?: string
  disabled?: boolean
  children?: React.ReactNode
} & Omit<Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange'>, 'value'>

export const Checkbox = ({
  value,
  className,
  disabled = false,
  children,
  onChange
}: CheckboxProps) => {
  const [focused, setFocused] = useState<boolean>(false)

  return <div
    className="flex gap-x-4"
    onClick={(e: any) => {
      if (disabled || !onChange) return

      onChange(!value, e as React.MouseEvent<HTMLInputElement>)
    }}
  >
    <div
      className={twMerge(
        'shrink-0 flex justify-center items-center w-6 h-6 border-[1.5px] rounded text-white',
        value && 'border-blue-500 bg-blue-500 hover:border-blue-600 hover:bg-blue-600',
        !value && 'border-slate-100',
        !value && disabled && 'bg-slate-100 pointer-events-none',
        !value && !disabled && 'hover:border-slate-300',
        !disabled && 'cursor-pointer',
        disabled && value && 'bg-slate-100 border-slate-100 pointer-events-none',
        className
      )}
      tabIndex={0}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onKeyDown={event => {
        if (focused && (event.key === 'Enter' || event.key === ' ')) {
          event.preventDefault()
          event.stopPropagation()
          onChange && onChange(!value)
        }
      }}
    >
      {value && <CheckIcon className="grow text-white-500 stroke" />}
    </div>
    <Base>{children}</Base>
  </div>
}
