import { Base, Info, Title } from '@/atoms-react/text/Text.react'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { StarIcon } from '@heroicons/react/24/solid'
import { DoughnutChart } from '../charts/DoughnutChart.react'
import { LineChart } from '../charts/LineChart.react'
import Image from '@/atoms-react/image/Image.react'
import { Link } from 'react-router-dom'
import { getRoute } from '@/app/olkypass/utils/routes'
import { Routes } from '@/features/router'
import { formatNumber } from '@/features/utils/functions'
import { useWallet } from '@/features/wallet/hooks/use-wallet'
import Loader from '@/atoms-react/loader/Loader.react'

type SmallWalletCardProps = {
  data: any
  config: any
  chart?: any
  disabled?: boolean
} & React.ComponentPropsWithoutRef<'div'>

export const SmallWalletCard = ({
  data,
  config,
  chart,
  disabled = false,
  ...props
}: SmallWalletCardProps) => {
  const { t } = useTranslation(['atoms'])
  const { sparklineDataQuery } = useWallet()
  const { data: sparklineData, isLoading: isSparklineDataLoading } = sparklineDataQuery({
    crypto_identifier: data.id
  })

  const timestamps: number[] = []
  const values: number[] = []
  sparklineData?.forEach(([timestamp, value]) => {
    timestamps.push(new Date(timestamp * 1000).toISOString().split('T')[0])
    values.push(value)
  })

  return (
    <div
      {...(disabled && { 'data-tooltip': t('molecules.kyc_alert.kyc_warning') })}
      className={twMerge(props.className, 'flex w-full')}
    >
      <div
        className={twMerge(
          'border-2 border-slate-50 rounded-sm px-4 py-2 md:px-6 md:py-4 w-full text-black dark:text-white dark:border-slate-700 flex flex-col justify-between',
          props.className,
          disabled && 'opacity-40 pointer-events-none'
        )}
        {..._.omit(props, 'data', 'config', 'className', 'chart', 'disabled')}
      >
        <div>
          {/* Header card (title, favorite) */}
          <div
            className={twMerge(
              'flex w-full justify-between items-center',
              config?.header?.className
            )}
          >
            <Link to={getRoute(Routes.wallet_crypto_details, { cryptoKey: data.id })}>
              <Base className="text-slate-300 flex items-center gap-2">
                {data.image && (
                  <Image src={data.image} alt={data.name} className={'w-8 h-8 inline-block'} />
                )}
                {data.name}
              </Base>
            </Link>
            {_.isBoolean(data?.isFavorite) && (
              <span className="h-8 w-8">
                <StarIcon className={data?.isFavorite ? 'text-blue-600' : 'text-white'}></StarIcon>
              </span>
            )}
          </div>
          {/* Body of card */}
          <div className={twMerge(`flex w-full`, config?.body?.className)}>
            {/* Amount and rate */}
            <div className="flex flex-col items-start gap-2 w-full">
              {_.isNumber(data.current_price) && (
                <div className="w-full">
                  <Title className={twMerge('font-bold', config?.amount?.className)} noColor>
                    {formatNumber(data.current_price)} {config.amount.unit}
                  </Title>
                </div>
              )}
              {_.isNumber(data.price_change_percentage_24h) && (
                <div className="w-full">
                  <Info
                    className={twMerge(
                      'rounded-3xl text-black dark:text-black px-4 py-2 font-bold',
                      data.price_change_percentage_24h > 0 ? 'bg-green-500' : 'bg-red-500'
                    )}
                  >
                    {formatNumber(data.price_change_percentage_24h)} {config.rate.unit}
                  </Info>
                </div>
              )}
            </div>
            {/* Charts */}
            {!isSparklineDataLoading && (
              <div className="flex items-center gap-2 w-full">
                {chart && chart.type === 'Doughnut' && (
                  <>
                    <DoughnutChart
                      data={chart.data}
                      labels={chart.labels}
                      config={chart.config}
                      className={chart.className}
                    />
                  </>
                )}

                {chart && chart.type === 'Line' && (
                  <>
                    <LineChart
                      data={values}
                      labels={timestamps}
                      config={chart.config}
                      className={chart.className}
                    />
                  </>
                )}
              </div>
            )}
            {isSparklineDataLoading && <Loader className={'w-8 h-8 text-blue-500'} />}
          </div>
        </div>
      </div>
    </div>
  )
}
