import { AssociateCollaborator } from '@/app/olkypass/views/account/company-details/components/associate-collaborator'
import { CollaboratorList } from '@/app/olkypass/views/account/company-details/components/collaborator-list'
import { Button } from '@/atoms/buttons/Button'
import { Input } from '@/atoms/inputs/Input'
import LabelDecoration from '@/atoms/inputs/decorations/LabelDecoration'
import ModalVue from '@/atoms/modals/Modal.vue'
import ModalContentVue from '@/atoms/modals/ModalContent.vue'
import { SectionTitle, Subtitle } from '@/atoms/text/Text.tsx'
import { Section } from '@/components/layout/Section'
import { useCollaborator } from '@/features/company/hooks/use-collaborator'
import { useCompany } from '@/features/company/hooks/use-company'
import { defineJsxComponent } from '@/jsx-utils'
import { UsersIcon } from '@heroicons/vue/20/solid'
import ArrowsPointingInIcon from '@heroicons/vue/20/solid/ArrowsPointingInIcon'
import ArrowsPointingOutIcon from '@heroicons/vue/20/solid/ArrowsPointingOutIcon'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export const CollaboratorTab = defineJsxComponent(_ => {
  const t = useI18n().t
  const searchCollaborator = ref('')
  const displaySection = ref(true)
  const associateCollaboratorModal = ref(false)
  const route = useRoute()
  const identity_uuid = route.params.identity_uuid
  const { loadCollaborators } = useCollaborator()
  const { companyStore } = useCompany()
  const canUpdateCompany = ref(companyStore.currentCompanyPermission?.update_company_data)
  return () => <>
    <ModalVue
      open={associateCollaboratorModal.value}
      onClose={() => {
        associateCollaboratorModal.value = false
      }}
    >
      <ModalContentVue title={t('olkypass.companyPage.collaborators_section.dialog.header.new')}>
        <AssociateCollaborator
          onComplete={() => {
            associateCollaboratorModal.value = false
            loadCollaborators(identity_uuid)
          }}
        />
      </ModalContentVue>
    </ModalVue>
    <Section class="mb-4">
      <Button
        theme="outlined"
        icon={displaySection.value ? ArrowsPointingInIcon : ArrowsPointingOutIcon}
        onClick={() => {
          displaySection.value = !displaySection.value
        }}
      ></Button>
      <SectionTitle>{t('olkypass.companyPage.tabs.collaborators')}</SectionTitle>
      {displaySection.value && <>
          <Subtitle class="block mt-3">
            {t('olkypass.companyPage.collaborators_section.toolbar.browse')}
          </Subtitle>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
            <div className="md:col-span-2">
              <LabelDecoration class="" label="">
                {{
                  input: (input: any) => <Input
                    size="lg"
                    class={input.class}
                    v-model={searchCollaborator.value}
                    placeholder={t('olkypass.companyPage.collaborators_section.toolbar.search')}
                  />
                }}
              </LabelDecoration>
            </div>
            <div className=" md:col-span-1">
              {canUpdateCompany.value && <Button
                  theme="outlined"
                  icon={UsersIcon}
                  onClick={() => {
                    associateCollaboratorModal.value = true
                  }}
                >
                  {t('olkypass.companyPage.tooltip.button.new_collaborator')}
                </Button>}
            </div>
          </div>
          <CollaboratorList search={searchCollaborator.value}></CollaboratorList>
        </>}
    </Section>
  </>
})
