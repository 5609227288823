<template>
  <v-theme-provider :theme="getTheme">
    <v-container id="accountPage">
      <v-row v-if="!loading && kycState.level < 100">
        <v-col>
          <v-alert density="compact" class="incompletedAlert">
            <template #prepend>
              <v-icon icon="mdi-alert-circle-outline" color="error"></v-icon>
            </template>
            <template #title>
              {{ $t('olkypass.accountPage.profileSection.incompleteDataTitle') }}
            </template>
            <template #text>
              {{ $t('olkypass.accountPage.profileSection.incompleteData') }}
            </template>
          </v-alert>
        </v-col>
      </v-row>
      <!-- Header box of details profile -->
      <v-row>
        <v-col>
          <v-card v-if="!loading" id="profile" class="cardBox overflow-visible mt-32">
            <v-card-text>
              <v-row>
                <div class="h-44 lg:h-0 w-full"></div>
                <v-col cols="12" sm="12" md="4">
                  <div class="profileData">
                    <h5>
                      {{ $t('olkypass.accountPage.profileSection.label.verify_identity') }}
                    </h5>
                    <v-row>
                      <v-col cols="auto">
                        <v-btn
                          variant="flat"
                          icon="mdi-account-convert"
                          color="#D4D700"
                          class="identifyIcon"
                          size="x-large"
                          @click.prevent="openIdentityDialog"
                        ></v-btn>
                      </v-col>
                      <v-col>
                        <a
                          v-if="kycState.level < 50"
                          href="#"
                          class="lanchVerification"
                          @click.prevent="openIdentityDialog"
                          >{{
                            $t('olkypass.accountPage.profileSection.label.launch_verification')
                          }}</a
                        >
                        <a v-if="kycState.level > 50" href="#" class="alreadyVerified">{{
                          $t('olkypass.accountPage.profileSection.label.already_verification')
                        }}</a>
                        <a v-if="kycState.level == 50" href="#" class="alreadyVerified">{{
                          $t('olkypass.accountPage.profileSection.label.encours_verification')
                        }}</a>
                        <v-btn
                          v-if="kycState.level <= 50"
                          variant="text"
                          class="verifyMessage px-0 text-none"
                          prepend-icon="mdi-close-circle"
                          @click.prevent="openIdentityDialog"
                        >
                          <template #prepend>
                            <v-icon color="error"></v-icon>
                          </template>
                          {{ $t('olkypass.accountPage.profileSection.label.profil_not_verified') }}
                        </v-btn>
                        <v-btn
                          v-if="kycState.level > 50"
                          variant="text"
                          class="verifyMessage px-0 text-none"
                          prepend-icon="mdi-check-circle-outline"
                          @click.prevent="openIdentityDialog"
                        >
                          <template #prepend>
                            <v-icon color="success"></v-icon>
                          </template>
                          {{ $t('olkypass.accountPage.profileSection.label.profil_verified') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="4" class="accountPage_progressIcon">
                  <v-img
                    class="mx-auto progressIcon"
                    style="max-height: 370px"
                    :src="getProgressIconPath(kycState.level)"
                  ></v-img>
                </v-col>
                <v-col cols="12" sm="12" md="4"></v-col>
                <v-col cols="12" sm="12" md="4">
                  <div class="profileData">
                    <h5>
                      {{ naturalPerson.Gender }} {{ naturalPerson.FirstName }}
                      {{ naturalPerson.LastName }}
                    </h5>

                    <p
                      class="text-secondary-500 underline cursor-pointer"
                      @click="ionRouter.navigate(Routes.account_security, 'root')"
                    >
                      {{ $t('olkypass.accountPage.profileSection.label.password_change') }}
                    </p>
                  </div>
                  <div class="profileData">
                    <h5>{{ $t('olkypass.accountPage.profileSection.label.kyc_identifier') }}</h5>
                    <p>
                      {{ naturalPerson?.UUID }}
                      <v-icon
                        size="small"
                        class="ml-2"
                        icon="mdi-content-copy"
                        @click="copyTextToClipboard('uuid')"
                      >
                      </v-icon>
                      <v-input
                        persistent-hint
                        :messages="textToClipboard.uuid"
                        class="color-secondary-500"
                      >
                      </v-input>
                    </p>
                  </div>
                  <div v-if="!loading && naturalPerson.ibanOlkyPay" class="profileData">
                    <h5>{{ $t('olkypass.accountPage.profileSection.label.olkypay_iban') }} :</h5>
                    <p>
                      {{ naturalPerson?.ibanOlkyPay }}
                      <v-icon
                        size="small"
                        class="ml-2"
                        icon="mdi-content-copy"
                        @click="copyTextToClipboard('iban')"
                      >
                      </v-icon>
                      <v-input persistent-hint :messages="textToClipboard.iban_olkypay"> </v-input>
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Principal box of data -->
      <v-row>
        <v-col>
          <v-tabs v-model="tab" align-tabs="start" class="identityTabs" show-arrows>
            <v-tab value="1" dark class="dark:text-white">{{
              $t('olkypass.accountPage.basicInfo.tabs.informations')
            }}</v-tab>
            <v-tab value="2" dark class="dark:text-white">{{
              $t('olkypass.accountPage.basicInfo.tabs.emails')
            }}</v-tab>
            <v-tab value="3">{{ $t('olkypass.accountPage.basicInfo.tabs.mobiles') }}</v-tab>
            <v-tab value="4">{{ $t('olkypass.accountPage.basicInfo.tabs.adresses') }}</v-tab>
            <v-tab value="5">{{ $t('olkypass.accountPage.basicInfo.tabs.payments') }}</v-tab>
            <v-tab value="6" @click="displayIdentityDocuments">{{
              $t('olkypass.accountPage.basicInfo.tabs.documents')
            }}</v-tab>
            <v-tab value="7">{{ $t('olkypass.accountPage.basicInfo.tabs.alias') }}</v-tab>
          </v-tabs>

          <v-window v-model="tab" class="tabContent" style="overflow: visible">
            <v-window-item key="1" value="1">
              <AccountInfoTab ref="accountInfoTab"></AccountInfoTab>
            </v-window-item>
            <v-window-item key="2" value="2">
              <EmailsTab ref="emailsTab"></EmailsTab>
            </v-window-item>
            <v-window-item key="3" value="3">
              <MobilesTab ref="mobilesTab"></MobilesTab>
            </v-window-item>
            <v-window-item key="4" value="4">
              <AddressTab ref="addressTab"></AddressTab>
            </v-window-item>
            <v-window-item key="5" value="5">
              <PaymentsTab ref="paymentsTab"></PaymentsTab>
            </v-window-item>
            <v-window-item key="6" value="6">
              <DocumentsTab ref="documentsTab"></DocumentsTab>
            </v-window-item>
            <v-window-item key="7" value="7">
              <PublicAliasTab ref="publicAliasTab"></PublicAliasTab>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
      <DialogConfirm ref="dialogConfirm"></DialogConfirm>
      <KYCIdentityDialogChild
        v-if="kycState.level <= 100"
        ref="popupIdentity"
      ></KYCIdentityDialogChild>
      <DialogInfo ref="dialogInfo"></DialogInfo>
    </v-container>
  </v-theme-provider>
</template>

<script lang="ts">
import KYCIdentityDialogChild from '../components/Account/KYCIdentityDialogChild.vue'
import NotificationsMixins from '../mixins/NotificationsMixins.js'
import ToolsMixins from '../mixins/ToolsMixins.js'
import store, { mapGetters } from '../store'
// import UniformPanels from "../components/Common/UniformPanels.vue";
import { Routes } from '@/features/router'
import { isSystemThemeDark } from '@/features/utils/theme'
import { useIonRouter } from '@ionic/vue'
import AccountInfoTab from '../components/Account/AccountInfoTab.vue'
import AddressTab from '../components/Account/AddressTab.vue'
import DocumentsTab from '../components/Account/DocumentsTab.vue'
import EmailsTab from '../components/Account/EmailsTab.vue'
import MobilesTab from '../components/Account/MobilesTab.vue'
import PaymentsTab from '../components/Account/PaymentsTab.vue'
import PublicAliasTab from '../components/Account/PublicAliasTab.vue'
import DialogConfirm from '../components/DialogConfirm.vue'
import DialogInfo from '../components/DialogInfo.vue'

export default {
  name: 'AccountPage',
  components: {
    DialogInfo,
    KYCIdentityDialogChild,
    // UniformPanels,
    DialogConfirm,
    AccountInfoTab,
    DocumentsTab,
    EmailsTab,
    MobilesTab,
    AddressTab,
    PaymentsTab,
    PublicAliasTab
  },
  mixins: [NotificationsMixins, ToolsMixins],
  data: () => ({
    editableFields: ['CountryOfCitizenship', 'CountryOfCitizenship2', 'ActivityCode', 'CSPCode'],
    panels: {
      basicInfo: {
        box_fields: [
          'FirstName',
          'LastName',
          'OtherFirstNames',
          'MaidenName',
          'DateOfBirth.date',
          'CountryOfCitizenship',
          'CountryOfCitizenship2',
          'countryOfBirth',
          'StateOfBirth',
          'ActivityCode',
          'CSPCode'
        ]
      }
    },
    identityDialog: false,
    interval: {},
    completeKYCPercentage: 0,
    textToClipboard: {
      uuid: '',
      iban_olkypay: ''
    },
    tab: null,
    progressIconBasePath: '/images/olkypass/',
    Routes,
    ionRouter: useIonRouter()
  }),
  computed: {
    ...mapGetters([
      'kycState',
      'loading',
      'naturalPerson',
      'inMemoryNP',
      'countries',
      'cspCodes',
      'activities',
      'countryLabel',
      'cspLabel',
      'activityLabel',
      'wantToLeavePage',
      'currentUUID',
      'currentLanguage'
    ]),
    getTheme() {
      return isSystemThemeDark() ? 'myCustomDarkTheme' : 'myCustomLightTheme'
    },
    isCompletedBasicInfo() {
      let hasEmptyPersonalData = false
      this.panels.basicInfo.box_fields.forEach((element) => {
        if (
          element !== 'OtherFirstNames' &&
          element !== 'MaidenName' &&
          element !== 'CountryOfCitizenship2'
        ) {
          const value = this.getValueFromObject(this.naturalPerson, element)
          if (value === '' || value === null) {
            hasEmptyPersonalData = true
          }
        }
      })
      return hasEmptyPersonalData
    }
  },
  async mounted() {
    await this.detectNaturalPersonIdentity(this.naturalPerson)
    if (this.naturalPerson.DateOfBirth === null) {
      if (!this.editableFields.includes('DateOfBirth.date')) {
        this.editableFields.push('DateOfBirth.date')
      }
    }
  },
  methods: {
    openIdentityDialog() {
      if (this.kycState.level < 50) {
        this.$refs.popupIdentity.open()
      }
    },
    copyTextToClipboard(field) {
      switch (field) {
        case 'uuid':
          navigator.clipboard.writeText(this.naturalPerson.UUID)
          this.textToClipboard.uuid = this.$i18n.t(
            'olkypass.accountPage.notifications.success.copyText.uuid'
          )
          setTimeout(() => {
            this.textToClipboard.uuid = ''
          }, 3000)
          break
        case 'iban':
          navigator.clipboard.writeText(this.naturalPerson.ibanOlkyPay)
          this.textToClipboard.iban_olkypay = this.$i18n.t(
            'olkypass.accountPage.notifications.success.copyText.iban'
          )
          setTimeout(() => {
            this.textToClipboard.iban_olkypay = ''
          }, 3000)
          break
      }
    },
    getProgressIconPath(level) {
      return this.progressIconBasePath + 'OlkyPass' + level + '.svg'
    },
    async createOlkyPayAccount() {
      let hasEmptyPersonalData = false
      this.$refs.accountInfoTab.panels.basicInfo.box_fields.forEach((element) => {
        if (
          element !== 'OtherFirstNames' &&
          element !== 'MaidenName' &&
          element !== 'CountryOfCitizenship2'
        ) {
          const value = this.getValueFromObject(this.naturalPerson, element)
          if (value === '' || value === null) {
            hasEmptyPersonalData = true
          }
        }
      })
      if (hasEmptyPersonalData) {
        this.$refs.dialogInfo.open(
          'accountPage.dialog.sweetAlert.incompleteBasicInfos.header',
          'accountPage.dialog.sweetAlert.incompleteBasicInfos.body'
        )
      } else {
        if (this.kycState.level === 100) {
          if (
            await this.$refs.dialogConfirm.open(
              'accountPage.dialog.confirm.create_okypay_account.header',
              'accountPage.dialog.confirm.create_okypay_account.body'
            )
          ) {
            store.dispatch('playLoader')
            await store.dispatch('createOlkyPayAccount')
            await store.dispatch('setAccessibleApplications')
            await store.dispatch('setCurrentNaturalPerson')
            store.dispatch('stopLoader')
          }
        } else {
          this.$refs.dialogInfo.open(
            'accountPage.dialog.sweetAlert.incompleteKYC.header',
            'accountPage.dialog.sweetAlert.incompleteKYC.body'
          )
        }
      }
    },
    async displayIdentityDocuments() {
      await store.dispatch('playInternalLoader')
      await store.dispatch('loadIdentityDocuments', { UUID: this.naturalPerson.UUID })
      await store.dispatch('stopInternalLoader')
    }
  }
}
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
