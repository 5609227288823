/**
 * Generate a new router route using params and keeping the search and hash from current location
 * @param path
 * @param params
 * @param search
 * @param hash
 * @returns
 */
export const getRoute = (path: string, params: any = {}, search?: string, hash?: string) => {
  for (const key in params) {
    path = path.replace(`:${key}`, params[key] || '-')
  }
  search = search || document.location.search.slice(1)
  hash = hash || document.location.hash.slice(1)
  return path + (search ? `?${search}` : '') + (hash ? `#${hash}` : '')
}

const prefix = '/pass'
const prefixWallet = '/pass/wallet'
export const OlkyPassRoutes = {
  pass: prefix,
  dashboard: prefix + '/dashboard',
  support: prefix + '/support',
  kycoin: prefix + '/kycoin',
  kypay: prefix + '/kypay',
  account_base: prefix + '/account',
  account_identity: prefix + '/account/identity',
  security: prefix + '/security',
  account_alias_email: prefix + '/account/alias/email',
  account_alias_mobile: prefix + '/account/alias/mobile',
  account_alias_address: prefix + '/account/alias/address',
  account_alias_documents: prefix + '/account/alias/documents',
  account_alias_public: prefix + '/account/alias/public',
  account_alias_payments: prefix + '/account/alias/payments',
  companies: prefix + '/company',
  companies_add: prefix + '/company/new',
  company_details: prefix + '/company/:identity_uuid',

  // Wallet
  wallet_base: prefixWallet,
  wallet_dashboard: prefixWallet + '/dashboard',
  wallet_form: prefixWallet + '/form',
  wallet_buy: prefixWallet + '/buy',
  wallet_sell: prefixWallet + '/sell',
  wallet_convert: prefixWallet + '/convert',
  wallet_transfer: prefixWallet + '/transfer',
  wallet_crypto: prefixWallet + '/crypto/list',
  wallet_crypto_details: prefixWallet + '/crypto/:cryptoKey',

  // Flow uses another prefix
  flow: '/flow',
  flow_session: '/flow/:session_uuid',
  flow_block: '/flow/:session_uuid/:block',
  flow_step: '/flow/:session_uuid/:block/:step',
  flow_substep: '/flow/:session_uuid/:block/:step/:substep'
}
