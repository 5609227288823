import { CSPCodesNoActivity } from '@/features/utils/constants'
import store from '../store'

import _ from 'lodash'

const ToolsMixins = {
  $store: store,
  methods: {
    getDocumentCookie(name, defaultValue) {
      /*let cookieValue;
            if (document.cookie && document.cookie !== '') {
                let cookies = document.cookie.split(';');

                for (let i = 0; i < cookies.length; i++) {
                    let cookie = cookies[i].trim();
                    if (cookie.substring(0, name.length + 1) === (name + '=')) {
                       cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                        break;
                    }
                }
            }
            if (cookieValue === undefined) {
                return defaultValue;
            }*/
      const cookieValue = window.localStorage.getItem(name)
      if (cookieValue === null) {
        return defaultValue
      }

      return cookieValue
    },
    detectChosenLanguage() {
      const language = this.getDocumentCookie('language', 'en')
      ToolsMixins.$store.dispatch('setCurrentLanguage', language)
      this.$i18n.locale = language
      return language
    },
    detectChosenTheme() {
      const theme = this.getDocumentCookie('theme', 'light')
      ToolsMixins.$store.dispatch('setCurrentTheme', theme)
      this.$i18n.theme = theme

      return theme
    },
    getValueFromObject(obj, path) {
      if (!path) return obj
      const properties = path.split('.')

      return this.getValueFromObject(obj[properties.shift()], properties.join('.'))
    },
    compareObjectsAttribute(inMemoryObject, currentObject, attribute) {
      try {
        let currentValue =
          currentObject !== undefined ? this.getValueFromObject(currentObject, attribute) : ''
        let memoryValue =
          inMemoryObject !== undefined ? this.getValueFromObject(inMemoryObject, attribute) : ''
        if (currentValue === undefined) {
          currentValue = null
        }
        if (memoryValue === undefined) {
          if (currentValue === true || currentValue === false) {
            memoryValue = false
          } else {
            memoryValue = null
          }
        }
        return memoryValue !== currentValue
      } catch (error) {
        return false
      }
    },
    isEqualObjects(obj1, obj2) {
      return _.isEqual(obj1, obj2)
    },
    isEmptyObject(obj) {
      return _.isEmpty(obj)
    },
    getCurrentCSPCodeCode() {
      const codeID = this.naturalPerson.CSPCode
      const CSPCodeEntity = (this.cspCodes ?? []).find(codeEntity => codeEntity.id === codeID)
      const code = CSPCodeEntity ? CSPCodeEntity.id : ''
      return code
    },
    // @TODO Ask backend to add a boolean to CSP response instead of hardcoding an array
    getCSPCodesNoActivity() {
      return CSPCodesNoActivity
    },
    checkCompletedBasicInfo(list, obj) {
      let hasEmptyPersonalData = false
      list.forEach(element => {
        if (
          element !== 'OtherFirstNames' &&
          element !== 'MaidenName' &&
          element !== 'ActivityCode'
        ) {
          const value = this.getValueFromObject(obj, element)
          if (value === '' || value === null || typeof value === 'undefined') {
            hasEmptyPersonalData = true
          }
        }

        // Check for CSPCode and ActivityCode interactions inside Natural Person object
        if (list.includes('ActivityCode') && list.includes('CSPCode')) {
          const activityValue = this.getValueFromObject(obj, 'ActivityCode')
          const CSPCode = this.getValueFromObject(obj, 'CSPCode')

          // If the CSP code means that the person is active, the person should mention it's activity
          if (
            !this.getCSPCodesNoActivity()
              .map(entity => entity.id)
              .includes(CSPCode)
          ) {
            if (
              activityValue === '' ||
              activityValue === null ||
              typeof activityValue === 'undefined'
            ) {
              hasEmptyPersonalData = true
            }
          }
        }
      })
      return hasEmptyPersonalData
    },
    async detectNaturalPersonIdentity(naturalPerson) {
      if (!naturalPerson.UUID) {
        await ToolsMixins.$store.dispatch('setCurrentTreatedEntity', 'naturalPerson')
        await ToolsMixins.$store.dispatch('playLoader')
        await ToolsMixins.$store.dispatch('setCurrentNaturalPerson')
        await ToolsMixins.$store.dispatch('stopLoader')
      }
    },
    async checkKypayInit(kypay) {
      // if (!kypay.balance) {
      await ToolsMixins.$store.dispatch('playLoader')
      await ToolsMixins.$store.dispatch('setCurrentKypay')
      await ToolsMixins.$store.dispatch('stopLoader')
      // }
    },
    async checkKycoinInit(kycoin) {
      // if (!kycoin.balance) {
      await ToolsMixins.$store.dispatch('playLoader')
      await ToolsMixins.$store.dispatch('setCurrentKycoin')
      await ToolsMixins.$store.dispatch('stopLoader')
      // }
    },
    format_date(date) {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }
      return new Date(date).toLocaleDateString('fr-FR', options)
    },
    checkCurrentWeek() {
      const today = new Date()
      const dayOfWeek = today.getDay()
      const difference = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
      const firstDayOfWeek = new Date(today.setDate(difference))

      const formattedDate = firstDayOfWeek.toISOString().slice(0, 10)
      return formattedDate
    },
    checkPrevWeek(date) {
      // Calculate first day of previous week
      const firstDayOfPreviousWeek = new Date(date)
      firstDayOfPreviousWeek.setDate(firstDayOfPreviousWeek.getDate() - 7)
      // Format of date of previous week
      const formattedPreviousWeek = firstDayOfPreviousWeek.toISOString().slice(0, 10)
      // this.firstDayOfPreviousWeek = formattedPreviousWeek;
      return formattedPreviousWeek
    }
  }
}
export default ToolsMixins
