import { Button } from '@/atoms/buttons/Button'
import { Input } from '@/atoms/inputs/Input'
import { LabelDecoration } from '@/atoms/inputs/decorations/LabelDecoration'
import { useRegister } from '@/features/register/hooks/use-register'
import type { RegisterCredentialType } from '@/features/register/types'
import { ref } from 'vue'
import { defineVueComponent } from '@/jsx-utils'
import { useI18n } from 'vue-i18n'

export const FormView = defineVueComponent(() => {
  const { t } = useI18n()
  const { loader, register } = useRegister()
  const validated_once = ref(false)
  const credentials = ref<RegisterCredentialType>({
    FirstName: '',
    LastName: '',
    email: '',
    telephone: '',
    password: '',
    confirm_password: '',
    accept_condition: ''
  })
  return () => <form
    class="mt-6 text-left"
    onSubmit={(e: any) => {
      e.preventDefault()
      validated_once.value = true
      if (!credentials.value.email) return
      register(credentials.value)
    }}
  >
    <LabelDecoration class="my-4">
      {{
        label: () => <>{t('login.registerPage.input.email')}</>,
        input: (input: any) => <Input
          size="lg"
          placeholder="jean.dupont@mail.com"
          required
          pattern="[^@]+@[^@]+\.[^@]+"
          autocomplete="email"
          disabled={loader.loading}
          class={input.class}
          v-model={credentials.value.email}
        />
      }}
    </LabelDecoration>

    <div class="flex flex-row items-center w-full mt-8">
      <div class="grow" />
      <Button theme="primary" type="submit" size="lg" loading={loader.loading}>
        {t('login.registerPage.button.sign_up')}
      </Button>
    </div>
  </form>
})
