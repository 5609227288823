<template>
  <div class="card">
    <div class="card-body border-gray card-plain border-radius-lg">
      <ion-row>
        <ion-col size="11">
          <ion-row @click="openSupplierTabs">
            <ion-col size="2" class="mt-3">
              <div class="circleIcon">
                <i class="logoOlky">
                  <ion-img :src="olkypayIcon"></ion-img>
                </i>
              </div>
            </ion-col>
            <ion-col>
              <ion-row>
                <ion-col>
                  <h4>{{ supplier.fullName }}</h4>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <span class="text-gray">{{ formattedIBAN }}</span>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="text-end text-sm"> € {{ formattedBalance }} </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col size="1" class="mt-3">
          <ion-icon
            v-if="supplier.supplierCards"
            :icon="displayed_card ? chevronUpCircleOutline : chevronDownCircleOutline"
            color="blue_olky"
            size="large"
            @click="displayCardAccount"
          />
        </ion-col>
      </ion-row>
    </div>
  </div>
  <div
    v-for="card in supplier.supplierCards"
    v-if="displayed_card && supplier.supplierCards"
    class="card ion-margin-top mx-2"
  >
    <div
      class="card-body border card-plain border-radius-lg bg-gray-100"
      @click="openCardTabs(card)"
    >
      <ion-row>
        <ion-col size="2" class="mt-2">
          <div class="circleIcon">
            <i class="logoOlky">
              <ion-icon :icon="cardOutline" color="blue_olky"></ion-icon>
            </i>
          </div>
        </ion-col>
        <ion-col>
          <div class="d-flex flex-column">
            <h4 class="mb-1">{{ card.fullName }}</h4>
            <div class="d-flex align-items-center text-md">
              €
              {{
                new Intl.NumberFormat('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }).format(card.balance / 100)
              }}
            </div>
          </div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script lang="ts">
import olkypayIcon from '../../../../../public/images/olkypay/olkypay_icon.svg'
import { IonRow, IonCol, IonIcon, IonImg } from '@ionic/vue'
import {
  caretDownCircleOutline,
  caretUpCircleOutline,
  cardOutline,
  caretUpCircle,
  caretDownCircle,
  chevronDownCircle,
  chevronDownCircleOutline,
  chevronUpCircleOutline
} from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Supplier',
  components: {
    IonRow,
    IonCol,
    IonIcon,
    IonImg
  },
  props: ['supplier'],
  data() {
    return {
      displayed_card: false,
      caretDownCircleOutline,
      caretUpCircleOutline,
      cardOutline,
      caretUpCircle,
      caretDownCircle,
      chevronDownCircle,
      chevronDownCircleOutline,
      chevronUpCircleOutline,
      olkypayIcon
    }
  },
  methods: {
    ...mapActions('supplier', ['setCurrentSupplier', 'setTreatedEntity']),
    ...mapActions('card', ['setSupplierCards', 'setCurrentCard']),
    ...mapActions('loader', ['playSkeletonLoader', 'stopSkeletonLoader']),
    displayCardAccount() {
      this.displayed_card = !this.displayed_card
    },
    async openSupplierTabs() {
      this.playSkeletonLoader()
      this.setTreatedEntity('account')
      await this.setSupplierCards(this.supplier.supplierCards)
      await this.setCurrentSupplier(this.supplier)
        .then(res => {
          this.stopSkeletonLoader()
          this.$router.push({ name: 'HomeTabRouter' })
        })
        .catch(async (err: any) => {})
        .finally(() => this.stopSkeletonLoader())
    },
    async openCardTabs(card) {
      this.playSkeletonLoader()
      this.setTreatedEntity('card')
      this.setCurrentCard(card)
      await this.setCurrentSupplier(card)
        .then((res) => {
          this.stopSkeletonLoader()
          this.$router.push({ name: 'HomeTabRouter' })
        })
        .catch(async (err: any) => {})
        .finally(() => this.stopSkeletonLoader())
    }
  },
  computed: {
    formattedBalance() {
      // balance is in centimes
      const balance = this.supplier.balance / 100
      return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(balance)
    },
    formattedCardBalance(cardBalance) {
      // balance is in centimes
      const balance = cardBalance / 100
      return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(balance)
    },
    formattedIBAN() {
      const formattedIBAN = this.supplier.iban.match(/.{1,4}/g).join(' ')

      return formattedIBAN
    }
  }
}
</script>

<style scoped></style>
