import { Modal } from '@/atoms-react/modal/index.react'
import { Base, CardTitle } from '@/atoms-react/text/Text.react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/atoms-react/button/Button.react.tsx'
import { Themes } from '@/types/theme.ts'
import { Routes } from '@/features/router.ts'
import { useNavigate } from 'react-router-dom'

type ModalAuthenticationRequireHelperProps = {
  open: boolean
  onClose: () => void
} & React.ComponentPropsWithoutRef<'div'>

export const ModalAuthenticationRequireHelper = ({
  open,
  onClose
}: ModalAuthenticationRequireHelperProps) => {
  const { t } = useTranslation(['pass'])
  const navigate = useNavigate()
  return (
    <Modal open={open} closable onClose={onClose} className="sm:w-full w-full max-w-lg">
      <div className="flex flex-col">
        <CardTitle className="text-center mb-4">
          {t('modal_authentication_helper.header')}
        </CardTitle>
        <Base className="mt-6 text-center text-slate-400 dark:text-slate-400">
          {t('modal_authentication_helper.body')}
        </Base>
        <Button
          className={'mt-10 mb-4'}
          theme={Themes.primary}
          onClick={() => navigate(Routes.security)}
        >
          {t('modal_authentication_helper.buttons.access_security')}
        </Button>
        <Button
          className={'mb-4'}
          theme={Themes.white}
          onClick={() => {
            onClose()
          }}
        >
          {t('modal_authentication_helper.buttons.cancel')}
        </Button>
      </div>
    </Modal>
  )
}
