/** Copy this file to environment.ts to override defaults with your own values. */

export const isDevelopment = window.location.origin.includes("uat") || window.location.origin.includes("algoreg") || window.location.origin.includes("localhost") || window.location.origin.includes("127.0.0.1");

export const environment = {
    APP_ROOT_API: isDevelopment ? "https://apidev.olkypay.com" : "https://api.olkypay.com",
    API_VID_ALGOREG: isDevelopment ? 'https://vid.olky-dev.algoreg.com' : 'https://vid.olky.algoreg.com',
    API_CHAT_ALGOREG: isDevelopment ? 'https://chat.olky-dev.algoreg.com' : 'https://chat.olky.algoreg.com',
    RECAPTCHA_PUBLIC_KEY: '6LeXEJ0lAAAAACBgL1wKFXUEUg6SbVAFpIIFVkWq',
    OLKYPASS_MODE: true,
    KYPAYFUND_REDIRECT_URL: 'https://app.olky.eu/#/kypay',
    KYCOIN_BUY_EXTERNAL_PAGE: 'https://nun-tech.fr/acheter-des-kycoin/',
    OLKY_BANKING_URL: isDevelopment ? 'https://my.uat.olkypay.com' : 'https://my.olky.eu',
};