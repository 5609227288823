<template>
  <a id="videoDashboardBox" :href="YOUTUBE_OLKYPAY_URL" target="_blank">
    <v-card class="dashboardBox video flex-col" height="320px" width="auto">
      <v-card-title>
        {{ $t('olkypass.mainPage.olkypass.bloc3.cardTitle') }}
      </v-card-title>

      <v-card-text class="flex flex-col grow">
        <div class="video-box grow d-flex">
          <div class="glightbox play-btn"></div>
        </div>
        <div class="flex grow flex-col align-middle justify-center">
          <p>{{ $t('olkypass.mainPage.olkypass.bloc3.desc') }}</p>
          <p>{{ $t('olkypass.mainPage.olkypass.bloc3.desc2') }}</p>
        </div>
      </v-card-text>
    </v-card>
  </a>
</template>

<script>
import { mapGetters } from '../../store'
import ToolsMixins from '../../mixins/ToolsMixins.js'
import 'glightbox/dist/css/glightbox.css'
import Glightbox from 'glightbox'

export default {
  name: 'DashboardVideoBox',
  data: () => ({
    YOUTUBE_OLKYPAY_URL: 'https://www.youtube.com/watch?v=p15M1radHYs'
  }),
  async mounted() {
    const lightbox = Glightbox({
      // Options de configuration de Glightbox
      selector: '.glightbox'
    })
  }
}
</script>
