<template>
  <ion-page id="SupplierTabs">
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="home" href="/pay/supplier/home">
          <ion-icon :icon="home" />
          <ion-label>{{ $t('olkypay.tabs.homePage.home') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="transfer"
          href="/pay/supplier/transfer"
          :disabled="treatedEntity === 'card'"
        >
          <ion-icon :icon="logoEuro" />
          <ion-label>{{ $t('olkypay.tabs.homePage.transfer') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button v-if="treatedEntity === 'account'" tab="cards" href="/pay/supplier/cards">
          <ion-icon :icon="card" />
          <ion-label>{{ $t('olkypay.tabs.homePage.cards') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button v-else tab="cards" href="/pay/supplier/cards/card/detail">
          <ion-icon :icon="card" />
          <ion-label>{{ $t('olkypay.tabs.homePage.cards') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="cards" href="/pay/supplier/cards/card/detail" v-else>
          <ion-icon :icon="card" />
          <ion-label>{{ $t('olkypay.tabs.homePage.cards') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="rib" href="/pay/supplier/rib" :disabled="treatedEntity === 'card'">
          <ion-icon :icon="wallet" />
          <ion-label>{{ $t('olkypay.tabs.homePage.rib') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="more" href="/pay/supplier/more">
          <ion-icon :icon="apps" />
          <ion-label>{{ $t('olkypay.tabs.homePage.more') }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
    <loader></loader>
  </ion-page>
</template>

<script lang="ts">
import Loader from '../components/Loader.vue'

import {
  IonContent,
  IonPage,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonIcon
} from '@ionic/vue'
import {
  playCircle,
  radio,
  library,
  search,
  home,
  logoEuro,
  card,
  wallet,
  apps
} from 'ionicons/icons'
import { mapGetters } from 'vuex'

export default {
  name: 'SupplierTabs',
  components: {
    IonPage,
    IonContent,
    IonTabs,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonLabel,
    IonIcon,
    Loader
  },
  data() {
    return {
      home,
      playCircle,
      radio,
      library,
      search,
      logoEuro,
      card,
      wallet,
      apps
    }
  },
  mounted() {},
  computed: {
    ...mapGetters('loader', ['isSkeletonLoading']),
    ...mapGetters('supplier', ['treatedEntity'])
  }
}
</script>
