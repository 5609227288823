<template>
  <v-container>
    <v-row v-if="kycState.level < 100">
      <v-col>
        <v-alert density="compact" class="incompletedAlert">
          <template #prepend>
            <v-icon icon="mdi-alert-circle-outline" color="error"></v-icon>
          </template>
          <template #title>
            {{ $t('olkypass.KycoinPage.incompleteDataTitle') }}
          </template>
          <template #text>
            {{ $t('olkypass.KycoinPage.incompleteData') }}
          </template>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card id="KycoinPageHeaderBox" class="mx-auto elementCard" width="auto">
          <template #title>
            {{ $t('olkypass.KycoinPage.title') }}
          </template>
          <v-card-text class="elementData">
            <v-row>
              <v-col>
                <v-img
                  class="mx-auto"
                  height="50px"
                  width="162px"
                  :src="olkyKycoinFullLogoPath"
                ></v-img>
              </v-col>
              <v-col>
                <h1>
                  {{ kycoin.balance }}<span>{{ kycoin.balanceEuro }}</span>
                </h1>
              </v-col>
              <v-col>
                <v-btn
                  v-if="!loading && kycState.level === 100"
                  variant="flat"
                  height="auto"
                  class="text-none feedSoldeBtn"
                  prepend-icon="mdi-plus-circle"
                  size="medium"
                  @click="openBuyKycoinPage"
                >
                  {{ $t('olkypass.KycoinPage.feedSolde') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card id="KycoinPageTransactionBox" class="mx-auto elementCard" width="auto">
          <template #title>
            {{ $t('olkypass.KycoinPage.transactionBox') }}
          </template>
          <v-card-text class="elementData">
            <v-table>
              <thead>
                <tr>
                  <th class="text-left"></th>
                  <th class="text-left">{{ $t('olkypass.KycoinPage.table.Date') }}</th>
                  <th class="text-left">{{ $t('olkypass.KycoinPage.table.Operation') }}</th>
                  <th class="text-left">{{ $t('olkypass.KycoinPage.table.montant') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in kycoin.transaction"
                  :key="item.Type"
                  :class="['pa-2', index % 2 === 0 ? 'bg-row' : '']"
                >
                  <td v-if="item.FlowCredit == 'F'">
                    <v-icon class="mdi mdi-arrow-left-circle-outline debit"></v-icon>
                  </td>
                  <td v-else>
                    <v-icon class="mdi mdi-arrow-right-circle-outline credit"></v-icon>
                  </td>
                  <td>{{ format_date(item.Date) }}</td>
                  <td>{{ item.Type }} : {{ item.Counterparty }}</td>
                  <td :class="item.FlowCredit == 'F' ? 'debit' : 'credit'">
                    {{ item.formatedAmount }} KYC
                  </td>
                </tr>
              </tbody>
            </v-table>
            <v-row>
              <v-col>
                <!-- <p>P: {{ checkCurrentWeek() }}</p>
            <p>C: {{ currentStartWeek }}</p>-->
                <v-btn
                  variant="flat"
                  height="auto"
                  class="text-none viewMoreBtn"
                  size="medium"
                  @click="getMoreTransaction()"
                  :disabled="disable_more_btn"
                >
                  {{ $t('olkypass.KycoinPage.viewmore') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from '../store'
import store from '../store'
import ToolsMixins from '../mixins/ToolsMixins.js'
import NotificationsMixins from '@/app/olkypass/views/_deprecated/vue/mixins/NotificationsMixins'
import config from '@/config/index'
import _ from 'lodash'
export default {
  name: 'KycoinPage',
  mixins: [ToolsMixins, NotificationsMixins],
  data: () => ({
    olkyKycoinLogoPath: '/images/olkypass/kycoin-logo.svg',
    olkyKycoinFullLogoPath: '/images/olkypass/kycoin-full-logo.svg',
    currentStartWeek: '',
    page: 1,
    disable_more_btn: false
  }),
  async mounted() {
    await this.checkKycoinInit(this.kycoin)
    if (!this.kycoin || !this.kycoin.transaction || this.kycoin.transaction.length < 100) {
      this.disable_more_btn = true
    }
    await this.detectNaturalPersonIdentity(this.naturalPerson)
  },
  methods: {
    async getMoreTransaction() {
      this.disable_more_btn = false
      const inMemoryTransactions = _.cloneDeep(this.kycoin.transaction)
      this.page++

      await store.dispatch('playLoader')
      await store.dispatch('getAdditialKycoinTransaction', {
        page: this.page
      })
      await store.dispatch('stopLoader')
      if (inMemoryTransactions.length === this.kycoin.transaction.length) {
        this.disable_more_btn = true
        this.displayNotification(
          'success',
          this.$i18n.t('KypayPage.notifications.success.no_more_transactions')
        )
      }
    },
    openBuyKycoinPage() {
      const success = window.open(config.KYCOIN_BUY_EXTERNAL_PAGE, '_blank')
      if (!success) document.location = config.KYCOIN_BUY_EXTERNAL_PAGE
    }
  },
  computed: {
    ...mapGetters(['loading', 'kycoin', 'naturalPerson', 'kycState'])
  }
}
</script>
