import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { KYCIdentity } from '../kyc-identity/index.react'

type ModalIdentityProps = ModalProps

export const ModalKYCIdentity = ({ closable, onClose, ...props }: ModalIdentityProps) => (
  <Modal
    positionWrapperClassName={'h-full'}
    className={'sm:m-0 m-0 sm:w-full p-0 rounded-none h-full sm:h-max items-end sm:p-4 md:w-9/12'}
    maxWidthClassName={'max-w-5xl'}
    closable={closable}
    onClose={onClose}
    {...props}
  >
    <ModalContent title={''}>
      <KYCIdentity onValidated={onClose} />
    </ModalContent>
  </Modal>
)
