import { AddressCreate, AddressFullType } from '../types'

export const AddressConverters = {
  Create_FullDTO: (userUUID: string, create: AddressCreate): AddressFullType => ({
    Address1: create.address,
    ZipCode: create.zip,
    Country: create.country?.toString() ?? 'FR',
    City: create.city,
    Tag: create.tag,
    UUID: userUUID || '',
    language: create.language || 'fr',
    isPrincipalAddress: create.isPrincipalAddress || false
  })
}
