import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { SecurityApiClient } from '../api-client/api-client'
import { UpdateTraceParams, AddTraceResponse } from '../types'

export default function useUpdateTraceMutation(
  options?: UseMutationOptions<AddTraceResponse, Error, UpdateTraceParams>
) {
  return useMutation<AddTraceResponse, Error, UpdateTraceParams>({
    mutationFn: async (params: UpdateTraceParams) => {
      const res = await SecurityApiClient.updateDemandOfAuthentication(
        params.id,
        params.success,
        params.web_authn_signing_action_id,
        params.user_otp_input
      )
      console.log('useUpdateTraceMutation response : ', res)
      if (!res.success) {
        throw new Error(res.data)
      }
      return res.data
    },
    ...options
  })
}
