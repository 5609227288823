import { useMutation } from '@tanstack/react-query'
import { nifValidationResponse, ValidateNifMutationProps } from '../../types'
import { NifValidationApiClient } from '../api-client/api-client'

export const useNifValidation = () => {
  const getNifValidation = () =>
    useMutation<nifValidationResponse, Error, ValidateNifMutationProps>({
      mutationFn: async ({ nif, countryCode }: ValidateNifMutationProps) =>
        NifValidationApiClient.sendNifCode(nif, countryCode)
    })

  return { getNifValidation }
}
