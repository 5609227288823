import { defineStore } from 'pinia'
import type { StaticData, UserType, CredentialType } from './types'

export const UserStore = defineStore('UserStore', {
  state: (): { user: UserType | null; credential: CredentialType | null } => ({
    user: null,
    credential: null
  }),
  actions: {
    set(user: UserType | null) {
      this.user = user
    },
    setCredential(credential: CredentialType | null) {
      this.credential = credential
    }
  }
})

export const UserStaticDataStore = defineStore('UserStaticDataStore', {
  state: (): { data: StaticData | null } => ({ data: null }),
  actions: {
    set(data: StaticData | null) {
      this.data = data
    }
  }
})
