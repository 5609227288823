import { createVuetify } from 'vuetify'
import { VOtpInput } from 'vuetify/labs/VOtpInput'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#F4F5F4',
    primary: '#00AFEB',
    'primary-darken-1': '#CCEFFB',
    secondary: '#01ABE8',
    'secondary-darken-1': '#018786',
    error: '#EA001B',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    'on-background': '#3A3541',
    'on-surface': '#3A3541',
    'on-primary': '#FFFFFF'
  }
}

const myCustomDarkTheme = {
  dark: true,
  colors: {
    background: '#1A1536',
    surface: '#0E0C18',
    primary: '#292145',
    'primary-darken-1': '#FFFFFF',
    secondary: '#01ABE8',
    'secondary-darken-1': '#018786',
    error: '#EA001B',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    'on-background': '#FFFFFF',
    'on-surface': '#FFFFFF',
    'on-primary': '#FFFFFF',
    'surface-variant': '#FFFFFF'
  }
}

const vuetify = createVuetify({
  theme: {
    dark: true,
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
      myCustomDarkTheme
    }
  },
  components: {
    ...components,
    VOtpInput
  },
  directives,
  icons: {
    defaultSet: 'mdi'
  }
})

export default vuetify
