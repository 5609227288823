import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { SecurityApiClient } from '../api-client/api-client'
import { SendSensitiveEmailCodeResponse, SensitiveEmail } from '../types'
export default function useRequestSensitiveEmailMutation(
  options?: UseMutationOptions<SendSensitiveEmailCodeResponse, Error, SensitiveEmail>
) {
  const { t, i18n } = useTranslation(['pass'])

  return useMutation<SendSensitiveEmailCodeResponse, Error, SensitiveEmail>({
    mutationFn: async (args: SensitiveEmail) => {
      // @TODO detect language correctly
      const data = await SecurityApiClient.requestSensitiveEmail(
        args.email,
        i18n.language,
        args.actionType,
        args?.actionData
      )
      if (!data.success) {
        throw new Error(t('flow.id_control.error.check_email'))
      }
      return data
    },
    ...options
  })
}
