import { defineJsxComponent } from '@/jsx-utils'
import { BaseSmall, InfoSmall } from '../text/Text.tsx'
import './index.css'

export const StoreButtons = defineJsxComponent<{
  theme: 'apple-btn' | 'google-btn'
  src: string
  class?: string
}>(props => {
  const theme = props.theme || 'apple-btn'
  const text = props.theme === 'apple-btn' ? 'App Store' : 'Google Store'

  return () => <a
    href={props.src}
    target="_blank"
    class={
      'store-btns market-btn dark:bg-slate-800 dark:hover:bg-slate-900' +
      theme +
      ' ' +
      props.class
    }
    role="button"
  >
    <InfoSmall class="market-button-subtitle">Download on the</InfoSmall>
    <BaseSmall class="market-button-title">{text}</BaseSmall>
  </a>
})
