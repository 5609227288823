import { Modal, ModalContent } from '@/atoms-react/modal/index.react'
import { useTranslation } from 'react-i18next'
import React from 'react'

export const ModalDiscoverKYPAY = ({ ...props }) => {
  const { t } = useTranslation(['pass'], { useSuspense: false })
  return (
    <Modal {...props} maxWidthClassName="max-w-xl">
      <ModalContent title={t('olkypass.KypayPage.cardsExplanationsKypay.title.1')}>
        <div className={'opacity-1 flex flex-col'}>
          <div className={'w-full my-6'}>
            <div class="flex flex-col space-y-4 font-normal dark:text-white">
              <p className={'text-justify'}>
                {t('olkypass.KypayPage.cardsExplanationsKypay.content.1.1-1')}
              </p>
              <p className={'text-justify'}>
                {t('olkypass.KypayPage.cardsExplanationsKypay.content.1.1-2')}
              </p>
              <p className={'text-justify'}>
                {t('olkypass.KypayPage.cardsExplanationsKypay.content.1.1-3')}
              </p>
              <p>
                {t('olkypass.KypayPage.cardsExplanationsKypay.content.1.1-4')}
                <a
                  href={t('olkypass.KypayPage.cardsExplanationsKypay.content.1.1-5')}
                  target="_blank"
                  className={'text-sky-500 font-semibold'}
                >
                  {t('olkypass.KypayPage.cardsExplanationsKypay.content.1.1-5')}
                </a>
              </p>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}
