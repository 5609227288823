import messages from '@/locales/en.ts'
import axios from 'axios'
import { createI18n, type I18n } from 'vue-i18n'
import i18nReactService from './i18n-react'

let i18n: I18n | null = null

const loadedLanguages = ['en'] // our default language that is preloaded

const setI18nLanguage = (langStr: string) => {
  i18n!.global.locale = langStr
  axios.defaults.headers.common['Accept-Language'] = langStr
  document.querySelector('html')?.setAttribute('lang', langStr)
  i18nReactService.changeLanguage(langStr)
  return langStr
}

export const getLanguage = () => {
  const navigatorLang = navigator.language.slice(0, 2)?.toLocaleLowerCase()
  const localStorageLang = localStorage.getItem('i18n')
  const languageQuery = new URLSearchParams(document.location.search).get('language')
  return languageQuery || localStorageLang !== '' && localStorageLang || navigatorLang || 'en'
}

export const setLanguage = async (lang?: string | string[]) => {
  const navigatorLang = navigator.language.slice(0, 2)?.toLocaleLowerCase()
  const langStr = (typeof lang === 'string' ? lang : lang?.[0]) || getLanguage()
  if (langStr !== navigatorLang && langStr !== '') {
    localStorage.setItem('i18n', langStr)
  } else {
    localStorage.removeItem('i18n')
  }

  // If the same language
  if (i18n!.global.locale === langStr) {
    return Promise.resolve(setI18nLanguage(langStr))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(langStr)) {
    return Promise.resolve(setI18nLanguage(langStr))
  }

  // If the language hasn't been loaded yet
  try {
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */
      `../../locales/${langStr}.ts`
    )
    i18n!.global.setLocaleMessage(langStr, messages.default)
    loadedLanguages.push(langStr)
    return setI18nLanguage(langStr)
  } catch (err) {
    console.error(err)
    return Promise.resolve(setI18nLanguage('en'))
  }
}

export const init = () => {
  i18n = createI18n({
    locale: 'en',
    fallbackLocale: ['en', 'fr'],
    messages: { en: messages },
    allowComposition: true
  })
  setLanguage()
  return i18n!
}

export default () => i18n!
