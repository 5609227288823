<template>
  <ion-page id="IbanCheckPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          default-href="/pay/supplier/transfer"
          :icon="caretBackSharp"
        ></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1 v-if="currentBeneficiary.id">
          {{ $t('olkypay.labels.beneficiariesPage.edit.header') }}
        </h1>
        <h1 v-else>{{ $t('olkypay.labels.beneficiariesPage.new.header') }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row class="border-row border-1 border-radius-md min-height-56 mx-1 mt-3">
          <ion-col>
            <ion-item lines="none" :class="ibanBankInfo.iban ? 'text-dark' : 'text-gray'">
              <ion-icon
                v-if="ibanBankInfo.status === 'VALID' && !isDataLoading"
                slot="end"
                :icon="checkmarkCircle"
                color="success"
              ></ion-icon>
              <ion-icon
                v-if="ibanBankInfo.status === 'INVALID_IBAN' && !isDataLoading"
                slot="end"
                :icon="closeCircleSharp"
                color="danger"
              ></ion-icon>
              <ion-input
                v-model="ibanBankInfo.iban"
                :label="$t('olkypay.labels.IbanCheckPage.iban')"
                label-placement="floating"
                @keydown="resetIbanInfo"
              ></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row v-if="ibanBankInfo.status === 'INVALID_IBAN'">
          <ion-col>
            <div class="card border border-radius-lg bg-gray-200 card-plain">
              <div class="flex-column">
                <h4 class="text-dark text-md p-1">
                  {{ $t('olkypay.labels.IbanCheckPage.error_message') }}
                </h4>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <div>
          <div v-if="isDataLoading" class="text-center mt-4">
            <ion-spinner name="bubbles" class="text-center"></ion-spinner>
            <br />
            <ion-label class="text-center text-gray text-md">{{
              $t('olkypay.labels.IbanCheckPage.loading_data')
            }}</ion-label>
          </div>
        </div>
        <div>
          <div v-if="ibanBankInfo.bic && ibanBankInfo.iban !== ''" class="iban-info">
            <ion-row class="border-row border-1 border-radius-md min-height-56 mx-1 mt-3">
              <ion-col>
                <ion-item lines="none">
                  <ion-icon slot="end" :icon="checkmarkCircle" color="success"></ion-icon>
                  <ion-input
                    v-model="ibanBankInfo.bic"
                    :label="$t('olkypay.labels.IbanCheckPage.bic')"
                    label-placement="floating"
                    :readonly="true"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row class="border-row border-1 border-radius-md min-height-56 mx-1 mt-3">
              <ion-col>
                <ion-item lines="none">
                  <ion-icon slot="end" :icon="checkmarkCircle" color="success"></ion-icon>
                  <ion-input
                    v-model="ibanBankInfo.name"
                    :label="$t('olkypay.labels.IbanCheckPage.bank')"
                    label-placement="floating"
                    :readonly="true"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row class="border-row border-1 border-radius-md min-height-56 mx-1 mt-3">
              <ion-col>
                <ion-item lines="none">
                  <ion-icon slot="end" :icon="checkmarkCircle" color="success"></ion-icon>
                  <ion-input
                    v-model="ibanBankInfo.countryName"
                    :label="$t('olkypay.labels.IbanCheckPage.country')"
                    label-placement="floating"
                    :readonly="true"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
          </div>
        </div>
        <!--        <ion-row class="mt-3">
                  <ion-col class="text-end" size="7">
                    <button class="btn bg-gradient-info mb-0 ms-2" @click="handleIbanCheck()" :disabled="!ibanBankInfo.iban" v-if="ibanBankInfo.status !== 'VALID' && !isDataLoading">
                      {{ $t('olkypay.buttons.IbanCheckPage.verify') }}
                    </button>
                    <button class="btn bg-gradient-info mb-0 ms-2" @click="handleNextStep()" :disabled="!ibanBankInfo.iban" v-if="ibanBankInfo.status === 'VALID' && !isDataLoading">
                      {{ $t('olkypay.buttons.IbanCheckPage.add') }}
                    </button>
                  </ion-col>
                </ion-row>-->
      </ion-grid>

      <ion-fab slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button
          v-if="ibanBankInfo.status !== 'VALID' && !isDataLoading"
          color="blue_olky"
          :disabled="!ibanBankInfo.iban"
          @click="handleIbanCheck()"
        >
          <ion-icon :icon="reload"></ion-icon>
        </ion-fab-button>
        <ion-fab-button
          v-if="ibanBankInfo.status === 'VALID' && !isDataLoading"
          color="blue_olky"
          :disabled="!ibanBankInfo.iban"
          @click="handleNextStep()"
        >
          <ion-icon :icon="chevronForwardCircle"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { BeneficiaryService } from '../../../services/beneficiary.service'
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonBackButton,
  IonButtons,
  IonInput,
  IonItem,
  IonIcon,
  IonLabel,
  IonFab,
  IonFabButton,
  IonSpinner
} from '@ionic/vue'
import {
  caretBackSharp,
  chevronForwardCircle,
  checkmarkCircle,
  closeCircleSharp,
  refresh,
  reload
} from 'ionicons/icons'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'IbanCheckPage',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButton,
    IonBackButton,
    IonButtons,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonIcon,
    IonLabel,
    IonFab,
    IonFabButton,
    IonSpinner
  },
  data() {
    return {
      caretBackSharp,
      chevronForwardCircle,
      checkmarkCircle,
      closeCircleSharp,
      refresh,
      reload,
      ibanBankInfo: {},
      isDataLoading: false
    }
  },
  methods: {
    ...mapActions('loader', ['playLoader', 'stopLoader']),
    gotToRouter(router_name) {
      this.$router.push({ name: router_name })
    },
    async handleIbanCheck() {
      this.isDataLoading = true
      await BeneficiaryService.ibanCheck(this.ibanBankInfo.iban)
        .then(async response => {
          this.ibanBankInfo.status = response.status
          if (response.status === 'VALID') {
            // FR7619733000010100000110702
            await BeneficiaryService.ibanGetBank(response.bic).then(result => {
              this.ibanBankInfo.bic = result.bic
              this.ibanBankInfo.countryName = result.countryName
              this.ibanBankInfo.name = result.name
            })
          }
          this.isDataLoading = false
        })
        .catch(err => {})
        .finally(() => {
          this.isDataLoading = false
        })
    },
    resetIbanInfo() {
      const iban = this.ibanBankInfo.iban
      this.ibanBankInfo = {}
      this.ibanBankInfo.iban = iban
    },
    handleNextStep() {
      this.currentBeneficiary.iban = this.ibanBankInfo.iban
      this.currentBeneficiary.supplierId = this.currentSupplier.id
      this.currentBeneficiary.typeBeneficiary = 1
      if (!this.currentBeneficiary.properties) {
        this.currentBeneficiary.properties = {}
      }
      if (!this.currentBeneficiary.fullName) {
        this.currentBeneficiary.fullName =
          this.currentBeneficiary.lastName + ' ' + this.currentBeneficiary.firstName
      }
      this.currentBeneficiary.properties.bic = this.ibanBankInfo.bic
      this.ibanBankInfo = {}
      this.$router.push({ name: 'BeneficiarySummarySubTabRouter' })
    }
  },
  async mounted() {
    this.ibanBankInfo = {}
    if (this.currentBeneficiary.iban) {
      this.ibanBankInfo = { iban: this.currentBeneficiary.iban }
      await this.handleIbanCheck()
    }
  },
  computed: {
    ...mapGetters('beneficiary', ['currentBeneficiary']),
    ...mapGetters('supplier', ['currentSupplier'])
  }
}
</script>

<style scoped></style>
