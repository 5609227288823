<template>
  <div style="display: flex; flex-direction: row">
    <v-otp-input
      ref="otpInput"
      v-model:value="challenge"
      input-classes="otp-input"
      separator="-"
      :num-inputs="6"
      :should-auto-focus="true"
      input-type="letter-numeric"
      :conditional-class="['one', 'two', 'three', 'four', 'five', 'six']"
      :placeholder="['*', '*', '*', '*', '*', '*']"
      @on-complete="handleOnComplete"
    />
  </div>
</template>

<script lang="ts">
import VOtpInput from 'vue3-otp-input'

export default {
  name: 'CustomOTP',
  components: {
    VOtpInput
  },
  data() {
    return {
      challenge: ''
    }
  },
  methods: {
    handleOnComplete(value) {
      this.$emit('complete-write-otp', value)
    }
  }
}
</script>

<style></style>
