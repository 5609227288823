import { Routes } from '@/features/router'
import {
  EyeIcon,
  IdentificationIcon,
  CreditCardIcon,
  DevicePhoneMobileIcon
} from '@heroicons/react/20/solid'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { MenuItem } from './index.react'
import { ChevronLeftIcon, DocumentIcon, EnvelopeIcon, HomeIcon } from '@heroicons/react/24/solid'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { CardTitle, Info } from '@/atoms-react/text/Text.react'
import { useMediaQuery } from 'usehooks-ts'

type MenuAccountProps = {
  open: boolean
  onClose: () => void
} & React.ComponentPropsWithoutRef<'div'>

export const MenuAccount = ({ open, onClose, ...props }: MenuAccountProps) => {
  const { t } = useTranslation(['pass'])
  const { company, id } = useCompanyContext()
  const companyName = company?.legal_name || ''
  const isNotMobile = useMediaQuery('(min-width: 768px)')

  return <div
    className={twMerge(
      'absolute bg-white dark:bg-slate-950 dark:border-slate-900 lg:relative border-r border-slate-50 h-full w-64 lg:w-80 shrink-0 px-4 lg:px-8 py-6',
      '-translate-x-full lg:translate-x-0 transition-all dark:',
      'overflow-auto z-50',
      company && 'dark bg-slate-950 border-slate-900',
      open ? 'translate-x-0' : ''
    )}
    {..._.omit(props, 'className')}
  >
    <div
      className={twMerge('flex gap-x-1 items-center mb-4 cursor-pointer', isNotMobile && 'h-12')}
      onClick={onClose}
    >
      <ChevronLeftIcon className="h-5 w-5 text-black dark:text-white" />
      <CardTitle className="block">
        {!id
          ? t('olkypass.mainPage.navigation_menu.link.my_pass')
          : t('olkypass.mainPage.navigation_menu.link.company_olkypass', {
              company: companyName
            })}
      </CardTitle>
    </div>
    {isNotMobile && <Info className="block mt-10"></Info>}
    <MenuItem to={Routes.account_identity} icon={p => <IdentificationIcon {...p} />}>
      {t('olkypass.mainPage.navigation_account_menu.link.identity')}
    </MenuItem>
    {!company && <>
        <MenuItem to={Routes.account_alias_documents} icon={p => <DocumentIcon {...p} />}>
          Documents
        </MenuItem>
        <MenuItem to={Routes.account_alias_email} icon={p => <EnvelopeIcon {...p} />}>
          {t('olkypass.mainPage.navigation_account_menu.link.alias_email')}
        </MenuItem>

        <MenuItem to={Routes.account_alias_address} icon={p => <HomeIcon {...p} />}>
          {t('olkypass.mainPage.navigation_account_menu.link.alias_address')}
        </MenuItem>

        <MenuItem to={Routes.account_alias_mobile} icon={p => <DevicePhoneMobileIcon {...p} />}>
          {t('olkypass.mainPage.navigation_account_menu.link.alias_mobiles')}
        </MenuItem>
        <MenuItem to={Routes.account_alias_payments} icon={p => <CreditCardIcon {...p} />}>
          {t('olkypass.mainPage.navigation_account_menu.link.alias_payments')}
        </MenuItem>
        <MenuItem to={Routes.account_alias_public} icon={p => <EyeIcon {...p} />}>
          {t('olkypass.mainPage.navigation_account_menu.link.alias_public')}
        </MenuItem>
      </>}
  </div>
}
