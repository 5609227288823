import { Button } from '@/atoms-react/button/Button.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { formatNumber } from '@/features/utils/functions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Routes } from '@/features/router'
import Icon from '@/atoms-react/Icon/Icon.react'
import Image from '@/atoms-react/image/Image.react'
import { KycoinIcon } from '@/atoms-react/crypto-icon/KycoinIcon.react'

export const FormBuy = ({ config }) => {
  const { t } = useTranslation(['wallet'])
  const [crypto, setSelectedCrypto] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const [selectedCost, setSelectedCost] = useState(0)
  const [amount, setAmount] = useState<string>(0)
  const [price, setPrice] = useState<string>(0)

  // Fonction pour convertir une chaîne de caractères en nombre
  const parseNumber = (amount: string) => parseFloat(amount.replace(',', '.').replace(/\s/g, ''))
  const onAmountChange = (amount: string) => {
    setAmount(amount)
    const calculatedPrice = parseNumber(amount) * selectedCost
    console.log(calculatedPrice)
    setPrice(calculatedPrice.toString())
  }
  const onPriceChange = (price: string) => {
    setPrice(price)
    const calculatedAmount = parseNumber(price) / selectedCost
    console.log(calculatedAmount)
    setAmount(calculatedAmount.toString())
  }

  useEffect(() => {
    if (config.data && config.data.length > 0) {
      setSelectedCrypto(config.data[0].symbol)
      setSelectedImage(config.data[0].image)
      setSelectedCost(config.data[0].current_price)
    }
  }, [config.data])
  const kypayAmount =
    t('wallet.buy.form.kypay_current') + ' ' + formatNumber(config.kypay.amount / 100) + config.unit

  return (
    <>
      <div className="flex flex-col w-full">
        <div className={'flex flex-row space-y-0 w-full items-end'}>
          <InputLabel
            className={'w-full rounded-e-none text-end'}
            label={t('wallet.buy.form.buy_amount')}
            value={amount}
            onChange={onAmountChange}
          />
          <Dropdown
            value={crypto}
            onChange={(e) => {
              setSelectedCrypto(e.key)
              setSelectedImage(e.image)
              setSelectedCost(e.amount)
            }}
            className={'w-full'}
            dropdownClass={
              'dark:bg-gray-100 dark:text-slate-300 dark:hover:bg-gray-100 dark:border-gray-100 rounded-sm rounded-s-none'
            }
            items={
              config.data?.map((item) => ({
                value: item.name,
                key: item.symbol.toUpperCase(),
                amount: item.current_price,
                image: item.image,
                icon: <Icon path={item.image} />
              })) ?? []
            }
          />
        </div>
        <div className={'text-end py-4'}>
          {t('wallet.buy.form.value_current')} {crypto} :{' '}
          <span className="text-blue-500">
            {formatNumber(selectedCost)} {config.unit}
          </span>
        </div>
        <div className={'flex flex-row space-y-0 w-full items-end'}>
          <InputLabel
            className={'w-full rounded-e-none text-end'}
            label={t('wallet.buy.form.kpay_amount')}
            value={price}
            onChange={onPriceChange}
          />
          <Dropdown
            value={'kypay'}
            onChange={() => {}}
            className={'w-full'}
            dropdownClass={
              'dark:bg-gray-100 dark:text-slate-300 dark:hover:bg-gray-100 dark:border-gray-100 rounded-sm rounded-s-none'
            }
            items={[
              {
                value: kypayAmount,
                key: 'kypay',
                icon: <KycoinIcon className="w-6 h-6" />
              }
            ]}
            disabled
          />
        </div>
        <div className={'text-end py-4'}>
          <Link to={Routes.wallet_dashboard} className="underline underline-offset-1">
            {t('wallet.buy.form.add_euro_kypay')}
          </Link>
        </div>
        <div className={'flex flex-row space-y-0 w-full items-end'}>
          <Dropdown
            label={t('wallet.buy.form.order_type')}
            value="store"
            className={'w-full'}
            dropdownClass={
              'dark:bg-gray-100 dark:text-slate-300 dark:hover:bg-gray-100 dark:border-gray-100 rounded-sm rounded-e-none'
            }
            items={[
              {
                value: t('wallet.buy.form.store_type'),
                key: 'store'
              }
            ]}
          />
          <InputLabel
            className={'w-full rounded-s-none text-center'}
            value={formatNumber(selectedCost) + ' ' + config.unit}
            disabled
            suffixIcon={() => (
              <Image src={selectedImage} alt={crypto} className={'w-6 h-6 inline-block'} />
            )}
          />
        </div>

        <div className={'flex flex-row space-y-0 w-full items-center'}>
          <div className="items-start flex flex-col w-full">
            <div className={'text-start pt-12'}>
              {t('wallet.buy.form.commission')} <span className="text-blue-500">1%</span>
            </div>
            <div className={'text-start py-4'}>
              {t('wallet.buy.form.final_rate')} {crypto} ={' '}
              <span className="text-blue-500">
                {formatNumber(selectedCost)} {config.unit}
              </span>
            </div>
            <Link
              to={Routes.wallet_dashboard}
              className="text-blue-500 underline underline-offset-1"
            >
              {t('wallet.buy.form.livraison')}
            </Link>
          </div>
          <div className="justify-end">
            <Button theme="primary" size="lg" className="flex">
              {t('wallet.buy.form.button')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
