import { FormBuy } from './form-buy.react'
import { FormSell } from './form-sell.react'
import { FormTransfer } from './form-transfer.react'
import { FormConvert } from './form-convert.react'

export const FormWallet = ({ config }) => (
  <>
    {config && config.type === 'buy' && <FormBuy config={config} />}

    {config && config.type === 'sell' && <FormSell config={config} />}

    {config && config.type === 'convert' && <FormConvert config={config} />}

    {config && config.type === 'transfer' && <FormTransfer config={config} />}
  </>
)
