import { useUser } from '@/features/user/hooks/use-user'
import { useCompanyContext } from './use-company-context'
import { KYCStatusType } from '@/features/kyc/types'
import { useKYC } from '@/features/kyc/hooks/use-kyc'

export const useKYCContext = () => {
  const { companyDetail } = useCompanyContext()
  const { useUserProfile } = useUser()
  const { data: user, refetch: refetchUserProfile } = useUserProfile()

  const { KYCStateQuery } = useKYC()
  const { data: kycSessionStatus, refetch: refetchKycSessionStatus } = KYCStateQuery(
    companyDetail
      ? (companyDetail?.data?.company?.identity_uuid ?? null)
      : (user?.naturalPerson.UUID ?? null)
  )

  const refetchKYCContext = async () =>
    await Promise.all([refetchUserProfile(), refetchKycSessionStatus()])
  const kYCStatus = user?.olkyPassLevel.olkypass_status
  const kYCStatusAudit = user?.olkyPassLevel.olkypass_status_under_audit
  
  let status: KYCStatusType = KYCStatusType.DECLARATIVE
  let nextStatus: KYCStatusType | null = null
  if (kYCStatus)
    status =
      KYCStatusType[
        kYCStatus.english_name.toLocaleUpperCase().replace(' ', '_') as keyof typeof KYCStatusType
      ]

  if (kYCStatusAudit)
    nextStatus =
      KYCStatusType[
        kYCStatusAudit.english_name
          .toLocaleUpperCase()
          .replace(' ', '_') as keyof typeof KYCStatusType
      ]

  let auditPending = false
  if (
    nextStatus &&
    status !== nextStatus &&
    (kYCStatus?.level as number) < (kYCStatusAudit?.level as number)
  )
    auditPending = true

  if (nextStatus && (kYCStatus?.level as number) >= (kYCStatusAudit?.level as number)) {
    auditPending = false
  }
  return {
    kycSessionStatus,
    status,
    refetchKYCContext,
    nextStatus,
    auditPending,
    kycLevel: kYCStatus?.level,
    kycAuditLevel: kYCStatusAudit?.level
  }
}
