import { fetchServer } from '@/features/utils/fetch'
import { CreateBankAccountResponse } from '../types'

export class BankAccountAPIClient {
  static async create(country: 'FR' | 'LU'): Promise<CreateBankAccountResponse> {
    const response = await fetchServer('/user/bank_account/create?country=' + country, {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: {}
    })

    if (!response.ok) throw new Error()
    const json: { success: boolean; iban?: string } = await response.json()

    return json
  }
}
