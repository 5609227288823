import { useToast } from 'vue-toast-notification'
import { CollaboratorStore } from '../store'
import { useI18n } from 'vue-i18n'
import { CollaboratorApiClient } from '@/features/company/api-client/collaborator-api-client'
import type { LINKNPLEType } from '@/features/company/types'

export const useCollaborator = () => {
  const { t } = useI18n()
  const toast = useToast()
  const collaboratorStore = CollaboratorStore()

  /**
   *
   */
  const loadCollaborators = async (identity_uuid: string | string[]) => {
    collaboratorStore.setLoading(true)
    const { success, data } = await CollaboratorApiClient.fetchListOfCollaborators(identity_uuid)
    if (success && data) {
      collaboratorStore.set(data)
    }
    collaboratorStore.setLoading(false)
  }

  const searchCollaborator = async (keyword: string) => {
    const { success, data } = await CollaboratorApiClient.searchAboutCollaborator(keyword)
    if (success && data) {
      return data
    }
    return null
  }
  const updateCollaborator = async (collaborator: LINKNPLEType) => {
    collaboratorStore.setLoading(true)
    const { success, data } = await CollaboratorApiClient.editCollaborator(collaborator)
    if (success && data) {
      toast.success(t('olkypass.companyPage.notifications.success.update_company'))
      return true
    } else {
      toast.error(t('olkypass.companyPage.notifications.error.update_company'))
    }
    collaboratorStore.setLoading(false)
    return false
  }
  return {
    collaboratorStore,
    loadCollaborators,
    searchCollaborator,
    updateCollaborator
  }
}
