import Text from '@/atoms-react/text/Text.react.tsx'
import { twMerge } from 'tailwind-merge'

type TextWithIconProps = {
  children?: React.ReactNode
  conditionValidate?: boolean
  suffixIcon?: React.ReactNode | any
  iconColor?: string
  textColor?: string
} & React.ComponentPropsWithoutRef<'div'>

export default function TextWithIcon(props: TextWithIconProps) {
  const { iconColor = 'text-blue-500' } = props

  const conditionStyle =
    props.conditionValidate !== undefined
      ? props.conditionValidate
        ? 'text-green-500'
        : 'text-slate-400'
      : ''

  return <div className={twMerge('flex gap-2', props.className)}>
    <props.suffixIcon className={`${conditionStyle} w-6 h-6 min-w-6 min-h-6 ` + iconColor} />
    <Text type="info" className={`${conditionStyle} font-medium ` + props.textColor}>
      {props.children}
    </Text>
  </div>
}
