import { fetchServer } from '@/features/utils/fetch'
import { getJwt } from '@/features/auth/utils'
import type {
  CheckEmailExistsResponse,
  CheckLoginResponse,
  LoginResponse,
  ResetPasswordRequest
} from '../types'

export class AuthApiClient {
  static async checkExistingEmail(email: string): Promise<CheckEmailExistsResponse> {
    const response = await fetchServer(`/login/_check_existing_mail`, {
      method: 'POST',
      reloadOn401: false,
      mainRoot: true,
      body: { username: email }
    })
    const res = await response.json()
    return res.data || { existStatus: false, is2faActivated: false, existAsAlias: false }
  }

  static async checkLogin(email: string, password: string): Promise<CheckLoginResponse> {
    const response = await fetchServer(`/_oauth_login_check`, {
      method: 'POST',
      reloadOn401: false,
      mainRoot: true,
      body: { username: email, password }
    })

    if (!response.ok) throw new Error('Error checkLogin')

    const res = await response.json()
    return res.data || { two_fa_required: false, token: null, login: false }
  }

  static async login(email: string, password: string, challenge: string): Promise<LoginResponse> {
    const response = await fetchServer(`/_oauth_two_fa_check`, {
      method: 'POST',
      reloadOn401: false,
      mainRoot: true,
      body: { username: email, password, challenge }
    })

    if (!response.ok) {
      throw new Error('error')
    }

    const res = await response.json()
    return res.data
  }

  static async forgetPasswordLink(email: string, captcha_token: string, language: string) {
    const response = await fetchServer(`/forgot_password/send_link`, {
      method: 'POST',
      reloadOn401: false,
      body: { email, captcha_token, language }
    })
    const res = await response.json()
    return res as { message: string }
  }

  static async resetPassword(credentials: ResetPasswordRequest) {
    const response = await fetchServer(`/forgot_password/save_new`, {
      method: 'POST',
      reloadOn401: false,
      body: { credentials }
    })
    const res = await response.json()
    return res as { message: string }
  }

  static async refresh() {
    const currentJwt = getJwt()
    if (!currentJwt.token) {
      return { token: null }
    }
    const response = await fetchServer(`/refresh_jwt`)
    const data = await response.json()
    return data as { token: string }
  }
}
