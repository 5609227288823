<template>
  <div class="card card-plain">
    <ion-row>
      <ion-col size="9">
        <h4 class="text-dark mb-1 mt-0">{{ transaction.line1 }}</h4>
        <span class="text-xs text-gray">{{ transaction.line2 }}</span>
      </ion-col>

      <ion-col size="3">
        <div v-if="transaction.debit" class="text-danger text-sm">
          <span
            >- €
            {{
              new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(transaction.debit / 100)
            }}</span
          >
        </div>
        <div v-if="transaction.credit" class="text-success text-sm">
          <span
            >+ €
            {{
              new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(transaction.credit / 100)
            }}</span
          >
        </div>
      </ion-col>
    </ion-row>
    <hr class="horizontal dark mt-3 mb-2" />
  </div>
</template>

<script lang="ts">
import { IonRow, IonCol, IonIcon, IonImg } from '@ionic/vue'
import {
  caretDownCircleOutline,
  caretUpCircleOutline,
  cardOutline,
  arrowDownSharp,
  arrowUpSharp
} from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Transaction',
  components: {
    IonRow,
    IonCol,
    IonIcon,
    IonImg
  },
  props: ['transaction'],
  data() {
    return {
      displayed_card: false,
      caretDownCircleOutline,
      caretUpCircleOutline,
      cardOutline,
      arrowDownSharp,
      arrowUpSharp
    }
  },
  methods: {}
}
</script>

<style scoped></style>
