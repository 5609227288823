import { Line } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'
import { twMerge } from 'tailwind-merge'
import { formatNumber } from '@/features/utils/functions'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  Filler
)

export const LineChart = ({ data, labels, config, className }) => {
  const dataChart = {
    labels,
    datasets: [
      {
        fill: config?.datasets?.fill ?? false,
        label: config?.datasets?.labelName,
        data,
        borderColor: 'rgba(0,175,235,1)',
        backgroundColor: 'rgba(0,175,235,0.2)',
        borderWidth: config?.datasets?.borderWidth ?? 0
      }
    ]
  }

  const optionsChart = {
    responsive: true,
    plugins: {
      legend: {
        display: config?.display?.legend ?? false
      },
      title: {
        display: config?.display?.title ?? false
      },
      datalabels: {
        display: config?.display?.datalabels ?? false,
        color: 'white',
        align: 'top',
        formatter: (value) => {
          return formatNumber(value) + (config?.unit || '€')
          /* if (context.dataIndex % 2 === 0) {
            return formatNumber(value) + (config?.unit || '€');
          } else {
              return '';
          } */
        }
      }
    },
    scales: {
      x: {
        display: false, // Cache l'axe x
        grid: {
          display: false // Cache la grille x
        }
      },
      y: {
        display: false, // Cache l'axe y
        grid: {
          display: false // Cache la grille y
        }
      }
    },
    layout: {
      padding: {
        top: config?.layout?.padding ?? 20,
        right: config?.layout?.padding ?? 20,
        bottom: config?.layout?.padding ?? 20,
        left: config?.layout?.padding ?? 20
      }
    }
  }

  return (
    <div className={twMerge('flex flex-col space-y-2 md:space-y-0 w-full items-center', className)}>
      <Line data={dataChart} options={optionsChart} />
    </div>
  )
}
