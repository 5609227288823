import _ from 'lodash'
import { AddressFullType, AliasEmail, NaturalPersonDTO } from '../user/types'
import { CompanyRCSDetailsEmpty } from '../company/types'

export type DateWithTimezone = {
  date: string
  timezone_type?: number
  timezone?: string
}

// Enum describing the DTO type sent to the server (encompasses the User and the Company entity at the same time)
export enum NaturalEntityDataType {
  AliasMobileDto = 'AliasMobileDto',
  AliasMailDto = 'AliasMailDto',
  AliasIBANDto = 'AliasIBANDto',
  AliasWalletDto = 'AliasWalletDto',
  AliasCardDto = 'AliasCardDto',
  ActiviteDto = 'ActiviteDto',
  CSPDto = 'CSPDto',
  CountryDto = 'CountryDto',
  AdresseDto = 'AdresseDto',
  NaturalPersonDto = 'NaturalPersonDto',
  LegalEntityDto = 'LegalEntityDto',
  TypePieceIdentiteDto = 'TypePieceIdentiteDto',
  PieceIdentiteDto = 'PieceIdentiteDto',
  LinkNPLEDto = 'LinkNPLEDto',
  OlkyPassLevelDto = 'OlkyPassLevelDto'
}

export enum NaturalEntityModifyActionType {
  UpdateAll = 'updateAll'
}

export type NaturalEntityModify = {
  action?: NaturalEntityModifyActionType.UpdateAll
  UUID: string
} & (
  | {
  typeDto?: undefined
} & NaturalPersonDTO
  | {
      typeDto: NaturalEntityDataType.AdresseDto
      Address: AddressFullType
    }
  | {
      typeDto: NaturalEntityDataType.AliasMailDto
      AliasEmail: AliasEmail
    }
  | {
      typeDto: NaturalEntityDataType.AliasMobileDto
      AliasMobile: any
    }
  | {
      typeDto: NaturalEntityDataType.AliasWalletDto
      AliasWallet: any
    }
  | {
      typeDto: NaturalEntityDataType.PieceIdentiteDto
      Document: any
    }
  | {
      typeDto: NaturalEntityDataType.AliasIBANDto
      AliasIBAN: any
    }
)

export enum TransactionType {
  Purchasing = 'Purchasing',
  Transfer = 'Transfer',
  Sale = 'Sale'
}

export enum FlowCreditType {
  Flow = 'F',
  Credit = 'C'
}

export type DateDTO = {
  date: string
  timezone: string
}

export enum CountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  EU = 'EU',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}

export type FormFieldProps = {
  required?: boolean
  visible?: boolean
  disabled?: boolean
  label?: string
  placeholder?: string
}

export type FormFieldGroup<Type> = {
  [Property in keyof Type | 'default']?: FormFieldProps | boolean | undefined
} & FormFieldProps

export type FormFieldRealGroup<Type> = {
  [Property in keyof Type]: FormFieldProps
}

export function getRealFieldGroup<T extends object>(
  fields: string[],
  fieldGroup: FormFieldGroup<T>
): FormFieldRealGroup<T> {
  const resultEntries = fields.map(field => [
    field,
    {
      // @ts-expect-error Types
      required: fieldGroup[field]?.required ?? fieldGroup?.required ?? true,
      // @ts-expect-error Types
      visible: fieldGroup[field]?.visible ?? fieldGroup?.visible ?? true,
      // @ts-expect-error Types
      disabled: fieldGroup[field]?.disabled ?? fieldGroup?.disabled ?? false,
      // @ts-expect-error Types
      label: fieldGroup[field]?.label ?? fieldGroup?.label ?? null,
      // @ts-expect-error Types
      placeholder: fieldGroup[field]?.placeholder ?? fieldGroup?.placeholder ?? null
    }
  ])

  return Object.fromEntries(resultEntries)
}

export function isStandardResponse<T extends object>(
  obj: T | StandardResponse<T>
): obj is StandardResponse<T> {
  return 'success' in obj && 'data' in obj
}

export function isStandardErrorResponse<T extends object>(
  obj: T | StandardErrorResponse
): obj is StandardErrorResponse {
  return 'success' in obj && obj.success === false
}

export function isCompanyRCSDetailsEmpty(
  obj: object | CompanyRCSDetailsEmpty
): obj is CompanyRCSDetailsEmpty {
  return !isStandardErrorResponse(obj) && 'legalInformation' in obj && _.isArray(obj.legalInformation)
}

export function isCompanyRCSDetails(
  obj: object | CompanyRCSDetailsEmpty
): obj is CompanyRCSDetailsEmpty {
  return !isStandardErrorResponse(obj) && 'legalInformation' in obj && _.isArray(obj.legalInformation)
}

export type StandardResponse<T> = {
  data: T
  success: boolean
}

export type StandardErrorResponse = {
  data: null
  success: false
}

export const StandardErrorResponse: StandardErrorResponse = { data: null, success: false }

export type PartialNaturalPerson = {
  FirstName?: string
  LastName?: string
}
