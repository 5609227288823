<template>
  <ion-page id="OrderDetailsPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          default-href="/pay/supplier/transfer"
          :icon="caretBackSharp"
        ></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1>{{ $t('olkypay.labels.transferPage.follow_up') }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row>
          <ion-col>
            <h2>{{ $t('olkypay.labels.orderDetailsPage.information.header') }}</h2>
          </ion-col>
        </ion-row>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{ $t('olkypay.labels.orderDetailsPage.information.list_keys.tiers_code') }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>

        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{ $t('olkypay.labels.orderDetailsPage.information.list_keys.full_name') }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>

        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{ $t('olkypay.labels.orderDetailsPage.information.list_keys.beneficiary_iban') }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>

        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{ $t('olkypay.labels.orderDetailsPage.information.list_keys.created_at') }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md">
                {{ formattedCreatedAt }}
              </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>

        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{ $t('olkypay.labels.orderDetailsPage.information.list_keys.nominal_amount') }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md">
                €
                {{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(currentOrder.nominalAmount / 100)
                }}
              </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{ $t('olkypay.labels.orderDetailsPage.information.list_keys.in_progress_amount') }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{ $t('olkypay.labels.orderDetailsPage.information.list_keys.wording') }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md">
                {{ currentOrder.comment }}
              </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{ $t('olkypay.labels.orderDetailsPage.information.list_keys.representation') }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{ $t('olkypay.labels.orderDetailsPage.information.list_keys.category') }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md">
                {{ currentOrder.category }}
              </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{ $t('olkypay.labels.orderDetailsPage.information.list_keys.order_type') }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{ $t('olkypay.labels.orderDetailsPage.information.list_keys.order_state') }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md">
                {{ formattedStatus }}
              </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{ $t('olkypay.labels.orderDetailsPage.information.list_keys.pay_limit') }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
        </div>
        <ion-row class="mt-4">
          <ion-col>
            <h2>{{ $t('olkypay.labels.orderDetailsPage.additional_information.header') }}</h2>
          </ion-col>
        </ion-row>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{
                  $t(
                    'olkypay.labels.orderDetailsPage.additional_information.list_keys.order_number'
                  )
                }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md">
                {{ currentOrder.id }}
              </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{
                  $t('olkypay.labels.orderDetailsPage.additional_information.list_keys.multiple')
                }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{ $t('olkypay.labels.orderDetailsPage.additional_information.list_keys.holder') }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{
                  $t(
                    'olkypay.labels.orderDetailsPage.additional_information.list_keys.beneficiary_address'
                  )
                }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{
                  $t(
                    'olkypay.labels.orderDetailsPage.additional_information.list_keys.structured_information'
                  )
                }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{
                  $t(
                    'olkypay.labels.orderDetailsPage.additional_information.list_keys.payer_validation'
                  )
                }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{ $t('olkypay.labels.orderDetailsPage.additional_information.list_keys.rule') }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{
                  $t(
                    'olkypay.labels.orderDetailsPage.additional_information.list_keys.transaction_immediate'
                  )
                }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{
                  $t('olkypay.labels.orderDetailsPage.additional_information.list_keys.lot_number')
                }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
          <hr class="horizontal dark text-gray" />
        </div>
        <div>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray text-sm">
                {{
                  $t('olkypay.labels.orderDetailsPage.additional_information.list_keys.date_type')
                }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-md"> - </ion-label>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { caretBackSharp, alertCircle, addCircle } from 'ionicons/icons'
import { mapGetters } from 'vuex'
import {
  IonHeader,
  IonTitle,
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonButton,
  IonBackButton,
  IonToolbar,
  IonButtons
} from '@ionic/vue'
export default {
  name: 'OrderDetailsPage',
  components: {
    IonHeader,
    IonTitle,
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonLabel,
    IonButton,
    IonBackButton,
    IonToolbar,
    IonButtons
  },
  data() {
    return {
      caretBackSharp
    }
  },
  mounted() {},
  computed: {
    ...mapGetters('transaction', ['currentOrder']),
    formattedCreatedAt() {
      return this.currentOrder.createdAt[0] +
      '-' +
      this.currentOrder.createdAt[1] +
      '-' +
      this.currentOrder.createdAt[2] +
      '  ' +
      this.currentOrder.createdAt[3] +
      ':' +
      this.currentOrder.createdAt[4]
    },
    formattedStatus() {
      switch (this.currentOrder.orderStatusId) {
        case 1:
          return this.$i18n.t('olkypay.labels.followUpPage.order_status.COMPLETED')
          break
        case 3:
          return this.$i18n.t('olkypay.labels.followUpPage.order_status.CANCELLED')
          break
        case 4:
          return this.$i18n.t('olkypay.labels.followUpPage.order_status.TO_VALIDATE')
          break
        case 5:
          return this.$i18n.t('olkypay.labels.followUpPage.order_status.IN_PROGRESS')
          break
        default:
          return '-'
      }
    }
  }
}
</script>

<style scoped></style>
