import { Button } from '@/atoms-react/button/Button.react'
import { Base, CardTitle } from '@/atoms-react/text/Text.react'
import config from '@/config'
import { Themes } from '@/types/theme'
import { useTranslation } from 'react-i18next'
import { BalanceCard } from './balance-card.react'

export type BalanceCardKycoinProps = {
  amount: string
  amountInEuro: string
  withButton?: boolean
  balanceType: 'euros' | 'onChain'
  className?: string
  disabled?: boolean
  loading?: boolean
}

export const BalanceCardKycoin = ({
  amount,
  amountInEuro,
  withButton,
  balanceType,
  className,
  disabled = false,
  loading = false
}: BalanceCardKycoinProps) => {
  const { t } = useTranslation(['wallet'])

  const openBuyKycoinPage = () => {
    const success = window.open(config.KYCOIN_BUY_EXTERNAL_PAGE, '_blank')
    if (!success) document.location = config.KYCOIN_BUY_EXTERNAL_PAGE
  }

  return <>
    <BalanceCard
      label={
        balanceType === 'euros'
          ? t('kycoin.balance_card.off_chain_balance')
          : t('kycoin.balance_card.on_chain_balance')
      }
      unit={balanceType === 'euros' ? '€' : 'KYCOIN'}
      unitProps={{ className: 'text-xl' }}
      color={balanceType === 'euros' ? '' : 'text-orange-500'}
      amount={amount}
      roundTo={balanceType === 'onChain' ? 0 : undefined}
      withButton={withButton}
      className={className}
      loading={!disabled && loading}
      disabled={disabled}
    >
      <CardTitle className="flex items-center font-bold">
        {!loading && <>
            <Base className={'mr-1 text-slate-500'}>
              {t('kycoin.balance_card.amount_euros_label')} {amountInEuro}
            </Base>
            <Base className={'ml-1 text-slate-500'}>€</Base>
          </>}
      </CardTitle>
      {balanceType === 'onChain' && withButton && <div className="flex gap-6 mt-6">
          <Button
            size="md"
            disabled={loading}
            theme={Themes.white}
            onClick={() => openBuyKycoinPage()}
            className={'w-max'}
          >
            {t('kypay.button.feed')}
          </Button>
          <div data-tooltip={t('kypay.info.coming_soon')}>
            <Button
              size="md"
              theme={Themes.white}
              onClick={() => openBuyKycoinPage()}
              disabled={true}
              className={'w-max'}
            >
              {t('kypay.button.sell')}
            </Button>
          </div>
        </div>}
    </BalanceCard>
  </>
}
