import { Routes } from '@/features/router'
import { Navigate, Routes as ReactRoutes, Route, useLocation } from 'react-router-dom'
import FlowRoot from './flow/index.react'
import { PassRoot } from './views/index.react'
import { useExposeReactRouter } from '@/features/utils/hooks/use-expose-react-router'
import { useEffect } from 'react'
import { useUser } from '@/features/user/hooks/use-user'
import { getLanguage } from '@/features/utils/i18n'
import { convert_naturalPerson_DTO } from '@/features/user/converters'
import { NaturalPersonDTO } from '@/features/user/types'

export const InitialRouter = () => {
  useExposeReactRouter()
  const { pathname } = useLocation()
  const { useEditProfile, useUserProfile } = useUser()
  const { mutateAsync: editProfile } = useEditProfile()
  const { data: user } = useUserProfile()

  useEffect(() => {
    if (user) {
      const len = getLanguage()
      if (
        !user.naturalPerson?.CodeLangue ||
        user.naturalPerson.CodeLangue !== len.toLocaleUpperCase()
      ) {
        const userForm: NaturalPersonDTO & { isDirty: boolean } = {
          ...convert_naturalPerson_DTO(user.naturalPerson),
          CodeLangue: len.toLocaleUpperCase(),
          isDirty: true
        }
        editProfile(userForm).catch((error) => {
          console.error('Error', error)
        })
      }
    }
  }, [user, pathname])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <ReactRoutes>
      <Route path={Routes.flow}>{FlowRoot()}</Route>
      <Route path={Routes.pass}>{PassRoot()}</Route>
      <Route path="*" element={<Navigate to={Routes.pass} />} />
      <Route path="" element={<Navigate to={Routes.pass} />} />
    </ReactRoutes>
  )
}
