<template>
  <v-col cols="12" sm="12" md="6" lg="4">
    <v-card class="mx-auto elementCard">
      <template #prepend>
        <v-icon color="white">mdi-bank-circle-outline</v-icon>
      </template>
      <template #title>
        {{ iban?.BankInfoDto?.Name }}
        <!--          <v-icon
              icon="mdi-map-marker-radius-outline"
              size="small"
          ></v-icon>-->
        {{ iban?.BankInfoDto?.CountryName }}
      </template>
      <v-card-text class="elementData">
        <p>
          {{ $t('olkypass.accountPage.ibans_section.input.institution') }}
          {{ iban?.BankInfoDto?.Institution }}
        </p>
        <p>{{ iban?.BankInfoDto?.Address }}</p>
        <p>
          {{ $t('olkypass.accountPage.ibans_section.input.iban') }}
          <strong>{{ iban.Iban }}</strong>
        </p>
        <p>
          {{ $t('olkypass.accountPage.ibans_section.input.bic') }}
          <strong>{{ iban.BIC }}</strong>
        </p>
        <v-row>
          <v-col>
            <v-switch
              v-model="iban.Actif"
              color="secondary"
              :label="$t('olkypass.accountPage.ibans_section._switch.active')"
              hide-details
              @change="savePartialData"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          density="comfortable"
          size="small"
          color="surface-variant"
          variant="tonal"
          icon="mdi-delete"
          :disabled="!checkDisabled"
          @click="deleteCurrent"
        ></v-btn>
      </v-card-actions>
    </v-card>
    <DialogConfirm ref="dialogConfirm"></DialogConfirm>
  </v-col>
</template>
<script>
import ToolsMixins from '../../mixins/ToolsMixins.js'
import { mapGetters } from '../../store'
import store from '../../store'
import DialogConfirm from '../DialogConfirm.vue'

export default {
  name: 'IbanChild',
  components: {
    DialogConfirm
  },
  mixins: [ToolsMixins],
  props: ['iban', 'inMemoryIban'],
  methods: {
    async deleteCurrent() {
      const setDeleteDataFunctionName =
        this.currentTreatedEntity === 'company' ? 'delCompanyAliasData' : 'delNPAliasData'
      if (
        await this.$refs.dialogConfirm.open(
          'accountPage.dialog.confirm.delete.header',
          'accountPage.dialog.confirm.delete.body'
        )
      ) {
        await store.dispatch('playLoader')
        await store.dispatch(setDeleteDataFunctionName, {
          UUID: this.naturalPerson.UUID,
          ItemUUID: this.iban.UUID,
          ItemType: 'AliasIBANDto'
        })
        if (this.currentTreatedEntity === 'company') {
          await store.dispatch('setCurrentCompany', { UUID: this.naturalPerson.UUID })
        } else {
          await store.dispatch('setCurrentNaturalPerson')
        }
        await store.dispatch('stopLoader')
      }
    },
    async savePartialData() {
      if (!this.iban.Actif) {
        this.iban.Public = false
      }
      if (!this.iban.Owner) {
        this.iban.Owner = this.naturalPerson.UUID
      }
      const setPartialDataFunctionName =
        this.currentTreatedEntity === 'company' ? 'setCompanyPartialData' : 'setNPPartialData'
      /*if (await this.$refs.dialogConfirm.open('accountPage.dialog.confirm.update.infos.header', 'accountPage.dialog.confirm.update.infos.body')) {*/
      await store.dispatch('playLoader')
      await store.dispatch(setPartialDataFunctionName, {
        typeDto: 'AliasIBANDto',
        UUID: this.naturalPerson.UUID,
        AliasIBAN: this.iban
      })
      if (this.currentTreatedEntity === 'company') {
        await store.dispatch('setCurrentCompany', { UUID: this.naturalPerson.UUID })
      } else {
        await store.dispatch('setCurrentNaturalPerson')
      }
      await store.dispatch('stopLoader')
      /*}*/
    }
  },
  computed: {
    ...mapGetters(['naturalPerson']),
    checkDisabled() {
      return !this.iban.Actif && !this.iban.Public
    }
  }
}
</script>

<style scoped></style>
