import React, { useEffect, useState } from 'react'
import Column from '@/atoms-react/column/Column.react'
import { LayoutTitle } from '../../../_layout/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { DocumentRow } from '@/molecules/document-row/index.react'
import { useUser } from '@/features/user/hooks/use-user'
import { Skeletton } from '@/atoms-react/skeletton/index.react'
import { DragAndDrop } from '../drag-drop/index.react'
import { Document } from '@/features/user/document/types'

import { DocumentViewer } from './document-viewer.react'
import { useDocument } from '@/features/user/document/hooks/use-document'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'

type AliasesDocumentsAppProps = React.ComponentPropsWithoutRef<'div'>

export const AliasesDocumentsApp = ({ ...props }: AliasesDocumentsAppProps) => {
  const { i18n, t } = useTranslation(['pass'])

  const { useUserProfile } = useUser()
  const { data: user } = useUserProfile()
  const [params] = useSearchParams()
  const { DocumentsDetailsQuery, UploadDocumentMutation } = useDocument()
  const {
    data: documents,
    isLoading: isLoadingDocuments,
    refetch
  } = DocumentsDetailsQuery(user?.naturalPerson?.UUID ?? null)
  const { mutateAsync: uploadDocument } = UploadDocumentMutation()
  const [loading, setLoading] = useState<boolean>(false)
  const [searchDocument, setSearchDocument] = useState('')
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null)

  const filteredDocuments = documents?.filter((document) =>
    document.typePerLanguage.fr.toLowerCase().includes(searchDocument.toLowerCase())
  )

  useEffect(() => {
    if (selectedDocument === null) {
      const previewUUID = params.get('preview')
      const found = documents?.find((doc) => doc.uuid === previewUUID)
      if (found) {
        setSelectedDocument(found)
      }
    }
  }, [documents, params])

  const handleDocumentClick = (document: Document) => {
    if (document.Document) {
      setSelectedDocument((prev) => (prev?.uuid === document.uuid ? null : document))
    } else {
      setSelectedDocument(null)
    }
  }

  const onUpload = async (files: FileList | null) => {
    if (!files || loading) {
      return
    }
    setLoading(true)
    const toBase64 = (file: File) =>
      new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve((reader.result as string).split(',')[1])
        reader.onerror = reject
      })
    const documentCountBeforeUpload = documents?.length || 0
    const base64 = await toBase64(files[0])
    await uploadDocument({
      ownerUUID: user?.naturalPerson?.UUID ?? '',
      language: i18n.language,
      document: {
        base64Doc: base64,
        fileFormat: files[0].type,
        fileName: files[0].name
      }
    })
    const { data: newDocuments } = await refetch()
    if ((newDocuments?.length || 0) > documentCountBeforeUpload) {
      toast.success(t('aliasDocumentsPage.successfully_added'))
    } else {
      toast.error(t('aliasDocumentsPage.error_import'))
    }
    setLoading(false)
  }

  return (
    <Column {...props} className="flex flex-col">
      <LayoutTitle>Documents</LayoutTitle>
      <div className="-mx-8 flex-1 h-full flex flex-col overflow-hidden">
        <div className="border-t-2 border-slate-50 flex-1 md:flex">
          {/* Documents list, drag & drop (left part) */}
          <div className="p-8 w-full flex-1 md:flex-1 md:border-r-2 border-slate-50">
            <DragAndDrop className="2xl:h-52" onValidated={onUpload} isLoading={loading} />
            <InputLabel
              placeholder={t('aliasDocumentsPage.search_document')}
              value={searchDocument}
              onChange={(value) => setSearchDocument(value)}
              className="mt-4 mb-3"
            />
            <div className="flex flex-col gap-y-4 overflow-y-auto">
              {filteredDocuments &&
                filteredDocuments.map((document, index) => (
                  <DocumentRow
                    document={document}
                    key={index}
                    className="rounded-sm"
                    isSelected={document.uuid === selectedDocument?.uuid ? true : false}
                    onClick={() => handleDocumentClick(document)}
                  />
                ))}
              {isLoadingDocuments &&
                [0, 1, 2].map((_, index) => <Skeletton key={index} className="mx-6 mb-1" />)}
            </div>
          </div>
          <div className="border-b-2 w-full md:hidden"></div>
          {/* Preview (right part) */}
          {selectedDocument && user && (
            <DocumentViewer
              user={user}
              document={selectedDocument}
              className="md:flex-1 min-h-[200px] flex flex-col"
            />
          )}
        </div>
      </div>
    </Column>
  )
}
