import { type RouteRecordRaw } from 'vue-router'
import LoginPage from './views/LoginPage.vue'
import OnBoardingPage from './views/OnBoardingPage.vue'
import ResetPasswordPage from './views/ResetPasswordPage.vue'
import TwoFaPage from './views/TwoFaPage.vue'
// supplier page and sub_tabs
import SuppliersPage from './views/SuppliersPage.vue'
import SupplierTabs from './views/SupplierTabs.vue'
import HomeTabPage from './views/tabs/HomePage.vue'
// transfer page and sub_tabs
import FollowUpPage from './views/subtabs/transfer/FollowUpPage.vue'
import OneTimeTransferPage from './views/subtabs/transfer/OneTimeTransferPage.vue'
import OrderDetailsPage from './views/subtabs/transfer/OrderDetailsPage.vue'
import RecurrentTransferPage from './views/subtabs/transfer/RecurrentTransferPage.vue'
import ChoiceTypeTransfer from './views/subtabs/transfer/steps/ChoiceTypeTransfer.vue'
import ReasonTransfer from './views/subtabs/transfer/steps/ReasonTransfer.vue'
import ValidationTransfer from './views/subtabs/transfer/steps/ValidationTransfer.vue'
import TransferTabPage from './views/tabs/TransferPage.vue'
// cards page and sub_tabs
import AutoLevelingCardPage from './views/subtabs/cards/AutoLevelingCard.vue'
import BlockCardPage from './views/subtabs/cards/BlockCardPage.vue'
import CardDetailPage from './views/subtabs/cards/CardDetailPage.vue'
import CreditCardPage from './views/subtabs/cards/CreditCardPage.vue'
import CardDisplayCodePage from './views/subtabs/cards/DisplayCodeCardPage.vue'
import OppositionCardPage from './views/subtabs/cards/OppositionCardPage.vue'
import CardsTabPage from './views/tabs/CardsPage.vue'
// rib page
import RibTabPage from './views/tabs/RibPage.vue'
// beneficiaries page and sub_tabs
import BeneficiariesPage from './views/subtabs/beneficiaries/BeneficiariesPage.vue'
import BeneficiarySummaryPage from './views/subtabs/beneficiaries/BeneficiarySummaryPage.vue'
import CUBeneficiary from './views/subtabs/beneficiaries/CUBeneficiaryPage.vue'
import IbanCheckPage from './views/subtabs/beneficiaries/IbanCheckPage.vue'
// more page and sub_tabs
import AuthenticationSettingsPage from './views/subtabs/settings/AuthenticationSettingsPage.vue'
import CodePinComplexityDetailsPage from './views/subtabs/settings/CodePinComplexityDetailsPage.vue'
import CodePinOnBoardingPage from './views/subtabs/settings/CodePinOnBoardingPage.vue'
import CodePinSavePage from './views/subtabs/settings/CodePinSavePage.vue'
import MoreTabPage from './views/tabs/MorePage.vue'

const prefix = '/pay'

export const OlkyPayRouterChildren: Array<RouteRecordRaw> = [
  {
    path: prefix + '/',
    redirect: prefix + '/suppliers'
  },
  {
    path: prefix + '/onboarding',
    name: 'OnBoardingRouter',
    component: OnBoardingPage,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: prefix + '/login',
    name: 'LoginRouter',
    component: LoginPage,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: prefix + '/twoFa',
    name: 'TwoFactoryRouter',
    component: TwoFaPage,
    meta: {
      public: true,
      onlyWhenAuthenticating: true
    }
  },
  {
    path: prefix + '/reset_password',
    name: 'ResetPassword',
    component: ResetPasswordPage,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: prefix + '/suppliers',
    component: SuppliersPage,
    name: 'SuppliersRouter'
  },
  {
    path: prefix + '/supplier',
    component: SupplierTabs,
    children: [
      {
        path: 'home',
        component: HomeTabPage,
        name: 'HomeTabRouter'
      },
      {
        path: 'transfer',
        component: TransferTabPage,
        name: 'TransferTabRouter'
      },
      {
        path: 'transfer/one_time',
        component: OneTimeTransferPage,
        name: 'OneTimeTransferSubTabRouter'
      },
      {
        path: 'transfer/one_time/choice_type',
        component: ChoiceTypeTransfer,
        name: 'ChoiceTypeTransferSubTabRouter'
      },
      {
        path: 'transfer/recurrent',
        component: RecurrentTransferPage,
        name: 'RecurrentTransferSubTabRouter'
      },
      {
        path: 'transfer/recurrent/validation',
        component: ValidationTransfer,
        name: 'ValidationTransferSubTabRouter'
      },
      {
        path: 'transfer/reason',
        component: ReasonTransfer,
        name: 'ReasonTransferSubTabRouter'
      },
      {
        path: 'transfer/validation',
        component: ValidationTransfer,
        name: 'ValidationTransferSubTabRouter'
      },
      {
        path: 'transfer/follow_up',
        component: FollowUpPage,
        name: 'FollowUpSubTabRouter'
      },
      {
        path: 'transfer/follow_up/order_details',
        component: OrderDetailsPage,
        name: 'OrderDetailsSubTabRouter'
      },
      {
        path: 'transfer/beneficiary/list',
        component: BeneficiariesPage,
        name: 'BeneficiariesSubTabRouter'
      },
      {
        path: 'transfer/beneficiary/new',
        component: CUBeneficiary,
        name: 'NewBeneficiarySubTabRouter'
      },
      {
        path: 'transfer/beneficiary/edit',
        component: CUBeneficiary,
        name: 'EditBeneficiarySubTabRouter'
      },
      {
        path: 'transfer/beneficiary/iban_check',
        component: IbanCheckPage,
        name: 'BeneficiaryIbanCheckSubTabRouter'
      },
      {
        path: 'transfer/beneficiary/summary',
        component: BeneficiarySummaryPage,
        name: 'BeneficiarySummarySubTabRouter'
      },
      {
        path: 'cards',
        component: CardsTabPage,
        name: 'CardsTabRouter'
      },
      {
        path: 'cards/card/block',
        component: BlockCardPage,
        name: 'CardBlockSubTabRouter'
      },
      {
        path: 'cards/card/opposition',
        component: OppositionCardPage,
        name: 'CardOppositionSubTabRouter'
      },
      {
        path: 'cards/card/auto_leveling',
        component: AutoLevelingCardPage,
        name: 'CardAutoLevelingSubTabRouter'
      },
      {
        path: 'cards/card/credit',
        component: CreditCardPage,
        name: 'CardCreditSubTabRouter'
      },
      {
        path: 'cards/card/display_code',
        component: CardDisplayCodePage,
        name: 'CardDisplayCodeSubTabRouter'
      },
      /*   {
                path: 'cards/card/settings',
                component: AuthenticationSettingsCardPage,
                name: 'CardAuthenticationSettingsSubTabRouter'
            },*/
      {
        path: 'cards/card/detail',
        component: CardDetailPage,
        name: 'CardDetailSubTabRouter'
      },
      {
        path: 'more',
        component: MoreTabPage,
        name: 'MoreTabRouter'
      },
      {
        path: 'more/authentication_settings',
        component: AuthenticationSettingsPage,
        name: 'MoreAuthSettingsSubTabRouter'
      },
      {
        path: 'more/authentication_settings/code_pin/onboarding',
        component: CodePinOnBoardingPage,
        name: 'MoreAuthSettingPinBoardingsSubTabRouter'
      },
      {
        path: 'more/authentication_settings/code_pin/save',
        component: CodePinSavePage,
        name: 'MoreAuthSettingPinSaveSubTabRouter'
      },
      {
        path: 'more/authentication_settings/code_pin/complexity_details',
        component: CodePinComplexityDetailsPage,
        name: 'MoreAuthSettingPinComplexitySubTabRouter'
      },
      {
        path: 'rib',
        component: RibTabPage,
        name: 'RibTabRouter'
      }
    ]
  }
]
