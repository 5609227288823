import { WalletRoot } from './components/wallet-root/index.react'
import { WalletFormRoot } from './components/wallet-root/index-form.react'
import { WalletBuyRoot } from './components/wallet-root/index-buy.react'
import { WalletSellRoot } from './components/wallet-root/index-sell.react'
import { WalletConvertRoot } from './components/wallet-root/index-convert.react'
import { WalletTransferRoot } from './components/wallet-root/index-transfer.react'
import { WalletCryptoRoot } from './components/wallet-root/index-crypto.react'
import { WalletCryptoDetailsRoot } from './components/wallet-root/index-crypto-details.react'

export const WalletApp = () => <WalletRoot />

export const WalletBuy = () => <WalletBuyRoot />

export const WalletSell = () => <WalletSellRoot />

export const WalletConvert = () => <WalletConvertRoot />

export const WalletTransfer = () => <WalletTransferRoot />

export const WalletCryptoList = () => <WalletCryptoRoot />

export const WalletCryptoDetails = () => <WalletCryptoDetailsRoot />

export const WalletForm = () => <WalletFormRoot />
