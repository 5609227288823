import config from '@/config'
import { getJwt, setJwt } from '../auth/utils'
import { getContextCompanyId } from '../auth/hooks/use-company-context'

export const fetchServer = async (
  url: string,
  options: Omit<RequestInit, 'body'> & {
    body?: string | any
    reloadOn401?: boolean
    mainRoot?: boolean
  } = {
    reloadOn401: true
  }
): Promise<Response> => {
  const { token } = getJwt()

  if (!options.method && options.body) {
    options.method = 'POST'
  }

  let mainRoot = config.APP_ROOT_API
  if (!options.mainRoot) {
    mainRoot = config.APP_ROOT_API + '/olkypass'
  }

  // Automatically include the token and set the content type
  options.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...options.headers
  }

  if (getContextCompanyId()) {
    const urlParts = url.split('?')
    if (urlParts.length === 1) {
      url = urlParts[0] + '?context_customer_id=' + getContextCompanyId()
    } else {
      url = urlParts[0] + '?context_customer_id=' + getContextCompanyId() + '&' + urlParts[1]
    }
  }

  //Automatically include the codeLieu
  if (options.body && typeof options.body !== 'string') {
    try {
      options.body = JSON.stringify(options.body)
    } catch (e) {
      console.error(e)
    }
  }
  const data = await fetch(mainRoot.replace(/\/$/, '') + '/' + url.replace(/^\//, ''), options)

  if (data.status === 401 && options.reloadOn401 !== false) {
    setJwt({ token: null, refresh: null })
    document.location.reload()
  }

  return data
}
