import ApiService from './api.service'
import { type AxiosRequestConfig } from 'axios'

class ResponseError extends Error {
  errorCode: any
  errorMessage: any

  constructor(errorCode: any, message: string | undefined) {
    super(message)
    this.name = this.constructor.name
    if (message != null) {
      this.message = message
    }
    this.errorCode = errorCode
  }
}

const BeneficiaryService = {
  async getBeneficiaries(paginationOptions: any) {
    const url = '/clients/with_payment_id'
    const requestData: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        page: paginationOptions.page,
        search: paginationOptions.search ? btoa(paginationOptions.search) : ''
      }
    }
    try {
      const response = await ApiService.getRequest(url, requestData)
      return response.data
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  },
  async getBeneficiary(beneficiary: any) {
    const url = '/client/' + beneficiary.id
    const requestData: AxiosRequestConfig = {
      method: 'GET',
      url
    }
    try {
      const response = await ApiService.getRequest(url, requestData)
      return response.data
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  },
  async disableBeneficiary(beneficiary: any) {
    const url = '/client/disable/' + beneficiary.id
    const requestData: AxiosRequestConfig = {
      method: 'PUT',
      url,
      data: {}
    }
    try {
      const response = await ApiService.customRequest(requestData)
      return response
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  },
  async getAllCountries() {
    const url = '/fetch-countries'
    const requestData: AxiosRequestConfig = {
      method: 'GET',
      url
    }
    try {
      const response = await ApiService.getRequest(url, requestData)
      return response.data
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  },
  async ibanCheck(iban_value: string) {
    const url = '/clients/iban/' + iban_value
    const requestData: AxiosRequestConfig = {
      method: 'GET',
      url
    }
    try {
      const response = await ApiService.getRequest(url, requestData)
      return response.data
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  },
  async ibanGetBank(bic_value: string) {
    const url = '/clients/bic/' + bic_value
    const requestData: AxiosRequestConfig = {
      method: 'GET',
      url
    }
    try {
      const response = await ApiService.getRequest(url, requestData)
      return response.data
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  },
  async saveBeneficiary(beneficiary: any, method: any) {
    let url = '/clients'
    if (method === 'PUT') {
      url = '/update_client/' + beneficiary.id
    }
    const requestData: AxiosRequestConfig = {
      method,
      url,
      data: beneficiary
    }
    try {
      const response = await ApiService.customRequest(requestData)
      return response.data
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  },
  async associateBankAccountToBeneficiary(client_id: any, input_data: any, method: any) {
    let url = '/payment/bankaccount/client/' + client_id
    if (method === 'PUT') {
      url = '/payment/update_bankaccount/' + input_data.bank_account_id + '/client/' + client_id
      delete input_data.bank_account_id
    }
    const requestData: AxiosRequestConfig = {
      method,
      url,
      data: input_data
    }
    try {
      const response = await ApiService.customRequest(requestData)
      return response
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message)
    }
  }
}

export { BeneficiaryService, ResponseError }
