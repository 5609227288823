'use client'
import { CoinLayout } from '@/molecules/coin-layout/index.react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../../../_layout/index.react'
import { CardWallet } from '../wallet-card/card-wallet.react'
import { OrdersList } from '../orders-list/orders-list.react'
import { useWallet } from '@/features/wallet/hooks/use-wallet'
import Loader from '@/atoms-react/loader/Loader.react'
import { OrdersTabs } from '../orders-list/orders-tabs.react'
import { FormWallet } from '../form-wallet/form.react'

export const WalletFormRoot = () => {
  const { t } = useTranslation(['wallet'])
  const [search, setSearch] = useState<string>('')

  const { currencyQuery, OrdersDataQuery } = useWallet()
  const { data: currency, isLoading: isCurrencyLoading } = currencyQuery()
  const { data: openOrders, isLoading: isOpenOrdersLoading } = OrdersDataQuery({ status: 'OPEN' })
  const { data: closeOrders, isLoading: isCloseOrdersLoading } = OrdersDataQuery({
    status: 'CLOSED'
  })
  const { data: cancelOrders, isLoading: isCancelOrdersLoading } = OrdersDataQuery({
    status: 'CANCELED'
  })

  const openOrdersData = openOrders?.map((itemList) => {
    const cryptoData = currency?.find((item) => item.id === itemList.crypto_currency.identifier)
    return {
      ...itemList,
      image: cryptoData?.image
    }
  })

  const closeOrdersData = closeOrders?.map((itemList) => {
    const cryptoData = currency?.find((item) => item.id === itemList.crypto_currency.identifier)
    return {
      ...itemList,
      image: cryptoData?.image
    }
  })

  const cancelOrdersData = cancelOrders?.map((itemList) => {
    const cryptoData = currency?.find((item) => item.id === itemList.crypto_currency.identifier)
    return {
      ...itemList,
      image: cryptoData?.image
    }
  })

  const tabs = [
    { label: 'OPEN', content: <OrdersList orders={openOrdersData} search={search} /> },
    { label: 'CLOSED', content: <OrdersList orders={closeOrdersData} search={search} /> },
    { label: 'CANCELED', content: <OrdersList orders={cancelOrdersData} search={search} /> }
  ]
  const tabsForm = [
    {
      label: 'Acheter',
      content: (
        <FormWallet
          config={{ unit: '€', data: currency, kypay: { amount: '200000' }, type: 'buy' }}
        />
      )
    },
    {
      label: 'Vendre',
      content: (
        <FormWallet
          config={{ unit: '€', data: currency, kypay: { amount: '200000' }, type: 'sell' }}
        />
      )
    },
    {
      label: 'Convertir',
      content: (
        <FormWallet
          config={{ unit: '€', data: currency, kypay: { amount: '200000' }, type: 'convert' }}
        />
      )
    },
    {
      label: 'Transférer',
      content: (
        <FormWallet
          config={{ unit: '€', data: currency, kypay: { amount: '200000' }, type: 'transfer' }}
        />
      )
    }
  ]

  return (
    <>
      <CoinLayout
        search={search}
        setSearch={setSearch}
        title={() => (
          <>
            <LayoutTitle>{t('wallet.buy.label.main_title')}</LayoutTitle>
          </>
        )}
        sectionTitle={''} // For the sub-title
        header={() => ''}
        body={() => (
          <div className="grid grid-cols-3 gap-4 w-full">
            {!isCurrencyLoading && (
              <>
                <div className="container mx-auto col-span-3 lg:col-span-2">
                  <OrdersTabs
                    tabs={tabsForm}
                    config={{
                      className:
                        'border-2 border-slate-50 rounded-sm px-4 py-2 md:px-6 md:py-4 w-full text-black dark:text-white dark:border-slate-700 flex flex-col justify-between'
                    }}
                  />
                </div>
                <CardWallet
                  Type="market"
                  config={{
                    format: 'list',
                    show: '3',
                    data: currency,
                    className: 'pt-7 mt-8 col-span-3 lg:col-span-1'
                  }}
                />
              </>
            )}
            {isCurrencyLoading && <Loader className={'w-8 h-8 text-blue-500'} />}
          </div>
        )}
        orders={{
          title: t('wallet.buy.section_title_orders'),
          body: () => (
            <>
              {!isOpenOrdersLoading && !isCloseOrdersLoading && !isCancelOrdersLoading && (
                <div className="container mx-auto">
                  <OrdersTabs tabs={tabs} config={{ className: 'flex-1 py-4' }} />
                </div>
              )}
              {(isOpenOrdersLoading || isCloseOrdersLoading || isCancelOrdersLoading) && (
                <Loader className={'w-8 h-8 text-blue-500'} />
              )}
            </>
          )
        }}
      />
    </>
  )
}
