import { defineJsxComponent } from '@/jsx-utils'
import { useCompany } from '@/features/company/hooks/use-company'
import { useI18n } from 'vue-i18n'
import { Section } from '@/components/layout/Section'
import { useRoute } from 'vue-router'
import ArrowsPointingInIcon from '@heroicons/vue/20/solid/ArrowsPointingInIcon'
import ArrowsPointingOutIcon from '@heroicons/vue/20/solid/ArrowsPointingOutIcon'
import { Button } from '@/atoms/buttons/Button'
import { ref } from 'vue'
import { SectionTitle, Subtitle } from '@/atoms/text/Text.tsx'
import { CompanyIdentityForm } from '@/app/olkypass/views/account/company-details/components/company-identity-form'

export const InfoTab = defineJsxComponent(_ => {
  const t = useI18n().t
  const route = useRoute()
  const { loadCurrentCompany } = useCompany()
  const identity_uuid = route.params.identity_uuid
  const displaySection = ref(true)
  return () => <div>
    <Section class="mb-4">
      <Button
        theme="outlined"
        icon={displaySection.value ? ArrowsPointingInIcon : ArrowsPointingOutIcon}
        onClick={() => {
          displaySection.value = !displaySection.value
        }}
      ></Button>
      <SectionTitle>{t('olkypass.companyPage.section.information.title')}</SectionTitle>
      {displaySection.value && <>
          <Subtitle class="block mt-3">
            {t('olkypass.companyPage.section.information.subtitle')}
          </Subtitle>
          <CompanyIdentityForm onComplete={() => loadCurrentCompany(identity_uuid)} />
        </>}
    </Section>
  </div>
})
