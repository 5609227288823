import { getRoute } from '@/app/olkypass/utils/routes'
import { A } from '@/atoms-react/a/A.react'
import { Skeletton } from '@/atoms-react/skeletton/index.react'
import { Base, Info } from '@/atoms-react/text/Text.react'
import { Routes } from '@/features/router'
import { useDocument } from '@/features/user/document/hooks/use-document'
import { useUser } from '@/features/user/hooks/use-user'
import { DocumentRow } from '@/molecules/document-row/index.react'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

type IdentityDocumentsProps = React.ComponentPropsWithoutRef<'div'>

export const IdentityDocuments = ({ ...props }: IdentityDocumentsProps) => {
  const { t } = useTranslation(['pass'])

  const { useUserProfile } = useUser()
  const { data: user } = useUserProfile()

  const { DocumentsDetailsQuery } = useDocument()
  const { data: documents, isLoading: isLoadingDocuments } = DocumentsDetailsQuery(
    user?.naturalPerson?.UUID ?? null
  )

  const navigate = useNavigate()

  return <div
    className={twMerge(
      'md:pt-4 md:pb-6 md:ml-6 2xl:ml-0 flex flex-col justify-between md:block',
      props.className
    )}
    {..._.omit(props, 'className')}
  >
    <div>
      <div className="flex justify-between md:px-6 mb-3">
        <Base className="text-slate-300 hidden md:block">
          {t('olkypass.accountPage.basicInfo.tabs.documents')}
        </Base>
        <A onClick={() => navigate(getRoute(Routes.account_alias_documents))}>
          {t('olkypass.general.see_all')}
        </A>
      </div>
      <div className={'mb-4'}>
        {!documents?.length && <Info className="px-6">
            {t('olkypass.accountPage.documentsSection.help.no_data_found')}
          </Info>}
        {documents &&
          documents
            .slice(0, 3)
            .map(document => <DocumentRow
              key={document.uuid}
              onClick={() =>
                navigate({
                  pathname: Routes.account_alias_documents,
                  search: `?preview=${document.uuid}`
                })
              }
              document={document}
            />)}
        {isLoadingDocuments &&
          [0, 1, 2].map(val => <Skeletton key={val} className={'mx-6 mb-1'} />)}
      </div>
    </div>

    {/*<AddDocument />*/}
  </div>
}
