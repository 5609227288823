<template>
  <ion-page>
    <ion-content id="resetPasswordPage" :fullscreen="true">
      <login-header :title="$t('olkypay.labels.resetPasswordPage.header')"></login-header>
      <div class="container">
        <ion-grid class="">
          <div class="card card-plain">
            <div class="card-body px-lg-5 py-lg-5 text-center">
              <ion-row class="ion-justify-content-center ion-margin-top ion-align-items-center">
                <ion-col size="12">
                  <ion-input
                    v-model="username"
                    :label="$t('olkypay.inputs.loginPage.username')"
                    label-placement="floating"
                    :autofocus="true"
                    :clear-input="true"
                    fill="outline"
                    :auto-focus="true"
                  ></ion-input>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <h2>{{ $t('olkypay.labels.resetPasswordPage.notice') }}</h2>
                </ion-col>
              </ion-row>
              <ion-row class="mt-4">
                <ion-col>
                  <ion-button
                    class="continue-button"
                    :disabled="!username"
                    @click="sendResetLink()"
                  >
                    {{ $t('olkypay.buttons.resetPasswordPage.standard.continue') }}
                  </ion-button>
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center ion-margin-top">
                <ion-col size="auto">
                  <a href="/pay/login" class="link-routers">{{
                    $t('olkypay.buttons.loginPage.standard.continue')
                  }}</a>
                </ion-col>
              </ion-row>
            </div>
          </div>
        </ion-grid>
      </div>
      <loader></loader>
    </ion-content>
  </ion-page>
</template>
<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonRadio,
  IonRadioGroup,
  IonInput,
  IonRouterOutlet,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
  alertController,
  IonLoading
} from '@ionic/vue'
import { logoIonic } from 'ionicons/icons'
import olkyLogoBlue from '../../../../public/images/olkypay/olky-logo-blue.png'
import LanguageSwitcher from '../components/LanguageSwitcher.vue'
import { mapActions, mapGetters } from 'vuex'
import Loader from '../components/Loader.vue'
import CustomOTP from '../components/CustomOTP.vue'
import LoginHeader from '../components/security/loginHeader.vue'

export default {
  name: 'ResetPasswordPage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonGrid,
    IonRow,
    IonCol,
    IonRadio,
    IonRadioGroup,
    IonInput,
    IonImg,
    IonButton,
    IonRouterOutlet,
    alertController,
    IonLoading,
    LanguageSwitcher,
    Loader,
    CustomOTP,
    LoginHeader
  },
  data() {
    return {
      logoIonic,
      username: ''
    }
  },
  methods: {
    ...mapActions('loader', ['playLoader', 'stopLoader']),
    async sendResetLink() {
      /*this.playLoader()
      this.stopLoader()*/
    }
  },
  computed: {
    olkyLogoBlue() {
      return olkyLogoBlue
    }
  }
}
</script>

<style scoped></style>
