<template>
  <ion-row>
    <ion-col>
      <div
        class="card border-1 border-gray border-radius-lg"
        :class="
          currentBeneficiary.id && beneficiary.id === currentBeneficiary.id
            ? 'bg-gray-300 border-selected'
            : ''
        "
        @click="setBeneficiary"
      >
        <div class="mx-2 mt-2 mb-2">
          <h4 class="mb-0 text-dark font-weight-bold">{{ beneficiary.fullName }}</h4>
          <span class="text-xs text-gray">{{ formattedIBAN }}</span>
        </div>
      </div>
    </ion-col>
  </ion-row>
</template>

<script lang="ts">
import { IonRow, IonCol } from '@ionic/vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BeneficiaryAccount',
  components: {
    IonRow,
    IonCol
  },
  props: ['beneficiary'],
  methods: {
    ...mapActions('beneficiary', ['setCurrentBeneficiary']),
    setBeneficiary() {
      this.setCurrentBeneficiary(this.beneficiary)
    }
  },
  computed: {
    ...mapGetters('beneficiary', ['currentBeneficiary']),
    formattedIBAN() {
      return this.beneficiary.iban.match(/.{1,4}/g).join(' ')
    }
  }
}
</script>

<style scoped></style>
