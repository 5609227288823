<template>
  <ion-modal
    ref="validationCodeModal"
    class="okypay-modal"
    :is-open="isOpenDialog"
    :can-dismiss="canDismiss"
  >
    <div class="wrapper">
      <h1></h1>

      <ion-grid class="mt-4">
        <ion-row class="ion-justify-content-center ion-margin-bottom">
          <ion-col size="auto">
            <ion-img :src="olkyLogoBlue" alt="olky-logo"> </ion-img>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-col size="auto" class="text-center">
            <h4>{{ $t('olkypay.labels.validationCodeDialog.validation_code') }}</h4>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <v-otp-input
              ref="otpInput"
              v-model:value="validationCode"
              input-classes="otp-input"
              separator=""
              :num-inputs="6"
              :should-auto-focus="false"
              @on-complete="handleValidationCode"
            />
          </ion-col>
        </ion-row>

        <!--        <ion-item>
          <ion-input
            v-model="validationCode"
            :label="$t('olkypay.inputs.validationCodeDialog.validation_code')"
            label-placement="floating"
            :clear-input="true"
          ></ion-input>
        </ion-item>-->
        <ion-row class="ion-margin-top">
          <ion-col class="text-start">
            <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" @click="dismissModal">
              {{ $t('olkypay.buttons.loginPage.standard.cancel') }}
            </button>
          </ion-col>
          <ion-col class="text-end">
            <button
              class="btn bg-gradient-info mb-0 ms-2"
              :disabled="validationCode === ''"
              @click="handleValidationCode()"
            >
              {{ $t('olkypay.buttons.validationCodeDialog.standard.continue') }}
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-modal>
  <info-modal ref="infoModal"></info-modal>
</template>

<script lang="ts">
import VOtpInput from 'vue3-otp-input'
import olkyLogoBluePath from '../../../../../public/images/olkypay/olky-logo-blue.png'
import InfoModal from '../../components/InfoModal.vue'
import {
  alertController,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonList,
  IonLoading,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRouterOutlet,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  IonModal,
  toastController
} from '@ionic/vue'
import { TransactionService } from '../../services/transaction.service'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ValidationCodeModal',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonGrid,
    IonRow,
    IonCol,
    IonRadio,
    IonRadioGroup,
    IonInput,
    IonImg,
    IonButton,
    IonRouterOutlet,
    alertController,
    IonLoading,
    IonModal,
    VOtpInput,
    InfoModal
  },
  data() {
    return {
      isOpenDialog: false,
      resolve: null,
      reject: null,
      canDismiss: false,
      validationCode: ''
    }
  },
  methods: {
    ...mapActions('loader', ['playLoader', 'stopLoader']),
    openValidationDialog() {
      this.isOpenDialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    proceedToNextStep() {
      this.canDismiss = true
      this.resolve(true)
      this.isOpenDialog = false
      this.validationCode = ''
    },
    showPrincipalModal() {
      this.canDismiss = false
      this.isOpenDialog = true
    },
    hidePrincipalModal() {
      this.canDismiss = true
      this.isOpenDialog = false
    },
    dismissModal() {
      this.canDismiss = true
      this.resolve(false)
      this.isOpenDialog = false
    },
    async handleValidationCode() {
      this.hidePrincipalModal()
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.checkOrderValidationCode.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      const inputData = {
        validationCode: this.validationCode,
        orderIds: this.selectedOrders,
        type: undefined
      }
      if (this.currentTransfer.typeVirement === 'rec') {
        inputData.type = 'rec'
      }
      await TransactionService.validateOrdersWithCode(inputData)
        .then(async res => {
          this.stopLoader()
          const successDialog = {
            type: 'success',
            title: this.$i18n.t('olkypay.notifications.success.transferPage.validate_order.header'),
            content: this.$i18n.t(
              'olkypay.notifications.success.transferPage.validate_order.message'
            ),
            button: this.$i18n.t('olkypay.buttons.codePinSettingsPage.close_dialog')
          }
          const response_modal = await this.$refs.infoModal.openModal(successDialog)
          if (response_modal) {
            this.proceedToNextStep()
          }
        })
        .catch(async (err: any) => {
          this.stopLoader()
          this.showPrincipalModal()
          const failedDialog = {
            type: 'warning',
            title: this.$i18n.t('olkypay.notifications.error.transferPage.validate_order.header'),
            content: this.$i18n.t(
              'olkypay.notifications.error.transferPage.validate_order.message'
            ),
            button: this.$i18n.t('olkypay.buttons.codePinSettingsPage.close_dialog')
          }
          const response_modal = await this.$refs.infoModal.openModal(failedDialog)
          if (response_modal) {
            this.dismissModal()
          }
        })
        .finally(() => this.stopLoader())
    }
  },
  computed: {
    ...mapGetters('transaction', ['orders', 'selectedOrders']),
    ...mapGetters('transfer', ['currentTransfer']),
    olkyLogoBlue() {
      return olkyLogoBluePath
    }
  }
}
</script>

<style scoped></style>
