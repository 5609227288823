<template>
  <v-card-item>
    <v-row>
      <v-col>
        <v-img class="mx-auto" width="50%" :src="getValidationPath()"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="typeState == 'success'" class="informationTitle">
        {{ $t('olkypass.accountPage.dialog.identity.step3TitleSuccess') }}
      </v-col>
      <v-col v-if="typeState == 'pending'" class="informationTitle">
        {{ $t('olkypass.accountPage.dialog.identity.step3TitlePending') }}
      </v-col>
      <v-col v-if="typeState == 'error'" class="informationTitle">
        {{ $t('olkypass.accountPage.dialog.identity.step3TitleError') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="typeState == 'success'" class="informationDesc">
        <p v-html="$t('olkypass.accountPage.dialog.identity.step3ContentSuccess')"></p>
      </v-col>
      <v-col v-if="typeState == 'pending'" class="informationDesc">
        <p v-html="$t('olkypass.accountPage.dialog.identity.step3ContentPending')"></p>
      </v-col>
      <v-col v-if="typeState == 'error'" class="informationDesc">
        <p v-html="$t('olkypass.accountPage.dialog.identity.step3ContentError')"></p>
      </v-col>
    </v-row>
  </v-card-item>
</template>

<script>
import { mapGetters } from '../../store'

export default {
  name: 'KYCIdentityEndSession',
  data() {
    return {
      validationKycSuccess: '/images/olkypass/validationKycSuccess.svg',
      validationKycPending: '/images/olkypass/validationKycPending.svg',
      validationKycError: '/images/olkypass/validationKycError.svg',
      typeState: ''
    }
  },
  computed: {
    ...mapGetters(['naturalPerson', 'kycState', 'kycSession'])
  },
  mounted() {
    this.detectKycResponseType()
    this.reloadReactKYC()
  },
  methods: {
    getValidationPath() {
      let returnImage = this.validationKycSuccess
      switch (this.typeState) {
        case 'error':
          returnImage = this.validationKycError
          break
        case 'pending':
          returnImage = this.validationKycPending
          break
      }
      return returnImage
    },
    detectKycResponseType() {
      if (this.kycState.level > 50) {
        this.typeState = 'success'
      } else if (this.kycState.level == 50) {
        this.typeState = 'pending'
      } else {
        this.typeState = 'error'
      }
    },
    reloadReactKYC() {
      if (!this?.naturalPerson?.UUID) return

      // @TODO reproduce same inside React
      window.queryClient.invalidateQueries(['flow', 'kyc', 'state', this?.naturalPerson?.UUID])
    }
  }
}
</script>
