<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex justify-start">
        <v-btn
          variant="text"
          color="primary"
          height="auto"
          class="text-none textBtn"
          width="auto"
          prepend-icon="mdi-home-plus"
          size="medium"
          @click="openFormDialog('adresse', true)"
        >
          <template #prepend>
            <v-icon class="mx-1 cercle" size="20"></v-icon>
          </template>
          {{ $t('olkypass.accountPage.addresses_section.button.addAddress') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-start">
      <AddressChild
        v-for="(address, index) in naturalPerson.Address"
        ref="addressChild"
        :key="index"
        :address="address"
        :countries="countries"
        :country-key="countryLabel"
        :index="index"
        :in-memory-address="inMemoryNP.Address[index]"
        @icon-delete-clicked="temporaryDeleteAddress"
      ></AddressChild>
    </v-row>
    <AddressFormDialog ref="addressFormDialog"></AddressFormDialog>
    <DialogConfirm ref="dialogConfirm"></DialogConfirm>
  </v-container>
</template>

<script>
// import UniformPanels from "../Common/UniformPanels.vue";
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import ToolsMixins from '../../mixins/ToolsMixins.js'
import { mapGetters } from '../../store'
import store from '../../store'
import AddressChild from '../Common/AddressChild.vue'
import AddressFormDialog from '../FormsDialog/AddressFormDialog.vue'
import DialogConfirm from '../DialogConfirm.vue'

export default {
  name: 'AddressTab',
  components: {
    AddressChild,
    AddressFormDialog,
    DialogConfirm
  },
  mixins: [NotificationsMixins, ToolsMixins],
  data: () => ({}),
  methods: {
    openFormDialog(section, isNew) {
      this.$refs.addressFormDialog.open({}, isNew)
    },
    async temporaryDeleteAddress(index) {
      if (
        await this.$refs.dialogConfirm.open(
          'accountPage.dialog.confirm.delete.address.header',
          'accountPage.dialog.confirm.delete.address.body'
        )
      ) {
        if (index !== null) {
          this.naturalPerson.Address.splice(index, 1)
        }
      }
    },
    addEmptyAddress() {
      this.naturalPerson.Address.push({})
    },
    async savePartialData(section) {
      const setPartialDataFunctionName =
        this.currentTreatedEntity === 'company' ? 'setCompanyPartialData' : 'setNPPartialData'
      if (
        await this.$refs.dialogConfirm.open(
          'accountPage.dialog.confirm.update.infos.header',
          'accountPage.dialog.confirm.update.infos.body'
        )
      ) {
        store.dispatch('playLoader')
        await store.dispatch(setPartialDataFunctionName, {
          UUID: this.naturalPerson.UUID,
          Address: this.naturalPerson.Address
        })
        if (this.currentTreatedEntity === 'company') {
          await store.dispatch('setCurrentCompany', { UUID: this.naturalPerson.UUID })
        } else {
          await store.dispatch('setCurrentNaturalPerson')
        }
        store.dispatch('stopLoader')
      }
    }
  },
  computed: {
    ...mapGetters([
      'countries',
      'countryLabel',
      'wantToLeavePage',
      'currentLanguage',
      'hasUpdatePermission',
      'naturalPerson',
      'inMemoryNP'
    ])
  }
}
</script>

<style scoped></style>
