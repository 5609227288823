import React, { useCallback, useState } from 'react'
import 'react-calendar/dist/Calendar.css'
import { twMerge } from 'tailwind-merge'
import _ from 'lodash'
import { defaultInputClassName } from './Input.react'
import { formatUTC } from '@/features/utils/functions'
import { DateTime } from 'luxon'
import { useNativeInputs } from '@/features/utils/is-mobile'
import FormattedInputDate from './FormattedInputDate.react'

type InputDateProps = {
  value: Date | null
  onChange?: (date: Date | null) => void
  placeholder?: string
  label?: string
  disabled?: boolean
  required?: boolean
  autoComplete?: string
  labelProps?: React.ComponentPropsWithoutRef<'label'>
} & Omit<Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange'>, 'value'>

function InputDate({
  value,
  placeholder,
  label,
  labelProps,
  autoComplete,
  onChange = () => {},
  ...props
}: InputDateProps) {
  //const { t } = useTranslation(['atoms'])
  const isMobile = useNativeInputs()
  const [valueText, setValueText] = useState<string>(value ? formatUTC(value.toISOString()) : '')
  const [textColor, setTextColor] = useState<string>('text-slate-400 dark:text-slate-600')
  const [missingRequired, setMissingRequired] = useState(false)
  const handleFocus = () => {
    if (!valueText) {
      setTextColor('text-black')
    }
  }

  const handleBlur = () => {
    const date = DateTime.fromISO(valueText)
    if (date.isValid) {
      setTextColor('text-black')
    } else {
      setTextColor('text-slate-400 dark:text-slate-600')
    }
  }

  const onInputChange = useCallback(
    (newValue: string) => {
      setValueText(newValue)
      const date = DateTime.fromISO(newValue)
      if (date.isValid) {
        onChange(date.toJSDate())
      } else {
        onChange(null)
      }
      if (props?.required) {
        setMissingRequired(!date.isValid)
      } else {
        setMissingRequired(false)
      }
    },
    [onChange]
  )

  return <label
    className={twMerge(
      'flex flex-col space-y-1',
      props.disabled && 'pointer-events-none pointer-events-none opacity-40',
      props.className
    )}
    {..._.omit(labelProps, 'className')}
  >
    <span
      className={twMerge(
        'text-base font-medium block text-black dark:text-white text-left whitespace-nowrap pb-1'
      )}
    >
      {label}
      {/*{props.required === false ? ' (' + t('general.optional') + ')' : ''}*/}
      {props.required === false ? '' : ' *'}
    </span>
    <div>
      {isMobile && <input
          autoComplete={autoComplete}
          type="date"
          max="2999-12-31"
          required
          pattern="\d{4}-\d{2}-\d{2}"
          value={valueText}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={twMerge(
            defaultInputClassName,
            'w-full',
            missingRequired && 'border-red-500',
            textColor,
            props.className
          )}
          placeholder={placeholder}
          onChange={(e: any) => onInputChange(e.target.value)}
          {..._.omit(props, 'onChange', 'type', 'value', 'placeholder', 'className')}
        />}
      {!isMobile && <FormattedInputDate
          value={valueText}
          className={twMerge(defaultInputClassName, 'w-full', textColor, props.className)}
          onChange={onInputChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />}
    </div>
  </label>
}

export default InputDate
