import { getRoute, OlkyPassRoutes } from '@/app/olkypass/utils/routes'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { setJwt } from '@/features/auth/utils'
import { Routes } from '@/features/router'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { animatedNext } from '../layout/AnimatedForm.react'
import { DocumentsGateway, DocumentsGatewayAction } from './documents/index.react'
import { AuthenticateOutput } from './login/authenticate/Authenticate.react'
import LoginRoot from './login/index.react'
import SignupRoot, { SignupOutput } from './signup/index.react'
import AccountWelcome, { AccountWelcomeOutput } from './welcome/index.react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { AuthApiClient } from '@/features/user/api-client/api-client.ts'
import { findMissingProfileKeys } from '@/features/flow/constants.ts'

export type LoginInfo = {
  welcome: AccountWelcomeOutput | null
  login: AuthenticateOutput | null
  signup: SignupOutput | null
}

export const Gateways = () => {
  const { t } = useTranslation(['pass'])
  const { block, step, session_uuid } = useParams()
  const { refresh, createMirrorIdentity } = useAuth()
  const navigate = useNavigate()

  const [gatewaysData, setGatewaysInfo] = useState<LoginInfo>({
    welcome: null,
    login: null,
    signup: null
  })
  const followRoute = () => {
    try {
      const params = new URLSearchParams(window.location.search)
      const redirect = params.get('r')
      const route = (redirect && decodeURIComponent(redirect)) || Routes.dashboard || ''
      navigate(route)
    } catch (e) {
      document.location = '/'
    }
  }
  const manipulatePartner = async () => {
    const partner_uuid = localStorage.getItem('partner_uuid')
    if (partner_uuid) {
      createMirrorIdentity(partner_uuid)
      localStorage.removeItem('partner_uuid')
      const profile = await AuthApiClient.profile()
      if (profile.olkyPassLevel?.olkypass_status?.level >= 2) {
        // redirect to kypay
        navigate(OlkyPassRoutes.kypay)
      } else {
        sessionStorage.removeItem('ConnectedPartner')
        followRoute()
      }
    } else {
      refresh(true)
    }
  }
  const checkMissedMandatoryData = async () => {
    const profile = await AuthApiClient.profile()
    const missingKeys = findMissingProfileKeys(profile?.naturalPerson)
    if (Object.keys(missingKeys).length > 0) {
      sessionStorage.setItem(
        'NewOlkyPayUser',
        JSON.stringify({ profile: profile, missingKeys: missingKeys })
      )
      const first_step = 'phone.phone' in missingKeys ? 'phone' : 'identity'
      navigate(
        getRoute(Routes.flow_step, { session_uuid, block: 'required_field', step: first_step })
      )
    } else {
      await manipulatePartner()
    }
  }
  useEffect(() => {
    // Special case for now for reset password
    if (block === 'login' && step === 'reset') {
      return
    }

    // Redirect to welcome if invalid block or just reloaded the page now
    if (
      (gatewaysData as any)[block || ''] !== undefined ||
      !session_uuid ||
      !gatewaysData.welcome?.email
    ) {
      navigate(getRoute(Routes.flow_block, { session_uuid, block: 'welcome' }), {
        replace: true
      })
      return
    }
  }, [])

  return (
    <>
      {block === 'documents' && (
        <DocumentsGateway
          action={DocumentsGatewayAction.KYCDocument}
          onValidated={() => refresh(true)}
        />
      )}

      {block === 'signup' && (
        <SignupRoot
          input={{ email: gatewaysData.welcome?.email || '' }}
          onValidated={async () => {
            await manipulatePartner()
          }}
        />
      )}
      {block === 'login' && (
        <LoginRoot
          input={{
            email: gatewaysData.welcome?.email || '',
            is2faActivated: gatewaysData.welcome?.is2faActivated || false
          }}
          onValidated={async (jwt) => {
            setJwt(jwt)
            await checkMissedMandatoryData()
          }}
        />
      )}
      {block === 'welcome' && (
        <AccountWelcome
          onValidated={(welcome) =>
            animatedNext('forward', async () => {
              setGatewaysInfo((i) => ({ ...i, welcome }))
              if (welcome.emailExists) {
                navigate(getRoute(Routes.flow_block, { session_uuid, block: 'login' }))
              } else if (welcome.existAsAlias) {
                toast.error(t('olkypass.general.mail_already_exist'))
              } else {
                navigate(getRoute(Routes.flow_block, { session_uuid, block: 'signup' }))
              }
            })
          }
        />
      )}
      {block === 'required_field' && (
        <SignupRoot
          input={{ email: gatewaysData.welcome?.email || '' }}
          onValidated={async () => {
            await manipulatePartner()
          }}
        />
      )}
    </>
  )
}
