<template>
  <v-card
    class="mx-auto dashboardBox olkyPayCard"
    height="320px"
    width="auto"
    @click="ionRouter.navigate(Routes.kypay, 'root')"
  >
    <v-card-item class="cardTopTitle">
      <template #title>
        {{ $t('olkypass.accountPage.cardActions.label.kpay') }}
      </template>
    </v-card-item>
    <v-card-text v-if="!loading" class="cardContentContainer">
      <v-row>
        <v-col>
          <v-img class="mx-auto" height="100px" width="77px" :src="olkyKypayLogoPath"></v-img>
        </v-col>
      </v-row>
      <v-row class="available">
        <v-col>
          <h1>{{ kypay.balance }}</h1>
          <!-- <h2>en date du 18/08/2023</h2>-->
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="!loading">
      <a class="no-ui databoxDetailsButton">{{ $t('olkypass.mainPage.olkypass.details') }}</a>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from '../../store'
import ToolsMixins from '../../mixins/ToolsMixins.js'
import { Routes } from '@/features/router'
import { useIonRouter } from '@ionic/vue'

export default {
  name: 'DashboardKPayDataBox',
  mixins: [ToolsMixins],
  data: () => ({
    olkyKypayLogoPath: '/images/olkypass/kypay-logo.svg',
    ionRouter: useIonRouter(),
    Routes
  }),
  async mounted() {
    // await this.checkKypayInit(this.kypay)
  },
  computed: {
    ...mapGetters(['loading', 'kypay'])
  }
}
</script>
