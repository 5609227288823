import React from 'react'
import { twMerge } from 'tailwind-merge'
import { ClipboardDocumentIcon } from '@heroicons/react/20/solid'
import { useCopyToClipboard } from 'usehooks-ts'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

type WalletNumberProps = {
  label?: string
  copy?: boolean
  wallet: string | null
  onSetWallet?: () => void
} & React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>>

export const WalletNumber = ({
  label,
  wallet,
  onSetWallet = () => {},
  copy = false,
  children,
  ...props
}: WalletNumberProps) => {
  const { t } = useTranslation(['wallet'])
  const [_, copyToClipboard] = useCopyToClipboard()

  const noWallet = wallet === null

  return <label>
    {label && <p className={'mb-2'}>{label}</p>}
    <div
      className={twMerge(
        'flex flex-row items-center justify-between px-4 py-3 bg-slate-100 rounded-sm text-slate-800 font-semibold dark:bg-slate-800 dark:text-white',
        noWallet && 'bg-white rounded-sm border cursor-pointer flex justify-center py-4',
        props.className
      )}
      onClick={() => {
        if (!wallet) onSetWallet()
      }}
      {...props}
    >
      {typeof wallet === 'string' && <p className={'mb-0'}>{wallet ?? 'No wallet'}</p>}
      {noWallet && <p className={'mb-0 text-blue-500'}>{t('kypay.input.new_wallet')}</p>}
      {children}
      {copy && <ClipboardDocumentIcon
          className={'cursor-pointer h-6'}
          onClick={() =>
            copyToClipboard(wallet ?? '')
              .then(() => toast.success(t('error.success_clipboard')))
              .catch(() => toast.error(t('error.error_clipboard')))
          }
        />}
    </div>
  </label>
}
