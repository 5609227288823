<template>
  <ion-page id="CreditCardPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          default-href="/pay/supplier/cards"
          :icon="caretBackSharp"
        ></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1>{{ $t('olkypay.labels.creditCardPage.header') }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <ion-grid class="mx-2">
        <ion-row class="mt-2">
          <ion-col class="text-center">
            <img :src="particularCard" />
            <h5 class="text-center text-gray mt-0 mb-0">{{ currentCard.fullName }}</h5>
          </ion-col>
        </ion-row>
        <ion-row class="mb-2 mt-4">
          <ion-col>
            <h2>{{ $t('olkypay.labels.creditCardPage.header') }}</h2>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-input
              v-model="formattedAmountInCentimes"
              :label="$t('olkypay.inputs.creditCardPage.amount')"
              :clear-input="true"
              label-placement="floating"
              fill="outline"
              @ionBlur="formatAmount"
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row class="mt-3">
          <ion-col class="text-center">
            <button
              class="btn bg-gradient-info mb-0 medium-button"
              :disabled="!amountInCentimes"
              @click="proceedToAutoLeveling()"
            >
              {{ $t('olkypay.buttons.creditCardPage.continue') }}
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <authentication-process-popup ref="authenticationPopup"></authentication-process-popup>
    <info-modal ref="infoModal"></info-modal>
  </ion-page>
</template>

<script lang="ts">
import { CardService } from '../../../services/card.service.ts'
import businessCard from '../../../../../../public/images/olkypay/cards/business-card.png'
import particularCard from '../../../../../../public/images/olkypay/cards/particular-card.png'
import {
  IonHeader,
  IonContent,
  IonPage,
  IonBackButton,
  IonButton,
  IonButtons,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonImg,
  IonGrid,
  alertController,
  IonSelect,
  IonSelectOption,
  IonInput
} from '@ionic/vue'
import { caretBackSharp, alertCircle, call } from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'
import AuthenticationProcessPopup from '../../../components/security/authenticationProcessPopup.vue'
import InfoModal from '../../../components/InfoModal.vue'

export default {
  name: 'CreditCard',
  components: {
    IonHeader,
    IonContent,
    IonPage,
    IonBackButton,
    IonButton,
    IonButtons,
    IonIcon,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonImg,
    IonGrid,
    IonSelect,
    IonSelectOption,
    IonInput,
    AuthenticationProcessPopup,
    InfoModal
  },
  data() {
    return {
      caretBackSharp,
      alertCircle,
      call,
      businessCard,
      particularCard,
      amountInCentimes: '',
      formattedAmountInCentimes: ''
    }
  },
  methods: {
    ...mapActions('loader', ['playLoader', 'stopLoader']),
    async proceedToAutoLeveling() {
      const isSuccessAuthenticated = await this.$refs.authenticationPopup.authenticate()
      if (isSuccessAuthenticated) {
        await this.onSuccessAuthenticateUser()
      }
    },
    formatAmount() {
      // Remove non-numeric characters from the input
      const numericValue = this.formattedAmountInCentimes.replace(/[^0-9]/g, '')
      // Convert to centimes (multiply by 100)
      const eurosValue = parseFloat(numericValue)
      // Format the centimesValue as a string with commas and euros (€)
      this.formattedAmountInCentimes = isNaN(eurosValue)
        ? ''
        : eurosValue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2
          })
      this.amountInCentimes = isNaN(eurosValue) ? 0 : eurosValue * 100
    },
    async onSuccessAuthenticateUser() {
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.creditCard.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      const inputData = { id: this.currentCard.id, amount: this.amountInCentimes }
      await CardService.credit(inputData)
        .then(async res => {
          this.stopLoader()
          const successDialog = {
            type: 'success',
            title: this.$i18n.t('olkypay.notifications.success.creditCardPage.credit_card.header'),
            content: this.$i18n.t(
              'olkypay.notifications.success.creditCardPage.credit_card.message'
            ),
            button: this.$i18n.t('olkypay.buttons.creditCardPage.go_back_home')
          }
          const response_modal = await this.$refs.infoModal.openModal(successDialog)
          if (response_modal) {
            this.$router.push({ name: 'CardsTabRouter' })
          }
        })
        .catch(async (err: any) => {
          this.stopLoader()
          const failedDialog = {
            type: 'warning',
            title: this.$i18n.t('olkypay.notifications.error.creditCardPage.credit_card.header'),
            content: this.$i18n.t('olkypay.notifications.error.creditCardPage.credit_card.message'),
            button: this.$i18n.t('olkypay.buttons.creditCardPage.close_dialog')
          }
          const response_modal = await this.$refs.infoModal.openModal(failedDialog)
        })
      this.stopLoader()
    }
  },

  computed: {
    ...mapGetters('card', ['currentCard'])
  }
}
</script>
