<template>
  <div>
    <div class="sm:flex sm:items-start">
      <div
        v-if="icon"
        :class="[
          'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full',
          `bg-${color}-100 sm:mx-0 sm:h-10 sm:w-10`
        ]"
      >
        <component :is="icon" :class="[`h-6 w-6 text-${color}-500`]" />
      </div>
      <div :class="['mt-3 text-center sm:mt-0 sm:text-left', icon ? 'sm:ml-4' : '']">
        <DialogTitle
          as="h3"
          class="-mt-2 text-xl font-medium leading-6 font-medium text-slate-900 dark:text-white pr-6"
        >
          <Title>
            {{ title }}
          </Title>
        </DialogTitle>
        <div>
          <p class="text-sm text-slate-500 dark:text-white mt-2">
            {{ text || '' }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="$slots.buttons"
      class="mt-4 sm:flex sm:flex-row-reverse text-center sm:text-left -mr-4 -mb-2"
    >
      <slot name="buttons"></slot>
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { DialogTitle } from '@headlessui/vue'

const props = defineProps({
  title: { type: String, default: '' },
  text: { type: String, default: '' },
  icon: { type: Function, default: null },
  theme: { type: String, default: '' }
})

let color = 'blue'
if (props.theme === 'success') color = 'green'
if (props.theme === 'danger') color = 'red'
if (props.theme === 'warning') color = 'orange'
if (props.theme === 'gray') color = 'gray'
</script>
