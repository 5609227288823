import { Base } from '@/atoms-react/text/Text.react'
import { Themes } from '@/types/theme'
import _ from 'lodash'
import React, { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type TagProps = {
  children?: ReactNode
  theme?: Themes
  size?: 'sm' | 'md'
} & React.ComponentPropsWithoutRef<'div'>

export default function Tag({ size, children, theme = Themes.primary, ...props }: TagProps) {
  let colorTag = ''
  let colorText = ''

  if (theme === Themes.danger) {
    colorTag = 'bg-red-50 dark:bg-red-900'
    colorText = 'text-red-600 dark:text-red-500'
  }

  if (theme === Themes.warning) {
    colorTag = 'bg-orange-50 dark:bg-orange-900'
    colorText = 'text-orange-600 dark:text-orange-500'
  }

  if (theme === Themes.primary) {
    colorTag = 'bg-blue-50 dark:bg-slate-900'
    colorText = 'text-blue-500'
  }
  return <div
    className={twMerge(
      'rounded-full px-3 py-1 text-truncate flex items-center justify-center',
      colorTag,
      props.className
    )}
    {..._.omit(props, 'className')}
  >
    <Base className={twMerge('font-semibold', colorText, size === 'sm' && 'text-sm')}>
      {children}
    </Base>
  </div>
}
