<template>
  <ion-page id="BeneficiariesPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          default-href="/pay/supplier/transfer"
          :icon="caretBackSharp"
        ></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1>{{ $t('olkypay.labels.transferPage.beneficiaries') }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-searchbar
              animated="true"
              :placeholder="$t('olkypay.inputs.homePage.search')"
              class="no-elevation bg-gray-100 border-radius-xs form-control form-control-sm border-radius-md mb-3"
              @ionBlur="searchBeneficiaries($event)"
            ></ion-searchbar>
            <div v-if="isDataLoading" class="text-center mt-4">
              <ion-spinner name="bubbles" class="text-center"></ion-spinner>
              <br />
              <ion-label class="text-center text-gray text-md">{{
                $t('olkypay.labels.homePage.loading_data')
              }}</ion-label>
            </div>
            <div v-else-if="!isDataLoading && beneficiaries.length" class="card-body pt-4 p-3">
              <div v-for="beneficiary in beneficiaries">
                <beneficiary-account :beneficiary="beneficiary"></beneficiary-account>
              </div>
              <ion-infinite-scroll @ionInfinite="displayPaginatedBeneficiaries($event)">
                <ion-infinite-scroll-content
                  :loading-text="$t('olkypay.labels.homePage.loading_data')"
                  loading-spinner="bubbles"
                ></ion-infinite-scroll-content>
              </ion-infinite-scroll>
            </div>
            <div v-else>
              <div class="d-flex flex-column bg-gray-200 border-radius-lg p-1 mt-2">
                <span class="mb-2 text-md">{{
                  $t('olkypay.labels.beneficiariesPage.no_beneficiary_found')
                }}</span>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-fab slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button color="blue_olky">
          <ion-icon :icon="settingsOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          <ion-fab-button
            v-if="currentBeneficiary.id"
            color="success"
            @click="proceedToEditBeneficiary"
          >
            <ion-icon :icon="pencilSharp"></ion-icon>
          </ion-fab-button>
          <ion-fab-button
            v-if="currentBeneficiary.id"
            color="danger"
            @click="startRemoveBeneficiary"
          >
            <ion-icon :icon="trashOutline"></ion-icon>
          </ion-fab-button>
          <ion-fab-button color="secondary" @click="gotToRouter('NewBeneficiarySubTabRouter')">
            <ion-icon :icon="addCircle"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>
      <ion-action-sheet
        :is-open="isConfirmationModalOpen"
        :header="$t('olkypay.labels.beneficiariesPage.dialog.remove.header')"
        :sub-header="$t('olkypay.labels.beneficiariesPage.dialog.remove.sub_header')"
        :buttons="actionSheetButtons"
        @didDismiss="actionSheetResult($event)"
      ></ion-action-sheet>
    </ion-content>
    <authentication-process-popup ref="authenticationPopup"></authentication-process-popup>
    <info-modal ref="infoModal"></info-modal>
  </ion-page>
</template>

<script lang="ts">
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonButton,
  IonBackButton,
  IonButtons,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSpinner,
  IonSearchbar,
  IonFab,
  IonFabButton,
  IonFabList,
  IonActionSheet,
  alertController
} from '@ionic/vue'
import {
  addCircle,
  caretBackSharp,
  checkmarkCircle,
  chevronUpCircle,
  trashOutline,
  settingsOutline,
  pencilSharp
} from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'
import BeneficiaryAccount from '../../../components/transfer/beneficiaryAccount.vue'
import AuthenticationProcessPopup from '../../../components/security/authenticationProcessPopup.vue'
import InfoModal from '../../../components/InfoModal.vue'
import { BeneficiaryService } from '../../../services/beneficiary.service'
export default {
  name: 'BeneficiariesPage',
  components: {
    IonToolbar,
    IonHeader,
    IonTitle,
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonLabel,
    IonButton,
    IonBackButton,
    IonButtons,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSpinner,
    IonSearchbar,
    IonFab,
    IonFabButton,
    IonFabList,
    IonActionSheet,
    BeneficiaryAccount,
    AuthenticationProcessPopup,
    InfoModal
  },
  data() {
    return {
      caretBackSharp,
      chevronUpCircle,
      checkmarkCircle,
      trashOutline,
      addCircle,
      settingsOutline,
      pencilSharp,
      paginationOptions: {
        page: 1,
        search: ''
      },
      actionSheetButtons: [
        {
          text: this.$i18n.t('olkypay.labels.followUpPage.confirm_dialog_orders.buttons.confirm'),
          data: {
            action: 'confirm'
          }
        },
        {
          text: this.$i18n.t('olkypay.labels.followUpPage.confirm_dialog_orders.buttons.cancel'),
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        }
      ],
      isDataLoading: false,
      isConfirmationModalOpen: false,
      operation: ''
    }
  },
  methods: {
    ...mapActions('beneficiary', [
      'loadBeneficiaries',
      'initBeneficiaries',
      'initCurrentBeneficiary'
    ]),
    ...mapActions('transfer', ['setCurrentTransfer']),
    ...mapActions('loader', ['playLoader', 'stopLoader']),
    async displayPaginatedBeneficiaries(event) {
      this.paginationOptions.page++
      await this.loadBeneficiaries({ pagination_options: this.paginationOptions })
      event.target.complete()
    },
    async searchBeneficiaries(event) {
      this.isDataLoading = true
      this.initBeneficiaries()
      const query = event.target.value
      this.paginationOptions.search = query
      await this.loadBeneficiaries({ pagination_options: this.paginationOptions })
      this.isDataLoading = false
    },
    setOpenConfirmationModal(state: boolean) {
      this.isConfirmationModalOpen = state
    },
    async actionSheetResult(ev: CustomEvent) {
      this.setOpenConfirmationModal(false)
      if (ev.detail.data.action === 'confirm') {
        await this.proceedToRemoveBeneficiary()
      }
    },
    startRemoveBeneficiary() {
      this.setOpenConfirmationModal(true)
    },
    async authenticateUser() {
      const isSuccessAuthenticated = await this.$refs.authenticationPopup.authenticate()
      if (isSuccessAuthenticated) {
        await this.onSuccessAuthenticateUser()
      }
    },
    async proceedToRemoveBeneficiary() {
      this.operation = 'remove'
      // just for test it must be removed
      await this.authenticateUser()
    },
    async proceedToEditBeneficiary() {
      this.operation = 'edit'
      await this.onSuccessAuthenticateUser()
    },
    async onSuccessAuthenticateUser() {
      // remove beneficiary
      if (this.operation === 'remove') {
        const loaderModal = {
          title: this.$i18n.t('olkypay.loader.removeBeneficiary.title'),
          content: this.$i18n.t('olkypay.loader.please_wait')
        }

        this.playLoader(loaderModal)
        const inputData = { id: this.currentBeneficiary.id }
        await BeneficiaryService.disableBeneficiary(inputData)
          .then(async res => {
            this.stopLoader()
            const successDialog = {
              type: 'success',
              title: this.$i18n.t(
                'olkypay.notifications.success.beneficiariesPage.remove_beneficiary.header'
              ),
              content: this.$i18n.t(
                'olkypay.notifications.success.beneficiariesPage.remove_beneficiary.message'
              ),
              button: this.$i18n.t('olkypay.buttons.beneficiariesPage.dialog_close')
            }
            await this.$refs.infoModal.openModal(successDialog)
            this.isDataLoading = true
            this.initBeneficiaries()
            this.initCurrentBeneficiary({})
            await this.loadBeneficiaries({ pagination_options: this.paginationOptions })
            this.isDataLoading = false
          })
          .catch(async (err: any) => {
            this.stopLoader()
            const failedDialog = {
              type: 'warning',
              title: this.$i18n.t(
                'olkypay.notifications.error.beneficiariesPage.remove_beneficiary.header'
              ),
              content: this.$i18n.t(
                'olkypay.notifications.error.beneficiariesPage.remove_beneficiary.message'
              ),
              button: this.$i18n.t('olkypay.buttons.beneficiariesPage.dialog_close')
            }
            await this.$refs.infoModal.openModal(failedDialog)
          })
        this.stopLoader()
      }
      // edit
      if (this.operation === 'edit') {
        this.gotToRouter('EditBeneficiarySubTabRouter')
      }
    },
    gotToRouter(router_name) {
      if (router_name === 'NewBeneficiarySubTabRouter') {
        this.initCurrentBeneficiary({})
      }
      this.$router.push({ name: router_name })
    }
  },
  async mounted() {
    this.isDataLoading = true
    this.initBeneficiaries()
    this.initCurrentBeneficiary({})
    this.setCurrentTransfer({})
    await this.loadBeneficiaries({ pagination_options: this.paginationOptions })
    this.isDataLoading = false
  },
  computed: {
    ...mapGetters('beneficiary', ['beneficiaries', 'currentBeneficiary'])
  }
}
</script>
