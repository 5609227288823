import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { EmailApiClient } from '../api-client/api-client'
import { NewEmailMutationProps } from '../../types'
import { ConfirmationResponse } from '@/features/register/types'

export const useCreateEmail = () => {
  const useCreateEmailMutation = (
    options?: UseMutationOptions<ConfirmationResponse, Error, NewEmailMutationProps>
  ) => {
    const { t } = useTranslation(['pass'])

    return useMutation<ConfirmationResponse, Error, NewEmailMutationProps>({
      mutationFn: async ({ email }: NewEmailMutationProps) => {
        try {
          return await EmailApiClient.createEmail(email)
        } catch (e: any) {
          throw new Error(t('olkypass.general.error_occured'))
        }
      },
      ...options
    })
  }
  const useCheckEmailMutation = (
    options?: UseMutationOptions<NewEmailMutationProps, Error, string>
  ) => {
    const { t } = useTranslation(['pass'])
    return useMutation<NewEmailMutationProps, Error, string>({
      mutationFn: async (email: string) => {
        try {
          return await EmailApiClient.checkAlias(email)
        } catch (e: any) {
          throw new Error(t('olkypass.general.mail_already_exist'))
        }
      },
      ...options
    })
  }

  return {
    useCreateEmailMutation,
    useCheckEmailMutation
  }
}
