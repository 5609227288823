import { defineJsxComponent, type VModelProps } from '@/jsx-utils'
import { useI18n } from 'vue-i18n'

type CheckboxProps = {
  class: string
} & VModelProps<boolean>

export const TooltipBubble = defineJsxComponent<CheckboxProps>(
  () => {
    const { t } = useI18n()
    return () => <div class="relative flex justify-center items-center group">
      <p class="absolute hidden group-hover:block rounded shadow-lg p-2 bg-black text-slate-100 w-52 mr-60 z-50">
        {t('olkypass.accountPage.basicInfo.input.nifTooltip')}
      </p>
      <p class="ml-2 mt-0.5 h-7 w-7 rounded-full bg-blue-500 flex justify-center items-center text-slate-50 cursor-pointer">
        ?
      </p>
    </div>
  },
  {
    inheritAttrs: false
  }
)
