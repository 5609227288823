<template>
  <ion-page id="CodePinOnBoardingPage">
    <ion-content :fullscreen="true">
      <div class="card-header">
        <div class="header-app height-261 absolute">
          <ion-grid>
            <ion-row class="mt-4">
              <ion-col size="1">
                <div class="circleIconMenu">
                  <ion-icon
                    :icon="menu"
                    color="light"
                    size="large"
                    @click="goToRouter('MoreTabRouter')"
                  ></ion-icon>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="text-center">
                <img :src="logoOlkyPayBlue" class="" />
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div class="text-center">
          <ion-grid>
            <ion-row class="mt-6">
              <ion-col class="text-center">
                <div class="item-on-header-app mt-75">
                  <img :src="on_boarding_image" alt="Slide 1" />
                  <h1 class="font-weight-bold mt-2 m-inline-80 mb-0">
                    {{ $t('olkypay.labels.codePinSettingsPage.on_boarding_sub_page.title') }}
                  </h1>
                  <p class="mt-1 m-inline-40 mb-0">
                    {{ $t('olkypay.labels.codePinSettingsPage.on_boarding_sub_page.description') }}
                  </p>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <button
                  class="btn bg-gradient-info mb-0 ms-2 large-button"
                  @click="goToRouter('MoreAuthSettingPinSaveSubTabRouter')"
                >
                  {{ $t('olkypay.buttons.codePinSettingsPage.configure') }}
                </button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import on_boarding_image from '../../../../../../public/images/olkypay/onBoarding/onboarding_pin_code.png'
import logoOlkyPayBlue from '../../../../../../public/images/olkypay/logo-olkypay-header-blue.svg'
import { menu } from 'ionicons/icons'
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonListHeader,
  IonProgressBar,
  IonList,
  IonButton
} from '@ionic/vue'

export default {
  name: 'CodePinOnBoardingPage',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonLabel,
    IonListHeader,
    IonProgressBar,
    IonList,
    IonButton,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      menu,
      on_boarding_image,
      logoOlkyPayBlue
    }
  },
  methods: {
    goToRouter(router_name) {
      this.$router.push({ name: router_name })
    }
  }
}
</script>

<style scoped>
/* Add your custom styles here */
</style>
