import { useToast } from 'vue-toast-notification'

const state = {
  notifications: []
}

const getters = {
  notifications: state => state.notifications
}

const mutations = {
  PUSH_NOTIFICATION(state, notification) {
    state.notifications.push({
      ...notification,
      id: (Math.random().toString(36) + Date.now().toString(36)).substr(2)
    })
  },
  CLEAR_NOTIFICATION(state, notification) {
    state.notifications = state.notifications.filter(obj => obj.id !== notification.id)
  },
  DISPLAY_NOTIFICATION(state, notification) {
    const toast = useToast()
    switch (notification.type) {
      case 'error':
        toast.error(notification.message)
        break
      case 'success':
        toast.success(notification.message)
        break
      default:
        toast.info(notification.message)
        break
    }
  }
}

const actions = {
  addNotification({ commit }, notification) {
    commit('DISPLAY_NOTIFICATION', notification)
  },
  removeNotification({ commit }, notification) {
    commit('CLEAR_NOTIFICATION', notification)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
