const state = {
  currentTheme: 'light'
}

const getters = {
  currentTheme: state => state.currentTheme
}

const mutations = {
  async SET_CURRENT_THEME(state, theme) {
    state.currentTheme = theme
    window.localStorage.setItem('theme', theme)
  }
}

const actions = {
  async setCurrentTheme({ commit }, theme) {
    commit('SET_CURRENT_THEME', theme)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
