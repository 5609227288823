import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import { useAddress } from '@/features/user/address/hooks/use-address'
import { useUser } from '@/features/user/hooks/use-user'
import { useDeleteAlias } from '@/features/user/hooks/use-delete-alias'
import { AddressCard } from '@/molecules/address-card/index.react'
import { ModalAddAddress } from '@/molecules/modal-add-address/index.react'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../../../_layout/index.react'
import { AddressFullType } from '@/features/user/types'
import { NaturalEntityDataType } from '@/features/utils/types'
import { ModalDeleteAlias } from '@/molecules/modal-delete-alias/index.react'
import { Base } from '@/atoms-react/text/Text.react'

type AliasesAddressProps = React.ComponentPropsWithoutRef<'div'>

export const AliasesAddressApp = ({ ...props }: AliasesAddressProps) => {
  const { t } = useTranslation(['pass'])
  const { useUserProfile, useEditProfile } = useUser()
  const { data: user, refetch } = useUserProfile()
  const addresses = user?.naturalPerson.Address

  const [editAddress, setEditAddress] = useState<AddressFullType | null>(null)

  const [modalAddAddress, setModalAddAddress] = useState<boolean>(false)
  const [deletedAddress, setDeletedAddress] = useState<AddressFullType | null>(null)
  const { createAddressMutation } = useAddress()
  const { mutateAsync: createAddress } = createAddressMutation()
  const { mutateAsync: editProfile } = useEditProfile()
  const { mutateAsync: deleteAlias } = useDeleteAlias()

  const onEdit = (address: AddressFullType) => {
    setEditAddress(address)
    setModalAddAddress(true)
  }

  const onCreate = () => {
    setEditAddress(null)
    setModalAddAddress(true)
  }

  const onDelete = async () => {
    if (!deletedAddress) {
      return
    }
    await deleteAlias({
      ItemType: NaturalEntityDataType.AdresseDto,
      ItemUUID: deletedAddress.UUID || '',
      UUID: user?.naturalPerson?.UUID || ''
    })
    await refetch()
    setDeletedAddress(null)
  }

  return (
    <Column {...props}>
      <LayoutTitle
        action={
          <Button onClick={onCreate} icon={PlusCircleIcon}>
            {t('aliasAddressPage.button.add_address')}
          </Button>
        }
      >
        {t('aliasAddressPage.title')}
      </LayoutTitle>

      <ModalAddAddress
        address={editAddress}
        open={modalAddAddress}
        onClose={() => setModalAddAddress(false)}
        onValidated={async (address) => {
          if (editAddress) {
            const updatedAddress: AddressFullType = {
              ...editAddress,
              Address1: address.address,
              ZipCode: address.zip,
              Country: address.country?.toString() || '',
              City: address.city,
              Tag: address.tag,
              isPrincipalAddress: address.isPrincipalAddress
            }
            await editProfile({
              typeDto: NaturalEntityDataType.AdresseDto,
              UUID: user?.naturalPerson.UUID || '',
              Address: updatedAddress
            })
            toast.success(t('aliasAddressPage.address_updated'))
          } else {
            await createAddress({ userUUID: user?.naturalPerson?.UUID || '', address })
              .then(() => toast.success(t('success.create')))
              .catch(() => toast.error(t('error.create')))
          }
          setModalAddAddress(false)
          refetch()
        }}
      />
      <ModalDeleteAlias
        isOpen={!!deletedAddress}
        onClose={() => setDeletedAddress(null)}
        onValidated={onDelete}
      >
        <Base>{t('aliasAddressPage.confirm_delete', { name: deletedAddress?.Tag })}</Base>
      </ModalDeleteAlias>
      <div className={'flex flex-wrap gap-4'}>
        {addresses &&
          addresses?.map((address) => (
            <AddressCard
              key={address.UUID}
              className="flex-1 max-w-md"
              address={address}
              onDelete={() => setDeletedAddress(address)}
              onEdit={() => onEdit({ ...address })}
            />
          ))}
      </div>
    </Column>
  )
}
