import { fetchServer } from '@/features/utils/fetch'

export class EmailApiClient {
  static async checkAlias(email: string) {
    const response = await fetchServer('/aliasMail/check_alias_email', {
      method: 'POST',
      reloadOn401: false,
      body: {
        email
      }
    })
    if (response.status !== 200) {
      throw new Error('Email already exist')
    }
    const res = await response.json()
    return res as { email: string }
  }

  /**
   *
   * @param email
   */
  static async createEmail(email: string) {
    const response = await fetchServer('/aliasMail/create_new', {
      method: 'POST',
      reloadOn401: false,
      body: {
        email
      }
    })
    if (response.status !== 200) {
      throw new Error('Cannot create new email.')
    }
    return { success: true, message: '' }
  }
}
