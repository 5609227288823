import { useTranslation } from 'react-i18next'
import { LanguageCode } from '../constants'

export type Locale = {
  language: LanguageCode
}

export const useLocale = (): { locale: Locale } => {
  const { i18n } = useTranslation()
  const language = i18n.language.toUpperCase()

  return { locale: { language: language as LanguageCode } }
}
