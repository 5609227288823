import _ from 'lodash'
import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { twMerge } from 'tailwind-merge'

interface FlagProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  countryCode: string
  className?: React.ComponentPropsWithoutRef<'svg'>['className']
}

function Flag({ className, countryCode, ...props }: FlagProps) {
  return <ReactCountryFlag
    countryCode={countryCode}
    style={{ width: 'auto', height: '1.25em', borderRadius: '3px' }}
    className={twMerge(className)}
    svg
    {..._.omit(props, 'className', 'countryCode')}
  />
}

export default Flag
