import Image from '@/atoms-react/image/Image.react'
import { Base, SectionTitle } from '@/atoms-react/text/Text.react'
import { useTranslation, Trans } from 'react-i18next'
import { FormFooter } from './form-footer.react'

type WelcomeStepProps = {
  onValidated: () => void
}

export const WelcomeStep = ({ onValidated }: WelcomeStepProps) => {
  const { t } = useTranslation(['atoms'])
  return (
    <>
      <div className="max-w-4xl py-4 mx-auto text-center px-4">
        <Image
          className="h-auto md:w-80 w-60 mx-auto mb-4"
          alt={t('molecules.modal_kyc_identity.step1_image_alt')}
          src={'/images/olkypass/informationStep1.png'}
        />
        <div className="mx-auto text-center px-4">
          <SectionTitle className="text-base mb-4">
            {t('molecules.modal_kyc_identity.step1_title')}
          </SectionTitle>
          <Base className="text-gray-400 font-medium">
            <Trans
              i18nKey={'molecules.modal_kyc_identity.step1_content_new'}
              ns={'atoms'}
              components={{ span: <span className="text-blue-500 font-semibold" /> }}
            />
          </Base>
        </div>
      </div>
      <FormFooter currentStep={0} onNext={onValidated} />
    </>
  )
}
