import config from '@/config'
import toast from 'react-hot-toast'

export async function addKypayToWallet() {
  /* @ts-expect-error Etherium is added via Metamask */
  const { ethereum } = window

  if (!ethereum) {
    toast.error("Impossible de récuperer l'instance MetaMask")
  } else {
    await ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: config.TOKEN_ADDRESS,
          symbol: config.TOKEN_SYMBOL,
          decimals: config.TOKEN_DECIMALS,
          image: config.TOKEN_IMAGE
        }
      }
    })
  }
}

export async function selectPolygonNetwork() {
  /* @ts-expect-error Etherium is added via Metamask */
  const { ethereum } = window
  const chainId = await ethereum.request({ method: 'eth_chainId' })
  if (chainId !== config.SITE_NETCHAIN_ID) {
    try {
      await switchNetwork()
    } catch (switchError) {
      if ((switchError as any)?.code === 4902) {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: config.SITE_NETCHAIN_ID,
              chainName: config.CHAIN_NAME,
              nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18
              },
              rpcUrls: config.POLYGON_NODE,
              blockExplorerUrls: [config.BLOCK_EXPLORER]
            }
          ]
        })
        await switchNetwork()
      }
    }
  }
}

export async function switchNetwork() {
  /* @ts-expect-error Etherium is added via Metamask */
  const { ethereum } = window
  await ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: config.SITE_NETCHAIN_ID }]
  })
}

export function isMetamaskActive() {
  /* @ts-expect-error Etherium is added via Metamask */
  const { ethereum } = window
  return !!ethereum
}
