<template>
  <v-dialog v-model="dialog" persistent width="auto" height="auto">
    <v-card class="cardBoxPopup">
      <v-card-title class="text-h5">{{
        $t('olkypass.accountPage.mobiles_section.dialog.header.new')
      }}</v-card-title>
      <v-card-text>
        <v-form ref="mobileForm" @submit.prevent="checkMobileAvailability">
          <v-row>
            <v-col cols="12">
              <label>{{ $t('olkypass.accountPage.mobiles_section.input.number') }}</label>
              <VueTelInput
                v-model="MobileNumber"
                :input-options="inputOptions"
                @country-changed="countryChanged"
              ></VueTelInput>
              <v-input persistent-hint clearable error :messages="availability_message"> </v-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col class="d-flex justify-space-between">
            <v-btn
              variant="flat"
              color="white"
              height="auto"
              class="text-none cancelBtn"
              size="medium"
              @click="cancel"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.cancel') }}
            </v-btn>
            <v-btn
              variant="flat"
              color="primary"
              height="auto"
              class="text-none validationBtn"
              :loading="loader"
              size="medium"
              @click="checkMobileAvailability"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import NotificationsMixins from '../../mixins/NotificationsMixins.js'

import { VueTelInput } from 'vue-tel-input'
import { mapGetters } from '../../store'
import store from '../../store'

export default {
  name: 'MobileFormDialog',
  components: {
    VueTelInput
  },
  mixins: [NotificationsMixins],
  data: () => ({
    dialog: false,
    MobileNumber: null,
    MobileIndicatif: null,
    loader: false,
    uri: '',
    availability_message: '',
    inputOptions: {
      required: true
    },
    setCurrentEntityFunctionName: ''
  }),
  methods: {
    open() {
      this.dialog = true
      this.MobileNumber = null
      this.MobileIndicatif = null
      this.availability_message = ''
      if (this.currentTreatedEntity === 'company') {
        this.uri = 'company/mobile/create_new'
        this.setCurrentEntityFunctionName = 'setCurrentCompany'
      } else {
        this.uri = 'user/mobile/create_new'
        this.setCurrentEntityFunctionName = 'setCurrentNaturalPerson'
      }
    },
    cancel() {
      this.dialog = false
    },
    async isValidForm() {
      const { valid } = await this.$refs.mobileForm.validate()
      return valid
    },
    async checkMobileAvailability() {
      /*let isValidForm = await this.isValidForm();
      if (isValidForm) {*/
      if (!this.MobileNumber) {
        this.availability_message =
          this.$i18n.t('olkypass.accountPage.mobiles_section.input.number') +
          ' ' +
          this.$i18n.t('login.registerPage.validation.required_field')
      } else {
        this.loader = true
        this.availability_message = ''
        await fetchServer(this.uri, {
          body: {
            UUID: this.currentUUID,
            MobileNumber: this.MobileNumber,
            MobileIndicatif: this.MobileIndicatif
          }
        })
          .then(async response => {
            this.displayNotification(
              'success',
              this.$i18n.t('olkypass.accountPage.notifications.success.profile.update')
            )
            await store.dispatch(this.setCurrentEntityFunctionName, {
              UUID: this.currentUUID
            })
            this.cancel()
          })
          .catch(error => {
            this.availability_message = this.$i18n.t(
              'olkypass.accountPage.notifications.error.mobile.create'
            )
          })
          .finally(() => this.loader = false)
      }
    },
    countryChanged(country) {
      this.MobileIndicatif = '+' + country.dialCode
    }
  },
  computed: {
    ...mapGetters(['currentTreatedEntity', 'currentUUID']),
    requiredField() {
      /*if (!this.MobileNumber) {
        return this.$i18n.t('olkypass.accountPage.mobiles_section.input.number') + ' ' + this.$i18n.t('login.registerPage.validation.required_field')
      }*/
      const $this = this
      return function (fieldName) {
        return (v) =>
          !!v ||
          $this.$i18n.t('olkypass.' + fieldName) +
            ' ' +
            $this.$i18n.t('login.registerPage.validation.required_field')
      }
    }
  }
}
</script>

<style scoped></style>
