<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { ref, watch, onBeforeUnmount, onMounted } from 'vue'
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  open: Boolean,
  onClose: { type: Function, default: null },
  className: { type: String, default: '' },
  style: { type: Object, default: {} as any },
  closable: { type: Boolean, default: true }
})

const internalOpen = ref(false)

const level = ref(0)
const zIndex = ref(`z-${level.value}0`)
const openStatus = ref(false)

// Custom logic for managing the number of modals, replace this with your own state management logic
const modalsCountState = ref(0)
const visibleModals = ref(0)

const internalOnClose = () => {
  openStatus.value = false
  visibleModals.value--
  modalsCountState.value = visibleModals.value
}

const onOpen = () => {
  openStatus.value = true
  visibleModals.value++
  level.value = visibleModals.value
  modalsCountState.value = visibleModals.value
}

watch(
  () => props.open,
  newVal => {
    internalOpen.value = newVal
    if (newVal) {
      onOpen()
    } else {
      internalOnClose()
    }
  }
)

onBeforeUnmount(() => {
  if (openStatus.value) internalOnClose()
})

onMounted(() => {
  internalOpen.value = false // Initialize with default or passed prop value
})

//On edge only because it sucks and wont focus correctly the inputs
const isEdge = navigator.userAgent.includes('Edg/') || navigator.userAgent.includes('Edge/')
</script>

<template>
  <TransitionRoot
    v-if="!((!internalOpen || level !== modalsCountState) && isEdge)"
    :show="internalOpen"
  >
    <Dialog as="div" :class="'relative ' + zIndex" @close="() => {}">
      <TransitionChild
        enter="ease-out duration-300"
        enter-from="opacity-0 pointer-events-none"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0 pointer-events-none"
      >
        <div
          :class="
            'fixed inset-0 bg-opacity-25 dark:bg-opacity-75 transition-opacity ' +
            (level === 1 || isEdge ? 'bg-black' : 'bg-transparent')
          "
        />
      </TransitionChild>

      <div
        :class="
          'fixed z-50 inset-0 overflow-y-auto transition-transform ' +
          (level !== modalsCountState && internalOpen
            ? '-translate-y-6 sm:scale-95 opacity-75 '
            : level !== modalsCountState && !internalOpen
              ? 'translate-y-6 sm:scale-95 opacity-75 '
              : '')
        "
      >
        <div class="flex items-end justify-center min-h-dvh text-center sm:block">
          <span class="hidden sm:inline-block sm:align-middle sm:h-dvh" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            :as="DialogPanel"
            enter="ease-out duration-300"
            enter-from="opacity-0 pointer-events-none sm:translate-y-0 translate-y-4 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 pointer-events-none sm:translate-y-0 translate-y-4 sm:scale-95"
            :class="
              'rounded-t-md sm:rounded-md align-bottom bg-white dark:bg-slate-900 px-4 pt-5 pb-4 text-left w-full sm:w-auto overflow-hidden shadow-xl transform sm:align-middle sm:p-6 ' +
              'relative inline-block sm: sm:my-8 w-full sm:w-full sm:max-w-[400px] ' +
              (props.className || '')
            "
            :style="{
              ...(props.style || {})
            }"
            @focus="(e: Event) => e.stopPropagation()"
          >
            <div v-if="props.closable" className="z-20 absolute top-1 right-1 pt-4 pr-4">
              <button
                type="button"
                class="bg-slate-300 dark:bg-slate-600 rounded-full p-1 text-slate-600 dark:text-slate-300 hover:opacity-75 focus:outline-none"
                @click="() => onClose && onClose()"
              >
                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <slot></slot>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
