import Image from '@/atoms-react/image/Image.react'
import { Base } from '@/atoms-react/text/Text.react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { Button } from '@/atoms-react/button/Button.react'
import { Themes } from '@/types/theme'

export const OlkywalletCard = () => {
  const { t } = useTranslation(['pass'])

  const olkyCardsClassName =
    'w-full h-[170px] lg:h-[185px] xl:h-[195px] 2xl:h-[200px] p-4 md:py-6 flex '
  const baseTextClassName = 'text-white font-semibold mt-0 z-10'

  const redirectToWalletDashboard = () => {
    /* navigate(getRoute(Routes.wallet_dashboard), {
      replace: true
    })*/
  }
  return (
    <>
      <div
        className={twMerge(
          'w-full  opacity-1 relative overflow-hidden bg-cover bg-center  bg-[url("/images/olkypass/bg_olky_wallet.jpg")]  rounded-sm'
        )}
        onClick={redirectToWalletDashboard}
      >
       
        <div className={`${olkyCardsClassName} flex-col items-left justify-start`}>
          <Base className={twMerge(baseTextClassName, 'text-left')}>
            {t('olkypass.general.olky_wallet')}
          </Base>
          <div className="mt-auto flex-1 flex items-end">
            <Button
              size="md"
              theme={Themes.white}
              disabled
              onClick={redirectToWalletDashboard}
              className="border-none text-orange-500 mt-4 bg-white dark:bg-white text-black dark:text-black   h-8 sm:h-10 w-48 text-xs  sm:text-base lg:h-8  lg:text-sm xl:text-base w-68  xl:h-10 xl:text-lg"
            >
              {t('olkypass.mainPage.olkypass.bloc3.btn')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
