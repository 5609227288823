import { DocumentTypeSelector } from './document-type-selector.react'
import { Base, SectionTitle } from '@/atoms-react/text/Text.react'
import { useTranslation, Trans } from 'react-i18next'
import { KYCDocumentType } from '@/features/kyc/types'
import { useState } from 'react'
import Column from '@/atoms-react/column/Column.react'
import { FormFooter } from './form-footer.react'

type DocumentTypeStepProps = {
  onValidated: (documentType: KYCDocumentType) => void
  onPrevious: () => void
}

export const DocumentTypeStep = ({ onPrevious, onValidated }: DocumentTypeStepProps) => {
  const { t } = useTranslation(['atoms'])
  const [documentType, setDocumentType] = useState<KYCDocumentType | null>(null)
  return <>
    <div className="max-w-4xl py-4 mx-auto text-center px-4">
      <SectionTitle className="text-base mb-4">
        {t('molecules.modal_kyc_identity.step2_title')}
      </SectionTitle>
      <Base className="text-gray-400 font-medium">
        <Trans
          i18nKey={'molecules.modal_kyc_identity.step2_content'}
          ns={'atoms'}
          components={{ span: <span className="text-blue-500 font-semibold" /> }}
        />
      </Base>
      <Column className="gap-2 text-left mt-6">
        <Base className="text-gray-800 font-semibold">
          {t('molecules.modal_kyc_identity.document_type')}
        </Base>
        <DocumentTypeSelector
          onDocumentSelected={documentType => setDocumentType(documentType)}
        />
      </Column>
    </div>
    <FormFooter
      onPrevious={onPrevious}
      onNext={() => documentType && onValidated(documentType)}
      nextDisabled={!documentType}
      currentStep={1}
    />
  </>
}
