<template>
  <v-col cols="12" sm="12" md="6" lg="4">
    <v-card class="mx-auto elementCard">
      <template #prepend>
        <v-avatar>
          <v-icon v-if="!document.selfUpload" color="white">mdi-clipboard-text</v-icon>
          <v-icon v-else color="white">mdi-cloud-upload-outline</v-icon>
        </v-avatar>
      </template>
      <template #title>
        {{ document.typePerLanguage[currentLanguage] }}
      </template>
      <v-card-text class="elementData">
        <v-card-title>
          <span v-if="document.Number > 0"
            >{{ $t('olkypass.accountPage.documentsSection.help.documentNumber') }}
            {{ document.Number }}</span
          >
          <span v-if="document.Validity && document.Number > 0"> - </span>
          <span v-if="document.Validity"
            >{{ $t('olkypass.accountPage.documentsSection.help.documentValidity') }}:
            {{ formatValidityDate }}</span
          >
        </v-card-title>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" icon :loading="loader" @click="downloadDocument()">
          <v-icon>mdi-download</v-icon>
          <v-tooltip activator="parent" location="top">
            {{ $t('olkypass.accountPage.documentsSection.button.download') }}
          </v-tooltip>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters } from '../../store'
import { fetchServer } from '@/features/utils/fetch'

export default {
  name: 'DocumentItem',
  props: ['document'],
  data: () => ({
    uri: 'document/download',
    loader: false
  }),
  methods: {
    async downloadDocument() {
      this.loader = true
      await fetchServer(this.uri, {
        body: { algoregDocumentId: this.document.algoregDocumentId, UUID: this.naturalPerson.UUID },
        responseType: 'blob'
      })
        .then(async response => {
          const blobData = await response.blob()
          const extension = this.document.selfUpload ? 'pdf' : 'jpg'
          const url = window.URL.createObjectURL(new Blob([blobData]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', this.document.algoregDocumentId + '.' + extension)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {})
        .finally(() => this.loader = false)
    }
  },
  computed: {
    ...mapGetters(['currentLanguage', 'naturalPerson']),
    formatValidityDate() {
      if (this.document.Validity.date) {
        const inputDate = new Date(this.document.Validity.date)
        return inputDate.getFullYear() + '-' + ('0' + (inputDate.getMonth() + 1)).slice(-2)
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped></style>
