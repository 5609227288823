import { Button } from '@/atoms-react/button/Button.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { useKypay } from '@/features/kypay/hooks/use-kypay'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatCurrencyInput, formatNumber } from '@/features/utils/functions.ts'
import { SearchOlkyPassIdentity, TransferKypayAmountProps } from '@/features/kypay/types.ts'
import { MagnifyingGlassCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Base } from '@/atoms-react/text/Text.react.tsx'
import { Alert, AlertType } from '@/atoms-react/alert/index.react.tsx'
import { KYCStatusType } from '@/features/kyc/types.ts'
import toast from 'react-hot-toast'
import { SolidAuthnModal } from '../../../identity/components/security/solid-authn-modal.react'
enum TransferTypes {
  INTERNAL = 'internal',
  EXTERNAL = 'external'
}

export const ModalTransferKypay = ({ ...props }: ModalProps) => {
  const { t } = useTranslation(['wallet'])
  const [amountString, setAmountString] = useState<string>('0,00')
  const [locked, setLocked] = useState<boolean>(false)
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null)
  const [suggestions, setSuggestions] = useState<SearchOlkyPassIdentity[]>()
  const [searching, setSearching] = useState<boolean>(false)
  const [displayAuthenticationModal, setDisplayAuthenticationModal] = useState<boolean>(false)
  const [actionData, setActionData] = useState<string | null>(null)
  const [transfer, setTransfer] = useState<TransferKypayAmountProps>({})
  const [selectedIdentity, setSelectedIdentity] = useState<SearchOlkyPassIdentity | null>(null)
  const [noItemFound, setNoItemFound] = useState<string | null>(null)

  const { useSearchIdentitiesMutation, kypayAccountsQuery, transferKypayAmountMutation } =
    useKypay()
  const { data: accounts } = kypayAccountsQuery()
  const { mutateAsync: searchIdentities } = useSearchIdentitiesMutation()
  const { mutateAsync: transferKypayAmount, isPending } = transferKypayAmountMutation()
  const resetInputs = () => {
    setAmountString(formatNumber(0))
    onClear()
    setTransfer()
  }
  const inputRef = useRef<HTMLInputElement | null>()
  useEffect(() => {
    forceCursorPosition()
  }, [amountString])

  const forceCursorPosition = () => {
    if (inputRef.current) {
      const inputLength = inputRef.current.value.length
      inputRef.current.setSelectionRange(inputLength, inputLength) // Force cursor to the end on focus
    }
  }

  const onChangeSearchKeyword = (value) => {
    if (locked) return null
    setSearchKeyword(value)
    setNoItemFound(null)
  }
  const onInputSearchKeyword = async () => {
    setNoItemFound(null)
    setSearching(true)
    setSuggestions([])
    const response = await searchIdentities({ keyword: searchKeyword })
    if (response.length === 0) {
      setNoItemFound(t('kypay.modal_transfer_kypay.toast.no_item_found'))
    }
    setSearching(false)
    setSuggestions(response)
  }
  const onClickSuffixIcon = async () => {
    if (locked) {
      onClear()
    } else {
      await onInputSearchKeyword()
    }
  }

  const onClear = () => {
    setSearchKeyword('')
    setLocked(false)
    setNoItemFound(null)
    setTransfer((prev) => ({
      ...prev,
      to: null
    }))
    setSelectedIdentity(null)
  }
  const getStatusDescription = (status: string) => {
    if (
      status === KYCStatusType.AUTHENTICATED ||
      status === KYCStatusType.AUTHENTICATED_BRONZE ||
      status === KYCStatusType.AUTHENTICATED_SILVER ||
      status === KYCStatusType.AUTHENTICATED_GOLD
    ) {
      return t('kypay.modal_transfer_kypay.input.passport.status_authenticated')
    } else if (status === KYCStatusType.VERIFIED) {
      return t('kypay.modal_transfer_kypay.input.passport.status_verified')
    } else {
      return t('kypay.modal_transfer_kypay.input.passport.status_declarative')
    }
  }
  const getStatus = (identity: SearchOlkyPassIdentity) => {
    const status =
      KYCStatusType[
        identity.olkyPassLevel?.olkypass_status?.english_name
          .toLocaleUpperCase()
          .replace(' ', '_') as keyof typeof KYCStatusType
      ]
    return status
  }
  const handleExecuteTransfer = async () => {
    handleCloseAuthModal()
    try {
      await transferKypayAmount(transfer)
      resetInputs()
      props.onClose?.()
      toast.success(t('kypay.modal_transfer_kypay.toast.success_transfer'))
    } catch (e) {
      toast.error(t('kypay.modal_transfer_kypay.toast.error_transfer'))
    }
  }
  const handleCloseAuthModal = () => {
    setDisplayAuthenticationModal(false)
  }
  return (
    <>
      <Modal
        className="sm:w-full w-full max-w-lg"
        {...props}
        onClose={() => {
          resetInputs()
          props.onClose?.()
        }}
      >
        <ModalContent title={t('kypay.modal_transfer_kypay.label.header')}>
          <div className={'mt-4 opacity-1'}>
            <InputLabel
              value={amountString}
              onClick={forceCursorPosition}
              onChange={(value: string) => {
                const formattedValue = formatCurrencyInput(value)
                const parsed = parseFloat(formattedValue)
                if (!isNaN(parsed)) {
                  setTransfer((prev) => ({
                    ...prev,
                    amount: parsed * 100
                  }))
                  setAmountString(formatNumber(parsed))
                } else {
                  setAmountString(formatNumber(0))
                }
              }}
              suffixIcon={() => <span>€</span>}
              type="text"
              label={t('kypay.modal_transfer_fund.input.amount_label')}
              ref={inputRef}
            />

            <InputLabel
              label={t('kypay.modal_transfer_kypay.label.title')}
              required={false}
              className={'mt-6 mb-2'}
              inputComponent={
                <>
                  <Dropdown
                    items={[TransferTypes.INTERNAL, TransferTypes.EXTERNAL].map((o) => ({
                      key: o,
                      value: t('kypay.modal_transfer_kypay.select.source.' + o)
                    }))}
                    placeholder={t('kypay.modal_transfer_kypay.select.source.placeholder')}
                    search
                    onChange={({ key }) => {
                      setTransfer((prev) => ({
                        ...prev,
                        type: key
                      }))
                      setTransfer((prev) => ({
                        ...prev,
                        to: null
                      }))
                      setSuggestions([])
                      setSearchKeyword('')
                      setSelectedIdentity(null)
                      setLocked(false)
                    }}
                    initialValue={null}
                  />
                </>
              }
            />

            {transfer?.type === TransferTypes.EXTERNAL && (
              <>
                <InputLabel
                  value={searchKeyword}
                  type="text"
                  placeholder={t('kypay.modal_transfer_kypay.input.placeholder')}
                  className={'mt-3'}
                  onChange={(value) => {
                    onChangeSearchKeyword(value)
                  }}
                  suffixIcon={!locked ? MagnifyingGlassCircleIcon : XMarkIcon}
                  onClickSuffixIcon={() => {
                    onClickSuffixIcon()
                  }}
                ></InputLabel>
                {suggestions && suggestions.length > 0 && (
                  <ul className="overflow-auto z-50  max-h-40 list-outside hover:list-inside mt-1 bg-white rounded-sm dark:bg-slate-900  hover:border-slate-100 w-full font-semibold  border-2 duration-300 border-slate-50 dark:border-slate-800 text-black dark:text-white dark:hover:bg-slate-900 dark:hover:border-slate-800 focus:bg-white dark:focus:bg-slate-900 focus:border-blue-500 dark:focus:border-blue-500 placeholder:font-semibold placeholder:text-slate-400 dark:placeholder:text-slate-600 ">
                    {suggestions.slice(0, 20).map((item: SearchOlkyPassIdentity) => {
                      const status = getStatus(item)
                      const description = getStatusDescription(status)
                      return (
                        <li
                          onClick={() => {
                            setTransfer((prev) => ({
                              ...prev,
                              to: item.uuid
                            }))
                            setLocked(true)
                            setSuggestions([])
                            setSelectedIdentity(item)
                          }}
                          key={item.uuid}
                          className="my-1 cursor-pointer hover:bg-slate-50 dark:bg-slate-900 dark:hover:bg-slate-600 hover:rounded-sm	px-4 py-4"
                        >
                          <Base>
                            <span className={'font-semibold'}>
                              {item.firstName} {item.lastName}{' '}
                            </span>
                            <span className={'text-sm'}>
                              {t('kypay.modal_transfer_kypay.input.passport.label')}{' '}
                            </span>
                            <span className={'font-semibold'}>{description}</span>
                          </Base>
                        </li>
                      )
                    })}
                  </ul>
                )}
                {searching && (
                  <ul className="overflow-auto z-50  max-h-40 list-outside hover:list-inside mt-1 bg-white rounded-sm dark:bg-slate-900  hover:border-slate-100 w-full font-semibold  border-2 duration-300 border-slate-50 dark:border-slate-800 text-black dark:text-white dark:hover:bg-slate-900 dark:hover:border-slate-800 focus:bg-white dark:focus:bg-slate-900 focus:border-blue-500 dark:focus:border-blue-500 placeholder:font-semibold placeholder:text-slate-400 dark:placeholder:text-slate-600 ">
                    <li className={'my-1 px-4 py-4'}>
                      {t('kypay.modal_transfer_kypay.label.searching')}
                    </li>
                  </ul>
                )}
              </>
            )}
            {transfer?.type === TransferTypes.INTERNAL && (
              <Dropdown
                className={'mt-3'}
                items={accounts?.map((o) => ({
                  key: o.account_id,
                  value: 'KYPAY - ' + o.partner_name
                }))}
                placeholder={t('kypay.modal_transfer_kypay.select.source.placeholder')}
                search
                onChange={({ key }) => {
                  setTransfer((prev) => ({
                    ...prev,
                    to: key
                  }))
                }}
                initialValue={null}
              />
            )}

            {selectedIdentity && (
              <Alert
                type={
                  [
                    KYCStatusType.AUTHENTICATED,
                    KYCStatusType.AUTHENTICATED_BRONZE,
                    KYCStatusType.AUTHENTICATED_SILVER,
                    KYCStatusType.AUTHENTICATED_GOLD
                  ].includes(getStatus(selectedIdentity))
                    ? AlertType.Success
                    : [KYCStatusType.VERIFIED].includes(getStatus(selectedIdentity))
                      ? AlertType.Warning
                      : AlertType.Danger
                }
                title=""
                className={'mt-4'}
              >
                <Base>
                  <span className={'font-semibold'}>
                    {selectedIdentity.firstName} {selectedIdentity.lastName}{' '}
                  </span>
                  <span className={'text-sm'}>
                    {t('kypay.modal_transfer_kypay.input.passport.label')}{' '}
                  </span>
                  <span className={'font-semibold'}>
                    {getStatusDescription(getStatus(selectedIdentity))}
                  </span>
                </Base>
              </Alert>
            )}
            {noItemFound && (
              <Alert type={AlertType.Info} title="" className={'mt-4'}>
                <Base>
                  <span className={'text-sm'}>{noItemFound}</span>
                </Base>
              </Alert>
            )}
            <Button
              disabled={!transfer?.amount || !amountString || !transfer?.to}
              className="mt-4"
              loading={isPending}
              onClick={async () => {
                if (transfer.amount) {
                  if (transfer?.type === TransferTypes.EXTERNAL) {
                    setActionData(
                      JSON.stringify({
                        firstName: selectedIdentity.firstName,
                        lastName: selectedIdentity?.lastName,
                        amount: transfer.amount
                      })
                    )
                    setDisplayAuthenticationModal(true)
                  } else {
                    handleExecuteTransfer()
                  }
                }
              }}
            >
              {t('kypay.modal_transfer_kypay.button.transfer')}
            </Button>
          </div>
        </ModalContent>
      </Modal>
      {displayAuthenticationModal && (
        <SolidAuthnModal
          action="transfer"
          actionData={actionData}
          onClose={handleCloseAuthModal}
          onValidated={async () => {
            handleExecuteTransfer()
          }}
        />
      )}
    </>
  )
}
