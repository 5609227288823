import config from '@/config'
import { captureException } from '@sentry/browser'
import Web3 from 'web3'

export const selectWeb3Rpc = async () => {
  const urls = config.POLYGON_NODE
  // Try rpc urls until one works
  for (const url of urls) {
    try {
      const web3 = new Web3(new Web3.providers.HttpProvider(url))
      await web3.eth.net.getId()
      return url
    } catch (e) {
      console.error(e)
    }
  }
  captureException(new Error('No RPC node available'))
  return urls[0]
}

export const getWeb3Client = async () => {
  const httpProvider = new Web3.providers.HttpProvider(await selectWeb3Rpc())
  const web3Client = new Web3(httpProvider)
  return web3Client
}
