export const isSystemThemeDark = () => {
  const localTheme = getTheme()
  return (
    (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ||
    (localTheme && localTheme === 'dark')
  )
}

export const getTheme = () => {
  const systemTheme =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  const storedTheme = localStorage.getItem('theme')
  return storedTheme === 'myCustomLightTheme' ? systemTheme : storedTheme || systemTheme
}
export const setTheme = async (theme: string) => {
  localStorage.setItem('theme', theme)
  document.documentElement.classList.toggle('dark', theme === 'dark')
}
