import { defineStore } from 'pinia'
import type {
  CollaboratorType,
  CompanyPermissionType,
  CompanyDTO,
  KYCType
} from '@/features/company/types'

export const CompanyStore = defineStore('CompanyStore', {
  state: (): {
    companies: Array<CompanyDTO> | null
    loading: boolean | null
    initialized: boolean | null
    currentCompanyKYC: KYCType | null
    currentCompany: CompanyDTO | null
    currentCompanyPermission: CompanyPermissionType | null
  } => ({
    companies: null,
    loading: true,
    initialized: false,
    currentCompanyKYC: null,
    currentCompany: null,
    currentCompanyPermission: null
  }),
  actions: {
    set(companies: Array<CompanyDTO> | null) {
      this.companies = companies
    },
    setCurrentCompanyKYC(companyKYC: KYCType) {
      this.currentCompanyKYC = companyKYC
    },
    setCurrentCompany(company: any = null) {
      this.currentCompany = company
    },
    setLoading(loading: boolean) {
      this.loading = loading
    },
    setInitialized(initialized: boolean) {
      this.initialized = initialized
    },
    setCurrentCompanyPermissions(companyPermission: CompanyPermissionType) {
      this.currentCompanyPermission = companyPermission
    }
  }
})

export const CollaboratorStore = defineStore('CollaboratorStore', {
  state: (): {
    loading: boolean | null
    initialized: boolean | null
    collaborators: Array<CollaboratorType> | null
  } => ({
    loading: true,
    initialized: false,
    collaborators: null
  }),
  actions: {
    set(collaborators: Array<CollaboratorType> | null) {
      this.collaborators = collaborators
    },
    setLoading(loading: boolean) {
      this.loading = loading
    },
    setInitialized(initialized: boolean) {
      this.initialized = initialized
    }
  }
})
