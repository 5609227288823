<template>
  <v-dialog v-model="dialog" persistent width="auto">
    <v-card class="cardBoxPopup">
      <v-card-title class="text-h5">{{
        $t('olkypass.accountPage.ibans_section.dialog.header.new')
      }}</v-card-title>
      <v-card-text>
        <v-form ref="ibanForm" @submit.prevent="checkIbanAvailability">
          <v-row>
            <v-col cols="12">
              <label>{{ $t('olkypass.accountPage.ibans_section.input.iban') }}<sup>*</sup></label>
              <v-text-field
                v-model="iban"
                density="comfortable"
                variant="plain"
                clearable
                :hint="availability_message"
                persistent-hint
                :rules="[requiredField('accountPage.ibans_section.input.iban')]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col class="d-flex justify-space-between">
            <v-btn
              variant="flat"
              color="white"
              height="auto"
              class="text-none cancelBtn"
              size="medium"
              @click="cancel"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.cancel') }}
            </v-btn>
            <v-btn
              variant="flat"
              color="primary"
              height="auto"
              class="text-none validationBtn"
              :loading="loader"
              size="medium"
              @click="checkIbanAvailability"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import { mapGetters } from '../../store'
import store from '../../store'

export default {
  name: 'IbanFormDialog',
  mixins: [NotificationsMixins],
  data: () => ({
    dialog: false,
    iban: '',
    loader: false,
    uri: '',
    availability_message: '',
    setCurrentEntityFunctionName: ''
  }),
  methods: {
    open() {
      this.dialog = true
      this.iban = ''
      this.availability_message = ''
      if (this.currentTreatedEntity === 'company') {
        this.uri = 'company/iban/create_new'
        this.setCurrentEntityFunctionName = 'setCurrentCompany'
      } else {
        this.uri = 'user/iban/create_new'
        this.setCurrentEntityFunctionName = 'setCurrentNaturalPerson'
      }
    },
    cancel() {
      this.availability_message = ''
      this.dialog = false
    },
    async isValidForm() {
      const { valid } = await this.$refs.ibanForm.validate()
      return valid
    },
    async checkIbanAvailability() {
      const isValidForm = await this.isValidForm()
      if (isValidForm) {
        /*if (!this.iban) {
        this.availability_message = this.$i18n.t('olkypass.accountPage.ibans_section.input.iban')+ ' ' + this.$i18n.t('login.registerPage.validation.required_field')
      } else {*/
        this.loader = true
        this.availability_message = ''
        await fetchServer(this.uri, { body: { UUID: this.currentUUID, iban: this.iban } })
          .then(response => {
            if (!response?.data?.success) throw new Error(response?.data?.response || 'Error')
          })
          .then(async response => {
            await store.dispatch(this.setCurrentEntityFunctionName, {
              UUID: this.currentUUID
            })
            this.cancel()
            this.displayNotification(
              'success',
              this.$i18n.t('olkypass.accountPage.notifications.success.profile.update')
            )
          })
          .catch(error => {
            if ((error + '').indexOf('Invalid bic for this IBAN') >= 0) {
              this.displayNotification(
                'error',
                this.$i18n.t('olkypass.accountPage.notifications.error.iban.create')
              )
            } else {
              this.displayNotification(
                'error',
                this.$i18n.t('olkypass.accountPage.notifications.error.iban.exists')
              )
            }
            this.cancel()
          })
          .finally(() => this.loader = false)
      }
    }
  },
  computed: {
    ...mapGetters(['currentTreatedEntity', 'currentUUID']),
    requiredField() {
      const $this = this
      return function (fieldName) {
        return (v) =>
          !!v ||
          $this.$i18n.t('olkypass.' + fieldName) +
            ' ' +
            $this.$i18n.t('login.registerPage.validation.required_field')
      }
    }
  }
}
</script>

<style scoped></style>
