import { Button } from '@/atoms-react/button/Button.react'
import { Modal } from '@/atoms-react/modal/index.react'
import { Base, CardTitle } from '@/atoms-react/text/Text.react'
import { AnimatedEnvelopePass } from '@/components/animations/envelope-olkypass/index.react'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import { KYCStatusType } from '@/features/kyc/types'
import { Themes } from '@/types/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ModalOlkyPassProps = {
  isOpen: boolean
  imageUrl: string
  title: string
  description: string
  onClose: () => void
  onClick:()=>void
  intermediate : boolean
  
} & React.ComponentPropsWithoutRef<'div'>

export const ModalOlkyPass = ({
  isOpen,
  title,
  description,
  imageUrl,
  onClose,
  onClick,
  intermediate
}: ModalOlkyPassProps) => {
  const { t } = useTranslation(['pass'])
  const { status } = useKYCContext()
  return (
    <Modal open={isOpen} closable onClose={onClose} className="sm:w-[550px] w-full max-w-lg">
      <div className="flex flex-col h-[550px]">
        <div className="bg-slate-50   w-full rounded-sm    mb-6 p-6 overflow-hidden flex items-center justify-center">
          {status === KYCStatusType.DECLARATIVE &&<AnimatedEnvelopePass srcImg={imageUrl}  />}
          {status === KYCStatusType.VERIFIED && <AnimatedEnvelopePass srcImg={imageUrl} />}
          {status === KYCStatusType.AUTHENTICATED && <AnimatedEnvelopePass srcImg={imageUrl} />}
          {status === KYCStatusType.AUTHENTICATED_BRONZE && <AnimatedEnvelopePass srcImg={imageUrl} />}
          {status === KYCStatusType.AUTHENTICATED_GOLD && <AnimatedEnvelopePass srcImg={imageUrl} />}
          {status === KYCStatusType.AUTHENTICATED_SILVER && <AnimatedEnvelopePass srcImg={imageUrl} />}

        </div>
        <CardTitle className="text-center mb-4 px-2">
          {' '}
          <span>
            {t('olkypass.mainPage.olkypass.modalOlkyPass.title_first_part')}{' '}
            <span className="text-primary">
              {t('olkypass.mainPage.olkypass.modalOlkyPass.title_second_part')}
            </span>
            <br></br>
            {title}
          </span>{' '}
        </CardTitle>
        <Base className="text-center text-sm mb-10 text-slate-400 dark:text-slate-400 leading-relaxed		">
          {description}
        </Base>
        
          { (status !== KYCStatusType.AUTHENTICATED_GOLD && !intermediate ) && <Button className="mb-4  px-2 py-4  text-sm" onClick={() => onClick()} theme={Themes.primary}>
            {t('olkypass.mainPage.olkypass.modalOlkyPass.button_modal_passport')}
          </Button>}
        
        <Button className=" px-2 py-4 text-sm text-primary" onClick={() => onClose()} theme={Themes.white}>
          {t('olkypass.mainPage.olkypass.modalOlkyPass.button_modal_passport_close')}
        </Button>
      </div>
    </Modal>
  )
}
