<template>
  <ion-page id="ReasonTransferPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          default-href="/pay/supplier/transfer"
          :icon="caretBackSharp"
        ></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1>{{ $t(currentTransfer.title) }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row class="mt-3">
          <ion-col>
            <div class="card card-plain mt-4">
              <div class="card-header pb-0 px-3">
                <h2 class="mb-3">
                  {{
                    $t(
                      'olkypay.inputs.transferPage.one_time_tab.steps.choice_type.classic_transfer.amount.label'
                    )
                  }}
                </h2>
              </div>
              <div class="card-body">
                <ion-row>
                  <ion-col>
                    <ion-input
                      v-model="formattedAmountInCentimes"
                      :placeholder="
                        $t(
                          'olkypay.inputs.transferPage.one_time_tab.steps.choice_type.classic_transfer.amount.placeholder'
                        )
                      "
                      :clear-on-edit="true"
                      fill="outline"
                      @ionBlur="formatAmount"
                    ></ion-input>
                  </ion-col>
                </ion-row>
              </div>
            </div>
          </ion-col>
        </ion-row>

        <ion-row class="mt-1">
          <ion-col>
            <div class="card card-plain mt-2">
              <div class="card-header pb-0 px-3">
                <h2 class="mb-3">
                  {{
                    $t(
                      'olkypay.inputs.transferPage.one_time_tab.steps.choice_type.classic_transfer.comment.label'
                    )
                  }}
                </h2>
              </div>
              <div class="card-body">
                <ion-row>
                  <ion-input
                    v-model="currentTransfer.comment"
                    :placeholder="
                      $t(
                        'olkypay.inputs.transferPage.one_time_tab.steps.choice_type.classic_transfer.comment.placeholder'
                      )
                    "
                    :clear-input="true"
                    fill="outline"
                  ></ion-input>
                </ion-row>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row
          v-if="
            currentTransfer.typeVirement !== 'instant' && currentTransfer.typeVirement !== 'rec'
          "
        >
          <ion-col>
            <div class="card card-plain mt-2">
              <div class="card-header pb-0 px-3">
                <h2 class="mb-2">
                  {{
                    $t(
                      'olkypay.inputs.transferPage.one_time_tab.steps.choice_type.classic_transfer.transfer_date.label'
                    )
                  }}
                </h2>
              </div>
              <div class="card-body">
                <ion-row class="border-row border-1 border-radius-md min-height-56 mx-0">
                  <ion-col>
                    <ion-datetime-button
                      datetime="datetime"
                      display-time="false"
                      class="mt-2 float-start"
                      :disabled="currentTransfer.typeVirement === 'classic'"
                    ></ion-datetime-button>
                    <ion-modal class="okypay-modal" :keep-contents-mounted="true">
                      <ion-datetime
                        id="datetime"
                        v-model="executionDate"
                        presentation="date"
                        :min="new Date().toJSON()"
                      ></ion-datetime>
                    </ion-modal>
                  </ion-col>
                </ion-row>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row v-if="currentTransfer.typeVirement === 'rec'">
          <ion-col>
            <div class="card card-plain mt-4">
              <div class="card-header pb-0 px-3">
                <h2 class="mb-3">
                  {{ $t('olkypay.inputs.transferPage.recurrent_tab.recurrence') }}
                </h2>
              </div>
              <div class="card-body">
                <ion-row class="border-row border-1 border-radius-md min-height-56 mx-0 mb-3">
                  <ion-col>
                    <ion-select
                      v-model="frequencyId"
                      :label="$t('olkypay.inputs.transferPage.recurrent_tab.recurrence')"
                      label-placement="floating"
                    >
                      <ion-select-option value="1"
                        >{{ $t('olkypay.inputs.transferPage.recurrent_tab.frequencies.monthly') }}
                      </ion-select-option>
                      <ion-select-option value="2">
                        {{ $t('olkypay.inputs.transferPage.recurrent_tab.frequencies.quarterly') }}
                      </ion-select-option>
                      <ion-select-option value="3">
                        {{
                          $t('olkypay.inputs.transferPage.recurrent_tab.frequencies.semi_annual')
                        }}
                      </ion-select-option>
                      <ion-select-option value="4"
                        >{{ $t('olkypay.inputs.transferPage.recurrent_tab.frequencies.annual') }}
                      </ion-select-option>
                    </ion-select>
                  </ion-col>
                </ion-row>

                <ion-row class="border-row border-1 border-radius-md min-height-56 mx-0 mt-3">
                  <ion-col size="4">
                    <label class="mt-3 mx-2">{{
                      $t('olkypay.inputs.transferPage.recurrent_tab.start_date')
                    }}</label>
                  </ion-col>
                  <ion-col size="6">
                    <ion-datetime-button
                      datetime="datetime"
                      display-time="false"
                      class="mt-2"
                    ></ion-datetime-button>
                    <ion-modal class="okypay-modal date-modal" :keep-contents-mounted="true">
                      <ion-datetime
                        id="datetime"
                        v-model="executionDate"
                        presentation="date"
                        :min="new Date().toJSON()"
                      ></ion-datetime>
                    </ion-modal>
                  </ion-col>
                </ion-row>
                <ion-row class="border-row border-1 border-radius-md min-height-56 mx-0 mt-3">
                  <ion-col size="4">
                    <label class="mt-3 mx-2">{{
                      $t('olkypay.inputs.transferPage.recurrent_tab.end_date')
                    }}</label>
                  </ion-col>
                  <ion-col size="6">
                    <ion-datetime-button
                      datetime="datetimeToDate"
                      display-time="false"
                      class="mt-2"
                      :disabled="!hasDateEnd"
                    ></ion-datetime-button>
                    <ion-modal class="okypay-modal date-modal" :keep-contents-mounted="true">
                      <ion-datetime
                        id="datetimeToDate"
                        v-model="toDate"
                        presentation="date"
                        :min="new Date().toJSON()"
                      ></ion-datetime>
                    </ion-modal>
                  </ion-col>
                  <ion-col size="2">
                    <ion-toggle
                      v-model="hasDateEnd"
                      :enable-on-off-labels="true"
                      class="mt-3"
                    ></ion-toggle>
                  </ion-col>
                </ion-row>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-fab v-if="showButtonNext" slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button color="blue_olky" @click="openNextStep">
          <ion-icon :icon="chevronForwardCircle"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import HeaderTabs from '../../../../components/HeaderTabs.vue'
import Stepper from '../../../../components/transfer/stepper.vue'
import {
  IonTitle,
  IonContent,
  IonPage,
  IonIcon,
  IonRow,
  IonCol,
  IonImg,
  IonGrid,
  IonFab,
  IonFabButton,
  IonItem,
  IonInput,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonBackButton,
  IonToolbar,
  IonButtons
} from '@ionic/vue'
import {
  handRight,
  calendarClearSharp,
  alertCircleOutline,
  timer,
  time,
  chevronForwardCircle,
  caretDownCircleOutline,
  caretDownCircle,
  arrowDown
} from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'
import { caretBackSharp, alertCircle } from 'ionicons/icons'

export default {
  name: 'ReasonTransfer',
  components: {
    IonTitle,
    IonContent,
    IonPage,
    IonIcon,
    IonRow,
    IonCol,
    IonImg,
    IonGrid,
    IonFab,
    IonFabButton,
    IonItem,
    IonInput,
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    IonSelect,
    IonSelectOption,
    IonToggle,
    IonBackButton,
    IonToolbar,
    IonButtons,
    HeaderTabs,
    Stepper
  },
  data() {
    return {
      handRight,
      calendarClearSharp,
      alertCircleOutline,
      timer,
      time,
      chevronForwardCircle,
      caretDownCircleOutline,
      caretDownCircle,
      arrowDown,
      caretBackSharp,
      amountInCentimes: '',
      formattedAmountInCentimes: '',
      executionDate: new Date().toISOString(),
      toDate: new Date().toISOString(),
      hasDateEnd: false,
      frequencyId: 1 // default monthy
    }
  },
  methods: {
    ...mapActions('transfer', ['setCurrentTransfer']),
    openNextStep() {
      const executionDate = this.executionDate.substring(0, 10)
      if (this.currentTransfer.typeVirement !== 'rec') {
        this.setCurrentTransfer({
          paymentId: this.currentBeneficiary.properties.paymentId,
          nominalAmount: this.amountInCentimes,
          executionDate: executionDate.split('-').map(Number),
          recidivism: 0
        })
      } else {
        const toDate = this.hasDateEnd ? this.toDate.substring(0, 10).split('-').map(Number) : []
        this.setCurrentTransfer({
          paymentId: this.currentBeneficiary.properties.paymentId,
          clientId: this.currentBeneficiary.properties.paymentId,
          nominalAmount: this.amountInCentimes,
          executionDate: executionDate.split('-').map(Number),
          recidivism: 0,
          catId: 3, // virement
          rec: this.frequencyId,
          toDate
        })
      }
      this.$router.push({ name: 'ValidationTransferSubTabRouter' })
    },
    formatAmount() {
      // Remove non-numeric characters from the input
      const numericValue = this.formattedAmountInCentimes.replace(/[^0-9]/g, '')
      // Convert to centimes (multiply by 100)
      const eurosValue = parseFloat(numericValue)
      // Format the centimesValue as a string with commas and euros (€)
      this.formattedAmountInCentimes = isNaN(eurosValue)
        ? ''
        : eurosValue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2
          })
      this.amountInCentimes = isNaN(eurosValue) ? 0 : eurosValue * 100
    }
  },
  mounted() {},
  computed: {
    ...mapGetters('supplier', ['currentSupplier']),
    ...mapGetters('transfer', ['currentTransfer']),
    ...mapGetters('beneficiary', ['currentBeneficiary']),
    formattedSupplierIBAN() {
      if (this.currentSupplier.iban) {
        return this.currentSupplier.iban.match(/.{1,4}/g).join(' ')
      }
      return ''
    },
    formattedBeneficiaryIBAN() {
      if (this.currentBeneficiary.iban) {
        return this.currentBeneficiary.iban.match(/.{1,4}/g).join(' ')
      }
      return ''
    },
    showButtonNext() {
      if (this.currentTransfer.typeVirement !== 'rec') {
        return this.amountInCentimes && this.executionDate && this.currentTransfer.comment
      } else {
        return (
          this.amountInCentimes &&
          this.executionDate &&
          this.currentTransfer.comment &&
          this.frequencyId
        )
      }
    }
  }
}
</script>

<style scoped></style>
