<template>
  <ion-page id="DisplayCodeCardPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          default-href="/pay/supplier/cards"
          :icon="caretBackSharp"
        ></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1>{{ $t('olkypay.labels.displayCardCodePage.header') }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <ion-grid class="mx-2">
        <ion-row class="mt-2">
          <ion-col class="text-center">
            <img :src="particularCard" />
            <h5 class="text-center text-gray mt-0 mb-0">{{ currentCard.fullName }}</h5>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="text-center">
            <ion-icon :icon="lockClosed" size="large" color="blue_olky"> </ion-icon>
          </ion-col>
        </ion-row>
        <ion-row class="mx-2 mt-2 rowContainer">
          <ion-col class="colContainer">
            <v-otp-input
              ref="otpInput"
              v-model:value="card_code"
              input-classes="otp-pin"
              separator=" "
              :num-inputs="4"
              :should-auto-focus="false"
              input-type="letter-numeric"
              :conditional-class="['one', 'two', 'three', 'four']"
              :placeholder="['*', '*', '*', '*']"
            />
          </ion-col>
        </ion-row>
        <ion-row class="mt-2">
          <ion-col class="text-center">
            <button class="btn bg-gradient-info mb-0" @click="proceedToDisplayCode">
              <div v-if="!isVisibleCode">
                <ion-icon :icon="eye"></ion-icon>
                {{ $t('olkypay.buttons.displayCardCodePage.show_code') }}
              </div>
              <div v-else>
                <ion-icon :icon="eyeOff"></ion-icon>
                {{ $t('olkypay.buttons.displayCardCodePage.hide_code') }}
              </div>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <authentication-process-popup ref="authenticationPopup"></authentication-process-popup>
  </ion-page>
</template>

<script lang="ts">
import VOtpInput from 'vue3-otp-input'
import businessCard from '../../../../../../public/images/olkypay/cards/business-card.png'
import particularCard from '../../../../../../public/images/olkypay/cards/particular-card.png'
import AuthenticationProcessPopup from '../../../components/security/authenticationProcessPopup.vue'
import {
  IonHeader,
  IonContent,
  IonPage,
  IonBackButton,
  IonButton,
  IonButtons,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonImg,
  IonGrid,
  IonSelect,
  IonSelectOption,
  IonInput
} from '@ionic/vue'
import { caretBackSharp, alertCircle, lockOpen, lockClosed, eye, eyeOff } from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'CreditCard',
  components: {
    IonHeader,
    IonContent,
    IonPage,
    IonBackButton,
    IonButton,
    IonButtons,
    IonIcon,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonImg,
    IonGrid,
    IonSelect,
    IonSelectOption,
    IonInput,
    VOtpInput,
    AuthenticationProcessPopup
  },
  data() {
    return {
      caretBackSharp,
      alertCircle,
      lockOpen,
      lockClosed,
      eye,
      eyeOff,
      businessCard,
      particularCard,
      card_code: '****',
      isVisibleCode: false
    }
  },
  mounted() {},
  methods: {
    async proceedToDisplayCode() {
      if (!this.isVisibleCode) {
        const isSuccessAuthenticated = await this.$refs.authenticationPopup.authenticate()
        if (isSuccessAuthenticated) {
          this.displayCode()
        }
      } else {
        this.displayCode()
      }
    },
    displayCode() {
      this.isVisibleCode = !this.isVisibleCode
      if (this.isVisibleCode) {
        this.card_code = '1234'
      } else {
        this.card_code = '****'
      }
    }
  },
  computed: {
    ...mapGetters('card', ['currentCard'])
  }
}
</script>
