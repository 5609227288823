import { QuizStepProps } from '@/features/user/quiz/types.ts'
import { useTranslation } from 'react-i18next'
import { Base, SectionTitleSmall } from '@/atoms-react/text/Text.react.tsx'
import { Button } from '@/atoms-react/button/Button.react.tsx'
import { Themes } from '@/types/theme.ts'
import React, { useState } from 'react'
import Image from '@/atoms-react/image/Image.react.tsx'
import { QuestionScenario } from '@/molecules/modal-iban-account/quiz/question-scenario.react.tsx'
import { EditKYCSession } from '@/features/kyc/types.ts'
import { useKYC } from '@/features/kyc/hooks/use-kyc.ts'
import toast from 'react-hot-toast'

export const VideoVerificationStep = ({ setStep, stepSettings, user }: QuizStepProps) => {
  const { t } = useTranslation(['pass'])
  const [showQuestionVideo, setShowQuestionVideo] = useState<boolean>(false)
  const toggleVideoModal = () => {
    // launch video
    // if video successfully
    setShowQuestionVideo(!showQuestionVideo)
  }
  const { updateQuizVideoQuestionMutation } = useKYC()
  const { mutateAsync: editSession } = updateQuizVideoQuestionMutation()
  const [sessionEnded, setSessionEnded] = useState<boolean>(false)
  const onQuestionSessionEnd = async (data: EditKYCSession) => {
    // update the session with the informations from the iframe message
    try {
      await editSession(data)
      setSessionEnded(true)
      setStep(stepSettings.next)
    } catch (e) {
      toast.error(t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.video_verification.error'))
      setShowQuestionVideo(false)
    }
  }

  return (
    <>
      <SectionTitleSmall className={'mb-2'}>
        {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.video_verification.section_title')}
      </SectionTitleSmall>
      {!showQuestionVideo && (
        <>
          <Base className="mt-4 mb-4">
            {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.video_verification.description')}
          </Base>
          <div
            className="h-full px-4 py-4 mb-4 md:px-6 md:py-6 border-2 border-slate-50 rounded-sm hover:bg-slate-50 dark:hover:bg-slate-800 cursor-pointer z-50"
            onClick={toggleVideoModal}
          >
            <div className="flex flex-col items-center h-full items-center justify-center">
              <div className="w-10 h-10">
                <Image src="/images/olkypass/play_video.svg" alt="Play icon" />
              </div>
              <Base className="mt-2 text-center text-sky-500">
                {t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.video_verification.launch')}
              </Base>
            </div>
          </div>
          <div className="flex flex-col items-center mt-auto mb-4">
            <div className="w-full flex flex-col-reverse gap-y-2 md:flex-row md:gap-x-2">
              <Button theme={Themes.white} onClick={() => setStep(stepSettings.previous)}>
                {t('olkypass.general.go_back')}
              </Button>

              <Button onClick={() => toggleVideoModal()}>{t('olkypass.general.continue')}</Button>
            </div>
          </div>
        </>
      )}
      {showQuestionVideo && (
        <>
          <QuestionScenario user={user} onValidated={onQuestionSessionEnd}></QuestionScenario>
          <div className="flex flex-col items-center mt-auto mb-4 pt-3">
            <div className="w-full flex flex-col-reverse gap-y-2 md:flex-row md:gap-x-2">
              <Button theme={Themes.white} onClick={() => setStep(stepSettings.previous)}>
                {t('olkypass.general.go_back')}
              </Button>

              <Button disabled={!sessionEnded} onClick={() => setStep(stepSettings.next)}>
                {t('olkypass.general.continue')}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  )
}
