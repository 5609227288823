import React from 'react'
import { DocumentDuplicateIcon as DocumentDuplicateIconSolid } from '@heroicons/react/24/solid'
import { DocumentDuplicateIcon as DocumentDuplicateIconOutline } from '@heroicons/react/24/outline'
import { useToast } from 'vue-toast-notification'
import { twMerge } from 'tailwind-merge'

const CopyButton = ({ text, className, iconClassName, succesMessage }) => {
  const toast = useToast()
  const [copied, setCopied] = React.useState(false)

  const copyToClipboard = () => {
    if (text) {
      navigator.clipboard.writeText(text)
      setCopied(true)
      toast.success(succesMessage)

      setTimeout(() => setCopied(false), 1000)
    }
  }

  return (
    <div>
      {copied ? (
        <div className={`relative flex items-center ${className}`}>
          <DocumentDuplicateIconSolid
            className={twMerge(
              `h-5 w-5  stroke-2 cursor-pointer ${iconClassName}`,
              !iconClassName && 'text-slate-900 hover:text-slate-600 '
            )}
            onClick={copyToClipboard}
          />
        </div>
      ) : (
        <div className={`relative flex items-center ${className}`}>
          <DocumentDuplicateIconOutline
            className={twMerge(
              `h-5 w-5 stroke-2 cursor-pointer ${iconClassName}`,
              !iconClassName && 'text-slate-500 hover:text-slate-600 '
            )}
            onClick={copyToClipboard}
          />
        </div>
      )}
    </div>
  )
}

export default CopyButton
