import { ConfirmationResponse } from '@/features/register/types'
import { Phone } from '@/features/user/types'
import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { PhoneApiClient } from '../api-client/api-client'

export default function useCreatePhone(
  options?: UseMutationOptions<ConfirmationResponse, Error, Phone>
) {
  return useMutation<ConfirmationResponse, Error, Phone>({
    mutationFn: async phone => PhoneApiClient.create(phone),
    ...options
  })
}
