import Avatar from '@/atoms-react/avatar/Avatar.react'
import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import Tag from '@/atoms-react/tag/Tag.react'
import { Base, CardTitle, Label } from '@/atoms-react/text/Text.react'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { useCompany } from '@/features/company/hooks/use-react-company'
import { CompanyDTO } from '@/features/company/types'
import { Routes } from '@/features/router'
import { useUser } from '@/features/user/hooks/use-user'
import { useLocale } from '@/features/utils/hooks/use-locale'
import { Themes } from '@/types/theme'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

type CompanyCardProps = { company: CompanyDTO } & React.ComponentPropsWithoutRef<'div'>

export const CompanyCard = ({ company, className, ...props }: CompanyCardProps) => {
  const { locale } = useLocale()
  const navigate = useNavigate()
  const { t } = useTranslation(['pass'])
  const { useCountries } = useUser()
  const { data: countries } = useCountries(locale?.language)

  const { companyDetailQuery } = useCompany()
  const { data: detailedCompany } = companyDetailQuery(company.identity_uuid ?? null)

  const { id, change } = useCompanyContext()
  const selected = id === company.identity_uuid

  return <Column
    className={twMerge(
      'inline-flex align-top border-2 border-slate-50 dark:border-slate-700 rounded-sm p-6 cursor-pointer h-full w-full min-h-96 md:w-96',
      selected && 'border-blue-500',
      className
    )}
    onClick={() => {
      change(company.identity_uuid ?? null)
      navigate(Routes.dashboard)
    }}
    {...props}
  >
    <div className="flex items-center md:col-span-2 gap-x-3 mb-8">
      <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-full border-2 border-blue-500">
        <Avatar size={10} fallback={company.legal_name} />
      </div>
      <div className="flex flex-wrap gap-x-3">
        <CardTitle>{company.legal_name}</CardTitle>
        {detailedCompany?.data?.olkyPassLevel.level !== 100 && <Tag theme={Themes.warning} className="mt-1 md:mt-0" size="sm">
            {t('olkypass.mainPage.olkypass.tagIncompletePass')}
          </Tag>}
      </div>
    </div>

    <div className="grow">
      <Label className="text-slate-400"> RCS</Label>
      <Base className="block mb-3 font-semibold">
        {company.RCS_number || t('business.card.rcs_not_registered')}
      </Base>

      <Label className="text-slate-400">{t('olkypass.general.address.input.address_one')}</Label>
      {(company.Address ?? []).map((address, index) => <Base key={address.UUID || index} className={'font-semibold line-clamp-2'}>
        {address.Address1.replace(',', '')}
      </Base>)}
      {(company.Address ?? []).map((address, index) => <Base key={address.UUID || index} className={'font-semibold line-clamp-2'}>
        {address.City} {address.ZipCode}
      </Base>)}
      {(company.Address ?? []).map((address, index) => <Base key={address.UUID || index} className={'font-semibold line-clamp-2'}>
        {
          (countries?.countries ?? []).find(c => c.id.toString() === address.Country)
            ?.defaultName
        }
      </Base>)}

      {company.mail_address && <>
          <Base>{t('business.card.email_label')}</Base>
          <Base className={'text-slate-400 line-clamp-2'}>{company.mail_address}</Base>
        </>}
    </div>

    <Button
      onClick={() => {}}
      icon={selected ? undefined : (p: any) => <ChevronRightIcon {...p} />}
      theme={selected ? Themes.primary : Themes.white}
      className="w-full mt-8"
    >
      {selected ? t('olkypass.mainPage.navigation_menu.link.home') : t('business.card.button')}
    </Button>
  </Column>
}
