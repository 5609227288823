import { Base } from '@/atoms-react/text/Text.react'
import { AddressFullType } from '@/features/user/types'
import { HomeIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { twMerge } from 'tailwind-merge'

type AdressRowProps = {
  address: AddressFullType
  home?: boolean
} & React.ComponentPropsWithoutRef<'div'>

export const AddressRow = ({ address, className, home = false, ...props }: AdressRowProps) => (
  <div
    className={twMerge(
      'flex justify-between items-center md:px-6 py-2 hover:bg-blue-50 dark:hover:bg-slate-900',
      className
    )}
    {...props}
  >
    <div className="flex flex-col items-start">
      <Base className="mb-1 font-semibold">{address.Tag}</Base>
      <Base className="text-slate-500">
        {address.Address1.replace(',', '')}, {address.City} {address.ZipCode}
      </Base>
    </div>
    {home && <HomeIcon className="h-6 w-6 text-black dark:text-white" />}
  </div>
)
