import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import _ from 'lodash'
// import store from "../index.vue";
const uris = {
  _get: {
    profile: '/user/profile',
    applications: '/user/applications',
    kycoin: '/user/kycoin',
    kypay: '/user/kypay'
  },
  _put: {
    profile: '/user/profile/edit',
    alias: '/user/profile/alias/delete'
  },
  _post: {
    new_bank_account: '/user/bank_account/create'
  }
}
const state = {
  user: {
    isAuthenticated: false,
    applications: []
  },
  naturalPerson: {
    DateOfBirth: {
      date: ''
    },
    Address: [],
    AliasIBAN: [],
    AliasEmail: [],
    AliasMobile: []
  },
  inMemoryNP: {
    DateOfBirth: {
      date: ''
    },
    Address: [],
    AliasIBAN: [],
    AliasEmail: [],
    AliasMobile: []
  },
  wantToLeavePage: false,
  kycoin: {},
  kypay: {}
}

const getters = {
  user: state => state.user,
  naturalPerson: state => state.naturalPerson,
  kycoin: state => state.kycoin,
  kypay: state => state.kypay,
  inMemoryNP: state => state.inMemoryNP,
  wantToLeavePage: state => state.wantToLeavePage
}
import i18n from '@/features/utils/i18n.ts'

const mutations = {
  SET_IS_AUTHENTICATED_USER(state) {
    state.user.isAuthenticated = true
  },
  SET_DISCONNECTED_USER(state) {
    state.user.isAuthenticated = false
  },
  SET_CURRENT_NATURAL_PERSON(state, response) {
    if (response.data.naturalPerson.DateOfBirth === null) {
      response.data.naturalPerson.DateOfBirth = { date: '' }
    }
    state.naturalPerson = _.cloneDeep(response.data.naturalPerson)
    if (state.naturalPerson.DateOfBirth.date !== undefined) {
      const properties = state.naturalPerson.DateOfBirth.date.split(' ')
      state.naturalPerson.DateOfBirth.date = properties[0]
    }
    state.inMemoryNP = _.cloneDeep(state.naturalPerson)
    this.dispatch('setCurrentTreatedEntity', 'naturalPerson')
    this.dispatch('setCurrentUUID', state.naturalPerson.UUID)
    this.dispatch('setCurrentKYCState', response.data.olkyPassLevel)
    this.dispatch('setHasUpdatePermission', true)
  },
  async SET_NP_PARTIAL_DATA(state, data) {
    this.dispatch('addNotification', {
      type: 'success',
      message: i18n().global.t('olkypass.accountPage.notifications.success.profile.update')
    })
  },
  async DEL_NP_ALIAS_DATA(state, data) {
    this.dispatch('addNotification', {
      type: 'success',
      message: i18n().global.t('olkypass.accountPage.notifications.success.profile.update')
    })
  },
  SET_WANT_TO_LEAVE_PAGE(state, value) {
    state.wantToLeavePage = value
  },
  CREATE_NEW_BANK_ACCOUNT(state, value) {
    this.dispatch('addNotification', {
      type: 'success',
      message: i18n().global.t('olkypass.accountPage.notifications.success.newOlkyAccount')
    })
  },
  SET_ACCESSIBLE_APPLICATIONS(state, response) {
    state.user.applications = response.data
  },
  SET_CURRENT_KYCOIN(state, response) {
    state.kycoin = response.data
  },
  SET_CURRENT_KYPAY(state, response) {
    state.kypay = response.data
  },
  UPDATE_CURRENT_KYPAY(state, response) {
    if (
      typeof response.data.balance == 'undefined' &&
      typeof state.kypay.transaction !== 'undefined'
    ) {
      state.kypay.transaction.push(...response.data)
    } else {
      state.kypay = response.data
    }
  },
  UPDATE_CURRENT_KYCOIN(state, response) {
    if (
      typeof response.data.balance == 'undefined' &&
      typeof state.kycoin.transaction !== 'undefined'
    ) {
      state.kycoin.transaction.push(...response.data)
    } else {
      state.kycoin = response.data
    }
  }
}

const actions = {
  setIsAuthenticatedUser({ commit }) {
    commit('SET_IS_AUTHENTICATED_USER')
  },
  setDisconnectedUser({ commit }) {
    commit('SET_DISCONNECTED_USER')
  },
  setWantToLeavePage({ commit }, value) {
    commit('SET_WANT_TO_LEAVE_PAGE', value)
  },
  async setCurrentNaturalPerson({ commit }, data) {
    try {
      const response = await fetchServer(uris._get.profile)
      commit('SET_CURRENT_NATURAL_PERSON', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async setNPPartialData({ commit }, data) {
    try {
      await fetchServer(uris._put.profile, { method: 'PUT', body: data })
      commit('SET_NP_PARTIAL_DATA', data)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async delNPAliasData({ commit }, data) {
    try {
      await fetchServer(uris._put.alias, { method: 'PUT', body: data })
      commit('DEL_NP_ALIAS_DATA', data)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async createOlkyPayAccount({ commit }, data) {
    try {
      await fetchServer(uris._post.new_bank_account, { body: data })
      commit('CREATE_NEW_BANK_ACCOUNT', data)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.newOlkyAccount')
        })
      }
    }
  },
  async setAccessibleApplications({ commit }) {
    const response = await fetchServer(uris._get.applications)
    commit('SET_ACCESSIBLE_APPLICATIONS', response)
  },
  async setCurrentKycoin({ commit }, data) {
    const response = await fetchServer(uris._get.kycoin, data)
    response.data.balanceEuro = response.data.balanceEuro.replace('€', ' €')
    commit('SET_CURRENT_KYCOIN', response)
  },
  async getAdditialKycoinTransaction({ commit }, data) {
    const response = await fetchServer(uris._get.kycoin + '?page=' + data.page)
    commit('UPDATE_CURRENT_KYCOIN', response)
  },
  async setCurrentKypay({ commit }, data) {
    const response = await fetchServer(uris._get.kypay, data)
    response.data.balance = response.data.balance.replace('€', ' €')

    commit('SET_CURRENT_KYPAY', response)
  },
  async getAdditialKypayTransaction({ commit }, data) {
    const response = await fetchServer(uris._get.kypay + '?page=' + data.page)
    commit('UPDATE_CURRENT_KYPAY', response)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
