import { CompanyForm } from '@/app/olkypass/views/account/company/components/company-form'
import { CompanyList } from '@/app/olkypass/views/account/company/components/company-list'
import { Button } from '@/atoms/buttons/Button'
import { Input } from '@/atoms/inputs/Input'
import LabelDecoration from '@/atoms/inputs/decorations/LabelDecoration'
import { Loader } from '@/atoms/loader/Loader'
import { SectionTitle, Subtitle } from '@/atoms/text/Text.tsx'
import { Section } from '@/components/layout/Section'
import { useCompany } from '@/features/company/hooks/use-company'
import { defineJsxComponent } from '@/jsx-utils'
import BuildingOfficeIcon from '@heroicons/vue/20/solid/BuildingOfficeIcon'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export const CompanyView = defineJsxComponent(_ => {
  const { companyStore, loadCompanies } = useCompany()
  companyStore.setInitialized(false)
  loadCompanies().then(() => companyStore.setInitialized(true))
  const t = useI18n().t
  const searchCompany = ref('')
  const displayCompanyForm = ref(false)

  return () => <div>
    {!companyStore.initialized && <Loader center />}
    {companyStore.initialized && <>
        <Section class="mb-4">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
            <div className="md:col-span-2">
              <LabelDecoration class="" label="">
                {{
                  input: (input: any) => <Input
                    size="lg"
                    class={input.class}
                    v-model={searchCompany.value}
                    placeholder={t('olkypass.companyPage.collaborators_section.toolbar.search')}
                  />
                }}
              </LabelDecoration>
            </div>
            <div className=" md:col-span-1">
              <Button
                theme="outlined"
                icon={BuildingOfficeIcon}
                onClick={() => {
                  displayCompanyForm.value = !displayCompanyForm.value
                }}
              >
                {t('olkypass.companyPage.tooltip.button.new_company')}
              </Button>
            </div>
          </div>
        </Section>
        {displayCompanyForm.value && <>
            <Section class=" mb-4">
              <SectionTitle>{t('olkypass.companyPage.section.information.title')}</SectionTitle>
              <Subtitle class="block mt-3">
                {t('olkypass.companyPage.section.information.subtitle')}
              </Subtitle>
              <CompanyForm
                onComplete={() => {
                  displayCompanyForm.value = false
                  loadCompanies().then(() => companyStore.setLoading(false))
                }}
              ></CompanyForm>
            </Section>
          </>}
        <CompanyList search={searchCompany.value}></CompanyList>
      </>}
  </div>
})
