<template>
  <ion-page id="BlockCardPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          default-href="/pay/supplier/cards"
          :icon="caretBackSharp"
        ></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1>{{ $t('olkypay.labels.blockCardPage.header') }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <ion-grid class="mx-2">
        <ion-row>
          <ion-col>
            <h4 class="text-gray font-weight-bolder">
              {{ $t('olkypay.labels.blockCardPage.sub_header') }}
            </h4>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="text-center">
            <img :src="particularCard" />
            <h5 class="text-center text-gray mt-0 mb-0">{{ currentCard.fullName }}</h5>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <h4 class="text-dark font-weight-extra-bolder">
              {{ $t('olkypay.labels.blockCardPage.blocked_operations.message') }}
            </h4>
          </ion-col>
        </ion-row>
        <ion-row>
          <ul class="mt-0 colored-bullets mb-2">
            <li>
              {{ $t('olkypay.labels.blockCardPage.blocked_operations.operation_withdrawal') }}
            </li>
            <li>{{ $t('olkypay.labels.blockCardPage.blocked_operations.operation_shop') }}</li>
            <li>{{ $t('olkypay.labels.blockCardPage.blocked_operations.operation_pay') }}</li>
          </ul>
        </ion-row>
        <ion-row class="alert alert-warning border-radius-lg">
          <ion-col size="2">
            <ion-icon :icon="alertCircle" size="large" color="light"></ion-icon>
          </ion-col>
          <ion-col>
            <p class="text-white mb-0">{{ $t('olkypay.labels.blockCardPage.alert_message') }}</p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p class="text-dark">{{ $t('olkypay.labels.blockCardPage.info_message') }}</p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="text-center">
            <button class="btn bg-gradient-info mb-0 medium-button" @click="proceedToBlockCard()">
              {{ $t('olkypay.buttons.blockCardPage.block') }}
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <authentication-process-popup ref="authenticationPopup"></authentication-process-popup>
    <info-modal ref="infoModal"></info-modal>
  </ion-page>
</template>

<script lang="ts">
import { CardService } from '../../../services/card.service.ts'
import businessCard from '../../../../../../public/images/olkypay/cards/business-card.png'
import particularCard from '../../../../../../public/images/olkypay/cards/particular-card.png'
import HeaderTabs from '../../../components/HeaderTabs.vue'
import AuthenticationProcessPopup from '../../../components/security/authenticationProcessPopup.vue'
import InfoModal from '../../../components/InfoModal.vue'
import {
  IonHeader,
  IonContent,
  IonPage,
  IonBackButton,
  IonButton,
  IonButtons,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonImg,
  IonGrid,
  alertController
} from '@ionic/vue'
import { caretBackSharp, alertCircle } from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'OppositionCardPage',
  components: {
    IonHeader,
    IonContent,
    IonPage,
    IonBackButton,
    IonButton,
    IonButtons,
    IonIcon,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonImg,
    IonGrid,
    HeaderTabs,
    AuthenticationProcessPopup,
    InfoModal
  },
  data() {
    return {
      caretBackSharp,
      alertCircle,
      businessCard,
      particularCard
    }
  },
  methods: {
    ...mapActions('loader', ['playLoader', 'stopLoader']),
    async proceedToBlockCard() {
      const isSuccessAuthenticated = await this.$refs.authenticationPopup.authenticate()
      if (isSuccessAuthenticated) {
        await this.onSuccessAuthenticateUser()
      }
    },
    async onSuccessAuthenticateUser() {
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.blockingCard.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      const inputData = { id: this.currentCard.id, active: 0, treatedEntity: this.treatedEntity }
      await CardService.blockCard(inputData)
        .then(async res => {
          this.stopLoader()
          const successDialog = {
            type: 'success',
            title: this.$i18n.t('olkypay.notifications.success.blockCardPage.blocking_card.header'),
            content: this.$i18n.t(
              'olkypay.notifications.success.blockCardPage.blocking_card.message'
            ),
            button: this.$i18n.t('olkypay.buttons.blockCardPage.go_back_home')
          }
          const response_modal = await this.$refs.infoModal.openModal(successDialog)
          if (response_modal) {
            this.$router.push({ name: 'SuppliersRouter' })
          }
        })
        .catch(async (err: any) => {
          this.stopLoader()
          const failedDialog = {
            type: 'warning',
            title: this.$i18n.t('olkypay.notifications.error.blockCardPage.blocking_card.header'),
            content: this.$i18n.t(
              'olkypay.notifications.error.blockCardPage.blocking_card.message'
            ),
            button: this.$i18n.t('olkypay.buttons.creditCardPage.close_dialog')
          }
          const response_modal = await this.$refs.infoModal.openModal(failedDialog)
        })
    }
  },
  computed: {
    ...mapGetters('card', ['currentCard']),
    ...mapGetters('supplier', ['treatedEntity'])
  }
}
</script>
