<template>
  <!--  border-2 border-secondary bg-gray-100-->
  <ion-row>
    <ion-col>
      <div
        class="card card-body border border-radius-lg"
        :class="
          transferType.name === currentTransfer.typeVirement
            ? 'border-2 border-selected bg-gray-200'
            : ''
        "
        @click="setTransfer"
      >
        <ion-row>
          <ion-col size="2">
            <ion-col size="2" class="mt-3">
              <div class="circleIcon Blue-light">
                <ion-icon
                  :icon="transferType.icon"
                  color="blue_olky"
                  class="withoutOpacity"
                ></ion-icon>
              </div>
            </ion-col>
          </ion-col>
          <ion-col size="10">
            <div class="d-flex flex-column">
              <h4 class="mb-1 text-dark">{{ $t(transferType.title) }}</h4>
              <span class="text-xs text-gray">{{ $t(transferType.description) }}</span>
            </div>
          </ion-col>
        </ion-row>
      </div>
    </ion-col>
  </ion-row>
</template>

<script lang="ts">
import { IonRow, IonCol, IonIcon } from '@ionic/vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TransferType',
  components: {
    IonRow,
    IonCol,
    IonIcon
  },
  props: ['transferType'],
  methods: {
    ...mapActions('transfer', ['setCurrentTransfer']),
    setTransfer() {
      this.setCurrentTransfer({
        typeVirement: this.transferType.name,
        title: this.transferType.title
      })
    }
  },
  computed: {
    ...mapGetters('transfer', ['currentTransfer'])
  }
}
</script>

<style scoped></style>
