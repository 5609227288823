import { formatUTC } from '@/features/utils/functions'
import { CompanyCreate, CompanyCreateDTO, CompanyDTO, CompanyLinkNPLE } from '../types'
import _ from 'lodash'

export const CompanyConverters = {
  DTO_Create: (dto: CompanyDTO): CompanyCreate => ({
    company: {
      name: dto.legal_name,
      legalForm: dto.legal_form.toString(),
      VATnumber: dto.VAT_number?.toString(),
      email: dto.mail_address ?? undefined
    },
    RCS: {
      number: dto.RCS_number ?? undefined,
      incorporationDate:
        dto.date_incorporation instanceof Date
          ? dto.date_incorporation ?? undefined
          : // @ts-expect-error Error typing
            new Date(dto.date_incorporation)
    },
    address: {
      addressLine: _.first(dto.Address)?.Address1 ?? undefined,
      zip: _.first(dto.Address)?.ZipCode ?? undefined,
      city: _.first(dto.Address)?.City ?? undefined,
      countryCode: _.first(dto.Address)?.Country ?? undefined
    },
    activity: {
      nace: dto.code_nace,
      description: dto.comment
    }
  }),
  Create_CreateDTO: (dto: CompanyCreate, linkNPLE?: CompanyLinkNPLE): CompanyCreateDTO => ({
    collaborators: linkNPLE ? [linkNPLE] : [],
    company: {
      legal_name: dto.company?.name ?? '',
      legal_form: dto.company?.legalForm ?? '',
      code_nace: dto?.activity?.nace ?? '',
      comment: dto?.activity?.description ?? '',
      RCS_number: dto?.RCS?.number ?? '',
      date_incorporation: dto?.RCS?.incorporationDate
        ? formatUTC(dto.RCS.incorporationDate.toISOString())
        : null,
      VAT_number: dto?.company?.VATnumber ?? '',
      website_url: dto.company?.url ?? '',
      mail_address: dto?.company?.email ?? ''
    },
    address: {
      address_1: dto?.address?.addressLine ?? '',
      zip_code: dto?.address?.zip ?? '',
      city: dto?.address?.city ?? '',
      country_id: parseInt(dto?.address?.countryCode ?? '') ?? -1
    }
  })
}
