import Tag from '@/atoms-react/tag/Tag.react'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../_layout/index.react'
import { IdentityLE } from './components/identity-le/index.react'
import { IdentityNP } from './components/identity-np/index.react'

export const IdentityApp = () => {
  const { t } = useTranslation(['pass'])
  const { id } = useCompanyContext()
  const cardClassName =
    'md:border-2 md:border-slate-50 md:rounded-sm w-full text-black dark:text-white dark:border-slate-700'

  return <>
    <LayoutTitle>
      <div className="flex items-center gap-x-2">
        {t('olkypass.mainPage.navigation_menu.link.identity')}
        {!!id && <Tag>Pro</Tag>}
      </div>
    </LayoutTitle>
    {!id && <IdentityNP cardClassName={cardClassName} />}
    {!!id && <IdentityLE cardClassName={cardClassName} />}
  </>
}
