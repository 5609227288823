<template>
  <ion-page id="OnBoardingPage">
    <ion-content :fullscreen="true">
      <div class="card-header">
        <div class="header-app height-261 absolute">
          <ion-grid>
            <ion-row class="mt-3">
              <ion-col class="text-center">
                <img :src="logoOlkyPayBlue" class="" />
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div class="text-center">
          <ion-row>
            <ion-col class="text-center mt-3">
              <swiper
                class="item-on-header-app mt-75 onBoardingSwiper"
                :loop="true"
                :modules="modules"
                :centered-slides="true"
                :autoplay="{
                  delay: 5000,
                  disableOnInteraction: false
                }"
                :pagination="{
                  clickable: true
                }"
                :navigation="true"
              >
                <swiper-slide class="text-center">
                  <img :src="on_boarding_page_1" alt="Slide 1" />
                  <h1 class="font-weight-bold mt-4">
                    {{ $t('olkypay.labels.onBoardingPage.page_1.title') }}
                  </h1>
                  <p class="mt-3">{{ $t('olkypay.labels.onBoardingPage.page_1.description') }}</p>
                </swiper-slide>

                <swiper-slide class="text-center">
                  <img :src="on_boarding_page_2" alt="Slide 1" class="withoutWidth" />
                  <h1 class="font-weight-bold">
                    {{ $t('olkypay.labels.onBoardingPage.page_2.title') }}
                  </h1>
                  <p>{{ $t('olkypay.labels.onBoardingPage.page_2.description') }}</p>
                </swiper-slide>
                <swiper-slide class="text-center">
                  <img :src="on_boarding_page_3" alt="Slide 1" class="withoutWidth" />
                  <h1 class="font-weight-bold">
                    {{ $t('olkypay.labels.onBoardingPage.page_3.title') }}
                  </h1>
                  <p>{{ $t('olkypay.labels.onBoardingPage.page_3.description') }}</p>
                </swiper-slide>
                <!-- Add more slides as needed -->
              </swiper>
            </ion-col>
          </ion-row>
          <ion-row class="mt-3">
            <ion-col>
              <button class="btn bg-gradient-info mb-0 ms-2 access-account" @click="gotToRouter">
                {{ $t('olkypay.buttons.onBoardingPage.access') }}
              </button>
            </ion-col>
          </ion-row>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import on_boarding_page_1 from '../../../../public/images/olkypay/onBoarding/onboarding_page_1.png'
import on_boarding_page_2 from '../../../../public/images/olkypay/onBoarding/onboarding_page_2.png'
import on_boarding_page_3 from '../../../../public/images/olkypay/onBoarding/onboarding_page_3.png'
import logoOlkyPayBlue from '../../../../public/images/olkypay/logo-olkypay-header-blue.svg'

import { EffectCoverflow, Pagination, Autoplay, Navigation } from 'swiper/modules'
import { StorageService } from '@/features/utils/storage.ts'
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonListHeader,
  IonProgressBar,
  IonList,
  IonButton
} from '@ionic/vue'

export default {
  name: 'OnBoardingPage',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonLabel,
    IonListHeader,
    IonProgressBar,
    IonList,
    IonButton,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      on_boarding_page_1,
      on_boarding_page_2,
      on_boarding_page_3,
      logoOlkyPayBlue,
      modules: [EffectCoverflow, Pagination, Autoplay, Navigation]
    }
  },
  methods: {
    gotToRouter() {
      StorageService.setItem('firstTimeUseApp', 1)
      this.$router.push({ name: 'SuppliersRouter' })
    }
  }
}
</script>

<style scoped>
/* Add your custom styles here */
</style>
