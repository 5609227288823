import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import { useUser } from '@/features/user/hooks/use-user'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../../../_layout/index.react'
import { IbanCard } from '@/molecules/iban-card/index.react'
import { NaturalEntityDataType } from '@/features/utils/types'
import { ModalAddIban } from '@/molecules/modal-add-iban/index.react'
import useCreateIban from '@/features/user/iban/hooks/use-create-iban'
import { AliasIBAN } from '@/features/user/types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Routes } from '@/features/router'
import { useDeleteAlias } from '@/features/user/hooks/use-delete-alias'
import { ModalDeleteAlias } from '@/molecules/modal-delete-alias/index.react'
import { Base, Info } from '@/atoms-react/text/Text.react'
import { useQuiz } from '@/features/user/quiz/hooks/use-quiz'
import { EmptyPageCactusAnimation } from '@/components/animations/empty-cactus/index.react.tsx'
import { A } from '@/atoms-react/a/A.react.tsx'
import { Themes } from '@/types/theme.ts'
import { ModalIbanAccount } from '@/molecules/modal-iban-account/index.react.tsx'
import { ModalKYCIdentity } from '@/molecules/modal-kyc-identity/index.react.tsx'

type AliasesAddressProps = React.ComponentPropsWithoutRef<'div'>

export const AliasesPaymentApp = ({ ...props }: AliasesAddressProps) => {
  const { t } = useTranslation(['pass'])
  const { useUserProfile } = useUser()
  const { data: user, refetch } = useUserProfile()
  const navigate = useNavigate()

  const ibans = user?.naturalPerson.AliasIBAN

  const [searchParams] = useSearchParams()

  const [modalAddIban, setModalAddIban] = useState<boolean>(!!searchParams.get('add'))
  const [deletedIBAN, setDeletedIBAN] = useState<AliasIBAN | null>(null)
  const { mutateAsync: createIban } = useCreateIban()
  const { mutateAsync: deleteAlias } = useDeleteAlias()

  const onDelete = async () => {
    if (!deletedIBAN) {
      return
    }
    await deleteAlias({
      ItemType: NaturalEntityDataType.AliasIBANDto,
      ItemUUID: deletedIBAN.UUID || '',
      UUID: user?.naturalPerson?.UUID || ''
    })
    await refetch()
    setDeletedIBAN(null)
  }

  const { useIsValidatedIbanQuizMutation, useCheckValidateIbanMutation } = useQuiz()
  const { mutateAsync: isValidatedIban } = useIsValidatedIbanQuizMutation()
  const { mutateAsync: validateIban } = useCheckValidateIbanMutation()

  const [attempts, setAttempts] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean | null>(null)

  const [iban, setIban] = useState<string>()
  const maxAttempts = 5
  const [isModalIbanOpen, setIsModalIbanOpen] = useState<boolean>(false)
  const [modalIdentity, setModalIdentity] = useState<boolean>(false)
  const openKYCModal = () => {
    setModalIdentity(true)
    setIsModalIbanOpen(false)
  }
  const handleValidateIban = async (iban: string) => {
    setAttempts(0)
    await validateIban(iban)
    setLoading(true)
    setIban(iban)
  }
  useEffect(() => {
    let timer: NodeJS.Timeout

    if (loading && iban) {
      const interval = 100
      const totalDuration = 3000
      let elapsed = 0
      let response: any
      timer = setInterval(() => {
        elapsed += interval
        if (elapsed >= totalDuration) {
          const onLoadingEnd = async () => {
            clearInterval(timer)
            try {
              response = await isValidatedIban(iban)
              if (response.valid === false) {
                setError(true)
              } else {
                window.location.reload()
                setLoading(false)
                setIban('')
                setError(false)
              }
            } catch (e) {
              console.log(e)
              setError(true)

              if (attempts < maxAttempts - 1) {
                // If attempts are less than maxAttempts, reset elapsed time and increment attempts
                setAttempts((prev) => prev + 1)
                elapsed = 0 // Reset elapsed time
              } else {
                setLoading(false)
                setIban('')
                if (error === true)
                  toast.error(
                    t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.european_account.error')
                  )
              }
            }
          }
          onLoadingEnd()
        }
      }, interval)
    }
    return () => clearInterval(timer)
  }, [attempts, iban, loading])

  return (
    <Column {...props}>
      <LayoutTitle
        action={
          <Button onClick={() => setModalAddIban(true)} icon={PlusCircleIcon}>
            {t('aliasPaymentsPage.button.add_iban')}
          </Button>
        }
      >
        {t('aliasPaymentsPage.title')}
      </LayoutTitle>

      <ModalAddIban
        open={modalAddIban}
        onClose={() => setModalAddIban(false)}
        onValidated={async (iban) => {
          try {
            await createIban({ UUID: user?.naturalPerson?.UUID || '', iban })
            toast.success(t('success.create'))
            setModalAddIban(false)
            refetch()
          } catch (_) {
            toast.error(t('error.create'))
          }
        }}
      />
      <ModalDeleteAlias
        isOpen={!!deletedIBAN}
        onClose={() => setDeletedIBAN(null)}
        onValidated={onDelete}
      >
        <Base>{t('aliasPaymentsPage.confirm_delete', { name: deletedIBAN?.Iban })}</Base>
      </ModalDeleteAlias>
      <div className={'flex flex-col max-w-3xl'}>
        {ibans &&
          ibans?.map((aliasIban) => (
            <IbanCard
              key={aliasIban.UUID}
              iban={aliasIban}
              onDelete={() => setDeletedIBAN(aliasIban)}
              onPublicClick={() => navigate(Routes.account_alias_public)}
              onClickHandleVerify={handleValidateIban}
              loading={iban === aliasIban.Iban && loading}
              isDisabledValidationButton={iban !== aliasIban.Iban && loading}
            />
          ))}
      </div>
      {!ibans ||
        (ibans.length === 0 && (
          <>
            <div className="md:border-2 md:border-slate-50 dark:md:border-slate-800">
              <div className="flex items-center justify-between my-4">
                <div className="w-full flex items-center flex-col space-y-4">
                  <EmptyPageCactusAnimation />
                  <Info className={'text-center'}>
                    {t('aliasPaymentsPage.no_iban_helper.first_part')}
                    <br />
                    {t('aliasPaymentsPage.no_iban_helper.second_part')}
                    <A onClick={() => setModalAddIban(true)}>
                      {t('aliasPaymentsPage.no_iban_helper.link_part')}
                    </A>
                  </Info>
                </div>
              </div>
            </div>
          </>
        ))}
      {!user?.naturalPerson?.codeShop && user?.naturalPerson.olkypay_account_requested !== 1 && (
        <>
          <div className="md:border-2 md:border-slate-50 dark:md:border-slate-800 mt-4">
            <div className="flex items-center justify-between my-4">
              <div className="w-full flex flex-col p-4">
                <Base className="items-center font-semibold text-base text-slate-400">
                  {t('aliasPaymentsPage.open_account')}
                </Base>
                <Button
                  size="md"
                  theme={Themes.primary}
                  onClick={() => setIsModalIbanOpen(true)}
                  iconPosition="before"
                  className="border-none text-white mt-4 hover:bg-slate-50 dark:hover:bg-slate-50 dark:bg-white dark:text-white h-8  sm:h-10 w-48 text-xs sm:w-64 sm:text-base lg:h-8 xl:h-10 xl:text-lg lg:text-sm xl:text-base  lg:w-72 "
                >
                  {t('olkypass.mainPage.olkypass.bloc2.btn')}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}

      <ModalIbanAccount
        isOpen={isModalIbanOpen}
        onClose={() => {
          sessionStorage.removeItem('DocumentKsyc')
          sessionStorage.removeItem('DocumentQuizKyc')
          setIsModalIbanOpen(false)
        }}
        openKYCModal={openKYCModal}
      />
      <ModalKYCIdentity onClose={() => setModalIdentity(false)} open={modalIdentity} />
    </Column>
  )
}
