import React, { useState } from 'react'
import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { Base } from '@/atoms-react/text/Text.react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@/atoms-react/input-checkbox/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { Button } from '@/atoms-react/button/Button.react'
import { SolidAuthnModal } from '@/app/olkypass/views/identity/components/security/solid-authn-modal.react.tsx'
import { useKypay } from '@/features/kypay/hooks/use-kypay.ts'
import toast from 'react-hot-toast'
type ModalAddWalletProps = {
  onValidated: () => void
} & ModalProps

export const ModalAddWallet = ({ onValidated = () => {}, ...props }: ModalAddWalletProps) => {
  const { t } = useTranslation(['wallet'])
  const [_wallet, _setWallet] = useState<string | null>('')
  const [agrees, setAgrees] = useState<boolean>(false)
  const [actionData, setActionData] = useState<string | null>(null)
  const [displayAuthenticationModal, setDisplayAuthenticationModal] = useState<boolean>(false)
  const { createUserWalletMutation } = useKypay()
  const { mutateAsync: createUserWallet, isPending } = createUserWalletMutation()
  const execute = async () => {
    handleCloseAuthModal()
    if (!_wallet) {
      toast.error(t('kypay.modal_add_wallet.error.must_enter_wallet'))
      return
    }
    if (!agrees) {
      toast.error(t('kypay.modal_add_wallet.error.must_accept_condition'))
      return
    }
    const response = await createUserWallet(_wallet)
    if (response.success) {
      props.onClose?.()
      toast.success(t('kypay.success.create_wallet'))
      onValidated()
    } else {
      toast.error(t('kypay.error.create_wallet'))
    }
  }
  const handleCloseAuthModal = () => {
    setDisplayAuthenticationModal(false)
  }

  return (
    <>
      <Modal className="sm:w-full w-full max-w-lg" {...props}>
        <ModalContent title={t('kypay.modal_add_wallet.label.title')}>
          <div className={'mt-4 opacity-1'}>
            <div className={'w-full mt-4 mb-6'}>
              <InputLabel
                autoFocus={true}
                label={t('kypay.modal_add_wallet.label.new_wallet')}
                value={_wallet ?? ''}
                onChange={(value) => _setWallet(value)}
              />
            </div>

            <Checkbox value={agrees} onChange={(value) => setAgrees(value)}>
              <Base className="mb-6 cursor-pointer">
                {t('kypay.modal_add_wallet.label.checkbox_agree_correct_wallet')}
              </Base>
            </Checkbox>

            <Button
              disabled={!agrees || !_wallet}
              loading={isPending}
              onClick={() => {
                setActionData(JSON.stringify({ wallet: _wallet }))
                setDisplayAuthenticationModal(true)
              }}
            >
              {t('button.continue')}
            </Button>
          </div>
        </ModalContent>
      </Modal>
      {displayAuthenticationModal && (
        <SolidAuthnModal
          action="new_wallet"
          actionData={actionData}
          onClose={handleCloseAuthModal}
          onValidated={async () => {
            execute()
          }}
        />
      )}
    </>
  )
}
