import Image from '@/atoms-react/image/Image.react'
import { Base, SectionTitle } from '@/atoms-react/text/Text.react'
import { useTranslation, Trans } from 'react-i18next'
import { Button } from '@/atoms-react/button/Button.react'

type IdentificationPendingProps = {
  onValidated?: () => void
}

export const IdentificationPending = ({ onValidated = () => {} }: IdentificationPendingProps) => {
  const { t } = useTranslation(['atoms'])
  return (
    <>
      <div className="max-w-4xl py-4 mx-auto text-center px-4">
        <Image
          className="h-auto md:w-80 w-60 mx-auto mb-4"
          alt={t('molecules.modal_kyc_identity.step3_image_alt_pending')}
          src={'/images/olkypass/validationKycPending.svg'}
        />
        <div className="mx-auto text-center px-4">
          <SectionTitle className="text-base mb-4">
            {t('molecules.modal_kyc_identity.step3_title_pending')}
          </SectionTitle>
          <Base className="text-gray-400 font-medium">
            <Trans
              i18nKey={'molecules.modal_kyc_identity.step3_content_pending'}
              ns={'atoms'}
              components={{ span: <span className="text-blue-500 font-semibold" /> }}
            />
          </Base>
        </div>
      </div>
      <div className={'flex justify-end'}>
        <Button className={'w-fit h-10 font-medium'} onClick={onValidated}>
          {t('molecules.modal_kyc_identity.continue')}
        </Button>
      </div>
    </>
  )
}
