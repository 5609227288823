import { twMerge } from 'tailwind-merge'
import Image from '@/atoms-react/image/Image.react.tsx'
import { CardTitle } from '@/atoms-react/text/Text.react.tsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { KYCStatusType } from '@/features/kyc/types.ts'
import { AnimatedEnvelopePass } from '@/components/animations/envelope-olkypass/index.react.tsx'
import { useLocale } from '@/features/utils/hooks/use-locale.ts'

type HeaderProps = {
  id?: string | number | null
  status: string
}
export const HeaderIbanModal = ({ id, status }: HeaderProps) => {
  const { t } = useTranslation(['pass'])
  const { locale } = useLocale()
  const backgroundStartStep =
    status === KYCStatusType.DECLARATIVE
      ? 'bg-slate-50'
      : " bg-cover bg-[url('/images/olkypass/olkyPayConso.png')]"

  const srcImg =
    locale.language === 'FR'
      ? '/images/olkypass/status_authenticated.png'
      : '/images/olkypass/status_authenticatedEn.png'
  return (
    <>
      <div className="h-2/5 md:h-1/2 w-full rounded-sm mb-6 flex items-center justify-center overflow-hidden">
        <div
          className={twMerge(
            'h-[200px] w-full rounded-sm flex items-center justify-center',
            backgroundStartStep
          )}
        >
          {status === KYCStatusType.DECLARATIVE ? (
            <AnimatedEnvelopePass srcImg={srcImg} />
          ) : (
            <div className="bg-blue-800 bg-opacity-30 h-full w-full flex flex-col items-center justify-center gap-y-3 p-6">
              <Image src="/images/olkypass/olkypay-logo.svg" className="w-24" alt="Olky Logo" />
              <CardTitle className="text-white text-center">
                {id
                  ? t('olkypass.mainPage.olkypass.modalOpenOlkyPay.opening_account_pro')
                  : t('olkypass.mainPage.olkypass.modalOpenOlkyPay.opening_account_conso')}
              </CardTitle>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
