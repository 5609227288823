import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Base } from '@/atoms-react/text/Text.react'
import { Logo } from '@/components/logo/Logo.react'
import Column from '@/atoms-react/column/Column.react'
import { FlowHeader } from '../../../flow-header.react'

export const CreatingAccount = () => {
  const { t } = useTranslation(['pass'])
  const [current, setCurrent] = useState(0)
  const [animationClass, setAnimationClass] = useState('fade-in-down')

  useEffect(() =>{ window.scrollTo(0, 0)}, [])

  const sentences = [
    t('flow.id_control.signup.label.creating_account.0'),
    t('flow.id_control.signup.label.creating_account.1'),
    t('flow.id_control.signup.label.creating_account.2')
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationClass('fade-out-down')

      setTimeout(() => {
        setCurrent(prev => (prev + 1) % sentences.length)
        setAnimationClass('fade-in-down')
      }, 300) // This should match the fadeOut animation time
    }, 3000)

    return () => clearInterval(interval)
  }, [])

  return <Column>
    <FlowHeader back={false} title={t('flow.id_control.signup.label.creating_account_title')} />
    <div className="flex items-center justify-center grow h-full flex-col my-32">
      <Logo loading init className="animated w-24 mb-8" />
      <div className={`${animationClass} ` + 'h-8 w-full text-center'}>
        <Base className="animate-pulse">{sentences[current]}</Base>
      </div>
    </div>
  </Column>
}
