import { getRoute } from '@/app/olkypass/utils/routes'
import { Routes } from '../router'

export const getRoutesWithoutPadding = (session_uuid: string) => [
  getRoute(Routes.flow_step, { session_uuid, block: 'documents', step: 'kyc-session-iframe' })
]

export const cspWithoutActivity = { CHÔMEUR: 14, INACTIF: 18, MILITAIRE: 13, ETUDIANT: 15 }
export const findMissingProfileKeys = (data: any) => {
  const structuredRequiredKeys = {
    'phone.phone': ['AliasMobile'],
    'identity.person': ['LastName', 'FirstName', 'Gender', 'CountryOfCitizenship'],
    'identity.birth': ['countryOfBirth', 'DateOfBirth', 'StateOfBirth'],
    'identity.residence': ['Address'],
    'identity.activity': ['CSPCode', 'ActivityCode'],
    'identity.monthly_income': ['minimumMonthlyIncome', 'maximumMonthlyIncome']
  }

  const missingItems = {}

  Object.keys(structuredRequiredKeys).forEach((section) => {
    const requiredFields = structuredRequiredKeys[section]
    missingItems[section] = []

    if (section === 'identity.activity') {
      if (!Object.values(cspWithoutActivity).includes(data.CSPCode) && !data.ActivityCode) {
        missingItems[section].push(...requiredFields) // Use spread operator to add all required fields
      }
    } else if (section === 'identity.monthly_income') {
      if (
        (data.minimumMonthlyIncome === null || data.minimumMonthlyIncome === undefined) &&
        (data.maximumMonthlyIncome === null || data.maximumMonthlyIncome === undefined)
      ) {
        missingItems[section].push(...requiredFields) // Use spread operator to add all required fields
      }
    } else {
      requiredFields.forEach((key) => {
        if (key === 'Address' || key === 'AliasMobile') {
          // Check if Address / AliasMobile has at least one element
          if (!Array.isArray(data[key]) || data[key].length === 0) {
            missingItems[section].push(key)
          }
        } else if (key === 'DateOfBirth') {
          // Check if DateOfBirth has a date property
          if (!data[key] || !data[key].date) {
            missingItems[section].push(key)
          }
        } else {
          // Check if other keys are missing
          if (!data[key]) {
            missingItems[section].push(key)
          }
        }
      })
    }
  })
  // Remove keys with empty arrays
  Object.keys(missingItems).forEach((key) => {
    if (missingItems[key].length === 0) {
      delete missingItems[key] // Remove key if its value is an empty array
    }
  })
  console.log(missingItems)
  return missingItems
}
