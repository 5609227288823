import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import i18n from '@/features/utils/i18n.ts'
const uris = {
  _get: {
    partners: '/partner/list'
  },
  _post: {
    manage_subscription: 'partner/manage_subscription'
  }
}
const state = {
  partners: []
}

const getters = {
  partners: state => state.partners
}

const mutations = {
  LOAD_PARTNERS(state, response) {
    state.partners = response.data
  },
  RESET_LOADED_PARTNERS(state) {
    state.partners = []
  },
  MANAGE_SUBSCRIPTION() {
    this.dispatch('addNotification', {
      type: 'success',
      message: i18n().global.t('olkypass.accountPage.notifications.success.profile.update')
    })
  }
}

const actions = {
  async loadPartners({ commit }) {
    try {
      const response = await fetchServer(uris._get.partners)
      commit('LOAD_PARTNERS', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async manageSubscription({ commit }, data) {
    try {
      const response = await fetchServer(uris._post.manage_subscription, { body: data })
      commit('MANAGE_SUBSCRIPTION', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  resetLoadedPartners({ commit }) {
    commit('RESET_LOADED_PARTNERS')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
