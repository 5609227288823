import Column from '@/atoms-react/column/Column.react'
import { IFrameKYCAuthorizations } from '@/features/kyc/constants'
import { generateSessionURL } from '@/features/kyc/functions'
import { useKYC } from '@/features/kyc/hooks/use-kyc'
import {
  CreateKYCDocument,
  KYCDocumentType,
  KYCSession,
  KYCSessionScenario,
  KYCSessionURLParams
} from '@/features/kyc/types'
import { useUser } from '@/features/user/hooks/use-user'
import React, { useEffect } from 'react'

type KYCSessionProps = {
  naturalPersonUUID?: string
  KYCSessionState?: KYCSession
  documentType?: KYCDocumentType
  onValidated: (data: CreateKYCDocument) => void
  params?: Partial<KYCSessionURLParams>
} & React.ComponentPropsWithoutRef<'div'>

export const KYCSessionIFrame = ({
  naturalPersonUUID,
  KYCSessionState,
  documentType,
  onValidated = () => {},
  params,
  ...props
}: KYCSessionProps) => {
  const { useUserProfile } = useUser()
  const { refetch: refetchUser } = useUserProfile()
  const { KYCStateQuery } = useKYC()
  const { refetch: refetchKYCState } = KYCStateQuery(naturalPersonUUID || '')

  const handleIFrameKYCEvent = async (
    UUID: string,
    event: { data: { event: string; content: { url: string; success: boolean } } }
  ) => {
    if (event.data.event === 'reload') {
      window.location.reload()
    }
    if (event.data.event === 'end_session') {
      refetchKYCState()
      refetchUser()

      let senario = KYCSessionScenario.KYC
      if (documentType?.need_manual_validation) {
        senario = KYCSessionScenario.ManualKYC
      }
      const documentData = {
        sessionId: KYCSessionState?.sessionId ?? '',
        sessionType: KYCSessionState?.sessionType ?? '',
        sessionSenario: senario,
        document: {
          pieceType: documentType,
          number: 'Document'
        },
        AbstractEntity: UUID
      }
      onValidated(documentData as any)
    }
  }

  useEffect(() => {
    const callback = (event: MessageEvent<any>) => {
      handleIFrameKYCEvent(naturalPersonUUID || '', event)
    }

    window.addEventListener('message', callback)
    return () => {
      window.removeEventListener('message', callback)
    }
  }, [])
  return (
    <Column
      className={'w-full min-w-1 flex-1 justify-center h-screen p-0 m-0 self-center'}
      {...props}
    >
      {KYCSessionState && (
        <iframe
          width="100%"
          height="100%"
          src={generateSessionURL(KYCSessionState, params).toString()}
          allow={IFrameKYCAuthorizations}
          loading="eager"
        ></iframe>
      )}
    </Column>
  )
}
