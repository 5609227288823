import './index.css'
import logo from '../../../../public/images/olkypass/olkypasslogoshield.png'
import { useEffect, useState } from 'react'
export const AnimatedShield = ({
  percentage,
  color,
  style
}: {
  percentage: number
  color: string
  style: string
}) => {
  const value = percentage
  const className = `absolute ${style}  z-10	 `

  const [size, setSize] = useState<string>('121')

  const handleWindowSizeChange = () => {
    if (window.innerWidth > 1280) setSize('121')
    if (window.innerWidth <= 1600 && window.innerWidth > 768) setSize('110')
    else if (window.innerWidth > 390 && window.innerWidth <= 768) setSize('108')
    else if (window.innerWidth < 368) setSize('0')
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [window])

  useEffect(() => {}, [size])

  return (
    <div className="relative">
      <img src={logo} className={className} />
      <svg
        className="shrink-0"
        width={size}
        height="142"
        viewBox="0 0 121 142"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_2076_2560"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="121"
          height="142"
        >
          <path
            d="M108.827 100.53L108.826 100.532C104.305 109.258 96.3396 117.227 87.1631 123.724C78.4292 129.908 68.851 134.578 60.6882 137.211C52.5244 134.578 42.9461 129.912 34.2128 123.731C25.0375 117.237 17.0735 109.269 12.5518 100.535C7.04691 89.864 5.74946 81.13 5.13154 69.798C4.49017 56.4364 4.68113 35.9586 4.99755 21.4276C5.86538 21.3603 6.72411 21.2946 7.57443 21.2296C26.9201 19.7504 41.9148 18.6039 60.7264 5.09499C79.4986 18.5536 94.4306 19.7123 113.666 21.2049C114.582 21.2759 115.507 21.3478 116.443 21.4217C116.731 35.9652 116.92 56.4443 116.245 69.801L116.245 69.8038C115.681 81.1188 114.334 89.9106 108.827 100.53Z"
            fill="#F0F2F3"
            stroke="white"
            strokeWidth="8"
          />
        </mask>
        <g mask="url(#mask0_2076_2560)">
          <path
            d="M1.08255 17.7278C24.5778 15.7816 39.8479 16.0184 60.7244 0.121094C81.637 16.0184 96.8709 15.7437 120.366 17.7278C120.692 32.2999 120.981 55.3358 120.24 70.0028C119.661 81.615 118.252 91.0443 112.378 102.372C102.546 121.346 78.3639 136.033 60.6882 141.402C43.0126 136.033 18.8305 121.365 8.99864 102.372C3.12483 90.9873 1.76933 81.615 1.13677 70.0028C0.43191 55.3358 0.721084 32.2999 1.08255 17.7278Z"
            fill="#F0F2F3"
          />

          <mask
            id="mask1_2076_2560"
            style={{ maskType: 'alpha', transition: 'all 1s' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y={100 - value + '%'}
            width="120"
            height={value + '%'}
          >
            <rect
              x="0"
              y={100 - value + '%'}
              width="120"
              height={value + '%'}
              style={{ transition: 'all 1s' }}
              fill={color}
            />
          </mask>
          <g x="0" width="120" className="shield-animated-wave-1">
            <g
              x="0.705078"
              width="120"
              style={{
                transition: 'all 1s',
                transform: `translate(0%, ${100 - value - 5}%)`
              }}
            >
              <path
                d="M275.391 0.640562C260.15 4.08564 221.861 9.08544 197.012 3.30667C182.763 -0.00686526 168.404 -0.374013 155.637 0.660601V0.640565C155.591 0.651013 155.544 0.661474 155.498 0.67195C146.457 1.41249 138.218 2.85444 131.388 4.44777C114.136 6.30269 93 6.96752 77.2576 3.30667C46.1952 -3.91679 14.6117 2.86199 0.130859 7.64064L119.885 7.64058C119.885 7.6406 119.885 7.64056 119.885 7.64058L275.391 7.64056V0.640562Z"
                fill={color}
              />
            </g>
          </g>
          <g x="0" width="120" className="shield-animated-wave-2">
            <g
              x="0"
              width="120"
              style={{
                transition: 'all 1s',
                transform: `translate(0, ${100 - value - 5}%)`
              }}
            >
              <path
                d="M275.391 0.640562C260.15 4.08564 221.861 9.08544 197.012 3.30667C182.763 -0.00686526 168.404 -0.374013 155.637 0.660601V0.640565C155.591 0.651013 155.544 0.661474 155.498 0.67195C146.457 1.41249 138.218 2.85444 131.388 4.44777C114.136 6.30269 93 6.96752 77.2576 3.30667C46.1952 -3.91679 14.6117 2.86199 0.130859 7.64064L119.885 7.64058C119.885 7.6406 119.885 7.64056 119.885 7.64058L275.391 7.64056V0.640562Z"
                fill={color}
                fillOpacity={0.5}
              />
            </g>
          </g>
          <g mask="url(#mask1_2076_2560)">
            <rect
              x="0.705078"
              y={100 - value + '%'}
              width="120"
              height={value + '%'}
              fill={color}
              style={{ transition: 'all 1s' }}
            />
          </g>

          <path
            d="M108.827 100.53L108.826 100.532C104.305 109.258 96.3396 117.227 87.1631 123.724C78.4292 129.908 68.851 134.578 60.6882 137.211C52.5244 134.578 42.9461 129.912 34.2128 123.731C25.0375 117.237 17.0735 109.269 12.5518 100.535C7.04691 89.864 5.74946 81.13 5.13154 69.798C4.49017 56.4364 4.68113 35.9586 4.99755 21.4276C5.86538 21.3603 6.72411 21.2946 7.57443 21.2296C26.9201 19.7504 41.9148 18.6039 60.7264 5.09499C79.4986 18.5536 94.4306 19.7123 113.666 21.2049C114.582 21.2759 115.507 21.3478 116.443 21.4217C116.731 35.9652 116.92 56.4443 116.245 69.801L116.245 69.8038C115.681 81.1188 114.334 89.9106 108.827 100.53Z"
            strokeWidth="8"
            stroke="url(#paint0_linear_3412_3983)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_3412_3983"
            x1="69.6553"
            y1="0"
            x2="69.6553"
            y2="162.448"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor={color} />
          </linearGradient>
          <clipPath id="clip0_2076_2560">
            <rect
              width="29.935"
              height="22.0531"
              fill="white"
              transform="translate(35.2676 36.5646654)"
            />
          </clipPath>
          <clipPath id="clip1_2076_2560">
            <rect
              width="26.935"
              height="22.0531"
              fill="white"
              transform="translate(35.2676 36.3945)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
