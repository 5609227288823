import { Routes } from '@/features/router'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { setJwt } from '../utils'
import { refreshVueAuth } from './use-auth-vue'
import { useUser } from '@/features/user/hooks/use-user'
import { FlowApiClient } from '@/features/flow/api-client/api-client.ts'

type CachedUser = {
  email?: string
  name?: string
  avatar?: string
}

const getCachedUser = () => JSON.parse(localStorage.getItem('user') || '{}') as CachedUser

const setCachedUser = (user: CachedUser) => localStorage.setItem('user', JSON.stringify(user))

/**
 * This is a placeholder function that will be replaced by a react "setState" equivalent
 * so vue can control the refresh of the user profile
 */
let _reactLoginStatusChange = (_: boolean) => {}

export const reactLoginStatusChange = (status: boolean) => _reactLoginStatusChange(status)

export const useAuth = () => {
  const navigate = useNavigate()
  const { useUserProfile } = useUser()
  const user = useUserProfile()
  const cached = useQuery<CachedUser>({ queryKey: ['cached-user'], queryFn: getCachedUser })

  useEffect(() => {
    if (!user.data?.naturalPerson?.UUID) return
    setCachedUser({
      email: user.data?.naturalPerson?.AliasEmail?.[0]?.Mail || '',
      name:
        [user.data?.naturalPerson?.FirstName, user.data?.naturalPerson?.LastName]
          .filter((a) => a)
          .join(' ') || '',
      avatar: ''
    })
  }, [user.data?.naturalPerson?.UUID])

  _reactLoginStatusChange = () => user.refetch()

  const logout = () => {
    setJwt({ token: null, refresh: null })
    sessionStorage.clear()
    document.location = '/'
  }

  const refresh = async (redirect?: boolean) => {
    await user.refetch()
    await refreshVueAuth()
    if (redirect) {
      try {
        const params = new URLSearchParams(window.location.search)
        const redirect = params.get('r')
        const route = (redirect && decodeURIComponent(redirect)) || Routes.dashboard || ''
        navigate(route)
      } catch (e) {
        document.location = '/'
      }
    }
  }

  const createMirrorIdentity = async (partner_uuid: string) => {
    try {
      await FlowApiClient.createMirrorFromReal(partner_uuid)
    } catch (e) {
      console.log('create mirror issue')
    }
  }
  return {
    logout,
    cached,
    removeCached: () => {
      setCachedUser({})
      cached.refetch()
      if (user.data) {
        logout()
      }
    },
    user,
    refresh,
    loading: user.isLoading,
    createMirrorIdentity
  }
}
