import { SignupState } from '@/app/olkypass/flow/components/gateways/signup/index.react'
import { getJwt, setJwt } from '@/features/auth/utils'
import { useCompany } from '@/features/company/hooks/use-react-company'
import { ConfirmationResponse } from '@/features/register/types'
import { useAddress } from '@/features/user/address/hooks/use-address'
import { AuthApiClient } from '@/features/user/api-client/api-client'
import { convert_naturalPerson_naturalEntityModify } from '@/features/user/converters'
import { factory_naturalPerson_edit } from '@/features/user/factories'
import { useUser } from '@/features/user/hooks/use-user'
import useCreatePhone from '@/features/user/phone/hooks/use-create-phone'
import { formatUTC } from '@/features/utils/functions'
import { client } from '@passwordless-id/webauthn'
import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import useAuthenticateWebAuthnMutation from './use-authenticate-web-authn'
import useCheckLogin from './use-check-login'
import { useCompleteCredentials } from './use-complete-credentials'

export type UseCreateAccountProps = {
  signupState: SignupState
  email: string
  password: string
}

export const useCreateAccount = (
  options?: UseMutationOptions<ConfirmationResponse, Error, UseCreateAccountProps>
) => {
  const { t } = useTranslation(['pass'])
  const { mutateAsync: completeCredentials } = useCompleteCredentials()
  const { mutateAsync: checkLogin } = useCheckLogin()
  const { mutateAsync: webauthnLogin } = useAuthenticateWebAuthnMutation()

  const { useEditProfile } = useUser()
  const { mutateAsync: editProfile } = useEditProfile()
  const { createAddressMutation } = useAddress()
  const { mutateAsync: createAddress } = createAddressMutation()
  const { mutateAsync: createPhone } = useCreatePhone()
  const { useCreateCompany } = useCompany()
  const { mutateAsync: createBusiness } = useCreateCompany()

  return useMutation<ConfirmationResponse, Error, UseCreateAccountProps>({
    mutationFn: async ({ signupState, password, email }: UseCreateAccountProps) => {
      // Create the account with partial infos
      await completeCredentials({
        FirstName: signupState?.identity?.person?.firstnames ?? '',
        LastName: signupState?.identity?.person?.lastnames ?? '',
        email,
        telephone: signupState?.phone?.phone ?? '',
        password
      }).catch((error) => {
        console.error('Error', error)
        throw new Error(t('flow.id_control.error.complete_credentials'))
      })

      // Try Webauthn
      const isWebauthnAvailable = client.isAvailable()

      if (isWebauthnAvailable) {
        const webauthnToken = await webauthnLogin(email).catch((error) =>
          console.error('error', error)
        )
        if (webauthnToken) {
          setJwt({ token: webauthnToken, refresh: webauthnToken })
        }
      }

      // Classical login
      if (!isWebauthnAvailable || !getJwt()?.token) {
        const checkLoginResult = await checkLogin({ email, password }).catch((error) => {
          console.error('Error', error)
          throw new Error(t('flow.id_control.error.check_login_failed'))
        })
        setJwt({ token: checkLoginResult.token, refresh: checkLoginResult.token })
      }

      const staticData = await AuthApiClient.getStaticData('fr')

      // Edit profile
      const user = await AuthApiClient.profile()
      const naturalPersonEdit = factory_naturalPerson_edit(
        user.naturalPerson,
        signupState,
        staticData
      )

      const naturalEntity = convert_naturalPerson_naturalEntityModify(naturalPersonEdit)

      await editProfile(naturalEntity).catch((error) => {
        console.error('Error', error)
        throw new Error(t('flow.id_control.error.complete_credentials'))
      })

      await createAddress({
        userUUID: user?.naturalPerson?.UUID,
        address: {
          address: signupState.identity?.addressResidence?.addressLine ?? '',
          zip: signupState.identity?.addressResidence?.zip ?? '',
          country: parseInt(signupState.identity?.addressResidence?.countryCode ?? '-1'),
          city: signupState.identity?.addressResidence?.city ?? '',
          tag: 'Résidence principale'
        }
      })

      await createPhone({
        MobileIndicatif: signupState.phone?.countryPrefix ?? '',
        MobileNumber: signupState.phone?.phone ?? '',
        UUID: user.naturalPerson.UUID
      })

      // Create Business
      if (signupState.pro) {
        const business = {
          company: {
            legal_name: signupState.pro.company?.name ?? '',
            legal_form: signupState.pro.company?.legalForm ?? '',
            code_nace: signupState.pro?.activity?.nace ?? '',
            comment: signupState.pro?.activity?.description ?? '',
            RCS_number: signupState.pro?.RCS?.number,
            date_incorporation: signupState.pro?.RCS?.incorporationDate
              ? formatUTC(signupState.pro.RCS.incorporationDate.toISOString())
              : undefined,
            VAT_number: signupState.pro?.company?.VATnumber,
            website_url: '',
            mail_address: signupState.pro?.company?.email ?? ''
          },
          address: {
            address_1: signupState.pro?.address?.addressLine ?? '',
            zip_code: signupState.pro?.address?.zip ?? '',
            city: signupState.pro?.address?.city ?? '',
            country_id:
              staticData.countries.find(
                (country) => country.alpha2 === signupState.pro?.address?.countryCode
              )?.id ?? -1
          }
        }

        await createBusiness(business as any)
      }

      return { success: true, message: '' }
    },
    ...options
  })
}
