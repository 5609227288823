import { defineComponent, type Ref } from 'vue'

export type VModelProps<T> = {
  modelValue?: T
  model?: Ref<T>
}

export const defineVueComponent = defineComponent as any

export function defineJsxComponent<T>(
  c: (
    props: T,
    args: {
      model: () => any
      attrs: any
      emit: (...args: any) => void
      slots: any
    }
  ) => () => any,
  options: {
    inheritAttrs?: boolean
    props?: (keyof T)[]
  } = {}
) {
  return defineComponent(
    (props, args) => {
      const a = jsxComponent(props, args) as any
      return c(a.props, a)
    },
    { inheritAttrs: false, ...options } as any
  ) as any
}

export function jsxComponent<T>(_: T, args: any) {
  const props = args.attrs as T //https://vuejs.org/api/general#function-signature
  const model = () => args.attrs.model?.value || args.attrs.modelValue || ''
  const emit = (event: string, ...args2: any[]) => {
    if (event === 'update:modelValue' && args.attrs.model) args.attrs.model.value = args2[0]
    args.emit(event, ...args2)
  }
  return { props, emit, attrs: args.attrs, model, slots: args.slots }
}
