import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react'
import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import { DeclarativeResidence } from '@/features/flow/types'
import { FormAddAddress } from '@/molecules/form-add-address/index.react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FlowHeader } from '../../../../flow-header.react'

interface DeclarativeIdentityResidenceProps extends LayoutBaseProps {
  onValidated?: (output: DeclarativeIdentityResidenceOutput) => void
}

export type DeclarativeIdentityResidenceOutput = DeclarativeResidence

export function isDeclarativeResidenceValid(data: DeclarativeResidence | null | undefined) {
  if (!data) return false
  return !!data.addressLine && !!data.city && !!data.countryCode
}

function DeclarativeIdentityResidence({
  onValidated = () => {}
}: DeclarativeIdentityResidenceProps) {
  const { t } = useTranslation(['pass', 'atoms'])
  const [data, setData] = useState<DeclarativeResidence>({})
  const [addressValid, setAddressValid] = useState(false)

  function execute() {
    if (!isDeclarativeResidenceValid(data) || !addressValid) {
      toast.error(t('flow.id_control.error.must_enter_fields'))
      return
    }

    onValidated(data)
  }

  return (
    <>
      <Column className="justify-between">
        <div className="mb-12">
          <FlowHeader
            title={t('flow.id_control.signup.declarative_identity.label.three_title')}
            description={t('flow.id_control.signup.declarative_identity.label.three_description')}
          />
          <FormAddAddress
            autoFocus
            fields={{
              tag: { visible: false },
              isPrincipalAddress: { visible: false },
              address: { visible: true, label: t('flow.id_control.input.address_label') },
              country: {
                visible: true,
                label: t('flow.id_control.input.country_of_residence_label')
              },
              city: {
                visible: true,
                label: t('flow.id_control.input.city_of_residence_label')
              },
              zip: {
                visible: true,
                label: t('flow.id_control.input.zip_label')
              }
            }}
            address={{
              address: data.addressLine ?? '',
              zip: data.zip ?? '',
              country: parseInt(data.countryCode ?? '-1'),
              city: data.city ?? '',
              tag: t('atoms:molecules.add_address_form.is_principal_address'),
              isPrincipalAddress: true
            }}
            onChange={(address, valid) => {
              setAddressValid(valid)
              setData((data) => ({
                ...data,
                addressLine: address.address,
                city: address.city,
                zip: address.zip,
                countryCode: (address.country ?? '').toString()
              }))
            }}
            className={'mt-0 mb-0'}
            noAutoComplete={true}
          />
        </div>
        <div className="shrink-0 flex flex-col items-center">
          <DotsNavigation total={5} current={2} />
          <Button
            shortcut="enter"
            onClick={() => execute()}
            icon={ChevronRightIcon}
            className="mt-4"
          >
            {t('flow.id_control.input.continue')}
          </Button>
        </div>
      </Column>
    </>
  )
}

export default DeclarativeIdentityResidence
