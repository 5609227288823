export const KypayInstrumentIcon = (props: {
  size?: number
  color?: string
  style?: any
  className?: string
  isDark?:boolean
}) => (
  <div className={props.className} style={props.style}>
    <img src={ props.isDark?"/images/olkypass/kpayWhiteLogo.svg":"/images/olkypass/KypayInstrumentIcon.png" }alt="KYPAY" />
  </div>
)
