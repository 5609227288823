import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export const RadioCard = ({
  children,
  onClick,
  className = '',
  active,
  disabled = false
}: {
  children?: ReactNode | ReactNode[]
  active: boolean
  className?: string
  onClick: () => void
  disabled?: boolean
}) => (
  <div
    className={twMerge(
      'w-full border-2 border-slate-50 dark:border-slate-700 rounded-sm flex flex-row p-4 space-x-3',
      active && !disabled && ' border-blue-500',
      disabled && 'select-none opacity-50',
      !disabled && 'cursor-pointer',
      className
    )}
    onClick={disabled ? () => {} : onClick}
  >
    <div
      className={twMerge(
        'w-6 h-6 min-w-6 border-2 border-slate-50 dark:border-slate-700 rounded-full flex items-center justify-center',
        active && !disabled && 'border-blue-500'
      )}
    >
      <div className={twMerge('w-3 h-3 rounded-full', active && !disabled && 'bg-blue-500')}></div>
    </div>
    <div>{children}</div>
  </div>
)
