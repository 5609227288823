import { Button, ButtonProps } from '@/atoms-react/button/Button.react'
import { Base, SectionTitle } from '@/atoms-react/text/Text.react'
import { Themes } from '@/types/theme'
import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import React, { ReactNode, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { animatedNext } from './layout/AnimatedForm.react'
import { OlkyPassRoutes } from '@/app/olkypass/utils/routes.ts'
import { useNavigate, useParams } from 'react-router-dom'
import { getRoute } from '@/app/olkypass/utils/routes'
import { Routes } from '@/features/router'
import { Modal } from '@/atoms-react/modal/index.react'
import { useTranslation } from 'react-i18next'

export const FlowHeader = ({
  title,
  description,
  sub_description,
  back,
  goHomePage
}: {
  title: string
  description?: string | ReactNode
  sub_description?: string | ReactNode
  back?: boolean
  goHomePage?: boolean
}) => (
  <div>
    {(back || back === undefined) && !goHomePage && <BackButton />}
    {goHomePage !== undefined && goHomePage && <HomeButton />}
    <SectionTitle className={'pb-4'}>{title}</SectionTitle>
    {description && <Base className={sub_description ? 'pb-2' : 'pb-8'}>{description}</Base>}
    {sub_description && <Base className={'pb-8'}>{sub_description}</Base>}
  </div>
)

export type BackButtonProps = {
  onClick?: (event: React.MouseEvent) => void
} & Omit<ButtonProps, 'onClick'>

export const BackButton = ({ className, onClick, ...props }: BackButtonProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['pass'])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { session_uuid, block, step, substep } = useParams()
  const handleClick = () => {
    if (step == 'phone') {
      setIsOpen(true)
    } else
      animatedNext('back', async () => {
        if (step === 'identity') {
          switch (substep) {
            case 'birth':
              navigate(
                getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'person' })
              )
              break
            case 'residence':
              navigate(
                getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'birth' })
              )
              break
            case 'activity':
              navigate(
                getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'residence' })
              )
              break
            case 'monthly_income':
              navigate(
                getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'activity' })
              )
              break
            default:
              break
          }
        } else navigate(-1)
      })
  }

  return (
    <>
      <Button
        theme={Themes.iconButton}
        icon={ArrowLeftIcon}
        size="lg"
        className={twMerge('mb-4', className)}
        onClick={(e) => (onClick ? onClick(e) : handleClick())}
        {...props}
      />
      <Modal
        className="sm:w-full w-full max-w-xl min-w-xl items-center justify-center"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div className="flex flex-col justify-center items-center p-2">
          <Base className="items-center font-bold text-center text-xl">
            {' '}
            {t('flow.id_control.signup.label.question')}
          </Base>

          <Base className="items-center font-semibold text-center text-base mt-6 text-slate-400">
            {' '}
            {t('flow.id_control.signup.label.question_description')}
          </Base>

          <Button size="lg" className="mt-10" theme={Themes.primary} onClick={() => navigate(0)}>
            {t('flow.id_control.signup.label.question_response1')}{' '}
          </Button>
          <Button
            size="lg"
            className="mt-4 text-primary"
            theme={Themes.white}
            onClick={() => setIsOpen(false)}
          >
            {t('flow.id_control.signup.label.question_response_2')}{' '}
          </Button>
        </div>{' '}
      </Modal>
    </>
  )
}

export const HomeButton = ({ className, onClick, ...props }: BackButtonProps) => {
  const navigate = useNavigate()
  const handleClick = () => animatedNext('back', async () => navigate(OlkyPassRoutes.pass))

  return (
    <Button
      theme={Themes.iconButton}
      icon={ArrowLeftIcon}
      size="lg"
      className={twMerge('mb-4', className)}
      onClick={(e) => (onClick ? onClick(e) : handleClick())}
      {...props}
    />
  )
}
