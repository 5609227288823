import { ConfirmationResponse } from '@/features/register/types'
import { Phone } from '@/features/user/types'
import { fetchServer } from '@/features/utils/fetch'

export class PhoneApiClient {
  static async create(phone: Phone): Promise<ConfirmationResponse> {
    const response = await fetchServer('/user/mobile/create_new', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: phone
    })

    if (!response.ok) throw new Error('Error')

    const res = await response.json()
    return res.data || { existStatus: false }
  }
}
