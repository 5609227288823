import { Button } from '@/atoms-react/button/Button.react'
import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { Base } from '@/atoms-react/text/Text.react'
import { useTranslation } from 'react-i18next'

type ModalKYCAlertProps = { onValidated: () => void } & ModalProps

export const ModalKYCAlert = ({ onValidated, ...props }: ModalKYCAlertProps) => {
  const { t } = useTranslation(['atoms'])

  return <Modal className={'sm:w-full w-full max-w-lg'} {...props}>
    <ModalContent title={t('molecules.modal_kyc_alert.title_to_complete')}>
      <div className={'mt-4 opacity-1'}>
        <div className={'w-full mt-4 mb-6'}>
          {/* {status !== KYCStatusType.VERIFIED && ( */}
          <Base>{t('molecules.modal_kyc_alert.description')}</Base>
          {/* )} */}
        </div>
        <Button onClick={() => onValidated()}>{t('molecules.modal_kyc_alert.action')}</Button>
      </div>
    </ModalContent>
  </Modal>
}
