import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Kycoin } from '../types'
import { KycoinApiClient } from '../api-client/api-client'

export function useKycoin() {
  const queryClient = useQueryClient()
  const getKycoin = (account_id?: string) =>
    useQuery<Kycoin>({
      queryKey: ['kycoin', account_id],
      queryFn: async () => await KycoinApiClient.getKycoin(account_id)
    })
  const kycoinAccountsQuery = () =>
    useQuery({
      queryKey: ['kycoinAccounts'],
      queryFn: () => KycoinApiClient.getKycoinAccounts()
    })

  return {
    queryClient,
    getKycoin,
    kycoinAccountsQuery
  }
}
