import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react'
import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import { DeclarativeMonthlyIncome } from '@/features/flow/types'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FlowHeader } from '../../../../flow-header.react'
import { RadioCard } from '@/atoms-react/radio-card/index.react.tsx'
import { Base } from '@/atoms-react/text/Text.react.tsx'
interface DeclarativeIdentityMonthlyIncomeProps extends LayoutBaseProps {
  onValidated?: (output: DeclarativeIdentityMonthlyIncomeOutput) => void
}

export type DeclarativeIdentityMonthlyIncomeOutput = DeclarativeMonthlyIncome

// MonthlyIncome can be null only if CSP code allows for it. CSP code must be enterred
export function isDeclarativeMonthlyIncomeValid(data: DeclarativeMonthlyIncome | undefined | null) {
  if (!data) return false
  if (!data.trans_key) return false
  return true
}
const monthlyIncomes = [
  {
    trans_key: 'less_1500',
    minimum: 0,
    maximum: 1500
  },
  {
    trans_key: 'between_1500_and_5000',
    minimum: 1500,
    maximum: 5000
  },
  {
    trans_key: 'between_5000_and_10000',
    minimum: 5000,
    maximum: 10000
  },
  {
    trans_key: 'more_10000',
    minimum: 10000,
    maximum: null
  }
]

export { monthlyIncomes }
function DeclarativeIdentityMonthlyIncome({
  onValidated = () => {}
}: DeclarativeIdentityMonthlyIncomeProps) {
  const { t } = useTranslation(['pass'])
  const [data, setData] = useState<DeclarativeMonthlyIncome>({})
  function execute() {
    if (!isDeclarativeMonthlyIncomeValid(data)) {
      toast.error(t('flow.id_control.error.must_enter_fields'))
      return
    }

    onValidated(data)
  }

  return (
    <>
      <Column>
        <div className="grow mb-12">
          <FlowHeader
            title={t('olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.monthly_incomes.question')}
            description=""
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-4 mt-4">
            {monthlyIncomes.map((m) => (
              <RadioCard
                active={data?.trans_key === m.trans_key}
                key={m.trans_key}
                onClick={() => setData(m)}
              >
                <Base>
                  {t(
                    'olkypass.mainPage.olkypass.modalOpenOlkyPay.steps.monthly_incomes.responses.' +
                      m.trans_key
                  )}
                </Base>
              </RadioCard>
            ))}
          </div>
        </div>
        <div className="shrink-0 flex flex-col items-center">
          <DotsNavigation total={5} current={4} />
          <Button
            shortcut="enter"
            onClick={() => execute()}
            icon={ChevronRightIcon}
            className="mt-4"
          >
            {t('flow.id_control.input.continue')}
          </Button>
        </div>
      </Column>
    </>
  )
}

export default DeclarativeIdentityMonthlyIncome
