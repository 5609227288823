import { createStore } from 'vuex'
import notificationModule from './modules/notificationModule.js'
import languageModule from './modules/languageModule.js'
import themeModule from './modules/themeModule.js'
import userModule from './modules/userModule.js'
import loaderModule from './modules/loaderModule.js'
import kycSessionModule from './modules/kycSessionModule.js'
import companyModule from './modules/companyModule.js'
import usefulDataModule from './modules/usefulDataModule.js'
import collaboratorModule from './modules/collaboratorModule.js'
import partnerModule from './modules/partnerModule.js'
import documentModule from './modules/documentModule.js'
import supportModule from './modules/supportModule.js'
import ToolsMixins from '../mixins/ToolsMixins.js'
import kyPayModule from './modules/kypayModule'

const store = createStore({
  modules: {
    notification: notificationModule,
    language: languageModule,
    theme: themeModule,
    user: userModule,
    loader: loaderModule,
    kycSession: kycSessionModule,
    company: companyModule,
    usefulData: usefulDataModule,
    collaborator: collaboratorModule,
    partner: partnerModule,
    document: documentModule,
    support: supportModule,
    kyPay: kyPayModule
  }
})

export function mapGetters(getters) {
  const store = ToolsMixins.$store
  const mappedGetters = {}
  getters.forEach(getterName => {
    mappedGetters[getterName] = function () {
      return store.getters[getterName]
    }
  })
  return mappedGetters
}

export default store
