<template>
  <ion-page id="TransferPage" class="tracking-normal">
    <ion-content :fullscreen="true">
      <header-app-with-label :title="$t('olkypay.tabs.homePage.transfer')"></header-app-with-label>
      <ion-grid class="ion-margin-bottom">
        <ion-row>
          <ion-col>
            <div class="card card-plain mt-2">
              <div class="card-body">
                <ion-row>
                  <ion-col>
                    <h1 class="mb-2">{{ $t('olkypay.labels.transferPage.types_transfers') }}</h1>
                    <ion-row>
                      <ion-col size="12">
                        <div
                          class="card border-1"
                          @click="openSubTab('OneTimeTransferSubTabRouter')"
                        >
                          <div class="card-body p-2">
                            <ion-row class="mt-2">
                              <ion-col size="2">
                                <div class="icon border-radius-md">
                                  <ion-icon
                                    :icon="calendar"
                                    color="blue_olky"
                                    size="large"
                                  ></ion-icon>
                                </div>
                              </ion-col>
                              <ion-col>
                                <h4 class="font-weight-normal p-0 m-0 mt-1">
                                  <span class="small">{{
                                    $t('olkypay.labels.transferPage.one_time_tab.title')
                                  }}</span>
                                </h4>
                              </ion-col>
                            </ion-row>
                          </div>
                        </div>
                      </ion-col>
                      <ion-col size="12">
                        <div
                          class="card border-1"
                          @click="openSubTab('RecurrentTransferSubTabRouter')"
                        >
                          <div class="card-body p-2">
                            <ion-row class="mt-2">
                              <ion-col size="2">
                                <div class="icon border-radius-md">
                                  <ion-icon
                                    :icon="infinite"
                                    color="blue_olky"
                                    size="large"
                                  ></ion-icon>
                                </div>
                              </ion-col>
                              <ion-col>
                                <h4 class="font-weight-normal p-0 m-0 mt-1">
                                  <span class="small">{{
                                    $t('olkypay.labels.transferPage.recurrent')
                                  }}</span>
                                </h4>
                              </ion-col>
                            </ion-row>
                          </div>
                        </div>
                      </ion-col>
                    </ion-row>
                  </ion-col>
                </ion-row>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div class="card card-plain">
              <div class="card-body">
                <ion-row>
                  <ion-col>
                    <h1 class="mb-2">{{ $t('olkypay.labels.transferPage.related_services') }}</h1>
                    <ion-row>
                      <ion-col size="12">
                        <div class="card border-1" @click="openSubTab('FollowUpSubTabRouter')">
                          <div class="card-body p-2">
                            <ion-row class="mt-2">
                              <ion-col size="2">
                                <div class="icon border-radius-md">
                                  <ion-icon
                                    :icon="logoEuro"
                                    color="blue_olky"
                                    size="large"
                                  ></ion-icon>
                                </div>
                              </ion-col>
                              <ion-col>
                                <h4 class="font-weight-normal p-0 m-0 mt-1">
                                  <span class="small">{{
                                    $t('olkypay.labels.transferPage.follow_up')
                                  }}</span>
                                </h4>
                              </ion-col>
                            </ion-row>
                          </div>
                        </div>
                      </ion-col>
                      <ion-col size="12">
                        <div class="card border-1" @click="openSubTab('BeneficiariesSubTabRouter')">
                          <div class="card-body p-2">
                            <ion-row class="mt-2">
                              <ion-col size="2">
                                <div class="icon border-radius-md">
                                  <ion-icon
                                    :icon="people"
                                    color="blue_olky"
                                    size="large"
                                  ></ion-icon>
                                </div>
                              </ion-col>
                              <ion-col>
                                <h4 class="font-weight-normal p-0 m-0 mt-1">
                                  <span class="small">{{
                                    $t('olkypay.labels.transferPage.beneficiaries')
                                  }}</span>
                                </h4>
                              </ion-col>
                            </ion-row>
                          </div>
                        </div>
                      </ion-col>
                      <ion-col size="12">
                        <div
                          class="card border-1"
                          @click="openSubTab('NewBeneficiarySubTabRouter')"
                        >
                          <div class="card-body p-2">
                            <ion-row class="mt-2">
                              <ion-col size="2">
                                <div class="icon border-radius-md">
                                  <ion-icon
                                    :icon="personAdd"
                                    color="blue_olky"
                                    size="large"
                                  ></ion-icon>
                                </div>
                              </ion-col>
                              <ion-col>
                                <h4 class="font-weight-normal p-0 m-0 mt-1">
                                  <span class="small">{{
                                    $t('olkypay.labels.transferPage.add_beneficiary')
                                  }}</span>
                                </h4>
                              </ion-col>
                            </ion-row>
                          </div>
                        </div>
                      </ion-col>
                    </ion-row>
                  </ion-col>
                </ion-row>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>
<script lang="ts">
import HeaderAppWithLabel from '../../components/headers/HeaderAppWithLabel.vue'
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonCol,
  IonRow,
  IonGrid,
  IonIcon,
  IonRouterOutlet
} from '@ionic/vue'
import { calendar, infinite, logoEuro, personAdd, people } from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TransferPage',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonCol,
    IonRow,
    IonGrid,
    IonIcon,
    IonRouterOutlet,
    HeaderAppWithLabel
  },
  data() {
    return {
      calendar,
      infinite,
      logoEuro,
      personAdd,
      people
    }
  },
  methods: {
    ...mapActions('beneficiary', ['setCurrentBeneficiary']),
    async openSubTab(router_name) {
      if (router_name === 'NewBeneficiarySubTabRouter') {
        await this.setCurrentBeneficiary({})
      }
      this.$router.push({ name: router_name })
    }
  },
  async mounted() {},
  computed: {
    ...mapGetters('beneficiary', ['beneficiaries'])
  }
}
</script>
