import config from '@/config'

export const IFrameKYCAuthorizations =
  'camera ' +
  config.API_VID_ALGOREG +
  '; microphone ' +
  config.API_VID_ALGOREG +
  '; geolocation ' +
  config.API_VID_ALGOREG +
  ';'
