<template>
  <v-container id="dashboardPage">
    <v-row>
      <!-- Welcome BOX -->
      <v-col cols="12" sm="12" md="8">
        <DashboardWelcomeBox></DashboardWelcomeBox>
      </v-col>

      <!-- OlkyPass KYC Box -->
      <v-col cols="12" sm="12" md="4">
        <DashboardOlkyPassBox></DashboardOlkyPassBox>
      </v-col>

      <!-- OlkyWallet Box -->
      <!-- <v-col cols="12" sm="12" md="4">
        <DashboardOlkyWalletDataBox></DashboardOlkyWalletDataBox>
      </v-col> -->

      <!-- KPay data Box -->
      <v-col cols="12" sm="12" md="4">
        <DashboardKPayDataBox></DashboardKPayDataBox>
      </v-col>

      <!-- Kycoin Box -->
      <v-col cols="12" sm="12" md="4">
        <DashboardKycoinDataBox></DashboardKycoinDataBox>
      </v-col>

      <!-- Support Box -->
      <v-col cols="12" sm="12" md="4">
        <DashboardSupportBox></DashboardSupportBox>
      </v-col>

      <!-- Video Box -->
      <v-col cols="12" sm="12" md="4">
        <DashboardVideoBox></DashboardVideoBox>
      </v-col>
      <!-- OlkyPay Subscription Box -->
      <!-- Disabled until OlkyPass is fully ready <v-col cols="12" sm="12" md="4" v-if="kycState.level === 100 && kycState.decision !== 'pending' && !naturalPerson.ibanOlkyPay">
        <DashboardOlkyPaySubscriptionBox></DashboardOlkyPaySubscriptionBox>
      </v-col>-->
      <!-- OlkyPay data Box -->
      <!-- <v-col cols="12" sm="12" md="4" v-if="naturalPerson.ibanOlkyPay">
        <DashboardOlkyPayDataBox></DashboardOlkyPayDataBox>
      </v-col>-->
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from '../store'
import ToolsMixins from '../mixins/ToolsMixins.js'
import DashboardWelcomeBox from '../components/DashboardBoxes/WelcomeBox.vue'
import DashboardVideoBox from '../components/DashboardBoxes/VideoBox.vue'
import DashboardSupportBox from '../components/DashboardBoxes/SupportBox.vue'
import DashboardKPayDataBox from '../components/DashboardBoxes/KPayDataBox.vue'
import DashboardKycoinDataBox from '../components/DashboardBoxes/KycoinDataBox.vue'
import DashboardOlkyPayDataBox from '../components/DashboardBoxes/OlkyPayDataBox.vue'
import DashboardOlkyPaySubscriptionBox from '../components/DashboardBoxes/OlkyPaySubscriptionBox.vue'
import DashboardOlkyWalletDataBox from '../components/DashboardBoxes/OlkyWalletDataBox.vue'
import DashboardOlkyPassBox from '../components/DashboardBoxes/OlkyPassBox.vue'

export default {
  name: 'DashboardPage',
  components: {
    DashboardWelcomeBox,
    DashboardVideoBox,
    DashboardSupportBox,
    DashboardKPayDataBox,
    DashboardOlkyPayDataBox,
    DashboardOlkyPaySubscriptionBox,
    DashboardOlkyWalletDataBox,
    DashboardKycoinDataBox,
    DashboardOlkyPassBox
  },
  mixins: [ToolsMixins],
  async mounted() {
    await this.checkKypayInit(this.kypay)
    await this.checkKycoinInit(this.kycoin)
  },
  computed: {
    ...mapGetters(['kycState', 'naturalPerson', 'kycoin', 'kypay'])
  }
}
</script>

<style scoped></style>
