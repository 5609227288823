import { NaturalPerson } from '../user/types'
import { DateWithTimezone } from '../utils/types'

export enum KYCSessionScenario {
  KYC = 'olkypass_kyc',
  ManualKYC = 'olkypass_manual_kyc',
  Document = 'olkypass_document',
  Question = 'olkypass_any_question_1'
}

export enum KYCSessionType {
  Document = 'DOCUMENT',
  Info = 'INFO',
  Question = 'QUESTION'
}

export type KYCSession = {
  sessionId: string
  sessionScoring: boolean
  statusType: 'UNFINISHED'
  sessionType: KYCSessionType
  AbstractEntity: string // NaturalPerson UUID
}

export type KYCSessionURLParams = {
  iframe_callback: boolean
  show_consent: boolean
  show_home: boolean
  show_final: boolean
  use_speech: boolean
  
}

export type KYCSessionURLParam = keyof KYCSessionURLParams

export type CreateKYCSession = PrepareKYCSessionData

export type PrepareKYCSessionData = {
  UUID: string // NaturalPerson UUID
  language: string
  sessionType?: KYCSessionType
  sessionSenario?: KYCSessionScenario
  naturalPerson: NaturalPerson
}

export type PrepareKYCSessionResponse = {
  session_id: string
  customer_reference: string
  start_date: string
}

export type CreateKYCSessionData = {
  sessionId: string
  AbstractEntity: string // NaturalPerson UUID
  UUID: string // NaturalPerson UUID
  sessionType?: KYCSessionType
  sessionSenario?: KYCSessionScenario
  naturalPerson: NaturalPerson
}

export type UpdateKYCSessionData = {
  sessionId: string
  document: CreateKYCDocument
  AbstractEntity: string // NaturalPerson UUID
}

// export type UpdateKYCSessionState = {
//   sessionId: string
//   document: KYCDocument
//   AbstractEntity: string
//   sessionScoring:
// }

export type KYCDocument = {
  UUID: string
  TypePiece: number
  Number: string
  Validity: boolean | null
  OwnerUUID: string
  NaturalPerson: NaturalPerson | null
  algoregDocumentId: string
  typePerLanguage: {
    fr: string
    en: string
  }
  selfUpload: boolean
  Document: null
  uuid: string
}

export type KYCDocumentType = {
  id: number
  type_fr: string
  type_en: string
  need_other_name: boolean
  need_manual_validation: boolean
}

export type CreateKYCDocument = {
  sessionId: string
  document: {
    pieceType: KYCDocumentType
    number: string
  }
  AbstractEntity: string // User UUID
  sessionType: KYCSessionType
  sessionSenario?: KYCSessionScenario
}

export type EditKYCSession = {
  sessionId: string
  document: {
    pieceType: KYCDocumentType
    number: string
  }
  AbstractEntity: string // User UUID
  sessionType: KYCSessionType
  sessionSenario?: KYCSessionScenario
}

export type KYCScenario = {
  sessionId: string
  sessionScoring: boolean
  statusType: KYCStatusType
  sessionType: KYCSessionType
  AbstractEntity: string // User UUID
}

export enum KYCStatusType {
  LOADING = 'LOADING', // Not yet response from server
  DECLARATIVE = 'DECLARATIVE', // Declarative
  VERIFIED = 'VERIFIED', // Verified
  AUTHENTICATED = 'AUTHENTICATED',
  AUTHENTICATED_BRONZE = 'AUTHENTICATED_BRONZE',
  AUTHENTICATED_SILVER ='AUTHENTICATED_SILVER',
  AUTHENTICATED_GOLD='AUTHENTICATED_GOLD',
  NEUTRAL ='NEUTRAL'
}
export type Olkypass_status = {
    id: number,
    uuid: string,
    name: string,
    english_name: string,
    level: number,
}

export type KYCSessionStatus = {
  level: number
  active: boolean
  algoreg_kyc_status: null | 'POSITIVE' | 'NEUTRAL' | 'NEGATIVE' | 'PENDING'
  activeUntil: DateWithTimezone
  createdAt: DateWithTimezone
  updatedAt: DateWithTimezone
  olkypass_status : Olkypass_status
  olkypass_status_under_audit: Olkypass_status
  missingData: any[] // Replace any with a more specific type if possible
  missingDocuments: any[] // Replace any with a more specific type if possible
  identityDocuments: any[] // Replace any with a more specific type if possible
  ValidForPayIn: boolean
  ValidForPayout: boolean
  uuid: string
  id: number
}
