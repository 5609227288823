import i18n, { TFunction } from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'
import { setLanguage as setLanguageVue } from './i18n'

export const setLanguage = (lang: string) => setLanguageVue(lang)

/**
 * For now, vue is controlling the language, not react
 */
await i18n
  .use(
    resourcesToBackend(
      (language: string, namespace: string) => import(`../../locales/${language}/${namespace}.json`)
    )
  )
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    load: 'languageOnly',
    fallbackLng: ['en', 'fr'],
    ns: ['pass'],
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    saveMissing: true,
    // for missing key handler to fire
    missingKeyHandler(lng, ns, key, fallbackValue) {
      console.error('Translation error - Missing Key', lng, ns, key, fallbackValue)
    },
    // LanguageDetector options
    detection: {
      // order and from where user language should be detected
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain'
      ],
      lookupCookie: 'language',
      lookupLocalStorage: 'language',
      lookupSessionStorage: 'language',
      lookupQuerystring: 'language',
      // cache user language on
      caches: ['localStorage', 'cookie'],
      cookieMinutes: 60
    }
  })

export const useAsyncTranslation = (namespaces: string[]) =>
  useTranslation(namespaces, { useSuspense: false })

export const asyncT = (t: TFunction<[string], undefined>, id: string) => {
  const value = t(id)
  if (value === id) return ''
  return value
}

export default i18n
