<script setup lang="ts">
import './styles/app.scss'
import './styles/olky-ui.scss'
import { onMounted, computed } from 'vue'

import ToolsMixins from './vue/mixins/ToolsMixins.js'
import Loader from '@/app/olkypass/views/_deprecated/vue/components/Loader.vue'

// Do this only once for all the app
onMounted(async () => {
  await ToolsMixins.$store.dispatch('setAccessibleApplications')
  await ToolsMixins.$store.dispatch('loadStaticData')
  await ToolsMixins.$store.dispatch('setIsAuthenticatedUser')
  await ToolsMixins.$store.dispatch('setCurrentNaturalPerson')
})

const ready = computed(() => ToolsMixins.$store.state.user.user.isAuthenticated)
</script>

<template>
  <div class="tracking-normal">
    <div class="v-overlay-container">
      <div v-if="ready">
        <slot />
      </div>
    </div>
    <Loader></Loader>
  </div>
</template>

<style scoped>
ion-content::part(background) {
  --background: transparent;
}
</style>
