import { A } from '@/atoms-react/a/A.react'
import { Button } from '@/atoms-react/button/Button.react'
import Image from '@/atoms-react/image/Image.react'
import { Modal } from '@/atoms-react/modal/index.react'
import Tag from '@/atoms-react/tag/Tag.react'
import { Base, SectionTitle } from '@/atoms-react/text/Text.react'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import { KYCStatusType } from '@/features/kyc/types'
import { useKypay } from '@/features/kypay/hooks/use-kypay'
import { Routes } from '@/features/router'
import { useUser } from '@/features/user/hooks/use-user'
import { formatNumber } from '@/features/utils/functions'
import { ModalKYCAlert } from '@/molecules/modal-kyc-alert/index.react'
import { Themes } from '@/types/theme'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getRoute } from '../../utils/routes'
import { LayoutTitle } from '../_layout/index.react'
import { BalanceCardKycoin } from '../kypay/components/balance-card/balance-card-kycoin.react'
import { BalanceCardKypay } from '../kypay/components/balance-card/balance-card-kypay.react'
import { TransactionsList } from '../kypay/components/transaction-list/transaction-list.react'
import { OlkypassCard } from './components/olkypass-cards/index.react'
import { DiscoverOlkyCard } from './components/card-discover-olky/index.react'
import { useLocale } from '@/features/utils/hooks/use-locale.ts'
import { useKycoin } from '@/features/kycoin/hooks/use-kycoin.ts'
import { OlkywalletCard } from './components/olkywallet-cards/index.react'
import { ForcedKYCIdentityModal } from '../_layout/forced-kyc-identity-modal.react'

const iframeStyles = {
  width: '50%',
  height: '50%'
}

const mobileStyles = {
  '@media (max-width: 768px)': {
    width: '100%',
    height: '100%'
  }
}

export const DashboardApp = () => {
  const { t } = useTranslation(['pass'])
  const navigate = useNavigate()
  const { kypayQuery } = useKypay()
  const { data: kypay, isFetching: isKypayLoading, refetch: refetchKypay } = kypayQuery()
  const { getKycoin } = useKycoin()
  const { data: kycoin } = getKycoin()
  const [showVideo, setShowVideo] = useState<boolean>(false)
  const { id } = useCompanyContext()
  const { status ,nextStatus,auditPending } = useKYCContext()
  const { useUserProfile } = useUser()
  const { data: user } = useUserProfile()
  const [modalKYCAlert, setModalKYCAlert] = useState<boolean>()
  const { locale } = useLocale()
  const isFrench = (locale.language ?? '').toLocaleUpperCase() === 'FR'
  const toggleVideoModal = () => setShowVideo(!showVideo)
  const olkyCardsClassName =
    'relative overflow-hidden w-full rounded-sm  h-[170px] lg:h-[185px] xl:h-[195px]  2xl:h-[200px]'
  return (
    <>
        <ModalKYCAlert
      open={modalKYCAlert}
      onClose={() => setModalKYCAlert(false)}
      onValidated={() => {
        setModalKYCAlert(false)
        navigate(Routes.account_identity)
      }}
    />
    <ForcedKYCIdentityModal />

    <LayoutTitle
      action={
        <Button
          size="md"
          theme={Themes.white}
          onClick={() => navigate(getRoute(Routes.support))}
          icon={QuestionMarkCircleIcon}
          iconPosition="before"
          className="hidden md:block"
        >
          {t('business.button.contact_support')}
        </Button>
      }
    >
      <div className="flex items-center gap-x-2">
        {t('business.label.main_title')}
        {!!id && <Tag>Pro</Tag>}
      </div>
    </LayoutTitle>

    <div className="flex flex-col  lg:flex-row w-full  gap-y-2 md:gap-x-6 mb-9 lg:mb-10">
      <OlkypassCard
        currentStatus={status}
        dashboard={true}
        nextStatus={nextStatus}
        auditPending ={auditPending}
        CTA={
          status === KYCStatusType.DECLARATIVE && user?.naturalPerson.DateOfBirth !== null
            ? false
            : true
        }
        verifyButton
        />
                {<DiscoverOlkyCard />}

        <div className=" w-full xl:w-4/12	">
          {!user?.naturalPerson?.olkyAccount?.iban ? (
            <div
              className={`${olkyCardsClassName} bg-[#100629] hover:bg-opacity-85 cursor-pointer z-50`}
              onClick={toggleVideoModal}
            >
              <div
                className={`h-full px-4 py-4 md:px-4 md:py-4 lg:px-5 xl:py-6 bg-[url('/images/olkypass/bg_discovery.png')] bg-contain`}
              >
                <Base className="text-white font-semibold text-left mt-2">
                  {t('olkypass.mainPage.olkypass.bloc3.cardTitle')}
                </Base>
                <div className="flex flex-col items-center h-full items-center justify-center">
                  <div className="w-10 h-10">
                    <Image src="/images/olkypass/play_icon.svg" alt="Play icon" />
                  </div>
                  <div className=' w-full'>
                  <Button
                    onClick={() => {
                      setShowVideo(true)
                    }}
                    size="md"
                    theme={Themes.declarative}
                    className="border-none  mb-6 truncate items-left w-96    lg:w-64 xl:w-96 lg:mb-5  text-orange-500 mt-4 bg-white dark:bg-white text-black dark:text-black   h-8 sm:h-10 text-xs  sm:text-base lg:h-8  lg:text-sm xl:text-base   xl:h-10 xl:text-lg"
                  >
                    <span className="flex space-x-2 truncate items-center justify-center z-10 overflow-hidden whitespace-nowrap text-ellipsis text-sm sm:text-base">
                      <span className=" truncate">
                      {t('olkypass.mainPage.olkypass.bloc3.desc')}
                      </span>
                    </span>
                  </Button>
                  </div>
                </div>
                <Modal
                  open={showVideo}
                  onClose={() => setShowVideo(false)}
                  style={{
                    maxWidth: '90vw',
                    maxHeight: '80vh',
                    height: 'calc(90vw* 0.580)'
                  }}
                >
                  {showVideo && (
                    <iframe
                      className="overflow-hidden rounded-md"
                      title="Olky Presentation"
                      src="https://www.youtube.com/embed/bR5-ajVO29I?autoplay=1"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{
                        ...iframeStyles,
                        ...mobileStyles['@media (max-width: 768px)']
                      }}
                    ></iframe>
                  )}
                </Modal>
              </div>
            </div>
          ):         <OlkywalletCard /> 
}
         
        </div>
      </div>
      <div className="flex justify-between items-end">
        <SectionTitle>KYPAY</SectionTitle>
        <A to={Routes.kypay}>{t('olkypass.general.see_all')}</A>
      </div>

      <div className="flex w-full space-x-4 mt-4">
        <div className="flex flex-col justify-between w-full md:w-1/3 space-y-4">
          <BalanceCardKypay
            balanceType="euros"
            className="grow"
            withWalletStatus={false}
            isPrincipalAccount={true}
            kypay={kypay}
            refetchKypay={refetchKypay}
            isKypayLoading={isKypayLoading}
          />
          <BalanceCardKypay
            balanceType="onChain"
            className="grow"
            toolitp={true}
            withWalletStatus={false}
            isPrincipalAccount={true}
            kypay={kypay}
            refetchKypay={refetchKypay}
            isKypayLoading={isKypayLoading}
          />
        </div>
        <div className="w-full border-2 border-slate-50 dark:border-slate-700 rounded-sm pb-1 overflow-hidden hidden md:block">
          <Base className="text-slate-300 px-5 pt-4 pb-4">
            {t('business.label.latest_transactions')}
          </Base>
          <div className="-mx-1">
            <TransactionsList
              transactions={
                (kypay?.transaction ?? []).map((trans) => ({
                  title: isFrench ? trans.Description : trans.EnglishDescription,
                  date: trans.Date,
                  amount: trans.NumberInt / 100,
                  type: trans.Type,
                  Counterparty: trans.Counterparty,
                  account_id: trans.account_id
                })) ?? []
              }
              numberOfTransactions={3}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between items-end">
        <SectionTitle className="mt-9 md:mt-10">KYCOIN</SectionTitle>
        <A to={Routes.kycoin}>{t('olkypass.general.see_all')}</A>
      </div>
      <div className="flex w-full space-x-4 mt-4 mb-16">
        <div className="md:flex flex-col justify-between w-full md:w-1/3 space-y-4">
          <>
            <BalanceCardKycoin
              amount={formatNumber(kycoin?.balance ?? 0, 0)}
              amountInEuro={formatNumber(kycoin?.balanceEuro ?? 0)}
              balanceType={'onChain'}
            />
          </>
        </div>
        <div className="w-full border-2 border-slate-50 dark:border-slate-700 rounded-sm pb-1 overflow-hidden hidden md:block">
          <Base className="text-slate-300 px-5 pt-4 pb-4">
            {t('business.label.latest_transactions')}
          </Base>
          <div className="-mx-1">
            <TransactionsList
              fracDigits={0}
              transactions={
                kycoin?.transaction.map((trans) => ({
                  title: isFrench ? trans.Description : trans.EnglishDescription,
                  amount: trans.NumberInt,
                  type: trans.Type,
                  date: trans.Date,
                  Counterparty: trans.Counterparty,
                  account_id: trans.account_id
                })) ?? []
              }
              numberOfTransactions={3}
              listType="kycoin"
            />
          </div>
        </div>
      </div>
    </>
  )
}
