import Text from '@/atoms-react/text/Text.react.tsx'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import _ from 'lodash'

import React, { RefObject, forwardRef, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import FormattedInputDate from './FormattedInputDate.react'
import { useNativeInputs } from '@/features/utils/is-mobile'

type InputProps = {
  label?: string
  borderless?: boolean
  value?: string
  placeholder?: string
  inputComponent?: React.ReactNode
  inputClassName?: string
  className?: string
  maxLength?: number
  isPhoneNumber?: boolean
  format?: 'iban' | 'date' | 'phone' | 'creditCard' | ((value: string) => string)
  disabled?: boolean
  onChange?: (value: string) => void
  suffixIcon?: () => React.ReactNode
  onClickSuffixIcon?: () => void
  rows?: number
  error?: string
  bgClass?: string
  onMouseDown?: () => void
  helper?: string
} & Omit<Omit<React.ComponentPropsWithoutRef<'input'>, 'value'>, 'onChange'>

const inputFormatsMap = {
  iban: (value: string) =>
    value
      .replace(/\D/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim(),
  date: (value: string) =>
    value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{2})(\d{4})/, '$1 / $2 / $3')
      .trim(),
  creditCard: (value: string) =>
    value
      .replace(/\D/g, '')
      .replace(/(\d{4})/g, '$1 ')
      .trim()
}

export const defaultInputClassName = `bg-slate-50 dark:bg-slate-800 hover:bg-slate-100 hover:border-slate-100 w-full font-semibold rounded-sm px-4 py-3 border-2 duration-300 border-slate-50 dark:border-slate-800 text-black dark:text-white dark:hover:bg-slate-900 dark:hover:border-slate-800 focus:bg-white dark:focus:bg-slate-900 focus:border-blue-500 dark:focus:border-blue-500 placeholder:font-semibold placeholder:text-slate-400 dark:placeholder:text-slate-600`

const InputLabel = forwardRef<HTMLInputElement, InputProps>(function (
  {
    name,
    label,
    borderless,
    value,
    placeholder,
    inputComponent,
    className,
    maxLength,
    format,
    disabled,
    isPhoneNumber,
    error,
    onChange,
    suffixIcon,
    onClickSuffixIcon,
    rows,
    bgClass,
    onMouseDown,
    onClick,
    helper,
    ...props
  }: InputProps,
  ref
) {
  const { t } = useTranslation(['atoms'])
  const [showPassword, setShowPassword] = useState(false)
  const borderlessClass = borderless ? 'border-0 focus:bg-slate-50 dark:focus:bg-slate-900' : ''

  const inputClassName = twMerge(
    defaultInputClassName,
    borderlessClass,
    bgClass
      ? 'bg-slate-50 hover:bg-slate-50 hover:border-slate-50'
      : 'hover:bg-slate-100 hover:border-slate-100',
    disabled ? 'pointer-events-none pointer-events-none opacity-60 text-opacity-70' : '',
    className
  )

  function formatValue(value: string) {
    let formattedValue = value

    if (_.isFunction(format)) {
      formattedValue = format(value)
    } else if (_.isString(format) && (inputFormatsMap as any)[format]) {
      formattedValue = (inputFormatsMap as any)[format](value)
    }
    return formattedValue
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (isChangeEvent(event)) {
      const { value: targetValue } = event.target
      if (onChange) onChange(targetValue ?? '')
    } else {
      onChange && onChange(event)
    }
  }
  const handleMouseDown = (event: React.MouseEvent<HTMLInputElement> | string) =>
    onMouseDown && onMouseDown(event)
  const handleClick = (event: React.MouseEvent<HTMLInputElement> | string) =>
    onClick && onClick(event)

  function isChangeEvent(
    input: React.ChangeEvent<HTMLInputElement> | string
  ): input is React.ChangeEvent<HTMLInputElement> {
    return (input as React.ChangeEvent<HTMLInputElement>).target !== undefined
  }

  const inputType = props.type === 'password' && showPassword ? 'text' : props.type ?? 'text'

  const [missingRequired, setMissingRequired] = useState(false)

  const [errorMessage, setErrorMessage] = useState(false)
  const setRequired = () => setMissingRequired(true)

  const checkRequired = useCallback(() => {
    if (props.required && !value) setMissingRequired(true)
  }, [props.required, value])

  useEffect(() => {
    // Listen for window message that check all required fields
    window.addEventListener('checkRequiredFields', checkRequired)
    if (name) {
      window.addEventListener('setRequired-' + name, setRequired)
    }
    return () => {
      window.removeEventListener('checkRequiredFields', checkRequired)
      if (name) {
        window.removeEventListener('setRequired-' + name, setRequired)
      }
    }
  }, [])
  useEffect(() => {
    if (error) setErrorMessage(true)
    else {
      setErrorMessage(false)
    }
  }, [error])

  const input = (
    <>
      {inputComponent || (
        <InputWidget
          type={inputType}
          rows={rows}
          value={value ?? ''}
          isPhoneNumber={isPhoneNumber}
          placeholder={placeholder}
          onChange={handleChange}
          onMouseDown={handleMouseDown}
          onClick={handleClick}
          formatValue={formatValue}
          onFocus={() => setMissingRequired(false)}
          onBlur={() => {
            if (props.required && !value) {
              setMissingRequired(true)
            }
          }}
          required={true}
          maxLength={maxLength}
          /* @ts-expect-error Vuetify */
          ref={ref}
          className={twMerge(
            inputClassName,
            (missingRequired || errorMessage) && 'border-red-500 dark:border-red-500 '
          )}
          {..._.omit(props, 'type')}
        />
      )}
      <SuffixIcon
        dataTooltip={missingRequired ? t('general.required') : undefined}
        className={missingRequired || errorMessage ? 'text-red-500 dark:text-red-500' : ''}
        suffixIcon={
          missingRequired || errorMessage
            ? ExclamationTriangleIcon
            : props.type === 'password'
              ? showPassword
                ? EyeSlashIcon
                : EyeIcon
              : suffixIcon
        }
        onClickSuffixIcon={
          onClickSuffixIcon ? onClickSuffixIcon : () => setShowPassword(!showPassword)
        }
      />
    </>
  )

  return (
    <>
      <div className={twMerge('relative', className || 'w-full')}>
        {!!label && (
          <label>
            <Text
              type="label"
              className={twMerge('text-left whitespace-nowrap', disabled ? 'opacity-40' : '')}
            >
              {label}
              {/*{props.required === false ? ' (' + t('general.optional') + ')' : '*'}*/}
              {props.required === false ? '' : ' *'}
            </Text>
            {!!helper && (
              <Text type="info" className={'text-sky-500'}>
                {helper}
              </Text>
            )}
          </label>
        )}
        {!!label && <div className={'mt-2 relative'}>{input}</div>}
        {!label && input}
      </div>
    </>
  )
})

export type InputWidgetProps = {
  type: HTMLInputElement['type']
  placeholder?: string
  required?: boolean
  maxLength?: number
  formatValue: (str: string) => string
  value: string
  rows?: number
  className?: string
  isPhoneNumber?: boolean
}

export const InputWidget = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputWidgetProps>(
  (
    {
      type,
      placeholder,
      required = false,
      maxLength,
      formatValue,
      value,
      rows,
      className,
      isPhoneNumber,
      ...props
    },
    ref
  ) => {
    const isMobile = useNativeInputs()
    const inputClassName = twMerge(
      maxLength && `w-[${maxLength + 0.5}rem]`,
      !maxLength && 'w-full',
      (maxLength ?? 0) > 10 && 'w-full'
    )

    if (rows) {
      const textareaClass = 'w-full'
      return (
        <textarea
          placeholder={placeholder}
          className={twMerge(inputClassName, textareaClass, className)}
          value={formatValue(value || '')}
          rows={rows}
          maxLength={maxLength}
          required={required}
          ref={ref as RefObject<HTMLTextAreaElement>}
          {..._.omit(props, 'type')}
        />
      )
    }
    if (type === 'date' && !isMobile) {
      return (
        <FormattedInputDate
          placeholder={placeholder}
          className={twMerge(inputClassName, className)}
          value={formatValue(value || '')}
          maxLength={maxLength}
          required={required}
          {..._.omit(props, 'type')}
        />
      )
    }
    return (
      <input
        type={type}
        placeholder={placeholder}
        className={twMerge(inputClassName, className)}
        value={formatValue(value || '')}
        maxLength={maxLength}
        required={required}
        inputMode={isPhoneNumber ? 'tel' : 'text'}
        ref={ref as RefObject<HTMLInputElement>}
        {..._.omit(props, 'type')}
      />
    )
  }
)

export type SuffixIconProps = {
  suffixIcon?: React.ReactNode | any
  onClickSuffixIcon?: () => void
  className?: string
  dataTooltip?: string
}

export const SuffixIcon = ({
  suffixIcon: SuffixIcon,
  onClickSuffixIcon,
  className,
  dataTooltip
}: SuffixIconProps) => {
  const iconStyle = 'h-6 w-6 text-blue-500 dark:text-white ' + className

  return (
    <>
      {SuffixIcon && (
        <button
          data-tooltip={dataTooltip}
          onClick={onClickSuffixIcon}
          className="h-6 w-6 focus:outline-none absolute top-1/2 transform -translate-y-1/2 right-4"
        >
          <SuffixIcon className={iconStyle} />
        </button>
      )}
    </>
  )
}

export default InputLabel
