import { CountryCode } from './types'

export const FRENCH_CODE = 'fr-FR'
export const FRENCH_CODE_SHORT = 'FR'
export const US_CODE = 'en-US'
export const OLKY_CGU_LINK = 'https://www.olky.eu/files/pass/cgu-olkypass-en.pdf'

export const CSPCodesNoActivity = [
  {
    Code: 15,
    Nom: 'Étudiant',
    NomEn: 'Student',
    Risk: 1,
    uuid: '72aedec2-3b7a-432c-82ec-b8151df7b6c4',
    id: 13
  },
  {
    Code: 13,
    Nom: 'Militaire',
    NomEn: 'Military',
    Risk: 1,
    uuid: 'b0941615-6d25-4f86-bc82-8fa1bcf126bb',
    id: 15
  },
  {
    Code: 18,
    Nom: 'Inactif',
    NomEn: 'None',
    Risk: 1,
    uuid: '0cf20168-ceaf-4d1d-b12a-80ecb9bed050',
    id: 14
  },
  {
    Code: 14,
    Nom: 'Chômeurs',
    NomEn: 'Unemployed',
    Risk: 1,
    uuid: 'd65dc018-8572-4115-8b44-e7b762eec318',
    id: 16
  }
]

export const PHONE_COUNTRY_CODES = {
  AD: '+376',
  AE: '+971',
  AF: '+93',
  AG: '+1-268',
  AI: '+1-264',
  AL: '+355',
  AM: '+374',
  AO: '+244',
  AQ: '+672',
  AR: '+54',
  AS: '+1-684',
  AT: '+43',
  AU: '+61',
  AW: '+297',
  AX: '+358-18',
  AZ: '+994',
  BA: '+387',
  BB: '+1-246',
  BD: '+880',
  BE: '+32',
  BF: '+226',
  BG: '+359',
  BH: '+973',
  BI: '+257',
  BJ: '+229',
  BL: '+590',
  BM: '+1-441',
  BN: '+673',
  BO: '+591',
  BQ: '+599',
  BR: '+55',
  BS: '+1-242',
  BT: '+975',
  BV: '+47',
  BW: '+267',
  BY: '+375',
  BZ: '+501',
  CA: '+1',
  CC: '+61',
  CD: '+243',
  CF: '+236',
  CG: '+242',
  CH: '+41',
  CI: '+225',
  CK: '+682',
  CL: '+56',
  CM: '+237',
  CN: '+86',
  CO: '+57',
  CR: '+506',
  CU: '+53',
  CV: '+238',
  CW: '+599',
  CX: '+61',
  CY: '+357',
  CZ: '+420',
  DE: '+49',
  DJ: '+253',
  DK: '+45',
  DM: '+1-767',
  DO: '+1-809, +1-829, +1-849',
  DZ: '+213',
  EC: '+593',
  EE: '+372',
  EG: '+20',
  EH: '+212',
  ER: '+291',
  ES: '+34',
  ET: '+251',
  EU: '+',
  FI: '+358',
  FJ: '+679',
  FK: '+500',
  FM: '+691',
  FO: '+298',
  FR: '+33',
  GA: '+241',
  GB: '+44',
  GD: '+1-473',
  GE: '+995',
  GF: '+594',
  GG: '+44-1481',
  GH: '+233',
  GI: '+350',
  GL: '+299',
  GM: '+220',
  GN: '+224',
  GP: '+590',
  GQ: '+240',
  GR: '+30',
  GS: '+500',
  GT: '+502',
  GU: '+1-671',
  GW: '+245',
  GY: '+592',
  HK: '+852',
  HM: '+',
  HN: '+504',
  HR: '+385',
  HT: '+509',
  HU: '+36',
  ID: '+62',
  IE: '+353',
  IL: '+972',
  IM: '+44-1624',
  IN: '+91',
  IO: '+246',
  IQ: '+964',
  IR: '+98',
  IS: '+354',
  IT: '+39',
  JE: '+44-1534',
  JM: '+1-876',
  JO: '+962',
  JP: '+81',
  KE: '+254',
  KG: '+996',
  KH: '+855',
  KI: '+686',
  KM: '+269',
  KN: '+1-869',
  KP: '+850',
  KR: '+82',
  KW: '+965',
  KY: '+1-345',
  KZ: '+7',
  LA: '+856',
  LB: '+961',
  LC: '+1-758',
  LI: '+423',
  LK: '+94',
  LR: '+231',
  LS: '+266',
  LT: '+370',
  LU: '+352',
  LV: '+371',
  LY: '+218',
  MA: '+212',
  MC: '+377',
  MD: '+373',
  ME: '+382',
  MF: '+590',
  MG: '+261',
  MH: '+692',
  MK: '+389',
  ML: '+223',
  MM: '+95',
  MN: '+976',
  MO: '+853',
  MP: '+1-670',
  MQ: '+596',
  MR: '+222',
  MS: '+1-664',
  MT: '+356',
  MU: '+230',
  MV: '+960',
  MW: '+265',
  MX: '+52',
  MY: '+60',
  MZ: '+258',
  NA: '+264',
  NC: '+687',
  NE: '+227',
  NF: '+672',
  NG: '+234',
  NI: '+505',
  NL: '+31',
  NO: '+47',
  NP: '+977',
  NR: '+674',
  NU: '+683',
  NZ: '+64',
  OM: '+968',
  PA: '+507',
  PE: '+51',
  PF: '+689',
  PG: '+675',
  PH: '+63',
  PK: '+92',
  PL: '+48',
  PM: '+508',
  PN: '+870',
  PR: '+1-787, +1-939',
  PS: '+970',
  PT: '+351',
  PW: '+680',
  PY: '+595',
  QA: '+974',
  RE: '+262',
  RO: '+40',
  RS: '+381',
  RU: '+7',
  RW: '+250',
  SA: '+966',
  SB: '+677',
  SC: '+248',
  SD: '+249',
  SE: '+46',
  SG: '+65',
  SH: '+290',
  SI: '+386',
  SJ: '+47',
  SK: '+421',
  SL: '+232',
  SM: '+378',
  SN: '+221',
  SO: '+252',
  SR: '+597',
  SS: '+211',
  ST: '+239',
  SV: '+503',
  SX: '+1-721',
  SY: '+963',
  SZ: '+268',
  TC: '+1-649',
  TD: '+235',
  TF: '+',
  TG: '+228',
  TH: '+66',
  TJ: '+992',
  TK: '+690',
  TL: '+670',
  TM: '+993',
  TN: '+216',
  TO: '+676',
  TR: '+90',
  TT: '+1-868',
  TV: '+688',
  TW: '+886',
  TZ: '+255',
  UA: '+380',
  UG: '+256',
  UM: '+1',
  US: '+1',
  UY: '+598',
  UZ: '+998',
  VA: '+379',
  VC: '+1-784',
  VE: '+58',
  VG: '+1-284',
  VI: '+1-340',
  VN: '+84',
  VU: '+678',
  WF: '+681',
  WS: '+685',
  YE: '+967',
  YT: '+262',
  ZA: '+27',
  ZM: '+260',
  ZW: '+263'
}

export type PhoneCountryCode = keyof typeof PHONE_COUNTRY_CODES

export const COUNTRY_CODES: CountryCode[] = Object.values(CountryCode)

export const LANGUAGE_CODES = {
  AD: 'ca',
  AE: 'ar',
  AF: 'fa',
  AG: 'en',
  AI: 'en',
  AL: 'sq',
  AM: 'hy',
  AO: 'pt',
  AQ: '',
  AR: 'es',
  AS: 'en',
  AT: 'de',
  AU: 'en',
  AW: 'nl',
  AX: 'sv',
  AZ: 'az',
  BA: 'bs',
  BB: 'en',
  BD: 'bn',
  BE: 'nl',
  BF: 'fr',
  BG: 'bg',
  BH: 'ar',
  BI: 'rn',
  BJ: 'fr',
  BL: 'fr',
  BM: 'en',
  BN: 'ms',
  BO: 'es',
  BQ: 'nl',
  BR: 'pt-BR',
  BS: 'en',
  BT: 'dz',
  BV: '',
  BW: 'en',
  BY: 'be',
  BZ: 'en',
  CA: 'en-CA',
  CC: 'ms',
  CD: 'fr',
  CF: 'fr',
  CG: 'fr',
  CH: 'de',
  CI: 'fr',
  CK: 'en',
  CL: 'es',
  CM: 'fr',
  CN: 'zh-CN',
  CO: 'es',
  CR: 'es',
  CU: 'es',
  CV: 'pt',
  CW: 'nl',
  CX: 'en',
  CY: 'el',
  CZ: 'cs',
  DE: 'de',
  DJ: 'fr',
  DK: 'da',
  DM: 'en',
  DO: 'es',
  DZ: 'ar',
  EC: 'es',
  EE: 'et',
  EG: 'ar',
  EH: 'ar',
  ER: 'ti',
  ES: 'es',
  ET: 'am',
  EU: '',
  FI: 'fi',
  FJ: 'en',
  FK: 'en',
  FM: 'en',
  FO: 'fo',
  FR: 'fr',
  GA: 'fr',
  GB: 'en-GB',
  GD: 'en',
  GE: 'ka',
  GF: 'fr',
  GG: 'en',
  GH: 'en',
  GI: 'en',
  GL: 'kl',
  GM: 'en',
  GN: 'fr',
  GP: 'fr',
  GQ: 'es',
  GR: 'el',
  GS: 'en',
  GT: 'es',
  GU: 'en',
  GW: 'pt',
  GY: 'en',
  HK: 'zh-HK',
  HM: '',
  HN: 'es',
  HR: 'hr',
  HT: 'ht',
  HU: 'hu',
  ID: 'id',
  IE: 'ga',
  IL: 'he',
  IM: 'en',
  IN: 'hi',
  IO: 'en',
  IQ: 'ar',
  IR: 'fa',
  IS: 'is',
  IT: 'it',
  JE: 'en',
  JM: 'en',
  JO: 'ar',
  JP: 'ja',
  KE: 'sw',
  KG: 'ky',
  KH: 'km',
  KI: 'en',
  KM: 'ar',
  KN: 'en',
  KP: 'ko',
  KR: 'ko',
  KW: 'ar',
  KY: 'en',
  KZ: 'kk',
  LA: 'lo',
  LB: 'ar',
  LC: 'en',
  LI: 'de',
  LK: 'si',
  LR: 'en',
  LS: 'st',
  LT: 'lt',
  LU: 'lb',
  LV: 'lv',
  LY: 'ar',
  MA: 'ar',
  MC: 'fr',
  MD: 'ro',
  ME: 'sr',
  MF: 'fr',
  MG: 'mg',
  MH: 'mh',
  MK: 'mk',
  ML: 'fr',
  MM: 'my',
  MN: 'mn',
  MO: 'zh-MO',
  MP: 'en',
  MQ: 'fr',
  MR: 'ar',
  MS: 'en',
  MT: 'mt',
  MU: 'fr',
  MV: 'dv',
  MW: 'ny',
  MX: 'es-MX',
  MY: 'ms',
  MZ: 'pt',
  NA: 'en',
  NC: 'fr',
  NE: 'fr',
  NF: 'en',
  NG: 'en',
  NI: 'es',
  NL: 'nl',
  NO: 'no',
  NP: 'ne',
  NR: 'na',
  NU: 'niu',
  NZ: 'en',
  OM: 'ar',
  PA: 'es',
  PE: 'es',
  PF: 'fr',
  PG: 'en',
  PH: 'fil',
  PK: 'ur',
  PL: 'pl',
  PM: 'fr',
  PN: 'en',
  PR: 'es',
  PS: 'ar',
  PT: 'pt-PT',
  PW: 'pau',
  PY: 'es',
  QA: 'ar',
  RE: 'fr',
  RO: 'ro',
  RS: 'sr',
  RU: 'ru',
  RW: 'rw',
  SA: 'ar',
  SB: 'en',
  SC: 'fr',
  SD: 'ar',
  SE: 'sv',
  SG: 'zh-SG',
  SH: 'en',
  SI: 'sl',
  SJ: 'no',
  SK: 'sk',
  SL: 'en',
  SM: 'it',
  SN: 'fr',
  SO: 'so',
  SR: 'nl',
  SS: 'en',
  ST: 'pt',
  SV: 'es',
  SX: 'nl',
  SY: 'ar',
  SZ: 'en',
  TC: 'en',
  TD: 'fr',
  TF: 'fr',
  TG: 'fr',
  TH: 'th',
  TJ: 'tg',
  TK: 'tkl',
  TL: 'pt',
  TM: 'tk',
  TN: 'ar',
  TO: 'to',
  TR: 'tr',
  TT: 'en',
  TV: 'tvl',
  TW: 'zh-TW',
  TZ: 'sw',
  UA: 'uk',
  UG: 'sw',
  UM: '',
  US: 'en-US',
  UY: 'es',
  UZ: 'uz',
  VA: 'la',
  VC: 'en',
  VE: 'es',
  VG: 'en',
  VI: 'en',
  VN: 'vi',
  VU: 'bi',
  WF: 'fr',
  WS: 'sm',
  YE: 'ar',
  ZA: 'zu',
  ZM: 'en',
  ZW: 'sn'
}

export type LanguageCode = keyof typeof PHONE_COUNTRY_CODES

export const FAVOURITE_COUNTRY_CODES = ['fr', 'lu', 'es', 'be', 'pl', 'it']

export const CREATE_IBAN_LINK_COMPANY =
  'https://iban-express.olky.eu/configurateur?_locale=fr&user_chapeau_session_id=vr51um4a9t32ah7cdhmh17ofhp'

export const CREATE_IBAN_LINK_USER = 'https://olky.eu'
