import Loader from '@/atoms-react/loader/Loader.react'
import { useGateways } from '@/features/flow/hooks/use-gateways'
import { GatewayVote } from '@/features/flow/types'
import { KYCSessionScenario, KYCSessionType } from '@/features/kyc/types'
import React, { useEffect, useState } from 'react'
import { KYCSessionIFrame } from '../../../../../../molecules/kyc-session-iframe/index.react'
import { DocumentTypeSelector } from './document-type-selector/index.react'

export enum DocumentsGatewayAction {
  AddDocument = 'AddDocument',
  KYCDocument = 'KYCDocument'
}

export type DocumentsGatewayProps = {
  onValidated: () => void
  action: DocumentsGatewayAction
} & React.ComponentPropsWithoutRef<'div'>

export const DocumentsGateway = ({
  onValidated,
  action = DocumentsGatewayAction.AddDocument
}: DocumentsGatewayProps) => {
  const [step, setStep] = useState<string>('document-type-selector')

  const { useDocumentsGatewayVoter } = useGateways()
  const vote = useDocumentsGatewayVoter()

  useEffect(() => {
    if (vote === GatewayVote.Continue) {
      onValidated()
    }
  }, [vote])

  const getSessionType = (): KYCSessionType => {
    if (action === DocumentsGatewayAction.AddDocument) return KYCSessionType.Document
    if (action === DocumentsGatewayAction.KYCDocument) return KYCSessionType.Info

    return KYCSessionType.Document
  }

  const getSessionScenario = (): KYCSessionScenario => {
    if (action === DocumentsGatewayAction.AddDocument) return KYCSessionScenario.Document
    if (action === DocumentsGatewayAction.KYCDocument) return KYCSessionScenario.KYC

    return KYCSessionScenario.Document
  }

  if (vote === GatewayVote.Pending) return <Loader />

  return <>
    {step === 'document-type-selector' && <DocumentTypeSelector onValidated={() => setStep('kyc-session-iframe')} />}
    {step === 'kyc-session-iframe' && <KYCSessionIFrame
        sessionType={getSessionType()}
        sessionScenario={getSessionScenario()}
        onValidated={() => onValidated()}
      />}
  </>
}
