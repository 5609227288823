import { Base, Info } from '@/atoms-react/text/Text.react'
import { EmptyPageCactusAnimation } from '@/components/animations/empty-cactus/index.react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { formatNumber } from '@/features/utils/functions'
import Image from '@/atoms-react/image/Image.react'
import { Link } from 'react-router-dom'
import { Routes } from '@/features/router'
import { getRoute } from '@/app/olkypass/utils/routes'

export type RepartitionListProps = {
  data: any
  config?: any
}

export const RepartitionsList = ({ data, config }: RepartitionListProps) => {
  const { t } = useTranslation(['wallet'])

  let list = data?.calculateRealPrice
  if (config?.pagination && config?.pagination > 0 && data?.calculateRealPrice) {
    list = data.calculateRealPrice.slice(0, config?.pagination)
  }

  return (
    <div className={'flex flex-col space-y-2 md:space-y-0 w-full h-max'}>
      {list?.length === 0 && (
        <>
          <div className="w-full flex items-center flex-col space-y-4 my-16">
            <EmptyPageCactusAnimation />
            <Info>{t('wallet.dashboard.info.no_repartition')}</Info>
          </div>
        </>
      )}
      {list?.length === 0 && (
        <>
          <div className="w-full flex items-center flex-col space-y-4 my-16">
            <Info>{t('wallet.dashboard.info.not_found')}</Info>
          </div>
        </>
      )}
      {list?.map((itemList, indexList) => (
        <div
          className={twMerge(
            'flex justify-between items-center px-3 py-1 md:px-8 md:py-2 border-2 border-slate-50 dark:border-slate-700 md:border-0 rounded-sm md:rounded-none space-x-2',
            indexList % 2 === 0 && 'md:bg-blue-50 md:dark:bg-slate-600'
          )}
          key={indexList}
        >
          <div className={'md:w-1/5 md:py-4 whitespace-nowrap shrink-0'}>
            <Base className={'w-full font-semibold text-right'}>{itemList.balance}</Base>
          </div>
          <div
            className="flex items-center md:w-2/5 px-4 space-x-4"
            style={{ maxWidth: 'calc(100% - 118px)' }}
          >
            {itemList?.image && (
              <Image
                src={itemList?.image}
                alt={itemList?.crypto_currency?.name}
                className={'w-8 h-8 inline-block'}
              />
            )}
            <div className="hidden md:block" title={itemList?.crypto_currency?.name}>
              <Link
                to={getRoute(Routes.wallet_crypto_details, {
                  cryptoKey: itemList?.crypto_currency?.identifier
                })}
              >
                <Base className="md:text-slate-500">
                  {itemList?.crypto_currency?.name} (
                  {itemList?.crypto_currency?.symbol.toUpperCase()}){' '}
                </Base>
              </Link>
            </div>
          </div>
          <div className={'md:w-1/5 md:py-4 whitespace-nowrap shrink-0'}>
            <Base className={twMerge('w-full font-semibold text-right', config?.amount?.className)}>
              {formatNumber(itemList?.currentAmount)} {config?.amount?.unit}
            </Base>
          </div>
          <div className="hidden md:block md:w-2/5 md:py-4"></div>
        </div>
      ))}
    </div>
  )
}
