import React, { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import _ from 'lodash'
import { twMerge } from 'tailwind-merge'
import { DECODING, FETCHPRIORITY, LOADING } from './types'

interface ImageProps extends React.ComponentPropsWithoutRef<'figure'> {
  alt: string
  placeholder?: string
  skeletonSrc?: string
  skeleton?: ImageSkeleton
  decoding?: DECODING
  fetchpriority?: FETCHPRIORITY
  loading?: LOADING
  onError?: () => any
  onLoad?: () => any
  sources?: React.ComponentPropsWithoutRef<'source'>[]
  src: React.ComponentPropsWithoutRef<'img'>['src']
  components?: {
    image: {
      attributes: React.ComponentPropsWithoutRef<'img'>
    }
  } | null
}

type ImageSkeleton = {
  width?: string
  height?: string
  src?: string
  className?: React.ComponentPropsWithoutRef<'div'>['style']
} & React.ComponentPropsWithoutRef<'div'>

const Image = ({
  alt,
  decoding = DECODING.AUTO,
  fetchpriority = FETCHPRIORITY.AUTO,
  loading = LOADING.EAGER,
  onError = () => {},
  onLoad = () => {},
  placeholder,
  skeleton,
  sources = [],
  components = null,
  src,
  ...props
}: ImageProps) => {
  const imageRef = useRef<HTMLImageElement>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    setIsLoading(!imageRef?.current?.complete)
    setError(false)
  }, [src, setIsLoading, setError])

  const handleLoad = () => {
    const loadCompleted = imageRef?.current?.complete
    if (loadCompleted === true) {
      setIsLoading(!loadCompleted)
      if (onLoad) onLoad()
    }
  }

  const handleError = () => {
    setIsLoading(false)
    setError(true)
    onError && onError()
  }

  const figureStyles = {
    backgroundImage: `url(${placeholder || skeleton?.src})`
  }

  return <figure
    className={classNames(
      'bg-center bg-no-repeat m-0',
      placeholder && 'bg-cover',
      placeholder && loading && 'backdrop-blur-lg',
      placeholder && !loading && 'backdrop-blur-none transition',
      skeleton?.src && 'bg-contain'
    )}
    style={!error && (placeholder || skeleton?.src) ? figureStyles : {}}
    {...props}
  >
    <picture>
      {sources.map((source, idx) => <source key={idx} {...source} />)}
      {isLoading && skeleton && <div
          className={twMerge('w-full h-full bg-slate-500 rounded-md', skeleton.className)}
          style={{ height: skeleton.height ?? undefined, width: skeleton.width ?? undefined }}
        ></div>}
      <img
        alt={alt}
        src={src}
        className={twMerge(
          classNames(
            'block w-full h-full object-contain',
            isLoading && 'opacity-0',
            !isLoading && 'opacity-100'
          ),
          components?.image?.attributes?.className
        )}
        decoding={decoding}
        // @ts-expect-error Not supported by TS yet
        fetchpriority={fetchpriority}
        loading={loading}
        onError={handleError}
        onLoad={handleLoad}
        ref={imageRef}
        {..._.omit(
          components?.image?.attributes ? components?.image?.attributes : [],
          'className'
        )}
        color="red"
      />
    </picture>
  </figure>
}

export default Image

export { DECODING, FETCHPRIORITY, LOADING }
