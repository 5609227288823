import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import { useEffect, useState } from 'react'
import DeclarativeIdentityPerson, {
  isDeclarativePersonValid
} from './person/DeclarativeIdentityPerson.react'
import { DeclarativeIdentity } from '@/features/flow/types'
import DeclarativeIdentityBirth, {
  isDeclarativeBirthValid
} from './birth/DeclarativeIdentityBirth.react'
import DeclarativeIdentityResidence, {
  isDeclarativeResidenceValid
} from './residence/DeclarativeIdentityResidence.react'
import DeclarativeIdentityOccupation, {
  isDeclarativeOccupationValid
} from './occupation/DeclarativeIdentityOccupation.react'
import { useNavigate, useParams } from 'react-router-dom'
import { getRoute } from '@/app/olkypass/utils/routes'
import { Routes } from '@/features/router'
import { animatedNext } from '../../../layout/AnimatedForm.react'
import DeclarativeIdentityMonthlyIncome, {
  isDeclarativeMonthlyIncomeValid
} from './monthly-income/DeclarativeIdentityMonthlyIncome.react.tsx'
import { NaturalPerson } from '@/features/user/types.ts'

interface DeclarativeIdentityRootProps extends LayoutBaseProps {
  input: DeclarativeIdentityInput
  onValidated?: (data: DeclarativeIdentityOutput) => void
}

export type DeclarativeIdentityInput = {
  email: string
  phone: string
}
export type DeclarativeIdentityOutput = DeclarativeIdentity

export type DeclarativeIdentityUIState = {
  sawWelcomeMessage: boolean
}

function DeclarativeIdentityRoot({ onValidated = () => {} }: DeclarativeIdentityRootProps) {
  const [profile, setProfile] = useState<NaturalPerson>(null)
  const [missingKeys, setMissingKeys] = useState(null)
  const { block, session_uuid, step, substep } = useParams()
  const navigate = useNavigate()

  const [declarativeIdentity, setDeclarativeIdentity] = useState<DeclarativeIdentity>()

  const prioritySections = ['person', 'birth', 'residence', 'activity', 'monthly_income'] // Define the priority order
  useEffect(() => {
    const storedValue = sessionStorage.getItem('NewOlkyPayUser')
    if (storedValue) {
      const jsonItems = JSON.parse(storedValue)
      const accountProfile = jsonItems.profile.naturalPerson
      const missingFields = jsonItems.missingKeys
      setProfile(accountProfile)
      setMissingKeys(missingFields)
      if (!substep) {
        let mySubStep = substep
        for (const section of Object.keys(missingFields)) {
          const sectionName = section.split('.').pop()
          if (prioritySections.includes(sectionName)) {
            mySubStep = sectionName
            break
          }
        }

        navigate(
          getRoute(Routes.flow_substep, {
            session_uuid,
            block,
            step,
            substep: mySubStep
          }),
          {
            replace: true
          }
        )
      }
    } else if (!session_uuid || !substep || !declarativeIdentity?.welcome) {
      // Redirect to welcome if invalid block or just reloaded the page now
      setDeclarativeIdentity((identity) => ({ ...identity, welcome: true }))
      navigate(getRoute(Routes.flow_substep, { session_uuid, block, step, substep: 'person' }), {
        replace: true
      })
    }
  }, [])

  const isDeclarativeIdentityCompleted =
    isDeclarativeResidenceValid(declarativeIdentity?.addressResidence) &&
    isDeclarativeBirthValid(declarativeIdentity?.addressBirth) &&
    isDeclarativePersonValid(declarativeIdentity?.person) &&
    isDeclarativeOccupationValid(declarativeIdentity?.occupation) &&
    isDeclarativeMonthlyIncomeValid(declarativeIdentity?.monthlyIncome)

  const validationFunctions = {
    person: isDeclarativePersonValid,
    birth: isDeclarativeBirthValid,
    residence: isDeclarativeResidenceValid,
    activity: isDeclarativeOccupationValid,
    monthly_income: isDeclarativeMonthlyIncomeValid
  }
  const equivalentFields = {
    person: 'person',
    birth: 'addressBirth',
    residence: 'addressResidence',
    activity: 'occupation',
    monthly_income: 'monthlyIncome'
  }

  useEffect(() => {
    if (profile && Object.keys(missingKeys).length > 0) {
      let isDeclarativeIdentityAuxCompleted = true
      for (const section of Object.keys(missingKeys)) {
        const sectionName = section.split('.').pop()
        const equivalentKey = equivalentFields[sectionName]
        const value = declarativeIdentity[equivalentKey] ?? null
        const validationFunction = validationFunctions[sectionName]
        // Validate the section dynamically
        if (validationFunction) {
          const isValid = validationFunction(value)
          if (!isValid) {
            isDeclarativeIdentityAuxCompleted = false
          }
        }
      }
      if (isDeclarativeIdentityAuxCompleted && declarativeIdentity) {
        onValidated(declarativeIdentity)
      }
    } else if (isDeclarativeIdentityCompleted && declarativeIdentity) {
      onValidated(declarativeIdentity)
    }
  }, [declarativeIdentity, isDeclarativeIdentityCompleted])

  const stepMap = {
    person: 'birth',
    birth: 'residence',
    residence: 'activity',
    activity: 'monthly_income',
    monthly_income: 'complete',
    complete: null // End of the steps
  }
  const calculateNextSubStep = (mySubStep: string) => {
    const nextStep = stepMap[mySubStep]
    if (nextStep === 'complete') {
      return nextStep
    }
    if (profile && Object.keys(missingKeys).length > 0) {
      if (!Object.prototype.hasOwnProperty.call(missingKeys, `identity.${nextStep}`)) {
        return calculateNextSubStep(nextStep)
      }
    }
    return nextStep
  }

  return (
    <>
      {substep === 'person' && (
        <DeclarativeIdentityPerson
          profile={profile}
          onValidated={(data) =>
            animatedNext('forward', async () => {
              setDeclarativeIdentity((identity) => ({ ...identity, welcome: true, person: data }))
              navigate(
                getRoute(Routes.flow_substep, {
                  session_uuid,
                  block,
                  step,
                  substep: calculateNextSubStep('person')
                })
              )
            })
          }
        />
      )}
      {substep === 'birth' && (
        <DeclarativeIdentityBirth
          personNationality={declarativeIdentity?.person?.nationalityCodes as string[]}
          profile={profile}
          onValidated={(data) =>
            animatedNext('forward', async () => {
              setDeclarativeIdentity((identity) => ({ ...identity, addressBirth: data }))
              navigate(
                getRoute(Routes.flow_substep, {
                  session_uuid,
                  block,
                  step,
                  substep: calculateNextSubStep('birth')
                })
              )
            })
          }
        />
      )}
      {substep === 'residence' && (
        <DeclarativeIdentityResidence
          onValidated={(data) =>
            animatedNext('forward', async () => {
              setDeclarativeIdentity((identity) => ({ ...identity, addressResidence: data }))
              navigate(
                getRoute(Routes.flow_substep, {
                  session_uuid,
                  block,
                  step,
                  substep: calculateNextSubStep('residence')
                })
              )
            })
          }
        />
      )}
      {substep === 'activity' && (
        <DeclarativeIdentityOccupation
          onValidated={(data) =>
            animatedNext('forward', async () => {
              setDeclarativeIdentity((identity) => ({ ...identity, occupation: data }))
              navigate(
                getRoute(Routes.flow_substep, {
                  session_uuid,
                  block,
                  step,
                  substep: calculateNextSubStep('activity')
                })
              )
            })
          }
        />
      )}

      {substep === 'monthly_income' && (
        <DeclarativeIdentityMonthlyIncome
          onValidated={(data) =>
            animatedNext('forward', async () => {
              setDeclarativeIdentity((identity) => ({ ...identity, monthlyIncome: data }))
              navigate(
                getRoute(Routes.flow_substep, {
                  session_uuid,
                  block,
                  step,
                  substep: calculateNextSubStep('monthly_income')
                })
              )
            })
          }
        />
      )}
    </>
  )
}

export default DeclarativeIdentityRoot
