import { useEffect, useState } from 'react'

export const Shield = ({
  verified,
  intermediate
}: {
  verified: boolean
  className?: string
  intermediate: boolean
}) => {
  const [src, setSrc] = useState<string>('images/olkypass/white.svg')

  useEffect(() => {
    if (verified)
      setSrc(intermediate ? 'images/olkypass/orange_shield.svg' : 'images/olkypass/green.svg')
  }, [])
  return (
    <div className={` flex justify-center	 items-center  ${!verified ? 'pt-1' : '	'}`}>
      <img src={src} alt="" />
    </div>
  )
}
