import {
  autoUpdate,
  flip,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole
} from '@floating-ui/react'
import { useRef, useState } from 'react'

export type UseDropdownProps = {
  disabled?: boolean
}

export const useDropdown = ({ disabled = false }: UseDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  const { refs, floatingStyles, context } = useFloating<HTMLElement>({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    transform: false,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`
          })
        },
        padding: 10
      })
    ]
  })

  const listRef = useRef<Array<HTMLElement | null>>([])
  const click = useClick(context, { event: 'mousedown', enabled: !disabled })
  const dismiss = useDismiss(context, { enabled: !disabled })
  const role = useRole(context, { role: 'listbox', enabled: !disabled })

  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    loop: true,
    enabled: !disabled,
    focusItemOnHover: false
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    dismiss,
    role,
    listNav,
    click
  ])

  const handleSelect = (index: number) => {
    setSelectedIndex(index)
    setIsOpen(false)
  }

  return {
    isOpen,
    setIsOpen,
    activeIndex,
    setActiveIndex,
    selectedIndex,
    setSelectedIndex,
    context,
    handleSelect,
    reference: {
      ref: refs.setReference,
      domRef: refs.domReference,
      getProps: getReferenceProps
    },
    floating: {
      ref: refs.setFloating,
      getProps: getFloatingProps,
      styles: floatingStyles
    },
    options: {
      getProps: getItemProps,
      refs: listRef
    }
  }
}
