import classNames from 'classnames'
import _ from 'lodash'
import React, { HTMLProps } from 'react'

export enum PREDEFINED_SIZE_MAP {
  small = 16,
  medium = 24,
  large = 32,
  xLarge = 52,
  xxLarge = 72
}

type IconProps = React.PropsWithChildren<{
  path?: string
  component?: React.ReactNode
  size?: PREDEFINED_SIZE_MAP | number
  className?: HTMLProps<HTMLElement>['className']
  style?: HTMLProps<HTMLElement>['style']
  props?: Omit<Omit<HTMLProps<HTMLElement>, 'className'>, 'style'>
}>

function Icon({
  path,
  component: Component,
  size = PREDEFINED_SIZE_MAP.small,
  className,
  ...props
}: IconProps) {
  if (path && Component)
    throw new Error('Icon atom - You cannot define path and component prop at the same time')

  const classes = 'inline-block'

  return <>
    {path && <img
        src={path}
        className={classNames(classes, className)}
        style={{
          width: size,
          ...(props.style || {})
        }}
        {..._.omit(props, 'className', 'style')}
      />}
    {Component && <>
        {/* @ts-expect-error Testttttttttttt **/}
        <Component
          className={classNames(classes, className)}
          style={{
            width: size,
            ...(props.style || {})
          }}
          {..._.omit(props, 'className', 'style')}
        />
      </>}
  </>
}

export default Icon
