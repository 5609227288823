<template>
  <ion-page>
    <ion-content id="cardsPage" :fullscreen="true" class="tracking-normal">
      <div class="header-app height-178 absolute" @click="gotoRouter('SuppliersRouter')">
        <ion-grid>
          <ion-row class="mt-4">
            <ion-col size="1" class="mt-2">
              <div class="circleIconMenu">
                <ion-icon
                  :icon="menu"
                  color="light"
                  size="large"
                  @click="gotoRouter('SuppliersRouter')"
                ></ion-icon>
              </div>
            </ion-col>
            <ion-col class="ion-margin-horizontal mt-1">
              <h1>{{ $t('olkypay.labels.cardsPage.header') }}</h1>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <ion-grid>
        <ion-row>
          <ion-col class="mt-6">
            <div class="item-on-header-app">
              <swiper
                :effect="'coverflow'"
                :grab-cursor="true"
                :centered-slides="true"
                :slides-per-view="'auto'"
                :coverflow-effect="{
                  rotate: 5,
                  stretch: -10,
                  depth: 100,
                  modifier: 5,
                  slideShadows: true
                }"
                :pagination="false"
                :modules="modules"
                :initial-slide="1"
                @slideChange="onSlideChange"
                @swiper="onSwiper"
              >
                <swiper-slide v-for="card in supplierCards">
                  <img :src="businessCard" />
                  <h5 class="text-center text-gray">{{ card.fullName }}</h5>
                </swiper-slide>
              </swiper>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <card-manager></card-manager>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>
<script lang="ts">
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules'
import businessCard from '../../../../../public/images/olkypay/cards/business-card.png'
import particularCard from '../../../../../public/images/olkypay/cards/particular-card.png'
import HeaderTabs from '../../components/HeaderTabs.vue'
import CardManager from '../../components/cards/cardManager.vue'
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonListHeader,
  IonProgressBar,
  IonList,
  IonButton
} from '@ionic/vue'
import { mapActions, mapGetters } from 'vuex'
import { menu } from 'ionicons/icons'
export default {
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonLabel,
    IonListHeader,
    IonProgressBar,
    IonList,
    IonButton,
    HeaderTabs,
    Swiper,
    SwiperSlide,
    CardManager
  },
  data() {
    return {
      menu,
      modules: [EffectCoverflow, Pagination],
      businessCard,
      particularCard
    }
  },
  methods: {
    ...mapActions('card', ['setCurrentCard']),
    onSlideChange(swiper) {
      const index = swiper.activeIndex
      const card = this.supplierCards[index]
      this.setCurrentCard(card)
    },
    onSwiper(swiper) {
      const index = swiper.activeIndex
      const card = this.supplierCards[index]
      this.setCurrentCard(card)
    },
    gotoRouter(router_name) {
      this.$router.push({ name: router_name })
    }
  },
  mounted() {
    if (this.supplierCards.length) {
      const index = this.supplierCards.length > 1 ? 1 : 0
      const card = this.supplierCards[index]
      this.setCurrentCard(card)
    } else {
      this.setCurrentCard({})
    }
  },
  computed: {
    ...mapGetters('card', ['supplierCards'])
  }
}
</script>
<style></style>
