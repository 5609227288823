import { LoginView } from '@/app/login/views/login/LoginView'
import { TwoFaView } from '@/app/login/views/login/TwoFaView'
import { ForgetPasswordView } from '@/app/login/views/login/ForgetPasswordView'
import { ResetPasswordView } from '@/app/login/views/login/ResetPasswordView'
import { ValidationCodeView } from '@/app/login/views/login/ValidationCodeView'
import { SignupView } from '@/app/login/views/signup/SignupView'
import { FormCompleteCredentialView } from '@/app/login/views/signup/components/FormCompleteCredentialView'

const prefix = '/sign-in'

export const LoginRoutes = {
  signup: prefix + '/create-account',
  login: prefix,
  two_fa: prefix + '/two-fa',
  forget_password: prefix + '/forget-password',
  reset_password: prefix + '/reset-password',
  validation_code: prefix + '/validation-code',
  complete_credential: prefix + '/complete-credential'
}

export const LoginRouterChildren = [
  {
    path: LoginRoutes.login,
    component: LoginView,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: LoginRoutes.two_fa,
    component: TwoFaView,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: LoginRoutes.forget_password,
    component: ForgetPasswordView,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: LoginRoutes.reset_password,
    component: ResetPasswordView,
    props: (route: any) => ({
      id: route.query.id,
      token: route.query.token,
      language: route.query.language
    }),
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: LoginRoutes.signup,
    component: SignupView,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: LoginRoutes.validation_code,
    component: ValidationCodeView,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: LoginRoutes.complete_credential,
    component: FormCompleteCredentialView,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  }
]
