import Image from '@/atoms-react/image/Image.react'
import { Title } from '@/atoms-react/text/Text.react'
import { LanguageSelector } from '@/components/lang-select/index.react'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { getRoutesWithoutPadding as getRoutesWithoutSpacing } from '@/features/flow/constants'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { AnimatedForm } from './AnimatedForm.react'
import { OlkyIntroduction } from './OlkyIntroduction.react'
import { FromURL } from '@/features/flow/types'
import { useOrigin } from '@/features/flow/hooks/use-origin'

type LayoutProps = React.ComponentPropsWithoutRef<'div'> &
  React.PropsWithChildren<{
    overlayMode?: boolean
    whiteLabel?: boolean
  }>

export const Layout = ({ children, whiteLabel = false }: LayoutProps) => {
  const { cached } = useAuth()
  const { session_uuid, block } = useParams()
  const location = useLocation()

  const routesWithoutSpacing = getRoutesWithoutSpacing(session_uuid ?? '')
  const isRouteWithoutSpacing = routesWithoutSpacing.includes(location.pathname + location.search)

  const newUser = !cached?.data?.email && block === 'welcome'

  whiteLabel = whiteLabel || !!new URLSearchParams(location.search).get('whitelabel')
  const overlayMode = whiteLabel
  const origin = useOrigin()

  const getImageURL = () => {
    switch (origin) {
      case FromURL.OlkyPayNP:
        return '/images/olkypass/olkyPayConso.png'
      case FromURL.OlkyPayLE:
        return '/images/olkypass/olkyPayPro.png'
      case FromURL.Wallet:
        return '/images/olkypass/kywallet-bg.png'
      default:
        return '/images/olkypass/olky-space-background.jpg'
    }
  }

  const getLogo = () => {
    switch (origin) {
      case FromURL.OlkyPayNP:
        return '/images/olkypass/olkypay-logo.svg'
      case FromURL.OlkyPayLE:
        return '/images/olkypass/olkypay-logo.svg'
      case FromURL.Wallet:
        return '/images/olkypass/olkywallet-logo.svg'
      default:
        return '/images/olkypass/logo-olky-pass-white.svg'
    }
  }

  return <div
    className={twMerge(
      'relative grow w-full flex max-h-dvh min-h-full overflow-hidden flex-col lg:flex-row bg-slate-50 p-0 items-center justify-center',
      whiteLabel ? '' : 'bg-white dark:bg-slate-950'
    )}
  >
    {!whiteLabel && <>
        <div
          className={twMerge(
            overlayMode ? 'w-full' : 'w-full lg:w-1/2 max-h-96 lg:max-h-full',
            'h-full bg-black absolute top-0 bottom-0 left-0 right-auto z-0 transition-all bg-cover bg-center bg-by-origin-app'
          )}
          style={{
            backgroundImage: 'url(' + getImageURL() + ')'
          }}
        />
        {(origin === FromURL.OlkyPayLE ||
          origin === FromURL.OlkyPayNP ||
          origin === FromURL.Wallet) && <div className="absolute top-0 left-0 lg:w-1/2 h-full bg-black opacity-35 z-10" />}
        <div
          className={twMerge(
            'grow hidden lg:flex w-full max-h-dvh lg:flex-col flex-row max-h-96 z-10 relative items-center justify-center transition-all',
            !newUser && 'max-h-0 lg:max-h-full'
          )}
        >
          <div className="px-8 lg:p-8 w-full text-center lg:text-left">
            <OlkyIntroduction mode={newUser ? 'long' : 'short'} />
          </div>
        </div>
      </>}

    <div
      className={twMerge(
        'z-10 justify-center flex grow overflow-auto',
        overlayMode ? 'lg:h-auto' : 'grow w-full bg-white dark:bg-slate-950 '
      )}
    >
      <div className="hidden lg:block absolute right-8 top-8 z-10">
        <LanguageSelector size="sm" />
      </div>
      <div
        className={twMerge(
          'overflow-auto grow w-full flex flex-col lg:flex-row lg:items-center',
          overlayMode ? 'lg:max-w-xl' : ''
        )}
      >
        {/* OlkyPass image for mobile screens*/}
        <div
          className={`shrink-0 relative bg-center bg-cover bg-no-repeat min-h-[100px] w-full lg:hidden`}
          style={{
            backgroundImage: `url(${getImageURL()})`
          }}
        >
          <div className="w-full h-full bg-black bg-opacity-40">
            <div>
              <div className="flex items-center justify-between w-full p-6">
                <Image src={getLogo()} className="w-24" alt="Olky Logo" />
                <LanguageSelector size="sm" />
              </div>
              {newUser && <Title className="text-white text-center block mb-6">
                  Votre passeport identité
                </Title>}
            </div>
          </div>
        </div>
        <AnimatedForm
          className={twMerge(
            'bg-white dark:bg-slate-950 lg:rounded-lg w-full lg:max-w-xl mx-auto p-6 lg:p-16',
            isRouteWithoutSpacing && 'p-0 lg:p-0 m-0 lg:m-0 max-h-full w-full min-w-full'
          )}
        >
          {children}
          <div className="mt-8" />
        </AnimatedForm>
      </div>
    </div>
  </div>
}
