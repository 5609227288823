import { Button } from '@/atoms/buttons/Button'
import ButtonConfirm from '@/atoms/buttons/ButtonConfirm.vue'
import { Checkbox } from '@/atoms/inputs/Checkbox'
import { Input } from '@/atoms/inputs/Input'
import { LabelDecoration } from '@/atoms/inputs/decorations/LabelDecoration'
import { useCollaborator } from '@/features/company/hooks/use-collaborator'
import { useCompany } from '@/features/company/hooks/use-company'
import type { CollaboratorType } from '@/features/company/types'
import { defineJsxComponent } from '@/jsx-utils'
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/vue/20/solid'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
//ArrowUpIcon
export const CollaboratorItem = defineJsxComponent<any>(props => {
  const t = useI18n().t
  const collaborator = props.collaborator as CollaboratorType
  const displayBoxDetail = ref(false)
  const readOnlyField = ref(true)
  const displayButtonSave = ref(false)
  const { companyStore, disassociateCollaborator } = useCompany()
  const { collaboratorStore, loadCollaborators, updateCollaborator } = useCollaborator()
  const route = useRoute()
  const identity_uuid = route.params.identity_uuid
  const loading = ref(false)
  const canUpdateCompany = ref(companyStore.currentCompanyPermission?.update_company_data)
  return () => <li className="py-3 sm:py-4">
    <div className="flex items-center">
      <div className="grow min-w-0 ms-4">
        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
          {collaborator.naturalPerson.first_name} {collaborator.naturalPerson.last_name}
        </p>
        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
          {collaborator.naturalPerson.identity_uuid}
        </p>
      </div>
      <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
        <Button
          theme="outlined"
          icon={!displayBoxDetail.value ? ChevronDownIcon : ChevronUpIcon}
          class="my-4 float-right"
          onClick={(e: any) => {
            e.preventDefault()
            displayBoxDetail.value = !displayBoxDetail.value
          }}
        ></Button>
      </div>
    </div>
    {displayBoxDetail.value && <div className="flex items-center">
        <div className="grow min-w-0 ms-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).status}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>{t('olkypass.companyPage.collaborators_section._switch.Status')}</>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).beneficial_owner}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>{t('olkypass.companyPage.collaborators_section._switch.UBO')}</>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).direct_holding}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>
                      {t(
                        'olkypass.companyPage.collaborators_section._switch.DirectOwnership'
                      )}
                    </>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).indirect_holding}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>
                      {t(
                        'olkypass.companyPage.collaborators_section._switch.IndirectOwnership'
                      )}
                    </>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).voting_rights}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>
                      {t('olkypass.companyPage.collaborators_section._switch.VotingRights')}
                    </>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).bare_ownership}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>
                      {t('olkypass.companyPage.collaborators_section._switch.BareOwnership')}
                    </>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).legal_representative}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>
                      {t(
                        'olkypass.companyPage.collaborators_section._switch.LegalRepresentative'
                      )}
                    </>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).trustee}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>{t('olkypass.companyPage.collaborators_section._switch.Trustee')}</>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).protector}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>{t('olkypass.companyPage.collaborators_section._switch.Protector')}</>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).settlor}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>{t('olkypass.companyPage.collaborators_section._switch.Settlor')}</>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).trust_beneficiary}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>
                      {t(
                        'olkypass.companyPage.collaborators_section._switch.TrustBeneficiary'
                      )}
                    </>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).associative_structure}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>
                      {t('olkypass.companyPage.collaborators_section._switch.NonProfitOrg')}
                    </>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).bond_holder}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>{t('olkypass.companyPage.collaborators_section._switch.BondHolder')}</>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).fiduciary}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>{t('olkypass.companyPage.collaborators_section._switch.Fiduciaire')}</>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).fiduciant}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>{t('olkypass.companyPage.collaborators_section._switch.Fiduciant')}</>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).other}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>{t('olkypass.companyPage.collaborators_section._switch.Other')}</>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).agent}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>{t('olkypass.companyPage.collaborators_section._switch.Mandatary')}</>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).agent_via_le}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>{t('olkypass.companyPage.collaborators_section._switch.MandataryLE')}</>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).contact}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>{t('olkypass.companyPage.collaborators_section._switch.Contact')}</>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).signatory}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>{t('olkypass.companyPage.collaborators_section._switch.Signatory')}</>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration class="my-2">
              {{
                input: () => <Checkbox
                  v-model={(collaborator.linkNPLE || {}).web_banking_olky}
                  disabled={readOnlyField.value}
                >
                  {{
                    label: () => <>
                      {t('olkypass.companyPage.collaborators_section._switch.WebBankingOlky')}
                    </>
                  }}
                </Checkbox>
              }}
            </LabelDecoration>
            <LabelDecoration
              class="my-2"
              label={t('olkypass.companyPage.collaborators_section._switch.MandataryPosition')}
            >
              {{
                input: (input: any) => <Input
                  size="lg"
                  class={input.class}
                  readOnly={readOnlyField.value}
                  v-model={(collaborator.linkNPLE || {}).agent_function}
                />
              }}
            </LabelDecoration>
            <LabelDecoration
              class="my-2"
              label={t('olkypass.companyPage.collaborators_section.input.OwnershipRate')}
            >
              {{
                input: (input: any) => <Input
                  size="lg"
                  class={input.class}
                  readOnly={readOnlyField.value}
                  v-model={(collaborator.linkNPLE || {}).beneco_percent}
                />
              }}
            </LabelDecoration>{' '}
            <LabelDecoration
              class="my-2"
              label={t('olkypass.companyPage.collaborators_section.input.MandataryCompanyName')}
            >
              {{
                input: (input: any) => <Input
                  size="lg"
                  class={input.class}
                  readOnly={readOnlyField.value}
                  v-model={(collaborator.linkNPLE || {}).non_le_agent}
                />
              }}
            </LabelDecoration>
          </div>

          {canUpdateCompany.value && <div className="mt-4">
              <ButtonConfirm
                class="float-right"
                confirmTitle={t(
                  'olkypass.companyPage.collaborators_section.dialog.header.confirm_action'
                )}
                confirmMessage={t(
                  'olkypass.companyPage.collaborators_section.dialog.body.remove'
                )}
                confirmButtonText={t('olkypass.general.confirm')}
                cancelButtonText={t('olkypass.general.cancel')}
                icon={TrashIcon}
                theme="danger"
                loading={loading.value}
                onClick={async (e: any) => {
                  loading.value = true
                  e.preventDefault()
                  const res = await disassociateCollaborator((collaborator.linkNPLE || {}).uuid)
                  if (res) {
                    await loadCollaborators(identity_uuid)
                  }
                  loading.value = false
                }}
              >
                {t('olkypass.companyPage.tooltip.button.delete')}
              </ButtonConfirm>
              {!displayButtonSave.value && <Button
                  theme="primary"
                  icon={PencilSquareIcon}
                  class="float-right"
                  onClick={(e: any) => {
                    e.preventDefault()
                    readOnlyField.value = false
                    displayButtonSave.value = true
                  }}
                >
                  {t('olkypass.companyPage.tooltip.button.update')}
                </Button>}
              {displayButtonSave.value && <Button
                  theme="primary"
                  icon={CheckIcon}
                  class="float-right"
                  loading={collaboratorStore.loading}
                  onClick={async (e: any) => {
                    e.preventDefault()
                    await updateCollaborator(collaborator.linkNPLE)
                  }}
                >
                  {t('olkypass.companyPage.tooltip.button.save_collaborator_info')}
                </Button>}
            </div>}
        </div>
      </div>}
  </li>
})
