import { createLoaderStore } from '@/features/utils/loader'
import { AuthApiClient } from '../api-client/api-client'
import { UserStaticDataStore, UserStore } from '../store'

const Loader = createLoaderStore('user')

export const useUser = () => {
  const loader = Loader()
  const user = UserStore()
  const userStaticData = UserStaticDataStore()

  // Get user profile
  const refreshProfile = async () => {
    refreshStaticData()
    const updated = await AuthApiClient.profile()
    user.set(updated)
  }

  // Get user profile
  const refreshStaticData = async () => {
    const updated = await AuthApiClient.getStaticData('fr')
    userStaticData.set(updated)
  }

  return { user, loader, refreshProfile, refreshStaticData }
}
