import { defineJsxComponent, type VModelProps } from '@/jsx-utils'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'
import { defaultInputClassName } from '.'
import './index.css'

const VueTelInputAny = VueTelInput as any

type InputPhoneProps = {
  size?: 'sm' | 'md' | 'lg'
} & Partial<Omit<HTMLInputElement, 'size'>> &
  VModelProps<string>

export const InputPhone = defineJsxComponent<InputPhoneProps>(
  props => {
    let className = defaultInputClassName({ focus: 'focus-within' })

    if (props.size === 'lg') className += ' h-10 pr-2'
    else if (props.size === 'sm') className += ' h-7 pr-2'
    else className += ' h-9 pr-2'

    return () => <VueTelInputAny
      id="phone"
      autocomplete="phone"
      class={
        'vue-tel-input flex flex-row focus-within:shadow-none focus-within:border-slate-300 ' +
        className
      }
      {...props}
    />
  },
  {
    inheritAttrs: false
  }
)
