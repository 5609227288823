<template>
  <v-dialog v-model="dialog" :scrim="true" transition="dialog-bottom-transition" width="50%">
    <v-card class="cardBoxPopup">
      <v-card-title class="text-h5">{{
        $t('olkypass.accountPage.documentsSection.dialog.header.document')
      }}</v-card-title>
      <!--      <template v-slot:append>
        <v-btn
            icon
            dark
            @click="destroyDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>-->
      <v-card-text>
        <div class="iframe-container">
          <iframe
            class="kyc-iframe"
            width="100%"
            height="650px"
            :src="[sessionUrl]"
            :allow="allowSourcesAccess"
            loading="eager"
          >
          </iframe>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import { mapGetters } from '../../store'
import store from '../../store'
import config from '@/config'

export default {
  name: 'KYCDocumentDialogChild',
  components: {},
  mixins: [NotificationsMixins],
  data() {
    return {
      dialog: false,
      api_config: {
        onBoardingUrl: config.API_VID_ALGOREG
      },
      sessionUrl: '',
      allowSourcesAccess:
        'camera ' +
        config.API_VID_ALGOREG +
        '; microphone ' +
        config.API_VID_ALGOREG +
        '; geolocation ' +
        config.API_VID_ALGOREG +
        ';',
      setCurrentEntityFunctionName: ''
    }
  },
  mounted() {},
  methods: {
    fillIFrameUrl() {
      this.sessionUrl =
        this.api_config.onBoardingUrl +
        '/?session_id=' +
        this.kycDocumentSession.sessionId +
        '&iframe_callback=1&show_consent=0&show_home=0&show_final=0&use_speech=0&powered_by=0&theme=' +
        (document.querySelector('.v-theme--myCustomDarkTheme') ? 'dark' : 'light')
    },
    async open() {
      this.dialog = true
      await store.dispatch('playLoader')
      this.sessionUrl = ''
      await store.dispatch('prepareKycSession', {
        UUID: this.currentUUID,
        language: this.currentLanguage,
        sessionType: 'DOCUMENT',
        naturalPerson: this.naturalPerson
      })
      await store.dispatch('createNewSession', {
        sessionId: this.kycDocumentSession.sessionId,
        UUID: this.currentUUID,
        sessionType: 'DOCUMENT',
        naturalPerson: this.naturalPerson,
        AbstractEntity: this.currentUUID
      })
      this.addWindowEventListener()
      this.fillIFrameUrl()
      await store.dispatch('stopLoader')
    },
    async internalEventListenerDocument(msg) {
      if (msg.data.event === 'reload') {
        window.location.reload()
      }
      if (msg.data.event === 'end_session') {
        await store.dispatch('playLoader')
        await store.dispatch('updateDocumentSession', {
          sessionId: this.kycDocumentSession.sessionId,
          document: this.newDocument,
          AbstractEntity: this.currentUUID
        })
        await store.dispatch('stopLoader')
        await this.destroyDialog()
      }
    },
    async destroyDialog() {
      await store.dispatch('playLoader')
      await store.dispatch('loadIdentityDocuments', {
        UUID: this.currentUUID
      })
      await store.dispatch('stopLoader')
      this.dialog = false
      this.sessionUrl = ''
      window.removeEventListener('message', this.internalEventListenerDocument)
    },
    addWindowEventListener() {
      window.addEventListener('message', this.internalEventListenerDocument)
    }
  },
  computed: {
    ...mapGetters([
      'kycDocumentSession',
      'currentTreatedEntity',
      'currentUUID',
      'currentLanguage',
      'newDocument',
      'naturalPerson'
    ])
  }
}
</script>

<style scoped>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}
.iframe-container {
  /*padding-top: 45.25%; !* 16:9*!*/
  overflow: hidden;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  /*height: 100%;
  left: 0;
  position: absolute;
  top: 0;*/
  width: 100%;
}
</style>
