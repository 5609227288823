<template>
  <ion-page id="ChoiceBeneficiaryPage">
    <!--    <header-tabs go-back-url="/transfer"></header-tabs>-->
    <ion-content :fullscreen="true">
      <ion-grid class="mt-6">
        <!--        <stepper :current-step="1"></stepper>-->
        <ion-row>
          <ion-col>
            <div class="card card-plain border-1">
              <div class="card-header pb-0 p-3">
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <h2 class="mb-0">
                      {{ $t('olkypay.labels.transferPage.one_time_tab.sender_account') }}
                    </h2>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <ion-row>
                  <ion-col>
                    <div
                      class="card border-1 border-gray border-radius-lg bg-gray-300 border-selected mt-2"
                    >
                      <ion-row>
                        <ion-col size="2" class="mt-2">
                          <div class="circleIcon">
                            <i class="logoOlky">
                              <ion-img :src="olkyFavicon" class="material-icons"></ion-img
                            ></i>
                          </div>
                        </ion-col>
                        <ion-col>
                          <div class="d-flex flex-column">
                            <h4 class="mb-0 text-dark mt-1">{{ currentSupplier.fullName }}</h4>
                            <span class="text-xs text-gray">{{ formattedIBAN }}</span>
                          </div>
                        </ion-col>
                      </ion-row>
                      <ion-row class="mt-0">
                        <ion-col>
                          <div
                            class="d-flex align-items-center ion-text-right text-normal text-sm mt-0"
                          >
                            € {{ formattedBalance }}
                          </div>
                        </ion-col>
                      </ion-row>
                    </div>
                  </ion-col>
                </ion-row>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div class="card card-plain">
              <div class="card-header pb-0 px-3">
                <h2 class="mb-2">
                  {{ $t('olkypay.labels.transferPage.one_time_tab.beneficiary') }}
                </h2>
              </div>
              <div v-if="isDataLoading" class="text-center mt-4">
                <ion-spinner name="bubbles" class="text-center"></ion-spinner>
                <br />
                <ion-label class="text-center text-gray text-md">{{
                  $t('olkypay.labels.homePage.loading_data')
                }}</ion-label>
              </div>
              <div v-else class="card-body pt-4 p-3">
                <ion-searchbar
                  animated="true"
                  :placeholder="$t('olkypay.inputs.homePage.search')"
                  class="no-elevation bg-gray-100 border-radius-xs form-control form-control-sm border-radius-md mb-3"
                  @ionBlur="searchBeneficiaries($event)"
                ></ion-searchbar>
                <div v-for="beneficiary in beneficiaries">
                  <beneficiary-account :beneficiary="beneficiary"></beneficiary-account>
                </div>
                <ion-infinite-scroll @ionInfinite="displayPaginatedBeneficiaries($event)">
                  <ion-infinite-scroll-content
                    :loading-text="$t('olkypay.labels.homePage.loading_data')"
                    loading-spinner="bubbles"
                  ></ion-infinite-scroll-content>
                </ion-infinite-scroll>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-fab v-if="currentBeneficiary.id" slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button color="blue_olky" @click="openNextStep">
          <ion-icon :icon="chevronForwardCircle"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import InternalSkeleton from '../../../../components/skeleton/InternalSkeleton.vue'
import olkyFavicon from '../../../../../../../public/images/olkypay/olkypay_icon.svg'
import { mapActions, mapGetters } from 'vuex'
import BeneficiaryAccount from '../../../../components/transfer/beneficiaryAccount.vue'
import Stepper from '../../../../components/transfer/stepper.vue'
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonFab,
  IonFabButton,
  IonIcon,
  IonRow,
  IonCol,
  IonImg,
  IonSearchbar,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSpinner,
  IonLabel
} from '@ionic/vue'
import {
  cardOutline,
  caretDownCircleOutline,
  caretUpCircleOutline,
  add,
  chevronDownCircle,
  chevronForwardCircle,
  chevronUpCircle,
  colorPalette,
  document,
  globe
} from 'ionicons/icons'

export default {
  name: 'ChoiceBeneficiary',
  components: {
    BeneficiaryAccount,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonFab,
    IonFabButton,
    IonIcon,
    IonRow,
    IonCol,
    IonImg,
    IonSearchbar,
    IonGrid,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSpinner,
    IonLabel,
    InternalSkeleton,
    Stepper
  },
  data() {
    return {
      displayed_card: false,
      caretDownCircleOutline,
      caretUpCircleOutline,
      cardOutline,
      add,
      chevronDownCircle,
      chevronForwardCircle,
      chevronUpCircle,
      colorPalette,
      document,
      globe,
      olkyFavicon,
      paginationOptions: {
        page: 1,
        search: ''
      },
      isDataLoading: false
    }
  },
  methods: {
    ...mapActions('beneficiary', [
      'loadBeneficiaries',
      'initBeneficiaries',
      'initCurrentBeneficiary'
    ]),
    ...mapActions('transfer', ['setCurrentTransfer']),
    ...mapActions('loader', ['playSkeletonLoader', 'stopSkeletonLoader']),
    async displayPaginatedBeneficiaries(event) {
      this.paginationOptions.page++
      await this.loadBeneficiaries({ pagination_options: this.paginationOptions })
      event.target.complete()
    },
    async searchBeneficiaries(event) {
      this.isDataLoading = true
      this.initBeneficiaries()
      const query = event.target.value
      this.paginationOptions.search = query
      await this.loadBeneficiaries({ pagination_options: this.paginationOptions })
      this.isDataLoading = false
    },
    openNextStep() {
      this.$emit('open-next-step')
    }
  },
  async mounted() {
    // rest current beneficiary
    this.isDataLoading = true
    this.initCurrentBeneficiary({})
    this.setCurrentTransfer({})
    this.initBeneficiaries()
    await this.loadBeneficiaries({ pagination_options: this.paginationOptions })
    this.isDataLoading = false
  },
  computed: {
    ...mapGetters('supplier', ['currentSupplier']),
    ...mapGetters('beneficiary', ['beneficiaries', 'currentBeneficiary']),
    ...mapGetters('loader', ['isSkeletonLoading']),
    formattedBalance() {
      // balance is in centimes
      const balance = this.currentSupplier.balance / 100
      return new Intl.NumberFormat('de-DE', {}).format(balance)
    },
    formattedIBAN() {
      if (this.currentSupplier.iban) {
        return this.currentSupplier.iban.match(/.{1,4}/g).join(' ')
      }
      return ''
    }
  }
}
</script>
<style></style>
