import * as Sentry from '@sentry/browser'
import * as SentryReact from '@sentry/react'

import React from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import config from './config'

// This enables automatic instrumentation (highly recommended)
// If you only want to use custom instrumentation:
// * Remove the `BrowserTracing` integration
// * add `Sentry.addTracingExtensions()` above your Sentry.init() call

if (document.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: 'https://a0d692b72c304710abbeae4204a0b0b9@sentry.adm.algoreg.com/6',
    environment: document.location.hostname,
    release: 'olkyapp@' + config.APP_VERSION, //Should be updated automatically by the ci-cd somehow (at least add a commit short hash to keep track of each sub version if we forgot to change it)

    integrations: [
      Sentry.browserTracingIntegration(),
      // Or, if you are using react router, use the appropriate integration
      // See docs for support for different versions of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      SentryReact.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/olkyapp-1\.int\.algoreg\.io/,
      /^https:\/\/olkyapp-2\.int\.algoreg\.io/,
      /^https:\/\/olkyapp-3\.int\.algoreg\.io/,
      /^https:\/\/pass\.uat\.olkypay\.com/,
      /^https:\/\/app\.olky\.eu/
    ]
  })
}
