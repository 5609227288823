import { StorageService } from '@/features/utils/storage'
import { UserService } from '../services/user.service.ts'
const state = {
  username: StorageService.getItem('username') ?? '',
  user_settings: {
    authentication_method: StorageService.getItem('authentication_method') ?? null,
    security_infos: {}
  }
}
const getters = {
  username: (state: { username: any }) => state.username,
  user_settings: (state: { user_settings: any }) => state.user_settings
}
const actions = {
  setUsername(context: any, username: any) {
    context.commit('setUsername', username)
  },
  setAuthenticationMethod(context: any, authentication_method: any) {
    context.commit('setAuthenticationMethod', authentication_method)
  },
  async setSecurityInfos(context: any) {
    const resp = await UserService.getSecurityInfos()
    context.commit('setSecurityInfos', resp)
    return resp
  }
}
const mutations = {
  setUsername(
    state: {
      username: string
    },
    username: any
  ) {
    state.username = username
  },
  setAuthenticationMethod(
    state: {
      user_settings: any
    },
    authentication_method: any
  ) {
    StorageService.setItem('authentication_method', authentication_method)
    state.user_settings.authentication_method = authentication_method
  },
  async setSecurityInfos(
    tate: {
      user_settings: any
    },
    security_infos: any
  ) {
    state.user_settings.security_infos = security_infos
  }
}
export const user = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
