import { defineJsxComponent, type VModelProps } from '@/jsx-utils'
import { CheckIcon } from '@heroicons/vue/20/solid'
import { IonToggle } from '@ionic/vue'
import { BaseSmall } from '../text/Text.tsx'

const IonToggleAny = IonToggle as any

type CheckboxProps = {
  size?: 'sm' | 'md' | 'lg'
  square?: boolean
  label?: string
  disabled?: boolean
  class?: string
} & VModelProps<boolean>

export const Checkbox = defineJsxComponent<CheckboxProps>(
  (props, { emit, slots, model }) => {
    let className = props.class || ''
    if (props.size === 'lg') className += ' h-10'
    else if (props.size === 'sm') className += ' h-7'
    else className += ' h-9'

    const renderSwitch = () => {
      if (!props.square)
        return <IonToggleAny
          checked={props.modelValue || props.model?.value}
          disabled={props.disabled}
        />
      return <div
        class={
          ' shrink-0 flex justify-center items-center w-5 h-5 border-2 rounded text-white ' +
          (model()
            ? 'border-blue-500 bg-blue-500 hover:border-blue-600 hover:bg-blue-600'
            : 'border-slate-300 ' + (props.disabled ? '' : 'hover:border-blue-300')) +
          ' ' +
          (props.disabled ? 'opacity-50' : 'cursor-pointer') +
          ' '
        }
      >
        {model() && <CheckIcon class="m-icon-small" />}
      </div>
    }

    if (props.label || slots.label) {
      return () => <div
        class={className + ' flex flex-row items-center w-full overflow-hidden '}
        onClick={() => {
          if (!props.disabled) {
            emit('update:modelValue', !model())
          }
        }}
      >
        {renderSwitch()}
        <BaseSmall class={'grow ml-4 ' + (props.disabled ? 'opacity-50' : 'cursor-pointer')}>
          {slots.label?.() || props.label}
        </BaseSmall>
      </div>
    } else {
      return () => renderSwitch()
    }
  },
  {
    inheritAttrs: false
  }
)
