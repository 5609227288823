import { useMutation } from '@tanstack/react-query'
import { AddressApiClient } from '../api-client/api-client'
import { CityInfoType } from '../../types'

export const useCityPostalCode = () => {
  const getCityPostalCode = () =>
    useMutation<CityInfoType[], Error, { address: string }>({
      mutationFn: async ({ address }) => AddressApiClient.getCityCode(address)
    })

  return { getPostalCodeByCity: getCityPostalCode }
}
