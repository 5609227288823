import { AuthApiClient } from '@/features/auth/api-client/api-client'
import { CheckEmailExistsResponse } from '@/features/auth/types'
import { UseMutationOptions, useMutation } from '@tanstack/react-query'

export default function useCheckEmailExistsMutation(
  options?: UseMutationOptions<CheckEmailExistsResponse, Error, string>
) {
  return useMutation<CheckEmailExistsResponse, Error, string>({
    mutationFn: async (email: string) => AuthApiClient.checkExistingEmail(email),
    ...options
  })
}
