import { Button } from '@/atoms/buttons/Button'
import LabelDecoration from '@/atoms/inputs/decorations/LabelDecoration'
import { defineJsxComponent } from '@/jsx-utils'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { Input } from '@/atoms/inputs/Input'
import { useCollaborator } from '@/features/company/hooks/use-collaborator'
import { Notice } from '@/atoms/notice'
import { Info } from '@/atoms/text/Text.tsx'
import MagnifyingGlassIcon from '@heroicons/vue/20/solid/MagnifyingGlassIcon'
import { CollaboratorType, NaturalPersonType } from '@/features/company/types'
import { useCompany } from '@/features/company/hooks/use-company'

export const AssociateCollaborator = defineJsxComponent<{
  onComplete: () => void
}>((_, { attrs }) => {
  const t = useI18n().t
  const loading = ref(false)
  const loadingSubmit = ref(false)
  const { collaboratorStore, searchCollaborator } = useCollaborator()
  const { companyStore, associateCollaborator } = useCompany()
  const searchKeyword = ref('')
  const isSearchComplete = ref(false)
  const foundPerson = ref<NaturalPersonType | any | null>(null)
  const step = ref(0)
  const isFormValid = computed(() => searchKeyword.value)
  const isAlreadyAssociated = computed(() => {
    if (collaboratorStore.collaborators) {
      const collaborator = collaboratorStore.collaborators.find(
        (collaborator: CollaboratorType) => {
          if (foundPerson.value === null) {
            return false
          }
          return collaborator.naturalPerson.identity_uuid
            ?.toLowerCase()
            .includes(foundPerson.value.identity_uuid.toLowerCase())
        }
      )
      return collaborator !== undefined // Returns true if a matching collaborator is found, false otherwise
    } else {
      return false
    }
  })
  return () => <div>
    {step.value === 0 && <div>
        <Notice type="warning">
          {t('olkypass.companyPage.collaborators_section.help.associate_collaborator.important')}
          <Info class="block mt-2">
            {t(
              'olkypass.companyPage.collaborators_section.help.associate_collaborator.message_1'
            )}
          </Info>
          <Info class="block mt-2">
            {t(
              'olkypass.companyPage.collaborators_section.help.associate_collaborator.message_2'
            )}
          </Info>
        </Notice>

        <Info class="block mt-2">
          {t('olkypass.companyPage.collaborators_section.help.associate_collaborator.message_3')}
        </Info>

        <Button
          class="mt-4 float-right"
          onClick={() => {
            step.value += 1
          }}
        >
          {t('olkypass.general.next')}
        </Button>
      </div>}
    {step.value === 1 && <div>
        <form
          onSubmit={async (e: any) => {
            loadingSubmit.value = true
            e.preventDefault()
            if (
              await associateCollaborator(
                companyStore.currentCompany?.identity_uuid || '',
                foundPerson.value.identity_uuid
              )
            ) {
              attrs.onComplete()
            }
            loadingSubmit.value = false
          }}
        >
          {/*<Info>{t('olkypass.companyPage.collaborators_section.dialog.header.new')}</Info>*/}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
            <LabelDecoration class="my-4 col-span-3" label="">
              {{
                input: (input: any) => <Input
                  size="lg"
                  placeholder="jean.dupont@mail.com"
                  required
                  class={input.class}
                  v-model={searchKeyword.value}
                />
              }}
            </LabelDecoration>
            <Button
              theme="outlined"
              icon={MagnifyingGlassIcon}
              class="my-4 float-right"
              loading={loading.value}
              disabled={!isFormValid.value}
              onClick={async (e: any) => {
                e.preventDefault()
                isSearchComplete.value = false
                foundPerson.value = null
                loading.value = true
                const res = await searchCollaborator(searchKeyword.value)
                const id = res?.id || null
                if (id) {
                  foundPerson.value = res
                }
                loading.value = false
                isSearchComplete.value = true
              }}
            ></Button>
          </div>
          {foundPerson.value && <div>
              <Notice type="success">
                {t('olkypass.companyPage.collaborators_section.dialog.body.search_result')}
                <Info class="block mt-2">
                  {foundPerson.value.first_name} {foundPerson.value.last_name}
                </Info>
                <Info class="block mt-2">{foundPerson.value.identity_uuid}</Info>
              </Notice>
            </div>}
          {isSearchComplete.value && !foundPerson.value && <div>
              <Notice type="warning">
                {t('olkypass.companyPage.collaborators_section.search.noPersonFound')}
              </Notice>
            </div>}
          {isSearchComplete.value && isAlreadyAssociated.value && <div>
              <Notice key="error" type="error" class="mt-4">
                {t('olkypass.companyPage.notifications.warning.already_associated')}
              </Notice>
            </div>}

          <Button
            class="mt-8 float-right"
            type="submit"
            theme="primary"
            loading={loadingSubmit.value}
            disabled={!foundPerson.value || !!isAlreadyAssociated.value}
          >
            {t('olkypass.companyPage.tooltip.button.associate_collaborator')}
          </Button>
        </form>
      </div>}
  </div>
})
