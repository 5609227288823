import React, { Ref, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { RenderItemParams } from './index.react'

type DropdownItemWidgetProps = {
  selected: boolean
  itemKey: string
  itemValue: string
  render?: (item: RenderItemParams) => React.ReactNode
  icon?: () => React.ReactNode
  onChange: (key: string) => void
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'ref'>

export const DropdownItemWidget = forwardRef(
  (
    {
      selected,
      itemKey,
      itemValue,
      render,
      icon,
      onChange = () => {},
      ...props
    }: DropdownItemWidgetProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const getWrapperClassName = () =>
      twMerge(
        'overflow-hidden whitespace-nowrap text-ellipsis flex items-center w-full  hover:bg-slate-50 dark:hover:bg-slate-900 px-2 py-3 rounded-sm cursor-pointer'
      )

    const textClassName = twMerge(
      selected ? 'font-bold dark:text-white' : 'font-medium dark:text-slate-300',
      'overflow-hidden whitespace-nowrap text-ellipsis'
    )

    return <div
      ref={ref}
      role={'option'}
      // Make these elements focusable using a roving tabIndex.
      tabIndex={selected ? 0 : -1}
      className={getWrapperClassName()}
      onClick={() => onChange(itemKey)}
      {...props}
    >
      {render && render({ key: itemKey, value: itemValue, icon, inDropdown: true })}
      {!render && <span className={textClassName}>
          {icon?.()} {itemValue}
        </span>}
    </div>
  }
)
