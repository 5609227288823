<template>
  <ion-page id="CodePinComplexityDetailsPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button default-href="/pay/supplier/more" :icon="caretBackSharp"></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1>{{ $t('olkypay.labels.codePinSettingsPage.header') }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <ion-grid class="mx-2">
        <ion-row class="mt-3">
          <ion-col class="text-center">
            <ion-icon :icon="alertCircle" size="large" color="medium"> </ion-icon>
          </ion-col>
        </ion-row>
        <ion-row class="p-0 mt-0">
          <ion-col class="mx-2" offset="1">
            <h1 class="text-center mt-0 mb-0">
              {{ $t('olkypay.labels.codePinSettingsPage.complexity_help_page.title') }}
            </h1>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p class="text-gray font-weight-bold text-center m-inline-40">
              {{ $t('olkypay.labels.codePinSettingsPage.complexity_help_page.sub_header') }}
            </p>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <h4>{{ $t('olkypay.labels.codePinSettingsPage.complexity_help_page.rules.title') }}</h4>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ul class="mt-0 mb-0 colored-bullets">
              <li>
                {{ $t('olkypay.labels.codePinSettingsPage.complexity_help_page.rules.rule_1') }}
              </li>
              <li>
                {{ $t('olkypay.labels.codePinSettingsPage.complexity_help_page.rules.rule_2') }}
              </li>
              <li>
                {{ $t('olkypay.labels.codePinSettingsPage.complexity_help_page.rules.rule_3') }}
              </li>
            </ul>
          </ion-col>
        </ion-row>
        <ion-row class="mt-7">
          <ion-col class="text-center">
            <button
              class="btn bg-gradient-info mb-0 ms-2 large-button"
              @click="goToRouter('MoreAuthSettingPinSaveSubTabRouter')"
            >
              {{ $t('olkypay.buttons.codePinSettingsPage.go_back_page') }}
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import VOtpInput from 'vue3-otp-input'
import { CardService } from '../../../services/card.service.ts'

import {
  IonHeader,
  IonContent,
  IonPage,
  IonBackButton,
  IonButton,
  IonButtons,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonImg,
  IonGrid,
  alertController,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonItem,
  IonToggle
} from '@ionic/vue'
import { caretBackSharp, fingerPrint, lockClosed, personCircle, alertCircle } from 'ionicons/icons'

export default {
  name: 'CodePinSavePage',
  components: {
    IonHeader,
    IonContent,
    IonPage,
    IonBackButton,
    IonButton,
    IonButtons,
    IonIcon,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonImg,
    IonGrid,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonItem,
    IonToggle,
    VOtpInput
  },
  data() {
    return {
      caretBackSharp,
      fingerPrint,
      lockClosed,
      personCircle,
      alertCircle
    }
  },
  computed: {},
  mounted() {},
  methods: {
    goToRouter(router_name) {
      this.$router.push({ name: router_name })
    }
  }
}
</script>
