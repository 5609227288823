import { useUser } from '@/features/user/hooks/use-user'
import { GatewayVote } from '../types'
import { useKYC } from '@/features/kyc/hooks/use-kyc'

export const useGateways = () => {
  const useDocumentsGatewayVoter = (): GatewayVote => {
    const { KYCStateQuery } = useKYC()
    const { useUserProfile } = useUser()
    const { data: user } = useUserProfile()
    const { data: KYC, isLoading: isLoadingKYC } = KYCStateQuery(user?.naturalPerson?.UUID ?? null)

    if (!user?.naturalPerson?.UUID) return GatewayVote.Inactive
    if (isLoadingKYC) return GatewayVote.Pending

    return KYC && KYC.level === 100 ? GatewayVote.Continue : GatewayVote.Block
  }

  return { useDocumentsGatewayVoter }
}
