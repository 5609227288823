<template>
  <ion-modal
    id="confirmModal"
    class="okypay-modal"
    :is-open="isOpenDialog"
    :can-dismiss="canDismiss"
  >
    <div class="wrapper">
      <div class="text-center mt-4">
        <ion-icon
          v-if="modal.type === 'info'"
          slot="start"
          :icon="alertCircle"
          color="medium"
          size="large"
        ></ion-icon>
        <ion-icon
          v-if="modal.type === 'warning'"
          slot="start"
          :icon="alertCircle"
          color="warning"
          size="large"
        ></ion-icon>
        <ion-icon
          v-if="modal.type === 'danger'"
          slot="start"
          :icon="alertCircle"
          color="danger"
          size="large"
        ></ion-icon>
        <ion-icon
          v-if="modal.type === 'success'"
          slot="start"
          :icon="checkmarkCircleOutline"
          color="success"
          size="large"
        ></ion-icon>
      </div>

      <h1 class="text-center m-inline-40">
        {{ modal.title }}
      </h1>
      <ion-grid class="mt-4">
        <ion-row class="ion-justify-content-center">
          <ion-col size="auto">
            <p>{{ modal.content }}</p>
          </ion-col>
        </ion-row>
        <ion-row class="ion-margin-top">
          <ion-col class="text-start">
            <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" @click="cancelAction()">
              {{ modal.button.cancel }}
            </button>
          </ion-col>
          <ion-col class="text-end" size="7">
            <button class="btn bg-gradient-info mb-0 ms-2" @click="confirmAction()">
              {{ modal.button.confirm }}
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-modal>
</template>

<script lang="ts">
import olkyLogoBluePath from '../../../../public/images/olkypay/olky-logo-blue.png'
import { checkmarkCircleOutline, alertCircle, handRight } from 'ionicons/icons'
import {
  alertController,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonList,
  IonLoading,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRouterOutlet,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  IonModal,
  toastController
} from '@ionic/vue'

export default {
  name: 'ConfirmModal',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonGrid,
    IonRow,
    IonCol,
    IonRadio,
    IonRadioGroup,
    IonInput,
    IonImg,
    IonButton,
    IonRouterOutlet,
    alertController,
    IonLoading,
    IonModal
  },
  data() {
    return {
      isOpenDialog: false,
      resolve: null,
      reject: null,
      canDismiss: false,
      checkmarkCircleOutline,
      alertCircle,
      handRight,
      modal: {}
    }
  },
  computed: {
    olkyLogoBlue() {
      return olkyLogoBluePath
    }
  },
  methods: {
    openModal(modal) {
      this.canDismiss = false
      this.isOpenDialog = true
      this.modal = modal
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    cancelAction() {
      this.canDismiss = true
      this.isOpenDialog = false
      this.resolve(false)
      this.reject(true)
    },
    confirmAction() {
      this.canDismiss = true
      this.isOpenDialog = false
      this.resolve(true)
      this.reject(false)
    }
  }
}
</script>

<style scoped></style>
