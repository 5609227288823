import config from '@/config'
import { fetchServer } from '@/features/utils/fetch'
import { isSystemThemeDark } from '@/features/utils/theme'

export class SupportApiClient {
  static async getSupportUrl(): Promise<string> {
    const tokenRes = await fetchServer('/support/chat/userToken', {
      body: {}
    })
    const token = (await tokenRes.json()).token as string

    const url = new URL(config.API_CHAT_ALGOREG)
    url.searchParams.set('token', token)
    url.searchParams.set('theme', isSystemThemeDark() ? 'dark' : 'light')

    return url.toString()
  }
}
