const state = {
  responseData: '',
  responseErrorCode: 0,
  responseError: '',
  supplierCards: [],
  currentCard: {}
}

const getters = {
  responseData: (state: { responseData: any }) => state.responseData,

  responseErrorCode: (state: { responseErrorCode: any }) => state.responseErrorCode,
  responseError: (state: { responseError: any }) => state.responseError,
  supplierCards: (state: { supplierCards: any }) => state.supplierCards,
  currentCard: (state: { currentCard: any }) => state.currentCard
}

const actions = {
  async setSupplierCards(context: any, cards: any) {
    context.commit('setSupplierCards', cards)
  },
  setCurrentCard(context: any, card: any) {
    context.commit('setCurrentCard', card)
  }
}

const mutations = {
  dataRequest(state: { responseError: string; responseErrorCode: number }) {
    state.responseError = ''
    state.responseErrorCode = 0
  },
  dataSuccess(state: { responseData: string }, payload: any) {
    state.responseData = payload
  },
  successLoadSuppliers(state: { suppliers: any }, payload: any) {
    state.suppliers = payload
  },
  dataError(
    state: {
      responseError: any
      responseErrorCode: any
    },
    { errorCode, errorMessage }: any
  ) {
    state.responseError = errorMessage
    state.responseErrorCode = errorCode
  },
  setSupplierCards(state: { supplierCards: any }, cards: any) {
    state.supplierCards = cards
  },
  setCurrentCard(state: { currentCard: any }, card: any) {
    state.currentCard = card
  }
}

export const card = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
