import Text, { Info } from '@/atoms-react/text/Text.react.tsx'
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import i18next from 'i18next'
import _ from 'lodash'
import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useDropdown } from './hooks/use-dropdown'
import { DropdownInputWidget } from './input.react'
import { DropdownItemWidget } from './item.react'
import { DropdownSearchWidget } from './search.react'

export type DropdownProps = {
  onChange?: (item: { key: string; value: any; alpha?: string }) => void
  initialValue?: string | null
  value?: string | null

  required?: boolean
  label?: string
  autoComplete?: string
  handleAutoComplete?: (
    autoComplete: string,
    items: DropdownItem[],
    change: (item: DropdownItem) => void
  ) => void
  disabled?: boolean
  placeholder?: string
  items: DropdownItem[]
  groups?: GroupParams[]
  className?: string
  showChevrons?: boolean
  search?: DropdownSearchParams | boolean
  hideUngrouped?: boolean
  dropdownClass?: string
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>

type WithoutType<T> = T extends { initialValue: any; value: any } ? never : T

export type DropdownSearchParams = {
  custom?: (
    inputValueSearch: string,
    item: DropdownItem,
    index: number,
    array: DropdownItem[]
  ) => boolean
  placeholder?: string
}

export type DropdownItem = {
  key: string
  value: string
  icon?: React.ReactNode
  groupKey?: string
  render?: ({ key, value, icon, inDropdown }: RenderItemParams) => React.ReactNode
}

export type RenderItemParams = {
  key: string
  value: string
  icon: React.ReactNode | null
  inDropdown: boolean | null
}

export type GroupParams = {
  key: string
  label?: string
}

export function Dropdown({
  initialValue,
  label,
  autoComplete,
  handleAutoComplete = () => {},
  placeholder,
  disabled,
  items,
  groups,
  className,
  showChevrons = true,
  onChange = () => {},
  search,
  hideUngrouped = false,
  dropdownClass,
  ...props
}: DropdownProps & WithoutType<DropdownProps>) {
  useEffect(() => {
    i18next.loadNamespaces(['pass'])
  }, [])

  const [searchValue, setSearchValue] = useState('')
  const [value, setValue] = useState<string | null | undefined>(initialValue)

  useEffect(() => {
    if (props.value !== undefined && props.value != initialValue && props.value !== value)
      setValue(props.value)
  }, [props.value])

  useEffect(() => {
    if (items.length > 0 && value !== initialValue) {
      onChange(
        items.find((item) => item.key.toLowerCase() === value?.toLowerCase()) ?? {
          key: '',
          value: null
        }
      )
    }
  }, [items?.length, value])

  const dropdown = useDropdown({ disabled })
  const selectedItem = items.find((item) => item.key.toLowerCase() === value?.toLowerCase()) ?? null

  const [missingRequired, setMissingRequired] = useState(false)
  const checkRequired = useCallback(() => {
    if (props.required && !value) setMissingRequired(true)
  }, [props.required, value])
  useEffect(() => {
    // Listen for window message that check all required fields
    window.addEventListener('checkRequiredFields', checkRequired)
    return () => window.removeEventListener('checkRequiredFields', checkRequired)
  }, [])
  const initDropDown = useRef(false)
  const searchInput = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!dropdown.isOpen && !value && initDropDown.current) setMissingRequired(true)
    if (dropdown.isOpen) setMissingRequired(false)
    initDropDown.current = true
  }, [dropdown.isOpen])

  useEffect(() => {
    if (!dropdown.isOpen) return
    setValue(items.at(dropdown.selectedIndex ?? 0)?.key)
  }, [dropdown.selectedIndex])

  const handleItemChange = (value: string | undefined) => {
    setSearchValue('')
    dropdown.handleSelect(items.findIndex((i) => i.key.toLowerCase() === value?.toLowerCase()))
    setValue(value)
    onChange(
      items.find((item) => item.key.toLowerCase() === value?.toLowerCase()) ?? {
        key: '',
        value: null
      }
    )
  }

  const getFilterFunction = (value: DropdownItem, index: number, array: DropdownItem[]) => {
    // Custom search function provided
    if (_.isObject(search) && search && search.custom) {
      return search.custom(searchValue, value, index, array)
    }
    return (value.value || '').toLowerCase().includes(searchValue.toLowerCase())
  }
  const filteredItems = _.uniqBy((items ?? []).filter(getFilterFunction), 'key')

  const dropdownStyle = twMerge(
    `relative px-4 py-3 rounded-sm border-2 duration-300 w-full flex justify-between items-center font-semibold focus-within:bg-white focus-within:dark:bg-slate-900 focus-within:border-blue-500 focus-within:text-black`,
    value && 'text-black dark:text-white',
    !value && 'text-slate-400 dark:text-slate-600',
    dropdown.isOpen && 'bg-white dark:bg-slate-900 border-blue-500 text-black',
    !dropdown.isOpen &&
      'bg-slate-50 dark:bg-slate-800 border-slate-50 dark:border-slate-800 hover:bg-slate-100 dark:hover:bg-slate-900 hover:border-slate-100 dark:hover:border-slate-800',
    disabled ? 'pointer-events-none opacity-60 text-opacity-70' : '',
    dropdownClass
  )

  const dropdownItemKeyDown = (event: KeyboardEvent<HTMLElement>, item: DropdownItem) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      handleItemChange(item.key)
    } else if (event.key === 'Tab') {
      event.preventDefault()
      event.stopPropagation()
      searchInput?.current?.focus()
    } else if (event.key.match(/[a-z]/i)) {
      searchInput?.current?.focus()
    }
  }

  let index = 0
  return (
    <>
      <div className={twMerge('relative', className)} {..._.omit(props, 'className')}>
        {label && (
          <Text
            type="label"
            className={twMerge(
              'pb-2 whitespace-nowrap overflow-hidden ellipsis',
              disabled ? 'opacity-40' : ''
            )}
          >
            {label} {props.required ? ' *' : ''}
          </Text>
        )}
        <div
          className={twMerge(dropdownStyle, missingRequired && 'border-red-500')}
          tabIndex={0}
          ref={dropdown.reference.ref}
          {...dropdown.reference.getProps({
            onKeyDown: (event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
                event.stopPropagation()
              }
            },
            onFocus: () => !value && dropdown.setIsOpen(true)
          })}
        >
          <DropdownInputWidget
            selectedValue={selectedItem ?? null}
            showChevrons={showChevrons && !disabled}
            placeholder={placeholder}
            isOpen={dropdown.isOpen}
          />

          {autoComplete && (
            <input
              tabIndex={-1}
              autoComplete={autoComplete}
              className="absolute top-0 left-0 w-0 h-1"
              onChange={(event) =>
                handleAutoComplete(event.target.value, items, (item) => handleItemChange(item.key))
              }
            />
          )}
        </div>
        {dropdown.isOpen ? (
          <FloatingPortal>
            <FloatingFocusManager
              context={dropdown.context}
              modal={false}
              children={
                <div
                  className={`z-50 bg-white dark:bg-slate-950 rounded-sm sm:w-96 p-2 pb-0 shadow-lg border-2 border-slate-50 dark:border-slate-900 max-h-60 flex flex-col`}
                  ref={dropdown.floating.ref}
                  style={dropdown.floating.styles}
                  {...dropdown.floating.getProps()}
                >
                  {(search === true || _.isObject(search)) && (
                    <DropdownSearchWidget
                      /* @ts-expect-error Veaury */
                      ref={searchInput}
                      search={search}
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault()
                          event.stopPropagation()
                          const newItem = filteredItems.find(
                            (i) => i.key === _.sortBy(filteredItems, 'value')[0].key
                          )
                          if (newItem) {
                            handleItemChange(newItem.key)
                          }
                        }
                      }}
                    />
                  )}

                  <div className={'grow overflow-auto pb-2 outline-none'}>
                    {!searchValue &&
                      (groups ?? []).map((group) => (
                        <div
                          className={twMerge(
                            'mt-2 mb-4 pb-4 border-b border-slate-100',
                            hideUngrouped && 'last:border-0 last:mb-0'
                          )}
                          key={group.key}
                        >
                          {group.label && <Info className={'text-slate-400'}>{group.label}</Info>}
                          {items
                            .filter((item) => item?.groupKey === group.key)
                            .map((item) => (
                              <DropdownItemWidget
                                key={item.key + (item?.groupKey ?? '-no-group')}
                                itemKey={item.key}
                                selected={item.key === value}
                                render={item.render}
                                icon={item.icon ? () => item.icon : undefined}
                                itemValue={item.value}
                                onChange={() => {
                                  const newItem = items.find((i) => i.key === item.key)
                                  if (newItem) handleItemChange(newItem.key)
                                }}
                                /* @ts-expect-error Veaury */
                                ref={(node: HTMLDivElement) =>
                                  (dropdown.options.refs.current[index++] = node)
                                }
                                {...dropdown.options.getProps({
                                  // Handle pointer select.
                                  onClick() {
                                    handleItemChange(item.key)
                                  },
                                  // Handle keyboard select.
                                  onKeyDown: (event) => dropdownItemKeyDown(event, item)
                                })}
                              />
                            ))}
                        </div>
                      ))}
                    {!hideUngrouped &&
                      _.sortBy(filteredItems, 'value').map((item) => (
                        <DropdownItemWidget
                          key={item.key + 'no-group'}
                          selected={item.key === value}
                          itemValue={item.value}
                          itemKey={item.key}
                          render={item.render}
                          icon={item.icon ? () => item.icon : undefined}
                          onChange={(itemKey) => {
                            const newItem = filteredItems.find((i) => i.key === itemKey)
                            if (newItem) handleItemChange(newItem.key)
                          }}
                          /* @ts-expect-error Veaury */
                          ref={(node: HTMLDivElement) =>
                            (dropdown.options.refs.current[index++] = node)
                          }
                          {...dropdown.options.getProps({
                            // Handle pointer select.
                            onClick() {
                              handleItemChange(item.key)
                            },
                            // Handle keyboard select.
                            onKeyDown: (event) => dropdownItemKeyDown(event, item)
                          })}
                        />
                      ))}
                  </div>
                </div>
              }
            />
          </FloatingPortal>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
