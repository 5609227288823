<template>
  <ion-page id="OneTimeTransferPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          default-href="/pay/supplier/transfer"
          :icon="caretBackSharp"
        ></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1>{{ $t('olkypay.labels.transferPage.one_time_tab.header') }}</h1>
      </ion-title>
    </ion-toolbar>
    <choice-beneficiary @open-next-step="openNextStep"></choice-beneficiary>
  </ion-page>
</template>

<script lang="ts">
import HeaderTabs from '../../../components/HeaderTabs.vue'
import ChoiceBeneficiary from './steps/ChoiceBeneficiary.vue'
import {
  IonHeader,
  IonContent,
  IonPage,
  IonBackButton,
  IonButton,
  IonButtons,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonImg,
  IonGrid
} from '@ionic/vue'
import { caretBackSharp, alertCircle } from 'ionicons/icons'
export default {
  name: 'OneTimeTransfer',
  components: {
    IonHeader,
    IonContent,
    IonPage,
    IonBackButton,
    IonButton,
    IonButtons,
    IonIcon,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonImg,
    IonGrid,
    ChoiceBeneficiary,
    HeaderTabs
  },
  data() {
    return {
      caretBackSharp
    }
  },
  computed: {},
  mounted() {},
  methods: {
    openNextStep() {
      this.$router.push({ name: 'ChoiceTypeTransferSubTabRouter' })
    }
  }
}
</script>
