import config from '@/config'
import { isSystemThemeDark } from '../utils/theme'
import { KYCSession, KYCSessionURLParam, KYCSessionURLParams } from './types'

const defaultParams: KYCSessionURLParams = {
  powered_by: true,
  iframe_callback: true,
  show_consent: false,
  show_home: false,
  show_final: false,
  use_speech: false
}

export const generateSessionURL = (
  session: KYCSession,
  urlParams: Partial<KYCSessionURLParams> = {}
): URL => {
  const url = new URL(config.API_VID_ALGOREG)
  const params = [
    ['session_id', session.sessionId],
    ...(Object.keys(defaultParams) as KYCSessionURLParam[]).map((key: KYCSessionURLParam) => {
      let value = urlParams[key]
      if (value === undefined) {
        value = defaultParams[key]
      }
      return [key, value ? '1' : '0']
    }),
    ['theme', isSystemThemeDark() ? 'dark' : 'light']
  ]

  params.forEach(param => url.searchParams.append(param[0], param[1]))

  return url
}
