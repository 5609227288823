<template>
  <ion-page id="FollowUpPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          default-href="/pay/supplier/transfer"
          :icon="caretBackSharp"
        ></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1>{{ $t('olkypay.labels.transferPage.follow_up') }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-chip
              color="primary"
              :outline="true"
              :class="segment === 'ALL' ? 'bg-blue-400 text-white' : ''"
              @click="refreshOrders('ALL')"
            >
              {{ $t('olkypay.labels.followUpPage.order_status.ALL') }}
            </ion-chip>
            <ion-chip
              color="tertiary"
              :outline="true"
              :class="segment === 'TO_VALIDATE' ? 'bg-blue-400 text-white' : ''"
              @click="refreshOrders('TO_VALIDATE')"
            >
              {{ $t('olkypay.labels.followUpPage.order_status.TO_VALIDATE') }}
            </ion-chip>
            <ion-chip
              color="secondary"
              :outline="true"
              :class="segment === 'IN_PROGRESS' ? 'bg-blue-400 text-white' : ''"
              @click="refreshOrders('IN_PROGRESS')"
            >
              {{ $t('olkypay.labels.followUpPage.order_status.IN_PROGRESS') }}
            </ion-chip>
            <ion-chip
              color="success"
              :outline="true"
              :class="segment === 'COMPLETED' ? 'bg-blue-400 text-white' : ''"
              @click="refreshOrders('COMPLETED')"
              >{{ $t('olkypay.labels.followUpPage.order_status.COMPLETED') }}
            </ion-chip>
            <ion-chip
              color="danger"
              :outline="true"
              :class="segment === 'CANCELLED' ? 'bg-blue-400 text-white' : ''"
              @click="refreshOrders('CANCELLED')"
              >{{ $t('olkypay.labels.followUpPage.order_status.CANCELLED') }}
            </ion-chip>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <div class="card card-plain">
              <div class="card-header pb-0 px-3">
                <h3 class="mb-2 font-weight-bold">
                  {{ $t('olkypay.labels.followUpPage.payment_orders') }}
                </h3>
              </div>
              <div class="card-body pt-4">
                <ion-searchbar
                  animated="true"
                  :placeholder="$t('olkypay.inputs.homePage.search')"
                  @ionBlur="searchOrders($event)"
                  class="no-elevation bg-gray-100 border-radius-xs form-control form-control-sm border-radius-md mb-2"
                ></ion-searchbar>
                <div v-if="isSkeletonLoading" class="text-center mt-4">
                  <ion-spinner name="bubbles" class="text-center"></ion-spinner>
                  <br />
                  <ion-label class="text-center text-gray text-md">{{
                    $t('olkypay.labels.homePage.loading_data')
                  }}</ion-label>
                </div>
                <div v-else-if="!isSkeletonLoading && orders.length">
                  <div v-for="(ordersList, dateKey) in groupedOrders">
                    <ion-row class="mt-2 mb-2">
                      <ion-col size="1">
                        <ion-icon :icon="calendar" class="text-gray"></ion-icon>
                      </ion-col>
                      <ion-col>
                        <h4 class="text-gray font-weight-bolder">{{ dateKey }}</h4>
                      </ion-col>
                    </ion-row>
                    <div v-for="order in ordersList">
                      <order :order="order"></order>
                    </div>
                  </div>
                  <ion-infinite-scroll @ionInfinite="displayPaginatedOrders($event)">
                    <ion-infinite-scroll-content
                      :loading-text="$t('olkypay.labels.homePage.loading_data')"
                      loading-spinner="bubbles"
                    ></ion-infinite-scroll-content>
                  </ion-infinite-scroll>
                </div>
                <div v-else>
                  <div class="d-flex flex-column bg-gray-200 border-radius-lg p-1 mt-2">
                    <span class="mb-2 text-md">{{
                      $t('olkypay.labels.followUpPage.no_orders_found')
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-fab slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button color="blue_olky">
          <ion-icon :icon="settingsOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          <ion-fab-button v-if="selectedOrders.length" color="success" @click="startConfirmOrders">
            <ion-icon :icon="checkmarkCircle"></ion-icon>
          </ion-fab-button>

          <ion-fab-button
            v-if="selectedOrders.length === 1"
            color="danger"
            @click="startCancelOrders"
          >
            <ion-icon :icon="trashOutline"></ion-icon>
          </ion-fab-button>

          <ion-fab-button
            v-if="!selectedOrders.length"
            color="blue_olky"
            size="large"
            @click="gotToRouter"
          >
            <ion-icon :icon="addCircle"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>

      <ion-action-sheet
        :is-open="isConfirmationModalOpen"
        :header="$t('olkypay.labels.followUpPage.confirm_dialog_orders.header')"
        :sub-header="$t('olkypay.labels.followUpPage.confirm_dialog_orders.sub_header')"
        :buttons="actionSheetButtons"
        @didDismiss="actionSheetResult($event)"
      ></ion-action-sheet>
    </ion-content>
    <authentication-process-popup ref="authenticationPopup"></authentication-process-popup>
    <validation-code-modal ref="validationCodeModal"></validation-code-modal>
    <info-modal ref="infoModal"></info-modal>
  </ion-page>
</template>

<script lang="ts">
import { TransactionService } from '../../../services/transaction.service'
import HeaderTabs from '../../../components/HeaderTabs.vue'
import Order from '../../../components/orders/order.vue'
import OrderSkeleton from '../../../components/orders/order-skeleton.vue'
import AuthenticationProcessPopup from '../../../components/security/authenticationProcessPopup.vue'
import ValidationCodeModal from '../../../components/security/validationCodeModal.vue'
import InfoModal from '../../../components/InfoModal.vue'
import { caretBackSharp, alertCircle, addCircle } from 'ionicons/icons'
import {
  IonHeader,
  IonTitle,
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonFab,
  IonFabButton,
  IonFabList,
  modalController,
  IonActionSheet,
  IonButton,
  IonChip,
  IonBackButton,
  IonToolbar,
  IonButtons,
  IonSpinner,
  IonRefresher,
  IonRefresherContent
} from '@ionic/vue'
import {
  home,
  hourglass,
  timer,
  removeCircle,
  logoEuro,
  checkmarkCircle,
  wallet,
  chevronUpCircle,
  saveOutline,
  trashOutline,
  calendar,
  settingsOutline
} from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    IonHeader,
    IonTitle,
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
    IonIcon,
    IonFab,
    IonFabButton,
    IonFabList,
    modalController,
    IonActionSheet,
    IonButton,
    IonChip,
    IonBackButton,
    IonToolbar,
    IonButtons,
    IonSpinner,
    IonRefresher,
    IonRefresherContent,
    HeaderTabs,
    Order,
    OrderSkeleton,
    AuthenticationProcessPopup,
    ValidationCodeModal,
    InfoModal
    // ConfirmationModal
  },
  data() {
    return {
      home,
      wallet,
      hourglass,
      timer,
      removeCircle,
      logoEuro,
      checkmarkCircle,
      chevronUpCircle,
      saveOutline,
      trashOutline,
      caretBackSharp,
      calendar,
      addCircle,
      settingsOutline,
      segment: 'ALL',
      paginationOptions: {
        //filter: "?f=status:eq:4&s=-id&p=1",
        page: 1,
        sort_key: '-id',
        filter_key: 'status',
        filter: '',
        search: ''
      },
      actionSheetButtons: [
        {
          text: this.$i18n.t('olkypay.labels.followUpPage.confirm_dialog_orders.buttons.confirm'),
          data: {
            action: 'confirm'
          }
        },
        {
          text: this.$i18n.t('olkypay.labels.followUpPage.confirm_dialog_orders.buttons.cancel'),
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        }
      ],
      isConfirmationModalOpen: false,
      isOpenValidationCodeModal: false,
      operation: ''
    }
  },
  methods: {
    ...mapActions('loader', [
      'playSkeletonLoader',
      'stopSkeletonLoader',
      'playLoader',
      'stopLoader'
    ]),
    ...mapActions('transaction', ['loadOrders', 'initOrders', 'initSelectedOrders']),
    ...mapActions('auth', ['setIsInternalReAuthenticatedStatus']),
    buildFilterOptions() {
      switch (this.segment) {
        case 'ALL':
          if (this.paginationOptions.search !== '') {
            this.paginationOptions.filter =
              '?f=comment:lk:' +
              this.paginationOptions.search +
              '&s=' +
              this.paginationOptions.sort_key +
              '&p=' +
              this.paginationOptions.page
          } else {
            this.paginationOptions.filter =
              '?s=' + this.paginationOptions.sort_key + '&p=' + this.paginationOptions.page
          }
          break
        case 'TO_VALIDATE':
          // 4: To validate
          this.paginationOptions.filter =
            '?f=' +
            this.paginationOptions.filter_key +
            ':eq:4' +
            (this.paginationOptions.search !== ''
              ? ',comment:lk:' + this.paginationOptions.search
              : '') +
            '&s=' +
            this.paginationOptions.sort_key +
            '&p=' +
            this.paginationOptions.page
          break
        case 'IN_PROGRESS':
          // 5: in progress
          this.paginationOptions.filter =
            '?f=' +
            this.paginationOptions.filter_key +
            ':eq:5' +
            (this.paginationOptions.search !== ''
              ? ',comment:lk:' + this.paginationOptions.search
              : '') +
            '&s=' +
            this.paginationOptions.sort_key +
            '&p=' +
            this.paginationOptions.page
          break
        case 'COMPLETED':
          // 1: completed
          this.paginationOptions.filter =
            '?f=' +
            this.paginationOptions.filter_key +
            ':eq:1' +
            (this.paginationOptions.search !== ''
              ? ',comment:lk:' + this.paginationOptions.search
              : '') +
            '&s=' +
            this.paginationOptions.sort_key +
            '&p=' +
            this.paginationOptions.page
          break
        case 'CANCELLED':
          // 3: canceled
          this.paginationOptions.filter =
            '?f=' +
            this.paginationOptions.filter_key +
            ':eq:3' +
            (this.paginationOptions.search !== ''
              ? ',comment:lk:' + this.paginationOptions.search
              : '') +
            '&s=' +
            this.paginationOptions.sort_key +
            '&p=' +
            this.paginationOptions.page
          break
      }
    },
    async handleRefresh(event) {
      await this.refreshOrders(this.segment)
      event.target.complete()
    },
    async searchOrders(event) {
      this.paginationOptions.search = event.target.value
      await this.refreshOrders(this.segment)
    },
    async refreshOrders(segment) {
      // init the page
      this.segment = segment
      this.playSkeletonLoader()
      this.initSelectedOrders()
      this.paginationOptions.page = 1
      this.buildFilterOptions()
      this.initOrders()
      await this.loadOrders({ pagination_options: this.paginationOptions })
      this.stopSkeletonLoader()
    },
    async displayPaginatedOrders(event) {
      this.paginationOptions.page++
      this.buildFilterOptions()
      await this.loadOrders({ pagination_options: this.paginationOptions })
      event.target.complete()
    },
    setOpenConfirmationModal(state: boolean) {
      this.isConfirmationModalOpen = state
    },
    async proceedToValidateOrders() {
      const isSuccessAuthenticated = await this.$refs.authenticationPopup.authenticate()
      if (isSuccessAuthenticated) {
        await this.onSuccessAuthenticateUser()
      }
    },
    async proceedToCancelOrders() {
      const isSuccessAuthenticated = await this.$refs.authenticationPopup.authenticate()
      if (isSuccessAuthenticated) {
        await this.onSuccessAuthenticateUser()
      }
    },
    startConfirmOrders() {
      this.operation = 'validate'
      this.setOpenConfirmationModal(true)
    },
    startCancelOrders() {
      this.operation = 'cancel'
      this.setOpenConfirmationModal(true)
    },
    async actionSheetResult(ev: CustomEvent) {
      this.setOpenConfirmationModal(false)
      if (ev.detail.data.action === 'confirm') {
        if (this.operation === 'validate') {
          await this.proceedToValidateOrders()
        }
        if (this.operation === 'cancel') {
          await this.proceedToCancelOrders()
        }
      }
    },
    async onSuccessAuthenticateUser() {
      if (this.operation === 'validate') {
        // send validation code
        const loaderModal = {
          title: this.$i18n.t('olkypay.loader.sendOrderValidationCode.title'),
          content: this.$i18n.t('olkypay.loader.please_wait')
        }
        this.playLoader(loaderModal)
        const inputData = { locale: this.currentLanguage, orderIds: this.selectedOrders }
        await TransactionService.sendOrdersValidationCode(inputData)
          .then(async res => {
            // open modal
            this.stopLoader()
            const response_modal = await this.$refs.validationCodeModal.openValidationDialog()
            if (response_modal) {
              await this.refreshOrdersBeforeManipulation()
            }
          })
          .catch(async (err: any) => {
            this.stopLoader()
            const failedDialog = {
              type: 'warning',
              title: this.$i18n.t('olkypay.notifications.error.transferPage.send_link_code.header'),
              content: this.$i18n.t(
                'olkypay.notifications.error.transferPage.send_link_code.message'
              ),
              button: this.$i18n.t('olkypay.buttons.codePinSettingsPage.close_dialog')
            }
            await this.$refs.infoModal.openModal(failedDialog)
          })
          .finally(() => this.stopLoader())
      }
      if (this.operation === 'cancel') {
        const loaderModal = {
          title: this.$i18n.t('olkypay.loader.sendOrderValidationCode.title'),
          content: this.$i18n.t('olkypay.loader.please_wait')
        }
        this.playLoader(loaderModal)
        const inputData = { locale: this.currentLanguage, order_id: this.selectedOrders[0].id }
        await TransactionService.cancelOrder(inputData)
          .then(async (res) => {
            // open modal
            this.stopLoader()
            const successDialog = {
              type: 'success',
              title: this.$i18n.t('olkypay.notifications.success.transferPage.cancel_order.header'),
              content: this.$i18n.t(
                'olkypay.notifications.success.transferPage.cancel_order.message'
              ),
              button: this.$i18n.t('olkypay.buttons.codePinSettingsPage.close_dialog')
            }
            const response_modal = await this.$refs.infoModal.openModal(successDialog)
            if (response_modal) {
              await this.refreshOrdersBeforeManipulation()
            }
          })
          .catch(async (err: any) => {
            this.stopLoader()
            const failedDialog = {
              type: 'warning',
              title: this.$i18n.t('olkypay.notifications.error.transferPage.send_link_code.header'),
              content: this.$i18n.t(
                'olkypay.notifications.error.transferPage.send_link_code.message'
              ),
              button: this.$i18n.t('olkypay.buttons.codePinSettingsPage.close_dialog')
            }
            await this.$refs.infoModal.openModal(failedDialog)
          })
          .finally(() => this.stopLoader())
      }
    },
    async refreshOrdersBeforeManipulation() {
      this.playSkeletonLoader()
      this.initSelectedOrders()
      this.initOrders()
      await this.loadOrders({ pagination_options: this.paginationOptions })
      this.stopSkeletonLoader()
    },
    gotToRouter() {
      this.$router.push({ name: 'OneTimeTransferSubTabRouter' })
    }
  },
  async mounted() {
    this.setIsInternalReAuthenticatedStatus(false)
    this.buildFilterOptions()
    this.playSkeletonLoader()
    this.initSelectedOrders()
    this.initOrders()
    await this.loadOrders({ pagination_options: this.paginationOptions })
    this.stopSkeletonLoader()
  },
  computed: {
    ...mapGetters('home', ['currentLanguage']),
    ...mapGetters('transaction', ['orders', 'selectedOrders']),
    ...mapGetters('loader', ['isSkeletonLoading']),
    ...mapGetters('auth', ['isInternalReAuthenticated']),
    groupedOrders() {
      return this.orders.reduce((result, item) => {
        const key = item.createdAt[0] + '-' + item.createdAt[1] + '-' + item.createdAt[2]
        if (!result[key]) {
          result[key] = []
        }
        result[key].push(item)
        return result
      }, {})
    }
  }
}
</script>
