import { Button } from '@/atoms/buttons/Button'
import { useCompany } from '@/features/company/hooks/use-company'
import { CompanyPermissionType } from '@/features/company/types'
import { defineJsxComponent } from '@/jsx-utils'
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export const CompanyItem = defineJsxComponent((props: any) => {
  const t = useI18n().t
  const company = props.company as any
  const { companyStore } = useCompany()
  const router = useRouter()
  return () => <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <div className="flex flex-col items-center pb-10 mt-4">
      <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
        {company.legal_name}
      </h5>
      <span className="text-sm text-gray-500 dark:text-gray-400">{company.website_url}</span>
      <div className="flex mt-4 md:mt-6">
        {company.linkNPLE.read_company_data && false && <Button
            class="mt-8 float-left"
            theme="outlined"
            icon={ArrowTopRightOnSquareIcon}
            onClick={() => {
              const permissions = {
                read_company_data: company.linkNPLE.read_company_data,
                update_company_data: company.linkNPLE.update_company_data
              } as CompanyPermissionType
              companyStore.setCurrentCompanyPermissions(permissions)
              router.push('/pass/company/' + company.identity_uuid)
            }}
          >
            {t('olkypass.companyPage.cardActions.showDetails')}
          </Button>}
      </div>
    </div>
  </div>
})
