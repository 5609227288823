import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import { Checkbox } from '@/atoms-react/input-checkbox/index.react'
import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import RadioButton from '@/atoms-react/radio-button/index.react'
import { Base } from '@/atoms-react/text/Text.react'
import { OLKY_CGU_LINK } from '@/features/utils/constants'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FlowHeader } from '../../../flow-header.react'

interface IndividualOrBusinessProps extends LayoutBaseProps {
  onValidated?: (data: EnterbusinessOutput) => void
}

export type EnterbusinessOutput = {
  signupType: SignupType
}

export enum SignupType {
  business = 'business',
  individual = 'individual'
}

export function signupTypeToString(signupType: SignupType): string {
  return signupType + ''
}

export function stringToSignupType(signupTypeString: string): SignupType {
  if (signupTypeString === SignupType.business) return SignupType.business
  if (signupTypeString === SignupType.individual) return SignupType.individual

  return SignupType.business
}

function SignupTypeSelect({ onValidated = () => {} }: IndividualOrBusinessProps) {
  const { t } = useTranslation(['pass'])
  const [signupType, setSignupType] = useState<SignupType>(SignupType.individual)
  const [checkedGDPR, setCheckedGDPR] = useState<boolean>(false)

  const linkStyle = 'font-semibold text-blue-500 cursor-pointer hover:underline'

  function execute() {
    if (!checkedGDPR) {
      toast.error(t('flow.id_control.error.tos_not_accepted'))
      return
    }

    onValidated({ signupType })
  }

  return <>
    <Column>
      <div className="grow mb-12">
        <FlowHeader
          title={t('flow.id_control.signup.label.individual_or_business_title')}
          description={t('flow.id_control.signup.label.individual_or_business_description')}
        />
        <RadioButton
          value={signupType}
          onChange={output => setSignupType(stringToSignupType(output))}
          listOfChoices={[
            { id: SignupType.business, title: t('flow.id_control.radio.select_pro_account_yes') },
            { id: SignupType.individual, title: t('flow.id_control.radio.select_pro_account_no') }
          ]}
        />
      </div>
      <div className="shrink-0">
        <div>
          <Checkbox value={checkedGDPR} onChange={value => setCheckedGDPR(value)}>
            <Base className="mb-6 cursor-pointer">
              {t('flow.id_control.actions.GDPR_accept_1')}{' '}
              <a href={OLKY_CGU_LINK} target="_blank" className={linkStyle}>
                {t('flow.id_control.actions.GDPR_accept_2')}
              </a>{' '}
              {t('flow.id_control.actions.GDPR_accept_3')}{' '}
              <a href={OLKY_CGU_LINK} target="_blank" className={linkStyle}>
                {t('flow.id_control.actions.GDPR_accept_4')}
              </a>
            </Base>
          </Checkbox>
        </div>
        <Button
          disabled={!checkedGDPR}
          shortcut="enter"
          onClick={() => execute()}
          icon={ChevronRightIcon}
        >
          {t('flow.id_control.input.continue')}
        </Button>
      </div>
    </Column>
  </>
}
export default SignupTypeSelect
