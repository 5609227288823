import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import { KYCStatusType } from '@/features/kyc/types'
import { ModalKYCIdentity } from '@/molecules/modal-kyc-identity/index.react'
import { useEffect, useState } from 'react'

export const ForcedKYCIdentityModal = () => {
  const { status ,kycAuditLevel , kycLevel} = useKYCContext()

  const [open, setOpen] = useState<boolean>()
  useEffect(() => {
    if (open === undefined && kycLevel as number < 2 &&  !kycAuditLevel ) {
      setOpen(status === KYCStatusType.DECLARATIVE)
    }
  }, [status, kycAuditLevel, kycLevel])

  return (
    <ModalKYCIdentity
      open={open}
      onClose={() => {
        sessionStorage.removeItem('DocumentKsyc')
        setOpen(false)
      }}
    />
  )
}
