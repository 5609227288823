import { Routes } from '@/features/router'
import {
  PaperAirplaneIcon,
  ArrowDownOnSquareIcon,
  ArrowUpOnSquareIcon,
  ArrowPathRoundedSquareIcon,
  ChartPieIcon,
  BeakerIcon
} from '@heroicons/react/20/solid'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { MenuItem } from './index.react'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { CardTitle, Info } from '@/atoms-react/text/Text.react'
import { useMediaQuery } from 'usehooks-ts'

type MenuWalletProps = {
  open: boolean
  onClose: () => void
} & React.ComponentPropsWithoutRef<'div'>

export const MenuWallet = ({ open, onClose, ...props }: MenuWalletProps) => {
  const { t } = useTranslation(['pass'])
  const isNotMobile = useMediaQuery('(min-width: 768px)')

  return (
    <div
      className={twMerge(
        'absolute bg-white dark:bg-slate-950 dark:border-slate-900 lg:relative border-r border-slate-50 h-full w-64 lg:w-80 shrink-0 px-4 lg:px-8 py-6',
        '-translate-x-full lg:translate-x-0 transition-all dark:',
        'overflow-auto z-50',
        open ? 'translate-x-0' : ''
      )}
      {..._.omit(props, 'className')}
    >
      <div
        className={twMerge('flex gap-x-1 items-center mb-4 cursor-pointer', isNotMobile && 'h-12')}
        onClick={onClose}
      >
        <ChevronLeftIcon className="h-5 w-5 text-black dark:text-white" />
        <CardTitle className="block">
          {t('olkypass.mainPage.navigation_menu.link.my_wallet')}
        </CardTitle>
      </div>
      {isNotMobile && <Info className="block mt-10"></Info>}
      <MenuItem to={Routes.wallet_dashboard} icon={(p) => <ChartPieIcon {...p} />}>
        {t('olkypass.mainPage.navigation_wallet_menu.link.identity')}
      </MenuItem>
      <MenuItem to={Routes.wallet_form} icon={(p) => <BeakerIcon {...p} />}>
        Forms
      </MenuItem>
      <MenuItem to={Routes.wallet_buy} icon={(p) => <ArrowDownOnSquareIcon {...p} />}>
        {t('olkypass.mainPage.navigation_wallet_menu.link.olkyWalletBuy')}
      </MenuItem>
      <MenuItem to={Routes.wallet_sell} icon={(p) => <ArrowUpOnSquareIcon {...p} />}>
        {t('olkypass.mainPage.navigation_wallet_menu.link.olkyWalletSell')}
      </MenuItem>
      <MenuItem to={Routes.wallet_convert} icon={(p) => <ArrowPathRoundedSquareIcon {...p} />}>
        {t('olkypass.mainPage.navigation_wallet_menu.link.olkyWalletConvert')}
      </MenuItem>
      <MenuItem to={Routes.wallet_transfer} icon={(p) => <PaperAirplaneIcon {...p} />}>
        {t('olkypass.mainPage.navigation_wallet_menu.link.olkyWalletTransfer')}
      </MenuItem>
    </div>
  )
}
