import { useSearchParams } from 'react-router-dom'
import { FromURL } from '../types'

export function useOrigin(): FromURL {
  const [params] = useSearchParams()

  const origins: { [key: string]: FromURL } = {
    olkypay_np: FromURL.OlkyPayNP,
    olkypay_le: FromURL.OlkyPayLE,
    olkywallet: FromURL.Wallet,
    kypay_partner: FromURL.Partner
  }

  const origin = params.has('origin') ? params.get('origin') : null

  if (!origin || origins[origin] === undefined) {
    return FromURL.None
  }
  return origins[origin]
}
