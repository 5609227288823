import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import InputLabel from '@/atoms-react/input/Input.react'
import Tag from '@/atoms-react/tag/Tag.react'
import { Base, CardTitle, SectionTitle } from '@/atoms-react/text/Text.react'
import { useUser } from '@/features/user/hooks/use-user'
import React, { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../../../_layout/index.react'
import { Themes } from '@/types/theme'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { EmailCard } from '@/molecules/email-card/index.react'
import { Routes } from '@/features/router'
import { useNavigate } from 'react-router-dom'
import { ModalDeleteAlias } from '@/molecules/modal-delete-alias/index.react'
import { AliasEmail } from '@/features/user/types'
import { useDeleteAlias } from '@/features/user/hooks/use-delete-alias'
import { NaturalEntityDataType } from '@/features/utils/types'
import { Modal, ModalContent } from '@/atoms-react/modal/index.react'
import CodeInput from '@/atoms-react/input/CodeInput.react'
import toast from 'react-hot-toast'
import { validateEmailFormat } from '@/features/utils/functions'
import { useCreateEmail } from '@/features/user/email/hooks/use-create-email'
import useRequestSensitiveEmailMutation from '@/features/security/hooks/use-request-sensitive-email-code.ts'
import useValidateSensitiveEmailMutation from '@/features/security/hooks/use-validate-sensitive-email-code.ts'

type AliasesProps = React.ComponentPropsWithoutRef<'div'>

export const AliasesEmailApp = ({ ...props }: AliasesProps) => {
  const { t } = useTranslation(['pass'])
  const { useUserProfile } = useUser()
  const { data: user, refetch } = useUserProfile()
  const [newEmail, setNewEmail] = useState<string>()
  const [uuidSensitiveActionValidation, setUuidSensitiveActionValidation] = useState<string>()
  const { mutateAsync: sendCode, isPending: isSendingCode } = useRequestSensitiveEmailMutation()
  const { mutateAsync: validateEmailCode, isPending: isPendingValidateEmail } =
    useValidateSensitiveEmailMutation()
  const { useCreateEmailMutation, useCheckEmailMutation } = useCreateEmail()
  const { mutateAsync: checkAlias, isPending: isCheckingAlias } = useCheckEmailMutation()
  const { mutateAsync: createAlias } = useCreateEmailMutation()
  const [validationMailModal, setValidationMailModal] = useState<boolean>(false)
  const [deletedEmail, setDeletedEmail] = useState<AliasEmail | null>(null)
  const { mutateAsync: deleteAlias } = useDeleteAlias()

  const navigate = useNavigate()
  const actionType = 'new_alias_mail'
  const onNewEmail = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!newEmail) {
      return
    }
    const validEmail = validateEmailFormat(newEmail)
    if (!validEmail) {
      toast.error(t('flow.id_control.error.email_invalid'))
      return
    }
    try {
      await checkAlias(newEmail)
      const { data } = await sendCode({ email: newEmail, actionType })
      setUuidSensitiveActionValidation(data?.uuid)
      setValidationMailModal(true)
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  const emails = user?.naturalPerson?.AliasEmail

  const onDelete = async () => {
    if (!deletedEmail) {
      return
    }
    try {
      await deleteAlias({
        ItemType: NaturalEntityDataType.AliasMailDto,
        ItemUUID: deletedEmail.UUID || '',
        UUID: user?.naturalPerson?.UUID || ''
      })
    } catch (e: any) {
      toast.error(t('olkypass.general.error_occured'))
    }

    await refetch()
    setDeletedEmail(null)
  }

  return (
    <Column {...props}>
      <LayoutTitle>{t('aliasEmailPage.title')}</LayoutTitle>
      <div className="2xl:w-2/3">
        <div className="md:border-2 md:border-slate-50 dark:md:border-2 dark:md:border-slate-800 md:rounded-sm md:py-4 md:px-6 md:mb-10 mb-8">
          <div className="flex flex-wrap-reverse items-center md:flex-wrap gap-x-3 mb-2 md:mb-4 justify-center md:justify-start w-full">
            {emails && emails.length > 0 && (
              <CardTitle className="font-semibold truncate">{emails[0]?.Mail}</CardTitle>
            )}
            <Tag className="mb-2 md:mb-0">
              {t('olkypass.accountPage.emails_section.badge.main_mail')}
            </Tag>
          </div>
          <Base>{t('olkypass.accountPage.emails_section.text.main_mail_text')}</Base>
        </div>

        <SectionTitle className="mb-4 md:mb-6">
          {t('olkypass.accountPage.emails_section.section.other_mails_section')}
        </SectionTitle>
        <div>
          <form onSubmit={onNewEmail} className="md:flex gap-x-3 mb-4">
            <InputLabel
              placeholder="email@gmail.com"
              value={newEmail}
              onChange={(value: string) => setNewEmail(value)}
              className="flex-1 mb-1 md:mb-0"
            />
            <Button
              onClick={() => {}}
              type="submit"
              disabled={!newEmail}
              theme={Themes.white}
              icon={PlusCircleIcon}
              loading={isSendingCode || isCheckingAlias}
              className="md:w-fit"
            >
              {t('olkypass.general.add')}
            </Button>
          </form>
        </div>
        <ModalDeleteAlias
          isOpen={!!deletedEmail}
          onClose={() => setDeletedEmail(null)}
          onValidated={onDelete}
        >
          <Base>{t('aliasEmailPage.confirm_delete', { name: deletedEmail?.Mail })}</Base>
        </ModalDeleteAlias>
        <div className="w-full h-full">
          {emails &&
            emails
              .slice(1)
              .map((email, index) => (
                <EmailCard
                  key={index}
                  email={email}
                  onPublicClick={() => navigate(Routes.account_alias_public)}
                  onDelete={() => setDeletedEmail(email)}
                />
              ))}
        </div>
      </div>
      <Modal
        maxWidthClassName="max-w-2xl"
        open={validationMailModal}
        onClose={() => setValidationMailModal(false)}
      >
        <ModalContent title={t('flow.id_control.signup.label.verify_email_title')}>
          <div className="flex flex-col items-center gap-4 mt-4">
            <div>
              {t('flow.id_control.signup.label.verify_email_description_1')}{' '}
              <Base className={'inline font-semibold'}>{newEmail ?? ''}</Base>
              {'.'}
            </div>
            <CodeInput
              codeNumberOfCharacters={6}
              className={'mb-6'}
              focus={true}
              onChange={(code) =>
                validateEmailCode(
                  { uuidSensitiveActionValidation, code, actionType },
                  {
                    onSuccess: async () => {
                      try {
                        await createAlias({ email: newEmail })
                        toast.success(t('olkypass.accountPage.emails_section.text.email_added'))
                        setValidationMailModal(false)
                        refetch()
                        setNewEmail('')
                      } catch (e) {
                        toast.error(t('olkypass.accountPage.emails_section.text.wrong_otp'))
                        setValidationMailModal(false)
                      }
                    },
                    onError: () =>
                      toast.error(t('olkypass.accountPage.emails_section.text.wrong_otp'))
                  }
                )
              }
              loading={isPendingValidateEmail}
            />
          </div>
        </ModalContent>
      </Modal>
    </Column>
  )
}
