import AnimatedHeight from '@/atoms/animated-height/AnimatedHeight.vue'
import Logo from '@/components/logo/Logo.vue'
import { defineVueComponent } from '@/jsx-utils'

type Props = {
  logo?: boolean
  animated?: boolean
  class?: string
}

export default defineVueComponent((props: Props, { slots }: any) => () => (
  <div class="min-h-full max-h-dvh overflow-auto">
    <div class="min-h-dvh flex flex-col justify-end sm:justify-center py-0 bg-transparent sm:pb-4 pt-8">
      <Logo
        v-if="props.logo"
        init
        class={'mx-auto h-14 w-auto ' + (props.animated ? 'animated' : '')}
      />
      <div
        style={{ zIndex: 1 }}
        class={
          'mt-0 mb-0 h-full sm:mb-12 sm:mt-8 mt-4 sm:mx-auto sm:w-full sm:max-w-md bg-white dark:bg-slate-900 shadow-md dark:shadow-dark-md rounded-t-md sm:rounded-md ' +
          (props.animated ? 'animate__animated animate__fadeIn animate__faster ' : '') +
          props.class
        }
      >
        <AnimatedHeight>
          <div class="pt-8 pb-6 px-4 sm:pb-8 pb-12 sm: sm:px-10">{slots.default?.()}</div>
        </AnimatedHeight>
      </div>
    </div>
  </div>
))
