import { queryClient } from '@/app/olkypass/ReactApp.react'
import { useQuery } from '@tanstack/react-query'
import { twMerge } from 'tailwind-merge'

type AnimationState = 'back' | 'forward' | 'forward_ready' | 'back_ready' | null
let animationState: AnimationState = null
let overflowState = false
let locked = false

export async function animatedNext<T>(
  direction: 'forward' | 'back' = 'forward',
  callback?: () => Promise<T>
) {
  if (locked) return callback?.()
  locked = true

  // Start animation
  animationState = direction
  overflowState = true
  queryClient.invalidateQueries({
    queryKey: ['form-animation']
  })

  await new Promise(resolve => setTimeout(resolve, 300))

  try {
    return await callback?.()
  } finally {
    // End animation
    animationState = direction === 'forward' ? 'forward_ready' : 'back_ready'
    queryClient.invalidateQueries({
      queryKey: ['form-animation']
    })

    setTimeout(() => {
      // End animation
      animationState = null
      queryClient.invalidateQueries({
        queryKey: ['form-animation']
      })

      setTimeout(() => {
        // End animation
        overflowState = false
        queryClient.invalidateQueries({
          queryKey: ['form-animation']
        })
        locked = false
      }, 300)
    }, 50)
  }
}

export const AnimatedForm = ({
  children,
  className
}: {
  children?: React.ReactNode
  className?: string
}) => {
  const state = useQuery<{ state: AnimationState; overflow: boolean }>({
    queryKey: ['form-animation'],
    queryFn: () => ({ state: animationState, overflow: overflowState })
  })

  const values = {
    back: '100%',
    back_ready: '0%',
    forward: '-100%',
    forward_ready: '0%'
  } as any

  return <div
    className="h-full w-full flex 2xl:items-center"
    style={{
      overflow: state.data?.overflow ? 'hidden' : ''
    }}
  >
    <div
      className={twMerge(className)}
      style={{
        transition: ['forward_ready', 'back_ready'].includes(state.data?.state as any)
          ? ''
          : 'all 300ms',
        pointerEvents: state.data?.state ? 'none' : 'auto',
        opacity: state.data?.state ? 0 : 1,
        transform: state.data
          ? `translateX(${values[state.data?.state || ''] || '0'})`
          : `translateX(0)`
      }}
    >
      {children}
    </div>
  </div>
}
