<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex justify-start">
        <v-btn
          variant="text"
          color="primary"
          height="auto"
          class="text-none textBtn"
          width="auto"
          prepend-icon="mdi-bank-plus"
          size="medium"
          @click="openFormDialog('iban')"
        >
          <template #prepend>
            <v-icon class="mx-1 cercle" size="20"></v-icon>
          </template>
          {{ $t('olkypass.accountPage.ibans_section.button.addIBAN') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-start">
      <IbanChild
        v-for="(iban, index) in naturalPerson.AliasIBAN"
        ref="ibanChild"
        :iban="iban"
        :in-memory-iban="inMemoryNP.AliasIBAN[index]"
      ></IbanChild>
    </v-row>
    <IbanFormDialog ref="ibanFormDialog"></IbanFormDialog>
    <DialogConfirm ref="dialogConfirm"></DialogConfirm>
  </v-container>
</template>

<script>
// import UniformPanels from "../Common/UniformPanels.vue";
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import ToolsMixins from '../../mixins/ToolsMixins.js'
import { mapGetters } from '../../store'
import store from '../../store'
import IbanChild from '../Common/IbanChild.vue'
import IbanFormDialog from '../FormsDialog/IbanFormDialog.vue'
import DialogConfirm from '../DialogConfirm.vue'

export default {
  name: 'PaymentsTab',
  components: {
    IbanChild,
    IbanFormDialog,
    DialogConfirm
  },
  mixins: [NotificationsMixins, ToolsMixins],
  data: () => ({}),
  methods: {
    openFormDialog(section) {
      this.$refs.ibanFormDialog.open()
    },
    async savePartialData(section) {
      const setPartialDataFunctionName =
        this.currentTreatedEntity === 'company' ? 'setCompanyPartialData' : 'setNPPartialData'
      if (
        await this.$refs.dialogConfirm.open(
          'accountPage.dialog.confirm.update.infos.header',
          'accountPage.dialog.confirm.update.infos.body'
        )
      ) {
        store.dispatch('playLoader')
        await store.dispatch(setPartialDataFunctionName, {
          UUID: this.naturalPerson.UUID,
          AliasIBAN: this.naturalPerson.AliasIBAN
        })
        if (this.currentTreatedEntity === 'company') {
          await store.dispatch('setCurrentCompany', {
            UUID: this.naturalPerson.UUID
          })
        } else {
          await store.dispatch('setCurrentNaturalPerson')
        }
        store.dispatch('stopLoader')
      }
    }
  },
  computed: {
    ...mapGetters([
      'wantToLeavePage',
      'currentLanguage',
      'hasUpdatePermission',
      'naturalPerson',
      'inMemoryNP'
    ])
  }
}
</script>

<style scoped></style>
