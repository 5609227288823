<template>
  <ion-grid class="mx-2 mb-2">
    <ion-row>
      <ion-col size="1" class="text-center mt-1 text-gray">
        <ion-icon :icon="personCircle" size="small"></ion-icon>
      </ion-col>
      <ion-col>
        <h4 class="mt-1 mb-2 text-gray font-weight-bold">
          {{ $t('olkypay.labels.beneficiariesPage.formItems.identity') }}
        </h4>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-toggle
          v-model="currentBeneficiary.isCompany"
          label-placement="end"
          class="text-md"
          :checked="currentBeneficiary.isCompany"
          >{{ $t('olkypay.labels.beneficiariesPage.formItems.is_professional') }}</ion-toggle
        >
      </ion-col>
    </ion-row>
    <div v-if="currentBeneficiary.isCompany" class="for-company-info">
      <ion-row class="mt-2">
        <ion-col :class="currentBeneficiary.fullName ? 'text-dark' : 'text-gray'">
          <ion-input
            v-model="currentBeneficiary.fullName"
            :label="$t('olkypay.labels.beneficiariesPage.formItems.company.name')"
            label-placement="floating"
            :clear-input="true"
            fill="outline"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="mt-2">
        <ion-col :class="currentBeneficiary.vat ? 'text-dark' : 'text-gray'">
          <ion-input
            v-model="currentBeneficiary.vat"
            :label="$t('olkypay.labels.beneficiariesPage.formItems.company.vat_number')"
            label-placement="floating"
            :clear-input="true"
            fill="outline"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="mt-2">
        <ion-col :class="currentBeneficiary.rcs ? 'text-dark' : 'text-gray'">
          <ion-input
            v-model="currentBeneficiary.rcs"
            :label="$t('olkypay.labels.beneficiariesPage.formItems.company.rcs_number')"
            label-placement="floating"
            :clear-input="true"
            fill="outline"
          ></ion-input>
        </ion-col>
      </ion-row>
    </div>
    <div v-else>
      <ion-row class="mt-2">
        <ion-col>
          <ion-label class="text-md">{{
            $t('olkypay.labels.beneficiariesPage.formItems.civility')
          }}</ion-label>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-radio-group v-model="currentBeneficiary.gender" :value="currentBeneficiary.gender">
            <ion-radio value="MME" alignment="start" justify="space-between" class="text-md">{{
              $t('olkypay.labels.beneficiariesPage.formItems.mrs')
            }}</ion-radio>
            <ion-radio value="Mr" alignment="start" justify="space-between" class="text-md">{{
              $t('olkypay.labels.beneficiariesPage.formItems.mr')
            }}</ion-radio>
          </ion-radio-group>
        </ion-col>
      </ion-row>
      <ion-row class="mt-2">
        <ion-col :class="currentBeneficiary.lastName ? 'text-dark' : 'text-gray'">
          <ion-input
            v-model="currentBeneficiary.lastName"
            :label="$t('olkypay.labels.beneficiariesPage.formItems.name')"
            label-placement="floating"
            :clear-input="true"
            fill="outline"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="mt-2">
        <ion-col :class="currentBeneficiary.firstName ? 'text-dark' : 'text-gray'">
          <ion-input
            v-model="currentBeneficiary.firstName"
            :label="$t('olkypay.labels.beneficiariesPage.formItems.first_name')"
            label-placement="floating"
            :clear-input="true"
            fill="outline"
          ></ion-input>
        </ion-col>
      </ion-row>
    </div>
    <hr class="dark horizontal mt-4" />
    <ion-row>
      <ion-col size="1" class="text-center mt-1 text-gray">
        <ion-icon :icon="homeSharp" size="small"></ion-icon>
      </ion-col>
      <ion-col>
        <h4 class="mt-1 mb-2 text-gray font-weight-bold">
          {{ $t('olkypay.labels.beneficiariesPage.formItems.address') }}
        </h4>
      </ion-col>
    </ion-row>

    <ion-row class="mt-2">
      <ion-col :class="currentBeneficiary.address1 ? 'text-dark' : 'text-gray'">
        <ion-input
          v-model="currentBeneficiary.address1"
          :label="$t('olkypay.labels.beneficiariesPage.formItems.address_1')"
          label-placement="floating"
          :clear-input="true"
          fill="outline"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="mt-2">
      <ion-col :class="currentBeneficiary.address2 ? 'text-dark' : 'text-gray'">
        <ion-input
          v-model="currentBeneficiary.address2"
          :label="$t('olkypay.labels.beneficiariesPage.formItems.address_2')"
          label-placement="floating"
          :clear-input="true"
          fill="outline"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="mt-2">
      <ion-col :class="currentBeneficiary.address3 ? 'text-dark' : 'text-gray'">
        <ion-input
          v-model="currentBeneficiary.address3"
          :label="$t('olkypay.labels.beneficiariesPage.formItems.address_3')"
          label-placement="floating"
          :clear-input="true"
          fill="outline"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="mt-2">
      <ion-col :class="currentBeneficiary.zip ? 'text-dark' : 'text-gray'">
        <ion-input
          v-model="currentBeneficiary.zip"
          :label="$t('olkypay.labels.beneficiariesPage.formItems.zip_code')"
          label-placement="floating"
          :clear-input="true"
          fill="outline"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="mt-2">
      <ion-col :class="currentBeneficiary.city ? 'text-dark' : 'text-gray'">
        <ion-input
          v-model="currentBeneficiary.city"
          :label="$t('olkypay.labels.beneficiariesPage.formItems.city')"
          label-placement="floating"
          :clear-input="true"
          fill="outline"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="border-row border-1 border-radius-md min-height-56 mx-1 mt-3">
      <!--          <ion-col size="8" class="mt-3">
            <label class="mt-1 mx-2 text-gray">{{ $t('olkypay.labels.beneficiariesPage.formItems.country') }}</label>
          </ion-col>-->
      <ion-col class="mb-0 mx-2">
        <ion-select
          v-model="currentBeneficiary.country"
          interface="popover"
          :value="currentBeneficiary.country"
          :placeholder="$t('olkypay.labels.beneficiariesPage.formItems.country')"
          :label="$t('olkypay.labels.beneficiariesPage.formItems.country')"
          label-placement="floating"
        >
          <ion-select-option v-for="country in countries" :value="country.alpha2">{{
            country.defaultName
          }}</ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
    <hr class="dark horizontal mt-4" />
    <ion-row>
      <ion-col size="1" class="text-center mt-1 text-gray">
        <ion-icon :icon="settingsSharp" size="small"></ion-icon>
      </ion-col>
      <ion-col>
        <h4 class="mt-1 mb-2 text-gray font-weight-bold">
          {{ $t('olkypay.labels.beneficiariesPage.formItems.additional_options') }}
        </h4>
      </ion-col>
    </ion-row>
    <ion-row class="mt-2">
      <ion-col class="text-gray">
        <ion-input
          :label="$t('olkypay.labels.beneficiariesPage.formItems.external_account')"
          label-placement="floating"
          :clear-input="true"
          fill="outline"
        ></ion-input>
      </ion-col>
    </ion-row>
    <hr class="dark horizontal mt-4" />
    <ion-row>
      <ion-col size="1" class="text-center mt-1 text-gray">
        <ion-icon :icon="documentTextSharp" size="small"></ion-icon>
      </ion-col>
      <ion-col>
        <h4 class="mt-1 mb-2 text-gray font-weight-bold">
          {{ $t('olkypay.labels.beneficiariesPage.formItems.additional_information') }}
        </h4>
      </ion-col>
    </ion-row>
    <ion-row class="mt-2">
      <ion-col :class="currentBeneficiary.mobile ? 'text-dark' : 'text-gray'">
        <ion-input
          v-model="currentBeneficiary.mobile"
          :label="$t('olkypay.labels.beneficiariesPage.formItems.phone_number')"
          label-placement="floating"
          :clear-input="true"
          fill="outline"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="mt-2">
      <ion-col :class="currentBeneficiary.phone ? 'text-dark' : 'text-gray'">
        <ion-input
          v-model="currentBeneficiary.phone"
          :label="$t('olkypay.labels.beneficiariesPage.formItems.phone_fix')"
          label-placement="floating"
          :clear-input="true"
          fill="outline"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="mt-2">
      <ion-col :class="currentBeneficiary.email ? 'text-dark' : 'text-gray'">
        <ion-input
          v-model="currentBeneficiary.email"
          :label="$t('olkypay.labels.beneficiariesPage.formItems.email')"
          label-placement="floating"
          :clear-input="true"
          fill="outline"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row
      v-if="!currentBeneficiary.isCompany"
      class="border-row border-1 border-radius-md min-height-56 mx-1 mt-3"
    >
      <ion-col size="6" class="mt-3">
        <label class="mt-3 mx-2 text-gray">{{
          $t('olkypay.labels.beneficiariesPage.formItems.birthday')
        }}</label>
      </ion-col>
      <ion-col class="mb-2">
        <ion-datetime-button
          datetime="datetime"
          display-time="false"
          class="mt-2"
        ></ion-datetime-button>
        <ion-modal class="okypay-modal date-modal" :keep-contents-mounted="true">
          <ion-datetime
            id="datetime"
            ref="datetime"
            v-model="birthdayISOFormat"
            presentation="date"
            @ionChange="onDatetimeChange($event)"
          >
          </ion-datetime>
        </ion-modal>
      </ion-col>
    </ion-row>
    <!--        <ion-row class="mt-2">
          <ion-col class="text-gray">
            <ion-input
                :label="$t('olkypay.labels.beneficiariesPage.formItems.choice_holder')" label-placement="floating"
                :clear-input="true" fill="outline"></ion-input>
          </ion-col>
        </ion-row>-->
  </ion-grid>
</template>

<script lang="ts">
import { BeneficiaryService } from '../../services/beneficiary.service'
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonButton,
  IonBackButton,
  IonButtons,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSpinner,
  IonSearchbar,
  IonFab,
  IonFabButton,
  IonFabList,
  IonActionSheet,
  alertController,
  IonToggle,
  IonRadio,
  IonRadioGroup,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonInput,
  IonSelect,
  IonSelectOption
} from '@ionic/vue'
import {
  caretBackSharp,
  personCircle,
  homeOutline,
  homeSharp,
  settingsSharp,
  documentTextSharp,
  informationSharp
} from 'ionicons/icons'
import { mapGetters } from 'vuex'
export default {
  name: 'BeneficiaryForm',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonLabel,
    IonButton,
    IonBackButton,
    IonButtons,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSpinner,
    IonSearchbar,
    IonFab,
    IonFabButton,
    IonFabList,
    IonActionSheet,
    alertController,
    IonToggle,
    IonRadio,
    IonRadioGroup,
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    IonInput,
    IonSelect,
    IonSelectOption
  },
  data() {
    return {
      caretBackSharp,
      personCircle,
      homeOutline,
      homeSharp,
      settingsSharp,
      documentTextSharp,
      informationSharp,
      birthdayISOFormat: '',
      countries: []
    }
  },
  async mounted() {
    if (this.currentBeneficiary.birthDayDate) {
      const birthday = this.currentBeneficiary.birthDayDate.join('-')
      this.birthdayISOFormat = new Date(birthday).toISOString()
    } else {
      this.birthdayISOFormat = new Date().toISOString()
    }
    this.countries = await BeneficiaryService.getAllCountries()
  },
  methods: {
    onDatetimeChange(event) {
      const selectedDate = event.detail.value
      const selectedBirthday = selectedDate.substring(0, 10)
      this.currentBeneficiary.birthDayDate = selectedBirthday.split('-').map(Number)
    }
  },
  computed: {
    ...mapGetters('beneficiary', ['currentBeneficiary'])
  }
}
</script>

<style scoped></style>
