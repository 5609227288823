<template>
  <v-row>
    <v-col>
      <v-img class="mx-auto informationStep1" :src="informationStep1"></v-img>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="informationTitle">
      {{ $t('olkypass.accountPage.dialog.identity.step1Title') }}
    </v-col>
  </v-row>
  <v-row>
    <v-col class="pa-2 ma-2 informationDesc">
      <p v-html="$t('olkypass.accountPage.dialog.identity.step1Content')"></p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'WelcomeKYCIdentity',
  data() {
    return {
      informationStep1: '/images/olkypass/informationStep1.png'
    }
  }
}
</script>
