import { WebAuthnApi } from '@/features/auth/api-client/webauthn-api-client'
import { AuthenticateWebAuthnResponse } from '@/features/auth/types'
import { UseMutationOptions, useMutation } from '@tanstack/react-query'

export default function useAuthenticateWebAuthnMutation(
  options?: UseMutationOptions<AuthenticateWebAuthnResponse | null | undefined, Error, string>
) {
  return useMutation<AuthenticateWebAuthnResponse | null | undefined, Error, string>({
    mutationFn: async (email: string) => WebAuthnApi.authenticateWebAuthnUser(email),
    ...options
  })
}
