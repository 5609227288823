<template>
  <div class="header-login">
    <ion-grid>
      <ion-row class="ion-justify-content-center mt-4">
        <ion-col size="auto">
          <ion-img :src="olkyPayLogoWhite" alt="olky-logo" class="ion-margin-top"> </ion-img>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center header-title">
        <ion-col size="auto">
          <h1>{{ title }}</h1>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script lang="ts">
import olkyPayLogoWhite from '../../../../../public/images/olkypay/logo-olkypay-header.png'
import {
  IonHeader,
  IonContent,
  IonPage,
  IonBackButton,
  IonButton,
  IonButtons,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonImg,
  IonGrid,
  IonCheckbox
} from '@ionic/vue'
export default {
  name: 'LoginHeader',
  components: {
    IonHeader,
    IonContent,
    IonPage,
    IonBackButton,
    IonButton,
    IonButtons,
    IonIcon,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonImg,
    IonGrid,
    IonCheckbox
  },
  props: ['title'],
  computed: {
    olkyPayLogoWhite() {
      return olkyPayLogoWhite
    }
  }
}
</script>

<style scoped></style>
