<template>
  <ion-page id="AuthenticationSettingsCardPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button :icon="caretBackSharp" default-href="/pay/supplier/more"></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1>{{ $t('olkypay.labels.authenticationSettingsPage.header') }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <ion-grid class="mx-2">
        <ion-row class="mt-3">
          <ion-col class="text-center">
            <ion-icon :icon="lockClosed" color="blue_olky" size="large"></ion-icon>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <h1 class="text-center">
              {{ $t('olkypay.labels.authenticationSettingsPage.sub_header') }}
            </h1>
          </ion-col>
        </ion-row>
        <ion-item></ion-item>
        <ion-item class="mt-2">
          <ion-row class="mb-3">
            <ion-col class="mt-4" size="2">
              <ion-icon :icon="fingerPrint" color="blue_olky" size="large"></ion-icon>
            </ion-col>
            <ion-col size="8">
              <h2>{{ $t('olkypay.labels.authenticationSettingsPage.fingerprint.title') }}</h2>
              <span>{{
                $t('olkypay.labels.authenticationSettingsPage.fingerprint.description')
              }}</span>
            </ion-col>
            <ion-col size="2">
              <ion-toggle
                :checked="user_settings.authentication_method === 'finger_print'"
                value="finger_print"
                @ionChange="handleToggleClick"
              ></ion-toggle>
            </ion-col>
          </ion-row>
        </ion-item>
        <ion-item class="mt-2">
          <ion-row class="mb-3">
            <ion-col class="mt-4" size="2">
              <ion-icon :icon="personCircle" color="blue_olky" size="large"></ion-icon>
            </ion-col>
            <ion-col size="8">
              <h2>{{ $t('olkypay.labels.authenticationSettingsPage.faceId.title') }}</h2>
              <span>{{ $t('olkypay.labels.authenticationSettingsPage.faceId.description') }}</span>
            </ion-col>
            <ion-col size="2">
              <ion-toggle
                :checked="user_settings.authentication_method === 'face_id'"
                value="face_id"
                @ionChange="handleToggleClick"
              ></ion-toggle>
            </ion-col>
          </ion-row>
        </ion-item>
        <ion-item class="mt-2">
          <ion-row class="mb-3">
            <ion-col class="mt-4" size="2">
              <ion-icon :icon="lockClosed" color="blue_olky" size="large"></ion-icon>
            </ion-col>
            <ion-col size="8">
              <h2>{{ $t('olkypay.labels.authenticationSettingsPage.codePin.title') }}</h2>
              <span>{{ $t('olkypay.labels.authenticationSettingsPage.codePin.description') }}</span>
              <br />
              <a
                v-if="user_settings.security_infos.code_pin"
                class="mt-2"
                href="#"
                @click.prevent="goToRouter('MoreAuthSettingPinBoardingsSubTabRouter')"
                >{{ $t('olkypay.labels.authenticationSettingsPage.codePin.changeLinker') }}</a
              >
            </ion-col>
            <ion-col size="2">
              <ion-toggle
                :checked="user_settings.authentication_method === 'code_pin'"
                value="code_pin"
                @ionChange="handleToggleClick"
              ></ion-toggle>
            </ion-col>
          </ion-row>
        </ion-item>
        <ion-item class="mt-2">
          <ion-row class="mb-3">
            <ion-col class="mt-4" size="2">
              <ion-icon :icon="keypad" color="blue_olky" size="large"></ion-icon>
            </ion-col>
            <ion-col size="8">
              <h2>{{ $t('olkypay.labels.authenticationSettingsPage.webAuthn.title') }}</h2>
              <span>{{
                $t('olkypay.labels.authenticationSettingsPage.webAuthn.description')
              }}</span>
            </ion-col>
            <ion-col size="2">
              <ion-toggle
                :checked="user_settings.authentication_method === 'web_authn'"
                value="web_authn"
                @ionChange="handleToggleClick"
              ></ion-toggle>
            </ion-col>
          </ion-row>
        </ion-item>
      </ion-grid>
      <biometric-verification-process
        ref="biometricVerificationProcess"
      ></biometric-verification-process>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import VOtpInput from 'vue3-otp-input'
import BiometricVerificationProcess from '../../../components/security/biometricVerificationProcess.vue'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToggle,
  IonToolbar
} from '@ionic/vue'
import { caretBackSharp, fingerPrint, keypad, lockClosed, personCircle } from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'
import { WebAuthnRegisterApi } from '@/features/auth/api-client/webauthn-register-api-client'
import { useToast } from 'vue-toast-notification'
import { Device } from '@capacitor/device'
import { Capacitor } from '@capacitor/core'

export default {
  name: 'AuthenticationSettingsPage',
  components: {
    IonHeader,
    IonContent,
    IonPage,
    IonBackButton,
    IonButton,
    IonButtons,
    IonIcon,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonImg,
    IonGrid,
    IonSelect,
    IonSelectOption,
    IonInput,
    VOtpInput,
    IonItem,
    IonToggle,
    BiometricVerificationProcess
  },
  data() {
    return {
      caretBackSharp,
      fingerPrint,
      lockClosed,
      personCircle,
      keypad
    }
  },
  methods: {
    ...mapActions('user', ['setAuthenticationMethod', 'setSecurityInfos']),
    goToRouter(router_name) {
      this.$router.push({ name: router_name })
    },
    async handleToggleClick(event) {
      const toast = useToast()
      if (event.detail.checked) {
        const selected_option = event.detail.value
        if (selected_option === 'web_authn') {
          if (!this.user_settings.security_infos.web_authn_activated) {
            // execute web authn
            /* let device_name = window.location.hostname;
            if (Capacitor.isNativePlatform()) {
              device_name = (await Device.getInfo()).name
            } */
            const device_name = 'TODO_CHANGE_ME'
            const challenge = await WebAuthnRegisterApi.registerWebAuthnUser(device_name)
            if (challenge) {
              toast.success(this.$i18n.t('olkypay.notifications.success.webAuthn.registerUser'))
              this.setAuthenticationMethod(event.detail.value)
            } else {
              this.setAuthenticationMethod(null)
            }
          } else {
            this.setAuthenticationMethod(event.detail.value)
          }
        } else if (selected_option === 'code_pin') {
          if (!this.user_settings.security_infos.code_pin) {
            this.$router.push({ name: 'MoreAuthSettingPinBoardingsSubTabRouter' })
          } else {
            this.setAuthenticationMethod(event.detail.value)
          }
        } else {
          await this.$refs.biometricVerificationProcess.tryToSaveFingerPrint()
          this.setAuthenticationMethod(event.detail.value)
        }
      } else {
        this.setAuthenticationMethod(null)
      }
    }
  },
  computed: {
    ...mapGetters('user', ['user_settings'])
  }
}
</script>
../../../../../features/utils/storage.service.ts
