import { SignupState } from '@/app/olkypass/flow/components/gateways/signup/index.react'
import _ from 'lodash'
import { NaturalPerson, StaticData } from './types'
import { Gender } from '../auth/types'

export const factory_naturalPerson_edit = (
  naturalPerson: NaturalPerson,
  signupState: SignupState,
  staticData: StaticData
): NaturalPerson => ({
  ...naturalPerson,
  OtherFirstNames: signupState?.identity?.person?.otherfirstnames ?? '',
  Gender: (signupState.identity?.person?.gender as Gender) || null,
  DateOfBirth: {
    date: new Intl.DateTimeFormat('en-US').format(
      signupState.identity?.addressBirth?.birth ?? new Date()
    ),
    timezone_type: 3,
    timezone: 'UTC'
  },
  NIF: '',
  CountryOfCitizenship:
    staticData.countries.find(
      (country) =>
        country.alpha2.toLowerCase() ===
        (_.first(signupState.identity?.person?.nationalityCodes) ?? '').toLowerCase()
    )?.id ?? -1,
  countryOfBirth: parseInt(signupState.identity?.addressBirth?.countryCode ?? '-1'),
  Birthplace: signupState.identity?.addressBirth?.countryCode ?? '-1',
  StateOfBirth: signupState.identity?.addressBirth?.cityOfBirth ?? '',
  Address: [],
  AliasMobile: [],
  ActivityCode: parseInt(signupState?.identity?.occupation?.activity ?? '') ?? -1,
  CSPCode: signupState?.identity?.occupation?.CSPcode ?? -1,
  maximumMonthlyIncome: signupState?.identity?.monthlyIncome?.maximum,
  minimumMonthlyIncome: signupState?.identity?.monthlyIncome?.minimum
})
export const factory_naturalPerson_partial_edit = (
  naturalPerson: NaturalPerson,
  signupState: SignupState,
  staticData: StaticData
) => {
  const personData: Partial<NaturalPerson> = {
    ...naturalPerson,
    Address: [],
    AliasMobile: []
  }

  const gender = signupState.identity?.person?.gender
  if (gender && !naturalPerson.Gender) {
    personData.Gender = gender
  }

  const dateOfBirth = signupState.identity?.addressBirth?.birth
  if (dateOfBirth && !naturalPerson.DateOfBirth) {
    personData.DateOfBirth = {
      date: new Intl.DateTimeFormat('en-US').format(dateOfBirth),
      timezone_type: 3,
      timezone: 'UTC'
    }
  }

  const countryOfCitizenship = signupState.identity?.person?.nationalityCodes
  if (countryOfCitizenship?.length > 0) {
    personData.CountryOfCitizenship =
      staticData.countries.find(
        (country) =>
          country.alpha2.toLowerCase() === (_.first(countryOfCitizenship) ?? '').toLowerCase()
      )?.id ?? -1
  }

  const countryOfBirth = signupState.identity?.addressBirth?.countryCode
  if (countryOfBirth && !naturalPerson.countryOfBirth) {
    personData.countryOfBirth = parseInt(countryOfBirth)
  }

  const stateOfBirth = signupState.identity?.addressBirth?.cityOfBirth
  if (stateOfBirth && !naturalPerson.StateOfBirth) {
    personData.StateOfBirth = stateOfBirth
  }

  const occupation = signupState?.identity?.occupation?.activity
  if (occupation) {
    personData.ActivityCode = parseInt(occupation)
  }

  const cspCode = signupState?.identity?.occupation?.CSPcode
  if (cspCode) {
    personData.CSPCode = cspCode
  }

  const maximumMonthlyIncome = signupState?.identity?.monthlyIncome?.maximum
  if (maximumMonthlyIncome) {
    personData.maximumMonthlyIncome = maximumMonthlyIncome
  }

  const minimumMonthlyIncome = signupState?.identity?.monthlyIncome?.minimum
  if (minimumMonthlyIncome) {
    personData.minimumMonthlyIncome = minimumMonthlyIncome
  }

  return personData as NaturalPerson
}
