import Avatar from '@/atoms-react/avatar/Avatar.react'
import { Base, Info } from '@/atoms-react/text/Text.react'
import { EmptyPageCactusAnimation } from '@/components/animations/empty-cactus/index.react'
import { formatDate, formatNumber } from '@/features/utils/functions'
import { useLocale } from '@/features/utils/hooks/use-locale'
import { TransactionType } from '@/features/utils/types'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

export type TransactionItem = {
  title: string
  date: Date | string
  amount: number | string
  type: TransactionType
}

export type TransactionListProps = {
  transactions: TransactionItem[]
  numberOfTransactions?: number
  search?: string
  listType?: 'kycoin' | 'kypay'
  fracDigits?: number
}

export const TransactionsList = ({
  transactions,
  numberOfTransactions,
  search,
  listType,
  fracDigits = 2
}: TransactionListProps) => {
  const { t } = useTranslation(['wallet'])
  const { locale } = useLocale()

  if (numberOfTransactions && numberOfTransactions > 0 && transactions) {
    transactions = transactions.slice(0, numberOfTransactions)
  }

  return (
    <div className={'flex flex-col space-y-2 md:space-y-0 w-full h-max'}>
      {transactions?.length === 0 && !search && (
        <>
          <div className="w-full flex items-center flex-col space-y-4 my-16">
            <EmptyPageCactusAnimation />
            <Info>{t('kypay.info.no_transaction')}</Info>
          </div>
        </>
      )}
      {transactions?.length === 0 && !!search && (
        <>
          <div className="w-full flex items-center flex-col space-y-4 my-16">
            <Info>{t('kypay.info.not_found')}</Info>
          </div>
        </>
      )}
      {transactions?.map((trans, index) => (
        <div
          className={twMerge(
            'flex justify-between items-center px-3 py-3 md:px-8 md:py-2 border-2 border-slate-50 dark:border-slate-700 md:border-0 rounded-sm md:rounded-none',
            index % 2 &&
              `${listType === 'kycoin' ? 'md:bg-orange-50 md:dark:bg-orange-900' : 'md:bg-blue-50 md:dark:bg-blue-900'}`
          )}
          key={index}
        >
          <div
            className="flex items-center w-full md:w-1/2"
            // @POTENTIAL SIDE EFFECT
            style={{ maxWidth: 'calc(100% - 118px)' }}
          >
            <Avatar
              className="shrink-0 mr-4"
              size={10}
              fallback={trans.title.split(' ').slice(1).join(' ') || '-'}
            />
            <div className="hidden md:block" title={trans.title}>
              <Base className="md:text-slate-500">{trans.title}</Base>
            </div>
            <div className="md:hidden w-3/4">
              <Base className="md:text-slate-500 truncate w-full">{trans.title}</Base>
              <Base className="text-xs md:text-base text-slate-500">
                {formatDate(locale.language, trans?.date)}
              </Base>
            </div>
          </div>
          <div className="hidden md:block md:w-1/4 md:py-4">
            <Base className="text-xs md:text-base text-slate-500">
              {formatDate(locale.language, trans?.date)}
            </Base>
          </div>
          <div className={'md:w-1/4 md:py-4 whitespace-nowrap shrink-0'}>
            {(trans.type === TransactionType.Transfer || trans.type === TransactionType.Sale) && (
              <Base className={'w-full font-semibold text-right'}>
                - {formatNumber(parseFloat(trans.amount as string), fracDigits)}
                {listType === 'kycoin' ? ' KYC' : ' €'}
              </Base>
            )}
            {trans.type === TransactionType.Purchasing && (
              <Base
                className={`w-full font-semibold text-right ${listType === 'kycoin' ? 'text-orange-500' : 'text-blue-500'}`}
              >
                + {formatNumber(parseFloat(trans.amount as string), fracDigits)}
                {listType === 'kycoin' ? ' KYC' : ' €'}
              </Base>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
