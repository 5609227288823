import { fetchServer } from '@/features/utils/fetch'
import type {
  CheckEmailProps,
  ErrorResponse,
  CheckEmailResponse,
  RegisterCredentialType,
  ConfirmationResponse,
  VerifyEmailCredentials
} from '../types'

export class RegisterApiClient {
  static async checkEmail(credentials: CheckEmailProps) {
    const response = await fetchServer(`/register/check_email`, {
      method: 'POST',
      reloadOn401: false,
      body: {
        email: credentials.email,
        recaptcha: credentials.captcha_token,
        language: credentials.language
      }
    })
    const res = await response.json()
    try {
      const parsedJSON = JSON.parse(res.message)
      return parsedJSON as CheckEmailResponse
    } catch (error) {
      return res as ErrorResponse<CheckEmailResponse>
    }
  }

  static async validateEmail(
    encryptedData: string,
    code: string,
    credentials: VerifyEmailCredentials
  ): Promise<ConfirmationResponse> {
    const response = await fetchServer(`/register/validate_email`, {
      method: 'POST',
      reloadOn401: false,
      body: {
        encryptedData,
        code,
        credentials: JSON.stringify(credentials)
      }
    })

    if (response.ok) {
      return { success: true, message: '' }
    } else {
      const res = await response.json()
      return res as { success: false; message: string }
    }
  }

  static async completeCredential(
    credentials: RegisterCredentialType
  ): Promise<ConfirmationResponse> {
    const response = await fetchServer(`/register/complete_credential`, {
      method: 'POST',
      reloadOn401: false,
      body: {
        // @TODO: Double stringify (one in fetch server, second here) is necessary besause the backend json_decode's two times, ask Xavier to correct that
        credentials: JSON.stringify(credentials)
      }
    })
    if (response.status === 200) {
      return { success: true, message: '' }
    } else {
      const res = await response.json()
      return res
    }
  }
}
