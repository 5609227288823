import { fetchServer } from '@/features/utils/fetch'
import {
  ChangePasswordResponse,
  // SendEmailCodeResponse,
  SendSensitiveEmailCodeResponse,
  TryConnectionResponse,
  ValidationSensitiveEmailCodeResponse
} from '../types'

export class SecurityApiClient {
  static async is2faActive() {
    const res = await fetchServer('/two_factory/check_activation')
    return (await res.json()).data.isTwoFaActivated as boolean
  }

  static async setup2fa() {
    const res = await fetchServer('/two_factory/setup')
    return (await res.json()).data as {
      qrContent: string
      secret: string
    }
  }

  static async activate2fa(otp: string, secret: string) {
    const res = await fetchServer('/two_factory/activate', {
      body: {
        otp,
        secret
      }
    })
    return res.status === 200
  }

  static async updatePassword(password: string) {
    const response = await fetchServer('/user/settings/update_password', {
      body: {
        password
      }
    })
    const success = response.status === 200 ? true : false
    if (!success) {
      const res = await response.json()
      return { success, errorCode: res.code } as ChangePasswordResponse
    } else {
      return { success } as ChangePasswordResponse
    }
  }

  // static async sendEmailCode(email: string, language: string) {
  //   const response = await fetchServer('/user/settings/pre_update_pwd/send_email_code', {
  //     method: 'POST',
  //     reloadOn401: false,
  //     body: {
  //       email,
  //       language
  //     }
  //   })
  //   const res = await response.json()
  //   return res as SendEmailCodeResponse
  // }

  /* /!**
   *
   * @param encryptedData
   * @param code
   *!/
  static async checkEmailCode(encryptedData: string, code: string, actionType: string) {
    const response = await fetchServer('/user/settings/pre_update_pwd/check_email_code', {
      method: 'POST',
      reloadOn401: false,
      body: {
        encryptedData,
        code,
        actionType
      }
    })
    const res = await response.json()
    console.log('checkEmailCode', res)
    try {
      return res.data as { success: boolean; data: ''; message: '' }
    } catch (error) {
      return res.data as { success: false; data: ''; message: string }
    }
  }*/

  static async tryConnectViaOTP(email: string, password: string, code = '') {
    const response = await fetchServer('/user/settings/pre_update_pwd/check_otp_code', {
      body: {
        email,
        password,
        code
      }
    })
    const success = response.status === 200 ? true : false
    if (!success) {
      const res = await response.json()
      return { success, data: res.data } as TryConnectionResponse
    } else {
      return { success } as TryConnectionResponse
    }
  }

  static async reset2Fa() {
    const response = await fetchServer('/two_factory/reset')
    const res = await response.json()
    return res.data
  }

  static async openDemandOfAuthentication() {
    const response = await fetchServer('/authentication/add_trace_auth')
    const res = await response.json()
    return res as TryConnectionResponse
  }

  static async updateDemandOfAuthentication(
    id: number,
    success: boolean,
    web_authn_signing_action_id: any,
    user_otp_input: string
  ) {
    const response = await fetchServer('/authentication/update_trace_auth', {
      method: 'POST',
      reloadOn401: false,
      body: {
        id,
        success,
        web_authn_signing_action_id,
        user_otp_input
      }
    })
    const res = await response.json()
    return res as TryConnectionResponse
  }

  static async requestSensitiveEmail(
    email: string,
    language: string,
    actionType: string | undefined,
    actionData: string | undefined
  ) {
    const response = await fetchServer('/authentication/request_email_code', {
      method: 'POST',
      reloadOn401: false,
      body: {
        email,
        language,
        actionType,
        actionData
      }
    })
    const res = await response.json()
    return res as SendSensitiveEmailCodeResponse
  }
  static async validateSensitiveEmail(
    uuidSensitiveActionValidation: string,
    code: string,
    actionType: string
  ) {
    const response = await fetchServer('/authentication/validate_email_code', {
      method: 'POST',
      reloadOn401: false,
      body: {
        uuidSensitiveActionValidation,
        code,
        actionType
      }
    })
    const res = await response.json()
    return res as ValidationSensitiveEmailCodeResponse
  }
}
