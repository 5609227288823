import { CheckIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import React from 'react'
import { twMerge } from 'tailwind-merge'

export type CheckCircleProps = {
  width?: number
  height?: number
  iconProps?: React.ComponentPropsWithoutRef<'svg'>
} & React.ComponentPropsWithoutRef<'div'>

export const CheckCircle = ({ width = 34, height = 32, iconProps, ...props }: CheckCircleProps) => (
  <div
    className={twMerge(
      'bg-blue-500 flex justify-center items-center rounded-full text-white mr-4',
      props.className
    )}
    style={{ width, height, ...props.style }}
    {..._.omit(props, 'className', 'style')}
  >
    <CheckIcon className="w-4 h-4" strokeWidth={iconProps?.strokeWidth ?? 3} {...iconProps} />
  </div>
)
