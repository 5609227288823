import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import i18n from '@/features/utils/i18n.ts'
import nationalitiesEN from '@/locales/nationalities-en.json'
import nationalitiesFR from '@/locales/nationalities-fr.json'
import languageModule from './languageModule.js'

const uris = {
  _get: {
    static_data: '/user/static_data'
  }
}
const state = {
  countries: [],
  activities: [],
  cspCodes: [],
  countryLabel: 'name',
  cspLabel: 'Nom',
  activityLabel: 'Nom',
  documentTypeLabel: 'type_fr',
  currentTreatedEntity: '',
  currentUUID: '',
  hasUpdatePermission: false
}

const getters = {
  countries: state => state.countries,
  nationalities: state => state.nationalities,
  activities: state => state.activities,
  cspCodes: state => state.cspCodes,
  countryLabel: state => state.countryLabel,
  cspLabel: state => state.cspLabel,
  activityLabel: state => state.activityLabel,
  currentTreatedEntity: state => state.currentTreatedEntity,
  currentUUID: state => state.currentUUID,
  hasUpdatePermission: state => state.hasUpdatePermission,
  documentTypeLabel: state => state.documentTypeLabel
}

const mutations = {
  LOAD_STATIC_DATA(state, response) {
    state.countries = response.data.countries

    // Localized nationality names
    state.nationalities = response.data.countries
      .map(country => ({
        ...country,
        name: nationalitiesFR[country.alpha2],
        englishName: nationalitiesEN[country.alpha2]
      }))
      // Sort Alphabetically according to app language
      .sort((a, b) => {
        const sortField = languageModule.state.currentLanguage === 'en' ? 'englishName' : 'name'
        const comparison = new Intl.Collator(languageModule.state.currentLanguage).compare(
          a[sortField],
          b[sortField]
        )
        return comparison
      })

    state.cspCodes = response.data.cspCodes.filter(csp => csp.Code !== 12)

    // @TODO Ask backend to remove the filtered option
    state.activities = response.data.activities.filter(act => act.Code !== 31)
  },
  SET_CURRENT_TREATED_ENTITY(state, data) {
    state.currentTreatedEntity = data
  },
  SET_CURRENT_UUID(state, UUID) {
    state.currentUUID = UUID
  },
  SET_HAS_UPDATE_PERMISSION(state, data) {
    state.hasUpdatePermission = data
  },
  SET_COMBOBOX_LABELS(state, language) {
    state.countryLabel = language === 'en' ? 'englishName' : 'name'
    state.cspLabel = language === 'en' ? 'NomEn' : 'Nom'
    state.activityLabel = language === 'en' ? 'NomEn' : 'Nom'
    state.documentTypeLabel = language === 'en' ? 'type_en' : 'type_fr'
  }
}

const actions = {
  async loadStaticData({ commit }) {
    try {
      const response = await fetchServer(uris._get.static_data)
      commit('LOAD_STATIC_DATA', response)
    } catch (error) {
      if (error?.response?.data?.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  setCurrentTreatedEntity({ commit }, data) {
    commit('SET_CURRENT_TREATED_ENTITY', data)
  },
  setCurrentUUID({ commit }, UUID) {
    commit('SET_CURRENT_UUID', UUID)
  },
  setHasUpdatePermission({ commit }, data) {
    commit('SET_HAS_UPDATE_PERMISSION', data)
  },
  setComboboxLabels({ commit }, language) {
    commit('SET_COMBOBOX_LABELS', language)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
