import { defineJsxComponent } from '@/jsx-utils'
import { Info, BaseSmall } from '@/atoms/text/Text.tsx'

export const LabelDecoration = defineJsxComponent<{
  feedback?: string
  error?: boolean
  class?: string
  label?: string
  labelClass?: string
}>((props, { slots }) => () => (
  <div class={props.class}>
    {!!(props.label || slots.label) && (
      <BaseSmall
        no-color
        class={
          'whitespace-nowrap text-slate-700 dark:text-slate-400 overflow-hidden text-ellipsis ' +
          (props.labelClass || '')
        }
      >
        {props.label || slots.label?.()}
      </BaseSmall>
    )}
    {slots.input?.({
      class: props.label || slots.label ? 'mt-1.5' : ''
    })}
    {props.feedback && (
      <Info no-color class={props.error ? 'text-red-400' : 'text-blue-500'}>
        {props.feedback}
      </Info>
    )}
  </div>
))

export default LabelDecoration
