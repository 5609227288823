import {
  Currency,
  SessionExternalScope,
  SessionGateway,
  type GetSessionByIDResponse
} from '../types'
import { MirrorMutationResponse, PartnerMutationResponse } from '@/features/auth/types.ts'
import { fetchServer } from '@/features/utils/fetch.ts'

export class FlowApiClient {
  static async getSessionByID(id: string): Promise<GetSessionByIDResponse> {
    return {
      id,
      scope: [SessionExternalScope.email],
      gateways: [SessionGateway.declarative_identity],
      order: {
        id: 'UID1',
        amount: 1000,
        currency: Currency.euros
      }
    }
  }
  static async getPartnerPerUUID(uuid: string): Promise<PartnerMutationResponse> {
    const response = await fetchServer(`/public/partner/getPerUUID/${uuid}`, {
      method: 'GET',
      reloadOn401: false,
      mainRoot: false
    })
    const res = await response.json()
    return res.data || null
  }
  static async createMirrorFromReal(partner_uuid: string): Promise<MirrorMutationResponse> {
    const response = await fetchServer(`/partner/create_mirror_from_real`, {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: {
        partner_uuid
      }
    })
    const res = await response.json()
    return res.data || null
  }
}
