import { GenderDTO } from '../auth/types'
import { KYCSessionStatus } from '../kyc/types'
import { DateDTO, DateWithTimezone, NaturalEntityDataType } from '../utils/types'

export type UserType = {
  applications: {
    olkypay: boolean
    olkywallet: boolean
  }
  naturalPerson: NaturalPerson
  olkyPassLevel: KYCSessionStatus
}

export type NaturalPerson = {
  Gender: string
  LastName: string
  FirstName: string
  OtherFirstNames: null | string
  MaidenName: null | string
  DateOfBirth: DateWithTimezone
  CountryOfCitizenship: number
  CountryOfCitizenship2: number | null
  Birthplace: null | string
  StateOfBirth: string
  Address: any[] // Replace any with a more specific type if possible
  AliasIBAN: AliasIBAN[] // Replace any with a more specific type if possible
  AliasMobile: AliasMobile[] // Replace any with a more specific type if possible
  AliasEmail: AliasEmail[]
  NIF: null | string
  nif_country: null | string
  Comments: null | string
  CodeLangue: null | string
  ActivityCode: number
  CSPCode: number
  codeShop: null | string
  idBB: null | string
  ibanOlkyPay: null | string
  countryOfBirth: number
  uuid: string
  UUID: string
  olkyAccount?: OlkyAccount | null
  minimumMonthlyIncome?: number
  maximumMonthlyIncome?: number
  olkypay_account_requested?:number

}

export type OlkyAccount = {
  iban: string
  balanceOperationYesterday: string
  balanceOperationDate: string
  supplierId: {
    logoPath: string | null
  }
  codeShop: string
  balance: string
}

export type AliasIBAN = {
  UUID: string
  Owner: string
  Iban: string
  BIC: string
  Actif: boolean
  Public: boolean
  BankInfoDto: {
    Address: string
    CountryName: string
    Institution: string
    Location: string
    Name: string
  }
  valid: boolean
}

// Different DTO schemas sent by backend are named "NaturalPerson", we name them differently to differenciate them
export type FullNaturalPerson = {
  HasToSendToLausanne: boolean
  HasToSendToAlgoreg: boolean
  id: number
  uuid: string
  gender: GenderDTO
  last_name: string | null
  first_name: string | null
  other_first_names: string | null
  maiden_name: string | null
  birth_date: DateDTO | string
  birth_city: string | null
  birth_state: string | null
  pep: string | null
  id_bb: string | null
  created_at: string
  updated_at: string
  deleted_date: string | null
  country_of_citizenship: FullCountry
}

export type RealIdentity = {
  id: number
  uuid: string
  risk: string | null
  nif: string
  nif_country: string
  internal_id: number | null
  comment: string | null
  status: string | null
  language_code: string | null
  created_at: string | null
  updated_at: string | null
  deleted_date: string | null
  algoreg_upload: boolean
  lausanne_upload: boolean
  code_shop: string | null
  data_validated_by_user: boolean
  lausanne_id: string | null
  algoreg_external_id: string | null
  natural_person: FullNaturalPerson
  kycoin_wallet: '[Circular]'
  alias_ibans: FullAliasIBAN[]
  email: FullAliasEmail
  home_address: FullAddress
}

export type AliasEmail = {
  UUID: string
  Owner: string
  Mail: string
  Actif: boolean
  Public: boolean
  LinkNPLE: null | string
  valid: boolean
}

export type AliasMobile = {
  UUID: string
  Owner: string
  Phone: string
  Public: boolean
  MobileIndicatif: string
  MobileNumber: string
}

export type MobileCreate = {
  UUID?: string
  MobileNumber: string
  MobileIndicatif: string
}

export type IbanCreate = {
  UUID: string
  iban: string
}

export type FullAliasEmail = {
  id: number
  uuid: string | null
  mail: string
  active: boolean
  public: boolean
  valid: boolean
  created_at: string
  updated_at: string
  deleted_date: string | null
}

export type FullAliasIBAN = {
  id: number
  uuid: string
  iban: string
  active: boolean
  public: boolean
  bic: string
  created_at: string
  updated_at: string
  deleted_date: string | null
  mirror_identity: string | null
}

export type AddressFullType = {
  Address1: string
  ZipCode: string
  Country: string
  City: string
  Tag?: string
  UUID?: string
  language?: string
  isPrincipalAddress?: boolean
}

export type AddressType = {
  address_1: string
  zip_code: string
  city: string
  country_id: number | null
}

export type FullAddress = {
  id: number
  uuid: string
  address_1: string
  address_2: string | null
  address_3: string | null
  zip_code: string
  city: string
  tag: string
  created_at: string
  updated_at: string
  deleted_date: string | null
  country: FullCountry
}

export type AddressCreate = {
  tag: string
  address: string
  city: string
  country: number | null
  zip: string
  UUID?: string
  language?: string
  isPrincipalAddress?: boolean
}

export type FullCountry = {
  id: number
  uuid: string | null
  name: string
  defaultName: string
  Code: string
  alpha2: string
  alpha3: string
  ibanAllowSize: number | null
  englishName: string
  countryValidForIban: boolean | null
}

export type Phone = {
  MobileIndicatif: string
  MobileNumber: string
  UUID: string
}

export type Iban = {
  iban: string
  UUID: string
}

export type NaturalPersonDTO = Omit<
  Omit<Omit<NaturalPerson, 'CountryOfCitizenship'>, 'CountryOfCitizenship2'>,
  'countryOfBirth'
> & {
  CountryOfCitizenship: string
  CountryOfCitizenship2: string | null
  countryOfBirth: string
  CodeLanguage?: string
}

export type StaticActivity = {
  Code: number
  defaultName: string
  Nom: string
  NomEn: string
  Risk: number
  id: number
  uuid: string
}

export type StaticCSP = {
  Code: number
  defaultName: string
  Nom: string
  NomEn: string
  Risk: number
  id: number
  uuid: string
}

export type StaticCountry = {
  Code: string
  alpha2: string
  alpha3: string
  countryValidForIban: boolean
  englishName: string
  defaultName: string
  ibanAllowSize: number
  id: number
  name: string
  uuid: string
}

export type StaticNace = {
  englishName: string
  defaultName: string
  id: string
  name: string
}

export type StaticLegalStatus = {
  englishName: string
  defaultName: string
  id: string
  name: string
}

export type StaticData = {
  activities: StaticActivity[]
  cspCodes: StaticCSP[]
  countries: StaticCountry[]
  naceCodes: StaticNace[]
  legalStatus: StaticLegalStatus[]
}

export type CredentialType = {
  username: string
  password: string
}
export type CityInfoType = {
  nom: string
  code: string
  departement: {
    code: string
    nom: string
  }
}

export enum PublicAliasType {
  Email = 'email',
  Iban = 'iban',
  Phone = 'phone'
}

export type AliasDelete = {
  UUID: string
  ItemUUID: string
  ItemType:
    | NaturalEntityDataType.AliasMailDto
    | NaturalEntityDataType.AliasIBANDto
    | NaturalEntityDataType.AliasMobileDto
    | NaturalEntityDataType.AdresseDto
}

export type EmailCodeResponse = {
  cypher: string
}
export type nifValidationResponse = {
  countryCode: string
  tinNumber: string
  requestDate: string
  validStructure: boolean
  validSyntax: boolean
}
export type NewEmailMutationProps = {
  email: string
}

export type ValidateNifMutationProps = {
  countryCode: string
  nif: string
}
