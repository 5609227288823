import { BeneficiaryService, ResponseError } from '../services/beneficiary.service'

const state = {
  responseData: '',
  responseErrorCode: 0,
  responseError: '',
  beneficiaries: [],
  currentBeneficiary: {}
}

const getters = {
  responseData: (state: { responseData: any }) => state.responseData,

  responseErrorCode: (state: { responseErrorCode: any }) => state.responseErrorCode,
  responseError: (state: { responseError: any }) => state.responseError,
  beneficiaries: (state: { beneficiaries: any }) => state.beneficiaries,
  currentBeneficiary: (state: { currentBeneficiary: any }) => state.currentBeneficiary
}

const actions = {
  initBeneficiaries(context: any) {
    context.commit('initBeneficiaries')
  },
  async loadBeneficiaries(context: any, payload: any) {
    context.commit('dataRequest')
    const pagination_options = payload.pagination_options
    try {
      const resp = await BeneficiaryService.getBeneficiaries(pagination_options)
      context.commit('successLoadBeneficiaries', resp)
      return resp
    } catch (e: any) {
      if (e instanceof ResponseError) {
        context.commit('dataError', {
          errorMessage: e.errorMessage,
          responseErrorCode: e.errorCode
        })
      }
      return e.message
    }
  },
  initCurrentBeneficiary(context: any, beneficiary: any) {
    context.commit('setCurrentBeneficiary', beneficiary)
  },
  async setCurrentBeneficiary(context: any, beneficiary: any) {
    context.commit('setCurrentBeneficiary', beneficiary)
    /* try {
             const resp = await BeneficiaryService.getBeneficiary(beneficiary);
             context.commit('setCurrentBeneficiary', resp)
             return resp;
         } catch (e: any) {
             if (e instanceof ResponseError) {
                 context.commit("dataError", {
                     errorMessage: e.errorMessage,
                     responseErrorCode: e.errorCode
                 });
             }
             return e.message;
         }*/
  }
}

const mutations = {
  dataRequest(state: { responseError: string; responseErrorCode: number }) {
    state.responseError = ''
    state.responseErrorCode = 0
  },
  dataSuccess(state: { responseData: string }, payload: any) {
    state.responseData = payload
  },
  successLoadBeneficiaries(state: { beneficiaries: any }, payload: any) {
    // transactions are paginated per 10 in page
    state.beneficiaries = state.beneficiaries.concat(payload)
  },
  initBeneficiaries(state: { beneficiaries: any }) {
    // beneficiaries are paginated per 10 in page
    state.beneficiaries = []
  },
  dataError(
    state: {
      responseError: any
      responseErrorCode: any
    },
    { errorCode, errorMessage }: any
  ) {
    state.responseError = errorMessage
    state.responseErrorCode = errorCode
  },
  setCurrentBeneficiary(state: { currentBeneficiary: any }, beneficiary: any) {
    state.currentBeneficiary = beneficiary
  }
}

export const beneficiary = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
