import { Base } from '@/atoms-react/text/Text.react'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { OlkypassCard } from '../../../dashboard/components/olkypass-cards/index.react'
import { IdentityAddresses } from '../identity-addresses/index.react'
import { IdentityDocuments } from '../identity-documents/index.react'
import { IdentityHeaderCard } from '../identity-header-card/index.react'
import { IdentityNPContent } from '../identity-np-content/index.react'

type UserIdentityProps = { cardClassName: string } & React.ComponentPropsWithoutRef<'div'>

enum Tab {
  Content,
  Addresses,
  Documents
}

export const IdentityNP = ({ cardClassName }: UserIdentityProps) => {
  const { t } = useTranslation(['pass'])
  const [activeTab, setActiveTab] = useState<Tab>(Tab.Content)

  // const { useUserProfile } = useUser()
  // const { data: user } = useUserProfile()
  const { status ,nextStatus,auditPending} = useKYCContext()

  const activeTabClassName = 'bg-blue-500 border-2 border-blue-500 rounded-full px-4 py-1'
  const notActiveTabClassName = 'border-2 border-slate-50 rounded-full px-4 py-1 hover:bg-slate-50'

  return (
    <>
      <div className="2xl:flex 2xl:gap-x-6">
        <IdentityHeaderCard
          className={twMerge('border-2 border-slate-50 rounded-sm', cardClassName)}
        />
        <div className="mt-4 mb-8 md:mb-0 md:mt-6 2xl:mt-0 2xl:w-1/4 lg:min-w-[456px] md:h-48">
          <OlkypassCard
            CTA={false}
            verifyButton
            currentStatus={status}
            nextStatus={nextStatus}
            auditPending={auditPending}
          />
        </div>
      </div>

      <div className="md:mb-2 overflow-x-scroll md:hidden">
        <div className="flex hidden space-x-6">
          <div
            className={`hover:cursor-pointer ${activeTab === Tab.Content ? activeTabClassName : notActiveTabClassName}`}
            onClick={() => setActiveTab(Tab.Content)}
          >
            <Base
              className={twMerge(
                'text-nowrap',
                activeTab === Tab.Content ? 'text-white' : 'text-slate-400'
              )}
            >
              {t('olkypass.accountPage.basicInfo.tabs.informations')}
            </Base>
          </div>
          {/*      <>
          <div className={`cursor-pointer`} onClick={() => setActiveTab(Tab.Addresses)}>
            <Base
              className={twMerge(
                'text-nowrap',
                activeTab === Tab.Addresses ? 'text-white' : 'text-slate-400'
              )}
            >
              {t('olkypass.accountPage.basicInfo.tabs.adresses')}
            </Base>
          </div>
          <div className={`cursor-pointer`} onClick={() => setActiveTab(Tab.Documents)}>
            <Base
              className={twMerge(
                'text-nowrap',
                activeTab === Tab.Documents ? 'text-white' : 'text-slate-400'
              )}
            >
              {t('olkypass.accountPage.basicInfo.tabs.documents')}
            </Base>
          </div>
        </>*/}
        </div>
      </div>
      {/* For mobile screens */}
      {
        <div className="md:mt-6">
          <div className="md:flex md:space-x-6 md:hidden">
            <div className="w-full 2xl:flex 2xl:space-x-6">
              {activeTab === Tab.Content && <IdentityNPContent className={cardClassName} />}
              {activeTab === Tab.Documents && <IdentityDocuments className={cardClassName} />}
              {activeTab === Tab.Addresses && <IdentityAddresses className={cardClassName} />}
            </div>
          </div>
          {/* For desktop screens */}
          <div className="md:flex md:space-x-6 hidden">
            <div className="w-full 2xl:flex 2xl:space-x-6">
              <IdentityNPContent className={cardClassName} />
              <div className="2xl:w-1/4 lg:min-w-[456px] md:flex 2xl:flex-col">
                <IdentityAddresses className={cardClassName} />
                <IdentityDocuments className={cardClassName} />
              </div>
            </div>
          </div>
        </div>
      }
      {/*
    <div className="-mx-4">
      <AliasesPageReact />
    </div> */}
    </>
  )
}
