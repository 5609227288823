<template>
  <v-dialog v-model="dialog" persistent width="auto">
    <v-card class="cardBoxPopup">
      <v-card-title class="text-h5">{{
        $t('olkypass.accountPage.emails_section.dialog.header.new')
      }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-form ref="emailForm" class="mt-4" @submit.prevent="checkEmailAvailability">
              <label>{{ $t('login.loginPage.input.email') }}</label>
              <!--              prepend-inner-icon="mdi-email-outline"-->
              <v-text-field
                v-model="email"
                density="comfortable"
                variant="plain"
                clearable
                :hint="availability_message"
                persistent-hint
                :rules="[
                  requiredField('login.registerPage.input.email'),
                  isEmailField('login.registerPage.input.email')
                ]"
              >
              </v-text-field>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col class="d-flex justify-space-between">
            <v-btn
              variant="flat"
              color="white"
              height="auto"
              class="text-none cancelBtn"
              size="medium"
              @click="cancel"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.cancel') }}
            </v-btn>
            <v-btn
              variant="flat"
              color="primary"
              height="auto"
              class="text-none validationBtn"
              :loading="loader"
              size="medium"
              @click="checkEmailAvailability"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <EmailCodeFormDialog ref="emailCodeFormDialog"></EmailCodeFormDialog>
</template>

<script>
import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import { mapGetters } from '../../store'
import EmailCodeFormDialog from './EmailCodeFormDialog.vue'
export default {
  name: 'EmailFormDialog',
  components: {
    EmailCodeFormDialog
  },
  mixins: [NotificationsMixins],
  data: () => ({
    dialog: false,
    email: '',
    loader: false,
    uri: '/aliasMail/create_new',
    availability_message: '',
    setCurrentEntityFunctionName: ''
  }),
  methods: {
    open() {
      this.dialog = true
      this.email = ''
      this.availability_message = ''
      if (this.currentTreatedEntity === 'company') {
        this.setCurrentEntityFunctionName = 'setCurrentCompany'
      } else {
        this.setCurrentEntityFunctionName = 'setCurrentNaturalPerson'
      }
    },
    cancel() {
      this.dialog = false
    },
    async isValidForm() {
      const { valid } = await this.$refs.emailForm.validate()
      return valid
    },
    async checkEmailAvailability() {
      const isValidForm = await this.isValidForm()
      if (isValidForm) {
        this.loader = true
        this.availability_message = ''
        await fetchServer(this.uri, {
          body: {
            UUID: this.currentUUID,
            email: this.email,
            language: this.currentLanguage
          }
        })
          .then(async response => {
            if (response.data.UUID !== undefined) {
              this.displayNotification(
                'success',
                this.$i18n.t('olkypass.accountPage.notifications.success.profile.update')
              )
              let validation_buffer = response.data.cypher
              let mail_UUID = response.data.UUID
              this.cancel()
              this.$refs.emailCodeFormDialog.open(mail_UUID, validation_buffer)
            } else {
              this.displayNotification(
                'error',
                this.$i18n.t('olkypass.accountPage.notifications.error.email.sendLink')
              )
            }
          })
          .catch(error => {
            this.availability_message = this.$i18n.t(
              'olkypass.accountPage.notifications.error.email.create'
            )
          })
          .finally(() => this.loader = false)
      }
    }
  },
  computed: {
    ...mapGetters(['currentTreatedEntity', 'currentUUID', 'currentLanguage']),
    requiredField() {
      const $this = this
      return function (fieldName) {
        return (v) =>
          !!v ||
          $this.$i18n.t(fieldName) +
            ' ' +
            $this.$i18n.t('login.registerPage.validation.required_field')
      }
    },
    isEmailField() {
      const $this = this
      return function (fieldName) {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return (v) =>
          pattern.test(v) ||
          $this.$i18n.t(fieldName) +
            ' ' +
            $this.$i18n.t('login.registerPage.validation.email_field')
      }
    }
  }
}
</script>

<style scoped></style>
