import { useGateways } from '@/features/flow/hooks/use-gateways'
import { GatewayVote } from '@/features/flow/types'
import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react'
import { Layout } from '../../layout/Layout.react'
import { DocumentsGateway, DocumentsGatewayAction } from '../documents/index.react'

export type GatewaysProviderProps = { children?: React.ReactNode }
export type GatewaysContext = {
  active: boolean
  setActive: Dispatch<SetStateAction<boolean>>
}

export const GatewaysContext = createContext<GatewaysContext>({
  active: false,
  setActive: () => {}
})

export const GatewaysProvider = ({ children }: GatewaysProviderProps) => {
  const [active, setActive] = useState<boolean>(false)

  const { useDocumentsGatewayVoter } = useGateways()
  const documentsVote = useDocumentsGatewayVoter()

  const getBlockingComponent = () => {
    if (documentsVote === GatewayVote.Block) {
      return <Layout>
        <DocumentsGateway
          action={DocumentsGatewayAction.AddDocument}
          onValidated={() => setActive(false)}
        />
      </Layout>
    }

    return null
  }

  return <GatewaysContext.Provider value={{ active, setActive }}>
    {getBlockingComponent() && active && getBlockingComponent()}
    {(!getBlockingComponent() || !active) && children}
  </GatewaysContext.Provider>
}

export const useGatewaysProvider = () => useContext(GatewaysContext)
