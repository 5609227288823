import Column from '@/atoms-react/column/Column.react'
import { DynamicBlobAnimation } from '@/components/animations/dynamic-blob/index.react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FlowHeader } from '../../../flow-header.react'
import { useEffect } from 'react'
import { AuthApiClient } from '@/features/auth/api-client/api-client'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import toast from 'react-hot-toast'
export const RecoverPassword = () => {
  const { i18n } = useTranslation()
  const { substep } = useParams()
  const { t } = useTranslation(['pass'])
  const { executeRecaptcha } = useGoogleReCaptcha()

  // Decode email from url encoded
  const email = decodeURIComponent(substep || '')

  useEffect(
    () =>
      (async () => {
        if (email) {
          const handleReCaptchaVerify = async () => {
            if (!executeRecaptcha) {
              console.error('Execute recaptcha not yet available')
              return
            }

            return executeRecaptcha('login')
          }

          const captchaToken = await handleReCaptchaVerify()
          if (captchaToken) {
            await AuthApiClient.forgetPasswordLink(email, captchaToken, i18n.language)
          } else {
            toast.error('An error occurred')
          }
        }
      })(),
    []
  )

  return <>
    <Column>
      <div className="grow mb-12 flex flex-col">
        <FlowHeader
          title={t('flow.id_control.label.recover_title')}
          description={t('flow.id_control.label.recover_description', { email })}
          goHomePage={true}
        />
        <DynamicBlobAnimation src="/images/olkypass/recover-password-bg.png" />
      </div>
    </Column>
  </>
}
