import { twMerge } from 'tailwind-merge'
import { DropdownItem } from './index.react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'

export type DropdownInputWidgetProps = {
  placeholder?: string
  selectedValue: DropdownItem | null
  showChevrons?: boolean
  isOpen?: boolean
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'value'>

export const DropdownInputWidget = ({
  placeholder,
  selectedValue,
  showChevrons = true,
  isOpen,
  ...props
}: DropdownInputWidgetProps) => (
  <>
    <div
      className="flex items-center w-full h-6 whitespace-nowrap overflow-hidden text-ellipsis"
      {..._.omit(props, 'autoComplete', 'value', 'onChange')}
    >
      {selectedValue &&
        selectedValue.render &&
        selectedValue.render({
          key: selectedValue.key,
          value: selectedValue.value,
          icon: selectedValue.icon,
          inDropdown: false
        })}
      {selectedValue && !selectedValue.render && (
        <>
          {selectedValue.icon}
          <span
            className={twMerge('flex-1 text-left', selectedValue.icon && 'ml-2')}
            key={selectedValue?.key}
          >
            {selectedValue.value}
          </span>
        </>
      )}

      {!selectedValue && <span className={twMerge('flex-1 text-left')}>{placeholder}</span>}

      {!!showChevrons && !isOpen && <ChevronDownIcon className="h-6 w-6"></ChevronDownIcon>}
      {!!showChevrons && isOpen && <ChevronUpIcon className="h-6 w-6"></ChevronUpIcon>}
    </div>
  </>
)
