<template>
  <ion-page id="BeneficiarySummaryPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          default-href="/pay/supplier/transfer"
          :icon="caretBackSharp"
        ></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1 v-if="currentBeneficiary.id">
          {{ $t('olkypay.labels.beneficiariesPage.edit.header') }}
        </h1>
        <h1 v-else>{{ $t('olkypay.labels.beneficiariesPage.new.header') }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <ion-grid class="mx-2">
        <ion-row>
          <ion-col>
            <div class="card card-summary">
              <div class="card-header">
                <ion-row>
                  <ion-col size="2" class="mt-3"> </ion-col>
                  <ion-col>
                    <h3 class="text-white mt-3 mb-0 font-weight-bold">
                      {{ $t('olkypay.labels.beneficiarySummaryPage.identity') }}
                    </h3>
                    <span class="text-xs text-white"></span>
                  </ion-col>
                </ion-row>
                <hr class="horizontal light mt-3 mb-2" />
              </div>
              <div class="card-body mx-2">
                <ion-row>
                  <ion-col>
                    <span class="text-xs text-white">IBAN</span>
                    <h4 class="text-white mt-1 mb-0 font-weight-bold">
                      {{ formattedBeneficiaryIBAN }}
                    </h4>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <span class="text-xs text-white">BIC</span>
                    <h4
                      v-if="currentBeneficiary.properties && currentBeneficiary.properties.bic"
                      class="text-white mt-1 mb-0 font-weight-bold"
                    >
                      {{ currentBeneficiary.properties.bic }}
                    </h4>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <span class="text-xs text-white">{{
                      $t('olkypay.labels.beneficiarySummaryPage.beneficiary_name')
                    }}</span>
                    <h4
                      v-if="currentBeneficiary.fullName"
                      class="text-white mt-1 mb-3 font-weight-bold"
                    >
                      {{ currentBeneficiary.fullName }}
                    </h4>
                    <h4 v-else class="text-white mt-1 mb-3 font-weight-bold">
                      {{ currentBeneficiary.lastName }} {{ currentBeneficiary.firstName }}
                    </h4>
                  </ion-col>
                </ion-row>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-fab slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button color="blue_olky" @click="authenticateUser">
          <ion-icon :icon="checkmarkCircle"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>

    <authentication-process-popup ref="authenticationPopup"></authentication-process-popup>
    <info-modal ref="infoModal"></info-modal>
  </ion-page>
</template>

<script lang="ts">
import { BeneficiaryService } from '../../../services/beneficiary.service'
import AuthenticationProcessPopup from '../../../components/security/authenticationProcessPopup.vue'
import InfoModal from '../../../components/InfoModal.vue'

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonBackButton,
  IonButtons,
  IonInput,
  IonItem,
  IonIcon,
  IonFab,
  IonFabButton,
  alertController,
  toastController
} from '@ionic/vue'
import {
  caretBackSharp,
  chevronForwardCircle,
  checkmarkCircle,
  closeCircleSharp
} from 'ionicons/icons'
import { mapGetters, mapActions } from 'vuex'
import { Device } from '@capacitor/device'
export default {
  name: 'BeneficiarySummaryPage',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButton,
    IonBackButton,
    IonButtons,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonIcon,
    IonFab,
    IonFabButton,
    AuthenticationProcessPopup,
    InfoModal
  },
  data() {
    return {
      caretBackSharp,
      chevronForwardCircle,
      checkmarkCircle,
      closeCircleSharp,
      isDataLoading: false,
      paginationOptions: {
        page: 1,
        search: ''
      }
    }
  },
  methods: {
    ...mapActions('loader', ['playLoader', 'stopLoader']),
    ...mapActions('beneficiary', ['initCurrentBeneficiary', 'loadBeneficiaries']),
    async authenticateUser() {
      const isSuccessAuthenticated = await this.$refs.authenticationPopup.authenticate()
      if (isSuccessAuthenticated) {
        await this.onSuccessAuthenticateUser()
      } else {
        const toast = await toastController.create({
          message: this.$i18n.t('olkypay.notifications.error.loginPage.message'),
          duration: 3000,
          position: 'bottom',
          color: 'danger'
        })
        await toast.present()
      }
    },
    async onSuccessAuthenticateUser() {
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.savingBeneficiary.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      // post for new beneficiary
      // put for old beneficiary
      const method = !this.currentBeneficiary.id ? 'POST' : 'PUT'
      await BeneficiaryService.saveBeneficiary(this.currentBeneficiary, method)
        .then(async response => {
          const input_data = {
            name: this.currentBeneficiary.fullName,
            iban: this.currentBeneficiary.iban,
            bank_account_id: undefined
          }
          const client_id = this.currentBeneficiary.id ? this.currentBeneficiary.id : response.id
          if (this.currentBeneficiary.id) {
            input_data.bank_account_id = this.currentBeneficiary.properties.bankAccountId
          }
          await BeneficiaryService.associateBankAccountToBeneficiary(
            client_id,
            input_data,
            method
          ).then(async response => {
            this.stopLoader()
            const successDialog = {
              type: 'success',
              title: this.$i18n.t(
                'olkypay.notifications.success.beneficiarySummaryPage.save_beneficiary.header'
              ),
              content: this.$i18n.t(
                'olkypay.notifications.success.beneficiarySummaryPage.save_beneficiary.message'
              ),
              button: this.$i18n.t('olkypay.buttons.beneficiarySummaryPage.dialog_close')
            }
            const response_modal = await this.$refs.infoModal.openModal(successDialog)
            if (response_modal) {
              await this.closeInfoModal()
            }
          })
          this.stopLoader()
        })
        .catch(async (err: any) => {
          this.stopLoader()
          const failedDialog = {
            type: 'warning',
            title: this.$i18n.t(
              'olkypay.notifications.error.beneficiarySummaryPage.save_beneficiary.header'
            ),
            content: this.$i18n.t(
              'olkypay.notifications.error.beneficiarySummaryPage.save_beneficiary.message'
            ),
            button: this.$i18n.t('olkypay.buttons.beneficiarySummaryPage.dialog_close')
          }
          await this.$refs.infoModal.openModal(failedDialog)
        })
      this.stopLoader()
    },
    async closeInfoModal() {
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.loading_data.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      await this.loadBeneficiaries({ pagination_options: this.paginationOptions })
      this.stopLoader()
      this.initCurrentBeneficiary({})
      this.$router.push({ name: 'TransferTabRouter' })
    }
  },
  mounted() {},
  computed: {
    ...mapGetters('beneficiary', ['currentBeneficiary']),
    formattedBeneficiaryIBAN() {
      if (this.currentBeneficiary.iban) {
        return this.currentBeneficiary.iban.match(/.{1,4}/g).join(' ')
      }
      return ''
    }
  }
}
</script>

<style scoped></style>
