import { A } from '@/atoms-react/a/A.react'
import Avatar from '@/atoms-react/avatar/Avatar.react'
import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import { Base } from '@/atoms-react/text/Text.react'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import useCheckEmailExistsMutation from '@/features/flow/hooks/use-check-email-exists'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { FlowHeader } from '../../flow-header.react'
import { useOrigin } from '@/features/flow/hooks/use-origin'
import { FromURL } from '@/features/flow/types'
import { validateEmailFormat } from '@/features/utils/functions'
import { useSearchParams } from 'react-router-dom'
import useCheckPartnerUUIDMutation from '@/features/flow/hooks/use-partners.ts'
interface AccountEmailSelectProps extends Omit<LayoutBaseProps, 'center'> {
  onValidated?: (output: AccountWelcomeOutput) => void
  overlayMode?: boolean
}

export interface AccountWelcomeOutput {
  emailExists: boolean
  email: string
  is2faActivated: boolean
  existAsAlias: boolean
}

function AccountWelcome({ onValidated = () => {} }: AccountEmailSelectProps) {
  const [searchParams] = useSearchParams()
  const partner_uuid = searchParams.has('partnerId') ? searchParams.get('partnerId') : null
  if (partner_uuid) {
    localStorage.setItem('partner_uuid', partner_uuid)
  }
  const [subDescription, setSubDescription] = useState<string>('')

  const { cached, removeCached } = useAuth()
  const { mutateAsync: checkPartnerPerUUID } = useCheckPartnerUUIDMutation()

  const origin = useOrigin()
  const { t } = useTranslation(['pass'])

  const getTitle = () => {
    switch (origin) {
      case FromURL.OlkyPayNP:
        return t('flow.id_control.label.hello_olkyPayNP')
      case FromURL.OlkyPayLE:
        return t('flow.id_control.label.hello_olkyPayLE')
      case FromURL.Wallet:
        return t('flow.id_control.label.hello_olkyWallet')
      default:
        return t('flow.id_control.label.hello')
    }
  }

  const getSubDescription = async () => {
    if (origin !== FromURL.Partner) {
      return ''
    }
    const partner = await checkPartnerPerUUID({ uuid: partner_uuid })
    sessionStorage.setItem('ConnectedPartner', JSON.stringify(partner))
    return `${t('flow.partner_sub_description')} ${partner?.name}`
  }

  const { mutate: checkEmailExists, isPending } = useCheckEmailExistsMutation({
    onError: (error) => toast.error(error.message)
  })
  const [email, setEmail] = useState<string>('')

  const execute = useCallback(() => {
    if (!email) {
      toast.error(t('flow.id_control.error.email_required'))
      return
    }

    const validEmail = validateEmailFormat(email)
    if (!validEmail) {
      toast.error(t('flow.id_control.error.email_invalid'))
      return
    }

    checkEmailExists(email, {
      onSuccess: (data) =>
        onValidated({
          email,
          emailExists: data?.existStatus ?? false,
          is2faActivated: data?.is2faActivated ?? false,
          existAsAlias: data?.existAsAlias ?? false
        })
    })
  }, [email])

  useEffect(() => {
    async function getDescription() {
      const response = await getSubDescription()
      if (response) {
        setSubDescription(response)
      }
    }
    if (partner_uuid) {
      getDescription()
    } else {
      setSubDescription('')
    }
  }, [partner_uuid])

  if (cached?.data?.email) {
    if (!email) setEmail(cached.data.email)
    return (
      <Column>
        <div className="grow">
          <FlowHeader
            back={false}
            title={t('flow.id_control.label.login_title')}
            description={t('flow.id_control.label.select_an_account')}
            sub_description={subDescription}
          />
        </div>
        <div className="shrink-0">
          <div
            className={twMerge(
              'flex flex-row space-x-4 items-center cursor-pointer bg-blue-50 dark:bg-slate-800 p-4 rounded-md hover:bg-blue-100 dark:hover:bg-slate-700',
              isPending ? 'opacity-50 pointer-events-none' : ''
            )}
            onClick={() => execute()}
          >
            <Avatar size={12} src={cached.data.avatar} fallback={cached.data.name || ''} />
            <div className="flex flex-col justify-center grow w-1">
              <Base className="font-semibold w-full overflow-hidden text-ellipsis whitespace-nowrap">
                {cached.data.name}
              </Base>
              <Base className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                {cached.data.email}
              </Base>
            </div>
            <Base>
              <ChevronRightIcon className="h-6 w-6" />
            </Base>
          </div>
        </div>
        <A
          className="mt-8"
          onClick={() => {
            removeCached()
            setEmail('')
          }}
        >
          {t('flow.id_control.label.change_email')}
        </A>
      </Column>
    )
  }

  return (
    <>
      <Column>
        <div className="grow mb-12">
          <FlowHeader
            back={false}
            title={getTitle()}
            description={t('flow.id_control.label.hello_description')}
            sub_description={subDescription}
          />

          <InputLabel
            type="email"
            placeholder={t('flow.id_control.input.placeholder_email')}
            value={email}
            autoComplete="email"
            autoFocus
            onChange={(value: string) => setEmail(value)}
            label={t('flow.id_control.input.email_address')}
          />
        </div>
        <Button
          shortcut="enter"
          className="shrink-0"
          onClick={() => execute()}
          icon={ChevronRightIcon}
          loading={isPending}
        >
          {t('flow.id_control.input.continue')}
        </Button>
      </Column>
    </>
  )
}

export default AccountWelcome
