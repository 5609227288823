import { Skeletton } from '@/atoms-react/skeletton/index.react'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { CompanyConverters } from '@/features/company/converters/converters'
import { FormAddCompany } from '@/molecules/form-add-company/index.react'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { OlkypassCard } from '../../../dashboard/components/olkypass-cards/index.react'
import { IdentityHeaderCard } from '../identity-header-card/index.react'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'

type IdentityLEProps = { cardClassName: string } & React.ComponentPropsWithoutRef<'div'>

export const IdentityLE = ({ cardClassName }: IdentityLEProps) => {
  const { company, companyDetail } = useCompanyContext()
  const { status, auditPending, nextStatus } = useKYCContext()

  if (!companyDetail) return <Skeletton />

  return (
    <>
      <div className="2xl:flex 2xl:gap-x-6 mb-6">
        <IdentityHeaderCard
          className={twMerge('border-2 border-slate-50 rounded-sm mb-6 md:mb-0', cardClassName)}
        />
        <div className="mt-4 md:mt-6 2xl:mt-0 ">
          <OlkypassCard
            CTA={false}
            currentStatus={status}
            nextStatus={nextStatus}
            auditPending={auditPending}
          />
        </div>
      </div>

      <div>
        <div className="w-full 2xl:flex 2xl:gap-x-6">
          <FormAddCompany
            className={'w-full'}
            fields={{
              company: {
                disabled: true
              },
              address: {
                disabled: true
              },
              activity: {
                disabled: true
              }
            }}
            data={CompanyConverters.DTO_Create(company!)}
            onChange={() => {}}
            onValidated={() => {}}
          />
          <div className="2xl:w-1/4 lg:min-w-[456px]"></div>
        </div>
      </div>
    </>
  )
}
