import { Button } from '@/atoms-react/button/Button.react'
import Image from '@/atoms-react/image/Image.react'
import { Base, Title } from '@/atoms-react/text/Text.react'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { useUser } from '@/features/user/hooks/use-user'
import { formatNumber } from '@/features/utils/functions'
import { getWebBankingUrl } from '@/features/utils/web-banking-url'
import { ModalIbanAccount } from '@/molecules/modal-iban-account/index.react'
import { ModalKYCIdentity } from '@/molecules/modal-kyc-identity/index.react'
import { Themes } from '@/types/theme'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

// type DiscoverOlkyCardProps = {
//   className?: string
// } & React.ComponentPropsWithoutRef<'div'>

export const DiscoverOlkyCard = () => {
  const olkyCardsClassName =
    'relative overflow-hidden  h-[170px] lg:h-[185px] xl:h-[195px] 2xl:h-[200px] rounded-sm w-full  xl:w-4/12'
  const olkyCardsClassName2 = 'w-full h-full px-5 py-7 flex justify-center'
  const baseTextClassName = 'text-white font-semibold mt-0 mb-2 z-10'

  const { t } = useTranslation(['pass'])
  const { id: companyId } = useCompanyContext()

  //const { status } = useKYCContext()
  const { useUserProfile } = useUser()
  const { data: user } = useUserProfile()

  const [modalIdentity, setModalIdentity] = useState<boolean>(false)

  const [isModalIbanOpen, setIsModalIbanOpen] = useState<boolean>(false)

  const openKYCModal = () => {
    setModalIdentity(true)
    setIsModalIbanOpen(false)
  }
  useEffect(() => {
    if (sessionStorage.getItem('DocumentQuizKyc')) {
      const data = JSON.parse(sessionStorage.getItem('DocumentQuizKyc') as string)
      if (data.quizIbanStep > 0) setIsModalIbanOpen(true)
    }
  }, [])
  const redirectToBankAccount = () => {
    let email = user?.naturalPerson.AliasEmail.find((email) => email.Actif === true)?.Mail
    if (!email && (user?.naturalPerson?.AliasEmail?.length as number) > 0) {
      email = user?.naturalPerson.AliasEmail[0].Mail
    }
    const url = getWebBankingUrl(email)
    const success = window.open(url, '_blank')
    if (!success) document.location = url
  }
  return (
    <>
      <ModalIbanAccount
        isOpen={isModalIbanOpen}
        onClose={() => {
          sessionStorage.removeItem('DocumentKsyc')
          sessionStorage.removeItem('DocumentQuizKyc')
          setIsModalIbanOpen(false)
        }}
        openKYCModal={openKYCModal}
      />
      {!user?.naturalPerson?.codeShop && !companyId && (
        <>
          <ModalKYCIdentity onClose={() => setModalIdentity(false)} open={modalIdentity} />
          <div
            className={twMerge(
              olkyCardsClassName,
              'relative overflow-hidden bg-cover bg-center  bg-[url("/images/olkypass/olkyPayConso.png")] '
            )}
          >
            <div className="absolute flex w-52 bottom-4 lg:bottom-5 right-0   xl:w-52	 xl:bottom-8  xl:right-0    2xl:right-0 	 gap-x-3 z-0   items-center justify-center">
              <div className="rounded-sm overflow-hidden">
                <Image src="/images/olkypay/cards/particular-card.png" alt="Individual card" />
              </div>
            </div>

            <div className={`${olkyCardsClassName2} flex-col items-left justify-between`}>
              <Base className={twMerge(baseTextClassName, 'text-left')}>
                {t('olkypass.general.olky_account_conso')}
              </Base>
              {user?.naturalPerson.olkypay_account_requested === 1 ? (
                <Base className={twMerge('mt-2 z-10 text-white text-left mb-4 w-52')}>
                  {' '}
                  {t('olkypass.general.olky_account_pending')}{' '}
                </Base>
              ) : (
                <div className="flex justify-start ">
                  <Button
                    size="md"
                    theme={Themes.white}
                    onClick={() => setIsModalIbanOpen(true)}
                    iconPosition="before"
                    className="border-none text-blue-500 mt-4 hover:bg-slate-50 dark:hover:bg-slate-50 text-base    dark:bg-white dark:text-blue-500 h-8  sm:h-10 w-48 text-xs sm:w-64 sm:text-base lg:h-8 xl:h-10 xl:text-lg   lg:text-sm xl:text-base  lg:w-72  text-black dark:text-black "
                  >
                    {t('olkypass.mainPage.olkypass.bloc2.btn')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {user?.naturalPerson?.codeShop && !companyId && (
        <div
          className={twMerge(
            'w-full rounded-sm relative overflow-hidden bg-cover bg-center   ',
            olkyCardsClassName,
            "bg-[url('/images/olkypass/olkyPayConso.png')]"
          )}
        >
          {/* Gradient behind the card for consumer card*/}
            <div className="absolute flex w-52 bottom-4 lg:bottom-5 right-0   xl:w-52	 xl:bottom-8  xl:right-0    2xl:right-0 	 gap-x-3 z-0   items-center justify-center">
              {user?.naturalPerson.olkypay_account_requested !== 1 && (
                <div className="rounded-sm overflow-hidden">
                  <Image src="/images/olkypay/cards/particular-card.png" alt="Individual card" />
                </div>
              )}
            </div>{' '}
          <div className="w-full h-full p-4 md:p-6 flex flex-col  ">
            <Base className={twMerge(baseTextClassName, 'mb-0')}>
              {' '}
              {companyId
                ? t('olkypass.general.olky_account_pro')
                : t('olkypass.general.olky_account_conso')}
            </Base>
            <Title className="text-white mb-0 w-max z-0 rounded-xl backdrop-blur">
              {formatNumber(
                parseFloat(
                  user.naturalPerson.olkyAccount?.balance
                    .replaceAll(' ', '')
                    .replaceAll(',', '.') as string
                )
              )}{' '}
              €
            </Title>
            <div className="mt-auto flex-1 flex items-end">
              <Button
                onClick={redirectToBankAccount}
                size="md"
                theme={Themes.white}
                className="border-none text-blue-500 mt-4 hover:bg-slate-50 dark:hover:bg-slate-50     dark:bg-white dark:text-blue-500 h-8  sm:h-10 text-xs w-96 md:w-96 sm:text-base lg:h-8 xl:h-10 xl:text-lg   lg:text-sm xl:text-base  lg:w-80  text-black dark:text-black "
                icon={ArrowTopRightOnSquareIcon}
              >
                {t('olkypass.mainPage.olkypass.bloc2.btn_access_olkypay')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
