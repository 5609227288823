import { useAuth } from '@/features/auth/hooks/use-auth-vue'
import { defineJsxComponent } from '@/jsx-utils'
import { applyPureReactInVue } from 'veaury'
import { OlkyPass as OlkyPassReact } from './ReactApp.react'

export default defineJsxComponent(() => {
  useAuth()
  const OlkyPass = applyPureReactInVue(OlkyPassReact)
  return () => <OlkyPass />
})
