import { CompanyDTO } from '@/features/company/types'
import { AddressType } from '@/features/user/types'
import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query'
import { DocumentApiClient } from '../api-client/api-client'
import { UploadDocument } from '../types'

export type useKYCProps = {
  company: CompanyDTO
  address: AddressType
}

export const useDocument = () => {
  const DocumentsQuery = (UUID: string | null) =>
    useQuery({
      queryKey: ['user', 'profile', 'documents'],
      queryFn: () => DocumentApiClient.getDocuments(UUID ?? ''),
      enabled: !!UUID
    })

  const DocumentsDetailsQuery = (UUID: string | null) =>
    useQuery({
      queryKey: ['user', 'profile', 'documents', 'details'],
      queryFn: () => DocumentApiClient.getDocumentsDetails(UUID ?? ''),
      enabled: !!UUID
    })

  const DocumentDetailsQuery = (UUID: string | null, algoregDocumentId: string) =>
    useQuery({
      queryKey: ['user', 'profile', 'document', 'details', algoregDocumentId],
      queryFn: () => DocumentApiClient.getDocumentDetail(UUID ?? '', algoregDocumentId),
      enabled: !!UUID
    })

  const DocumentQuery = (UUID: string | null, algoregDocumentId: string) =>
    useQuery({
      queryKey: ['user', 'profile', 'document', algoregDocumentId],
      queryFn: () => DocumentApiClient.getDocumentContent(UUID ?? '', algoregDocumentId),
      enabled: !!UUID
    })

  const DocumentTypesQuery = () =>
    useQuery({
      queryKey: ['user', 'profile', 'documents', 'types'],
      queryFn: () => DocumentApiClient.getDocumentTypes()
    })

  const UploadDocumentMutation = (options?: UseMutationOptions<any, Error, UploadDocument>) =>
    useMutation<any, Error, UploadDocument>({
      mutationFn: async (doc: UploadDocument) => DocumentApiClient.uploadDocument(doc),
      ...options
    })

  return {
    DocumentsQuery,
    DocumentsDetailsQuery,
    DocumentDetailsQuery,
    DocumentQuery,
    DocumentTypesQuery,
    UploadDocumentMutation
  }
}
