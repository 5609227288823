import { useCompany } from '@/features/company/hooks/use-react-company'
import { useCallback, useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'

let contextCompanyId: string | null = localStorage.getItem('contextCompanyId') || null

export const getContextCompanyId = () => contextCompanyId

export const ContextCompanyAtom = atom<string | null>({
  key: 'ContextCompanyAtom',
  default: getContextCompanyId()
})

export const useCompanyContext = () => {
  const [id, setCompany] = useRecoilState(ContextCompanyAtom)

  const { companiesQuery, companyDetailQuery } = useCompany()
  const { data: companies } = companiesQuery()

  const company = (companies || []).find(c => c.identity_uuid === id)
  const { data: detailedCompany } = companyDetailQuery(company?.identity_uuid ?? null)

  const change = useCallback(
    (companyId: string | null) => {
      if (companyId !== id) {
        localStorage.setItem('contextCompanyId', companyId || '')
        contextCompanyId = companyId
        setCompany(companyId)
      }
    },
    [setCompany, id]
  )

  useEffect(() => {
    if (id && (companies || []).length && !company) {
      change(null)
    }
  }, [companies, id, change])

  return {
    company,
    companyDetail: detailedCompany,
    companies,
    id,
    change
  }
}
