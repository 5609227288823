import { fetchServer } from '@/features/utils/fetch'
import type { CollaboratorType, LINKNPLEType, NaturalPersonType } from '@/features/company/types'

export class CollaboratorApiClient {
  /**
   *
   */
  static async fetchListOfCollaborators(identity_uuid: string | string[]) {
    const response = await fetchServer('/collaborator/list', {
      method: 'POST',
      body: {
        identity_uuid
      }
    })
    const res = await response.json()
    try {
      return res as { success: boolean; data: Array<CollaboratorType> }
    } catch (error) {
      return res as { success: false; data: '' }
    }
  }

  /**
   *
   * @param keyword
   */
  static async searchAboutCollaborator(keyword: string) {
    const response = await fetchServer('/collaborator/search', {
      method: 'POST',
      body: {
        keyword
      }
    })
    const res = await response.json()
    try {
      return res as { success: boolean; data: NaturalPersonType }
    } catch (error) {
      return res as { success: false; data: '' }
    }
  }

  /**
   *
   * @param collaborator
   */
  static async editCollaborator(collaborator: LINKNPLEType) {
    const response = await fetchServer('/collaborator/edit', {
      method: 'POST',
      body: {
        collaborator
      }
    })
    const res = await response.json()
    try {
      return res as { success: boolean; data: ''; message: '' }
    } catch (error) {
      return res as { success: false; data: ''; message: string }
    }
  }
}
