import PropTypes from 'prop-types'

/**
 * <img> props, https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img
 */
const htmlImgProps = {
  crossorigin: PropTypes.string,
  height: PropTypes.string,
  ismap: PropTypes.bool,
  longdesc: PropTypes.string,
  referrerpolicy: PropTypes.string,
  sizes: PropTypes.string,
  srcset: PropTypes.string,
  title: PropTypes.string,
  usemap: PropTypes.string,
  width: PropTypes.string
}

enum DECODING {
  AUTO = 'auto',
  ASYNC = 'async',
  SYNC = 'sync'
}

enum FETCHPRIORITY {
  AUTO = 'auto',
  HIGH = 'high',
  LOW = 'low'
}
enum LOADING {
  EAGER = 'eager',
  LAZY = 'lazy'
}

export { htmlImgProps, DECODING, FETCHPRIORITY, LOADING }
