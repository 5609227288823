<template>
  <ion-page id="RibPage" class="tracking-normal">
    <ion-content v-if="!isSkeletonLoading" :fullscreen="true">
      <header-app-with-label :title="$t('olkypay.labels.ribPage.header')"></header-app-with-label>
      <ion-grid class="ion-margin-bottom">
        <ion-row>
          <ion-col>
            <div class="card card-summary">
              <div class="card-body mx-2">
                <ion-row class="mt-3">
                  <ion-col>
                    <span class="text-xs text-white">{{ $t('olkypay.labels.ribPage.name') }}</span>
                    <h4 class="text-white mt-1 mb-0 font-weight-bold">
                      {{ ribInfo.beneficiaryName }}
                    </h4>
                  </ion-col>
                </ion-row>
                <ion-row class="mt-3">
                  <ion-col>
                    <span class="text-xs text-white">{{ $t('olkypay.labels.ribPage.iban') }}</span>
                    <h4 class="text-white mt-1 mb-0 font-weight-bold">{{ formattedIBAN }}</h4>
                  </ion-col>
                  <ion-col size="1" class="text-white mt-4">
                    <ion-icon :icon="copySharp" @click="copyTextToClipboard('iban')"></ion-icon>
                  </ion-col>
                </ion-row>
                <ion-row class="mt-3 mb-2">
                  <ion-col>
                    <span class="text-xs text-white">{{ $t('olkypay.labels.ribPage.bic') }}</span>
                    <h4 class="text-white mt-1 mb-3 font-weight-bold">{{ ribInfo.ics }}</h4>
                  </ion-col>
                  <ion-col size="1" class="text-white mt-4">
                    <ion-icon :icon="copySharp" @click="copyTextToClipboard('ics')"></ion-icon>
                  </ion-col>
                </ion-row>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="mt-3">
          <ion-col>
            <button class="btn manager-buttons" @click="copyFullInformation">
              <ion-icon slot="start" :icon="copySharp" color="blue_olky" size="small"></ion-icon>
              {{ $t('olkypay.buttons.ribPage.copyInfo') }}
            </button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <button class="btn manager-buttons" @click="downloadIban">
              <ion-icon
                slot="start"
                :icon="cloudDownload"
                color="blue_olky"
                size="small"
              ></ion-icon>
              {{ $t('olkypay.buttons.ribPage.downloadRib') }}
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <full-skeleton v-else></full-skeleton>
    <info-modal ref="infoModal"></info-modal>
  </ion-page>
</template>

<script lang="ts">
import HeaderAppWithLabel from '../../components/headers/HeaderAppWithLabel.vue'
import FullSkeleton from '../../components/skeleton/FullSkeleton.vue'
import InfoModal from '../../components/InfoModal.vue'
import { saveAs } from 'file-saver'
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  toastController,
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail
} from '@ionic/vue'
import { mapActions, mapGetters } from 'vuex'
import { copy, cloudDownload, clipboard, copySharp } from 'ionicons/icons'
import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { Clipboard } from '@capacitor/clipboard'
export default {
  name: 'RibPage',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    toastController,
    IonButton,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonSkeletonText,
    IonThumbnail,
    HeaderAppWithLabel,
    FullSkeleton,
    InfoModal
  },
  data() {
    return {
      copy,
      cloudDownload,
      clipboard,
      copySharp
    }
  },
  methods: {
    ...mapActions('supplier', ['loadRibInfo', 'downloadPdfIban']),
    ...mapActions('loader', [
      'playSkeletonLoader',
      'stopSkeletonLoader',
      'playLoader',
      'stopLoader'
    ]),
    async copyTextToClipboard(item) {
      const value = this.ribInfo[item]
      if (Capacitor.isNativePlatform()) {
        await Clipboard.write({
          string: value
        })
      } else {
        await navigator.clipboard.writeText(value)
      }

      const toast = await toastController.create({
        message: this.$i18n.t('olkypay.notifications.success.ribPage.copyText.' + item),
        duration: 3000,
        position: 'bottom',
        color: 'success'
      })
      await toast.present()
    },
    async downloadIban() {
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.downloadIban.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      const file = await this.downloadPdfIban({ supplier_id: this.currentSupplier.id })
      const randomNumber = Math.floor(Math.random() * 10)
      const filename = `download_${randomNumber}.pdf`
      let download_result = true
      let dialogContent = this.$i18n.t('olkypay.notifications.success.ribPage.downloadIban.message')
      if (Capacitor.isNativePlatform()) {
        const document_path = Directory.Documents + '/' + filename
        download_result = await this.saveDownloadedFileAsPdf(file, filename)
        dialogContent +=
          '\n' +
          this.$i18n.t('olkypay.notifications.success.ribPage.downloadIban.sub_message') +
          '\n' +
          document_path
      } else {
        saveAs(file, filename)
      }
      this.stopLoader()
      if (download_result) {
        const successDialog = {
          type: 'success',
          title: this.$i18n.t('olkypay.notifications.success.ribPage.downloadIban.header'),
          content: dialogContent,
          button: this.$i18n.t('olkypay.buttons.loginPage.dialog.close')
        }
        if (await this.$refs.infoModal.openModal(successDialog)) {
          // open file
        }
      } else {
        const failedDialog = {
          type: 'warning',
          title: this.$i18n.t('olkypay.notifications.error.ribPage.downloadIban.header'),
          content: this.$i18n.t('olkypay.notifications.error.ribPage.downloadIban.message'),
          button: this.$i18n.t('olkypay.buttons.loginPage.dialog.close')
        }
        await this.$refs.infoModal.openModal(failedDialog)
      }
    },
    convertBlobToBase64(blob: Blob): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onerror = reject
        reader.onload = () => resolve(reader.result as string)
        reader.readAsDataURL(blob)
      })
    },
    async saveDownloadedFileAsPdf(blobData, fileName) {
      try {
        const base64Data = await this.convertBlobToBase64(blobData)
        const path = fileName
        await Filesystem.writeFile({
          path,
          data: base64Data,
          directory: Directory.Documents,
          recursive: true
        })
        return true // You can return the file path if needed
      } catch (error) {
        return false
      }
    },
    async copyFullInformation() {
      let information = this.$i18n.t('olkypay.labels.ribPage.name') + '\n'
      information += this.ribInfo.beneficiaryName + '\n\n'
      information += this.$i18n.t('olkypay.labels.ribPage.iban') + '\n'
      information += this.ribInfo.iban + '\n\n'
      information += this.$i18n.t('olkypay.labels.ribPage.bic') + '\n'
      information += this.ribInfo.ics
      if (Capacitor.isNativePlatform()) {
        await Clipboard.write({
          string: information
        })
      } else {
        await navigator.clipboard.writeText(information)
      }

      const toast = await toastController.create({
        message: this.$i18n.t('olkypay.notifications.success.ribPage.copyText.full_info'),
        duration: 3000,
        position: 'bottom',
        color: 'success'
      })
      await toast.present()
    }
  },
  async mounted() {
    this.playSkeletonLoader()
    await this.loadRibInfo()
    this.stopSkeletonLoader()
  },
  computed: {
    ...mapGetters('supplier', ['ribInfo', 'currentSupplier']),
    ...mapGetters('loader', ['isSkeletonLoading']),
    formattedIBAN() {
      if (this.ribInfo.iban) {
        return this.ribInfo.iban.match(/.{1,4}/g).join(' ')
      }
      return ''
    }
  }
}
</script>
