import InputLabel from '@/atoms-react/input/Input.react'
import { Alert, AlertType } from '@/atoms-react/alert/index.react'
import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import CodeInput from '@/atoms-react/input/CodeInput.react'
import { Base, SectionTitleSmall } from '@/atoms-react/text/Text.react'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import useTryConnectionMutation from '@/features/security/hooks/use-try-connection'
import useAuthenticateWebAuthnDataMutation from '@/features/flow/hooks/use-authenticate-web-authn-data'
import useAddTraceMutation from '@/features/security/hooks/use-add-trace'
import useUpdateTraceMutation from '@/features/security/hooks/use-update-trace'
import { SecurityApiClient } from '@/features/security/api-client/api-client.ts'
import useRequestSensitiveEmailMutation from '@/features/security/hooks/use-request-sensitive-email-code.ts'
import useValidateSensitiveEmailMutation from '@/features/security/hooks/use-validate-sensitive-email-code.ts'

enum SolidAuthnFormStep {
  WebAuthn,
  OTP2fa,
  OTPEmail,
  Success
}

type SolidAuthnFormProps = {
  action: string
  actionData?: string
  onValidated?: () => void
}

export const SolidAuthnForm = ({
  action,
  actionData,
  onValidated = () => {}
}: SolidAuthnFormProps) => {
  const { t } = useTranslation(['pass'])
  const codeOtpLength = 6
  const codeEmailLength = 6
  const { cached } = useAuth()
  const [password, setPassword] = useState<string>()
  const [actionType] = useState<string>(action)
  const [uuidSensitiveActionValidation, setUuidSensitiveActionValidation] = useState<string>()
  const [codeOtp, setCodeOtp] = useState<string>('')
  const [codeEmail, setCodeEmail] = useState<string>('')
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)
  const [isCodeOtpValid, setIsCodeOtpValid] = useState<boolean>(false)
  const [isFailWebauthn, setIsFailWebauthn] = useState<boolean>(false)
  const [isCodeEmailValid, setIsCodeEmailValid] = useState<boolean>(false)
  const [step, setStep] = useState<SolidAuthnFormStep>(SolidAuthnFormStep.WebAuthn)
  const [error, setError] = useState<string | null>(null)
  const { mutateAsync: sendCode } = useRequestSensitiveEmailMutation()
  const { mutateAsync: tryConnect, isPending: isConnected } = useTryConnectionMutation()
  const { mutateAsync: validateEmailCode, isPending: isValidatedEmail } =
    useValidateSensitiveEmailMutation()
  const {
    mutate: webauthnLoginData,
    data: webauthnToken,
    isPending: isPendingWebauthn
  } = useAuthenticateWebAuthnDataMutation()
  const { mutateAsync: openDemandOfAuthentication } = useAddTraceMutation()
  const { mutateAsync: updateDemandOfAuthentication } = useUpdateTraceMutation()

  useEffect(() => {
    if (codeOtp.length === codeOtpLength) {
      setIsCodeOtpValid(true)
      tryConnection()
    } else {
      setIsCodeOtpValid(false)
    }
  }, [codeOtp])

  useEffect(() => {
    if (codeEmail.length === codeEmailLength) {
      setIsCodeEmailValid(true)
      onSubmitSolidAuthnForm()
    } else {
      setIsCodeEmailValid(false)
    }
  }, [codeEmail])

  useEffect(() => {
    if (step === SolidAuthnFormStep.WebAuthn) {
      console.log('Call to tryConnectionWebauthn function in step ' + step)
      tryConnectionWebauthn()
    }
  }, [step])

  const tryConnectionWebauthn = async () => {
    const is2faActive = await SecurityApiClient.is2faActive()
    try {
      setError(null)
      const email = cached?.data?.email
      if (!email) {
        throw new Error()
      }
      // console.log('Call to tryConnectWebauthn function with email ' + email)
      const response = await openDemandOfAuthentication()
      const demandID = response.id
      // console.log('Detect traceID in userAuth : ' + demandID)
      webauthnLoginData(email, {
        onSuccess: async (webauthnToken) => {
          // console.log('OnSuccess webauthnLogin', webauthnToken)
          if (webauthnToken) {
            const params = {
              id: demandID,
              success: true,
              web_authn_signing_action_id: webauthnToken?.signing_action_id,
              user_otp_input: null
            }
            await updateDemandOfAuthentication(params)
            const { data } = await sendCode({
              email: email,
              actionType: actionType,
              actionData: actionData
            })
            setUuidSensitiveActionValidation(data?.uuid)
            setStep(SolidAuthnFormStep.OTPEmail)
          } else if (is2faActive) {
            setStep(SolidAuthnFormStep.OTP2fa)
          } else {
            setIsFailWebauthn(true)
          }
        },
        onError: async (e: any) => {
          console.log('Error', e.message)
          const params = {
            id: demandID,
            success: false,
            web_authn_signing_action_id: webauthnToken?.signing_action_id,
            user_otp_input: null
          }
          await updateDemandOfAuthentication(params)
          setError(e.message)
          if (is2faActive) {
            setStep(SolidAuthnFormStep.OTP2fa)
          } else {
            setIsFailWebauthn(true)
          }
        }
      })
    } catch (e: any) {
      console.log('Error', e.message)
      setError(e.message)
      if (is2faActive) {
        setStep(SolidAuthnFormStep.OTP2fa)
      } else {
        setIsFailWebauthn(true)
      }
    }
  }

  const tryConnection = async () => {
    try {
      setError(null)
      const email = cached?.data?.email
      if (!email) {
        throw new Error()
      }
      if (!password || !codeOtp) {
        throw new Error(t('olkypass.forgetPasswordPage.notifications.error.reset'))
      }
      // console.log('Call to tryConnection function with email ' + email)
      const response = await openDemandOfAuthentication()
      const demandID = response.id
      await tryConnect({ email, password, codeOtp })
      const params = {
        id: demandID,
        success: true,
        web_authn_signing_action_id: null,
        user_otp_input: codeOtp
      }
      await updateDemandOfAuthentication(params)
      const { data } = await sendCode({
        email: email,
        actionType: actionType,
        actionData: actionData
      })
      setUuidSensitiveActionValidation(data?.uuid)
      setStep(SolidAuthnFormStep.OTPEmail)
    } catch (e: any) {
      console.log(e.message)
      setError(e.message)
    }
  }

  const onSubmitSolidAuthnForm = async () => {
    try {
      setError(null)
      if (!uuidSensitiveActionValidation || !codeEmail) {
        throw new Error(t('olkypass.forgetPasswordPage.notifications.error.reset'))
      }
      await validateEmailCode({ uuidSensitiveActionValidation, code: codeEmail, actionType })
      onValidated()
    } catch (e: any) {
      console.log(e.message)
      //setError(e.message)
      setError(t('olkypass.securityPage.passwordSection.2fa.modal.notifications.otp.code_mismatch'))
    }
  }

  return (
    <div>
      {step === SolidAuthnFormStep.WebAuthn && (
        <Column>
          <div className="grow mb-12 flex flex-col">
            {error && <Alert className="my-4" type={AlertType.Danger} title={error} />}
            <InputLabel
              type="email"
              className={'my-2'}
              value={cached?.data?.email}
              disabled
              label={t('flow.id_control.input.email_address')}
            />
          </div>

          <div className="shrink-0">
            <Button
              shortcut="enter"
              onClick={tryConnectionWebauthn}
              disabled={!isFailWebauthn}
              loading={isPendingWebauthn}
            >
              {t('olkypass.general.continue')}
            </Button>
          </div>
        </Column>
      )}
      {step === SolidAuthnFormStep.OTP2fa && (
        <Column>
          <div className="grow mb-12 flex flex-col">
            {error && <Alert className="my-4" type={AlertType.Danger} title={error} />}
            <InputLabel
              type="email"
              className={'my-2'}
              value={cached?.data?.email}
              disabled
              label={t('flow.id_control.input.email_address')}
            />
            <InputLabel
              autoFocus
              type="password"
              placeholder={t('flow.id_control.input.placeholder_password')}
              className={'my-2'}
              value={password}
              autoComplete="current-password"
              onChange={(value) => {
                setPassword(value)
                if (value.length > codeOtpLength) {
                  setIsPasswordValid(true)
                }
              }}
              label={t('flow.id_control.input.password')}
            />
            <div className="flex flex-col items-left gap-4">
              <SectionTitleSmall type="label" className={'text-sm'}>
                {t('flow.id_control.label.two_fa_description')}
              </SectionTitleSmall>
              <CodeInput
                codeNumberOfCharacters={codeOtpLength}
                label={t('olkypass.securityPage.passwordSection.password.title')}
                className={'mb-6'}
                onChange={(code) => setCodeOtp(code)}
                loading={isConnected}
              />
            </div>
          </div>

          <div className="shrink-0">
            <Button
              shortcut="enter"
              onClick={tryConnection}
              disabled={!isPasswordValid || !isCodeOtpValid}
              loading={isConnected}
            >
              {t('olkypass.general.continue')}
            </Button>
          </div>
        </Column>
      )}
      {step === SolidAuthnFormStep.OTPEmail && (
        <Column>
          <div className="grow mb-12 flex flex-col">
            {error && <Alert className="my-4" type={AlertType.Danger} title={error} />}
            <div className="flex flex-col items-center gap-4">
              <SectionTitleSmall>
                {t('flow.id_control.signup.label.verify_email_title')}
              </SectionTitleSmall>
              <Base>
                <>
                  {t('flow.id_control.signup.label.verify_email_description_1')}{' '}
                  {t('flow.id_control.signup.label.verify_email_description_2')}
                </>
              </Base>
              <CodeInput
                codeNumberOfCharacters={codeEmailLength}
                className={'mb-6'}
                focus={true}
                onChange={(codeEmail) => setCodeEmail(codeEmail)}
                loading={isValidatedEmail}
              />
            </div>
          </div>

          <div className="shrink-0">
            <Button
              shortcut="enter"
              onClick={onSubmitSolidAuthnForm}
              disabled={!isCodeEmailValid}
              loading={isValidatedEmail}
            >
              {t('olkypass.general.continue')}
            </Button>
          </div>
        </Column>
      )}
    </div>
  )
}
