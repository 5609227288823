import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import Loader from '@/atoms-react/loader/Loader.react'
import { Base } from '@/atoms-react/text/Text.react'
import { DynamicBlobAnimation } from '@/components/animations/dynamic-blob/index.react'
import { useCompany } from '@/features/company/hooks/use-react-company'
import { Routes } from '@/features/router'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LayoutTitle } from '../_layout/index.react'
import { CompanyCard } from './components/company-card/index.react'
import _ from 'lodash'

type CompanyAppProps = React.ComponentPropsWithoutRef<'div'>

export const CompanyApp = ({ ...props }: CompanyAppProps) => {
  const { t } = useTranslation(['pass'])
  const navigate = useNavigate()
  const { companiesQuery } = useCompany()
  const { data: companies, isPending: isPendingCompanies } = companiesQuery()

  return <Column {...props}>
    <LayoutTitle
      action={
        <Button
          size="md"
          onClick={() => navigate(Routes.companies_add)}
          iconPosition={'after'}
          icon={(p: any) => <PlusCircleIcon {...p} />}
        >
          {t('business.button.add_company')}
        </Button>
      }
    >
      {t('business.title')}
    </LayoutTitle>

    {isPendingCompanies && <Loader center className={'w-8 h-8'} />}
    {!isPendingCompanies && companies && companies.length === 0 && <div className="flex flex-col items-center justify-center h-full grow pb-10">
        <DynamicBlobAnimation src="/images/olkypass/signup-business-bg.png" className="w-1/4" />
        <Base className="mt-3 text-slate-500 text-center max-w-lg">
          {t('business.label.no_company_message_part1')}
          <span
            className="text-blue-500 font-semibold hover:underline cursor-pointer"
            onClick={() => navigate(Routes.companies_add)}
          >
            {t('business.label.no_company_message_part2')}
          </span>
          .
        </Base>
      </div>}
    {!isPendingCompanies && companies && companies.length > 0 && <div className={'-m-2 h-full grow'}>
        {companies.map(company => <div key={company.uuid} className="mx-2 mb-4 md:m-2 md:inline-block">
          <CompanyCard company={company} />
        </div>)}
      </div>}
  </Column>
}
