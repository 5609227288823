import { Dropdown } from '@/atoms-react/dropdown/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import InputDate from '@/atoms-react/input/InputDate.react'
import { Skeletton } from '@/atoms-react/skeletton/index.react'
import { CompanyInfoCreate, CompanyRCSCreate } from '@/features/company/types'
import { useUser } from '@/features/user/hooks/use-user'
import { useLocale } from '@/features/utils/hooks/use-locale'
import { FormFieldGroup, getRealFieldGroup } from '@/features/utils/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import _ from 'lodash'

type FormAddCompanyInfoProps = {
  data: CompanyInfoCreate & CompanyRCSCreate
  fields?: FormFieldGroup<CompanyInfoCreate & CompanyRCSCreate>
  onChange: (data: CompanyInfoCreate & CompanyRCSCreate) => void
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>

export const FormAddCompanyInfo = ({
  data,
  fields,
  onChange = () => {}
}: FormAddCompanyInfoProps) => {
  const { t } = useTranslation(['pass'])

  const inputClassName = 'grow'

  const companyFields = getRealFieldGroup(
    ['name', 'legalForm', 'number', 'VATnumber', 'incorporationDate', 'url'],
    fields ?? {}
  )

  const { locale } = useLocale()
  const { useLegalStatus } = useUser()
  const { data: legalStatuses } = useLegalStatus(locale?.language)

  if (!companyFields) return <Skeletton />

  return <>
    {companyFields?.name?.visible && <InputLabel
        label={companyFields?.name?.label ?? t('business.input.company_name_label')}
        placeholder={
          companyFields?.name?.placeholder ?? t('business.input.company_name_placeholder')
        }
        required={companyFields?.name?.required}
        disabled={companyFields?.name?.disabled}
        value={data?.name ?? ''}
        onChange={(value: string) => onChange({ ...data, name: value ?? '' })}
      />}

    {companyFields?.legalForm?.visible && <Dropdown
        className={twMerge(inputClassName)}
        label={companyFields?.legalForm?.label ?? t('business.input.company_legal_form_label')}
        value={data?.legalForm ?? null}
        required={companyFields?.legalForm?.required}
        disabled={companyFields?.legalForm?.disabled}
        placeholder={
          companyFields?.legalForm?.placeholder ??
          t('business.input.company_legal_form_placeholder')
        }
        items={(legalStatuses?.legalStatus ?? []).map(status => ({
          key: status.id.toString(),
          value: status.defaultName
        }))}
        search={true}
        onChange={({ key }) => onChange({ ...data, legalForm: key })}
      />}

    {companyFields?.number?.visible && <InputLabel
        className={inputClassName}
        label={companyFields?.number?.label ?? t('business.new.input.rcs_code')}
        value={data?.number ?? ''}
        required={data?.number ? true : companyFields?.number?.required}
        disabled={companyFields?.number?.disabled}
        placeholder={companyFields?.number?.placeholder ?? t('business.new.input.rcs_code')}
        onChange={(val) => onChange({ ...data, number: val })}
      />}

    {companyFields?.incorporationDate?.visible && <InputDate
        className={inputClassName}
        label={
          companyFields?.incorporationDate?.label ?? data?.number
            ? t('business.new.input.rcs_date_label')
            : t('business.new.input.rcs_date_expected_label')
        }
        required={companyFields?.incorporationDate?.required}
        value={data?.incorporationDate ?? null}
        disabled={companyFields?.incorporationDate?.disabled}
        placeholder={
          companyFields?.incorporationDate?.placeholder ?? t('business.new.input.rcs_date_label')
        }
        onChange={(date) => {
          if (date && !Array.isArray(date)) {
            onChange({ ...data, incorporationDate: date })
          } else if (date == null) {
            onChange({ ..._.omit(data, 'incorporationDate') })
          }
        }}
      />}

    {companyFields?.VATnumber?.visible && <InputLabel
        className={inputClassName}
        required={companyFields?.VATnumber?.required}
        disabled={companyFields?.VATnumber?.disabled}
        placeholder={
          companyFields?.VATnumber?.placeholder ?? t('business.input.company_vat_placeholder')
        }
        label={companyFields?.VATnumber?.label ?? t('business.input.company_vat_label')}
        value={data?.VATnumber ?? ''}
        onChange={(value: string) => onChange({ ...data, VATnumber: value })}
      />}

    {companyFields?.url?.visible && <InputLabel
        className={inputClassName}
        label={companyFields?.url?.label ?? t('business.new.input.url_label')}
        required={companyFields?.url?.required}
        value={data?.url ?? ''}
        disabled={companyFields?.url?.disabled}
        placeholder={companyFields?.url?.placeholder ?? t('business.new.input.url_placeholder')}
        onChange={(value) => onChange({ ...data, url: value })}
      />}
  </>
}
