import { Shortcut, useShortcuts } from '@/features/utils/shortcuts'
import { Themes } from '@/types/theme'
import _ from 'lodash'
import React, { ElementType, MouseEventHandler, useCallback, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import Icon, { PREDEFINED_SIZE_MAP } from '../Icon/Icon.react'

export type ButtonProps = {
  size?: 'sm' | 'md' | 'lg'
  theme?: Themes
  loading?: boolean
  disabled?: boolean
  icon?: React.ReactNode | ElementType | any
  iconPosition?: 'before' | 'after'
  onClick: MouseEventHandler<HTMLButtonElement>
  shortcut?: Shortcut
  shortcutDisabled?: boolean
  contentProps?: React.ComponentPropsWithoutRef<'span'>
  dataTooltip?: string
} & React.ComponentPropsWithoutRef<'button'>

export const Button = ({
  className,
  size = 'lg',
  theme = Themes.primary,
  loading = false,
  disabled = false,
  shortcutDisabled = false,
  icon: IconElement,
  contentProps = {},
  ...props
}: ButtonProps) => {
  const [clickLoading, setClickLoading] = useState(false)
  loading = loading || clickLoading
  const nonInteractable = disabled || loading

  const onClick = useCallback(
    async (e: any) => {
      if (nonInteractable) return
      try {
        setClickLoading(true)
        if (props.onClick) await props.onClick(e)
      } finally {
        setClickLoading(false)
      }
    },
    [nonInteractable, props.onClick]
  )

  useShortcuts(props.shortcut ? [props.shortcut] : [], (e: any) => !shortcutDisabled && onClick(e))

  let colors = 'text-white bg-gradient-to-tr from-blue-500 to-blue-400 shadow-md'
  let hover =
    'transition-all before:absolute before:hover:bg-blue-500 before:transition-all before:ease-out before:h-full before:w-full'

  if (theme === Themes.danger) {
    colors = 'text-white bg-red-600 border-transparent shadow-md'
    hover =
      'transition-all before:absolute before:hover:bg-red-700 before:transition-all before:ease-out before:h-full before:w-full'
  }

  if (theme === Themes.success) {
    colors = 'text-white bg-green-500 border-transparent shadow-md'
    hover =
      'transition-all before:absolute before:hover:bg-green-600 before:transition-all before:ease-out before:h-full before:w-full'
  }

  if (theme === Themes.white) {
    colors =
      'text-black bg-white dark:bg-slate-900 border-solid border-2 border-slate-50 dark:border-slate-800 dark:text-white'
    hover =
      'transition-all before:absolute hover:bg-slate-50 dark:hover:bg-slate-800 before:transition-all before:ease-out before:h-full before:w-full'
  }
  if (theme === Themes.declarative) {
    colors =
      'text-black bg-white text-semibold  border-solid border-2 border-slate-50  '
    hover =
      'transition-all before:absolute hover:bg-slate-100  before:transition-all before:ease-out before:h-full before:w-full'
  }

  if (nonInteractable) colors += ' opacity-50 pointer-events-none'
  let sizes = 'h-12 px-6'
  if (size === 'md') {
    sizes = 'h-10 px-5'
  } else if (size === 'sm') {
    sizes = 'h-8 px-4'
  }

  const buttonClassName = twMerge(
    theme === Themes.iconButton && 'text-black dark:text-white',
    theme !== Themes.iconButton &&
      twMerge(
        'relative overflow-hidden inline-flex items-center justify-center w-full font-semibold rounded-full',
        hover,
        colors,
        sizes
      ),
    className
  )
  return <button
    data-tooltip={props.dataTooltip}
    type="button"
    className={buttonClassName}
    disabled={nonInteractable}
    onClick={onClick}
    {..._.omit(
      props,
      'children',
      'className',
      'onClick',
      'iconPosition',
      'icon',
      'size',
      'theme',
      'loading'
    )}
  >
    <span
      className={twMerge(
        theme !== Themes.iconButton &&
          'flex space-x-2 items-center justify-center z-10 overflow-hidden whitespace-nowrap text-ellipsis',
        contentProps.className
      )}
      {..._.omit(contentProps, 'className', 'iconPosition', 'icon', 'size', 'theme', 'loading')}
    >
      {loading && <ButtonLoader />}
      {props.iconPosition === 'before' &&
        IconElement &&
        typeof IconElement !== 'number' &&
        typeof IconElement !== 'boolean' && <ButtonIcon size={size} theme={theme} element={IconElement} />}
      <span className={'w-max truncate'}>{props.children}</span>
      {props.iconPosition !== 'before' &&
        IconElement &&
        typeof IconElement !== 'number' &&
        typeof IconElement !== 'boolean' && <ButtonIcon size={size} theme={theme} element={IconElement} />}
    </span>
  </button>
}

type ButtonLoaderProps = React.ComponentPropsWithoutRef<'svg'>

export const ButtonLoader = ({ ...props }: ButtonLoaderProps) => (
  <>
    <svg
      className="animate-spin mr-1 h-5 w-5"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </>
)

type ButtonIconsProps = {
  element: React.ReactNode
  size?: 'sm' | 'md' | 'lg'
  theme: Themes
} & React.ComponentPropsWithoutRef<'svg'>

export const ButtonIcon = ({ element: IconElement, size, theme, ...props }: ButtonIconsProps) => (
  <Icon
    size={size === 'md' ? PREDEFINED_SIZE_MAP.medium : PREDEFINED_SIZE_MAP.small}
    component={IconElement}
    className={twMerge(
      'stroke-2',
      size === 'md' && '!h-6 !w-6',
      size !== 'md' && '!h-8 !w-8',
      theme !== Themes.iconButton && '!h-5 !w-5'
    )}
    {..._.omit(props, 'size')}
  />
)
