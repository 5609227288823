import Column from '@/atoms-react/column/Column.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import { useKYC } from '@/features/kyc/hooks/use-kyc'
import { KYCDocumentType } from '@/features/kyc/types'
import React, { useState } from 'react'
import { ref } from 'vue'
import { getLanguage } from '@/features/utils/i18n.ts'
import { Base } from '@/atoms-react/text/Text.react'
import { useTranslation } from 'react-i18next'

type DocumentTypeSelectorProps = {
  onDocumentSelected: (documentType: KYCDocumentType) => void
} & React.ComponentPropsWithoutRef<'div'>

export const DocumentTypeSelector = ({
  onDocumentSelected,
  ...props
}: DocumentTypeSelectorProps) => {
  const userLanguage = ref(getLanguage())
  const { t } = useTranslation(['atoms'])
  const { KYCDocumentTypesQuery } = useKYC()
  const { data: KYCDocuments, isLoading: isLoadingKYCDocuments } = KYCDocumentTypesQuery()
  const [selectedDocumentTypeID, setSelectedDocumentTypeID] = useState<number | null>(null)
  const [selectedDocumentTypeName, setSelectedDocumentTypeName] = useState<string | null>(null)
  const passeportFrKey = 'Passeport'
  const onSelectedDocumentChange = (id: number) => {
    if (!id) return

    const document = KYCDocuments?.find((doc) => doc.id === id)
    if (!document) return

    setSelectedDocumentTypeID(id)
    setSelectedDocumentTypeName(document.type_fr)
    onDocumentSelected(document)
  }

  return (
    <Column {...props}>
      <div className="grow flex flex-col justify-between">
        <div className={'mb-1'}>
          {!isLoadingKYCDocuments && (
            <Dropdown
              className={'mb-2'}
              value={selectedDocumentTypeID ? selectedDocumentTypeID.toString() : null}
              onChange={(selected) => onSelectedDocumentChange(parseInt(selected.key))}
              items={
                KYCDocuments?.map((document) => ({
                  value:
                    userLanguage.value === 'fr'
                      ? document.type_fr === passeportFrKey
                        ? `${document.type_fr} (recommandé)`
                        : document.type_fr
                      : document.type_fr === passeportFrKey
                        ? `${document.type_en} (recommended)`
                        : document.type_en,
                  key: document.id.toString(),
                  groupKey: document.need_other_name ? '1' : '0'
                })) ?? []
              }
              hideUngrouped
              groups={[{ key: '0' }, { key: '1' }]}
            />
          )}
        </div>
      </div>
      <div>
        {selectedDocumentTypeName && selectedDocumentTypeName !== passeportFrKey && (
          <Base className="mb-1 text-sky-500">{t('molecules.modal_kyc_identity.helper')}</Base>
        )}
      </div>
    </Column>
  )
}
