import { Routes } from '@/features/router'
import { Button } from '@/atoms/buttons/Button'
import { Input } from '@/atoms/inputs/Input'
import { Link } from '@/atoms/link/Link2'
import { BaseSmall } from '@/atoms/text/Text.tsx'
import { LabelDecoration } from '@/atoms/inputs/decorations/LabelDecoration'
import { ref } from 'vue'
import { defineVueComponent } from '@/jsx-utils'
import { useI18n } from 'vue-i18n'
import { useLogin as useAuth } from '@/features/auth/hooks/use-login-vue'

export const FormForgetPasswordView = defineVueComponent(() => {
  const { t } = useI18n()
  const { loader, sendForgetPasswordLink } = useAuth()
  const email = ref('')
  return () => <div class="mt-6 text-left">
    <LabelDecoration class="my-4" label={t('login.forgetPasswordPage.input.email')}>
      {{
        input: (input: any) => <Input
          size="lg"
          placeholder="jean.dupont@mail.com"
          autocomplete="email"
          disabled={loader.loading}
          class={input.class}
          v-model={email.value}
        />
      }}
    </LabelDecoration>

    <div class="flex flex-row items-center w-full mt-6">
      <BaseSmall>
        <Link to={Routes.login}>{t('login.forgetPasswordPage.button.cancel')}</Link>
      </BaseSmall>

      <div class="grow" />

      <Button
        theme="primary"
        size="lg"
        disabled={!email.value}
        loading={loader.loading}
        onClick={() => sendForgetPasswordLink(email.value)}
      >
        {t('login.forgetPasswordPage.button.next')}
      </Button>
    </div>
  </div>
})
