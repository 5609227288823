import { Button } from '@/atoms/buttons/Button'
import { Checkbox } from '@/atoms/inputs/Checkbox'
import { Input } from '@/atoms/inputs/Input'
import { InputPhone } from '@/atoms/inputs/Phone'
import { LabelDecoration } from '@/atoms/inputs/decorations/LabelDecoration'
import { Link } from '@/atoms/link/Link2'
import { Notice } from '@/atoms/notice'
import { useRegister } from '@/features/register/hooks/use-register'
import type { RegisterCredentialType } from '@/features/register/types'
import { ref } from 'vue'
import { defineVueComponent } from '@/jsx-utils'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toast-notification'

export const FormCompleteCredentialView = defineVueComponent(() => {
  const { t } = useI18n()
  const toast = useToast()
  const { loader, registerStore, completeCredential } = useRegister()
  const validated_once = ref(false)
  const credentials = ref<RegisterCredentialType>({
    FirstName: '',
    LastName: '',
    email: registerStore.credentials?.email,
    telephone: '',
    password: registerStore.credentials?.password,
    confirm_password: registerStore.credentials?.confirm_password,
    accept_condition: ''
  })
  return () => <form
    class="mt-6 text-left"
    onSubmit={(e: any) => {
      e.preventDefault()
      validated_once.value = true

      if (!credentials.value.accept_condition) return
      if (
        !credentials.value.FirstName ||
        !credentials.value.LastName ||
        !credentials.value.email ||
        !credentials.value.telephone ||
        !credentials.value.password ||
        !credentials.value.password?.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)
      ) {
        toast.error(t('login.registerPage.validation.credentials_error'))
        return
      }

      completeCredential(false, credentials.value)
    }}
  >
    <div class="flex flex-row items-center w-full my-4 space-x-4">
      <LabelDecoration>
        {{
          label: () => <>{t('login.registerPage.input.firstName')}</>,
          input: (input: any) => <Input
            size="lg"
            placeholder="Jean"
            required
            disabled={loader.loading}
            class={input.class}
            v-model={credentials.value.FirstName}
          />
        }}
      </LabelDecoration>

      <LabelDecoration>
        {{
          label: () => <>{t('login.registerPage.input.lastName')}</>,
          input: (input: any) => <Input
            size="lg"
            placeholder="Dupont"
            required
            disabled={loader.loading}
            class={input.class}
            v-model={credentials.value.LastName}
          />
        }}
      </LabelDecoration>
    </div>

    <LabelDecoration class="my-4">
      {{
        label: () => <>{t('login.registerPage.input.telephone')}</>,
        input: () => <InputPhone size="lg" v-model={credentials.value.telephone} />
      }}
    </LabelDecoration>

    <LabelDecoration class="my-4">
      {{
        label: () => <>{t('login.registerPage.input.password')}</>,
        input: (input: any) => <Input
          size="lg"
          type="password"
          placeholder="••••••••"
          autocomplete="new-password"
          minLength={8}
          disabled={loader.loading}
          class={input.class}
          v-model={credentials.value.password}
        />
      }}
    </LabelDecoration>

    {credentials.value.password &&
      credentials.value.confirm_password &&
      !credentials.value.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/) && <Notice class="my-2" type="warning">
          {t('login.registerPage.validation.password_field')}
        </Notice>}

    <div class="my-8" />
    <LabelDecoration class="my-6">
      {{
        input: () => <Checkbox v-model={credentials.value.accept_condition} square="true">
          {{
            label: () => <>
              {t('login.registerPage._checkbox.agree') + ' '}
              <Link href="https://www.olky.eu/files/pass/cgu-olkypass-en.pdf" target="_blank">
                {t('login.registerPage._checkbox.terms')}
              </Link>
            </>
          }}
        </Checkbox>
      }}
    </LabelDecoration>

    {!credentials.value.accept_condition && validated_once.value && <Notice type="error">{t('login.registerPage.validation.agree_field')}</Notice>}

    <div class="flex flex-row items-center w-full mt-8">
      <div class="grow" />
      <Button theme="primary" type="submit" size="lg" loading={loader.loading}>
        {t('login.registerPage.button.sign_up')}
      </Button>
    </div>
  </form>
})
