<template>
  <v-card id="olkypayDashboardBox" class="dashboardBox olkypay" height="320px" width="auto">
    <v-card-text>
      <v-img height="228px" class="mx-auto" :src="olkypayIconPath"></v-img>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn
        variant="flat"
        color="primary"
        height="auto"
        class="text-none validationBtn"
        size="medium"
        @click="createOlkyPayAccount"
      >
        {{ $t('olkypass.mainPage.olkypass.bloc2.btn') }}
      </v-btn>
    </v-card-actions>
  </v-card>
  <DialogConfirm ref="dialogConfirm"></DialogConfirm>
  <DialogInfo ref="dialogInfo"></DialogInfo>
</template>

<script>
import { mapGetters } from '../../store'
import store from '../../store'
import DialogConfirm from '../DialogConfirm.vue'
import DialogInfo from '../DialogInfo.vue'

export default {
  name: 'DashboardOlkyPaySubscriptionBox',
  components: {
    DialogConfirm,
    DialogInfo
  },
  data: () => ({
    olkypayIconPath: '/images/olkypass/olkypay_card.svg'
  }),
  methods: {
    async createOlkyPayAccount() {
      if (this.naturalPerson.Address.length < 1) {
        this.$refs.dialogInfo.open(
          'accountPage.dialog.confirm.create_okypay_account.header',
          'accountPage.dialog.confirm.create_okypay_account.body_alert'
        )
      } else if (
        await this.$refs.dialogConfirm.open(
          'accountPage.dialog.confirm.create_okypay_account.header',
          'accountPage.dialog.confirm.create_okypay_account.body'
        )
      ) {
        store.dispatch('playLoader')
        await store.dispatch('createOlkyPayAccount', this.naturalPerson)
        await store.dispatch('setAccessibleApplications')
        await store.dispatch('setCurrentNaturalPerson')
        store.dispatch('stopLoader')
      }
    }
  },
  computed: {
    ...mapGetters(['loading', 'naturalPerson'])
  }
}
</script>
