import { Button } from '@/atoms-react/button/Button.react'
import Column from '@/atoms-react/column/Column.react'
import InputPhone, { PhoneNumber } from '@/atoms-react/input/InputPhone.react'
import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import { FRENCH_CODE, FRENCH_CODE_SHORT } from '@/features/utils/constants'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FlowHeader } from '../../../flow-header.react'
import { Checkbox } from '@/atoms-react/input-checkbox/index.react'
import config from '@/config'
import { A } from '@/atoms-react/a/A.react'
import { ref } from 'vue'
import { getLanguage } from '@/features/utils/i18n.ts'

interface EnterPhoneProps extends LayoutBaseProps {
  onValidated?: (data: EnterPhoneOutput) => void
}

export type EnterPhoneOutput = {
  phone: PhoneNumber
}

function EnterPhone({ onValidated = () => {} }: EnterPhoneProps) {
  const { t } = useTranslation(['pass'])
  const [cgu, setCgu] = useState(false)
  const [phone, setPhone] = useState<PhoneNumber>({
    phone: '',
    valid: false,
    international: '',
    countryCode: FRENCH_CODE,
    countryPrefix: '+33'
  })
  const userLanguage = ref(getLanguage())
  function execute() {
    if (!phone?.phone || !phone?.valid) {
      toast.error(t('flow.id_control.error.must_enter_fields'))
      return
    }

    onValidated({ phone })
  }

  return <>
    <Column>
      <div className="grow mb-12">
        <FlowHeader
          title={t('flow.id_control.signup.label.enter_phone_title')}
          description={t('flow.id_control.signup.label.enter_phone_description')}
        />
        <InputPhone
          autoFocus
          value={phone}
          autoComplete="tel"
          onChange={value => setPhone(value)}
          initialCountry={FRENCH_CODE_SHORT}
        />

        <br />
        <br />
        <Checkbox value={cgu} onChange={(value) => setCgu(value)}>
          <span>
            {t('flow.id_control.signup.label.cgu_accept_1') + ' '}
            <A
              href={userLanguage.value === 'fr' ? config.OLKYPASS_CGU_FR : config.OLKYPASS_CGU_EN}
              target="_blank"
              rel="noreferrer"
            >
              {t('flow.id_control.signup.label.cgu_accept_2_link')}
            </A>
            {t('flow.id_control.signup.label.cgu_accept_3')}
          </span>
        </Checkbox>
      </div>
      <Button
        disabled={!phone?.phone || !phone?.valid || !cgu}
        shortcut="enter"
        className="shrink-0"
        onClick={() => execute()}
        icon={ChevronRightIcon}
      >
        {t('flow.id_control.input.continue')}
      </Button>
    </Column>
  </>
}

export default EnterPhone
