import { RegisterApiClient } from '@/features/register/api-client/api-client'
import { ConfirmationResponse } from '@/features/register/types'
import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'

export type CompleteCredentialsMutationInput = {
  FirstName: string | null
  LastName: string | null
  email: string | null | undefined
  telephone: string | null
  password: string | null | undefined
}

export function useCompleteCredentials(
  options?: UseMutationOptions<ConfirmationResponse, Error, CompleteCredentialsMutationInput>
) {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { t, i18n } = useTranslation(['pass'])

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available')
      return
    }

    return executeRecaptcha('login')
  }, [executeRecaptcha])

  return useMutation<ConfirmationResponse, Error, CompleteCredentialsMutationInput>({
    mutationFn: async (props: CompleteCredentialsMutationInput) => {
      const token = await handleReCaptchaVerify()
      const data = await RegisterApiClient.completeCredential({
        ...props,
        confirm_password: props.password,
        captcha_token: token,
        language: i18n.language,
        accept_condition: 'true'
      })

      if (!data.success) {
        throw new Error(t('flow.id_control.error.complete_credentials'))
      }

      return data
    },
    ...options
  })
}
