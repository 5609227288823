import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import i18n from '@/features/utils/i18n.ts'
import _ from 'lodash'
const uris = {
  _get: {
    companies: '/company/list'
  },
  _post: {
    company_detail: '/company/detail',
    associate_collaborator: 'company/associate_collaborator',
    disassociate_collaborator: 'company/disassociate_collaborator',
    reassociate_collaborator: 'company/reassociate_collaborator',
    company_new: 'company/new'
  },
  _put: {
    company_edit: '/company/edit'
  }
}
const state = {
  companies: [],
  company: {
    DateOfIncorporation: {
      date: ''
    },
    Address: [],
    AliasIBAN: [],
    AliasEmail: [],
    AliasMobile: []
  },
  inMemoryCompany: {
    DateOfIncorporation: {
      date: ''
    },
    Address: [],
    AliasIBAN: [],
    AliasEmail: [],
    AliasMobile: []
  }
}

const getters = {
  companies: state => state.companies,
  company: state => state.company,
  inMemoryCompany: state => state.inMemoryCompany
}

const mutations = {
  LOAD_NP_COMPANIES(state, response) {
    state.companies = response.data
  },
  RESET_LOADED_NP_COMPANIES(state) {
    state.companies = []
  },
  SET_CURRENT_COMPANY(state, response) {
    if (response.data.company.DateOfIncorporation === null) {
      response.data.company.DateOfIncorporation = { date: '' }
    }
    state.company = response.data.company
    if (state.company.DateOfIncorporation.date !== undefined) {
      const properties = state.company.DateOfIncorporation.date.split(' ')
      state.company.DateOfIncorporation.date = properties[0]
    }
    state.inMemoryCompany = _.cloneDeep(state.company)
    this.dispatch('setCurrentUUID', state.company.UUID)
    this.dispatch('setCurrentKYCState', response.data.olkyPassLevel)
  },
  SET_COMPANY_PARTIAL_DATA() {
    this.dispatch('addNotification', {
      type: 'success',
      message: i18n().global.t('olkypass.accountPage.notifications.success.profile.update')
    })
  },
  ASSOCIATE_NEW_COLLABORATOR() {
    this.dispatch('addNotification', {
      type: 'success',
      message: i18n().global.t('olkypass.accountPage.notifications.success.profile.update')
    })
  },
  CREATE_NEW_COMPANY(state, response) {
    this.dispatch('addNotification', {
      type: 'success',
      message: i18n().global.t('olkypass.accountPage.notifications.success.profile.update')
    })
    this.dispatch('setCurrentCompany', { UUID: response.data.UUID })
  },
  DISASSOCIATE_COLLABORATOR() {
    this.dispatch('addNotification', {
      type: 'success',
      message: i18n().global.t('olkypass.accountPage.notifications.success.profile.update')
    })
  },
  RE_ASSOCIATE_COLLABORATOR() {
    this.dispatch('addNotification', {
      type: 'success',
      message: i18n().global.t('olkypass.accountPage.notifications.success.profile.update')
    })
  }
}

const actions = {
  async loadNPCompanies({ commit }) {
    try {
      commit('RESET_LOADED_NP_COMPANIES')
      const response = await fetchServer(uris._get.companies)
      commit('LOAD_NP_COMPANIES', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async setCurrentCompany({ commit }, data) {
    try {
      const response = await fetchServer(uris._post.company_detail, { body: data })
      commit('SET_CURRENT_COMPANY', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async setCompanyPartialData({ commit }, data) {
    try {
      await fetchServer(uris._put.company_edit, { method: 'PUT', body: data })
      commit('SET_COMPANY_PARTIAL_DATA', data)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async associateNewCollaborator({ commit }, data) {
    try {
      const response = await fetchServer(uris._post.associate_collaborator, { body: data })
      commit('ASSOCIATE_NEW_COLLABORATOR', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async createNewCompany({ commit }, data) {
    try {
      const response = await fetchServer(uris._post.company_new, { body: data })
      commit('CREATE_NEW_COMPANY', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async disassociateCollaborator({ commit }, data) {
    try {
      const response = await fetchServer(uris._post.disassociate_collaborator, { body: data })
      commit('DISASSOCIATE_COLLABORATOR', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async reassociateCollaborator({ commit }, data) {
    try {
      const response = await fetchServer(uris._post.reassociate_collaborator, { body: data })
      commit('RE_ASSOCIATE_COLLABORATOR', response)
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
