import { defineStore } from 'pinia'

export const useSecurityStore = defineStore('security', {
  state: () => ({
    is2faActive: false,
    isWebAuthnActive: false,
    webAuthnUserDevices: [] as any[],
    loading: true,
    initialized: false
  })
})
