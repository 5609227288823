<script setup lang="ts">
import { StorageService } from '@/features/utils/storage.ts'
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { RouterView } from 'vue-router'
import { useRouter } from 'vue-router'
const router = useRouter()

const isFirstTime = StorageService.getItem('firstTimeUseApp')
if (!isFirstTime) {
  StorageService.setItem('firstTimeUseApp', 'true')
  router.push({ name: 'OnBoardingRouter' })
}
</script>

<template>
  <IonApp>
    <RouterView v-if="router.currentRoute.value.meta.noIonic" />
    <IonRouterOutlet v-if="!router.currentRoute.value.meta.noIonic" />
  </IonApp>
</template>
