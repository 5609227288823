import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { FlowApiClient } from '@/features/flow/api-client/api-client.ts'
import { PartnerMutationResponse } from '@/features/auth/types.ts'

export type PartnerMutationProps = {
  uuid: string
}

export default function useCheckPartnerUUIDMutation(
  options?: UseMutationOptions<PartnerMutationResponse, Error, PartnerMutationProps>
) {
  return useMutation<PartnerMutationResponse, Error, PartnerMutationProps>({
    mutationFn: async ({ uuid }) => {
      const response = await FlowApiClient.getPartnerPerUUID(uuid)
      return response
    },
    ...options
  })
}
