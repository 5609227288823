import { A } from '@/atoms-react/a/A.react'
import { Button } from '@/atoms-react/button/Button.react'
import { Dropdown } from '@/atoms-react/dropdown/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { Info } from '@/atoms-react/text/Text.react'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { useKypay } from '@/features/kypay/hooks/use-kypay'
import { Routes } from '@/features/router'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatCurrencyInput, formatNumber } from '@/features/utils/functions.ts'
import { SolidAuthnModal } from '../../../identity/components/security/solid-authn-modal.react'

export const ModalWithdrawBank = ({ ...props }: ModalProps) => {
  const { t } = useTranslation(['wallet'])
  const [amountString, setAmountString] = useState<string>('0,00')
  const [amount, setAmount] = useState<number | null>(null)
  const [aliasIbanUUID, setAliasIbanUUID] = useState<string>('')
  const [iban, setIban] = useState<string>('')
  const [displayAuthenticationModal, setDisplayAuthenticationModal] = useState<boolean>(false)
  const [actionData, setActionData] = useState<string | null>(null)

  const { user } = useAuth()
  const { withdrawKypayBalanceMutation } = useKypay()
  const { mutateAsync: withdrawKypayBalance, isPending } = withdrawKypayBalanceMutation()
  const resetInputs = () => {
    setAmount(0)
    setAmountString(formatNumber(0))
  }
  const inputRef = useRef<HTMLInputElement | null>()

  useEffect(() => {
    forceCursorPosition()
  }, [amountString])

  const forceCursorPosition = () => {
    if (inputRef.current) {
      const inputLength = inputRef.current.value.length
      inputRef.current.setSelectionRange(inputLength, inputLength) // Force cursor to the end on focus
    }
  }

  const handleWithdraw = async () => {
    setActionData(JSON.stringify({ iban, amount: amount * 100 }))
    setDisplayAuthenticationModal(true)
  }

  const handleCloseAuthModal = () => {
    setDisplayAuthenticationModal(false)
  }

  const handleValidated = async () => {
    handleCloseAuthModal()
    await withdrawKypayBalance({ amount: amount * 100, alias_iban_uuid: aliasIbanUUID })
    resetInputs()
    props.onClose?.()
  }
  const handleIbanChange = (key) => {
    const ibanKey = key.split('##')
    setAliasIbanUUID(ibanKey[0])
    setIban(ibanKey[1])
  }

  return (
    <>
      <Modal
        className="sm:w-full w-full max-w-lg"
        {...props}
        onClose={() => {
          resetInputs()
          props.onClose?.()
        }}
      >
        <ModalContent title={t('kypay.modal_withdraw_funds.label.title')}>
          <div className={'mt-4 opacity-1'}>
            <InputLabel
              value={amountString}
              onClick={forceCursorPosition}
              onChange={(value: string) => {
                const formattedValue = formatCurrencyInput(value)
                const parsed = parseFloat(formattedValue)
                if (!isNaN(parsed)) {
                  setAmount(parsed)
                  setAmountString(formatNumber(parsed))
                } else {
                  setAmountString(formatNumber(0))
                }
              }}
              suffixIcon={() => <span>€</span>}
              type="text"
              min="0.00"
              max="10000.00"
              step="0.01"
              label={t('kypay.modal_transfer_fund.input.amount_label')}
              placeholder={t('kypay.modal_transfer_fund.input.amount_placeholder')}
              ref={inputRef}
            />

            <InputLabel
              label={t('kypay.modal_withdraw_funds.input.bank_account_label')}
              className={'mt-6 mb-2'}
              inputComponent={
                <>
                  {!!(user.data?.naturalPerson?.AliasIBAN || []).length && (
                    <Dropdown
                      items={(user.data?.naturalPerson?.AliasIBAN || [])?.map((o) => ({
                        value: o.BankInfoDto.Name + ' - ' + o.Iban.replace(/ /, ''),
                        key: o.UUID + '##' + o.Iban.replace(/ /, '')
                      }))}
                      placeholder={t('kypay.modal_withdraw_funds.input.bank_account_placeholder')}
                      search
                      onChange={({ key }) => {
                        handleIbanChange(key)
                      }}
                      initialValue={null}
                    />
                  )}
                  {!(user.data?.naturalPerson?.AliasIBAN || []).length && (
                    <Info>{t('kypay.modal_withdraw_funds.label.no_bank_account')}</Info>
                  )}
                </>
              }
            />
            <div className="mb-6">
              <A to={Routes.account_identity}>
                {t('kypay.modal_withdraw_funds.label.add_bank_account')}
              </A>
            </div>

            <Button
              disabled={!amount || !amountString || !aliasIbanUUID}
              loading={isPending}
              onClick={handleWithdraw}
            >
              {t('kypay.button.withdraw')}
            </Button>
          </div>
        </ModalContent>
      </Modal>

      {displayAuthenticationModal && (
        <SolidAuthnModal
          action="draw_bank"
          actionData={actionData}
          onClose={handleCloseAuthModal}
          onValidated={handleValidated}
        />
      )}
    </>
  )
}
