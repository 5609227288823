import { DateTime } from 'luxon'
import { Kycoin, KycoinDTOServer, KycoinTransaction, KycoinTransactionDTOServer } from './types'

export const KycoinConverters = {
  DTOServer_entity: (dto: KycoinDTOServer): Kycoin => ({
    ...dto,
    transaction: dto.transaction.map((trans) =>
      KycoinConverters.transactionDTOServer_transactionEntity(trans)
    )
  }),
  transactionDTOServer_transactionEntity: (dto: KycoinTransactionDTOServer): KycoinTransaction => ({
    ...dto,
    Date: DateTime.fromISO(dto.Date).toJSDate()
  })
}
