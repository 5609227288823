import type { JwtType } from './types'

export const setJwt = (jwt: JwtType) => localStorage.setItem('jwt', JSON.stringify(jwt))

export const getJwt = (): JwtType => {
  try {
    return JSON.parse(localStorage.getItem('jwt') || '{}')
  } catch (e) {
    return {
      refresh: null,
      token: null
    }
  }
}

export const setTwoFaAuthenticating = (twoFaAuthenticating: boolean) =>
  localStorage.setItem('twoFaAuthenticating', String(twoFaAuthenticating))

export const getTwoFaAuthenticating = (): JwtType => {
  try {
    return JSON.parse(localStorage.getItem('twoFaAuthenticating') || '0')
  } catch (e) {
    return {
      refresh: null,
      token: null
    }
  }
}
