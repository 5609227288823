<template>
  <ion-row>
    <ion-col>
      <div class="card border">
        <div class="card-header pb-0 px-3">
          <h6 class="mb-0">{{ $t('olkypay.labels.followUpPage.payment_orders') }}</h6>
        </div>
        <div class="card-body pt-4 p-3">
          <ion-skeleton-text :animated="true"></ion-skeleton-text>
          <div>
            <ion-row>
              <ion-col size="1">
                <button
                  class="btn btn-icon-only btn-rounded btn-outline-light mb-0 btn-sm d-flex align-items-center justify-content-center"
                >
                  <ion-thumbnail slot="start">
                    <ion-skeleton-text :animated="true"></ion-skeleton-text>
                  </ion-thumbnail>
                </button>
              </ion-col>
              <ion-col size="7">
                <div class="d-flex flex-column">
                  <h6 class="mb-1 text-dark text-sm">
                    <ion-skeleton-text :animated="true" style="width: 80%"></ion-skeleton-text>
                  </h6>
                  <span class="text-xs">
                    <ion-skeleton-text :animated="true" style="width: 80%"></ion-skeleton-text
                  ></span>
                </div>
              </ion-col>
              <ion-col size="3">
                <div
                  class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold"
                >
                  <ion-skeleton-text :animated="true" style="width: 80%"></ion-skeleton-text>
                </div>
              </ion-col>
              <ion-col size="1">
                <ion-thumbnail slot="start">
                  <ion-skeleton-text :animated="true"></ion-skeleton-text>
                </ion-thumbnail>
              </ion-col>
            </ion-row>
            <li class="list-group-item border-0 justify-content-between ps-0 mb-2 border-radius-lg">
              <ion-row v-for="i in 10">
                <ion-col>
                  <div class="flex-column">
                    <ion-skeleton-text :animated="true"></ion-skeleton-text>
                  </div>
                </ion-col>
                <hr class="horizontal dark mt-3 mb-2" />
              </ion-row>
            </li>
          </div>
        </div>
      </div>
    </ion-col>
  </ion-row>
</template>

<script lang="ts">
import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail
} from '@ionic/vue'

export default {
  name: 'OrderSkeleton',
  components: {
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonSkeletonText,
    IonThumbnail
  },
  data() {
    return {}
  }
}
</script>

<style scoped></style>
