import { Dropdown } from '@/atoms-react/dropdown/index.react'
import { Base } from '@/atoms-react/text/Text.react'
import Flag from '@/atoms/flag/Flag.react'
import { setLanguage } from '@/features/utils/i18n'
import { CountryCode } from '@/features/utils/types'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

const languages = {
  fr: 'Français',
  en: 'English'
}

export const LanguageSelector = ({ size }: { size?: 'sm' }) => {
  const { i18n } = useTranslation()

  return <Dropdown
    className={'w-auto inline-block'}
    items={Object.entries(languages).map(([key, value]) => ({
      key: key as CountryCode,
      value,
      icon: () => <Flag countryCode={key} />,
      render: ({ key, value, inDropdown }) => <Fragment key={key}>
          <Flag countryCode={((key as string) === 'en' ? 'gb' : 'fr') as CountryCode} />
          {(size !== 'sm' || inDropdown) && (
            <div className={'w-full flex justify-between items-center ml-4'}>
              <Base>{value}</Base>
            </div>
          )}
        </Fragment>
    }))}
    showChevrons
    // search={{
    //   custom: (searchValue, item) => {
    //     return (item.key + ' ' + item.value).toLowerCase().includes(searchValue.toLowerCase())
    //   }
    // }}
    value={i18n.language as CountryCode}
    onChange={({ key }) => setLanguage(key)}
  />
}
