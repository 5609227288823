import { A } from '@/atoms-react/a/A.react'
import Column from '@/atoms-react/column/Column.react'
import CodeInput from '@/atoms-react/input/CodeInput.react'
import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import { Base } from '@/atoms-react/text/Text.react'
import useCheckEmailMutation from '@/features/flow/hooks/use-check-email'
import useValidateEmailMutation from '@/features/flow/hooks/use-validate-email'
import { CheckEmailResponse } from '@/features/register/types'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { FlowHeader } from '../../../flow-header.react'

interface VerifyEmailRootProps extends LayoutBaseProps {
  input: VerifyEmailInput
  onValidated?: () => void
  overlayMode?: boolean
}

export type VerifyEmailInput = {
  email: string
}

function VerifyEmail({ input, onValidated = () => {} }: VerifyEmailRootProps) {
  const { t } = useTranslation(['pass'])

  const [codeValidation, setCodeValidation] = useState<CheckEmailResponse | null>(null)
  const [canResendCode, setCanResendCode] = useState(false)

  const { mutateAsync: sendCode, isPending: isPendingSendCode } = useCheckEmailMutation()
  const { mutate: validateEmail, isPending: isPendingValidateEmail } = useValidateEmailMutation()

  useEffect(() =>{ execute()}, [])

  const execute = () => {
    sendCode(input.email, {
      onSuccess: response => {
        setCodeValidation(response)
        setCanResendCode(false)
      },
      onError: () => toast.error(t('flow.id_control.signup.error.error_sending'))
    })
    setTimeout(() => setCanResendCode(true), 5000)
  }

  return <>
    <Column>
      <FlowHeader
        title={t('flow.id_control.signup.label.verify_email_title')}
        description={
          <>
            {t('flow.id_control.signup.label.verify_email_description_1')}{' '}
            <Base className={'inline font-semibold'}>{input?.email ?? ''}</Base>{' '}
            {t('flow.id_control.signup.label.verify_email_description_2')}
          </>
        }
      />
      <CodeInput
        codeNumberOfCharacters={8}
        className={'mb-6'}
        focus={true}
        onChange={code =>
          validateEmail(
            {
              code,
              cypher: codeValidation?.data.cypher ?? '',
              email: input.email
            },
            {
              onSuccess: () => onValidated(),
              onError: () => toast.error(t('flow.id_control.signup.error.verify_email_wrong'))
            }
          )
        }
        loading={isPendingValidateEmail}
      />

      <A
        disabled={!canResendCode || isPendingSendCode || isPendingValidateEmail}
        className={twMerge(
          'font-medium transition-all',
          isPendingSendCode || canResendCode ? '' : 'opacity-0'
        )}
        onClick={() => execute()}
      >
        {t('flow.id_control.signup.actions.resend_code')}
      </A>
    </Column>
  </>
}

export default VerifyEmail
