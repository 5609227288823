<template>
  <v-dialog v-model="dialog" persistent style="min-width: 50vw !important; max-width: 500px">
    <v-card class="cardBoxPopup">
      <v-card-title class="text-h5">
        {{
          this.action === 'new'
            ? $t('olkypass.accountPage.addresses_section.dialog.header.new')
            : $t('olkypass.accountPage.addresses_section.dialog.header.update')
        }}</v-card-title
      >
      <v-card-text>
        <address-form-content ref="addressFormComponent" :address="address"></address-form-content>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col class="d-flex justify-space-between">
            <v-btn
              variant="flat"
              color="white"
              height="auto"
              class="text-none cancelBtn"
              size="medium"
              @click="cancel"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.cancel') }}
            </v-btn>
            <v-btn
              variant="flat"
              color="primary"
              height="auto"
              class="text-none validationBtn"
              :loading="loader"
              size="medium"
              @click="checkAddressAvailability"
            >
              {{ $t('olkypass.accountPage.dialog.newItems.button.next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import { mapGetters } from '../../store'
import store from '../../store'
import ToolsMixins from '../../mixins/ToolsMixins.js'
import _ from 'lodash'
import AddressFormContent from './AddressFormContent.vue'
export default {
  name: 'AddressFormDialog',
  components: {
    AddressFormContent
  },
  mixins: [NotificationsMixins, ToolsMixins],
  data: () => ({
    address: {},
    action: 'new',
    dialog: false,
    loader: false,
    uri: '',
    setCurrentEntityFunctionName: '',
    availability_message: '',
    inputOptions: {
      required: true
    }
  }),
  methods: {
    open(inputAddress, isNew) {
      if (!inputAddress.tag) {
        inputAddress.Tag = this.$i18n.t(
          'olkypass.accountPage.dialog.identity.residenceAddressStaticLabel'
        )
      }
      this.dialog = true
      this.address = inputAddress
      this.action = isNew ? 'new' : 'update'
      this.availability_message = ''

      if (this.currentTreatedEntity === 'company') {
        this.uri = 'company/address/create_new'
        this.setCurrentEntityFunctionName = 'setCurrentCompany'
      } else {
        this.uri = 'user/address/create_new'
        this.setCurrentEntityFunctionName = 'setCurrentNaturalPerson'
      }
    },
    cancel() {
      this.dialog = false
    },
    async isValidForm() {
      const { valid } = await this.$refs.addressFormComponent.$refs.addressForm.validate()
      return valid
    },
    async checkAddressAvailability() {
      const isValidForm = await this.isValidForm()
      if (isValidForm) {
        this.loader = true
        this.availability_message = ''
        const objectToSend = _.cloneDeep(this.address)
        objectToSend.UUID = this.currentUUID
        objectToSend.language = this.currentLanguage

        if (this.action === 'update') {
          await this.savePartialData()
          this.loader = false
        } else {
          await fetchServer(this.uri, { body: objectToSend })
            .then(response => {
              if (!response.ok) throw new Error(response?.data?.response || 'Error')
            })
            .then(async response => {
              await store.dispatch(this.setCurrentEntityFunctionName, {
                UUID: this.currentUUID
              })
              this.cancel()
              this.displayNotification(
                'success',
                this.$i18n.t('olkypass.accountPage.notifications.success.profile.update')
              )
            })
            .catch(error => {
              this.availability_message = this.$i18n.t(
                'olkypass.accountPage.notifications.error.address.create'
              )
              this.cancel()
              this.displayNotification('error', this.availability_message)
            })
            .finally(() => this.loader = false)
        }
      }
    },
    async savePartialData() {
      const setPartialDataFunctionName =
        this.currentTreatedEntity === 'company' ? 'setCompanyPartialData' : 'setNPPartialData'
      /*if (await this.$refs.dialogConfirm.open('accountPage.dialog.confirm.update.infos.header', 'accountPage.dialog.confirm.update.infos.body')) {*/
      if (!this.address.AbstractEntityUUID) {
        this.address.AbstractEntityUUID = this.naturalPerson.UUID
      }
      store.dispatch('playLoader')
      await store.dispatch(setPartialDataFunctionName, {
        typeDto: 'AdresseDto',
        UUID: this.naturalPerson.UUID,
        Address: this.address
      })
      if (this.currentTreatedEntity === 'company') {
        await store.dispatch('setCurrentCompany', { UUID: this.naturalPerson.UUID })
      } else {
        await store.dispatch('setCurrentNaturalPerson')
      }
      store.dispatch('stopLoader')
      this.dialog = false
    }
    /*}*/
  },
  computed: {
    ...mapGetters([
      'currentTreatedEntity',
      'currentUUID',
      'currentLanguage',
      'countries',
      'countryLabel',
      'naturalPerson'
    ]),
    requiredField() {
      const $this = this
      return function (fieldName) {
        return (v) =>
          !!v ||
          $this.$i18n.t('olkypass.' + fieldName) +
            ' ' +
            $this.$i18n.t('login.registerPage.validation.required_field')
      }
    }
  }
}
</script>

<style scoped></style>
