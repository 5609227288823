import { Button } from '@/atoms/buttons/Button'
import { Input } from '@/atoms/inputs/Input'
import { Option, Select } from '@/atoms/inputs/Select'
import LabelDecoration from '@/atoms/inputs/decorations/LabelDecoration'
import { useCompany } from '@/features/company/hooks/use-company'
import { UserStaticDataStore } from '@/features/user/store'
import { defineJsxComponent } from '@/jsx-utils'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export const CompanyIdentityForm = defineJsxComponent<{
  onComplete: () => void
}>((_, { attrs }) => {
  const t = useI18n().t
  const { companyStore, updateCompany } = useCompany()
  const userStaticData = UserStaticDataStore()
  const naceCodes = userStaticData.data?.naceCodes
  const companyModel = ref<any>(companyStore?.currentCompany || null)
  const canUpdateCompany = ref(companyStore?.currentCompanyPermission?.update_company_data)

  const isFormValid = computed(
    () =>
      companyModel.value.legal_name &&
      companyModel.value.legal_form &&
      (companyModel.value.date_incorporation && companyModel.value.RCS_number ||
        !companyModel.value.RCS_number) &&
      companyModel.value.comment
  )

  return () => <div>
    <form
      className="pb-6"
      onSubmit={async (e: any) => {
        e.preventDefault()
        if (await updateCompany(companyModel as any)) {
          attrs.onComplete()
        }
      }}
    >
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="md:col-span-2">
          <LabelDecoration
            class="my-4"
            label={`* ${t('olkypass.companyPage.basicInfo.input.legalName')}`}
          >
            {{
              input: (input: any) => <Input
                size="lg"
                required
                class={input.class}
                v-model={companyModel.value.legal_name}
                readOnly={!canUpdateCompany.value}
              />
            }}
          </LabelDecoration>
        </div>
        <div className="md:col-span-2">
          <LabelDecoration
            class="my-4"
            label={`* ${t('olkypass.companyPage.basicInfo.input.legalForm')}`}
          >
            {{
              input: (input: any) => <Select
                size="lg"
                required
                class={input.class}
                v-model={companyModel.value.legal_form}
                readOnly={!canUpdateCompany.value}
              ></Select>
            }}
          </LabelDecoration>
        </div>
        <div className="md:col-span-2">
          <LabelDecoration
            class="my-4"
            label={t('olkypass.companyPage.basicInfo.input.rcsNumber')}
          >
            {{
              input: (input: any) => <Input
                size="lg"
                class={input.class}
                v-model={companyModel.value.RCS_number}
                readOnly={!canUpdateCompany.value}
              />
            }}
          </LabelDecoration>
        </div>
        <div className="md:col-span-2">
          <LabelDecoration
            class="my-4"
            label={t('olkypass.companyPage.basicInfo.input.vatNumber')}
          >
            {{
              input: (input: any) => <Input
                size="lg"
                class={input.class}
                v-model={companyModel.value.VAT_number}
                readOnly={!canUpdateCompany.value}
              />
            }}
          </LabelDecoration>
        </div>

        {companyModel.value.RCS_number && <div className="md:col-span-4">
            <LabelDecoration
              class="my-4"
              label={`* ${t('olkypass.companyPage.basicInfo.input.dateOfIncorporation')}`}
            >
              {{
                input: (input: any) => <Input
                  size="lg"
                  required
                  class={input.class}
                  type="date"
                  v-model={companyModel.value.date_incorporation}
                  readOnly={!canUpdateCompany.value}
                />
              }}
            </LabelDecoration>
          </div>}

        <div className="md:col-span-2">
          <LabelDecoration
            class="my-4"
            label={`* ${t('olkypass.companyPage.basicInfo.input.activity_sector_nace')}`}
          >
            {{
              input: (input: any) => <Select
                size="lg"
                required
                class={input.class}
                v-model={companyModel.value.code_nace}
                readOnly={!canUpdateCompany.value}
              >
                {(naceCodes || []).map(opt => <Option value={opt.id} key={opt.id}>
                  {opt.defaultName}
                </Option>)}
              </Select>
            }}
          </LabelDecoration>
        </div>

        <div className="md:col-span-2">
          <LabelDecoration
            class="my-4"
            label={`* ${t('olkypass.companyPage.basicInfo.input.description_activity')}`}
          >
            {{
              input: (input: any) => <Input
                size="lg"
                required
                multiline="multiline"
                class={input.class}
                v-model={companyModel.value.comment}
                readOnly={!canUpdateCompany.value}
              />
            }}
          </LabelDecoration>
        </div>

        <div className="md:col-span-4">
          <LabelDecoration
            class="my-4"
            label={`${t('olkypass.companyPage.basicInfo.input.website_url')}`}
          >
            {{
              input: (input: any) => <Input
                size="lg"
                class={input.class}
                v-model={companyModel.value.website_url}
                readOnly={!canUpdateCompany.value}
              />
            }}
          </LabelDecoration>
        </div>
      </div>

      {canUpdateCompany.value && <Button
          class="float-right"
          type="submit"
          theme="primary"
          loading={companyStore.loading}
          disabled={!isFormValid.value}
        >
          {t('olkypass.companyPage.tooltip.button.save_collaborator_info')}
        </Button>}
    </form>
  </div>
})
