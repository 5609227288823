import Avatar from '@/atoms-react/avatar/Avatar.react'
import { Base, SectionTitle } from '@/atoms-react/text/Text.react'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { useCompanyContext } from '@/features/auth/hooks/use-company-context'
import { useUser } from '@/features/user/hooks/use-user'
import { DocumentDuplicateIcon as DocumentDuplicateIconOutline } from '@heroicons/react/24/outline'
import { DocumentDuplicateIcon as DocumentDuplicateIconSolid } from '@heroicons/react/24/solid'
import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { getFullName } from '@/features/utils/functions'
import { useToast } from 'vue-toast-notification'

type IdentityCardProps = React.ComponentPropsWithoutRef<'div'>

export const IdentityHeaderCard = ({ ...props }: IdentityCardProps) => {
  const { cached } = useAuth()
  const { id, company } = useCompanyContext()
  const toast = useToast()

  const { t } = useTranslation(['pass'])
  const { useUserProfile } = useUser()
  const { data: user } = useUserProfile()
  const [copied, setCopied] = useState(false)

  const naturalPersonMode = !id

  const copyToClipboard = () => {
    const copyID = naturalPersonMode ? user?.naturalPerson.UUID : id

    if (copyID) {
      navigator.clipboard.writeText(copyID)
      setCopied(true)
      toast.success(`${t('olkypass.accountPage.basicInfo.success.copied_id')}`)

      setTimeout(() => setCopied(false), 1000)
    }
  }

  return <div
    className={twMerge('relative overflow-hidden 2xl:w-3/4', props.className)}
    {..._.omit(props, 'className')}
  >
    <div className="absolute w-full h-12 md:h-20 bg-[url('/images/olkypass/olky-space-background.jpg')] bg-cover bg-center bg-no-repeat z-0"></div>
    <div className="relative z-10 px-4 pt-4 pb-2 md:px-6 md:pt-6 md:pb-0">
      <Avatar
        className="border-4 border-white md:mb-3 h-16 w-16 md:w-24 md:h-24"
        src={naturalPersonMode ? cached.data?.avatar : null}
        fallback={naturalPersonMode ? cached.data?.name : company?.legal_name}
      ></Avatar>
    </div>
    <div className="md:flex md:justify-between px-6 md:pb-5">
      <div className="mb-4 md:mb-0">
        <div className="flex items-center md:mb-1">
          <SectionTitle>
            {naturalPersonMode
              ? getFullName(user?.naturalPerson)
              : t('olkypass.accountPage.basicInfo.label.company', {
                  company: company?.legal_name
                })}
          </SectionTitle>
        </div>
        <span className="flex items-center">
          <Base className="text-slate-500 mr-2 truncate">
            {t('olkypass.accountPage.profileSection.label.kyc_identifier')} :{' '}
            {naturalPersonMode ? user?.naturalPerson.UUID : id}
          </Base>
          {copied ? <div className="relative flex items-center">
              <DocumentDuplicateIconSolid
                className="h-5 w-5 text-slate-900 stroke-2 cursor-pointer hover:text-slate-600"
                onClick={copyToClipboard}
              />
            </div> : <DocumentDuplicateIconOutline
              className="h-5 w-5 text-slate-500 stroke-2 cursor-pointer hover:text-slate-600"
              onClick={copyToClipboard}
            />}
        </span>
      </div>
    </div>
  </div>
}
