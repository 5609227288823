<template>
  <v-col cols="12" sm="12" md="6" lg="4">
    <v-card class="mx-auto elementCard">
      <template #prepend>
        <v-tooltip activator="parent" location="top">
          <template #activator="{ props }">
            <v-icon
              :color="mail.valid ? '#00D770' : 'error'"
              :icon="mail.valid ? 'mdi-check-circle' : 'mdi-close-circle'"
            ></v-icon>
          </template>
          <span v-if="mail.valid">{{
            $t('olkypass.accountPage.emails_section.badge.isValid')
          }}</span>
          <span v-else>{{ $t('olkypass.accountPage.emails_section.badge.isNotValid') }}</span>
        </v-tooltip>
      </template>
      <template #title>
        {{ mail.Mail }}
      </template>
      <v-card-text class="elementData">
        <v-row>
          <v-col>
            <v-switch
              v-model="mail.Actif"
              color="secondary"
              :label="$t('olkypass.accountPage.ibans_section._switch.active')"
              hide-details
              @change="savePartialData"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!mail.valid"
          variant="text"
          class="text-none textBtn"
          height="auto"
          prepend-icon="mdi-email-arrow-right"
          size="medium"
          :loading="loader"
          @click="sendEmailLink"
        >
          <template #prepend>
            <v-icon class="mx-1"></v-icon>
          </template>
          {{ $t('olkypass.accountPage.emails_section.button.validateEmail') }}
        </v-btn>
        <v-btn
          density="comfortable"
          size="small"
          color="surface-variant"
          variant="tonal"
          icon="mdi-delete"
          :disabled="!checkDisabled"
          @click="deleteCurrent"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
  <DialogConfirm ref="dialogConfirm"></DialogConfirm>
  <EmailCodeFormDialog ref="emailCodeFormDialog"></EmailCodeFormDialog>
</template>

<script>
import ToolsMixins from '../../mixins/ToolsMixins.js'
import { mapGetters } from '../../store'
import store from '../../store'
import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import DialogConfirm from '../DialogConfirm.vue'
import EmailCodeFormDialog from '../FormsDialog/EmailCodeFormDialog.vue'

export default {
  name: 'EmailChild',
  components: {
    DialogConfirm,
    EmailCodeFormDialog
  },
  mixins: [ToolsMixins, NotificationsMixins],
  props: ['mail', 'inMemoryMail'],
  data: () => ({
    uris: {
      _post: {
        sendValidationCode: '/aliasMail/sendValidationCode'
      }
    },
    loader: false
  }),
  methods: {
    async deleteCurrent() {
      const setDeleteDataFunctionName =
        this.currentTreatedEntity === 'company' ? 'delCompanyAliasData' : 'delNPAliasData'
      if (
        await this.$refs.dialogConfirm.open(
          'accountPage.dialog.confirm.delete.header',
          'accountPage.dialog.confirm.delete.body'
        )
      ) {
        store.dispatch('playLoader')
        await store.dispatch(setDeleteDataFunctionName, {
          UUID: this.naturalPerson.UUID,
          ItemUUID: this.mail.UUID,
          ItemType: 'AliasMailDto'
        })
        if (this.currentTreatedEntity === 'company') {
          await store.dispatch('setCurrentCompany', { UUID: this.naturalPerson.UUID })
        } else {
          await store.dispatch('setCurrentNaturalPerson')
        }
        store.dispatch('stopLoader')
      }
    },
    async sendEmailLink() {
      this.loader = true
      const $this = this
      await fetchServer(this.uris._post.sendValidationCode, {
        body: {
          email: this.mail.Mail,
          language: this.currentLanguage
        }
      })
        .then(response => {
          if (!response || !response.data || !response.data.validation_code) {
            this.displayNotification(
              'error',
              this.$i18n.t('olkypass.accountPage.notifications.error.email.sendLink')
            )
            return
          }

          this.displayNotification(
            'success',
            this.$i18n.t('olkypass.accountPage.notifications.success.email.sendLink')
          )
          if (response.data.validation_code !== undefined) {
            const validation_code = response.data.validation_code
            $this.$refs.emailCodeFormDialog.open(this.mail.UUID, validation_code)
          }
        })
        .catch(error => {
          if (error?.response?.data?.message !== undefined) {
            this.displayNotification('error', error.response.data.message)
          } else {
            this.displayNotification(
              'error',
              this.$i18n.t('olkypass.accountPage.notifications.error.email.sendLink')
            )
          }
        })
        .finally(async () => {
          this.loader = false
        })
    },
    async savePartialData() {
      if (!this.mail.Actif) {
        this.mail.Public = false
      }
      if (!this.mail.Owner) {
        this.mail.Owner = this.naturalPerson.UUID
      }
      const setPartialDataFunctionName =
        this.currentTreatedEntity === 'company' ? 'setCompanyPartialData' : 'setNPPartialData'
      /*if (await this.$refs.dialogConfirm.open('accountPage.dialog.confirm.update.infos.header', 'accountPage.dialog.confirm.update.infos.body')) {*/
      store.dispatch('playLoader')
      await store.dispatch(setPartialDataFunctionName, {
        typeDto: 'AliasMailDto',
        UUID: this.naturalPerson.UUID,
        AliasEmail: this.mail
      })
      if (this.currentTreatedEntity === 'company') {
        await store.dispatch('setCurrentCompany', { UUID: this.naturalPerson.UUID })
      } else {
        await store.dispatch('setCurrentNaturalPerson')
      }
      store.dispatch('stopLoader')
      /*}*/
    }
  },
  computed: {
    ...mapGetters(['currentLanguage', 'naturalPerson']),
    checkDisabled() {
      return !this.mail.Actif && !this.mail.Public
    }
  }
}
</script>

<style scoped></style>
