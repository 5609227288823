import { CollaboratorTab } from '@/app/olkypass/views/account/company-details/components/collaborator-tab'
import { InfoTab } from '@/app/olkypass/views/account/company-details/components/info-tab'
import { KYCBanner } from '@/app/olkypass/views/account/company-details/components/kyc-banner'
import { Loader } from '@/atoms/loader/Loader'
import { useCollaborator } from '@/features/company/hooks/use-collaborator'
import { useCompany } from '@/features/company/hooks/use-company'
import { defineJsxComponent } from '@/jsx-utils'
import { useRoute } from 'vue-router'

export const CompanyDetailsView = defineJsxComponent((_, { attrs }) => {
  const route = useRoute()
  const { companyStore, loadCurrentCompany } = useCompany()
  const { loadCollaborators } = useCollaborator()
  companyStore.setInitialized(false)
  const identity_uuid = route.params.identity_uuid
  loadCurrentCompany(identity_uuid).then(() => companyStore.setInitialized(false))
  loadCollaborators(identity_uuid).then(() => companyStore.setInitialized(true))

  return () => <>
    <div {...attrs}>
      {!companyStore.initialized && <Loader center />}
      {companyStore.initialized && <>
          <KYCBanner />
          <InfoTab />
          <CollaboratorTab />
        </>}
      {/*{companyStore.initialized && <><InfoTab /></>}*/}
    </div>
  </>
})
