'use client'
import { CoinLayout } from '@/molecules/coin-layout/index.react'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutTitle } from '../../../_layout/index.react'
import { CardWallet } from '../wallet-card/card-wallet.react'
import { Button } from '@/atoms-react/button/Button.react'
import { StarIcon } from '@heroicons/react/24/solid'
import { Themes } from '@/types/theme'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Routes } from '@/features/router'
import { useWallet } from '@/features/wallet/hooks/use-wallet'
import Loader from '@/atoms-react/loader/Loader.react'

export const WalletCryptoDetailsRoot = () => {
  const { t } = useTranslation(['wallet'])
  const [search, setSearch] = useState<string>('')
  const { cryptoKey } = useParams()

  const { currencyQuery, sparklineDataQuery } = useWallet()
  const { data: currency, isLoading: isCurrencyLoading } = currencyQuery()
  const { data: sparklineData, isLoading: isSparklineDataLoading } = sparklineDataQuery({
    crypto_identifier: cryptoKey
  })

  const cryptoIdentity = currency?.find((item) => item.id === cryptoKey)
  const isFavorite = true

  return (
    <>
      <CoinLayout
        search={search}
        setSearch={setSearch}
        title={() => (
          <>
            <LayoutTitle>{cryptoIdentity?.name.split(' (')[0]}</LayoutTitle>
          </>
        )}
        sectionTitle={''} // For the sub-title
        header={() => (
          <div className="flex justify-end items-center gap-4">
            {t('wallet.dashboard.label.add_favorite')}
            <span className="h-8 w-8">
              <StarIcon className={isFavorite ? 'text-blue-600' : 'text-white'}></StarIcon>
            </span>
          </div>
        )}
        body={() => (
          <>
            {!isCurrencyLoading && !isSparklineDataLoading && (
              <div className="flex flex-col w-full gap-4">
                <div className="grid grid-cols-3 gap-4 w-full">
                  <CardWallet
                    Type="details"
                    className="col-span-3"
                    config={{ cardData: cryptoIdentity, chartData: sparklineData }}
                  />
                </div>
                <div className="flex flex-row gap-2 justify-end">
                  <Link
                    to={Routes.wallet_buy}
                    className="view-more text-end text-blue-500 underline underline-offset-1"
                  >
                    <Button
                      className={'text-base text-black w-auto h-full py-2'}
                      size="sm"
                      onClick={() => {}}
                      theme={Themes.white}
                      iconPosition="before"
                    >
                      {t('wallet.buy.label.main_title')}
                    </Button>
                  </Link>

                  <Link
                    to={Routes.wallet_sell}
                    className="view-more text-end text-blue-500 underline underline-offset-1"
                  >
                    <Button
                      className={'text-base text-black w-auto h-full py-2'}
                      size="sm"
                      onClick={() => {}}
                      theme={Themes.white}
                      iconPosition="before"
                    >
                      {t('wallet.sell.label.main_title')}
                    </Button>
                  </Link>

                  <Link
                    to={Routes.wallet_convert}
                    className="view-more text-end text-blue-500 underline underline-offset-1"
                  >
                    <Button
                      className={'text-base text-black w-auto h-full py-2'}
                      size="sm"
                      onClick={() => {}}
                      theme={Themes.white}
                      iconPosition="before"
                    >
                      {t('wallet.convert.label.main_title')}
                    </Button>
                  </Link>

                  <Link
                    to={Routes.wallet_transfer}
                    className="view-more text-end text-blue-500 underline underline-offset-1"
                  >
                    <Button
                      className={'text-base text-black w-auto h-full py-2'}
                      size="sm"
                      onClick={() => {}}
                      theme={Themes.white}
                      iconPosition="before"
                    >
                      {t('wallet.transfer.label.main_title')}
                    </Button>
                  </Link>
                </div>
              </div>
            )}
            {(isCurrencyLoading || isSparklineDataLoading) && (
              <Loader className={'w-8 h-8 text-blue-500'} />
            )}
          </>
        )}
      />
    </>
  )
}
