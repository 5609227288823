import { Base } from '@/atoms-react/text/Text.react'
import {
  CompanyActivityCreate,
  CompanyAddressCreate,
  CompanyCreate,
  CompanyInfoCreate,
  CompanyRCSCreate
} from '@/features/company/types'
import { FormFieldGroup } from '@/features/utils/types'
import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { FormAddAddress } from '../form-add-address/index.react'
import { FormAddActivity } from './components/form-add-activity/index.react'
import { FormAddCompanyInfo } from './components/form-add-company-info/index.react'

type FormAddCompanyProps = {
  data: CompanyCreate
  fields?: {
    company?: FormFieldGroup<CompanyInfoCreate & CompanyRCSCreate>
    address?: FormFieldGroup<CompanyAddressCreate>
    activity?: FormFieldGroup<CompanyActivityCreate>
  }
  onChange: (company: CompanyCreate, valid: boolean) => void
} & React.ComponentPropsWithoutRef<'div'>

export function isCompanyValid(data: CompanyCreate) {
  return (
    data.activity &&
    data.address &&
    data.activity.description &&
    data.company &&
    data.company.name &&
    data.company.legalForm &&
    data.activity.nace &&
    ((!!data.RCS?.number && !!data.RCS.incorporationDate) || !data.RCS?.number)
  )
}

export const FormAddCompany = ({
  data,
  fields = {},
  onChange: _onChange = () => {},
  ...props
}: FormAddCompanyProps) => {
  const { t } = useTranslation(['pass'])
  const [addressValid, setAddressValid] = useState(false)

  const onChange = (company: CompanyCreate) => {
    const valid = !!isCompanyValid(company) && addressValid
    _onChange(company, valid)
  }

  const cardClassName =
    '2xl:w-3/4 self-center md:border-2 md:border-slate-50 rounded-sm text-black dark:text-white dark:border-slate-700'

  const sectionClassName =
    'grid grid-cols-1 md:grid-cols-2 border-b-2 border-slate-50 pt-14 pb-8 md:gap-x-6 gap-y-6 md:px-2 overflow-y-auto relative'

  const subtitleClassName = 'absolute top-4 md:left-2 text-slate-300'

  return (
    <div
      className={twMerge('md:px-4 pt-0', cardClassName, props.className)}
      {..._.omit(props, 'className')}
    >
      <div className={sectionClassName}>
        <Base className={subtitleClassName}>{t('business.label.tab_business_info')}</Base>
        <FormAddCompanyInfo
          data={{ ...data.company, ...data.RCS }}
          fields={fields?.company}
          onChange={(companyInfo) =>
            onChange({
              ...data,
              company: {
                ...data.company,
                url: companyInfo.url,
                name: companyInfo.name,
                legalForm: companyInfo.legalForm,
                VATnumber: companyInfo.VATnumber
              },
              RCS: {
                number: companyInfo.number,
                incorporationDate: companyInfo.incorporationDate
              }
            })
          }
        />
      </div>
      <div className={twMerge(sectionClassName, 'mt-2 md:grid-cols-1')}>
        <Base className={subtitleClassName}>{t('business.label.tab_address')}</Base>

        <FormAddAddress
          address={{
            address: data.address?.addressLine ?? '',
            zip: data.address?.zip ?? '',
            country: parseInt(data.address?.countryCode ?? '-1'),
            city: data.address?.city ?? '',
            tag: '',
            isPrincipalAddress: false
          }}
          fields={{
            ...fields.address,
            tag: { visible: false },
            isPrincipalAddress: { visible: false }
          }}
          onChange={(address, valid) => {
            setAddressValid(valid)
            onChange({
              ...data,
              address: {
                ...data.address,
                addressLine: address.address,
                city: address.city,
                zip: address.zip,
                countryCode: (address.country ?? '').toString(),
                isPrincipalAddress: address.isPrincipalAddress
              }
            })
          }}
        />
      </div>

      <div className={twMerge(sectionClassName, 'mt-2 border-0 pb-4 md:grid-cols-1')}>
        <Base className={subtitleClassName}>{t('business.label.tab_activity')}</Base>
        <FormAddActivity
          data={data.activity ?? {}}
          fields={fields?.activity ?? {}}
          onChange={(activity) =>
            onChange({
              ...data,
              activity: {
                ...data.activity,
                nace: activity.nace,
                description: activity.description
              }
            })
          }
        />
      </div>
    </div>
  )
}
