import { ConfirmationResponse } from '@/features/register/types'
import { useMutation } from '@tanstack/react-query'
import { AddressCreate } from '../../types'
import { AddressApiClient } from '../api-client/api-client'

export const useAddress = () => {
  const createUserAddressMutation = () =>
    useMutation<ConfirmationResponse, Error, { userUUID: string; address: AddressCreate }>({
      mutationFn: async ({ userUUID, address }) => AddressApiClient.create(userUUID, address)
    })

  return { createAddressMutation: createUserAddressMutation }
}
