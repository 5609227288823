import Loader from '@/atoms-react/loader/Loader.react'
import { Modal, ModalContent, ModalProps } from '@/atoms-react/modal/index.react'
import { Info, Base } from '@/atoms-react/text/Text.react'
import { useKypay } from '@/features/kypay/hooks/use-kypay'
import { InfoCard, InfoCardType } from '@/molecules/info-card/InfoCard.react'
import { useTranslation } from 'react-i18next'
import { WalletNumber } from '../wallet-number/wallet-number.react'

type ModalFeedKypayProps = {
  wallet?: string | null
  onSetWallet?: () => void
} & ModalProps

export const ModalCryptoToBank = ({
  wallet,
  onSetWallet = () => {},
  ...props
}: ModalFeedKypayProps) => {
  const { t } = useTranslation(['wallet'])
  const { walletPolygon2LausanneQuery } = useKypay()

  const { data: walletPolygon2Lausanne, isLoading: isWalletPolygon2LausanneLoading } =
    walletPolygon2LausanneQuery()

  return <Modal className="sm:w-full w-full max-w-lg" {...props}>
    <ModalContent title={t('kypay.modal_deposit_funds.label.choice_wallet_crypto')}>
      <div className={'mt-4 opacity-1'}>
        {wallet && <InfoCard type={InfoCardType.warning}>
            <Info className="text-white dark:text-white">{t('kypay.modal_deposit_funds.label.warning')}</Info>
          </InfoCard>}

        {!wallet && <InfoCard className={'mb-4'} type={InfoCardType.error} title={'Alerte'}>
            <Base className="text-white">
              {t('kypay.modal_deposit_funds.label.warning_crutial')}
            </Base>
          </InfoCard>}

        <div className={'w-full mt-4 mb-6'}>
          <WalletNumber
            wallet={wallet ?? null}
            onSetWallet={onSetWallet}
            label={t('kypay.modal_deposit_funds.label.sending_wallet')}
          />
        </div>
        <div className={'w-full my-4'}>
          {!isWalletPolygon2LausanneLoading && walletPolygon2Lausanne?.WalletAddress && <WalletNumber
              wallet={walletPolygon2Lausanne?.WalletAddress ?? null}
              label={t('kypay.modal_deposit_funds.label.receiving_wallet')}
              copy
            />}
          {isWalletPolygon2LausanneLoading && <Loader className={'w-6 h-6 text-blue-500'} />}
        </div>
      </div>
    </ModalContent>
  </Modal>
}
