import _ from 'lodash'
import { Dispatch, SetStateAction, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import InputLabel from '../input/Input.react'
import { DropdownSearchParams } from './index.react'

export type DropdownSearchWidgetProps = {
  search: DropdownSearchParams | true
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
} & React.ComponentPropsWithoutRef<'div'>

export const DropdownSearchWidget = forwardRef<HTMLInputElement, DropdownSearchWidgetProps>(
  ({ search, searchValue, setSearchValue = () => {}, ...props }, ref) => {
    const { t } = useTranslation(['pass'])

    return <div
      className="flex items-center pb-2"
      {..._.omit(props, 'search', 'searchValue', 'setSearchValue')}
    >
      <InputLabel
        /* @ts-expect-error Vuetify  */
        ref={ref}
        borderless
        placeholder={(search as any)?.placeholder || t('flow.input.search_placeholder')}
        value={searchValue}
        onChange={(e: any) => setSearchValue(e)}
        onFocus={(e) => e.stopPropagation()}
        onBlur={(e) => e.stopPropagation()}
      />
    </div>
  }
)
