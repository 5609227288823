export const StorageService = {
  getItem(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key) || 'null')
    } catch (e) {
      return null
    }
  },

  setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value))
  },

  removeItem(key: string) {
    localStorage.removeItem(key)
  }
}
