import config from '@/config'
import { getJwt } from '@/features/auth/utils'
import axios, { type AxiosRequestConfig } from 'axios'

const ApiService = {
  async customRequest<T>(requestData: AxiosRequestConfig<T>) {
    requestData.url =
      config.APP_ROOT_API?.replace(/\/$/, '') + '/' + requestData.url?.replace(/^\//, '')
    requestData.headers = {
      ...(requestData.headers || {}),
      ...(getJwt().token ? { Authorization: `Bearer ${getJwt().token}` } : {})
    }

    return (await axios.request(requestData))?.data
  },

  async getRequest<T>(uri: string, requestData: AxiosRequestConfig<T>) {
    return await this.customRequest<T>({ ...requestData, url: uri })
  }
}

export default ApiService
