import { Dropdown } from '@/atoms-react/dropdown/index.react'
import { Base } from '@/atoms-react/text/Text.react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { KypayAccount } from '@/features/kypay/types.ts'
type AccountSelectorProps = {
  accounts: KypayAccount[]
  size?: string
  prefix?: string
  onSwitchAccount?: () => void
  currentAccount?: KypayAccount
}
export const AccountSelector = ({
  accounts,
  size,
  onSwitchAccount,
  currentAccount,
  prefix
}: AccountSelectorProps) => {
  const { t } = useTranslation(['wallet'])
  return (
    <>
      {!accounts && (
        <>
          <Dropdown
            className={'max-w-' + size}
            disabled={true}
            label={t('kypay.account_switcher.label') + ' ' + prefix}
            items={[t('kypay.account_switcher.principal_account')].map((label) => ({
              key: label,
              value: label,
              render: ({ key, value, inDropdown }) => (
                <Fragment key={key}>
                  {(size !== 'sm' || inDropdown) && (
                    <div className={'w-full flex justify-between items-center ml-4'}>
                      <Base>{value}</Base>
                    </div>
                  )}
                </Fragment>
              )
            }))}
            showChevrons
            value={t('kypay.account_switcher.principal_account')}
          />
        </>
      )}
      {accounts?.length > 0 && (
        <Dropdown
          className={'max-w-' + size}
          label={t('kypay.account_switcher.label') + ' ' + prefix}
          items={(accounts ?? []).map((account) => ({
            key: account.account_id,
            value: account.is_principal_account
              ? t('kypay.account_switcher.principal_account')
              : prefix + ' - ' + account.partner_name,
            render: ({ key, value, inDropdown }) => (
              <Fragment key={key}>
                {(size !== 'sm' || inDropdown) && (
                  <div className={'w-full flex justify-between items-center ml-4'}>
                    <Base>{value}</Base>
                  </div>
                )}
              </Fragment>
            )
          }))}
          showChevrons
          search={{
            custom: (searchValue, item) =>
              (item.key + ' ' + item.value).toLowerCase().includes(searchValue.toLowerCase())
          }}
          initialValue={
            (accounts ?? []).find((account) => account.is_principal_account === true)?.account_id
          }
          value={
            currentAccount?.account_id
              ? currentAccount?.account_id
              : (accounts ?? []).find((account) => account.is_principal_account === true)
                  ?.account_id
          }
          onChange={({ key }) => {
            // here change
            onSwitchAccount(key)
          }}
        />
      )}
    </>
  )
}
