<template>
  <v-dialog v-model="dialog" persistent width="auto">
    <v-card class="cardBoxPopup">
      <v-card-title class="text-h5">{{ $t('olkypass.' + title) }}</v-card-title>
      <v-card-text>
        {{ $t('olkypass.' + message) }}
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col class="d-flex justify-space-between">
            <v-btn
              variant="flat"
              color="white"
              height="auto"
              class="text-none cancelBtn"
              size="medium"
              @click="cancel"
            >
              {{ $t('olkypass.accountPage.dialog.confirm.button.disagree') }}
            </v-btn>
            <v-btn
              variant="flat"
              color="primary"
              height="auto"
              class="text-none validationBtn"
              size="medium"
              @click="agree"
            >
              {{ $t('olkypass.accountPage.dialog.confirm.button.agree') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogConfirm',
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null
  }),
  methods: {
    open(title, message) {
      this.dialog = true
      this.title = title
      this.message = message
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>

<style scoped></style>
