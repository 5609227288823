import { Button, ButtonIcon } from '@/atoms-react/button/Button.react'
import Tag from '@/atoms-react/tag/Tag.react'
import { Document, MimeType } from '@/features/user/document/types'
import { DocumentRow } from '@/molecules/document-row/index.react'
import { Themes } from '@/types/theme'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import { UserType } from '@/features/user/types'
import { useMemo, useState } from 'react'
import { useDocument } from '@/features/user/document/hooks/use-document'
import { Base } from '@/atoms-react/text/Text.react'

type DocumentViewerProps = {
  document: Document
  user: UserType
} & React.ComponentPropsWithoutRef<'div'>

export const DocumentViewer = ({ document, user, ...props }: DocumentViewerProps) => {
  const { t } = useTranslation(['pass'])

  const [downloadButton, setDownloadButton] = useState<boolean>(false)

  const { DocumentQuery } = useDocument()
  const { data: docData } = DocumentQuery(user.naturalPerson.UUID, document?.Document?.id || '')
  const blobUrl = useMemo(() => {
    if (docData) {
      const fileMime = document.Document?.file_mime
      // récupérer le mime type et afficher en image ou pdf le fichier.
      // Si pas dans notre liste on dit que le fichier ne peut pas être afficher mais on propose quand même de le télécharger.
      if (fileMime && Object.values(MimeType).includes(fileMime)) {
        setDownloadButton(false)
        const blob = new Blob([docData], { type: fileMime })
        const url = URL.createObjectURL(blob)
        return url
      } else {
        setDownloadButton(true)
        const blob = new Blob([docData])
        const url = URL.createObjectURL(blob)
        return url
      }
    }
    return null
  }, [docData, document])

  const buttonIconClassName =
    'flex items-center justify-center rounded-sm h-12 w-12 bg-white hover:bg-slate-50 cursor-pointer'
  return <div {...props}>
    <div className="flex justify-between p-6">
      <DocumentRow document={document} clickable={false} />
      <Tag className="h-fit" size="sm">
        {t('aliasDocumentsPage.tag')}
      </Tag>
    </div>
    {/* Preview with 3 buttons */}
    <div className="hover:bg-gradient-to-t from-black/15 to-35% rounded-sm flex flex-col items-center flex-1 justify-start group relative">
      {downloadButton && blobUrl && document.Document?.file_name && <div className="flex-1">
          <Base>{t('aliasDocumentsPage.document_cannot_be_preview')}</Base>
          <a href={blobUrl} download={document.Document?.file_name}>
            <Button onClick={() => {}} className="w-full">
              {t('aliasDocumentsPage.download_document_button')}
            </Button>
          </a>
        </div>}
      {!downloadButton &&
        blobUrl &&
        document.Document?.file_mime &&
        ([MimeType.JPEG, MimeType.PNG].includes(document.Document.file_mime) ? <img className="w-full h-auto" src={`${blobUrl}`} /> : <iframe
            className="w-full flex-1 min-h-96"
            src={`${blobUrl}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&zoom=FitW`}
          />)}
      {blobUrl && <div className="flex bottom-0 gap-x-6 absolute justify-end">
          <div
            className={`${buttonIconClassName} mb-6 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out`}
          >
            <a href={blobUrl} download={document.Document?.file_name}>
              <ButtonIcon
                size="md"
                theme={Themes.white}
                element={ArrowDownTrayIcon}
                href={blobUrl}
                className="text-black"
              />
            </a>
          </div>

          {/*
                  Delete make no sense in document context
              <div
                  className={`${buttonIconClassName} mb-6 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out`}
              >
                  <ButtonIcon
                      size="md"
                      theme={Themes.white}
                      element={TrashIcon}
                      className="text-red-600"
                  />
              </div>
              */}
        </div>}
    </div>
  </div>
}
