import { Routes } from '@/features/router'
import { Navigate, Route } from 'react-router-dom'
import { Gateways } from './components/gateways/index.react.tsx'
import { Layout } from './components/layout/Layout.react.tsx'

function FlowRoot() {
  return <Route
    path={''}
    element={
      <Layout>
        <Gateways />
      </Layout>
    }
  >
    {[
      '',
      Routes.flow + '/',
      Routes.flow_session,
      Routes.flow_block,
      Routes.flow_step,
      Routes.flow_substep
    ].map((route, index) => <Route key={index} path={route} element={<></>} />)}
    <Route path="*" element={<Navigate to={Routes.flow + '/-/welcome'} />} />
    <Route path="" element={<Navigate to={Routes.flow + '/-/welcome'} />} />
  </Route>
}

export default FlowRoot
