import Column from '@/atoms-react/column/Column.react'
import { CompanyConverters } from '@/features/company/converters/converters'
import { useCompany } from '@/features/company/hooks/use-react-company'
import { CompanyCreate, CompanyLinkNPLE } from '@/features/company/types'
import { Routes } from '@/features/router'
import { FormAddCompany, isCompanyValid } from '@/molecules/form-add-company/index.react'
import { FormRCS } from '@/molecules/form-rcs/index.react'
import _ from 'lodash'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { LayoutTitle } from '../../../_layout/index.react'
import { useCountryConverter } from '@/features/utils/hooks/use-country-converter'
import { useUser } from '@/features/user/hooks/use-user'
import { useLocale } from '@/features/utils/hooks/use-locale'
import DotsNavigation from '@/atoms-react/dots-navigation/DotsNavigation.react'
import { Button } from '@/atoms-react/button/Button.react'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { FormCompanyLinkNPLE } from '@/molecules/form-company-link-nple/index.react'
import { SectionTitle } from '@/atoms-react/text/Text.react'

type CompanyNewAppProps = React.ComponentPropsWithoutRef<'div'>

export enum CompanyAppPage {
  PageRCS = 'page-rcs',
  PageMain = 'page-main',
  PageCreator = 'page-creator'
}

export const CompanyNewApp = ({ ...props }: CompanyNewAppProps) => {
  const { t } = useTranslation(['pass'])
  const navigate = useNavigate()
  const [page, setPage] = useState<CompanyAppPage>(CompanyAppPage.PageRCS)
  const [data, setData] = useState<CompanyCreate>({})
  const [linkNPLE, setLinkNPLE] = useState<Partial<CompanyLinkNPLE>>({})
  const [RCSRegistrationCountry, setRCSRegistrationCountry] = useState<number | null>(null)

  useLayoutEffect(() => setPage(CompanyAppPage.PageRCS), [])

  useEffect(() =>{ window.scrollTo(0, 0)}, [page])

  const { countryID_Alpha2 } = useCountryConverter()
  const { useCompanyDetailsByRCS, useCreateCompany, companiesQuery } = useCompany()
  const { mutateAsync: createBusiness } = useCreateCompany()
  const { refetch: refetchCompanies } = companiesQuery()
  const { mutateAsync: getCompanyDetailsByRCS } = useCompanyDetailsByRCS()

  const { useUserProfile } = useUser()
  const { data: user } = useUserProfile()
  const userId = user?.naturalPerson?.UUID

  const { locale } = useLocale()
  const { useLegalStatus } = useUser()
  const { data: legalStatus } = useLegalStatus(locale.language)

  const execute = async () => {
    if (!isCompanyValid(data)) {
      toast.error(t('olkypass.general.must_enter_all'))
    }

    const dataDTO = CompanyConverters.Create_CreateDTO(data, {
      uuidNP: userId,
      ...linkNPLE
    } as CompanyLinkNPLE)
    const response = await createBusiness(dataDTO)

    if (response.success) {
      toast.success(t('olkypass.general.success_save'))

      navigate(Routes.companies)
      refetchCompanies()
    } else {
      toast.error(t('olkypass.general.error_save'))
      setPage(CompanyAppPage.PageMain)
    }
  }

  return <Column
    className={twMerge('flex flex-col pb-8', props.className)}
    {..._.omit(props, 'className')}
  >
    <LayoutTitle back>{t('business.new.title')}</LayoutTitle>

    <Column center className="grow">
      {page === CompanyAppPage.PageRCS && <Column className={'mt-4 lg:w-1/2'} center>
          <FormRCS
            data={{
              number: data?.RCS?.number ?? null,
              email: data.company?.email ?? null,
              registrationCountry: RCSRegistrationCountry
            }}
            onChange={data => {
              setData(d => ({
                ...d,
                RCS: { ...d.RCS, number: data.number ?? '' },
                company: { ...d.company, email: data.email ?? '' },
                address: { ...d.address, countryCode: data.registrationCountry?.toString() }
              }))
              setRCSRegistrationCountry(data.registrationCountry)
            }}
            onValidated={async dataRCS => {
              const safeRCS = (dataRCS.number ?? '').replace(/ /gm, '')
              setData(data => ({ ...data, RCS: { ...data.RCS, number: safeRCS } }))

              const res = await getCompanyDetailsByRCS({
                country: countryID_Alpha2(dataRCS.registrationCountry ?? -1) ?? 'fr',
                rcs: safeRCS ?? ''
              })
              const legalInfo = res?.data?.legalInformation

              if (legalInfo) {
                setData(data => ({
                  ...data,
                  company: {
                    ...data.company,
                    name: legalInfo.name,
                    legalForm:
                      (legalStatus?.legalStatus ?? []).find(
                        ls => ls.name === legalInfo.formeJuridique
                      )?.id ?? ''
                  },
                  RCS: {
                    ...data.RCS,
                    incorporationDate: new Date(legalInfo.dateImmatriculation.date)
                  },
                  address: {
                    ...data.address,
                    addressLine: legalInfo.adresse,
                    city: legalInfo.city,
                    zip: legalInfo.cp
                  }
                }))
              }

              setPage(CompanyAppPage.PageMain)
            }}
          />
        </Column>}
      {page === CompanyAppPage.PageMain && <Column className={'w-full pb-10'} center>
          <SectionTitle>{t('business.new.general.title')}</SectionTitle>
          <br />
          <br />
          <FormAddCompany
            fields={{
              company: {
                incorporationDate: {
                  required: !!data.RCS?.number
                },
                number: {
                  required: false
                },
                VATnumber: {
                  required: false
                },
                url: {
                  required: false
                }
              },
              activity: {}
            }}
            data={data}
            onChange={async (data) => setData((d) => ({ ...d, ...data }))}
          />
          <div
            className={'flex flex-col items-center mt-6 2xl:w-3/4 text-center'}
            data-tooltip={!isCompanyValid(data) ? t('input.form_incomplete') : undefined}
          >
            <DotsNavigation total={3} current={1} />
            <Button
              shortcut="enter"
              onClick={() => {
                if (isCompanyValid(data)) {
                  setPage(CompanyAppPage.PageCreator)
                } else {
                  window.dispatchEvent(new Event('checkRequiredFields'))
                  toast.error(t('input.form_incomplete'))
                }
              }}
              icon={ChevronRightIcon}
              className="mt-4 w-full md:w-auto"
            >
              {t('input.continue')}
            </Button>
          </div>
        </Column>}
      {page === CompanyAppPage.PageCreator && <Column className={'mt-4 max-w-4xl w-full'} center>
          <SectionTitle>
            {t('business.collaborators.creator.title', { name: data.company?.name })}
          </SectionTitle>
          <br />
          <br />

          <FormCompanyLinkNPLE
            data={linkNPLE}
            onChange={async (data) => setLinkNPLE((d) => ({ ...d, ...data }))}
          />
          <div className={'flex flex-col items-center mt-6 2xl:w-3/4 text-center'}>
            <DotsNavigation total={3} current={2} />
            <Button
              shortcut="enter"
              onClick={() => execute()}
              icon={ChevronRightIcon}
              className="mt-4 w-full md:w-auto"
            >
              {t('input.continue')}
            </Button>
          </div>
        </Column>}
    </Column>
  </Column>
}
