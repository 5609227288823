<template>
  <ion-row>
    <ion-col>
      <div class="card pt-4 card-plain p-0">
        <div class="card-header p-0 position-relative">
          <div class="bg-gradient-primary border-radius-lg pt-4 pb-3">
            <div class="multisteps-form__progress">
              <button
                class="multisteps-form__progress-btn"
                :class="parseInt(currentStep) < 1 ? 'js-active' : ''"
                type="button"
                title="User Info"
              >
                <span>Choice</span>
              </button>
              <button
                class="multisteps-form__progress-btn"
                :class="parseInt(currentStep) < 2 ? 'js-active' : ''"
                type="button"
                title="Address"
              >
                <span>Type</span>
              </button>
              <button
                class="multisteps-form__progress-btn"
                :class="parseInt(currentStep) < 3 ? 'js-active' : ''"
                type="button"
                title="Order Info"
              >
                <span>Reason</span>
              </button>
              <button
                class="multisteps-form__progress-btn"
                :class="parseInt(currentStep) < 4 ? 'js-active' : ''"
                type="button"
                title="Order Info"
              >
                <span>Summary</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ion-col>
  </ion-row>
</template>

<script lang="ts">
import { IonRow, IonCol } from '@ionic/vue'

export default {
  name: 'Stepper',
  components: {
    IonRow,
    IonCol
  },
  props: ['currentStep'],
  mounted() {}
}
</script>

<style scoped></style>
