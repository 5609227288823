import { defineJsxComponent } from '@/jsx-utils'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon
} from '@heroicons/vue/20/solid'
import { Base } from '../text/Text.tsx'

type Props = {
  type?: 'info' | 'warning' | 'error' | 'success' | 'primary'
  class?: string
}

export const Notice = defineJsxComponent<Props>((props, { slots }) => {
  let color = 'bg-slate-100 border-slate-200 dark:bg-slate-800 dark:border-slate-700'
  if (props.type === 'warning') {
    color = 'bg-yellow-100 border-yellow-200 dark:bg-yellow-800 dark:border-yellow-700'
  } else if (props.type === 'error') {
    color = 'bg-red-100 border-red-200 dark:bg-red-800 dark:border-red-700'
  } else if (props.type === 'success') {
    color = 'bg-green-100 border-green-200 dark:bg-green-800 dark:border-green-700'
  } else if (props.type === 'primary') {
    color = 'bg-blue-100 border-blue-200 dark:bg-slate-800 dark:border-blue-700'
  }

  return () => <div
    class={
      props.class + ' flex flex-row space-x-3 px-3 py-3 border-solid border rounded-md ' + color
    }
  >
    {props.type === 'info' && <div>
        <InformationCircleIcon class="h-7 w-7 text-slate-500" />
      </div>}
    {props.type === 'primary' && <div>
        <InformationCircleIcon class="h-7 w-7 text-blue-500" />
      </div>}
    {props.type === 'warning' && <div>
        <ExclamationCircleIcon class="h-7 w-7 text-yellow-500" />
      </div>}
    {props.type === 'error' && <div>
        <ExclamationCircleIcon class="h-7 w-7 text-red-500" />
      </div>}
    {props.type === 'success' && <div>
        <CheckCircleIcon class="h-7 w-7 text-green-500" />
      </div>}
    <Base>{slots.default()}</Base>
  </div>
})
