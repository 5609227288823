import React, { useCallback, useEffect, useMemo, useState } from 'react'
import 'react-calendar/dist/Calendar.css'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

type InputDateProps = {
  value: string
  onChange?: (newDate: string) => void
  className?: string
} & Omit<Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange'>, 'value'>

function FormattedInputDate({ value, onChange = () => {}, className, ...props }: InputDateProps) {
  const { i18n } = useTranslation()
  const [shownValue, setShownValue] = useState<string>('')

  const languageFormats: { [key: string]: { format: string; placeholder: string; regex: RegExp } } =
    {
      en: { format: 'yyyy-MM-dd', placeholder: 'YYYY-MM-DD', regex: /^\d{2}\/\d{2}\/\d{4}$/ },
      fr: { format: 'dd/MM/yyyy', placeholder: 'JJ/MM/AAAA', regex: /^\d{2}\/\d{2}\/\d{4}$/ }
    }

  const languageFormat = useMemo(() => {
    let language = i18n.language as string
    if (!(language in languageFormats)) {
      language = 'en'
    }
    return languageFormats[language]
  }, [i18n.language])

  useEffect(() => {
    const dt = DateTime.fromISO(value)
    if (dt.isValid) {
      setShownValue(dt.toFormat(languageFormat.format))
    }
  }, [value, languageFormat])

  const onInputChange = useCallback(
    (e: any) => {
      let value: string =
        languageFormat.format === 'dd/MM/yyyy'
          ? e.target.value.replace(/[^0-9/]/g, '')
          : e.target.value.replace(/[^0-9-]/g, '')
      const onlyNumbers: string = e.target.value.replace(/[\D]/g, '')

      if (languageFormat.format === 'dd/MM/yyyy') {
        if (onlyNumbers.length == 3) {
          value = `${onlyNumbers.slice(0, 2)}/${onlyNumbers.slice(2)}`
        }
        if (onlyNumbers.length == 5) {
          value = `${onlyNumbers.slice(0, 2)}/${onlyNumbers.slice(2, 4)}/${onlyNumbers.slice(4)}`
        }
        if (onlyNumbers.length == 8) {
          value = `${onlyNumbers.slice(0, 2)}/${onlyNumbers.slice(2, 4)}/${onlyNumbers.slice(4, 8)}`
        }
      } else {
        if (onlyNumbers.length == 5) {
          value = `${onlyNumbers.slice(0, 4)}-${onlyNumbers.slice(4)}`
        }
        if (onlyNumbers.length == 7) {
          value = `${onlyNumbers.slice(0, 4)}-${onlyNumbers.slice(4, 6)}-${onlyNumbers.slice(6)}`
        }
        if (onlyNumbers.length == 8) {
          value = `${onlyNumbers.slice(0, 4)}-${onlyNumbers.slice(4, 6)}-${onlyNumbers.slice(6, 8)}`
        }
      }

      const [newDay, newMonth] =
        languageFormat.format === 'dd/MM/yyyy'
          ? [onlyNumbers.slice(0, 2), onlyNumbers.slice(2, 4)]
          : [onlyNumbers.slice(6, 8), onlyNumbers.slice(4, 6)]
      const newYear =
        languageFormat.format === 'dd/MM/yyyy' ? onlyNumbers.slice(4, 8) : onlyNumbers.slice(0, 4)
      let newDate = DateTime.now()
      if (newDay.length === 2) {
        newDate = newDate.set({ day: parseInt(newDay) })
      }
      if (newMonth.length === 2) {
        newDate = newDate.set({ month: parseInt(newMonth) })
      }
      if (newYear.length === 4) {
        let year = parseInt(newYear)
        const currentYear = DateTime.now().year
        if (currentYear - year > 150) {
          year = currentYear - 150
        } else if (currentYear - year < -150) {
          year = currentYear + 150
        }
        newDate = newDate.set({ year })
      }
      if (onlyNumbers.length >= 8 && newDate.isValid) {
        setShownValue(newDate.toFormat(languageFormat.format))
        onChange(newDate.toFormat('yyyy-MM-dd'))
        return
      } else {
        onChange('')
      }
      setShownValue(value)
    },
    [languageFormat, onChange]
  )

  return <input
    type="text"
    value={shownValue}
    className={className}
    onChange={onInputChange}
    placeholder={languageFormat.placeholder}
    {..._.omit(props, ['placeholder'])}
  />
}

export default FormattedInputDate
