import { getRoute } from '@/app/olkypass/utils/routes'
import { PhoneNumber } from '@/atoms-react/input/InputPhone.react'
import { CompanyCreate } from '@/features/company/types'
import { useCreateAccount } from '@/features/flow/hooks/use-create-account'
import { DeclarativeIdentity } from '@/features/flow/types'
import { Routes } from '@/features/router'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { animatedNext } from '../../layout/AnimatedForm.react'
import { AccountSecurityPassword } from './account-security-password/account-security-password.react'
import { AccountSecurityWebauthn } from './account-security-webauthn/account-security-webauthn.react'
import { CreatingAccount } from './creating-account/index.react'
import DeclarativeIdentityRoot from './declarative-identity/index.react'
import EnterPhone from './enter-phone/EnterPhone.react'
import { SignupType } from './signup-type-select/SignupTypeSelect.react'
import VerifyEmail from './verify-email/VerifyEmail.react'
import { client } from '@passwordless-id/webauthn'
import { StorageService } from '@/features/utils/storage'
import { NaturalPerson } from '@/features/user/types.ts'
import { useCompleteAccount } from '@/features/flow/hooks/use-complete-account.ts'

type SignupRootProps = {
  input: SignupInput
  onValidated?: (data: SignupOutput) => void
}

export type SignupInput = {
  email: string
}

export type SignupOutput = SignupState

export type SignupState = {
  verify_email: boolean
  phone: PhoneNumber | null
  signup_type: SignupType | null
  pro?: CompanyCreate | null
  identity?: DeclarativeIdentity | null
  security: {
    isWebauthn: boolean
    password: string | null
  }
}

function SignupRoot({ onValidated = () => {}, input }: SignupRootProps) {
  const { block, session_uuid, step } = useParams()
  const navigate = useNavigate()

  const [signupState, setSignupState] = useState<SignupState>({
    verify_email: false,
    phone: null,
    signup_type: null,
    security: {
      isWebauthn: false,
      password: null
    }
  })
  const [profile, setProfile] = useState<NaturalPerson>(null)
  const [missingKeys, setMissingKeys] = useState(null)

  // Right now isPendingCreateAccount from react-query is not working, this is a workaround
  const [isCreatingAccount, setIsCreatingAccount] = useState(false)
  const { mutateAsync: createAccount } = useCreateAccount()
  const { mutateAsync: completeMissingAccountInfos } = useCompleteAccount()

  useEffect(() => {
    const storedValue = sessionStorage.getItem('NewOlkyPayUser')
    if (storedValue) {
      const jsonItems = JSON.parse(storedValue)
      setProfile(jsonItems.profile.naturalPerson)
      setMissingKeys(jsonItems.missingKeys)
    } else {
      if (!session_uuid || !step || !signupState.verify_email) {
        navigate(getRoute(Routes.flow_step, { session_uuid, block, step: 'verify_email' }), {
          replace: true
        })
      }
    }
    // Redirect to welcome if invalid block or just reloaded the page now
  }, [])
  useEffect(() => {
    const completeInfo = async () => {
      try {
        await completeMissingAccountInfos({
          signupState: {
            ...signupState,
            security: { isWebauthn: false, password: '' }
          },
          email: input.email,
          password: '',
          missingKeys
        })
      } catch (e) {
        console.log('Error occurred while completing account info:', e)
        // logout();
      }
    }
    if (profile && Object.keys(missingKeys).length > 0) {
      if (
        (Object.keys(missingKeys).length == 1 &&
          Object.prototype.hasOwnProperty.call(missingKeys, 'phone.phone') &&
          signupState.phone) ||
        (Object.keys(missingKeys).length >= 1 && signupState.identity)
      ) {
        // Check if identity is set before calling
        completeInfo()
        onValidated()
      }
    }
  }, [signupState, input.email])
  if (isCreatingAccount) {
    return <CreatingAccount />
  }

  return (
    <>
      {step === 'verify_email' && (
        <VerifyEmail
          input={{ email: input.email }}
          onValidated={() =>
            animatedNext('forward', async () => {
              setSignupState((state) => ({ ...state, verify_email: true }))
              navigate(getRoute(Routes.flow_step, { session_uuid, block, step: 'phone' }))
            })
          }
        />
      )}

      {step === 'phone' && (
        <EnterPhone
          onValidated={(output) =>
            animatedNext('forward', async () => {
              setSignupState((state) => ({
                ...state,
                phone: output.phone,
                signup_type: SignupType.individual
              }))
              navigate(getRoute(Routes.flow_step, { session_uuid, block, step: 'identity' }))
            })
          }
        />
      )}

      {/* {step === 'signup_type' && ( // Currently ignored as company creation will be in the app later
      <SignupTypeSelect
        onValidated={(output) => {
          animatedNext('forward', async () => {
            setSignupState((state) => ({ ...state, signup_type: output.signupType }))
            navigate(
              getRoute(Routes.flow_step, {
                session_uuid,
                block,
                step: 'identity'
              })
            )
          })
        }}
      />
    )} */}

      {step === 'identity' && (
        <DeclarativeIdentityRoot
          input={{
            email: input.email,
            phone: signupState.phone?.phone ?? ''
          }}
          onValidated={(declarativeIdentity) =>
            animatedNext('forward', async () => {
              setSignupState((state) => ({ ...state, identity: declarativeIdentity }))
              if (!(profile && Object.keys(missingKeys).length > 0)) {
                navigate(
                  getRoute(Routes.flow_step, {
                    session_uuid,
                    block,
                    step: 'security'
                  })
                )
              }
            })
          }
        />
      )}

      {/* {step === 'pro' && (
      <BusinessIdentityRoot
        input={{ email: input.email }}
        onValidated={(businessIdentity) => {
          animatedNext('forward', async () => {
            setSignupState((data) => ({ ...data, pro: businessIdentity }))
            navigate(
              getRoute(Routes.flow_step, {
                session_uuid,
                block,
                step: 'security'
              })
            )
          })
        }}
      />
    )} */}

      {step === 'security' && (
        <AccountSecurityPassword
          onValidated={async (output) =>
            animatedNext('forward', async () => {
              // This make sure we don't hide all the UI because of the AnimatedForm system
              // (that wait for the callback to finish before showing up next page)
              ;(async () => {
                setIsCreatingAccount(true)
                StorageService.setItem('jwt', {})
                try {
                  await createAccount({
                    signupState: {
                      ...signupState,
                      security: { isWebauthn: false, password: output.password }
                    },
                    email: input.email,
                    password: output.password ?? ''
                  })
                } finally {
                  setIsCreatingAccount(false)
                  StorageService.setItem('username', input.email)
                }

                if (!client.isAvailable()) {
                  animatedNext('forward', async () => onValidated(signupState))
                  return
                }

                setSignupState((state) => {
                  const newState = {
                    ...state,
                    security: { isWebauthn: false, password: output.password }
                  }
                  return newState
                })

                navigate(
                  getRoute(Routes.flow_step, {
                    session_uuid,
                    block,
                    step: 'webauthn'
                  })
                )
              })()
            })
          }
        />
      )}

      {step === 'webauthn' && (
        <AccountSecurityWebauthn onValidated={() => onValidated(signupState)} />
      )}
    </>
  )
}

export default SignupRoot
