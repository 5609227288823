import { ResponseError, SupplierService } from '../services/supplier.service'

const state = {
  responseData: '',
  responseErrorCode: 0,
  responseError: '',
  suppliers: [],
  currentSupplier: {},
  treatedEntity: '',
  currentSupplierCards: [],
  ribInfo: {}
}

const getters = {
  responseData: (state: { responseData: any }) => state.responseData,

  responseErrorCode: (state: { responseErrorCode: any }) => state.responseErrorCode,
  responseError: (state: { responseError: any }) => state.responseError,
  suppliers: (state: { suppliers: any }) => state.suppliers,
  currentSupplier: (state: { currentSupplier: any }) => state.currentSupplier,
  currentSupplierCards: (state: { currentSupplierCards: any }) => state.currentSupplierCards,
  treatedEntity: (state: { treatedEntity: any }) => state.treatedEntity,
  ribInfo: (state: { ribInfo: any }) => state.ribInfo
}

const actions = {
  async loadSuppliers(context: any) {
    context.commit('dataRequest')
    try {
      const resp = await SupplierService.getSuppliers()
      context.commit('successLoadSuppliers', resp)
      return resp
    } catch (e: any) {
      if (e instanceof ResponseError) {
        context.commit('dataError', {
          errorMessage: e.errorMessage,
          responseErrorCode: e.errorCode
        })
      }
      throw e
    }
  },
  async setCurrentSupplier(context: any, supplier: any) {
    context.commit('dataRequest')
    context.commit('setCurrentSupplier', supplier)
    try {
      const resp = await SupplierService.setCurrentSupplier(supplier.id)
      return resp
    } catch (e: any) {
      if (e instanceof ResponseError) {
        context.commit('dataError', {
          errorMessage: e.errorMessage,
          responseErrorCode: e.errorCode
        })
      }
      return e.message
    }
  },
  async loadRibInfo(context: any) {
    context.commit('dataRequest')
    try {
      const resp = await SupplierService.ribInfo()
      context.commit('loadRibInfo', resp)
      return resp
    } catch (e: any) {
      if (e instanceof ResponseError) {
        context.commit('dataError', {
          errorMessage: e.errorMessage,
          responseErrorCode: e.errorCode
        })
      }
      return e.message
    }
  },
  async downloadPdfIban(context: any) {
    context.commit('dataRequest')
    try {
      const resp = await SupplierService.downloadPdfIban()
      context.commit('dataSuccess', resp)
      return resp
    } catch (e: any) {
      if (e instanceof ResponseError) {
        context.commit('dataError', {
          errorMessage: e.errorMessage,
          responseErrorCode: e.errorCode
        })
      }
      return e.message
    }
  },
  async setCurrentSupplierCards(context: any, cards: any) {
    context.commit('setCurrentSupplierCards', cards)
  },
  setTreatedEntity(context: any, treatedEntity: any) {
    context.commit('setTreatedEntity', treatedEntity)
  }
}

const mutations = {
  dataRequest(state: { responseError: string; responseErrorCode: number }) {
    state.responseError = ''
    state.responseErrorCode = 0
  },
  dataSuccess(state: { responseData: string }, payload: any) {
    state.responseData = payload
  },
  successLoadSuppliers(state: { suppliers: any }, payload: any) {
    state.suppliers = payload
  },
  dataError(
    state: {
      responseError: any
      responseErrorCode: any
    },
    { errorCode, errorMessage }: any
  ) {
    state.responseError = errorMessage
    state.responseErrorCode = errorCode
  },
  setCurrentSupplier(state: { currentSupplier: any }, supplier: any) {
    state.currentSupplier = supplier
  },
  setCurrentSupplierCards(state: { currentSupplierCards: any }, supplier: any) {
    state.currentSupplierCards = supplier
  },
  setTreatedEntity(state: { treatedEntity: any }, treatedEntity: any) {
    state.treatedEntity = treatedEntity
  },
  loadRibInfo(state: { ribInfo: string }, payload: any) {
    state.ribInfo = payload
  }
}

export const supplier = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
