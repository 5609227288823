import { fetchServer } from '@/features/utils/fetch'
import i18n from '@/features/utils/i18n.ts'
import { StorageService } from '@/features/utils/storage'
import { utils } from '@passwordless-id/webauthn'
import _ from 'lodash'
import type { CredentialInterface } from '../types'

const webauthn_register_uri = {
  checkActivation: '/webauthn/register/check_activation',
  verifyUserRegistration: '/webauthn/register/verify_user_registration',
  storeWebauthnCredential: '/webauthn/register/store_credential',
  registrationOptions: '/webauthn/register/get_registration_options',
  removeCredential: '/webauthn/register/remove_credential',
  activateDeactivateCredential: '/webauthn/register/activate_deactivate_credential',
  updateDeviceName: '/webauthn/register/update_device_name'
}

export class WebAuthnRegisterApi {
  /**
   *
   */
  static async checkActivation() {
    const response = await fetchServer(webauthn_register_uri.checkActivation, {
      method: 'GET',
      mainRoot: true
    })

    if (200 === response.status) {
      const res = await response.json()
      return res.data as {
        isWebAuthnActivated: boolean
      }
    } else {
      // Handle cases where data is empty or not valid
      throw new Error('Invalid checkActivation')
    }
  }

  /**
   *
   */
  static async getRegistrationOptions() {
    const response = await fetchServer(webauthn_register_uri.registrationOptions, {
      method: 'GET',
      mainRoot: true,
      reloadOn401: false
    })

    if (200 === response.status) {
      const res = await response.json()
      return res.data as {
        rp: any
        user: any
        challenge: any
        pubKeyCredParams: Array<{ type: 'public-key'; alg: number }>
        timeout?: number
        attestation?: any
        authenticatorSelection?: AuthenticatorSelectionCriteria
        rawChallenge?: ArrayBuffer
        extensions?: any
      }
    } else {
      // Handle cases where data is empty or not valid
      throw new Error('Invalid registrationOptions')
    }
  }

  /**
   *
   * @param authnrData
   * @param clientData
   * @param deviceName
   */
  static async storeWebAuthnCredential(
    authnrData: any,
    clientData: any,
    deviceName: string,
    userShaId: any
  ) {
    const response = await fetchServer(webauthn_register_uri.storeWebauthnCredential, {
      method: 'POST',
      mainRoot: true,
      body: {
        authnrData,
        clientData,
        deviceName,
        domainName:
          window.location.hostname.indexOf('uat.') >= 0
            ? 'uat.olkypay.com'
            : window.location.hostname.indexOf('olky.eu') >= 0
              ? 'olky.eu'
              : window.location.hostname,
        userShaId
      }
    })
    if (200 === response.status) {
      const res = await response.json()
      return res.data as CredentialInterface // Return valid data
    } else {
      // Handle cases where data is empty or not valid
      throw new Error('Invalid storeWebauthnCredential')
    }
  }

  /**
   *
   * @param registration
   * @param expected
   */
  static async verifyUserRegistration(
    registration: {
      rawId: string
      response: { attestationObject: string; clientDataJSON: string }
    },
    expected: {
      challenge: string // whatever was randomly generated by the server
      origin: string
      factor: string
    }
  ) {
    const response = await fetchServer(webauthn_register_uri.verifyUserRegistration, {
      method: 'POST',
      mainRoot: true,
      body: {
        clientAttestationResponse: registration,
        attestationExpectations: expected
      }
    })
    if (200 === response.status) {
      const res = await response.json()
      return res.data as {
        request: any
        clientData: any
        authnrData: any
        expectations: any
      } // Return valid data
    } else {
      // Handle cases where data is empty or not valid
      throw new Error('Invalid verifyUserRegistration')
    }
  }

  /**
   *
   * @param deviceName
   */
  static async registerWebAuthnUser(deviceName: string) {
    try {
      const registrationOptions = await WebAuthnRegisterApi.getRegistrationOptions()
      // base 64
      registrationOptions.challenge = utils.toBuffer(registrationOptions.challenge)
      const challenge = registrationOptions.challenge
      registrationOptions.user.id = await utils.sha256(
        new TextEncoder().encode(`WEBAUTHN_USER_:${registrationOptions.user.name}${Date.now()}`)
      )
      console.log('registerWebAuthnUser', deviceName)
      if (deviceName === 'TODO_CHANGE_ME') {
        deviceName = `Appareil de ${registrationOptions.user.userIdentifier}`
        console.log('Update device name to ', deviceName)
      }

      const rawClientAttestationResponse = (await navigator.credentials.create({
        publicKey: registrationOptions
      })) as unknown as {
        rawId: ArrayBuffer
        response: any
        type: string
      }
      if (rawClientAttestationResponse) {
        const attestationExpectations = {
          challenge: utils.toBase64url(challenge), // whatever was randomly generated by the server
          origin: window.location.origin,
          factor: 'either'
        }
        const clientAttestationResponse = {
          rawId: utils.toBase64url(rawClientAttestationResponse?.rawId),
          response: {
            attestationObject: utils.toBase64url(
              rawClientAttestationResponse?.response?.attestationObject
            ),
            clientDataJSON: utils.toBase64url(
              rawClientAttestationResponse?.response?.clientDataJSON
            )
          }
          // ..._.omit(rawClientAttestationResponse, ['rawId', 'response'])
        }
        const verifyResponse = await WebAuthnRegisterApi.verifyUserRegistration(
          clientAttestationResponse,
          attestationExpectations
        )
        const userShaId = utils.toBase64url(registrationOptions.user.id)
        const storeResponse = await WebAuthnRegisterApi.storeWebAuthnCredential(
          verifyResponse.authnrData,
          verifyResponse.clientData,
          deviceName,
          userShaId
        )
        if (storeResponse.credential_id) {
          StorageService.setItem(
            `WEB_AUTHN_USER_${storeResponse.username}`,
            storeResponse.credential_id
          )
        }
        return challenge
      }
    } catch (error) {
      throw new Error((i18n().global as any).t('olkypay.notifications.error.webAuthn.registerUser'))
    }
  }

  /**
   *
   * @param id
   */
  static async removeCredential(id: any) {
    const response = await fetchServer(webauthn_register_uri.removeCredential, {
      method: 'DELETE',
      mainRoot: true,
      body: {
        id
      }
    })
    if (200 === response.status) {
      const res = await response.json()
      return res.data as CredentialInterface // Return valid data
    } else {
      // Handle cases where data is empty or not valid
      throw new Error('Invalid removeCredential')
    }
  }

  /**
   *
   * @param id
   * @param active
   */
  static async activateDeactivateCredential(id: any, active: boolean) {
    const response = await fetchServer(webauthn_register_uri.activateDeactivateCredential, {
      method: 'POST',
      mainRoot: true,
      body: {
        credential_id: id,
        active
      }
    })
    if (200 === response.status) {
      const res = await response.json()
      return res.data as CredentialInterface // Return valid data
    } else {
      // Handle cases where data is empty or not valid
      throw new Error('Invalid activateDeactivateCredential')
    }
  }

  /**
   *
   * @param id
   * @param active
   */
  static async updateDeviceName(id: any, deviceName: string) {
    const response = await fetchServer(webauthn_register_uri.updateDeviceName, {
      method: 'POST',
      mainRoot: true,
      body: {
        credential_id: id,
        device_name: deviceName
      }
    })
    if (200 === response.status) {
      const res = await response.json()
      return res.data as CredentialInterface // Return valid data
    } else {
      // Handle cases where data is empty or not valid
      throw new Error('Invalid updateDeviceName')
    }
  }
}
