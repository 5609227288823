<template>
  <v-row>
    <v-col>
      <v-sheet class="informationTitle">
        {{ $t('olkypass.accountPage.dialog.identity.step2Title') }}
      </v-sheet>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-sheet class="pa-2 ma-2 informationDesc">
        <p v-html="$t('olkypass.accountPage.dialog.identity.step2Content')"></p>
      </v-sheet>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <label>{{ $t('olkypass.accountPage.documentsSection.autocomplete.documentType') }}</label>
      <!--            <v-autocomplete
                      v-model="authentificationType"
                      :items="authentificationTypeList"
                      :item-title="getAuthentificationTypeLabel"
                      item-value="id"
                      variant="outlined"
                      autocomplete="off"
                      class="selectIconInput"
                      density="compact"
                  ></v-autocomplete>-->
      <v-select
        v-model="kycSession.document.type_id"
        :loading="!documentsType.length || loader"
        :items="documentsType"
        :item-title="documentTypeLabel"
        item-value="id"
        variant="outlined"
        autocomplete="off"
        class="selectIconInput"
        density="compact"
      ></v-select>
    </v-col>
  </v-row>
  <v-row v-if="checkNeedMoreData">
    <v-col cols="12">
      <label>{{ $t('olkypass.accountPage.documentsSection.input.documentName') }}</label>
      <v-text-field
        v-model="kycSession.document.other_name"
        density="comfortable"
        type=""
        clearable
        variant="plain"
      >
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from '../../store'
import store from '../../store'

export default {
  name: 'KYCIdentityTypes',
  data() {
    return {
      loader: false
    }
  },
  async mounted() {
    this.loader = true
    await store.dispatch('loadFiltersDocumentsType', { category: 2 })
    this.loader = false
  },
  computed: {
    ...mapGetters(['kycSession', 'documentsType', 'documentTypeLabel']),
    checkNeedMoreData() {
      let needMore = false
      this.documentsType.forEach(element => {
        if (this.kycSession.document.type_id && element.id == this.kycSession.document.type_id) {
          needMore = element.need_other_name
          this.kycSession.document.need_other_name = element.need_other_name
          this.kycSession.document.need_manual_validation = element.need_manual_validation
          this.kycSession.document.name_fr = element.type_fr
          this.kycSession.document.name_en = element.type_en
        }
      })
      return needMore
    }
  }
}
</script>
