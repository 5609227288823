import classNames from 'classnames'
import _ from 'lodash'
import React, { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type TextProps = {
  type:
    | 'big-title'
    | 'title'
    | 'section-title'
    | 'section-title-small'
    | 'base'
    | 'label'
    | 'info'
    | 'cardTitle'
  children?: ReactNode | ReactNode[]
  className?: string
  noColor?: boolean
  dataTooltip?: string
} & Omit<React.ButtonHTMLAttributes<HTMLSpanElement>, 'children' | 'className' | 'type' | 'noColor'>

type TypedTextProps = Omit<TextProps, 'type'>

export const BigTitle = (props: TypedTextProps) => Text({ type: 'big-title', ...props })

export const Title = (props: TypedTextProps) => Text({ type: 'title', ...props })

export const SectionTitle = (props: TypedTextProps) => Text({ type: 'section-title', ...props })

export const SectionTitleSmall = (props: TypedTextProps) =>
  Text({ type: 'section-title-small', ...props })

export const Base = (props: TypedTextProps) => Text({ type: 'base', ...props })

export const Label = (props: TypedTextProps) => Text({ type: 'label', ...props })

export const Info = (props: TypedTextProps) => Text({ type: 'info', ...props })

export const CardTitle = (props: TypedTextProps) => Text({ type: 'cardTitle', ...props })

const Text = (props: TextProps) => {
  let defaultClassName = ''

  switch (props.type) {
    case 'big-title':
      defaultClassName =
        'text-3xl lg:text-5xl leading-tight lg:leading-tight font-bold block' +
        ' ' +
        (props.noColor ? '' : 'text-black dark:text-white')
      break
    case 'title':
      defaultClassName =
        'text-3xl lg:text-4xl leading-tight lg:leading-tight font-bold block' +
        ' ' +
        (props.noColor ? '' : 'text-black dark:text-white')
      break
    case 'section-title':
      defaultClassName =
        'text-xl md:text-2xl lg:text-2xl leading-tight lg:leading-tight font-semibold block' +
        ' ' +
        (props.noColor ? '' : 'text-black dark:text-white')
      break
    case 'section-title-small':
      defaultClassName =
        'text-md leading-tight lg:leading-tight font-semibold block' +
        ' ' +
        (props.noColor ? '' : 'text-black dark:text-white')
      break
    case 'base':
      defaultClassName =
        'text-base font-medium block ' + ' ' + (props.noColor ? '' : 'text-black dark:text-white')
      break
    case 'info':
      defaultClassName =
        'text-sm font-normal' + ' ' + (props.noColor ? '' : 'text-slate-400 dark:text-slate-400')
      break
    case 'label':
      defaultClassName =
        'text-base font-medium block ' + ' ' + (props.noColor ? '' : 'text-black dark:text-white')
      break
    case 'cardTitle':
      defaultClassName =
        'text-xl font-semibold block' + ' ' + (props.noColor ? '' : 'text-black dark:text-white')
      break
  }

  return <span
    className={twMerge(classNames(defaultClassName, props.className || ''))}
    {..._.omit(props, 'type', 'className', 'children', 'noColor')}
  >
    {props.children}
  </span>
}

export default Text
