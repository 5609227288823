<template>
  <v-card
    class="mx-auto dashboardBox olkyPassCard"
    height="320px"
    width="auto"
    @click="ionRouter.navigate(Routes.account_identity, 'root')"
  >
    <v-card-item class="cardTopTitle">
      <template #title>
        {{ $t('olkypass.accountPage.cardActions.label.kyc') }}
      </template>
    </v-card-item>
    <v-card-text v-if="!loading" class="grow cardContentContainer">
      <v-img
        class="mx-auto progressIcon m-0 p-0"
        :src="getProgressIconPath(kycState.level)"
      ></v-img>
    </v-card-text>
    <v-card-actions v-if="!loading">
      <div class="no-ui databoxDetailsButton">
        {{ $t('olkypass.mainPage.olkypass.details') }}
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from '../../store'
import ToolsMixins from '../../mixins/ToolsMixins.js'
import { Routes } from '@/features/router'
import { useIonRouter } from '@ionic/vue'

export default {
  name: 'DashboardOlkyPassBox',
  mixins: [ToolsMixins],
  data: () => ({
    progressIconBasePath: '/images/olkypass/',
    olkypassLogoPath: '/images/olkypass/olkypass-logo.svg',
    ionRouter: useIonRouter(),
    Routes
  }),
  methods: {
    getProgressIconPath(level) {
      return this.progressIconBasePath + 'OlkyPass' + level + '.svg'
    }
  },
  computed: {
    ...mapGetters(['kycState', 'loading', 'naturalPerson', 'currentUUID', 'currentLanguage'])
  },
  async mounted() {
    this.detectNaturalPersonIdentity(this.naturalPerson)
  }
}
</script>
