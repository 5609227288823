<template>
  <v-dialog v-model="dialog" persistent width="auto">
    <v-card class="cardBoxPopup">
      <v-card-title class="text-h5">{{ $t('olkypass.' + title) }}</v-card-title>
      <v-card-text>
        {{ $t('olkypass.' + message) }}
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn
              variant="flat"
              color="white"
              height="auto"
              class="text-none cancelBtn"
              size="medium"
              @click="cancel"
            >
              {{ $t('olkypass.accountPage.dialog.sweetAlert.button.cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogInfo',
  data: () => ({
    dialog: false,
    message: null,
    title: null
  }),
  methods: {
    open(title, message) {
      this.dialog = true
      this.title = title
      this.message = message
    },
    cancel() {
      this.dialog = false
    }
  }
}
</script>

<style scoped></style>
