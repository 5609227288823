import { RegisterApiClient } from '@/features/register/api-client/api-client'
import { CheckEmailResponse } from '@/features/register/types'
import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'

export default function useCheckEmailMutation(
  options?: UseMutationOptions<CheckEmailResponse, Error, string>
) {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { t } = useTranslation(['pass'])

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available')
      return
    }

    return executeRecaptcha('login')
  }, [executeRecaptcha])

  return useMutation<CheckEmailResponse, Error, string>({
    mutationFn: async (email: string) => {
      const token = await handleReCaptchaVerify()

      // @TODO detect language correctly
      const data = await RegisterApiClient.checkEmail({
        email,
        captcha_token: token,
        language: 'fr'
      })

      if (!data.success) {
        throw new Error(t('flow.id_control.error.check_email'))
      }

      return data
    },
    ...options
  })
}
