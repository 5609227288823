<template>
  <v-card class="mx-auto olkyWalletCard" height="320px" width="auto">
    <v-card-item>
      <template #title>
        {{ $t('olkypass.accountPage.cardActions.label.wallet') }}
      </template>
      <template #append>
        <v-img class="mx-auto" height="30px" width="77px" :src="olkywalletLogoPath"></v-img>
      </template>
    </v-card-item>
    <v-card-text v-if="!loading">
      <v-row class="ma-0">
        <v-col class="currentSold"> 38 073,00€ </v-col>
      </v-row>
      <v-row class="ma-0 px-10">
        <v-col class="chart">
          <DashboardOlkyWalletChart></DashboardOlkyWalletChart>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from '../../store'
import DashboardOlkyWalletChart from './OlkyWalletChart.vue'

export default {
  name: 'DashboardOlkyWalletDataBox',
  components: {
    DashboardOlkyWalletChart
  },
  data: () => ({
    olkywalletLogoPath: '/images/olkypass/olkywallet-logo.svg'
  }),
  computed: {
    ...mapGetters(['loading'])
  }
}
</script>
