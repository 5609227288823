import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { SecurityApiClient } from '../api-client/api-client'
import { AddTraceResponse } from '../types'

export default function useAddTraceMutation(options?: UseMutationOptions<AddTraceResponse, Error>) {
  return useMutation<AddTraceResponse, Error>({
    mutationFn: async () => {
      const res = await SecurityApiClient.openDemandOfAuthentication()
      console.log('useAddTraceMutation', res)
      if (!res.success) {
        throw new Error(res.data)
      }
      return res.data
    },
    ...options
  })
}
