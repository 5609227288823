import { Button } from '@/atoms/buttons/Button'
import { Input } from '@/atoms/inputs/Input'
import { LabelDecoration } from '@/atoms/inputs/decorations/LabelDecoration'
import { Link } from '@/atoms/link/Link2'
import { BaseSmall } from '@/atoms/text/Text.tsx'
import { useLogin as useAuth } from '@/features/auth/hooks/use-login-vue'
import { Routes } from '@/features/router'
import { defineJsxComponent } from '@/jsx-utils'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

type Props = {
  onSwitchAuthMode: () => void
}

export const FormView = defineJsxComponent<Props>(props => {
  const { t } = useI18n()
  const { loader, webAuthnLogin, login } = useAuth()
  const email = ref('')
  const password = ref('')
  const usePassword = ref(false)
  return () => <form
    class="mt-6 text-left"
    onSubmit={async (e: any) => {
      e.preventDefault()
      if (usePassword.value) {
        await login(email.value, password.value)
      } else {
        if (!(await webAuthnLogin(email.value))) {
          props.onSwitchAuthMode()
          usePassword.value = true
        }
      }
    }}
  >
    <LabelDecoration class="my-4" label={t('login.loginPage.label.account')}>
      {{
        input: (input: any) => <Input
          type="email"
          autocomplete="email"
          autocapitalize="none"
          size="lg"
          placeholder="jean.dupont@mail.com"
          disabled={loader.loading}
          class={input.class}
          v-model={email.value}
          required
        />
      }}
    </LabelDecoration>

    {usePassword.value && <LabelDecoration class="my-4" label={t('login.loginPage.label.password')}>
        {{
          input: (input: any) => <Input
            size="lg"
            type="password"
            placeholder="••••••••"
            autocapitalize="none"
            autocomplete="current-password"
            disabled={loader.loading}
            class={input.class}
            v-model={password.value}
            minLength={8}
            required
          />
        }}
      </LabelDecoration>}

    <div class="flex flex-row items-center w-full mt-6">
      <BaseSmall>
        <Link to={Routes.forget_password}>{t('login.loginPage.label.forget_password')}</Link>
      </BaseSmall>

      <div class="grow" />

      <Button type="submit" theme="primary" size="lg" loading={loader.loading}>
        {t('login.loginPage.button.login')}
      </Button>
    </div>
  </form>
})
