const state = {
  loading: false,
  internalLoader: false
}

const getters = {
  loading: state => state.loading,
  internalLoader: state => state.internalLoader
}

const mutations = {
  PLAY_LOADER(state) {
    state.loading = true
  },
  STOP_LOADER(state) {
    state.loading = false
  },
  PLAY_INTERNAL_LOADER(state) {
    state.internalLoader = true
  },
  STOP_INTERNAL_LOADER(state) {
    state.internalLoader = false
  }
}

const actions = {
  playLoader({ commit }) {
    commit('PLAY_LOADER')
  },
  stopLoader({ commit }) {
    commit('STOP_LOADER')
  },
  playInternalLoader({ commit }) {
    commit('PLAY_INTERNAL_LOADER')
  },
  stopInternalLoader({ commit }) {
    commit('STOP_INTERNAL_LOADER')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
