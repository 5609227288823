<template>
  <v-dialog v-model="dialog" persistent width="auto">
    <v-card class="cardBoxPopup">
      <v-card-title class="text-h5">{{
        $t('olkypass.accountPage.emails_section.dialog.header.validateEmail')
      }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <label>{{ $t('olkypass.emailCodePage.input.code_mail') }}</label>
            <v-text-field
              v-model="validationCode"
              density="comfortable"
              variant="plain"
              :hint="availability_message"
              persistent-hint
              :messages="validation_message"
              :counter="8"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col class="d-flex justify-space-between">
            <v-btn
              variant="flat"
              color="white"
              height="auto"
              class="text-none cancelBtn"
              size="medium"
              @click="cancel"
            >
              {{ $t('olkypass.emailCodePage.button.cancel') }}
            </v-btn>
            <v-btn
              variant="flat"
              color="primary"
              height="auto"
              class="text-none validationBtn"
              :loading="loader"
              :disabled="!validationCode"
              size="medium"
              @click="checkValidationCode"
            >
              {{ $t('olkypass.emailCodePage.button.next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchServer } from '@/features/utils/fetch.ts'
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import { mapGetters } from '../../store'
import store from '../../store'

export default {
  name: 'EmailCodeFormDialog',
  mixins: [NotificationsMixins],
  data: () => ({
    dialog: false,
    email: {
      validation_buffer: null,
      UUID: null
    },
    validationCode: null,
    loader: false,
    uri: '/aliasMail/setValidMail',
    setCurrentEntityFunctionName: '',
    validation_message: ''
  }),
  methods: {
    open(UUID, validation_buffer) {
      this.validationCode = ''
      this.dialog = true
      this.email.UUID = UUID
      this.email.validation_buffer = validation_buffer

      if (this.currentTreatedEntity === 'company') {
        this.setCurrentEntityFunctionName = 'setCurrentCompany'
      } else {
        this.setCurrentEntityFunctionName = 'setCurrentNaturalPerson'
      }
    },
    async cancel() {
      this.dialog = false
      await store.dispatch('playLoader')
      await store.dispatch(this.setCurrentEntityFunctionName, { UUID: this.currentUUID })
      await store.dispatch('stopLoader')
    },
    async checkValidationCode() {
      this.loader = true
      await fetchServer(this.uri, {
        body: {
          UUID: this.email.UUID,
          realIdentityUUID: this.currentUUID,
          encryptedData: this.email.validation_buffer,
          code: this.validationCode
        }
      })
        .then(async response => {
          if (!response.ok) {
            this.displayNotification(
              'error',
              this.$i18n.t('olkypass.accountPage.notifications.error.code_mail.invalid')
            )
            return
          }
          this.displayNotification(
            'success',
            this.$i18n.t('olkypass.accountPage.notifications.success.code_mail.valid')
          )
          await this.cancel()
          this.loader = false
        })
        .catch(error => {
          this.availability_message = this.$i18n.t(
            'olkypass.accountPage.notifications.error.code_mail.invalid'
          )
          this.validation_message = this.$i18n.t(
            'olkypass.accountPage.notifications.error.code_mail.invalid'
          )
        })
        .finally(() => this.loader = false)
    }
  },
  computed: {
    ...mapGetters(['currentTreatedEntity', 'currentUUID'])
  }
}
</script>

<style scoped></style>
