import Column from '@/atoms-react/column/Column.react'
import { Base } from '@/atoms-react/text/Text.react'
import { ArrowRightCircleIcon } from '@heroicons/react/24/solid'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

type InfoCardProps = {
  type: InfoCardType
  title?: string | null
  content?: React.ComponentPropsWithoutRef<'div'>
  children?: React.ReactNode
  withIcon?: boolean
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'content'>

export enum InfoCardType {
  warning = 'warning',
  error = 'error',
  info = 'info',
  success = 'success'
}

export const InfoCard = ({
  title = undefined,
  type = InfoCardType.info,
  children,
  content,
  ...props
}: InfoCardProps) => {
  const { t } = useTranslation(['atoms'])

  const getContrastColor = () => {
    if (type === InfoCardType.info) return 'bg-blue-500 bg-opacity-80'
    if (type === InfoCardType.warning) return 'bg-orange-600 bg-opacity-75'
    if (type === InfoCardType.error) return 'bg-red-600 bg-opacity-80'
    if (type === InfoCardType.success) return 'bg-lime-600 bg-opacity-85'
  }

  const getBackgroundImage = () => {
    if (type === InfoCardType.info)
      return "bg-[url('/images/olkypass/kypay-background.png')] bg-cover bg-center bg-no-repeat"
    if (type === InfoCardType.warning)
      return "bg-[url('/images/olkypass/kywallet-bg.png')] bg-cover bg-center bg-no-repeat"
    if (type === InfoCardType.error)
      return "bg-[url('/images/olkypass/kypass-bg.png')] bg-cover bg-center bg-no-repeat"
    if (type === InfoCardType.success)
      return "bg-[url('/images/olkypass/kypass-bg.png')] bg-cover bg-center bg-no-repeat"
  }

  const getTitle = () => {
    if (type === InfoCardType.info) return t('molecules.info_card.title_info')
    if (type === InfoCardType.warning) return t('molecules.info_card.title_warning')
    if (type === InfoCardType.error) return t('molecules.info_card.title_error')
    if (type === InfoCardType.success) return t('molecules.info_card.title_success')
  }

  return <div className={twMerge(`${getBackgroundImage()} flex h-max rounded-sm `, props.className)}>
    <div className={`${getBackgroundImage()} w-full rounded-sm`}>
      <div
        className={twMerge('w-full h-full p-4 rounded-sm text-white', getContrastColor())}
        {..._.omit(props, 'className')}
      >
        <div className="flex justify-between items-center">
          <Column
            className={twMerge('ml-2 justify-center items-center', content?.className)}
            {..._.omit(content, 'className')}
          >
            {title !== null && <Base className={twMerge(`font-medium text-white`, children ? 'mb-1 ' : 'mb-0')}>
                {title ? title : getTitle()}
              </Base>}
            {children}
          </Column>
          {props.withIcon && <ArrowRightCircleIcon className="ml-2 h-6 w-6 text-white hidden md:block" />}
        </div>
      </div>
    </div>
  </div>
}
