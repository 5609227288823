import Column from '@/atoms-react/column/Column.react'
import CodeInput from '@/atoms-react/input/CodeInput.react'
import { LayoutBaseProps } from '@/atoms-react/layout-base/LayoutBase.react'
import { JwtType } from '@/features/auth/types'
import useTwoFaAuthenticateMutation from '@/features/flow/hooks/use-two-fa-authenticate'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FlowHeader } from '../../../flow-header.react'

interface TwoFaAuthenticateProps extends LayoutBaseProps {
  className?: LayoutBaseProps['className']
  input: TwoFaAuthenticateInput
  onValidated?: (data: TwoFaAuthenticateOutput) => void
  overlayMode?: boolean
}

export type TwoFaAuthenticateInput = {
  email: string
  password: string
}
export type TwoFaAuthenticateOutput = JwtType

function TwoFaAuthenticate({ input, onValidated = () => {} }: TwoFaAuthenticateProps) {
  const { t } = useTranslation(['pass'])
  const { mutate: loginTwoFa, isPending: isPendingLoginTwoFa } = useTwoFaAuthenticateMutation()

  function execute(challenge: string) {
    loginTwoFa(
      { email: input.email, password: input.password, challenge },
      {
        onSuccess: data => {
          const jwt = { token: data.token, refresh: data.token }
          onValidated(jwt)
        },
        onError: () => toast.error(t('flow.id_control.error.challenge_failed'))
      }
    )
  }

  return <>
    <Column>
      <FlowHeader
        title={t('flow.id_control.label.two_fa_title')}
        description={t('flow.id_control.label.two_fa_description')}
      />
      <CodeInput
        codeNumberOfCharacters={6}
        className={'pb-4'}
        focus={true}
        onChange={code => execute(code)}
        loading={isPendingLoginTwoFa}
      />
    </Column>
  </>
}

export default TwoFaAuthenticate
