import { fetchServer } from '@/features/utils/fetch'

export class NifValidationApiClient {
  static async sendNifCode(nif: string, countryCode: string) {
    const response = await fetchServer(`/public/nif_validation/${nif}/${countryCode}`, {
      method: 'GET',
      reloadOn401: false
    })
    if (response.status !== 200) {
      throw new Error('Code cannot be send')
    }
    const res = await response.json()
    return res
  }
}
