import { Dropdown } from '@/atoms-react/dropdown/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { CompanyActivityCreate } from '@/features/company/types'
import { useUser } from '@/features/user/hooks/use-user'
import { useLocale } from '@/features/utils/hooks/use-locale'
import { FormFieldGroup, getRealFieldGroup } from '@/features/utils/types'
import React from 'react'
import { useTranslation } from 'react-i18next'

type FormAddActivityProps = {
  data: CompanyActivityCreate
  fields: FormFieldGroup<CompanyActivityCreate>
  onChange: (data: CompanyActivityCreate) => void
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>

export const FormAddActivity = ({ data, fields, onChange = () => {} }: FormAddActivityProps) => {
  const { t } = useTranslation(['pass'])
  const { locale } = useLocale()
  const { useLegalNace } = useUser()
  const { data: nace } = useLegalNace(locale?.language)

  const realFields = getRealFieldGroup(['nace', 'description'], fields)

  return <>
    {realFields?.nace?.visible && <Dropdown
        value={data?.nace ?? null}
        label={realFields?.nace?.label ?? t('business.input.activity_nace_label')}
        disabled={realFields?.nace?.disabled}
        required={realFields?.nace?.required}
        items={(nace?.naceCodes ?? []).map(naceItem => ({
          key: naceItem.id.toString(),
          value: naceItem.defaultName
        }))}
        search={true}
        className={'mb-4'}
        onChange={({ key }) => onChange({ ...data, nace: key })}
      />}

    {realFields?.description?.visible && <InputLabel
        value={data?.description ?? ''}
        onChange={(value: string) => onChange({ ...data, description: value })}
        label={realFields?.description?.label ?? t('business.input.activity_description_label')}
        placeholder={realFields?.description?.label ?? ''}
        disabled={realFields?.description?.disabled}
        required={realFields?.description?.required}
        rows={2}
      />}
  </>
}
