import { Base, Info, Title } from '@/atoms-react/text/Text.react'
import { Button } from '@/atoms-react/button/Button.react'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid'
import { Themes } from '@/types/theme'
import { RepartitionsList } from '../repartition-list/repartition-list.react'
import { FormWallet } from '../form-wallet/form.react'
import { DoughnutChart } from '../charts/DoughnutChart.react'
import { LineChart } from '../charts/LineChart.react'
import Image from '@/atoms-react/image/Image.react'
import { formatNumber } from '@/features/utils/functions'

type BigWalletCardProps = {
  data: any
  config: any
  chart?: any
  disabled?: boolean
  listData?: any
  formConfig?: any
} & React.ComponentPropsWithoutRef<'div'>

export const BigWalletCard = ({
  data,
  config,
  chart,
  disabled = false,
  listData,
  formConfig,
  ...props
}: BigWalletCardProps) => {
  const { t } = useTranslation(['atoms'])
  return (
    <div
      {...(disabled && { 'data-tooltip': t('molecules.kyc_alert.kyc_warning') })}
      className={twMerge(props.className, 'flex w-full')}
    >
      <div
        className={twMerge(
          'border-2 border-slate-50 rounded-sm px-4 py-2 md:px-6 md:py-4 w-full text-black dark:text-white dark:border-slate-700 flex flex-col justify-between',
          props.className,
          disabled && 'opacity-40 pointer-events-none'
        )}
        {..._.omit(props, 'data', 'config', 'className', 'chart', 'listData', 'formConfig')}
      >
        <div>
          {/* Header card (title, favorite) */}
          <div
            className={twMerge(
              'flex w-full justify-between items-center',
              config?.header?.className
            )}
          >
            <Base className="text-slate-300 flex items-center gap-2">
              {data?.image && (
                <Image src={data?.image} alt={data?.name} className={'w-8 h-8 inline-block'} />
              )}
              {data?.name}
            </Base>
            {config?.button?.text && (
              <Button
                className={'ml-2 text-base text-black w-auto py-4'}
                size="sm"
                onClick={() => {}}
                theme={Themes.white}
                icon={AdjustmentsHorizontalIcon}
                iconPosition="before"
              >
                {config?.button?.text}
              </Button>
            )}
          </div>
          {/* Body of card */}
          <div className={twMerge(`flex w-full`, config?.body?.className)}>
            {/* Amount and rate */}
            <div className="flex flex-col items-start gap-2 w-full">
              {_.isNumber(data?.current_price) && (
                <div className="w-full">
                  <Title className={twMerge('font-bold', config?.amount?.className)} noColor>
                    {formatNumber(data?.current_price)} {config?.amount?.unit}
                  </Title>
                </div>
              )}
              {_.isNumber(data?.price_change_percentage_24h) && (
                <div className="w-full">
                  <Info
                    className={twMerge(
                      'rounded-3xl text-black dark:text-black px-4 py-2 font-bold',
                      data.price_change_percentage_24h > 0 ? 'bg-green-500' : 'bg-red-500'
                    )}
                  >
                    {formatNumber(data?.price_change_percentage_24h)} {config?.rate?.unit}
                  </Info>
                </div>
              )}
              {/* Charts */}
              <div className="flex items-center gap-2 w-full">
                {chart && chart.type === 'Doughnut' && (
                  <>
                    <DoughnutChart
                      data={chart.data}
                      labels={chart.labels}
                      config={chart.config}
                      className={chart.className}
                    />
                  </>
                )}

                {chart && chart.type === 'Line' && (
                  <>
                    <LineChart
                      data={chart.data}
                      labels={chart.labels}
                      config={chart.config}
                      className={chart.className}
                    />
                  </>
                )}

                {listData && (
                  <>
                    <RepartitionsList data={listData} config={config} />
                  </>
                )}

                {formConfig && (
                  <>
                    <FormWallet config={formConfig} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
