import { SignupState } from '@/app/olkypass/flow/components/gateways/signup/index.react'
import { ConfirmationResponse } from '@/features/register/types'
import { useAddress } from '@/features/user/address/hooks/use-address'
import { AuthApiClient } from '@/features/user/api-client/api-client'
import { convert_naturalPerson_naturalEntityModify } from '@/features/user/converters'
import { factory_naturalPerson_partial_edit } from '@/features/user/factories'
import { useUser } from '@/features/user/hooks/use-user'
import useCreatePhone from '@/features/user/phone/hooks/use-create-phone'
import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

export type UseCompleteAccountProps = {
  signupState: SignupState
  email: string
  password: string
  missingKeys: any
}

export const useCompleteAccount = (
  options?: UseMutationOptions<ConfirmationResponse, Error, UseCompleteAccountProps>
) => {
  const { t } = useTranslation(['pass'])

  const { useEditProfile } = useUser()
  const { mutateAsync: editProfile } = useEditProfile()
  const { createAddressMutation } = useAddress()
  const { mutateAsync: createAddress } = createAddressMutation()
  const { mutateAsync: createPhone } = useCreatePhone()
  return useMutation<ConfirmationResponse, Error, UseCompleteAccountProps>({
    mutationFn: async ({ signupState, missingKeys }: UseCompleteAccountProps) => {
      const staticData = await AuthApiClient.getStaticData('fr')
      // Edit profile
      const user = await AuthApiClient.profile()

      const naturalPersonEdit = factory_naturalPerson_partial_edit(
        user.naturalPerson,
        signupState,
        staticData
      )

      const naturalEntity = convert_naturalPerson_naturalEntityModify(naturalPersonEdit)

      await editProfile(naturalEntity).catch((error) => {
        console.error('Error', error)
        throw new Error(t('flow.id_control.error.complete_credentials'))
      })
      if (Object.prototype.hasOwnProperty.call(missingKeys, 'identity.residence')) {
        await createAddress({
          userUUID: user?.naturalPerson?.UUID,
          address: {
            address: signupState.identity?.addressResidence?.addressLine ?? '',
            zip: signupState.identity?.addressResidence?.zip ?? '',
            country: parseInt(signupState.identity?.addressResidence?.countryCode ?? '-1'),
            city: signupState.identity?.addressResidence?.city ?? '',
            tag: 'Résidence principale'
          }
        })
      }
      if (Object.prototype.hasOwnProperty.call(missingKeys, 'phone.phone')) {
        await createPhone({
          MobileIndicatif: signupState.phone?.countryPrefix ?? '',
          MobileNumber: signupState.phone?.phone ?? '',
          UUID: user.naturalPerson.UUID
        })
      }

      return { success: true, message: '' }
    },
    ...options
  })
}
