import { LayoutTitle } from '@/app/olkypass/views/_layout/index.react'
import InputLabel from '@/atoms-react/input/Input.react'
import { SectionTitle } from '@/atoms-react/text/Text.react'
import _ from 'lodash'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

type CoinLayoutProps = {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  title: () => React.ReactNode
  sectionTitle: string | (() => React.ReactNode)
  body: () => React.ReactNode
  header?: () => React.ReactNode
  sub_header?: () => React.ReactNode
  transactions?: {
    title: string | (() => React.ReactNode)
    body: () => React.ReactNode
  }
  orders?: {
    title: string | (() => React.ReactNode)
    body: () => React.ReactNode
  }
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'title'>

export const CoinLayout = ({
  search,
  setSearch,
  title,
  sectionTitle,
  header,
  sub_header,
  body,
  transactions,
  orders
}: CoinLayoutProps) => {
  const { t } = useTranslation(['atoms'])

  return (
    <>
      <div className="flex justify-between">
        {_.isFunction(title) && title()}
        {!_.isFunction(title) && <LayoutTitle>{title}</LayoutTitle>}
      </div>

      {header && <div>{header()}</div>}
      {sub_header && <div>{sub_header()}</div>}

      {_.isFunction(sectionTitle) && sectionTitle()}
      {!_.isFunction(sectionTitle) && <SectionTitle>{sectionTitle}</SectionTitle>}

      <div
        className={
          'lg:flex mt-4 space-y-3 flex-col gap-4 lg:flex-row lg:space-y-0 lg:space-x-0 md:items-stretch '
        }
      >
        {body()}
      </div>

      {transactions && (
        <div className={'flex flex-col w-full mt-8 rounded-md h-max'}>
          <div className={'sticky top-[-25px] pt-6 bg-white dark:bg-slate-950 lg:-mx-8'}>
            <div className={'lg:mx-8'}>
              {_.isFunction(transactions.title) && transactions.title()}
              {!_.isFunction(transactions.title) && (
                <>
                  <SectionTitle className={'mb-4'}>{transactions.title}</SectionTitle>
                  {search && (
                    <InputLabel
                      value={search}
                      onChange={(value) => setSearch(value)}
                      className={'mb-8 hidden md:block'}
                      bgClass="bg-white"
                      placeholder={t('molecules.coin_layout.search_placeholder')}
                    />
                  )}
                </>
              )}
            </div>

            <div
              className={
                'flex w-full text-left text-slate-200 border-b-slate-50 dark:border-b-slate-800 border-b-2 px-8 hidden md:flex'
              }
            >
              <div className={'w-1/2 py-2 font-medium text-slate-300'}>
                {t('molecules.coin_layout.header_operations')}
              </div>
              <div className={'w-1/4 py-2 font-medium text-slate-300'}>
                {t('molecules.coin_layout.header_date')}
              </div>
              <div className={'w-1/4 py-2 font-medium text-slate-300 text-right'}>
                {t('molecules.coin_layout.header_amount')}
              </div>
            </div>
          </div>

          <div className={'justify-between rounded-md rounded-t-none lg:-mx-8 h-max'}>
            <div className={'flex flex-col w-full h-max'}>{transactions.body()}</div>
          </div>
        </div>
      )}

      {orders && (
        <div className={'flex flex-col w-full mt-8 rounded-md h-max'}>
          <div className={'sticky top-[-25px] pt-6 bg-white dark:bg-slate-950'}>
            {_.isFunction(orders.title) && orders.title()}
            {!_.isFunction(orders.title) && (
              <>
                <SectionTitle className={'mb-4'}>{orders.title}</SectionTitle>
                {search && (
                  <InputLabel
                    value={search}
                    onChange={(value) => setSearch(value)}
                    className={'mb-8 hidden md:block'}
                    bgClass="bg-white"
                    placeholder={t('molecules.coin_layout.search_placeholder')}
                  />
                )}
              </>
            )}
          </div>

          <div className={'justify-between rounded-md rounded-t-none h-max'}>
            <div className={'flex flex-col w-full h-max'}>{orders.body()}</div>
          </div>
        </div>
      )}
    </>
  )
}
