<template>
  <v-card
    id="supportDashboardBox"
    class="dashboardBox support"
    height="320px"
    width="auto"
    @click="ionRouter.navigate(Routes.support, 'root')"
  >
    <v-card-title>
      <v-avatar color="primary-darken-1" size="x-large">
        <v-icon icon="mdi-tooltip-question-outline" color="primary"></v-icon>
      </v-avatar>
      <h1>{{ $t('olkypass.mainPage.olkypass.bloc1.title') }}</h1>
    </v-card-title>
    <v-card-text>
      {{ $t('olkypass.mainPage.olkypass.bloc1.desc') }}
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn
        variant="flat"
        color="primary"
        height="auto"
        class="text-none validationBtn"
        size="medium"
      >
        {{ $t('olkypass.mainPage.olkypass.bloc1.btn') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Routes } from '@/features/router'
import { useIonRouter } from '@ionic/vue'

export default {
  name: 'DashboardSupportBox',
  data: () => ({
    ionRouter: useIonRouter(),
    Routes
  })
}
</script>
