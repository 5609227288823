<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      :md="this.inputResponsiveWidth"
      :lg="inputResponsiveWidth"
      class="pass_accountInfoChild_input"
    >
      <label>{{ $t('olkypass.accountPage.basicInfo.input.firstName') }}<sup>*</sup></label>
      <p v-if="isReadOnly('FirstName')" class="readOnlyForm">
        {{ naturalPerson.FirstName }}
      </p>
      <v-text-field
        v-if="!isReadOnly('FirstName')"
        v-model="naturalPerson.FirstName"
        density="comfortable"
        name="FirstName"
        variant="plain"
      >
      </v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      :md="inputResponsiveWidth"
      :lg="inputResponsiveWidth"
      class="pass_accountInfoChild_input"
    >
      <label>{{ $t('olkypass.accountPage.basicInfo.input.lastName') }}<sup>*</sup></label>
      <p v-if="isReadOnly('LastName')" class="readOnlyForm">
        {{ naturalPerson.LastName }}
      </p>
      <v-text-field
        v-if="!isReadOnly('LastName')"
        v-model="naturalPerson.LastName"
        density="comfortable"
        name="LastName"
        variant="plain"
      >
      </v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      :md="inputResponsiveWidth"
      :lg="inputResponsiveWidth"
      class="pass_accountInfoChild_input"
    >
      <label>{{ $t('olkypass.accountPage.basicInfo.input.otherFirstNames') }}</label>
      <p v-if="isReadOnly('OtherFirstNames')" class="readOnlyForm">
        {{ naturalPerson.OtherFirstNames }}
      </p>
      <v-text-field
        v-if="!isReadOnly('OtherFirstNames')"
        v-model="naturalPerson.OtherFirstNames"
        density="comfortable"
        name="OtherFirstNames"
        variant="plain"
      >
      </v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      :md="inputResponsiveWidth"
      :lg="inputResponsiveWidth"
      class="pass_accountInfoChild_input"
    >
      <label>{{ $t('olkypass.accountPage.basicInfo.input.maidenName') }}</label>
      <p v-if="isReadOnly('MaidenName')" class="readOnlyForm">
        {{ naturalPerson.MaidenName }}
      </p>
      <v-text-field
        v-if="!isReadOnly('MaidenName')"
        v-model="naturalPerson.MaidenName"
        density="comfortable"
        name="MaidenName"
        variant="plain"
      >
      </v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      :md="inputResponsiveWidth"
      :lg="inputResponsiveWidth"
      class="pass_accountInfoChild_input"
    >
      <label>{{ $t('olkypass.accountPage.basicInfo.input.birthDate') }}<sup>*</sup></label>
      <p v-if="isReadOnly('DateOfBirth.date')" class="readOnlyForm">
        {{ formatDate(naturalPerson.DateOfBirth.date) }}
      </p>
      <v-text-field
        v-if="!isReadOnly('DateOfBirth.date')"
        v-model="naturalPerson.DateOfBirth.date"
        density="comfortable"
        name="DateOfBirth"
        variant="plain"
        type="date"
      >
      </v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      :md="inputResponsiveWidth"
      :lg="inputResponsiveWidth"
      class="pass_accountInfoChild_input"
    >
      <label>{{ $t('olkypass.accountPage.basicInfo.input.stateOfBirth') }}<sup>*</sup></label>
      <p v-if="isReadOnly('StateOfBirth')" class="readOnlyForm">
        {{ naturalPerson.StateOfBirth }}
      </p>
      <v-text-field
        v-if="!isReadOnly('StateOfBirth')"
        v-model="naturalPerson.StateOfBirth"
        density="comfortable"
        name="StateOfBirth"
        variant="plain"
      >
      </v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      :md="inputResponsiveWidth"
      :lg="inputResponsiveWidth"
      class="pass_accountInfoChild_input"
    >
      <label>{{ $t('olkypass.accountPage.basicInfo.autocomplete.birthPlace') }}<sup>*</sup></label>
      <p v-if="isReadOnly('countryOfBirth')" class="readOnlyForm">
        {{ countryOfBirthName }}
      </p>
      <v-autocomplete
        v-if="!isReadOnly('countryOfBirth')"
        :model-value="naturalPerson.countryOfBirth ? parseInt(naturalPerson.countryOfBirth) : null"
        name="countryOfBirth"
        :items="
          countries
            .map(country => ({ value: country.id, title: country[countryLabel] }))
            // Sort Alphabetically according to app language
            .sort((a, b) =>
              new Intl.Collator(languageModule.state.currentLanguage).compare(a.title, b.title)
            )
        "
        :menu-props="{ width: '250px' }"
        variant="outlined"
        autocomplete="off"
        class="selectInput"
        density="compact"
        @update:model-value="naturalPerson.countryOfBirth = $event.toString()"
      ></v-autocomplete>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      :md="inputResponsiveWidth"
      :lg="inputResponsiveWidth"
      class="pass_accountInfoChild_input"
    >
      <label>
        {{ $t('olkypass.accountPage.basicInfo.autocomplete.countryOfCitizenship') }}<sup>*</sup>
      </label>
      <p v-if="isReadOnly('CountryOfCitizenship')" class="readOnlyForm">
        {{ nationalityName }}
      </p>

      <v-autocomplete
        v-if="!isReadOnly('CountryOfCitizenship')"
        :model-value="
          naturalPerson.CountryOfCitizenship ? parseInt(naturalPerson.CountryOfCitizenship) : null
        "
        name="CountryOfCitizenship"
        :items="nationalities.map(nat => ({ value: nat.id, title: nat[countryLabel] }))"
        :menu-props="{ width: '250px' }"
        variant="outlined"
        autocomplete="off"
        class="selectInput"
        density="compact"
        @update:model-value="naturalPerson.CountryOfCitizenship = $event.toString()"
      ></v-autocomplete>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      :md="inputResponsiveWidth"
      :lg="inputResponsiveWidth"
      class="pass_accountInfoChild_input"
    >
      <label>{{ $t('olkypass.accountPage.basicInfo.autocomplete.cspCode') }}<sup>*</sup></label>

      <p v-if="isReadOnly('CSPCode')" class="readOnlyForm">
        {{ (cspCodes.find(code => code.id === naturalPerson.CSPCode) || {})['defaultName'] }}
      </p>

      <v-select
        v-if="!isReadOnly('CSPCode')"
        v-model="naturalPerson.CSPCode"
        name="CSPCode"
        :items="cspCodes"
        :item-title="cspLabel"
        item-value="id"
        variant="outlined"
        autocomplete="off"
        class="selectInput"
        density="compact"
        :menu-props="{ width: '200px' }"
        @update:modelValue="resetActivityCode"
      ></v-select>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      :md="inputResponsiveWidth"
      :lg="inputResponsiveWidth"
      class="pass_accountInfoChild_input"
    >
      <label
        >{{ $t('olkypass.accountPage.basicInfo.autocomplete.activityCode') }}<sup>*</sup></label
      >
      <p v-if="isReadOnly('ActivityCode') && !this.isUserInactive()" class="readOnlyForm">
        {{ (activities.find(act => act.id === naturalPerson.ActivityCode) || {})['defaultName'] }}
      </p>

      <p class="justify-self-center self-center" v-if="this.isUserInactive()">Inactif</p>

      <v-select
        v-if="!isReadOnly('ActivityCode') && !this.isUserInactive()"
        v-model="naturalPerson.ActivityCode"
        name="ActivityCode"
        :items="activities"
        :item-title="activityLabel"
        item-value="id"
        variant="outlined"
        autocomplete="off"
        class="selectInput"
        density="compact"
        :menu-props="{ width: '200px' }"
      >
      </v-select>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      :md="this.inputResponsiveWidth"
      :lg="this.inputResponsiveWidth"
      class="flex pass_accountInfoChild_input"
    >
      <v-col sm="12" class="flex flex-col pass_accountInfoChild_inputNIF">
        <label>{{ $t('olkypass.accountPage.basicInfo.input.nif') }}</label>
        <!-- <p v-if="isReadOnly('NIF')" class="readOnlyForm">
          {{ naturalPerson.NIF }}
        </p> -->
        <v-text-field
          v-model="naturalPerson.NIF"
          density="comfortable"
          name="NIF"
          variant="plain"
          autocomplete="off"
        >
        </v-text-field>
      </v-col>
      <TooltipBubble />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import ToolsMixins from '../../mixins/ToolsMixins.js'
import { mapGetters } from '../../store'
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import { TooltipBubble } from '@/atoms/tooltip-bubble/TooltipBubble'
import languageModule from '@/app/olkypass/views/_deprecated/vue/store/modules/languageModule.js'
import { getLanguage } from '@/features/utils/i18n'

export default {
  name: 'AccountInfoChild',
  components: {
    TooltipBubble
  },
  mixins: [ToolsMixins, NotificationsMixins],
  props: {
    columns: Number,
    kycLevel: Number
  },
  data: () => ({
    uris: {
      _post: {
        profile: '/user/profile/edit'
      }
    },
    // editableFields: ['CountryOfCitizenship', 'ActivityCode', 'CSPCode'],
    editableFields: [
      'ActivityCode',
      'CSPCode',
      'FirstName',
      'LastName',
      'OtherFirstNames',
      'MaidenName',
      'DateOfBirth.date',
      'CountryOfCitizenship',
      'countryOfBirth',
      'StateOfBirth',
      'ActivityCode',
      'CSPCode',
      'NIF'
    ],
    panels: {
      basicInfo: {
        box_fields: [
          'FirstName',
          'LastName',
          'OtherFirstNames',
          'MaidenName',
          'DateOfBirth.date',
          'CountryOfCitizenship',
          'countryOfBirth',
          'StateOfBirth',
          'ActivityCode',
          'CSPCode'
        ]
      }
    },
    loader: false
  }),
  methods: {
    isReadOnly(attribute: string | null | undefined) {
      if (this.loading || !attribute) {
        return false
      }
      try {
        const value = this.getValueFromObject(this.inMemoryNP, attribute)

        if (this.kycLevel >= 50) {
          return true
        }
        return value !== '' && value !== null && !this.editableFields.includes(attribute)
      } catch (error) {
        return false
      }
    },
    isClearable(attribute: string | null | undefined) {
      return !this.isReadOnly(attribute)
    },
    hasUnsavedChanges(attribute: string | null | undefined) {
      if (attribute !== undefined) {
        return this.compareObjectsAttribute(this.inMemoryNP, this.naturalPerson, attribute)
      } else {
        return !this.isEqualObjects(this.inMemoryNP, this.naturalPerson)
      }
    },
    getCountryName(key: string, jsonArray: any) {
      const findCountryFiltred = jsonArray.find(function (element: any) {
        return element.id === parseInt(key)
      })

      return findCountryFiltred?.name
    },
    getNationalityName(key: string, jsonArray: any) {
      const findNationalityFiltred = jsonArray.find(function (element: any) {
        return element.id === parseInt(key)
      })
      return findNationalityFiltred?.name
    },
    resetActivityCode() {
      if (
        this.getCSPCodesNoActivity()
          .map(entity => entity.id)
          .includes(this.getCurrentCSPCodeCode())
      ) {
        this.naturalPerson.ActivityCode = null
      }
    },
    getLanguage() {
      return getLanguage()
    },
    isUserInactive() {
      return this.getCSPCodesNoActivity()
        .map(entity => entity.id)
        .includes(this.getCurrentCSPCodeCode())
    },
    formatDate(date) {
      const language = this.getLanguage()
      if (!date) return ''

      const dateObj = new Date(date)
      const year = dateObj.getFullYear()
      const month = `0${dateObj.getMonth() + 1}`.slice(-2) // months are 0-based
      const day = `0${dateObj.getDate()}`.slice(-2)

      if (language === 'fr') {
        return `${day}/${month}/${year}`
      } else {
        return `${year}-${month}-${day}`
      }
    }
  },
  computed: {
    ...mapGetters([
      'kycState',
      'loading',
      'naturalPerson',
      'inMemoryNP',
      'countries',
      'nationalities',
      'cspCodes',
      'activities',
      'countryLabel',
      'cspLabel',
      'activityLabel',
      'wantToLeavePage',
      'currentUUID',
      'currentLanguage',
      'hasUpdatePermission'
    ]),
    languageModule() {
      return languageModule
    },
    nationalityName() {
      return this.getNationalityName(this.naturalPerson.CountryOfCitizenship, this.nationalities)
    },
    countryOfCitizenship2Name() {
      return this.getCountryName(this.naturalPerson.CountryOfCitizenship2, this.countries)
    },
    countryOfBirthName() {
      return this.getCountryName(this.naturalPerson.countryOfBirth, this.countries)
    },
    inputResponsiveWidth() {
      if (this.columns == 2) return 6
      if (this.columns == 3) return 4

      return 4
    }
  }
}
</script>
