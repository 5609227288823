import { Document, DocumentType, UploadDocument } from '../types'
import { fetchServer } from '@/features/utils/fetch'

export class DocumentApiClient {
  static async getDocuments(UUID: string): Promise<Document[]> {
    const response = await fetchServer('/document/list', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: { UUID }
    })

    if (!response.ok) throw new Error('Error')

    const res = await response.json()
    return res
  }

  static async getDocumentsDetails(UUID: string): Promise<Document[]> {
    const response = await fetchServer(`/document/list_details/${UUID}`, {
      method: 'GET',
      reloadOn401: false,
      mainRoot: false
    })

    if (!response.ok) throw new Error('Error')

    const res = await response.json()
    return res
  }

  static async getDocumentDetail(UUID: string, algoregDocumentId: string): Promise<Document[]> {
    const response = await fetchServer(`/document/details/${UUID}/${algoregDocumentId}`, {
      method: 'GET',
      reloadOn401: false,
      mainRoot: false
    })

    if (!response.ok) throw new Error('Error')

    const res = await response.json()
    return res
  }

  static async getDocumentContent(UUID: string, algoregDocumentId: string): Promise<Blob> {
    const response = await fetchServer('/document/download', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: { UUID, algoregDocumentId }
    })

    if (!response.ok) throw new Error('Error')

    const res = await response.blob()
    return res
  }

  static async getDocumentTypes(): Promise<DocumentType[]> {
    const response = await fetchServer('/document/typesByCateg/2', {
      method: 'GET',
      reloadOn401: false,
      mainRoot: false
    })

    if (!response.ok) throw new Error('Error')

    const res = await response.json()
    return res
  }

  static async uploadDocument(document: UploadDocument): Promise<UploadDocument> {
    const response = await fetchServer('/document/upload', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: document
    })
    if (!response.ok) throw new Error('Error')

    const res = await response.json()
    return res
  }
}
