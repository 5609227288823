import { Button } from '@/atoms/buttons/Button'
import ButtonConfirm from '@/atoms/buttons/ButtonConfirm.vue'
import { Input } from '@/atoms/inputs/Input'
import LabelDecoration from '@/atoms/inputs/decorations/LabelDecoration'
import ModalVue from '@/atoms/modals/Modal.vue'
import ModalContentVue from '@/atoms/modals/ModalContent.vue'
import { SectionTitle } from '@/atoms/text/Text.tsx'
import { WebAuthnRegisterApi } from '@/features/auth/api-client/webauthn-register-api-client'
import { useSecurity } from '@/features/security/hooks/use-security'
import { useUser } from '@/features/user/hooks/use-user-vue'
import { defineJsxComponent } from '@/jsx-utils'
import { ShieldCheckIcon, ShieldExclamationIcon } from '@heroicons/vue/20/solid'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toast-notification'
import { SolidAuthnModal } from '../../../identity/components/security/solid-authn-modal.react'
import { applyPureReactInVue } from 'veaury'
import { WebAuthnApi } from '@/features/auth/api-client/webauthn-api-client.ts'
const SolidAuthnModalReact = applyPureReactInVue(SolidAuthnModal)

export const TrDevice = defineJsxComponent<{
  onComplete: () => void
  device: NonNullable<unknown>
}>((props, { attrs }) => {
  const t = useI18n().t
  const toast = useToast()
  const device = props.device as any
  const loading = ref(false)
  const changeStatusAction = ref(false)
  const editDeviceName = ref(false)
  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  const statusAction = async () => {
    try {
      const res = await WebAuthnRegisterApi.activateDeactivateCredential(device.id, !device.active)
      if (res) {
        await attrs.onComplete()
      }
    } catch (e) {
      toast.error(t('olkypass.securityPage.webauthnSection.devices.notifications.update.error'))
    }
  }
  const handleComplete = async () => {
    loading.value = true
    try {
      await attrs.onComplete()
      editDeviceName.value = false
    } catch (error) {
      toast.error(t('olkypass.securityPage.webauthnSection.devices.notifications.update.error'))
    } finally {
      loading.value = false
    }
  }

  return () => (
    <>
      {changeStatusAction.value && (
        <SolidAuthnModalReact
          action="activate_deactivate_webauthn_device"
          onClose={() => (changeStatusAction.value = false)}
          onValidated={async () => {
            changeStatusAction.value = false
            loading.value = true
            await statusAction()
            loading.value = false
          }}
        />
      )}
      <ModalVue
        open={editDeviceName.value}
        onClose={() => {
          editDeviceName.value = false
        }}
      >
        <ModalContentVue
          title={t('olkypass.securityPage.webauthnSection.devices.table.actions.update')}
        >
          <UpdateWebauthnDevice device={device} onComplete={handleComplete} />
        </ModalContentVue>
      </ModalVue>
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <th
          scope="row"
          className={
            device.active
              ? 'px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
              : 'px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white line-through'
          }
        >
          {device.device_name}
        </th>
        <td className="px-6 py-4">{formatDate(device.created_at)}</td>
        <td className="px-6 py-4">{device.domain_name}</td>
        <td className="px-6 py-4">
          {device.active && <ShieldCheckIcon class="h-5 w-5 text-green-500" />}
          {!device.active && <ShieldExclamationIcon class="h-5 w-5 text-yellow-500" />}
        </td>
        <td className="px-6 py-4 text-right">
          <Button class="my-2" theme="outlined" onClick={() => (editDeviceName.value = true)}>
            {t('olkypass.securityPage.webauthnSection.devices.table.actions.update')}
          </Button>
          <ButtonConfirm
            class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
            confirmTitle={t('olkypass.securityPage.webauthnSection.devices.confirm_dialog.header')}
            confirmMessage={t('olkypass.securityPage.webauthnSection.devices.confirm_dialog.body')}
            confirmButtonText={t('olkypass.general.confirm')}
            cancelButtonText={t('olkypass.general.cancel')}
            theme="outlined"
            loading={loading.value}
            onClick={() => {
              changeStatusAction.value = true
            }}
          >
            {t('olkypass.securityPage.webauthnSection.devices.table.actions.remove')}
          </ButtonConfirm>
        </td>
      </tr>
    </>
  )
})

export const NewWebauthnDevice = defineJsxComponent<{ onComplete: () => void }>(
  (props, { attrs }) => {
    const t = useI18n().t
    const { user } = useUser()
    const email = user.user?.naturalPerson?.AliasEmail?.[0]?.Mail
    const toast = useToast()
    const short = computed(() =>
      [
        user.user?.naturalPerson.FirstName?.slice(0, 1),
        user.user?.naturalPerson.LastName?.slice(0, 2) + '.'
      ]
        .filter((a) => a && a !== '.')
        .join('')
        .slice(0, 2)
    )
    const language = useI18n().locale
    const deviceName = ref(
      `${language.value === 'fr' ? `Appareil de` : `Device of`} ${short.value}`
    )

    const loading = ref(false)
    return () => (
      <div>
        <LabelDecoration
          class="my-4"
          label={`* ${t(
            'olkypass.securityPage.webauthnSection.devices.table.columns.device_name'
          )}`}
        >
          {{
            input: (input: any) => (
              <Input
                size="lg"
                required
                placeholder={
                  t('olkypass.securityPage.webauthnSection.devices.placeholder_new_device') +
                  ' ' +
                  short.value
                }
                class={input.class}
                v-model={deviceName.value}
              />
            )
          }}
        </LabelDecoration>

        <Button
          class="mt-4 float-right"
          disabled={!deviceName.value}
          loading={loading.value}
          onClick={async () => {
            loading.value = true
            let userActiveCredentials = []
            try {
              userActiveCredentials = await WebAuthnApi.retrieveWebAuthnActiveCredentials(email)
            } catch (e) {
              console.log('no active credentials')
            }
            try {
              const hasSameDeviceName = userActiveCredentials.some(
                (userActiveCredential) => userActiveCredential['device_name'] === deviceName.value
              )
              if (!hasSameDeviceName) {
                const challenge = await WebAuthnRegisterApi.registerWebAuthnUser(deviceName.value)
                if (challenge) {
                  attrs.onComplete()
                }
              } else {
                toast.error(
                  t('olkypass.securityPage.webauthnSection.devices.notifications.update.same_name')
                )
              }
            } catch (e) {
              toast.error(
                t('olkypass.securityPage.webauthnSection.devices.notifications.add.error')
              )
            } finally {
              loading.value = false
            }
          }}
        >
          {t('olkypass.general.next')}
        </Button>
      </div>
    )
  }
)

export const UserDevices = defineJsxComponent(() => {
  const t = useI18n().t
  const { store, refresh } = useSecurity()
  const toast = useToast()
  const freshDevices = computed(() => store.webAuthnUserDevices)
  const newDeviceModal = ref(false)
  const addNewWebAuthnDeviceAction = ref(false)

  return () => (
    <>
      {addNewWebAuthnDeviceAction.value && (
        <SolidAuthnModalReact
          action="new_webauthn_device"
          onClose={() => (addNewWebAuthnDeviceAction.value = false)}
          onValidated={async () => {
            setTimeout(() => {
              newDeviceModal.value = true
            }, 50)
            addNewWebAuthnDeviceAction.value = false
          }}
        />
      )}
      <ModalVue
        open={newDeviceModal.value}
        onClose={() => {
          newDeviceModal.value = false
        }}
      >
        <ModalContentVue
          title={t('olkypass.securityPage.webauthnSection.devices.table.actions.add')}
        >
          <NewWebauthnDevice
            onComplete={async () => {
              await refresh()
              newDeviceModal.value = false
              toast.success(
                t('olkypass.securityPage.webauthnSection.devices.notifications.add.success')
              )
            }}
          />
        </ModalContentVue>
      </ModalVue>
      <div className="mt-4">
        <SectionTitle>
          {t('olkypass.securityPage.webauthnSection.devices.section_title')}
        </SectionTitle>
        {store.isWebAuthnActive && (
          <Button
            theme="primary"
            class="mt-2"
            onClick={() => {
              addNewWebAuthnDeviceAction.value = true
            }}
          >
            {t('olkypass.securityPage.webauthnSection.devices.table.actions.add')}
          </Button>
        )}
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                {t('olkypass.securityPage.webauthnSection.devices.table.columns.device_name')}
              </th>
              <th scope="col" className="px-6 py-3">
                {t('olkypass.securityPage.webauthnSection.devices.table.columns.activation_date')}
              </th>
              <th scope="col" className="px-6 py-3">
                {t('olkypass.securityPage.webauthnSection.devices.table.columns.domain_name')}
              </th>
              <th scope="col" className="px-6 py-3">
                {t('olkypass.securityPage.webauthnSection.devices.table.columns.is_active')}
              </th>
              <th scope="col" className="px-6 py-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {freshDevices.value
              .slice()
              .sort((a, b) => {
                if (a.id < b.id) return 1
                if (a.id > b.id) return -1
                return 0
              })
              .map((device) => (
                <TrDevice
                  key={device.id + device.active + device.device_name}
                  device={device}
                  onComplete={async () => {
                    await refresh()
                    toast.success(
                      t(
                        'olkypass.securityPage.webauthnSection.devices.notifications.update.success'
                      )
                    )
                  }}
                ></TrDevice>
              ))}
          </tbody>
        </table>
      </div>
    </>
  )
})

export const UpdateWebauthnDevice = defineJsxComponent<{ device: any; onComplete: () => void }>(
  (props, { attrs }) => {
    const t = useI18n().t
    const { user } = useUser()
    const email = user.user?.naturalPerson?.AliasEmail?.[0]?.Mail
    const toast = useToast()
    const short = computed(() =>
      [
        user.user?.naturalPerson.FirstName?.slice(0, 1),
        user.user?.naturalPerson.LastName?.slice(0, 2) + '.'
      ]
        .filter((a) => a && a !== '.')
        .join('')
        .slice(0, 2)
    )

    const deviceName = ref(props.device.device_name)
    const loading = ref(false)
    return () => (
      <div>
        <LabelDecoration
          class="my-4"
          label={`* ${t(
            'olkypass.securityPage.webauthnSection.devices.table.columns.device_name'
          )}`}
        >
          {{
            input: (input: any) => (
              <Input
                size="lg"
                required
                placeholder={
                  t('olkypass.securityPage.webauthnSection.devices.placeholder_new_device') +
                  ' ' +
                  short.value
                }
                class={input.class}
                v-model={deviceName.value}
              />
            )
          }}
        </LabelDecoration>

        <Button
          class="mt-4 float-right"
          disabled={!deviceName.value}
          loading={loading.value}
          onClick={async () => {
            loading.value = true
            let userActiveCredentials = []
            try {
              userActiveCredentials = await WebAuthnApi.retrieveWebAuthnActiveCredentials(email)
            } catch (e) {
              console.log('no active credentials')
            }
            try {
              const hasSameDeviceName = userActiveCredentials.some(
                (userActiveCredential) => userActiveCredential['device_name'] === deviceName.value
              )
              if (!hasSameDeviceName) {
                const challenge = await WebAuthnRegisterApi.updateDeviceName(
                  props.device.id,
                  deviceName.value
                )
                if (challenge) {
                  attrs.onComplete()
                }
              } else {
                toast.error(
                  t('olkypass.securityPage.webauthnSection.devices.notifications.update.same_name')
                )
              }
            } catch (e) {
              console.log(e)
              toast.error(
                t('olkypass.securityPage.webauthnSection.devices.notifications.update.error')
              )
            } finally {
              loading.value = false
            }
          }}
        >
          {t('olkypass.general.next')}
        </Button>
      </div>
    )
  }
)
