import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import i18n from '@/features/utils/i18n.ts'

const uris = {
  _post: {
    last_incomplete: '/kyc_session/findLastIncomplete',
    kyc_state: '/kyc_session/state',
    new: '/kyc_session/new',
    edit: '/kyc_session/edit',
    prepare_token: '/kyc_session/prepare_token',
    prepare_session: '/kyc_session/prepare_session',
    edit_document: '/kyc_session/document/edit'
  }
}
const state = {
  kycSession: { document: { type_id: '', other_name: '', need_manual_validation: false } },
  kyc: {
    level: 0,
    algoreg_kyc_status: '',
    loader: false,
    color: '',
    decision: ''
  },
  kycDocumentSession: {}
}

const getters = {
  kycSession: state => state.kycSession,
  kycState: state => state.kyc,
  kycDocumentSession: state => state.kycDocumentSession
}

const mutations = {
  async PREPARE_KYC_INFO_SESSION(state, response) {
    state.kycSession.sessionId = response.data.session_id
  },
  async PREPARE_KYC_DOCUMENT_SESSION(state, response) {
    state.kycDocumentSession.sessionId = response.data.session_id
  },
  async CHANGE_KYC_MESSAGE_LANGUAGE(state) {
    const level = state.kyc.level
    if (level === 0) {
      // No Kyc session
      state.kyc.algoreg_kyc_status = i18n().global.t(
        'olkypass.accountPage.notifications.warning.kycLevel'
      )
      state.kyc.color = 'error'
      state.kyc.decision = 'no_session'
    } else if (level === 25) {
      // unfinished
      state.kyc.algoreg_kyc_status = i18n().global.t(
        'olkypass.accountPage.notifications.unfinished.kycLevel'
      )
      state.kyc.color = 'warning'
      state.kyc.decision = 'empty'
    } else if (level === 50) {
      // pending
      state.kyc.algoreg_kyc_status = i18n().global.t(
        'olkypass.accountPage.notifications.info.kycLevel'
      )
      state.kyc.color = 'info'
      state.kyc.decision = 'pending'
    } else if (level === 75) {
      // failure
      state.kyc.algoreg_kyc_status = i18n().global.t(
        'olkypass.accountPage.notifications.error.kycLevel'
      )
      state.kyc.color = 'error'
      state.kyc.decision = 'failure'
    } else {
      // positive
      state.kyc.algoreg_kyc_status = i18n().global.t(
        'olkypass.accountPage.notifications.success.kycLevel'
      )
      state.kyc.color = 'success'
      state.kyc.decision = 'success'
    }
  },
  async SET_CURRENT_KYC_STATE(state, response) {
    const level = response.level
    state.kyc.level = level
    this.dispatch('changeKycMessageLanguage')
  },
  async FIND_LAST_INCOMPLETE(state, response) {
    state.kycSession = response.data
  },
  async CREATE_NEW_INFO_SESSION(state, response) {
    state.kycSession.sessionId = response.data.sessionId
    state.kycSession.sessionType = response.data.sessionType
  },
  async CREATE_NEW_DOCUMENT_SESSION(state, response) {
    state.kycDocumentSession = response.data
  },
  async UPDATE_SESSION(state, response) {
    state.kycSession = response.data
  },
  async RESET_KYC_SESSION(state) {
    state.kycSession = { document: { type_id: '', other_name: '', need_manual_validation: false } }
  },
  async UPDATE_DOCUMENT_SESSION(state, response) {
    state.kycDocumentSession = response.data
    this.dispatch('addNotification', {
      type: 'success',
      message: i18n().global.t('olkypass.accountPage.documentsSection.notifications.success.upload')
    })
  }
}

const actions = {
  async prepareKycSession({ commit }, data) {
    try {
      const response = await fetchServer(uris._post.prepare_session, { body: data })

      if (data.sessionType === 'INFO') {
        commit('PREPARE_KYC_INFO_SESSION', response)
      }
      if (data.sessionType === 'DOCUMENT') {
        commit('PREPARE_KYC_DOCUMENT_SESSION', response)
      }
    } catch (error) {
      if (error.response.data.message !== undefined) {
        this.dispatch('addNotification', { type: 'error', message: error.response.data.message })
      } else {
        this.dispatch('addNotification', {
          type: 'error',
          message: i18n().global.t('olkypass.accountPage.notifications.error.profile.load')
        })
      }
    }
  },
  async setCurrentKYCState({ commit }, data) {
    commit('SET_CURRENT_KYC_STATE', data)
  },
  async findLastIncomplete({ commit }, data) {
    const response = await fetchServer(uris._post.last_incomplete, { body: data })
    commit('FIND_LAST_INCOMPLETE', response)
  },
  async createNewSession({ commit }, data) {
    const response = await fetchServer(uris._post.new, { body: data })

    if (data.sessionType === 'INFO') {
      commit('CREATE_NEW_INFO_SESSION', response)
    }
    if (data.sessionType === 'DOCUMENT') {
      commit('CREATE_NEW_DOCUMENT_SESSION', response)
    }
  },
  async updateSession({ commit }, data) {
    const response = await fetchServer(uris._post.edit, { body: data })
    commit('UPDATE_SESSION', response)
  },
  async updateDocumentSession({ commit }, data) {
    try {
      const response = await fetchServer(uris._post.edit_document, { body: data })
      commit('UPDATE_DOCUMENT_SESSION', response)
    } catch (error) {
      this.dispatch('addNotification', {
        type: 'error',
        message: i18n().global.t('olkypass.accountPage.documentsSection.notifications.error.upload')
      })
    }
  },
  async resetKycSession({ commit }) {
    commit('RESET_KYC_SESSION')
  },
  async changeKycMessageLanguage({ commit }) {
    commit('CHANGE_KYC_MESSAGE_LANGUAGE')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
