<template>
  <ion-page id="RecurrentTransferPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          default-href="/pay/supplier/transfer"
          :icon="caretBackSharp"
        ></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1>{{ $t('olkypay.labels.transferPage.one_time_tab.header') }}</h1>
      </ion-title>
    </ion-toolbar>
    <choice-beneficiary @open-next-step="openNextStep"></choice-beneficiary>
  </ion-page>
</template>

<script lang="ts">
import HeaderTabs from '../../../components/HeaderTabs.vue'
import ChoiceBeneficiary from './steps/ChoiceBeneficiary.vue'
import { IonPage, IonBackButton, IonToolbar, IonButtons, IonTitle } from '@ionic/vue'
import { caretBackSharp, alertCircle } from 'ionicons/icons'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RecurrentTransferPage',
  components: {
    IonPage,
    IonBackButton,
    IonToolbar,
    IonButtons,
    IonTitle,
    ChoiceBeneficiary,
    HeaderTabs
  },
  data() {
    return {
      caretBackSharp
    }
  },
  methods: {
    ...mapActions('transfer', ['setCurrentTransfer']),
    openNextStep() {
      this.setCurrentTransfer({
        typeVirement: 'rec',
        title: 'olkypay.labels.transferPage.recurrent'
      })
      this.$router.push({ name: 'ReasonTransferSubTabRouter' })
    }
  },
  mounted() {}
}
</script>
