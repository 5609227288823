<script setup lang="ts">
import type { PropType } from 'vue'

const props = defineProps({
  class: { type: String, default: '' },
  onClick: { type: Function, default: null },
  prefix: {
    type: Object as PropType<null | ((p: { class: string }) => any)>,
    default: null
  },
  suffix: {
    type: Object as PropType<null | ((p: { class: string }) => any)>,
    default: null
  }
})
</script>

<template>
  <div :class="'relative ' + props.class">
    <div
      v-if="props.prefix"
      :class="
        'absolute m-auto top-0 bottom-0 h-full left-0 px-3 flex justify-center items-center ' +
        (props.prefix ? 'cursor-pointer' : '')
      "
      @click="props.onClick"
    >
      <props.prefix class="h-4 w-4 text-slate-500" />
    </div>
    <slot :class="(props.prefix ? 'pl-9 ' : '') + (props.suffix ? 'pr-9 ' : '')"></slot>
    <div
      v-if="props.suffix"
      :class="
        'absolute m-auto top-0 bottom-0 h-full right-0 px-3 flex justify-center items-center ' +
        (props.suffix ? 'cursor-pointer' : '')
      "
      @click="props.onClick"
    >
      <props.suffix class="h-4 w-4 text-slate-500" />
    </div>
  </div>
</template>

<!-- Usage
<IconDecoration
  :prefix="FilterIcon"
  v-slot="decoration"
>
    <Input :class="decoration.class" />
</IconDecoration>
-->
