<template>
  <ion-modal ref="modal" :can-dismiss="canDismiss" :is-open="isOpenDialog" class="okypay-modal">
    <div v-if="user_settings.authentication_method === 'code_pin'" class="wrapper">
      <div class="ion-padding">
        <ion-grid>
          <ion-row>
            <ion-col class="text-center">
              <ion-icon :icon="lockClosed" color="blue_olky" size="large"></ion-icon>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="text-center" size="auto">
              <h2>{{ $t('olkypay.labels.loginPage.connect_via_code_pin.title') }}</h2>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="text-center">
              <ion-note
                >{{ $t('olkypay.labels.loginPage.connect_via_code_pin.sub_title') }}
              </ion-note>
            </ion-col>
          </ion-row>
          <ion-row class="mt-3 rowContainer">
            <ion-col class="colContainer">
              <v-otp-input
                ref="otpInput"
                v-model:value="code_pin"
                :num-inputs="4"
                :should-auto-focus="false"
                input-classes="otp-pin"
                input-type="password"
                separator=""
                @on-complete="handleCheckPin"
              />
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="text-center">
              <button
                class="btn btn-as-link mb-3 mb-md-0 ms-auto text-blue-dark"
                @click="closeModal"
              >
                {{ $t('olkypay.buttons.loginPage.standard.cancel') }}
              </button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
    <div v-else class="wrapper">
      <div v-if="step === 1" class="ion-padding">
        <ion-grid class="mt-4">
          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              <ion-img :src="olkyLogoBlue" alt="olky-logo"></ion-img>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              <h4>{{ $t('olkypay.labels.loginPage.reconnect_to_olky') }}</h4>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-item>
          <ion-input
            v-model="form.username"
            :clear-input="true"
            :label="$t('olkypay.inputs.loginPage.username')"
            label-placement="stacked"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            v-model="form.password"
            :clear-input="true"
            :label="$t('olkypay.inputs.loginPage.password')"
            :type="showPassword ? 'text' : 'password'"
            label-placement="stacked"
          ></ion-input>
          <ion-icon
            slot="end"
            :icon="showPassword ? eye : eyeOff"
            color="medium"
            @click="togglePasswordVisibility()"
          ></ion-icon>
        </ion-item>
        <ion-row class="ion-margin-top">
          <ion-col class="text-start" size="5">
            <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" @click="closeModal">
              {{ $t('olkypay.buttons.loginPage.standard.cancel') }}
            </button>
          </ion-col>
          <ion-col class="text-end">
            <button
              :disabled="form.username === '' || form.password === ''"
              class="btn bg-gradient-info mb-0 ms-2 full-button"
              @click="handleLogin()"
            >
              {{ $t('olkypay.buttons.loginPage.standard.continue') }}
            </button>
          </ion-col>
        </ion-row>
      </div>
      <div v-else class="ion-padding">
        <ion-grid class="mt-4">
          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              <ion-img :src="olkyLogoBlue" alt="olky-logo"></ion-img>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center ion-margin-top">
            <ion-col size="auto">
              <h4>{{ $t('olkypay.labels.twoFaPage.two_fa_code') }}</h4>
            </ion-col>
          </ion-row>
        </ion-grid>
        <CustomOTP @complete-write-otp="completeWriteOTP"></CustomOTP>
        <ion-row class="ion-margin-top">
          <ion-col class="text-start">
            <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" @click="closeModal">
              {{ $t('olkypay.buttons.twoFaPage.standard.cancel') }}
            </button>
          </ion-col>
          <ion-col class="text-end" size="7">
            <button
              :disabled="challenge === ''"
              class="btn bg-gradient-info mb-0 ms-2"
              @click="handleTwoFactory()"
            >
              {{ $t('olkypay.buttons.twoFaPage.standard.continue') }}
            </button>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </ion-modal>
  <info-modal ref="infoModal"></info-modal>
</template>

<script lang="ts">
import VOtpInput from 'vue3-otp-input'
import AuthService from '../../services/auth.service.ts'
import olkyLogoBluePath from '../../../../../public/images/olkypay/olky-logo-blue.png'
import HeaderTabs from '../../components/HeaderTabs.vue'
import LanguageSwitcher from '../../components/LanguageSwitcher.vue'
import CustomOTP from '../../components/CustomOTP.vue'
import InfoModal from '../InfoModal.vue'
import { WebAuthnApi } from '@/features/auth/api-client/webauthn-api-client'
import {
  alertController,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonList,
  IonLoading,
  IonModal,
  IonNote,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRouterOutlet,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  toastController
} from '@ionic/vue'
import { mapActions, mapGetters } from 'vuex'
import { eye, eyeOff, lockClosed } from 'ionicons/icons'
import { Capacitor } from '@capacitor/core'
import { NativeBiometric } from 'capacitor-native-biometric'
import { useToast } from 'vue-toast-notification'

export default {
  name: 'AuthenticationProcessPopup',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonGrid,
    IonRow,
    IonCol,
    IonRadio,
    IonRadioGroup,
    IonInput,
    IonImg,
    IonButton,
    IonRouterOutlet,
    alertController,
    IonLoading,
    IonModal,
    IonNote,
    VOtpInput,
    HeaderTabs,
    LanguageSwitcher,
    CustomOTP,
    InfoModal
  },
  data() {
    return {
      isOpenDialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      form: {
        username: '',
        password: ''
      },
      code_pin: '',
      step: 1,
      challenge: '',
      canDismiss: false,
      lockClosed,
      eye,
      eyeOff,
      showPassword: false
    }
  },
  methods: {
    ...mapActions('loader', ['playLoader', 'stopLoader']),
    ...mapActions('auth', ['setIsInternalReAuthenticatedStatus']),
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    resetCredentialData() {
      this.setIsInternalReAuthenticatedStatus(false)
      this.form.username = this.username
      this.form.password = ''
      this.code_pin = ''
    },
    async authenticate() {
      const toast = useToast()
      this.resetCredentialData()
      if (this.user_settings.authentication_method === 'web_authn') {
        try {
          await WebAuthnApi.authenticateWebAuthnUser(this.username)
          toast.success(this.$i18n.t('olkypay.notifications.success.webAuthn.authenticateUser'))
          return Promise.resolve(true)
        } catch (error) {
          toast.error(this.$i18n.t('olkypay.notifications.error.webAuthn.authenticateUser'))
          return Promise.resolve(false)
        }
      } else if (
        !Capacitor.isNativePlatform() ||
        this.user_settings.authentication_method === 'code_pin'
      ) {
        this.isOpenDialog = true
      } else {
        const result = await NativeBiometric.isAvailable()
        if (!result.isAvailable) {
          this.isOpenDialog = true
        } else {
          const failed_authentication = this.$i18n.t('olkypay.notifications.error.loginPage.header')
          const success_authentication = this.$i18n.t(
            'olkypay.notifications.success.loginPage.header'
          )

          NativeBiometric.verifyIdentity({
            reason: 'For easy log in',
            title: this.$i18n.t('olkypay.labels.loginPage.reconnect_to_olky'),
            subtitle: '',
            description: ''
          })
            .then(async () => {
              const toast = await toastController.create({
                message: success_authentication,
                duration: 5000,
                position: 'bottom',
                color: 'success'
              })
              await toast.present()
              this.proceedToNextStep()
            })
            .catch(async () => {
              const toast = await toastController.create({
                message: failed_authentication,
                duration: 5000,
                position: 'bottom',
                color: 'danger'
              })
              await toast.present()
              this.closeModal()
            })
        }
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    proceedToNextStep() {
      this.canDismiss = true
      this.resolve(true)
      this.reject(false)
      this.isOpenDialog = false
    },
    hidePrincipalModal() {
      this.canDismiss = true
      this.isOpenDialog = false
    },
    ShowPrincipalModal() {
      this.canDismiss = false
      this.isOpenDialog = true
    },
    /*   cancel() {
      this.resolve(false);
    },*/
    closeModal() {
      this.canDismiss = true
      this.resolve(false)
      this.reject(true)
      this.isOpenDialog = false
    },
    async handleLogin() {
      this.hidePrincipalModal()
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.loginPage.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      this.setIsInternalReAuthenticatedStatus(false)
      const response = await AuthService.verifyIdentityCredentials(this.form)
      this.stopLoader()
      if (response) {
        if (response.token) {
          // means 2fa not active and success login
          this.setIsInternalReAuthenticatedStatus(true)
          this.proceedToNextStep()
        } else {
          this.ShowPrincipalModal()
          // means success login and require 2fa
          this.step = 2
        }
      } else {
        this.hidePrincipalModal()
        const failedAuthenticatedModal = {
          type: 'warning',
          title: this.$i18n.t('olkypay.notifications.error.loginPage.header'),
          content: this.$i18n.t('olkypay.notifications.error.loginPage.message'),
          button: this.$i18n.t('olkypay.buttons.loginPage.dialog.understood')
        }
        const response_modal = await this.$refs.infoModal.openModal(failedAuthenticatedModal)
        if (response_modal) {
          this.ShowPrincipalModal()
        }
      }
    },
    async completeWriteOTP(value) {
      this.challenge = value
      await this.handleTwoFactory()
    },
    async handleTwoFactory() {
      this.hidePrincipalModal()
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.twoFaPage.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      this.setIsInternalReAuthenticatedStatus(false)
      const twoFaData = {
        username: this.form.username,
        password: this.form.password,
        challenge: this.challenge
      }
      const response = await AuthService.verifyIdentityTwoFa(twoFaData)
      this.stopLoader()
      if (response) {
        this.setIsInternalReAuthenticatedStatus(true)
        this.proceedToNextStep()
        this.step = 1
        this.form.username = ''
        this.form.password = ''
      } else {
        this.hidePrincipalModal()
        const failedAuthenticatedModal = {
          type: 'warning',
          title: this.$i18n.t('olkypay.notifications.error.twoFaPage.header'),
          content: this.$i18n.t('olkypay.notifications.error.twoFaPage.message'),
          button: this.$i18n.t('olkypay.buttons.loginPage.dialog.understood')
        }
        const response_modal = await this.$refs.infoModal.openModal(failedAuthenticatedModal)
        if (response_modal) {
          this.ShowPrincipalModal()
        }
      }
    },
    async handleCheckPin(value) {
      this.hidePrincipalModal()
      const loaderModal = {
        title: this.$i18n.t('olkypay.loader.loginPage.title'),
        content: this.$i18n.t('olkypay.loader.please_wait')
      }
      this.playLoader(loaderModal)
      this.setIsInternalReAuthenticatedStatus(false)
      AuthService.verifyIdentityViaCodePin(value)
        .then(async res => {
          this.stopLoader()
          this.proceedToNextStep()
        })
        .catch(async (err: any) => {
          this.stopLoader()
          this.hidePrincipalModal()
          const failedAuthenticatedModal = {
            type: 'warning',
            title: this.$i18n.t('olkypay.notifications.error.loginPage.header'),
            content: this.$i18n.t('olkypay.notifications.error.loginPage.message'),
            button: this.$i18n.t('olkypay.buttons.loginPage.dialog.understood')
          }
          const response_modal = await this.$refs.infoModal.openModal(failedAuthenticatedModal)
          if (response_modal) {
            this.code_pin = ''
            this.ShowPrincipalModal()
          }
        })
        .finally(() => {})
    }
  },
  mounted() {},
  computed: {
    ...mapGetters('user', ['username', 'user_settings']),
    olkyLogoBlue() {
      return olkyLogoBluePath
    }
  }
}
</script>
