import { fetchServer } from '@/features/utils/fetch'
import {
  PrepareKYCSessionData as PrepareKYCSessionData,
  KYCDocumentType,
  KYCSessionStatus as KYCSessionState,
  CreateKYCSessionData as CreateKYCSessionData,
  KYCDocument,
  CreateKYCDocument,
  PrepareKYCSessionResponse,
  KYCSession
} from '../types'

export class KYCApiClient {
  static async prepareKYCSession(data: PrepareKYCSessionData): Promise<PrepareKYCSessionResponse> {
    const response = await fetchServer('/kyc_session/prepare_session', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: data
    })

    const responseJSON = await response.json()
    return responseJSON
  }

  static async createKYCSession(data: CreateKYCSessionData): Promise<KYCSession> {
    const response = await fetchServer('/kyc_session/new', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: data
    })

    return response.json()
  }

  static async getKYCSessionState(data: { UUID: string }): Promise<KYCSessionState> {
    const response = await fetchServer('/kyc_session/state', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: data
    })

    return response.json()
  }

  static async editKYCSession(data: CreateKYCDocument) {
    const response = await fetchServer('/kyc_session/edit', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: data
    })
    return response.json()
  }

  static async updateDocument(data: CreateKYCDocument) {
    const response = await fetchServer('/kyc_session/document/edit', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: data
    })
    return response.json()
  }

  static async getKYCDocuments(UUID: string): Promise<KYCDocument[]> {
    const response = await fetchServer('/document/list', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: { UUID }
    })

    if (!response.ok) throw new Error('Error')

    const res = await response.json()
    return res
  }

  static async getKYCDocumentTypes(): Promise<KYCDocumentType[]> {
    const response = await fetchServer('/document/typesByCateg/2', {
      method: 'GET',
      reloadOn401: false,
      mainRoot: false
    })

    if (!response.ok) throw new Error('Error')

    const res = await response.json()
    return res
  }
  static async updateQuizVideoQuestion(data: CreateKYCDocument) {
    const response = await fetchServer('/kyc_session/quiz/edit', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: data
    })
    if (!response.ok) throw new Error('Error')
    const res = await response.json()
    return res
  }
}
