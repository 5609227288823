import { Button } from '@/atoms-react/button/Button.react'
import { useKypay } from '@/features/kypay/hooks/use-kypay'
import { formatNumber } from '@/features/utils/functions'
import { Themes } from '@/types/theme'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalAddWallet } from '../modal-add-wallet/index.react'
import { ModalFeedBank } from '../modal-feed-kypay-bank/index.react'
import { ModalCryptoToBank } from '../modal-feed-kypay-crypto/index.react'
import { ModalBankToCrypto } from '../modal-transfer-funds/index.react'
import { ModalWithdrawBank } from '../modal-withdraw-kypay-bank/index.react'
import { BalanceCard } from './balance-card.react'
import { ModalKypayOnChainExplanation } from '../modal-kypay-onchain-explanation/index.react'
import { Kypay, KypayAccount, WalletStatus } from '@/features/kypay/types.ts'
import { ModalTransferKypay } from '@/app/olkypass/views/kypay/components/modal-transfer-kypay/index.react.tsx'
import { Base } from '@/atoms-react/text/Text.react'
import { ModalAuthenticationRequireHelper } from '@/app/olkypass/views/kypay/components/modal-authentication-require/index.react.tsx'
import { SecurityApiClient } from '@/features/security/api-client/api-client.ts'
import { WebAuthnRegisterApi } from '@/features/auth/api-client/webauthn-register-api-client.ts'
import toast from 'react-hot-toast'

export const BalanceCardKypay = ({
  withButton,
  withWalletStatus,
  balanceType,
  allowedToKypay = true,
  isPrincipalAccount = true,
  className,
  kypay = null,
  isKypayLoading = false,
  refetchKypay,
  currentAccount
}: {
  withButton?: boolean
  withWalletStatus?: boolean
  balanceType: 'euros' | 'onChain'
  allowedToKypay?: boolean
  isPrincipalAccount?: boolean
  className?: string
  kypay?: Kypay
  isKypayLoading?: boolean
  refetchKypay?: () => void
  currentAccount?: KypayAccount
}) => {
  const { t } = useTranslation(['wallet'])
  const {
    userWalletQuery,
    getUserWalletBalanceQuery,
    sendKypayToUserWalletMutation,
    useUserWalletStatusMutation
  } = useKypay()
  // const { data: kypay, isLoading: isKypayLoading, refetch: refetchKypay } = kypayQuery()
  const { data: wallet, isLoading: isWalletLoading, refetch: refetchWallet } = userWalletQuery()
  const { mutateAsync: fetchUserStatus } = useUserWalletStatusMutation()
  const [walletStatus, setWalletStatus] = useState<WalletStatus | null>(null)
  const [walletStatusError, setWalletStatusError] = useState<boolean | null>(false)

  const {
    data: userBalance,
    isLoading: isLoadingUserBalance,
    refetch: refetchUserBalance
  } = getUserWalletBalanceQuery(wallet?.wallet_address ?? '', { enabled: !!wallet })

  const handleGetWalletStatus = async () => {
    if (
      (wallet && !wallet?.status) ||
      (wallet && wallet?.status === null) ||
      (wallet &&
        wallet?.status !== 'Qualified' &&
        wallet?.status !== 'TokenHolder' &&
        withWalletStatus)
    ) {
      try {
        const data = await fetchUserStatus()
        setWalletStatus(data)
        if ((data as any).success && (data as any)?.success === false) {
          toast.error(t('kypay.balance_card.wallet_validation_failed'))
          setWalletStatusError(true)
        }
        if (data.status && data.status === 'Failed')
          toast.error(t('kypay.balance_card.wallet_validation_failed'))
      } catch (error) {
        toast.error(t('kypay.balance_card.wallet_validation_failed'))
      }
    }
  }
  useEffect(() => {
    if (balanceType === 'onChain') {
      handleGetWalletStatus()
    }
  }, [wallet])

  const { mutateAsync: sendKypayToUserWallet, isPending: isPendingSendKypayToUserWallet } =
    sendKypayToUserWalletMutation()
  const [modals, setModals] = useState({
    addWallet: false,
    feedBank: false,
    withdrawBank: false,
    cryptoToBank: false,
    bankToCrypto: false,
    kypayOnChainDetails: false,
    transfer: false,
    authenticationRequireHelper: false
  })
  const [hasOneTOTP, setHasOneTOTP] = useState<boolean>(false)
  const amountValue =
    balanceType === 'euros'
      ? allowedToKypay
        ? formatNumber((kypay?.balance ?? 0) / 100)
        : formatNumber(0)
      : allowedToKypay
        ? userBalance && !isNaN(userBalance?.balanceEther)
          ? formatNumber(userBalance?.balanceEther)
          : 0 ?? null
        : 0
  const checkTOTP = async () => {
    const is2faActive = await SecurityApiClient.is2faActive()
    const isWebAuthnActive = (await WebAuthnRegisterApi.checkActivation())?.isWebAuthnActivated
    setHasOneTOTP(is2faActive || isWebAuthnActive)
  }
  useEffect(() => {
    if (withButton) {
      checkTOTP()
    }
  }, [])
  return (
    <>
      <BalanceCard
        disabled={!allowedToKypay}
        enableRefresh={true}
        refetchKypay={refetchKypay}
        handleGetWalletStatus={handleGetWalletStatus}
        animate={isKypayLoading}
        label={
          balanceType === 'euros'
            ? t('kypay.balance_card.off_chain_balance')
            : t('kypay.balance_card.on_chain_balance')
        }
        unit={balanceType === 'euros' ? '€' : 'KYPAY'}
        unitProps={{ className: balanceType === 'euros' ? 'text-3xl' : 'text-xl' }}
        color={balanceType === 'euros' ? 'text-blue-500' : ''}
        amount={amountValue}
        loading={
          balanceType === 'euros'
            ? allowedToKypay && (isKypayLoading || isPendingSendKypayToUserWallet)
            : allowedToKypay && (isLoadingUserBalance || isPendingSendKypayToUserWallet)
        }
        roundTo={balanceType === 'onChain' ? 0 : undefined}
        withButton={withButton}
        className={className}
        detailsModalIcon={balanceType === 'onChain'}
        onClickIcon={() => setModals((data) => ({ ...data, kypayOnChainDetails: true }))}
        walletStatusChildren={() => (
          <>
            {withWalletStatus && (
              <div>
                {wallet && balanceType === 'onChain'
                  ? wallet &&
                    walletStatusError === false &&
                    wallet?.status !== 'Qualified' &&
                    wallet?.status !== 'TokenHolder' &&
                    (!walletStatus?.status || walletStatus?.status === 'InProgress') && (
                      <Base className="text-primary">
                        {' '}
                        {t('kypay.balance_card.wallet_validation')}
                      </Base>
                    )
                  : ''}
              </div>
            )}
          </>
        )}
      >
        {withButton &&
          (balanceType === 'euros' ? (
            <div className="mb-2 mt-2 md:flex md:mt-0 gap-2">
              <Button
                size="md"
                theme={Themes.white}
                onClick={() => setModals((data) => ({ ...data, feedBank: true }))}
                disabled={!allowedToKypay || isWalletLoading || isKypayLoading}
                className={'mt-2 md:mt-4 w-max'}
              >
                {t('kypay.button.feed')}
              </Button>
              {isPrincipalAccount && (
                <>
                  <Button
                    size="md"
                    theme={Themes.white}
                    onClick={() => {
                      if (hasOneTOTP) {
                        setModals((data) => ({ ...data, withdrawBank: true }))
                      } else {
                        setModals((data) => ({ ...data, authenticationRequireHelper: true }))
                      }
                    }}
                    disabled={!allowedToKypay || isWalletLoading || isKypayLoading}
                    className={'mt-2 md:mt-4 w-max'}
                  >
                    {t('kypay.button.withdraw')}
                  </Button>
                  <Button
                    size="md"
                    theme={Themes.white}
                    onClick={() => {
                      if (hasOneTOTP) {
                        setModals((data) => ({ ...data, transfer: true }))
                      } else {
                        setModals((data) => ({ ...data, authenticationRequireHelper: true }))
                      }
                    }}
                    disabled={!allowedToKypay || isWalletLoading || isKypayLoading}
                    className={'mt-2 md:mt-4 w-max'}
                  >
                    {t('kypay.button.transfer')}
                  </Button>
                </>
              )}
            </div>
          ) : (
            <>
              {userBalance && !isLoadingUserBalance && (
                <div className={'flex mb-2 mt-2 md:mt-0'}>
                  <Button
                    size="md"
                    theme={Themes.white}
                    onClick={() => setModals((data) => ({ ...data, cryptoToBank: true }))}
                    disabled={!allowedToKypay || isWalletLoading || isKypayLoading}
                    className={'w-max mt-4'}
                  >
                    {t('kypay.button.transfer_off_chain')}
                  </Button>
                  {isPrincipalAccount && (
                    <Button
                      size="md"
                      onClick={() => setModals((data) => ({ ...data, bankToCrypto: true }))}
                      disabled={
                        !allowedToKypay ||
                        isWalletLoading ||
                        isKypayLoading ||
                        (wallet &&
                          wallet?.status !== 'Qualified' &&
                          wallet?.status !== 'TokenHolder' &&
                          (!walletStatus?.status ||
                            walletStatus?.status === 'Failed' ||
                            walletStatus?.status === 'InProgress'))
                      }
                      className={'w-max shadow-none mt-4 ml-2'}
                    >
                      {t('kypay.button.transfer_on_chain')}
                    </Button>
                  )}
                </div>
              )}
              {!userBalance && !isLoadingUserBalance && (
                <div className="flex mb-2 mt-2 md:flex md:mt-0 gap-2">
                  <Button
                    size="md"
                    theme={Themes.white}
                    onClick={() => {
                      if (hasOneTOTP) {
                        setModals((data) => ({ ...data, addWallet: true }))
                      } else {
                        setModals((data) => ({ ...data, authenticationRequireHelper: true }))
                      }
                    }}
                    disabled={!allowedToKypay || isWalletLoading || isKypayLoading}
                    className={'w-max'}
                  >
                    {t('kypay.button.add_user_wallet')}
                  </Button>
                </div>
              )}
            </>
          ))}
      </BalanceCard>
      <ModalAddWallet
        open={modals.addWallet}
        onClose={() => setModals((data) => ({ ...data, addWallet: false }))}
        onValidated={async () => {
          refetchWallet()
        }}
      />
      <ModalCryptoToBank
        open={modals.cryptoToBank}
        onClose={() => setModals((data) => ({ ...data, cryptoToBank: false }))}
        wallet={wallet?.wallet_address}
        onSetWallet={() => setModals((data) => ({ ...data, addWallet: true }))}
      />
      <ModalFeedBank
        open={modals.feedBank}
        onClose={() => setModals((data) => ({ ...data, feedBank: false }))}
        currentAccount={currentAccount}
      />
      <ModalWithdrawBank
        open={modals.withdrawBank}
        onClose={() => setModals((data) => ({ ...data, withdrawBank: false }))}
      />
      <ModalBankToCrypto
        wallet={wallet?.wallet_address ?? null}
        open={modals.bankToCrypto}
        onClose={() => setModals((data) => ({ ...data, bankToCrypto: false }))}
        onValidated={async (amount) => {
          setModals((data) => ({ ...data, bankToCrypto: false }))
          await sendKypayToUserWallet({ amount: amount * 100 })
          refetchKypay()
          refetchUserBalance()
        }}
        onSetWallet={() => setModals((data) => ({ ...data, addWallet: true }))}
      />
      <ModalKypayOnChainExplanation
        open={modals.kypayOnChainDetails}
        onClose={() => setModals((data) => ({ ...data, kypayOnChainDetails: false }))}
      />
      <ModalTransferKypay
        open={modals.transfer}
        onClose={() => {
          setModals((data) => ({ ...data, transfer: false }))
          refetchKypay()
        }}
      />

      <ModalAuthenticationRequireHelper
        open={modals.authenticationRequireHelper}
        onClose={() => setModals((data) => ({ ...data, authenticationRequireHelper: false }))}
      />
    </>
  )
}
