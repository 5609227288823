import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toast-notification'
import { CompanyApiClient } from '../api-client/api-client'
import { CompanyStore } from '../store'
import type { CompanyDTO } from '../types'

export const useCompany = () => {
  const { t } = useI18n()
  const toast = useToast()
  const companyStore = CompanyStore()

  /**
   *
   * @param companyModel
   * @param addressMode
   */
  const newCompany = async (companyModel: any) => {
    companyStore.setLoading(true)
    const { success, data } = await CompanyApiClient.createCompany(companyModel.value)
    if (success && data) {
      toast.success(t('olkypass.companyPage.notifications.success.create_new_company'))
      return true
    } else {
      toast.error(t('olkypass.companyPage.notifications.error.create_new_company'))
    }
    companyStore.setLoading(false)
    return false
  }
  /**
   *
   * @param companyModel
   */
  const updateCompany = async (companyModel: CompanyDTO) => {
    companyStore.setLoading(true)
    const { success, data } = await CompanyApiClient.editCompany(companyModel)
    if (success && data) {
      toast.success(t('olkypass.companyPage.notifications.success.update_company'))
      return true
    } else {
      toast.error(t('olkypass.companyPage.notifications.error.update_company'))
    }
    companyStore.setLoading(false)
    return false
  }
  /**
   *
   */
  const loadCompanies = async () => {
    companyStore.setLoading(true)
    const { success, data } = await CompanyApiClient.fetchListOfCompanies()
    if (success && data) {
      companyStore.set(data)
    }
    companyStore.setLoading(false)
  }
  /**
   *
   * @param identity_uuid
   */
  const loadCurrentCompany = async (identity_uuid: string | string[]) => {
    companyStore.setLoading(true)
    const { success, data } = await CompanyApiClient.fetchCompanyDetails(identity_uuid)
    if (success && data) {
      companyStore.setCurrentCompany(data)
    }
    companyStore.setLoading(false)
  }
  /**
   *
   * @param company_identity_uuid
   * @param collaborator_identity_uuid
   */
  const associateCollaborator = async (
    company_identity_uuid: string,
    collaborator_identity_uuid: string
  ) => {
    const { success, data } = await CompanyApiClient.associateNewCollaborator(
      company_identity_uuid,
      collaborator_identity_uuid
    )
    if (success && data) {
      toast.success(t('olkypass.companyPage.notifications.success.update_company'))
      return true
    } else {
      toast.error(t('olkypass.companyPage.notifications.error.update_company'))
    }
    return false
  }
  const disassociateCollaborator = async (uuid: string) => {
    const { success, data } = await CompanyApiClient.disassociateCollaborator(uuid)
    if (success && data) {
      toast.success(t('olkypass.companyPage.notifications.success.update_company'))
      return true
    } else {
      toast.error(t('olkypass.companyPage.notifications.error.update_company'))
    }
    return false
  }

  return {
    companyStore,
    newCompany,
    updateCompany,
    loadCompanies,
    loadCurrentCompany,
    associateCollaborator,
    disassociateCollaborator
  }
}
