<template>
  <ion-page id="CUBeneficiaryPage">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          default-href="/pay/supplier/transfer"
          :icon="caretBackSharp"
        ></ion-back-button>
      </ion-buttons>
      <ion-title>
        <h1 v-if="currentBeneficiary.id">
          {{ $t('olkypay.labels.beneficiariesPage.edit.header') }}
        </h1>
        <h1 v-else>{{ $t('olkypay.labels.beneficiariesPage.new.header') }}</h1>
      </ion-title>
    </ion-toolbar>
    <ion-content :fullscreen="true">
      <beneficiary-form></beneficiary-form>
      <ion-fab v-if="hasFilledRequiredFields" slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button color="blue_olky" @click="gotToRouter('BeneficiaryIbanCheckSubTabRouter')">
          <ion-icon :icon="chevronForwardCircle"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import BeneficiaryForm from '../../../components/beneficiaries/beneficiaryForm.vue'
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButton,
  IonBackButton,
  IonButtons,
  IonFab,
  IonFabButton,
  IonIcon
} from '@ionic/vue'
import { caretBackSharp, chevronForwardCircle } from 'ionicons/icons'
import { mapGetters } from 'vuex'
export default {
  name: 'CUBeneficiaryPage',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButton,
    IonBackButton,
    IonButtons,
    IonFab,
    IonFabButton,
    IonIcon,
    BeneficiaryForm
  },
  data() {
    return {
      caretBackSharp,
      chevronForwardCircle
    }
  },
  methods: {
    gotToRouter(router_name) {
      this.$router.push({ name: router_name })
    }
  },
  computed: {
    ...mapGetters('beneficiary', ['currentBeneficiary']),
    hasFilledRequiredFields() {
      if (this.currentBeneficiary.isCompany) {
        return this.currentBeneficiary.fullName &&
        this.currentBeneficiary.address1 &&
        this.currentBeneficiary.zip &&
        this.currentBeneficiary.city &&
        this.currentBeneficiary.country
      } else {
        return this.currentBeneficiary.lastName &&
        this.currentBeneficiary.firstName &&
        this.currentBeneficiary.address1 &&
        this.currentBeneficiary.zip &&
        this.currentBeneficiary.city &&
        this.currentBeneficiary.country
      }
      return false
    }
  }
}
</script>

<style scoped></style>
