<template>
  <v-col cols="12" sm="12" md="6" lg="4">
    <v-card class="mx-auto elementCard">
      <template #prepend>
        <v-icon color="white">mdi-phone</v-icon>
      </template>
      <template #title> {{ mobile.MobileIndicatif }} {{ mobile.MobileNumber }} </template>
      <v-card-text class="elementData">
        <v-row>
          <v-col>
            <v-switch
              v-model="mobile.Actif"
              color="secondary"
              :label="$t('olkypass.accountPage.ibans_section._switch.active')"
              hide-details
              @change="savePartialData"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          density="comfortable"
          size="small"
          color="surface-variant"
          variant="tonal"
          icon="mdi-delete"
          :disabled="!checkDisabled"
          @click="deleteCurrent"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
  <DialogConfirm ref="dialogConfirm"></DialogConfirm>
</template>

<script>
import ToolsMixins from '../../mixins/ToolsMixins.js'
import { mapGetters } from '../../store'
import store from '../../store'
import { fetchServer } from '@/app/olkypass/views/_deprecated/fetch.ts'
import DialogConfirm from '../DialogConfirm.vue'

export default {
  name: 'MobileChild',
  components: {
    DialogConfirm
  },
  mixins: [ToolsMixins],
  props: ['mobile', 'inMemoryMobile'],
  methods: {
    async deleteCurrent() {
      const setDeleteDataFunctionName =
        this.currentTreatedEntity === 'company' ? 'delCompanyAliasData' : 'delNPAliasData'
      if (
        await this.$refs.dialogConfirm.open(
          'accountPage.dialog.confirm.delete.header',
          'accountPage.dialog.confirm.delete.body'
        )
      ) {
        store.dispatch('playLoader')
        await store.dispatch(setDeleteDataFunctionName, {
          UUID: this.naturalPerson.UUID,
          ItemUUID: this.mobile.UUID,
          ItemType: 'AliasMobileDto'
        })
        if (this.currentTreatedEntity === 'company') {
          await store.dispatch('setCurrentCompany', { UUID: this.naturalPerson.UUID })
        } else {
          await store.dispatch('setCurrentNaturalPerson')
        }
        store.dispatch('stopLoader')
      }
    },
    async savePartialData() {
      if (!this.mobile.Actif) {
        this.mobile.Public = false
      }
      if (!this.mobile.Owner) {
        this.mobile.Owner = this.naturalPerson.UUID
      }
      const setPartialDataFunctionName =
        this.currentTreatedEntity === 'company' ? 'setCompanyPartialData' : 'setNPPartialData'
      /*if (await this.$refs.dialogConfirm.open('accountPage.dialog.confirm.update.infos.header', 'accountPage.dialog.confirm.update.infos.body')) {*/
      store.dispatch('playLoader')
      await store.dispatch(setPartialDataFunctionName, {
        typeDto: 'AliasMobileDto',
        UUID: this.naturalPerson.UUID,
        AliasMobile: this.mobile
      })
      if (this.currentTreatedEntity === 'company') {
        await store.dispatch('setCurrentCompany', { UUID: this.naturalPerson.UUID })
      } else {
        await store.dispatch('setCurrentNaturalPerson')
      }
      store.dispatch('stopLoader')
      /*}*/
    }
  },
  computed: {
    ...mapGetters(['naturalPerson']),
    checkDisabled() {
      return !this.mobile.Actif && !this.mobile.Public
    }
  }
}
</script>

<style scoped></style>
