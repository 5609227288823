import { fetchServer } from '@/features/utils/fetch'
import { Kycoin, KycoinAccount, KycoinDTOServer } from '../types'
import { KycoinConverters } from '../converters'

export class KycoinApiClient {
  static async getKycoin(account_id): Promise<Kycoin> {
    const response = await fetchServer('/user/kycoin', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: {
        account_id
      }
    })

    const data = await response.json() as KycoinDTOServer
    return KycoinConverters.DTOServer_entity(data)
  }
  static async getKycoinAccounts(): Promise<KycoinAccount[]> {
    const response = await fetchServer('/kycoin/my_accounts', {
      method: 'GET',
      reloadOn401: false,
      mainRoot: false
    })

    
    return await response.json() as KycoinAccount[]
  }

  
}
