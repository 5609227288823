import { useCallback, useEffect, useState } from 'react'
import { WelcomeStep } from './components/welcome-step.react'
import { DocumentTypeStep } from './components/document-type-step.react'
import { EditKYCSession, KYCDocumentType } from '@/features/kyc/types'
import { IdentitySession } from './components/identity-session.react'
import { useAuth } from '@/features/auth/hooks/use-auth-react'
import { IdentificationSuccessful } from './components/identification-successful.react'
import { IdentificationFailed } from './components/identification-failed.react'
import { IdentificationPending } from './components/identification-pending.react'
import { useKYC } from '@/features/kyc/hooks/use-kyc'
import { useKYCContext } from '@/features/auth/hooks/use-kyc-context'
import Loader from '@/atoms-react/loader/Loader.react'

enum KYCIdentityStep {
  Welcome,
  SelectDocumentType,
  IdentitySession,
  Success,
  Fail,
  Pending
}

type KYCIdentityProps = {
  onValidated?: () => void
}

export const KYCIdentity = ({ onValidated }: KYCIdentityProps) => {
  const [step, setStep] = useState<KYCIdentityStep>(KYCIdentityStep.Welcome)
  const [documentType, setDocumentType] = useState<KYCDocumentType>()
  const { user } = useAuth()
  const { editKYCSessionMutation } = useKYC()
  const { mutateAsync: editSession } = editKYCSessionMutation()
  const { refetchKYCContext, status, nextStatus, auditPending } = useKYCContext()
  const [sessionEnded, setSessionEnded] = useState<boolean>(false)
  const onIdentitySessionEnd = useCallback(
    async (data: EditKYCSession) => {
      // update the session with the informations from the iframe message
      await editSession(data)
      await refetchKYCContext()
      setSessionEnded(true)
      sessionStorage.removeItem('DocumentKsyc')
    },
    [refetchKYCContext, editSession]
  )

  const saveOnCache = () => {
    const data = {
      steps: step,
      documentType: documentType
    }
    sessionStorage.setItem('DocumentKsyc', JSON.stringify(data))
  }
  useEffect(() => {
    if (step !== KYCIdentityStep.IdentitySession) return
    if (!nextStatus && !auditPending) {
      setStep(KYCIdentityStep.Fail)
    } else if (auditPending && nextStatus) {
      setStep(KYCIdentityStep.Pending)
    } else if (nextStatus === status) {
      setStep(KYCIdentityStep.Success)
    }
  }, [nextStatus, status, sessionEnded, auditPending])

  useEffect(() => {
    if (sessionStorage.getItem('DocumentKsyc') && step === KYCIdentityStep.Welcome) return
    saveOnCache()
  }, [step, documentType])

  useEffect(() => {
    if (sessionStorage.getItem('DocumentKsyc')) {
      const data = JSON.parse(sessionStorage.getItem('DocumentKsyc') as string)
      setStep(data.steps)
      setDocumentType(data.documentType)
    }
  }, [])
  return (
    <div className={'h-full opacity-1 flex min-h-[30rem] '}>
      <div className={'w-full m-3 sm:m-0 flex-1 flex flex-col gap-10 justify-end'}>
        {step === KYCIdentityStep.Welcome && (
          <WelcomeStep onValidated={() => setStep(KYCIdentityStep.SelectDocumentType)} />
        )}
        {step === KYCIdentityStep.SelectDocumentType && (
          <DocumentTypeStep
            onPrevious={() => setStep(KYCIdentityStep.Welcome)}
            onValidated={(documentType: KYCDocumentType) => {
              setDocumentType(documentType)
              setStep(KYCIdentityStep.IdentitySession)
              saveOnCache()
            }}
          />
        )}
        {step === KYCIdentityStep.IdentitySession && sessionEnded === false && (
          <IdentitySession
            user={user?.data}
            documentType={documentType}
            onValidated={onIdentitySessionEnd}
          />
        )}
        {step === KYCIdentityStep.IdentitySession && sessionEnded === true && (
          <div className="flex flex-1 items-center justify-center">
            <Loader className="text-gray-500" />
          </div>
        )}
        {step === KYCIdentityStep.Success && <IdentificationSuccessful onValidated={onValidated} />}
        {step === KYCIdentityStep.Fail && <IdentificationFailed onValidated={onValidated} />}
        {step === KYCIdentityStep.Pending && <IdentificationPending onValidated={onValidated} />}
      </div>
    </div>
  )
}
