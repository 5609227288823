import { Routes } from '@/features/router'
import { createLoaderStore } from '@/features/utils/loader'
import { RegisterStore } from '../store'
import { useRouter } from 'vue-router'
import { RegisterApiClient } from '../api-client/api-client'
import { useI18n } from 'vue-i18n'
import { useReCaptcha, type IReCaptchaComposition } from 'vue-recaptcha-v3'
import type { RegisterCredentialType } from '../types'
import { useToast } from 'vue-toast-notification'
import { useLogin as useAuth } from '@/features/auth/hooks/use-login-vue'
import { AuthApiClient } from '@/features/auth/api-client/api-client'

const Loader = createLoaderStore('user')
const InitialReady = createLoaderStore('user-ready', true)

export const useRegister = () => {
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha() as IReCaptchaComposition
  const { locale, t } = useI18n()
  const { login } = useAuth()
  const loader = Loader()
  const initialLoading = InitialReady()
  const router = useRouter()
  const registerStore = RegisterStore()
  const toast = useToast()

  // Register the user using credentials
  const register = async (credentials: RegisterCredentialType) => {
    loader.set(true)

    const { existStatus } = await AuthApiClient.checkExistingEmail(credentials.email ?? '')
    if (!existStatus) {
      await recaptchaLoaded()
      credentials.captcha_token = await executeRecaptcha('login')
      credentials.language = locale.value

      const { success, data, message } = await RegisterApiClient.checkEmail(credentials)
      if (success) {
        registerStore.setCredentials(credentials)
        registerStore.setEncryptedData(data.cypher)
        await validationCode(true)
        loader.set(false)
        return true
      } else {
        toast.error(message)
      }
    } else {
      toast.error(t('login.loginPage.errors.alreadyExistingMail'))
    }
    loader.set(false)
    return false
  }

  // Validation of code
  const validationCode = async (
    validation_code_required?: boolean,
    encryptedData?: string,
    code?: string,
    credentials?: RegisterCredentialType
  ) => {
    if (validation_code_required) {
      const path = router.currentRoute.value.fullPath
      router.push(path.includes(Routes.validation_code) ? path : Routes.validation_code)
    } else {
      const attempts = registerStore.getAttempts()
      if (attempts > 2) {
        registerStore.setAttempts(0)
        toast.error(t('login.emailCodePage.attempts'))
        const path = router.currentRoute.value.fullPath
        router.push(path.includes(Routes.signup) ? path : Routes.signup)
      } else {
        loader.set(true)
        const { success } = await RegisterApiClient.validateEmail(
          encryptedData!,
          code!,
          credentials!
        )
        if (success) {
          await completeCredential(true)
          loader.set(false)
          return true
        } else {
          registerStore.setAttempts(attempts + 1)
          toast.error(t('login.emailCodePage.error'))
        }
        loader.set(false)
        return false
      }
    }
  }

  // Complete credential to register
  const completeCredential = async (
    complete_credential_required?: boolean,
    credentials?: RegisterCredentialType
  ) => {
    if (complete_credential_required) {
      const path = router.currentRoute.value.fullPath
      router.push(path.includes(Routes.complete_credential) ? path : Routes.complete_credential)
    } else if (credentials) {
      credentials.confirm_password = credentials?.password

      loader.set(true)
      const { success } = await RegisterApiClient.completeCredential(credentials!)
      if (success) {
        try {
          // Log the user in
          if (!(await login(credentials!.email!, credentials!.password!, true))) {
            throw new Error('Login failed')
          }
        } catch (e) {
          router.push(Routes.login)
        }
        loader.set(false)
        return true
      } else {
        toast.error(t('login.emailCodePage.error'))
      }
      loader.set(false)
      return false
    }
  }

  return { loader, initialLoading, register, validationCode, registerStore, completeCredential }
}
