import { Base, CardTitle } from '@/atoms-react/text/Text.react'
import { AnimatedShield } from '@/components/animations/shield/index.react'
import { KYCStatusType } from '@/features/kyc/types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export type OlkypassCardProps = {
  CTA?: boolean
  currentStatus: KYCStatusType
  verifyButton?: boolean
  small?: boolean
  nextStatus?: KYCStatusType | null
  dashboard?: boolean
  auditPending: boolean
}

import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { useMemo, useState } from 'react'
import { ModalOlkyPass } from '@/molecules/modal-olkypass/index.react'
import { Button } from '@/atoms-react/button/Button.react'
import { Themes } from '@/types/theme'
import { Shield } from '@/atoms/shield/index.react'
import { ModalKYCIdentity } from '@/molecules/modal-kyc-identity/index.react'
import { useLocale } from '@/features/utils/hooks/use-locale'

export const OlkypassCard = ({
  currentStatus,
  nextStatus,
  CTA = true,
  dashboard = false,
  auditPending
}: OlkypassCardProps) => {
  //const { useUserProfile } = useUser()
  //const { data: user } = useUserProfile()
  const [modalIdentity, setModalIdentity] = useState<boolean>(false)

  const { t } = useTranslation(['pass'])
  const navigate = useNavigate()
  // Use States et variables for the modal
  const modalOlkyPassAllSteps = [
    {
      id: 0,
      title: t('olkypass.mainPage.olkypass.modalOlkyPass.status_declarative'),
      description: t('olkypass.mainPage.olkypass.modalOlkyPass.declarative_description'),
      imageSource: '/images/olkypass/status_declarative.png',
      imageSourceInter: '/images/olkypass/status_declarative_inter.png',
      imageSourceEn: '/images/olkypass/status_declarativeEn.png'
    },
    {
      id: 1,
      title: t('olkypass.mainPage.olkypass.modalOlkyPass.status_verified'),
      description: t('olkypass.mainPage.olkypass.modalOlkyPass.verified_description'),
      imageSource: '/images/olkypass/status_verified.png',
      imageSourceInter: '/images/olkypass/status_verified_inter.png',
      imageSourceEn: '/images/olkypass/status_verifiedEn.png',
      imageSourceInterEn: '/images/olkypass/status_verified_interEn.png'
    },
    {
      id: 2,
      title: t('olkypass.mainPage.olkypass.modalOlkyPass.status_authenticated'),
      description: t('olkypass.mainPage.olkypass.modalOlkyPass.authenticated_description'),
      imageSource: '/images/olkypass/status_authenticated.png',
      imageSourceInter: '/images/olkypass/status_authenticated_inter.png',
      imageSourceEn: '/images/olkypass/status_authenticatedEn.png',
      imageSourceInterEn: '/images/olkypass/status_authenticated_interEn.png'
    },

    {
      id: 3,
      title: t('olkypass.mainPage.olkypass.modalOlkyPass.status_authenticated_bronz'),
      description: t('olkypass.mainPage.olkypass.modalOlkyPass.authenticated_bronz_description'),
      imageSource: '/images/olkypass/status_authenticated_bronze.png',
      imageSourceInter: '/images/olkypass/status_authenticated_bronze_inter.png',
      imageSourceEn: '/images/olkypass/status_authenticated_bronzeEn.png',
      imageSourceInterEn: '/images/olkypass/status_authenticated_bronze_interEn.png'
    },
    {
      id: 4,
      title: t('olkypass.mainPage.olkypass.modalOlkyPass.status_authenticated_silver'),
      description: t('olkypass.mainPage.olkypass.modalOlkyPass.authenticated_silver_description'),
      imageSource: '/images/olkypass/status_authenticated_silver.png',
      imageSourceInter: '/images/olkypass/status_authenticated_silver_inter.png',
      imageSourceEn: '/images/olkypass/status_authenticated_silverEn.png',
      imageSourceInterEn: '/images/olkypass/status_authenticated_silver_interEn.png'
    },
    {
      id: 5,
      title: t('olkypass.mainPage.olkypass.modalOlkyPass.status_authenticated_gold'),
      description: t('olkypass.mainPage.olkypass.modalOlkyPass.authenticated_gold_description'),
      imageSource: '/images/olkypass/status_authenticated_Gold.png',
      imageSourceInter: '/images/olkypass/status_authenticated_gold_inter.png',
      imageSourceEn: '/images/olkypass/status_authenticated_GoldEn.png',
      imageSourceInterEn: '/images/olkypass/status_authenticated_gold_interEn.png'
    }
  ]
  const [isOlkyPassModalOpen, setIsOlkyPassModalOpen] = useState<boolean>(false)
  const { locale } = useLocale()

  const filteredSteps = useMemo(() => {
    const st = auditPending ? nextStatus : currentStatus

    switch (st) {
      case KYCStatusType.DECLARATIVE:
        return modalOlkyPassAllSteps[0]
        break
      case KYCStatusType.VERIFIED:
        return modalOlkyPassAllSteps[1]
        break
      case KYCStatusType.AUTHENTICATED:
        return modalOlkyPassAllSteps[2]
        break
      case KYCStatusType.AUTHENTICATED_BRONZE:
        return modalOlkyPassAllSteps[3]
        break
      case KYCStatusType.AUTHENTICATED_SILVER:
        return modalOlkyPassAllSteps[4]
        break
      case KYCStatusType.AUTHENTICATED_GOLD:
        return modalOlkyPassAllSteps[5]
        break
      default:
        break
    }
  }, [currentStatus])
  const status = useMemo(() => {
    const st = auditPending ? nextStatus : currentStatus
    return st
  }, [currentStatus, nextStatus])

  const handleModalAction = () => {
    setIsOlkyPassModalOpen(false)
    switch (status) {
      case KYCStatusType.DECLARATIVE:
        setModalIdentity(true)
        break
      case KYCStatusType.VERIFIED:
        navigate('/pass/support')
        break
      case KYCStatusType.AUTHENTICATED:
        navigate('/pass/support')
        break
      case KYCStatusType.AUTHENTICATED_BRONZE:
        navigate('/pass/support')
        break
      case KYCStatusType.AUTHENTICATED_SILVER:
        navigate('/pass/support')
        break
      case KYCStatusType.AUTHENTICATED_GOLD:
        navigate('/pass/support')
        break
      default:
        break
    }
  }

  return (
    <>
      <ModalOlkyPass
        isOpen={isOlkyPassModalOpen}
        intermediate={auditPending}
        imageUrl={
          (auditPending
            ? locale.language === 'FR'
              ? filteredSteps?.imageSourceInter
              : filteredSteps?.imageSourceInterEn
            : locale.language === 'FR'
              ? filteredSteps?.imageSource
              : filteredSteps?.imageSourceEn) as string
        }
        title={filteredSteps?.title as string}
        description={
          auditPending
            ? t('olkypass.mainPage.olkypass.modalOlkyPass.kyc_status_intermediate')
            : (filteredSteps?.description as string)
        }
        onClose={() => setIsOlkyPassModalOpen(false)}
        onUnderstandButtonClick={() => setIsOlkyPassModalOpen(false)}
        onClick={handleModalAction}
        auditPending={auditPending}
      />
      <div
        className={twMerge(
          `rounded-sm h-[170px] lg:h-[185px] xl:h-[195px] 2xl:h-[200px] text-left bg-cover bg-left-bottom 	w-full  ${dashboard ? 'xl:w-4/12' : ''}  2xl:h-[200px]`,
          status === KYCStatusType.DECLARATIVE &&
            " bg-[url('/images/olkypass/declarative_kyc_bg.png')]",
          status === KYCStatusType.AUTHENTICATED_BRONZE &&
            " bg-[url('/images/olkypass/declarative_authenticted_bronze_kyc_bg.png')]",
          status === KYCStatusType.VERIFIED && " bg-[url('/images/olkypass/verified_kyc_bg.png')]",
          status === KYCStatusType.AUTHENTICATED &&
            " bg-[url('/images/olkypass/authenticated_kyc_bg.png')]",
          status === KYCStatusType.AUTHENTICATED_SILVER &&
            " bg-[url('/images/olkypass/declarative_authenticted_silver_kyc_bg.png')]",
          status === KYCStatusType.AUTHENTICATED_GOLD &&
            " bg-[url('/images/olkypass/declarative_authenticted_or_kyc_bg.png')]",

          //status === KYCStatusType.LOADING && 'bg-gradient-to-b from-gray-500 to-gray-500/50',
          'min-h-40'
        )}
      >
        <div
          className={twMerge(
            'rounded-sm w-full h-full',
            CTA && ' hover:from-lime-500 hover:to-lime-500/50',
            'min-h-48'
          )}
        >
          <div className="px-4 sm:px-4 lg:px-5 py-3 lg:py-5 2xl:px-6 2xl:py-6 flex relative gap-x-2 sm:gap-x-8 lg:gap-x-0 items-center justify-between ">
            <div>
              <div
                className="flex items-center justify-start gap-x-2 mb-1 :mt-0 cursor-pointer text-white hover:text-white/80 "
                onClick={(e) => {
                  e.stopPropagation()
                  setIsOlkyPassModalOpen(true)
                }}
              >
                <InformationCircleIcon className="text-inherit 	 h-6 w-6" />
                <CardTitle
                  className={'text-white flex font-semibold text-sm   absolute left-14 lg:left-13 '}
                >
                  {t('olkypass.level.status')}
                  <div
                    className={twMerge(
                      `text-black font-semibold text-sm text-black ${auditPending ? 'bg-orange-500 text-white' : 'bg-green-500'}   px-2	rounded-sm	 ml-2	`
                    )}
                  >
                    {status === KYCStatusType.AUTHENTICATED &&
                      t('olkypass.level.authenticated_title')}
                    {status === KYCStatusType.AUTHENTICATED_BRONZE &&
                      t('olkypass.level.authenticated_bronze_title')}
                    {status === KYCStatusType.AUTHENTICATED_SILVER &&
                      t('olkypass.level.authenticated_silver_title')}
                    {status === KYCStatusType.AUTHENTICATED_GOLD &&
                      t('olkypass.level.authenticated_gold_title')}
                    {status === KYCStatusType.VERIFIED && t('olkypass.level.verified_title')}
                    {status === KYCStatusType.DECLARATIVE && t('olkypass.level.declarative_title')}
                  </div>
                </CardTitle>
              </div>

              <div className={twMerge('min-h-28')}>
                <div className="mb-12 sm:mb-9 lg:mb-12  inline-grid grid-cols-6   ">
                  <Shield
                    intermediate={auditPending}
                    verified={status === KYCStatusType.DECLARATIVE}
                  />
                  <Shield
                    intermediate={auditPending}
                    verified={status === KYCStatusType.VERIFIED}
                  />
                  <Shield
                    intermediate={auditPending}
                    verified={status === KYCStatusType.AUTHENTICATED}
                  />
                  <Shield
                    intermediate={auditPending}
                    verified={status === KYCStatusType.AUTHENTICATED_BRONZE}
                  />
                  <Shield
                    intermediate={auditPending}
                    verified={status === KYCStatusType.AUTHENTICATED_SILVER}
                  />
                  <Shield
                    intermediate={auditPending}
                    verified={status === KYCStatusType.AUTHENTICATED_GOLD}
                  />
                </div>
                {auditPending && (
                  <Base className="absolute lg:mt-3 text-white top-20 left-6 xl:left-7">
                    {t('olkypass.mainPage.olkypass.modalOlkyPass.verification')}
                  </Base>
                )}{' '}
                <div className="flex justify-start">
                  <Button
                    onClick={() => {
                      setIsOlkyPassModalOpen(true)
                    }}
                    size="md"
                    theme={Themes.declarative}
                    className="border-none  text-black bg-white items-center justify-center  h-8 sm:h-10 w-48 text-xs sm:w-64 sm:text-sm lg:h-8  lg:text-sm xl:text-base md:w-68  lg:w-52 xl:w-64 xl:h-10 xl:text-base "
                  >
                    <span className="flex space-x-2 items-center justify-center z-10 overflow-hidden whitespace-nowrap text-ellipsis ">
                      <span className="w-max truncate">
                        {t('olkypass.mainPage.button.declarativeButton')}
                      </span>
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            {/* Shield*/}

            <div>
              {status === KYCStatusType.AUTHENTICATED_GOLD && (
                <AnimatedShield
                  color={'#D09A31'}
                  style="top-14 left-3 2xl:left-4 w-21 h-8"
                  percentage={100}
                />
              )}
              {status === KYCStatusType.AUTHENTICATED_SILVER && (
                <AnimatedShield
                  color={'#B7B7B7'}
                  style="top-14 left-3 2xl:left-4 w-21 h-8"
                  percentage={78}
                />
              )}
              {status === KYCStatusType.AUTHENTICATED_BRONZE && (
                <AnimatedShield
                  color={'#652E10'}
                  style="top-14 left-3 2xl:left-4 w-21 h-8"
                  percentage={70}
                />
              )}

              {status === KYCStatusType.AUTHENTICATED && (
                <AnimatedShield
                  style="bottom-11  left-3 w-20 2xl:left-4 2xl:w-21 h-8"
                  color={'#82C805'}
                  percentage={60}
                />
              )}
              {status === KYCStatusType.VERIFIED && (
                <AnimatedShield
                  style="bottom-9 left-6 2xl:left-7  w-14 2xl:w-18 h-6"
                  color={'#1D98DF'}
                  percentage={50}
                />
              )}
              {status === KYCStatusType.DECLARATIVE && (
                <AnimatedShield
                  style="bottom-7 left-6 2xl:left-8 w-15 h-5"
                  color={'#D11E1F'}
                  percentage={40}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalKYCIdentity
        onClose={() => {
          sessionStorage.removeItem('DocumentKsyc')
          setModalIdentity(false)
        }}
        open={modalIdentity}
      />
    </>
  )
}
