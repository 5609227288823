import { useUser } from '@/features/user/hooks/use-user'
import { useLocale } from './use-locale'

export const useCountryConverter = () => {
  const { locale } = useLocale()
  const { useCountries } = useUser()
  const { data: countries } = useCountries(locale.language)

  const countryID_Alpha2 = (id: number) =>
    (countries?.countries ?? []).find(c => c.id === id)?.alpha2

  const countryAlpha2_ID = (alpha2: string) =>
    (countries?.countries ?? []).find(c => c.alpha2 === alpha2)?.id

  return { countryID_Alpha2, countryAlpha2_ID }
}
