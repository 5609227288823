import { Routes } from '@/features/router'
import { Navigate, Outlet, Route, useLocation } from 'react-router-dom'
import { applyVueInReact } from 'veaury'
import { useTranslation } from 'react-i18next'
import { Layout, LayoutTitle } from './_layout/index.react'
import AccountViewVue from './account/AccountView.vue'
import { CompanyDetailsView as CompanyDetailsViewVue } from './account/company-details/index'
import { CompanyView as CompanyViewVue } from './account/company/index'
import { SecurityView as SecurityViewVue } from './account/security/index'

import { DashboardApp } from './dashboard/index.react'
import DashboardViewVue from './home/DashboardView.vue'
import KycoinViewVue from './home/KycoinView.vue'
import { IdentityApp } from './identity/index.react'
import { KycoinApp } from './kycoin/index.react'
import { KypayApp } from './kypay/index.react'
import {
  WalletApp,
  WalletBuy,
  WalletSell,
  WalletTransfer,
  WalletCryptoList,
  WalletCryptoDetails,
  WalletConvert,
  WalletForm
} from './wallet/index.react'
import { CompanyApp } from './company/index.react'
import { CompanyNewApp } from './company/components/company-new/index.react'
import { SupportApp } from './support/index.react'

import { AliasesAddressApp } from './identity/components/aliases-address/index.react'
import { AliasesDocumentsApp } from './identity/components/aliases-documents/index.react'
import { AliasesMobileApp } from './identity/components/aliases-mobile/index.react'
import { AliasesPaymentApp } from './identity/components/aliases-payment/index.react'
import { AliasesPublicApp } from './identity/components/aliases-public/index.react'
import { AliasesEmailApp } from './identity/components/aliases-email/index.react'

import EmailViewVue from '../views/account/EmailView.vue'
import PhoneViewVue from '../views/account/PhoneView.vue'
import AddressViewVue from '../views/account/AddressView.vue'
import DocumentsViewVue from './account/DocumentsView.vue'
import PaymentsViewVue from './account/PaymentsView.vue'
import PublicAliasesViewVue from './account/PublicAliasesView.vue'

const SecurityView = applyVueInReact(SecurityViewVue) as any

applyVueInReact(DashboardViewVue) as any
applyVueInReact(AccountViewVue) as any
applyVueInReact(KycoinViewVue) as any
applyVueInReact(CompanyViewVue) as any
applyVueInReact(CompanyDetailsViewVue) as any

applyVueInReact(EmailViewVue) as any
applyVueInReact(PhoneViewVue) as any
applyVueInReact(AddressViewVue) as any
applyVueInReact(DocumentsViewVue) as any
applyVueInReact(PaymentsViewVue) as any
applyVueInReact(PublicAliasesViewVue) as any

export const PassRoot = () =>
  <Route element={<Layout />}>
    <Route
      path=''
      element={
        <>
          <Outlet />
        </>
      }
    >
      {' '}
      <Route path='' element={<WithVueTitle />}>
        {/* <Route path={Routes.dashboard + '/old'} element={<DashboardView />} />
        <Route path={Routes.kycoin + '/old'} element={<KycoinView />} />
        <Route path={Routes.account_identity + '/old'} element={<AccountView />} /> */}
        {/* <Route path={Routes.account_alias_email} element={<EmailView />} /> */}
        {/* <Route path={Routes.account_alias_address} element={<AddressView />} /> */}
        {/* <Route path={Routes.account_alias_documents} element={<DocumentsView />} /> */}

        {/* <Route path={Routes.company_details} element={<CompanyDetailsView />} /> */}
        {/* <Route path={Routes.account_alias_payments} element={<PaymentsView />} /> */}

        {/* <Route path={Routes.account_alias_public} element={<PublicAliasesView />} /> */}
        {/* <Route path={Routes.account_alias_mobile} element={<PhoneView />} /> */}
        <Route path={Routes.security} element={<SecurityView />} />
        <Route path='*' element={<Navigate to={Routes.dashboard} />} />
        <Route path='' element={<Navigate to={Routes.dashboard} />} />
      </Route>
      <Route path={Routes.support} element={<SupportApp />} />
      <Route path={Routes.companies} element={<CompanyApp />} />
      <Route path={Routes.companies_add} element={<CompanyNewApp />} />
      <Route path={Routes.dashboard} element={<DashboardApp />} />
      <Route path={Routes.kypay} element={<KypayApp />} />
      <Route path={Routes.kycoin} element={<KycoinApp />} />
      <Route path={Routes.wallet_dashboard} element={<WalletApp />} />
      <Route path={Routes.wallet_form} element={<WalletForm />} />
      <Route path={Routes.wallet_buy} element={<WalletBuy />} />
      <Route path={Routes.wallet_sell} element={<WalletSell />} />
      <Route path={Routes.wallet_convert} element={<WalletConvert />} />
      <Route path={Routes.wallet_transfer} element={<WalletTransfer />} />
      <Route path={Routes.wallet_crypto} element={<WalletCryptoList />} />
      <Route path={Routes.wallet_crypto_details} element={<WalletCryptoDetails />} />
      <Route path={Routes.account_identity} element={<IdentityApp />} />
      <Route path={Routes.account_alias_email} element={<AliasesEmailApp />} />
      <Route path={Routes.account_alias_address} element={<AliasesAddressApp />} />
      <Route path={Routes.account_alias_documents} element={<AliasesDocumentsApp />} />
      <Route path={Routes.account_alias_public} element={<AliasesPublicApp />} />
      <Route path={Routes.account_alias_payments} element={<AliasesPaymentApp />} />
      <Route path={Routes.account_alias_mobile} element={<AliasesMobileApp />} />
      {/* <Route path={Routes.account_security} element={<SecurityApp />} /> */}
    </Route>

    {/* <Route path={Routes.companies + '/old'} element={<CompanyView />} /> */}

    <Route path='*' element={<Navigate to={Routes.dashboard} />} />
    <Route path='' element={<Navigate to={Routes.dashboard} />} />
  </Route>

/** This is temporary until we replace vue component by react ones */
export const WithVueTitle = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  let found = false

  return <>
    {Routes.security.indexOf(pathname) === 0 && !found && (found = true) && <LayoutTitle>{t('olkypass.mainPage.navigation_menu.link.security')}</LayoutTitle>}
    {(Routes.account_identity + '/old').indexOf(pathname) === 0 && !found && (found = true) && <LayoutTitle>{t('olkypass.mainPage.navigation_menu.link.my_pass')}</LayoutTitle>}
    {Routes.companies.indexOf(pathname) === 0 && !found && (found = true) && <LayoutTitle>{t('olkypass.mainPage.navigation_menu.link.companies')}</LayoutTitle>}
    {Routes.support.indexOf(pathname) === 0 && !found && (found = true) && <LayoutTitle>{t('olkypass.mainPage.navigation_menu.link.support')}</LayoutTitle>}
    {Routes.kycoin.indexOf(pathname) === 0 && !found && (found = true) && <LayoutTitle>{t('olkypass.mainPage.navigation_menu.link.olkyKycoin')}</LayoutTitle>}
    {Routes.company_details.indexOf(pathname) === 0 && !found && (found = true) && <LayoutTitle>{t('olkypass.mainPage.navigation_menu.link.companies')}</LayoutTitle>}
    {Routes.dashboard.indexOf(pathname) === 0 && !found && (found = true) && <LayoutTitle>{t('olkypass.mainPage.navigation_menu.link.home')}</LayoutTitle>}
    {Routes.account_alias_email.indexOf(pathname) === 0 && !found && (found = true) && <LayoutTitle>{t('aliasEmailPage.title')}</LayoutTitle>}
    {Routes.account_alias_mobile.indexOf(pathname) === 0 && !found && (found = true) && <LayoutTitle>{t('aliasMobilePage.title')}</LayoutTitle>}
    {Routes.account_alias_address.indexOf(pathname) === 0 && !found && (found = true) && <LayoutTitle>{t('aliasAddressPage.title')}</LayoutTitle>}
    {Routes.account_alias_documents.indexOf(pathname) === 0 && !found && (found = true) && <LayoutTitle>{t('aliasDocumentsPage.title')}</LayoutTitle>}
    {Routes.account_alias_payments.indexOf(pathname) === 0 && !found && (found = true) && <LayoutTitle>{t('aliasPaymentsPage.title')}</LayoutTitle>}
    {Routes.account_alias_public.indexOf(pathname) === 0 && !found && (found = true) && <LayoutTitle>{t('aliasPublicPage.title')}</LayoutTitle>}
    <Outlet />
  </>
}
