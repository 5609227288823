import { NaturalPerson } from '../types'

export type Document = {
  UUID: string
  TypePiece: number
  Number: string
  Validity: boolean | null
  OwnerUUID: string
  NaturalPerson: NaturalPerson | null
  algoregDocumentId: string
  typePerLanguage: {
    fr: string
    en: string
  }
  selfUpload: boolean
  Document: DocumentDescription | null
  uuid: string
}

export type DocumentDescription = {
  file_mime: MimeType
  file_name: string
  id: string
}

export enum MimeType {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  PDF = 'application/pdf'
}

export type DocumentType = {
  id: number
  type_fr: string
  type_en: string
  need_other_name: boolean
  need_manual_validation: boolean
}

export type UploadDocument = {
  language: string
  ownerUUID: string
  document: {
    base64Doc: string
    fileName: string
    fileFormat: string
  }
}
