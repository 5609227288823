<template>
  <v-card class="mx-auto olkyPayCard" height="320px" width="auto">
    <v-card-item class="cardTopTitle">
      <template #title>
        {{ $t('olkypass.accountPage.cardActions.label.pay') }}
      </template>
      <template #append>
        <v-img class="mx-auto" height="30px" width="77px" :src="olkypayLogoPath"></v-img>
      </template>
    </v-card-item>
    <v-card-text v-if="!loading" class="cardContentContainer">
      <v-row class="available">
        <v-col>
          <h1>{{ naturalPerson.dividedBalance }}€</h1>
          <!--          <h2>Solde disponile en date du {{ naturalPerson.balanceOperationDate }}</h2>-->
          <h2>IBAN : {{ naturalPerson.ibanOlkyPay }}</h2>
        </v-col>
      </v-row>
      <v-row class="operation">
        <v-col>
          <h1>&nbsp;</h1>
          <h2>&nbsp;</h2>
          <!-- Solde sur opération en date du 18/08/2023 -->
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="!loading">
      <a
        href="https://app.olkypay.com/login"
        target="_blank"
        class="no-ui"
        rel="noopener noreferrer"
        >{{ $t('olkypass.mainPage.olkypass.details') }}</a
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from '../../store'

export default {
  name: 'DashboardOlkyPayDataBox',
  data: () => ({
    olkypayLogoPath: '/images/olkypass/olkypay-logo.svg'
  }),
  computed: {
    ...mapGetters(['loading', 'naturalPerson'])
  }
}
</script>
