<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex justify-start">
        <v-btn
          variant="text"
          color="primary"
          height="auto"
          class="text-none textBtn"
          width="auto"
          prepend-icon="mdi-email-plus-outline"
          size="medium"
          @click="openFormDialog('email')"
        >
          <template #prepend>
            <v-icon class="mx-1 cercle" size="20"></v-icon>
          </template>
          {{ $t('olkypass.accountPage.emails_section.button.addEmail') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-start">
      <EmailChild
        v-for="(mail, index) in naturalPerson.AliasEmail"
        :key="(mail, index) in naturalPerson.AliasEmail"
        :mail="mail"
        :in-memory-mail="inMemoryNP.AliasEmail[index]"
      ></EmailChild>
    </v-row>
    <EmailFormDialog ref="emailFormDialog"></EmailFormDialog>
    <EmailCodeFormDialog ref="emailCodeFormDialog"></EmailCodeFormDialog>
  </v-container>
</template>

<script lang="ts">
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import ToolsMixins from '../../mixins/ToolsMixins.js'
import { mapGetters } from '../../store'
import EmailChild from '../Common/EmailChild.vue'
import EmailCodeFormDialog from '../FormsDialog/EmailCodeFormDialog.vue'
import EmailFormDialog from '../FormsDialog/EmailFormDialog.vue'

export default {
  name: 'EmailsTab',
  components: {
    EmailChild,
    EmailFormDialog,
    EmailCodeFormDialog
  },
  mixins: [NotificationsMixins, ToolsMixins],
  data: () => ({}),
  computed: {
    ...mapGetters([
      'wantToLeavePage',
      'currentLanguage',
      'hasUpdatePermission',
      'naturalPerson',
      'inMemoryNP',
      'kycState',
      'loading',
      'naturalPerson',
      'inMemoryNP',
      'countries',
      'cspCodes',
      'activities',
      'countryLabel',
      'cspLabel',
      'activityLabel',
      'wantToLeavePage',
      'currentUUID',
      'currentLanguage'
    ])
  },
  async mounted() {
    await this.detectNaturalPersonIdentity(this.naturalPerson)
  },
  methods: {
    openFormDialog() {
      this.$refs.emailFormDialog.open()
    }
  },
  isDirtyMail() {
    if (this.wantToLeavePage && this.hasUpdatePermission) {
      return !this.isEqualObjects(this.inMemoryMail.AliasEmail, this.mail.AliasEmail)
    }
    return false
  }
}
</script>

<style scoped></style>
