<template>
  <ion-item>
    <ion-icon slot="start" aria-hidden="true" :icon="language" color="blue_olky"></ion-icon>
    <ion-select
      v-model="currentLanguage"
      :label="$t('olkypay.labels.morePage.language')"
      interface="popover"
      class="text-md"
      @ionChange="changeLanguage"
    >
      <ion-select-option value="fr">FR</ion-select-option>
      <ion-select-option value="en">EN</ion-select-option>
    </ion-select>
  </ion-item>
</template>
<script lang="ts">
import { setLanguage, getLanguage } from '@/features/utils/i18n'
import { IonSelect, IonItem, IonSelectOption, IonIcon } from '@ionic/vue'
import { caretDownSharp, language } from 'ionicons/icons'

export default {
  components: {
    IonItem,
    IonSelect,
    IonSelectOption,
    IonIcon
  },
  data() {
    return {
      currentLanguage: 'fr',
      language
    }
  },
  computed: {
    caretDownSharp() {
      return caretDownSharp
    }
  },
  mounted() {
    this.currentLanguage = getLanguage()
  },
  methods: {
    changeLanguage() {
      setLanguage(this.currentLanguage)
    }
  }
}
</script>
<style scoped></style>
