import { ConfirmationResponse } from '@/features/register/types'
import {
  StandardErrorResponse,
  StandardResponse,
  isStandardErrorResponse
} from '@/features/utils/types'
import { useMutation, useQuery } from '@tanstack/react-query'
import { CompanyApiClient } from '../api-client/api-client'
import { CompanyCreateDTO, CompanyDTO, CompanyDetailedDTO, CompanyRCSDetails } from '../types'

type UseCompanyDetailsByRCSProps = {
  country: string
  rcs: string
}

export const useCompany = () => {
  const companiesQuery = () =>
    useQuery<StandardResponse<CompanyDTO[] | null>, null, CompanyDTO[]>({
      queryKey: ['companies'],
      queryFn: async () => {
        const data = await CompanyApiClient.fetchListOfCompanies()
        if (!data.success) throw new Error()

        return data
      },
      select: data => data.data ?? []
    })

  const companyDetailQuery = (uuid: string | null) =>
    useQuery<StandardResponse<CompanyDetailedDTO | null>, null>({
      queryKey: ['companies', uuid],
      queryFn: async () => {
        if (!uuid) return StandardErrorResponse
        const data = await CompanyApiClient.fetchCompanyDetails(uuid)
        if (!data.success) throw new Error()

        return data
      },
      enabled: !!uuid
    })

  const useCompanyDetailsByRCS = () =>
    useMutation<StandardResponse<CompanyRCSDetails> | null, Error, UseCompanyDetailsByRCSProps>({
      mutationFn: async ({ country, rcs }) => {
        try {
          const data = await CompanyApiClient.fetchCompanyDetailsByRCS(country, rcs)
          if (isStandardErrorResponse(data)) throw new Error()

          return data
        } catch (e) {
          return null
        }
      },
      throwOnError: false
    })

  const useCreateCompany = () =>
    useMutation<ConfirmationResponse, Error, CompanyCreateDTO>({
      mutationFn: async (companyCreate: CompanyCreateDTO) => {
        const { success, message } = await CompanyApiClient.createCompany(companyCreate)
        if (success === false) throw new Error()
        return { success, message }
      }
    })

  return { companiesQuery, useCreateCompany, companyDetailQuery, useCompanyDetailsByRCS }
}
