<script setup lang="ts">
import { Button } from '@/atoms/buttons/Button.tsx'
import Modal from '@/atoms/modals/Modal.vue'
import ModalContent from '@/atoms/modals/ModalContent.vue'
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import { defineProps, ref, useAttrs } from 'vue'

const $attrs = useAttrs()

const props = defineProps({
  confirmTitle: { type: String, default: '' },
  confirmMessage: { type: String, default: '' },
  confirmIcon: { type: Function, default: null },
  confirmButtonTheme: { type: String, default: '' },
  confirmButtonText: { type: String, default: '' },
  cancelButtonText: { type: String, default: '' },
  onClick: { type: Function, default: null }
})

const inConfirm = ref(false)
const showModal = () => inConfirm.value = true
const closeModal = () => inConfirm.value = false

const confirmClick = (e: Event) => {
  closeModal()
  setTimeout(() => props.onClick && props.onClick(e), 500)
}
</script>

<template>
  <div>
    <Button v-bind="{ ...$attrs, onClick: showModal }" @click="showModal"><slot></slot></Button>

    <Modal :open="inConfirm" @close="closeModal">
      <ModalContent
        :title="confirmTitle || 'Confirm action'"
        :text="confirmMessage || 'Click on confirm to continue.'"
        :icon="confirmIcon || ExclamationCircleIcon"
      >
        <template #buttons>
          <Button
            :theme="(props.confirmButtonTheme as any) || 'primary'"
            class="mr-4 my-2"
            size="lg"
            @click="confirmClick"
          >
            {{ props.confirmButtonText || 'Confirmer' }}
          </Button>
          <Button theme="default" size="lg" class="mr-4 my-2 shadow-none" @click="closeModal">
            {{ props.cancelButtonText || 'Annuler' }}
          </Button>
        </template>
      </ModalContent>
    </Modal>
  </div>
</template>
