import { Base, Info } from '@/atoms-react/text/Text.react'
import { EmptyPageCactusAnimation } from '@/components/animations/empty-cactus/index.react'
import { formatNumber } from '@/features/utils/functions'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { ArrowDownRightIcon, ArrowUpRightIcon } from '@heroicons/react/24/solid'
import { OrdersListProps } from '@/features/wallet/types'
import Image from '@/atoms-react/image/Image.react'

export const OrdersList = ({ orders, numberOfOrders, search }: OrdersListProps) => {
  const { t } = useTranslation(['wallet'])

  if (numberOfOrders && numberOfOrders > 0 && orders) {
    orders = orders.slice(0, numberOfOrders)
  }

  return (
    <div className={'flex flex-col space-y-2 md:space-y-0 w-full h-max'}>
      {orders?.length === 0 && !search && (
        <>
          <div className="w-full flex items-center flex-col space-y-4 my-16">
            <EmptyPageCactusAnimation />
            <Info>{t('wallet.orders.info.no_orders')}</Info>
          </div>
        </>
      )}
      {orders?.length === 0 && !!search && (
        <>
          <div className="w-full flex items-center flex-col space-y-4 my-16">
            <Info>{t('wallet.orders.info.not_found')}</Info>
          </div>
        </>
      )}
      {orders?.map((order, index) => (
        <div
          className={twMerge(
            'flex justify-between items-center px-3 py-3 md:px-8 md:py-2 border-2 border-slate-50 dark:border-slate-700 md:border-0 rounded-sm md:rounded-none',
            index % 2 === 0 && 'md:bg-blue-50 md:dark:bg-slate-600'
          )}
          key={index}
        >
          <div
            className="flex items-center w-full md:w-1/2 gap-2"
            // @POTENTIAL SIDE EFFECT
            style={{ maxWidth: 'calc(100% - 118px)' }}
          >
            {order.operation_type === 'SELL' && (
              <>
                <ArrowDownRightIcon className="h-5 w-5 text-red-500" />
                <div className="hidden md:block" title="Ordre de vente">
                  <Base className="md:text-slate-500">{t('wallet.orders.operation.sell')}</Base>
                </div>
                <div className="md:hidden w-3/4">
                  <Base className="md:text-slate-500 truncate w-full">
                    {t('wallet.orders.operation.sell')}
                  </Base>
                </div>
              </>
            )}
            {order.operation_type === 'BUY' && (
              <>
                <ArrowUpRightIcon className="h-5 w-5 text-green-500" />
                <div className="hidden md:block" title="Ordre d'achat">
                  <Base className="md:text-slate-500">{t('wallet.orders.operation.buy')}</Base>
                </div>
                <div className="md:hidden w-3/4">
                  <Base className="md:text-slate-500 truncate w-full">
                    {t('wallet.orders.operation.buy')}
                  </Base>
                </div>
              </>
            )}
          </div>
          <div className="hidden md:block md:w-1/4">
            <Base className="text-xs md:text-base text-slate-500 gap-2 flex items-center">
              {order?.image && (
                <Image
                  src={order?.image}
                  alt={order?.crypto_currency?.symbol}
                  className={'w-8 h-8 inline-block'}
                />
              )}
              {order?.amount} {order?.crypto_currency?.symbol.toUpperCase()} (
              {formatNumber(order?.price)}€)
            </Base>
          </div>
          <div className="hidden md:block md:w-1/4">
            <Base className="text-xs md:text-base text-slate-500">
              {t('wallet.orders.operation.condition')} {formatNumber(order?.condition_price)}€
            </Base>
          </div>
          <div className="hidden md:block md:w-1/4">
            <Base className="text-xs md:text-base text-end text-blue-500 dark:text-blue-500 underline underline-offset-1">
              {t('wallet.orders.operation.cancel')}
            </Base>
          </div>
        </div>
      ))}
    </div>
  )
}
