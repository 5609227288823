import { useMutation } from '@tanstack/react-query'
import { BankAccountAPIClient } from '../api-client/api-client'

export const useBankAccount = () => {
  function useCreateBankAccountMutation() {
    return useMutation({
      mutationFn: BankAccountAPIClient.create
    })
  }
  return { useCreateBankAccountMutation }
}
