<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-alert density="compact" class="noticeAlert">
          <template #prepend>
            <v-icon icon="mdi-information-outline" color="info"></v-icon>
          </template>
          <template #title>
            {{ $t('olkypass.accountPage.publicAliasSection.label.addAlias') }}
          </template>
          <template #text>
            {{ $t('olkypass.accountPage.publicAliasSection.label.availableAlias') }}
          </template>
        </v-alert>
      </v-col>
    </v-row>
    <v-row class="justify-start">
      <!-- EMAIL -->
      <v-col cols="12" sm="12" md="6" lg="4">
        <v-card class="mx-auto elementCard">
          <template #title>
            {{ $t('olkypass.accountPage.basicInfo.tabs.emails') }}
          </template>
          <v-card-text class="elementData">
            <v-list-item
              v-for="aliasEmail in filteredActiveEmails"
              :key="aliasEmail.UUID"
              :title="aliasEmail.Mail"
            >
              <template #prepend>
                <v-avatar color="primary-darken-1">
                  <v-icon color="primary">mdi-email-outline</v-icon>
                </v-avatar>
              </template>

              <template #append>
                <v-switch
                  v-model="aliasEmail.Public"
                  color="secondary"
                  :label="$t('olkypass.accountPage.ibans_section._switch.public')"
                  hide-details
                  @change="savePublicAlias('email', aliasEmail.UUID)"
                ></v-switch>
              </template>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- MOBILE -->
      <v-col v-if="filteredActiveMobiles.length" cols="12" sm="12" md="6" lg="4">
        <v-card class="mx-auto elementCard">
          <template #title>
            {{ $t('olkypass.accountPage.basicInfo.tabs.mobiles') }}
          </template>
          <v-card-text class="elementData">
            <v-list-item
              v-for="mobile in filteredActiveMobiles"
              :key="mobile.UUID"
              :title="mobile.MobileIndicatif + ' ' + mobile.MobileNumber"
            >
              <template #prepend>
                <v-avatar color="primary-darken-1">
                  <v-icon color="primary">mdi-phone-outline</v-icon>
                </v-avatar>
              </template>

              <template #append>
                <v-switch
                  v-model="mobile.Public"
                  color="secondary"
                  :label="$t('olkypass.accountPage.ibans_section._switch.public')"
                  hide-details
                  @change="savePublicAlias('mobile', mobile.UUID)"
                ></v-switch>
              </template>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- MODE PAYEMENT -->
      <v-col v-if="filteredActiveIbans.length" cols="12" sm="12" md="6" lg="4">
        <v-card class="mx-auto elementCard">
          <template #title>
            {{ $t('olkypass.accountPage.basicInfo.tabs.payments') }}
          </template>
          <v-card-text class="elementData">
            <v-list-item
              v-for="aliasIban in filteredActiveIbans"
              :key="aliasIban.UUID"
              :title="aliasIban.Iban"
              :subtitle="aliasIban.BIC"
            >
              <template #prepend>
                <v-avatar color="primary-darken-1">
                  <v-icon color="primary">mdi-bank-circle-outline</v-icon>
                </v-avatar>
              </template>

              <template #append>
                <v-switch
                  v-model="aliasIban.Public"
                  color="secondary"
                  :label="$t('olkypass.accountPage.ibans_section._switch.public')"
                  hide-details
                  @change="savePublicAlias('iban', aliasIban.UUID)"
                ></v-switch>
              </template>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from '../../store'
import store from '../../store'

export default {
  name: 'PublicAliasTab',
  data: () => ({}),
  methods: {
    deactivateOtherAlias(arr, inputUUID) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].UUID !== inputUUID) {
          arr[i].Public = false
        }
      }
    },
    async savePublicAlias(section, uuid) {
      const setPartialDataFunctionName =
        this.currentTreatedEntity === 'company' ? 'setCompanyPartialData' : 'setNPPartialData'
      store.dispatch('playLoader')
      switch (section) {
        case 'email':
          this.deactivateOtherAlias(this.naturalPerson.AliasEmail, uuid)
          await store.dispatch(setPartialDataFunctionName, {
            typeDto: 'AliasMailDto',
            action: 'updateAll',
            UUID: this.naturalPerson.UUID,
            AliasEmail: this.naturalPerson.AliasEmail
          })
          break
        case 'mobile':
          this.deactivateOtherAlias(this.naturalPerson.AliasMobile, uuid)
          await store.dispatch(setPartialDataFunctionName, {
            typeDto: 'AliasMobileDto',
            action: 'updateAll',
            UUID: this.naturalPerson.UUID,
            AliasMobile: this.naturalPerson.AliasMobile
          })
          break
        case 'iban':
          this.deactivateOtherAlias(this.naturalPerson.AliasIBAN, uuid)
          await store.dispatch(setPartialDataFunctionName, {
            typeDto: 'AliasIBANDto',
            action: 'updateAll',
            UUID: this.naturalPerson.UUID,
            AliasIBAN: this.naturalPerson.AliasIBAN
          })
          break
      }
      if (this.currentTreatedEntity === 'company') {
        await store.dispatch('setCurrentCompany', { UUID: this.naturalPerson.UUID })
      } else {
        await store.dispatch('setCurrentNaturalPerson')
      }
      store.dispatch('stopLoader')
    }
  },
  computed: {
    ...mapGetters(['naturalPerson', 'currentUUID', 'currentTreatedEntity']),
    filteredActiveEmails() {
      return this.naturalPerson.AliasEmail.filter(element => element.Actif)
    },
    filteredActiveMobiles() {
      return this.naturalPerson.AliasMobile.filter(element => element.Actif)
    },
    filteredActiveIbans() {
      return this.naturalPerson.AliasIBAN.filter(element => element.Actif)
    }
  },
  mounted() {}
}
</script>

<style scoped></style>
