<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex justify-start">
        <v-btn
          variant="text"
          color="primary"
          height="auto"
          class="text-none textBtn"
          width="auto"
          prepend-icon="mdi-phone-plus-outline"
          size="medium"
          @click="openFormDialog('mobile')"
        >
          <template #prepend>
            <v-icon class="mx-1 cercle" size="20"></v-icon>
          </template>
          {{ $t('olkypass.accountPage.mobiles_section.button.addMobile') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-start">
      <MobileChild
        v-for="(mobile, index) in naturalPerson.AliasMobile"
        :mobile="mobile"
        :in-memory-mobile="inMemoryNP.AliasMobile[index]"
      ></MobileChild>
    </v-row>
    <MobileFormDialog ref="mobileFormDialog"></MobileFormDialog>
    <DialogConfirm ref="dialogConfirm"></DialogConfirm>
  </v-container>
</template>

<script>
// import UniformPanels from "../Common/UniformPanels.vue";
import NotificationsMixins from '../../mixins/NotificationsMixins.js'
import ToolsMixins from '../../mixins/ToolsMixins.js'
import { mapGetters } from '../../store'
import store from '../../store'
import MobileChild from '../Common/MobileChild.vue'
import MobileFormDialog from '../FormsDialog/MobileFormDialog.vue'
import DialogConfirm from '../DialogConfirm.vue'

export default {
  name: 'MobilesTab',
  components: {
    MobileChild,
    MobileFormDialog,
    DialogConfirm
  },
  mixins: [NotificationsMixins, ToolsMixins],
  data: () => ({}),
  methods: {
    openFormDialog(section) {
      this.$refs.mobileFormDialog.open()
    },
    async savePartialData(section) {
      const setPartialDataFunctionName =
        this.currentTreatedEntity === 'company' ? 'setCompanyPartialData' : 'setNPPartialData'
      if (
        await this.$refs.dialogConfirm.open(
          'accountPage.dialog.confirm.update.infos.header',
          'accountPage.dialog.confirm.update.infos.body'
        )
      ) {
        store.dispatch('playLoader')
        await store.dispatch(setPartialDataFunctionName, {
          UUID: this.naturalPerson.UUID,
          AliasMobile: this.naturalPerson.AliasMobile
        })
        if (this.currentTreatedEntity === 'company') {
          await store.dispatch('setCurrentCompany', { UUID: this.naturalPerson.UUID })
        } else {
          await store.dispatch('setCurrentNaturalPerson')
        }
        store.dispatch('stopLoader')
      }
    }
  },
  computed: {
    ...mapGetters([
      'wantToLeavePage',
      'currentLanguage',
      'hasUpdatePermission',
      'naturalPerson',
      'inMemoryNP'
    ])
  }
}
</script>

<style scoped></style>
